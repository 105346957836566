import {
  Follower,
  FollowerMembershipState,
  FollowerSubscription,
  MembershipPlan,
} from "@markit/common.types";
import {
  convertToMoney,
  dateIsCurrentYear,
  formatDateTime,
  GetDateWithTime,
} from "@markit/common.utils";
import { useMemo } from "react";
import { Icon } from "@iconify/react";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import { Colors } from "../../../utils/colors";
import BillingItemInvoice from "../Items/BillingItemInvoice";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";

type MembershipBillingItemProps = {
  membershipPlans: MembershipPlan[];
  follower: Follower | undefined;
  subscription: FollowerSubscription;
  lastMonthSubscription?: FollowerSubscription;
  isUpcoming?: boolean;
  creatorFullName?: string; // to display for the payment failed item case
};

const MembershipBillingItem = (props: MembershipBillingItemProps) => {
  const {
    membershipPlans,
    follower,
    subscription,
    lastMonthSubscription,
    isUpcoming = false,
    creatorFullName,
  } = props;
  const uid = useSelector(getAccountState).account.accountData.uid;

  const membershipPlan = useMemo(
    () =>
      membershipPlans.find((plan) => plan.id === subscription.membershipPlanId),
    [membershipPlans, subscription.membershipPlanId]
  );

  const isFreePlan = useMemo(
    () => subscription.membershipPlanId === "free",
    [subscription.membershipPlanId]
  );

  const upcomingPaymentFailed = useMemo(
    () =>
      follower
        ? isUpcoming &&
          follower.membershipState === FollowerMembershipState.PAYMENT_FAILED
        : false,
    [follower, isUpcoming]
  );

  // If the last month's subscription has a different membershipPlanId or there is no last month, this is a new subscription object
  const isNewSubscriptionStart = useMemo(
    () =>
      (lastMonthSubscription &&
        lastMonthSubscription.membershipPlanId !==
          subscription.membershipPlanId) ||
      !lastMonthSubscription,
    [lastMonthSubscription, subscription.membershipPlanId]
  );

  return (
    <div className="AlignedRow" style={{ gap: 14, flex: 1 }}>
      <StandardBorderedContainer
        containerStyles={{
          padding: 7,
          borderRadius: 8,
          borderColor: upcomingPaymentFailed
            ? Colors.RED3
            : isUpcoming && !isFreePlan
            ? Colors.GRAY2
            : Colors.BLUE5,
          backgroundColor: upcomingPaymentFailed
            ? `${Colors.RED3}1A`
            : isUpcoming && !isFreePlan
            ? Colors.WHITE1
            : `${Colors.BLUE5}1A`,
        }}
      >
        <div className="Centering">
          <Icon
            icon={
              upcomingPaymentFailed
                ? "ion:close"
                : isUpcoming && !isFreePlan
                ? "ion:time"
                : "ion:checkmark"
            }
            height={23}
            color={
              upcomingPaymentFailed
                ? Colors.RED3
                : isUpcoming && !isFreePlan
                ? Colors.GRAY1
                : Colors.BLUE5
            }
          />
        </div>
      </StandardBorderedContainer>
      <div className="ColumnNormal" style={{ gap: 4 }}>
        <span className="bodyMedium">
          {upcomingPaymentFailed && creatorFullName
            ? `Failed Payment: ${creatorFullName},`
            : subscription.id === "downgrade"
            ? "Downgraded to:"
            : isNewSubscriptionStart
            ? "Subscribed to:"
            : "Subscription:"}{" "}
          {membershipPlan ? membershipPlan.name : "Plan no longer exists"}
        </span>
        {!isFreePlan ? (
          <span className="smallBodySubtext">
            {upcomingPaymentFailed
              ? "Failed Bill"
              : isUpcoming
              ? "Upcoming Bill"
              : "Billed"}{" "}
            on{" "}
            {formatDateTime(
              new Date(subscription.start),
              true,
              !dateIsCurrentYear(subscription.start)
            )}
          </span>
        ) : null}
        {!isFreePlan ? (
          <span className="smallBodySubtext">
            Billing cycle:{" "}
            {formatDateTime(new Date(subscription.start), true, false)} -{" "}
            {formatDateTime(new Date(subscription.end), true, true)}
          </span>
        ) : subscription.id !== "downgrade" ? (
          <span className="smallBodySubtext">
            {GetDateWithTime(
              subscription.start,
              true,
              !dateIsCurrentYear(subscription.start)
            )}
          </span>
        ) : null}
        <span className="smallBodySubtext">
          Price:{" "}
          {membershipPlan ? convertToMoney(membershipPlan.price / 100) : "N/A"}
        </span>
        {membershipPlan &&
        follower?.uid === uid &&
        !isFreePlan &&
        (!isUpcoming || upcomingPaymentFailed) ? (
          <BillingItemInvoice
            subscriptionId={subscription.subscriptionId}
            subscriptionStart={subscription.start}
            upcomingPaymentFailed={upcomingPaymentFailed}
          />
        ) : null}
      </div>
    </div>
  );
};

export default MembershipBillingItem;
