import { EventType } from "@markit/common.types";
import { isExternalGenericLink } from "@markit/common.utils";
import { NavigationId } from "../navigation/AppParamList";

// Scroll to top when page render changes
export const scrollToTop = () => {
  setTimeout(() => {
    const canControlScrollRestoration = "scrollRestoration" in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 100);
};

// Determines what pathname to navigate to when navigating to a create page
export const onCreateNavigatePath = (eventType: EventType) => {
  return `/create${isExternalGenericLink(eventType) ? "?link" : ""}`;
};

// Determines what pathname to navigate to when navigating to createLink page
export const onCreateLinkNavigatePath = (eventType: EventType) => {
  return `/createLink${isExternalGenericLink(eventType) ? "" : "?event"}`;
};

// Helper to navigate to the campaign dashboard
export const onCampaignNavigatePath = (campaignId: string) => {
  return `/c/${campaignId}`;
};

// Helper to navigate to the audience list dashboard
export const onAudienceListNavigatePath = (listId: string) => {
  return `/a/${listId}`;
};

// Helper to navigate to the Welcome Flow tab on the Home Profile page
export const onCreatorProfileNavigatePath = (
  navigate: any,
  initialTabValue?: number
) => {
  navigate(NavigationId.HOME_PROFILE, { state: { initialTabValue } });
};

// Helper to navigate to the profile page (and immediately to subscribe flow if specified)
export const onProfilePageNavigatePath = (
  navigate: any,
  username: string,
  subscribeFlow?: "subscribe" | "manage" | "upgrade",
  replace?: boolean
) => {
  navigate(
    `/u/${username}${
      subscribeFlow === "subscribe"
        ? "/subscribe"
        : subscribeFlow === "manage"
        ? "/manageSubscription"
        : subscribeFlow === "upgrade"
        ? "/manageSubscription?upgrade"
        : ""
    }`,
    {
      state: { username },
      replace: replace,
    }
  );
};
