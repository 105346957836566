import "../../css/ProfilePage/ProfilePage.css";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import SearchBoxContainer from "../Containers/SearchBoxContainer";
import ProfilePic from "../ProfilePic";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import {
  AccountData,
  Follower,
  FollowerMembershipState,
} from "@markit/common.types";
import filter from "lodash.filter";
import { onProfilePageNavigatePath } from "../../utils/navigationUtils";
import { useNavigate } from "react-router-dom";
import {
  getUserFollowerData,
  getUserFollowerRef,
} from "../../utils/FirebaseUtils";
import { onSnapshot } from "../../firebase";
import { convertToMoney } from "@markit/common.utils";
import { DataLoaders } from "../../redux/slices/dataSlice";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import EmptyStateButton from "../Buttons/EmptyStateButton";
import HelpModal from "../Containers/HelpModal";
import { getShortMembershipFrequencyLabel } from "../../utils/subscriptionUtils";
import FlatlistLoadMore from "../FlatlistLoadMore";
import useAsyncOnMount from "../../hooks/useAsyncEffectOnMount";
import { isDesktop } from "react-device-detect";
import MembershipPaymentFailedButtonModal from "../Subscription/Memberships/MembershipPaymentFailedButtonModal";
import PaymentFailedButton from "../Subscription/PaymentFailedButton";

type ProfilePageUserSubscriptionsProps = {
  followingAccountDataFollowing: AccountData[];
  followerDataMap: Map<string, Follower>;
  setFollowerDataMap: (followersDataMap: Map<string, Follower>) => void;
};

const ProfilePageUserSubscriptions = memo(
  function ProfilePageUserSubscriptionsFn(
    props: ProfilePageUserSubscriptionsProps
  ) {
    const { accountData } = useSelector(getAccountState).account;
    const {
      followingAccountDataFollowing,
      followerDataMap,
      setFollowerDataMap,
    } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [helpModal, setHelpModal] = useState(false);
    const [windowSize, setWindowSize] = useState(10);
    const [loading, setLoading] = useState(true);
    const [opacity, setOpacity] = useState(0);
    const [paymentFailedModal, setPaymentFailedModal] = useState(false);
    const [singlePaymentFailedEntry, setSinglePaymentFailedEntry] = useState<
      Map<string, Follower>
    >(new Map());

    const styles = {
      headerButton: {
        padding: 10,
        whiteSpace: "nowrap",
        border: `0.5px solid ${Colors.GRAY2}`,
        backgroundColor: Colors.WHITE1,
        borderRadius: 8,
      },
      sectionContainer: {
        paddingBlock: 14,
        backgroundColor: Colors.WHITE1,
        borderRadius: 20,
      },
    };

    useEffect(() => {
      if (!loading) {
        setOpacity(1);
      }
    }, [loading]);

    useAsyncOnMount(async () => {
      const newFollowerMap = new Map<string, Follower>();
      await Promise.all(
        followingAccountDataFollowing.map(async (followingUser) => {
          // Check if the follower data is already in the map
          if (followerDataMap.has(followingUser.uid)) {
            newFollowerMap.set(
              followingUser.uid,
              followerDataMap.get(followingUser.uid)!
            );
            return;
          }
          const followerData = await getUserFollowerData(
            followingUser.uid,
            accountData.uid
          );
          if (followerData) {
            newFollowerMap.set(followingUser.uid, followerData);
          }
        })
      );
      setFollowerDataMap(newFollowerMap);

      const hasFailedPayment = Array.from(newFollowerMap.values()).some(
        (follower) =>
          follower.membershipState === FollowerMembershipState.PAYMENT_FAILED
      );
      let unsubscribers: (() => void)[] = [];
      if (hasFailedPayment) {
        // Add listeners for the follower subscriptions to change state when membershipState changes
        unsubscribers = Array.from(newFollowerMap.entries()).map(
          ([uid, follower]) => {
            const followerRef = getUserFollowerRef(uid, follower.uid);
            return onSnapshot(followerRef, (doc) => {
              const updatedFollower = doc.data();
              if (updatedFollower) {
                const updatedMap = new Map(newFollowerMap);
                updatedMap.set(uid, updatedFollower);
                setFollowerDataMap(updatedMap);
              }
            });
          }
        );
      }

      setLoading(false);

      return () => {
        if (unsubscribers) {
          unsubscribers.forEach((unsubscriber) => unsubscriber());
        }
      };
    });

    const hasPaidMemberships = useMemo(
      () =>
        Array.from(followerDataMap.values()).some(
          (follower) => follower.membershipPlanId !== "free"
        ),
      [followerDataMap]
    );

    const hasPaymentFailedSubscription = useMemo(
      () =>
        Array.from(followerDataMap.values()).some(
          (follower) =>
            follower.membershipState === FollowerMembershipState.PAYMENT_FAILED
        ),
      [followerDataMap]
    );

    const containsUser = useCallback((user: AccountData, query: string) => {
      return user.fullName.toLowerCase().includes(query.toLowerCase());
    }, []);

    const followingItemsToShow: AccountData[] = useMemo(() => {
      let searchResults: AccountData[] = followingAccountDataFollowing.slice(
        0,
        windowSize
      );
      if (searchTerm !== "") {
        searchResults = filter(
          followingAccountDataFollowing,
          (user: AccountData) => {
            return containsUser(user, searchTerm);
          }
        );
      }
      return searchResults;
    }, [followingAccountDataFollowing, windowSize, searchTerm, containsUser]);

    const handleSearch = useCallback((value: string) => {
      setSearchTerm(value);
    }, []);

    const managePaymentOnPress = useCallback(() => {
      setHelpModal(true);
    }, []);

    const navigateProfileOnPress = useCallback(
      (username: string, userId: string) => {
        dispatch(DataLoaders.user(userId));
        onProfilePageNavigatePath(navigate, username);
      },
      [dispatch, navigate]
    );

    const renderFollowingItem = useCallback(
      (followingUser: AccountData) => {
        const followerData = followerDataMap.get(followingUser.uid);
        const membershipPlan = followingUser.membership.membershipPlans.find(
          (plan) => plan.id === followerData?.membershipPlanId
        );
        const priceLabel =
          membershipPlan && membershipPlan.price > 0
            ? `${convertToMoney(
                membershipPlan.price / 100
              )}/${getShortMembershipFrequencyLabel(
                membershipPlan.billingFrequency
              )}`
            : "Free";
        const isPaymentFailed =
          followerData &&
          followerData.membershipState ===
            FollowerMembershipState.PAYMENT_FAILED;
        return followerData ? (
          <div
            onClick={() =>
              navigateProfileOnPress(followingUser.username, followingUser.uid)
            }
            className="AlignedRowSpacedSelect"
            key={followingUser.uid}
          >
            <div className="AlignedRowSelect" style={{ gap: 14 }}>
              <ProfilePic user={followingUser} size={55} forcePointer />
              <div className="ColumnNormalSelect" style={{ gap: 4 }}>
                <span className="sectionTitle">{followingUser.fullName}</span>
                <span className="bodySubtext">{priceLabel}</span>
              </div>
            </div>
            <div
              onClick={(e) => {
                if (isPaymentFailed) {
                  e.stopPropagation();
                  setSinglePaymentFailedEntry(
                    new Map([[followingUser.uid, followerData]])
                  );
                  setPaymentFailedModal(true);
                }
              }}
              className="AlignedRowSelect"
              style={{ gap: 4, ...styles.headerButton }}
            >
              {followerData.membershipPlanId !== "free" && !isPaymentFailed ? (
                <Icon icon={"ion:ribbon"} height={16} color={Colors.GREEN2} />
              ) : undefined}
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: isPaymentFailed
                    ? Colors.RED3
                    : followerData.membershipPlanId !== "free"
                    ? Colors.GREEN2
                    : Colors.BLACK,
                }}
              >
                {isPaymentFailed ? "Payment Failed" : "Subscribed"}
              </span>
              {isPaymentFailed ? (
                <Icon
                  icon="ion:chevron-right"
                  height={12}
                  color={Colors.RED3}
                />
              ) : undefined}
            </div>
          </div>
        ) : null;
      },
      [followerDataMap, navigateProfileOnPress, styles.headerButton]
    );

    return (
      <>
        <div
          className="ColumnNormal"
          style={{
            gap: isDesktop ? 30 : 20,
            minHeight: 300,
            paddingInline: isDesktop ? 0 : 14,
            opacity,
            transition: "opacity 0.2s ease-in",
          }}
        >
          {hasPaidMemberships ? (
            <StandardBorderedContainer
              containerStyles={styles.sectionContainer}
            >
              <div
                className="AlignedRow"
                style={{ gap: 10, paddingInline: 14 }}
              >
                <span className="sectionTitle">Account Billing</span>
                <Icon icon="ion:card" height={20} color={Colors.BLACK} />
              </div>
              <HorizontalDivider altMargin={14} />
              {hasPaymentFailedSubscription ? (
                <PaymentFailedButton
                  type="Subscriber"
                  onPress={() => setPaymentFailedModal(true)}
                  containerStyles={{ marginInline: 14 }}
                />
              ) : (
                <div
                  onClick={managePaymentOnPress}
                  className="AlignedRowSpacedSelect"
                  style={{ paddingInline: 14 }}
                >
                  <span className="bodyMedium">Manage Payment Method</span>
                  <Icon
                    icon="ion:chevron-right"
                    height={16}
                    color={Colors.GRAY2}
                  />
                </div>
              )}
            </StandardBorderedContainer>
          ) : null}
          <SearchBoxContainer
            placeholder={"Search"}
            onChange={(e) => handleSearch(e.target.value)}
            containerStyles={{ marginTop: 0 }}
          />
          <div className="ColumnNormal" style={{ gap: 20 }}>
            <FlatlistLoadMore
              fullList={followingAccountDataFollowing}
              currentList={followingItemsToShow}
              renderItem={(item: AccountData) => renderFollowingItem(item)}
              renderWhenEmpty={
                <EmptyStateButton
                  title="No Results"
                  description="Check for typos or search for something else"
                  icon={
                    <Icon icon="ion:search" height={40} color={Colors.GRAY1} />
                  }
                  containerStyles={{ paddingTop: 30 }}
                />
              }
              itemGap={20}
              isLoadingMore={false}
              showLoadMore={
                windowSize < followingAccountDataFollowing.length &&
                searchTerm === ""
              }
              loadMoreOnPress={() => setWindowSize(windowSize + 10)}
              hideShowingAllResults={true}
            />
          </div>
        </div>
        {helpModal ? (
          <HelpModal
            showModal={helpModal}
            setShowModal={setHelpModal}
            alternateSubtext="Text our hotline to change your payment method."
          />
        ) : null}
        {paymentFailedModal ? (
          <MembershipPaymentFailedButtonModal
            setIsVisible={(isVisible) => {
              setPaymentFailedModal(isVisible);
              setSinglePaymentFailedEntry(new Map());
            }}
            followerDataMap={
              singlePaymentFailedEntry.size > 0
                ? singlePaymentFailedEntry
                : followerDataMap
            }
            followingAccountDataFollowing={
              singlePaymentFailedEntry.size > 0
                ? followingAccountDataFollowing.filter((user) =>
                    singlePaymentFailedEntry.has(user.uid)
                  )
                : followingAccountDataFollowing
            }
          />
        ) : null}
      </>
    );
  }
);

export default ProfilePageUserSubscriptions;
