import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import { useTheme } from "../../../hooks/useTheme";
import { memo, useCallback, useMemo } from "react";
import { NameAndPhoneNumberInput } from "../../UserInfoContainers/NameAndPhoneNumberInput";
import { isDesktop } from "react-device-detect";
import ProfilePic from "../../ProfilePic";
import { AccountData, VerificationState } from "@markit/common.types";
import { onProfilePageNavigatePath } from "../../../utils/navigationUtils";
import { useLocation, useNavigate } from "react-router-dom";
import RecaptchaVerifierWrapper from "../../Wrappers/RecaptchaVerifierWrapper";

type SubscribeFlowInitialProps = {
  creatorData: AccountData;
  processing: boolean;
  tempFullName: string;
  setTempFullName: (tempFullName: string) => void;
  tempPhoneNumber: string;
  setTempPhoneNumber: (tempPhoneNumber: string) => void;
  verificationState: VerificationState;
  setVerificationState: (verificationState: VerificationState) => void;
  optedOut: boolean;
  setOptedOut: (optedOut: boolean) => void;
  optedOutCreator: boolean;
  setOptedOutCreator: (optedOutCreator: boolean) => void;
  verifyOnPress: () => void;
  setUid: (uid: string) => void;
};

const SubscribeFlowInitial = (props: SubscribeFlowInitialProps) => {
  const {
    creatorData,
    processing,
    tempFullName,
    setTempFullName,
    tempPhoneNumber,
    setTempPhoneNumber,
    verificationState,
    setVerificationState,
    optedOut,
    setOptedOut,
    optedOutCreator,
    setOptedOutCreator,
    verifyOnPress,
    setUid,
  } = props;
  const { theme } = useTheme();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const isUpgradeFlow = useMemo(() => pathname.includes("upgrade"), [pathname]);

  const navigateProfilePage = useCallback(
    () => onProfilePageNavigatePath(navigate, creatorData?.username),
    [navigate, creatorData]
  );

  return (
    <RecaptchaVerifierWrapper>
      <div
        className="ColumnNormal"
        style={{
          marginInline: "auto",
          paddingTop: 100,
          width: isDesktop ? 544 : "100%",
          gap: 30,
        }}
      >
        <div
          className="ColumnCentering"
          style={{ gap: 20, paddingInline: isDesktop ? 0 : 14 }}
        >
          <div className="ColumnCentering" style={{ gap: isDesktop ? 30 : 14 }}>
            <ProfilePic size={isDesktop ? 100 : 75} user={creatorData} />
            <div className="ColumnCentering" style={{ gap: 7 }}>
              <span
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: 500,
                  ...theme.PrimaryText,
                }}
              >
                {isUpgradeFlow
                  ? "Upgrade Subscription Plan"
                  : `Subscribe to ${creatorData.fullName}`}
              </span>
              <span className="bodySubtext" style={{ fontWeight: 500 }}>
                @{creatorData.username}
              </span>
            </div>
          </div>
          <div
            className="ColumnCentering"
            style={{ gap: 7, textAlign: "center", width: "90%" }}
          >
            {creatorData.bio ? (
              <span className="bodySubtext">{creatorData.bio}</span>
            ) : null}
          </div>
        </div>
        <div
          className="ColumnNormal"
          style={{
            gap: 20,
            width: isDesktop ? 340 : "100%",
            alignSelf: "center",
          }}
        >
          <NameAndPhoneNumberInput
            host={creatorData}
            verificationState={verificationState}
            setVerificationState={setVerificationState}
            optedOut={optedOut}
            setOptedOut={setOptedOut}
            optedOutCreator={optedOutCreator}
            setOptedOutCreator={setOptedOutCreator}
            tempFullName={tempFullName}
            setTempFullName={setTempFullName}
            tempPhoneNumber={tempPhoneNumber}
            setTempPhoneNumber={setTempPhoneNumber}
            setUid={setUid}
            processing={processing}
            callbackOnVerify={verifyOnPress}
          />
          <div
            className="AlignedRowSelect"
            style={{ justifyContent: "center", gap: 4 }}
            onClick={navigateProfilePage}
          >
            <span className="smallBodySubtext" style={{ fontWeight: 500 }}>
              Maybe later
            </span>
            <Icon icon="ion:chevron-right" height={11} color={Colors.GRAY1} />
          </div>
        </div>
      </div>
    </RecaptchaVerifierWrapper>
  );
};

export default memo(SubscribeFlowInitial);
