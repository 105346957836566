import { CSSProperties, useState } from "react";
import { useTheme } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";

type CustomTextFieldMultilineProps = {
  placeholder: string;
  value: string;
  onChange: (change: any) => void;
  maxLength?: number;
  disabled?: boolean;
  containerStyles?: CSSProperties;
};

// Multiline text input as an alternative to CustomTextField which is single line
const CustomTextFieldMultiline = (props: CustomTextFieldMultilineProps) => {
  const { placeholder, value, maxLength, disabled, onChange, containerStyles } =
    props;
  const { theme } = useTheme();

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <textarea
      value={value}
      placeholder={placeholder}
      inputMode="text"
      onChange={(e) => onChange(e)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className="RowNormal"
      disabled={disabled}
      maxLength={maxLength}
      style={{
        ...theme.PrimaryText,
        fontSize: 14,
        borderRadius: 12,
        backgroundColor: Colors.WHITE1,
        padding: 14,
        height: 120,
        resize: "none",
        border: "1px solid",
        outline: "none",
        borderColor: isFocused
          ? theme.FocusedTextFieldBorder.borderColor
          : theme.EditableTextFieldBorder.borderColor,
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: theme.DisabledTextFieldText.color,
        },
        "& input:-webkit-autofill": {
          WebkitBoxShadow: `0 0 0 1000px ${theme.SecondaryBG.backgroundColor} inset`,
          WebkitTextFillColor: theme.PrimaryText.color,
          borderRadius: 12,
          caretColor: theme.PrimaryText.color,
          WebkitBackgroundClip: "text !important",
        },
        ...containerStyles,
      }}
    />
  );
};

export default CustomTextFieldMultiline;
