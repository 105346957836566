import { useTheme } from "../../../hooks/useTheme";
import { memo } from "react";
import { isDesktop } from "react-device-detect";
import ProfilePic from "../../ProfilePic";
import {
  AccountData,
  FormAnswersV2,
  SavedFormQuestion,
} from "@markit/common.types";
import SingularQuestionInput from "../../UserInfoContainers/SingularQuestionInput";
import RectangleButton from "../../Buttons/RectangleButton";
import { Colors } from "../../../utils/colors";

type SubscribeFlowQuestionsProps = {
  creatorData: AccountData;
  profileQuestions: SavedFormQuestion[];
  processing: boolean;
  answers: FormAnswersV2;
  setAnswers: (answers: FormAnswersV2) => void;
  formErrorIds: string[];
  setFormErrorIds: (formErrorIds: string[]) => void;
  verifyOnPress: () => void;
};

const SubscribeFlowQuestions = (props: SubscribeFlowQuestionsProps) => {
  const {
    creatorData,
    profileQuestions,
    processing,
    answers,
    setAnswers,
    formErrorIds,
    setFormErrorIds,
    verifyOnPress,
  } = props;
  const { theme } = useTheme();

  return (
    <div
      className="ColumnNormal"
      style={{
        marginInline: "auto",
        paddingTop: 40,
        width: isDesktop ? 544 : "100%",
        gap: 20,
      }}
    >
      <div
        className="ColumnCentering"
        style={{ gap: isDesktop ? 30 : 14, paddingInline: isDesktop ? 0 : 14 }}
      >
        <ProfilePic size={isDesktop ? 100 : 75} user={creatorData} />
        <div className="ColumnCentering" style={{ gap: 7 }}>
          <span
            className="sectionTitle"
            style={{ fontWeight: 600, ...theme.PrimaryText }}
          >
            Just a few more questions
          </span>
          <span
            className="bodySubtext"
            style={{ textAlign: "center", paddingInline: isDesktop ? 0 : 14 }}
          >
            {creatorData.fullName} is asking for the following info to subscribe
          </span>
        </div>
      </div>
      <div
        className="ColumnNormal"
        style={{
          width: isDesktop ? 340 : "100%",
          alignSelf: "center",
          gap: 20,
        }}
      >
        <div style={{ paddingInline: isDesktop ? 0 : 24 }}>
          {profileQuestions.map((question) => {
            return (
              <SingularQuestionInput
                key={question.id}
                question={question}
                formQuestions={profileQuestions}
                answers={answers}
                setAnswers={setAnswers}
                formErrorIds={formErrorIds}
                setFormErrorIds={setFormErrorIds}
                disabled={false}
              />
            );
          })}
        </div>
        <div style={{ paddingInline: isDesktop ? 0 : 24 }}>
          <RectangleButton
            buttonLabel="Continue"
            onPress={verifyOnPress}
            containerStyles={{
              paddingInline: 40,
              paddingBlock: 14,
              borderRadius: 12,
              backgroundColor: Colors.RED2,
              color: Colors.WHITE,
            }}
            loading={processing}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(SubscribeFlowQuestions);
