import { Colors } from "../../utils/colors";
import RectangleButton from "../Buttons/RectangleButton";
import LoginWelcomeBody from "./LoginWelcomeBody";

type LoginOnboardingAccountTypeProps = {
  creatorOnPress: () => void;
  subscriberOnPress: () => void;
};

const LoginOnboardingAccountType = (props: LoginOnboardingAccountTypeProps) => {
  const { creatorOnPress, subscriberOnPress } = props;

  return (
    <LoginWelcomeBody
      title="Sign up as creator or subscriber?"
      subtext="This helps us tailor your Markit experience to fit you"
    >
      <div className="ColumnNormal" style={{ gap: 10, width: "100%" }}>
        <RectangleButton
          buttonLabel={<span>Continue as Creator</span>}
          onPress={creatorOnPress}
          altPaddingVert={14}
          altColor={Colors.BLACK}
          altTextColor={Colors.WHITE}
        />
        <RectangleButton
          buttonLabel={<span>Continue as Subscriber</span>}
          onPress={subscriberOnPress}
          altColor={Colors.GRAY6}
          altTextColor={Colors.BLACK}
          altPaddingVert={14}
          containerStyles={{ border: `0.5px solid ${Colors.GRAY2}` }}
        />
      </div>
    </LoginWelcomeBody>
  );
};

export default LoginOnboardingAccountType;
