import "../../../css/GlobalStyles.css";
import { useCallback } from "react";
import { PeoplePanel } from "../../../components/HomePage/PeoplePanel";
import {
  greetingMessage,
  hasSubscriptionUnlockedAdvancedData,
} from "@markit/common.utils";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { useNavigate } from "../../../hooks/useNavigate";
import MarkitPlusActionButton from "../../../components/Subscription/MarkitPlusActionButton";
import { ImportantAnnouncementModal } from "../../../components/DisplayModal/ImportantAnnouncementModal";
import CreatorModePanelWrapper from "../../../components/CreatorDashboard/CreatorModePanelWrapper";
import FatHeaderActionButton from "../../../components/Buttons/FatHeaderActionButton";
import { NavigationId } from "../../../navigation/AppParamList";
import { notificationActions } from "../../../redux/slices/notificationSlice";

const HomePanel = () => {
  const { accountData } = useSelector(getAccountState).account;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateNewUpload = useCallback(() => {
    if (!hasSubscriptionUnlockedAdvancedData(accountData)) {
      dispatch(notificationActions.setMarkitPlusModal(true));
      return;
    }
    navigate("/import");
  }, [accountData, navigate, dispatch]);

  return (
    <CreatorModePanelWrapper
      title={greetingMessage()}
      headerRight={
        <div className="AlignedRow" style={{ gap: 14 }}>
          <FatHeaderActionButton
            title="Integrations"
            onPress={() => navigate(NavigationId.HOME_INTEGRATIONS)}
          />
          <MarkitPlusActionButton mainAction />
        </div>
      }
    >
      <div
        className="CreatorPanelContainer"
        style={{ padding: 30, paddingBottom: 100 }}
      >
        <PeoplePanel importOnPress={navigateNewUpload} />
      </div>
      <ImportantAnnouncementModal />
    </CreatorModePanelWrapper>
  );
};

export default HomePanel;
