import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import { useState, useMemo } from "react";
import PopupModalContainer from "../../../Containers/PopupModalContainer";
import RectangleButton from "../../../Buttons/RectangleButton";
import {
  MARKIT_TEXTING_CREDITS,
  calculateTextMessageCreditsEstimate,
} from "@markit/common.utils";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import { Event } from "@markit/common.types";
import TextCreditsNotice from "./TextCreditsNotice";

type TextCreditsTagModalProps = {
  message: string;
  mediaUrl: string | undefined;
  event?: Event | undefined;
  showCreditsNotice?: boolean;
};

const TextCreditsTagModal = (props: TextCreditsTagModalProps) => {
  const { message, mediaUrl, event, showCreditsNotice } = props;
  const [showModal, setShowModal] = useState(false);

  const styles = {
    bodyMedium: { fontSize: 14, fontWeight: 500, color: Colors.BLACK },
    subtext: { fontSize: 12, color: Colors.GRAY2 },
  };

  const numCredits = useMemo(
    () => calculateTextMessageCreditsEstimate(message, mediaUrl, event),
    [event, mediaUrl, message]
  );

  const creditsLabelColor = useMemo(
    () => (numCredits >= 5 && showCreditsNotice ? Colors.BLUE5 : Colors.BLACK),
    [numCredits, showCreditsNotice]
  );

  return (
    <div style={{ position: "relative" }}>
      <div
        className="AlignedRowSelect"
        onClick={() => setShowModal(true)}
        style={{
          flexWrap: "wrap",
          color: creditsLabelColor,
          gap: 4,
        }}
      >
        <span style={{ fontSize: 14 }}>
          {numCredits} Credit{numCredits !== 1 ? "s" : ""} Applied
        </span>
        <Icon
          icon="ion:chevron-right"
          height={14}
          style={{ color: creditsLabelColor }}
        />
      </div>
      {showModal ? (
        <PopupModalContainer
          headerComp={
            <div className="ColumnNormal" style={{ gap: 10 }}>
              <Icon icon="ion:prism" height={35} />
              <span style={{ fontSize: 16, fontWeight: 500 }}>
                Credits Applied
              </span>
            </div>
          }
          altPadding={0}
          valueComp={
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <div
                className="ColumnNormal"
                style={{ gap: 14, paddingInline: 14 }}
              >
                <TextCreditsNotice numCredits={numCredits} />
                <div className="ColumnNormal" style={{ gap: 7 }}>
                  <span style={styles.bodyMedium}>
                    Credits Applied: {numCredits}
                  </span>
                  <span className="smallBodySubtext">
                    *Estimate may vary depending on the usage of plugins and
                    recipients that require compliance message.
                  </span>
                </div>
                <div className="ColumnNormal" style={{ gap: 7 }}>
                  <span style={styles.bodyMedium}>What are credits?</span>
                  <span style={styles.subtext}>
                    Credits are the cost per SMS or MMS for a single recipient.
                    <br />
                    If single credit message, 1 credit is 160 characters.
                    <br />
                    If multiple credit message, 1 credit is 153 characters.
                  </span>
                </div>
                <div className="ColumnNormal" style={{ gap: 7 }}>
                  <span style={styles.bodyMedium}>Emojis</span>
                  <span style={styles.subtext}>
                    Inclusion of any emojis in your message reduces the number
                    of characters allowed per credit.
                    <br />
                    If single credit message, 1 credit is 70 characters.
                    <br />
                    If multiple credit message, 1 credit is 67 characters.
                  </span>
                </div>
                <div className="ColumnNormal" style={{ gap: 7 }}>
                  <span style={styles.bodyMedium}>Attaching Media</span>
                  <span style={styles.subtext}>
                    Attaching an image to your message will automatically add 4
                    credits to your message credit count.
                  </span>
                </div>
                <div className="ColumnNormal" style={{ gap: 7 }}>
                  <span style={styles.bodyMedium}>Plugins</span>
                  <span style={styles.subtext}>
                    Plugins may change the number of characters used depending
                    on the data they reference. We make an estimation for you (7
                    characters avg. for /First Name, and 15 character avg. for
                    /Full Name).
                  </span>
                </div>
                <div className="ColumnNormal" style={{ gap: 7 }}>
                  <span style={styles.bodyMedium}>Attaching Links</span>
                  <span style={styles.subtext}>
                    7 characters are added to any attached Markit links to
                    account for the unique recipient tracking IDs.
                  </span>
                </div>
              </div>
              <HorizontalDivider />
              <div style={{ paddingInline: 14 }}>
                <RectangleButton
                  buttonLabel="Learn More"
                  onPress={() => window.open(MARKIT_TEXTING_CREDITS)}
                  altTextColor={Colors.BLACK}
                  altColor={Colors.GRAY6}
                />
              </div>
            </div>
          }
          noExit
          closeOnOutsidePress
          closeModal={() => setShowModal(false)}
        />
      ) : null}
    </div>
  );
};

export default TextCreditsTagModal;
