import { MARKIT_FREE_CREATOR_NUMBER } from "@markit/common.utils";
import { API } from "../API";
import { formatPhoneNumber } from "./FormatPhoneNumber";
import {
  AccountData,
  FollowSourceType,
  FollowType,
  FormAnswersV2,
} from "@markit/common.types";
import { isDesktop } from "react-device-detect";

export const followUser = async ({
  userPhone,
  userFullName,
  userToFollow,
  uid,
  answers,
  followType,
  disableTriggerMessage,
}: {
  userPhone: string;
  userFullName: string;
  userToFollow: AccountData;
  uid: string;
  answers: FormAnswersV2 | undefined;
  followType: FollowType;
  disableTriggerMessage?: boolean;
}): Promise<boolean> => {
  const followSourceType = isDesktop
    ? FollowSourceType.DESKTOP_WEB
    : FollowSourceType.MOBILE_WEB;
  const success = await (uid
    ? API.text.sendFollowUserMessage({
        uid: uid,
        fullName: userFullName,
        userToFollowUid: userToFollow.uid,
        followSourceType: followSourceType,
        followType: followType,
        answers: answers,
        disableTriggerMessage: disableTriggerMessage,
      })
    : API.text.sendFollowUserMessage({
        toPhoneNumber: userPhone,
        fullName: userFullName,
        userToFollowUid: userToFollow.uid,
        followSourceType: followSourceType,
        followType: followType,
        answers: answers,
        disableTriggerMessage: disableTriggerMessage,
      })
  )
    .then((response) => {
      const { alreadyFollowing, optedOut, isFreeCreator } = response;
      if (optedOut) {
        alert(
          "You are currently opted out from texts from " +
            (isFreeCreator
              ? "the Markit free creator phone"
              : userToFollow.fullName) +
            ". Text START to " +
            (isFreeCreator
              ? formatPhoneNumber(MARKIT_FREE_CREATOR_NUMBER)
              : formatPhoneNumber(userToFollow.customer.phone)) +
            " if you want to follow"
        );
        return false;
      } else if (alreadyFollowing) {
        alert("You are already subscribed to this profile!");
        return false;
      }
      return true;
    })
    .catch((err: any) => {
      console.log(err.message);
      alert(
        `Failed following the user (Error: ${err.message}). Try again later.`
      );
      return false;
    });
  return success;
};

export const unfollowUser = async (
  userId: string,
  userToUnfollowUid: string,
  manuallyRemoved: boolean
) => {
  try {
    await API.user.unfollowUser({
      uid: userId,
      userToUnfollowUid: userToUnfollowUid,
      manuallyRemoved: manuallyRemoved,
    });
  } catch (e: any) {
    console.error("Error unfollowing the user: " + e.message);
  }
};

export const readdFollower = async (userId: string, creatorUid: string) => {
  try {
    await API.user.readdFollower({
      uid: userId,
      creatorUid: creatorUid,
    });
  } catch (e: any) {
    console.error("Error readding follower: " + e.message);
  }
};
