import { Icon } from "@iconify/react";
import PopupModalContainer from "../../../Containers/PopupModalContainer";
import {
  MARKIT_HOTLINE_NUMBER,
  MARKIT_TEXTING_POLICIES,
} from "@markit/common.utils";
import { Colors } from "../../../../utils/colors";
import { formatPhoneNumber } from "../../../../utils/FormatPhoneNumber";

type SendRateModalProps = {
  modalVisible: boolean;
  setModalVisible: (modalVisible: boolean) => void;
  sendRate: number;
};

const SendRateModal = (props: SendRateModalProps) => {
  const { modalVisible, setModalVisible, sendRate } = props;

  return modalVisible ? (
    <PopupModalContainer
      headerComp={
        <div className="ColumnNormal" style={{ gap: 14 }}>
          <Icon icon="ion:paper-plane" height={35} />
          <span className="sectionTitle">Send Rate</span>
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="bodyMedium">What is send rate?</span>
            <span className="bodySubtext">
              Send rate shows the percentage of text messages sent successfully
              to your selected recipients.
            </span>
          </div>
          {sendRate === -1 ? (
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <span className="bodyMedium">
                Your messages are still being sent.
              </span>
              <span className="bodySubtext">
                Your send rate is currently being calculated, as some messages
                are still being delivered. When all messages have been
                delivered, your send rate will be displayed.
              </span>
            </div>
          ) : null}
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="bodyMedium">Low send rate?</span>
            <span className="bodySubtext">
              A low send rate can be a result of messages being filtered and
              undelivered based on phone carrier guidelines.
            </span>
            {sendRate !== -1 && sendRate < 100 ? (
              <>
                <span className="bodySubtext">
                  You may have used a term(s) in your message that was not
                  caught in our banned terms list. You can learn more about our
                  texting guidelines here:{" "}
                  <span
                    onClick={() => {}}
                    style={{
                      textDecorationLine: "underline",
                      color: Colors.BLUE5,
                    }}
                  >
                    {MARKIT_TEXTING_POLICIES}
                  </span>
                </span>
                <span className="bodySubtext">
                  Please contact our hotline to report a low send rate.
                </span>
              </>
            ) : null}
          </div>
        </div>
      }
      valueComp={
        <div
          className="AlignedRow"
          style={{
            padding: 14,
            backgroundColor: Colors.GRAY6,
            borderRadius: 12,
            gap: 7,
            justifyContent: "center",
          }}
        >
          <span className="bodyMedium">Contact Hotline</span>{" "}
          <span className="bodySubtext">
            {formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}
          </span>
        </div>
      }
      noExit
      closeOnOutsidePress
      closeModal={() => setModalVisible(false)}
    />
  ) : null;
};

export default SendRateModal;
