import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { EventDashboardExternalLinkCopy } from "../Links/CopyCustomLinkify";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";

const ShareMarkitNumber = () => {
  const markitPhone =
    useSelector(getAccountState).account.accountData.customer.phone;

  const styles = {
    container: {
      paddingBlock: 14,
      borderRadius: 20,
      backgroundColor: Colors.WHITE,
    },
  };

  return (
    <StandardBorderedContainer containerStyles={styles.container}>
      <div className="ColumnNormal" style={{ gap: 4, paddingInline: 14 }}>
        <div className="AlignedRow" style={{ gap: 10 }}>
          <span className="sectionTitle">Share Number</span>
          <Icon icon="ion:call" height={20} />
        </div>
        <span className="smallBodySubtext">
          Share your unique Markit number. When people text your number they can
          join your audience.
        </span>
      </div>
      <HorizontalDivider altMargin={14} />
      <div className="ColumnNormal" style={{ paddingInline: 14 }}>
        <EventDashboardExternalLinkCopy
          copyText={formatPhoneNumber(markitPhone)}
          containerStyles={{
            display: "flex",
            padding: 14,
            backgroundColor: Colors.GRAY6,
            borderColor: Colors.GRAY1,
            borderWidth: 0.5,
          }}
          altTextColor={Colors.BLACK}
        />
      </div>
    </StandardBorderedContainer>
  );
};

export default ShareMarkitNumber;
