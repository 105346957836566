import "../../css/Buttons/RectangleButtonStyles.css";
import React from "react";
import { ThemeStyle } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
// import { Button } from "@mui/material";
import { CircularProgress } from "@mui/material";

type RectangleButtonProps = {
  buttonLabel: React.ReactNode;
  onPress: () => void;
  altColor?: string;
  altTextColor?: string;
  altPaddingVert?: number | string;
  altPaddingHorz?: number | string;
  altBorderRadius?: number | string;
  theme?: ThemeStyle;
  disabled?: boolean;
  width?: number;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  loading?: boolean;
  containerStyles?: React.CSSProperties;
};

const RectangleButton = (props: RectangleButtonProps) => {
  const {
    buttonLabel,
    onPress,
    altColor,
    altTextColor,
    altPaddingVert,
    altPaddingHorz,
    altBorderRadius,
    theme,
    disabled,
    width,
    iconLeft,
    iconRight,
    loading,
    containerStyles,
  } = props;

  return (
    <button
      onClick={onPress}
      disabled={disabled || loading}
      className="RectangleButton"
      style={{
        ...(theme
          ? loading
            ? theme.ButtonBG
            : disabled
            ? theme.DisabledButtonBG
            : theme.ButtonBG
          : disabled && !loading
          ? { backgroundColor: Colors.GRAY6 }
          : altColor
          ? { backgroundColor: altColor }
          : { backgroundColor: Colors.BLUE5 }),
        cursor: "pointer",
        pointerEvents: disabled || loading ? "none" : "all",
        paddingBlock: altPaddingVert || 10,
        paddingInline: altPaddingHorz || 0,
        borderRadius: altBorderRadius || 12,
        width: width || null,
        transition: "opacity 0.2s ease",
        ...containerStyles,
      }}
      onMouseEnter={(e) => (e.currentTarget.style.opacity = "0.7")}
      onMouseLeave={(e) => (e.currentTarget.style.opacity = "1")}
    >
      {loading ? (
        <CircularProgress
          style={{
            ...(theme
              ? theme.ButtonText
              : altTextColor
              ? { color: altTextColor }
              : { color: Colors.WHITE }),
            alignSelf: "center",
          }}
          size={17}
        />
      ) : (
        <>
          {iconLeft || null}
          <p
            style={{
              ...(theme
                ? disabled
                  ? theme.DisabledButtonText
                  : theme.ButtonText
                : disabled
                ? { color: Colors.GRAY2 }
                : altTextColor
                ? { color: altTextColor }
                : { color: Colors.WHITE }),
              fontWeight: "600",
              fontSize: 14,
              cursor: "pointer",
            }}
          >
            {buttonLabel}
          </p>
          {iconRight || null}
        </>
      )}
    </button>
  );
};

export default RectangleButton;
