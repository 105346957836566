import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { firebaseAuth, onAuthStateChanged, onSnapshot } from "../firebase";
import {
  getUserData,
  getUserDataByUsername,
  getUserRef,
} from "../utils/FirebaseUtils";
import {
  accountActions,
  fetchSavedFormQuestions,
  getAccountState,
  fetchAudienceLists,
  fetchReadyForPayment,
  fetchAllUserFollowersData,
  createInitialFavoritesList,
} from "../redux/slices/accountSlice";
import { fetchCreatorEvents } from "../redux/slices/eventSlice";
import { fetchCurrUserWishList } from "../redux/slices/wishMarkSlice";
import useAsyncOnMount from "./useAsyncEffectOnMount";
import { loadInitialCampaigns } from "../redux/slices/campaignSlice";
import { createSubscribeTriggerCampaign } from "../utils/campaignUtils";

export const useInitialAppLoad = () => {
  const { appInitialized } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const unsubscribeRef = useRef<(() => void) | void>();

  // handle all initial app loading
  useAsyncOnMount(async () => {
    const unsubscribeAuth = onAuthStateChanged(firebaseAuth, async (user) => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
        unsubscribeRef.current = undefined;
      }
      if (user) {
        // First check if the current user is an admin before attempting override
        const userData = await getUserData(user.uid);
        const isAdmin = userData?.isAdmin || false;

        // Check for admin override of user ID
        const urlParams = new URLSearchParams(window.location.search);
        const overrideUsername = urlParams.get("username");
        let targetUid = user.uid;

        if (isAdmin && overrideUsername) {
          const existingUserData = await getUserDataByUsername(
            overrideUsername
          );
          if (existingUserData) {
            targetUid = existingUserData.uid;
          }
        }

        const unsubscribe = onSnapshot(
          getUserRef(targetUid),
          async (doc) => {
            const userData = doc.data();
            if (userData) {
              const start = new Date().getTime();
              dispatch(accountActions.login(userData));
              if (!appInitialized) {
                await Promise.all([
                  dispatch(fetchAllUserFollowersData(userData.uid)),
                  dispatch(fetchCreatorEvents(userData.uid)),
                  dispatch(fetchCurrUserWishList(userData.uid)),
                  dispatch(fetchReadyForPayment(userData.uid)),
                  dispatch(fetchSavedFormQuestions(userData.uid)),
                  dispatch(fetchAudienceLists(userData.uid)),
                  dispatch(loadInitialCampaigns(userData.uid)),
                  dispatch(createInitialFavoritesList(userData.uid)),
                  createSubscribeTriggerCampaign(userData),
                ]);
                dispatch(accountActions.setAppInitialized(true));
              }
              const end = new Date().getTime();
              console.log(
                "Total fetch time (app initialization): " + (end - start) / 1000
              );
            }
          },
          (error: any) => {
            console.error(error);
          }
        );
        unsubscribeRef.current = unsubscribe;
      }
    });

    return () => {
      unsubscribeAuth();
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
        unsubscribeRef.current = undefined;
      }
    };
  });

  return undefined;
};
