import { CSSProperties } from "react";
import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import { SUBSCRIBED_CAMPAIGN_NAME } from "@markit/common.utils";

type PresetAutomationTriggerIconProps = {
  campaignTitle: string;
  iconSize?: number;
  containerStyles?: CSSProperties;
};

// Helper icon used in multiple places in the automation campaign items
const PresetAutomationTriggerIcon = (
  props: PresetAutomationTriggerIconProps
) => {
  const { campaignTitle, iconSize, containerStyles } = props;

  return (
    <div
      className="Centering"
      style={{
        padding: 7,
        borderRadius: 8,
        backgroundColor: Colors.WHITE1,
        ...containerStyles,
      }}
    >
      <Icon
        icon={
          campaignTitle === SUBSCRIBED_CAMPAIGN_NAME
            ? "mdi:cursor-default-click"
            : "ion:chatbox-ellipses"
        }
        height={iconSize ?? 17}
      />
    </div>
  );
};

export default PresetAutomationTriggerIcon;
