import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { memo, useCallback, useContext, useRef, useState } from "react";
import { AccountData } from "@markit/common.types";
import { isValidUserName } from "../../utils/stringUtils";
import {
  getUserDataByUsername,
  getUsernameSnap,
} from "../../utils/FirebaseUtils";
import { useSelector, useDispatch } from "react-redux";
import {
  changeBio,
  changeFullName,
  changeInstagram,
  changeLinkedin,
  changeProfilePicURL,
  changeSpotify,
  changeTiktok,
  changeTwitter,
  changeUsername,
  getAccountState,
} from "../../redux/slices/accountSlice";
import { checkValidSocialMediaLink } from "@markit/common.utils";
import { MixpanelContext } from "../../context/AnalyticsService";
import AlertContainer from "../../components/Containers/AlertContainer";
import { useOnMount } from "../../hooks/useOnMount";
import EditProfileDetails, {
  EditProfileError,
} from "../../components/ProfilePage/EditProfileDetails";
import LoginWelcomeBody from "../../components/Login/LoginWelcomeBody";

type LoginOnboardingProfileProps = {
  accountSettings: AccountData;
  updateAccountSettings: (accountSettings: Partial<AccountData>) => void;
  loading: boolean;
  onFinishSetUp: () => void;
};

const LoginOnboardingProfile = (props: LoginOnboardingProfileProps) => {
  const { accountSettings, updateAccountSettings, loading, onFinishSetUp } =
    props;
  const { accountData } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);
  const { theme } = useTheme();
  const [showError, setShowError] = useState<EditProfileError>({
    type: "",
    message: "",
  });
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useOnMount(() => {
    // Default the username to empty since it starts as the random generated
    updateAccountSettings({ username: "" });
  });

  const finishSetupOnPress = useCallback(async () => {
    // check all inputs first
    const usernameLower = accountSettings.username.toLowerCase();

    if (accountSettings.profilePicURL === "" && accountData.inCreatorMode) {
      setShowError({
        type: "profilePic",
        message: "You must choose a profile picture",
      });
      return;
    }
    if (accountSettings.fullName === "") {
      setShowError({
        type: "fullName",
        message: "Full Name cannot be empty.",
      });
      return;
    }
    if (usernameLower === "") {
      setShowError({
        type: "username",
        message: "Username cannot be empty.",
      });
      return;
    }
    if (!isValidUserName(usernameLower)) {
      setShowError({
        type: "username",
        message:
          "*Username can only consist of alphanumeric characters, 3-30 characters in length, no spaces",
      });
      return;
    }
    const usernameSnap = await getUsernameSnap(usernameLower);
    if (usernameSnap.exists()) {
      const usernameData = usernameSnap.data();
      const existingUserData = await getUserDataByUsername(
        usernameData.username
      );
      if (existingUserData && existingUserData.uid !== accountData.uid) {
        setShowError({
          type: "username",
          message: "Username already exists. Try another.",
        });
        return;
      }
    }

    // check socials
    if (accountSettings.instagram !== "") {
      const valid = checkValidSocialMediaLink(
        "instagram",
        accountSettings.instagram
      );
      if (!valid) {
        setAlertText({ heading: "Invalid instagram url", subHeading: "" });
        return;
      } else if (accountSettings.instagram.length > 200) {
        setAlertText({
          heading: "Instagram is limited to 200 characters",
          subHeading: "",
        });
        return;
      }
    }

    if (accountSettings.linkedin !== "") {
      const valid = checkValidSocialMediaLink(
        "linkedin",
        accountSettings.linkedin
      );
      if (!valid) {
        setAlertText({ heading: "Invalid linkedin url", subHeading: "" });
        return;
      } else if (accountSettings.linkedin.length > 200) {
        setAlertText({
          heading: "Linkedin is limited to 200 characters",
          subHeading: "",
        });
        return;
      }
    }

    if (accountSettings.twitter !== "") {
      const valid = checkValidSocialMediaLink(
        "twitter",
        accountSettings.twitter
      );
      if (!valid) {
        setAlertText({ heading: "Invalid twitter url", subHeading: "" });
        return;
      } else if (accountSettings.twitter.length > 200) {
        setAlertText({
          heading: "Twitter is limited to 200 characters",
          subHeading: "",
        });
        return;
      }
    }

    if (accountSettings.tiktok !== "") {
      const valid = checkValidSocialMediaLink("tiktok", accountSettings.tiktok);
      if (!valid) {
        setAlertText({ heading: "Invalid tiktok url", subHeading: "" });
        return;
      } else if (accountSettings.tiktok.length > 200) {
        setAlertText({
          heading: "Tiktok is limited to 200 characters",
          subHeading: "",
        });
        return;
      }
    }

    if (accountSettings.spotify !== "") {
      const valid = checkValidSocialMediaLink(
        "spotify",
        accountSettings.spotify
      );
      if (!valid) {
        setAlertText({ heading: "Invalid spotify url", subHeading: "" });
        return;
      } else if (accountSettings.spotify.length > 200) {
        setAlertText({
          heading: "Spotify is limited to 200 characters",
          subHeading: "",
        });
        return;
      }
    }

    // execute all the set changes
    dispatch(
      changeUsername(
        accountData.uid,
        accountData.username,
        usernameLower.trim()
      )
    );
    dispatch(changeFullName(accountData.uid, accountSettings.fullName.trim()));
    if (accountSettings.profilePicURL) {
      dispatch(
        changeProfilePicURL(accountSettings.profilePicURL, accountData.uid)
      );
    }
    // set bio
    if (accountSettings.bio !== "") {
      dispatch(changeBio(accountData.uid, accountSettings.bio.trim()));
    }

    // Set socials
    if (accountSettings.instagram !== "") {
      dispatch(
        changeInstagram(accountData.uid, accountSettings.instagram.trim())
      );
      mixpanel.track("Set Instagram Account Details", {
        instagram: accountSettings.instagram,
        type: "sign-up",
        from: "webapp",
      });
    }

    if (accountSettings.linkedin !== "") {
      dispatch(
        changeLinkedin(accountData.uid, accountSettings.linkedin.trim())
      );
      mixpanel.track("Set LinkedIn Account Details", {
        linkedin: accountSettings.linkedin,
        type: "sign-up",
        from: "webapp",
      });
    }

    if (accountSettings.twitter !== "") {
      dispatch(changeTwitter(accountData.uid, accountSettings.twitter.trim()));
      mixpanel.track("Set Twitter Account Details", {
        twitter: accountSettings.twitter,
        type: "sign-up",
        from: "webapp",
      });
    }

    if (accountSettings.tiktok !== "") {
      dispatch(changeTiktok(accountData.uid, accountSettings.tiktok.trim()));
      mixpanel.track("Set TikTok Account Details", {
        tiktok: accountSettings.tiktok,
        type: "sign-up",
        from: "webapp",
      });
    }

    if (accountSettings.spotify !== "") {
      dispatch(changeSpotify(accountData.uid, accountSettings.spotify.trim()));
      mixpanel.track("Set Spotify Account Details", {
        spotify: accountSettings.spotify,
        type: "sign-up",
        from: "webapp",
      });
    }

    mixpanel.track("Webapp: Entered Account Details", {
      distinct_id: accountData.uid,
      fullName: accountSettings.fullName,
      username: usernameLower,
    });
    mixpanel.identify(accountData.uid);
    mixpanel.people.set({
      $name: accountSettings.fullName,
    });

    onFinishSetUp();
  }, [
    accountSettings.username,
    accountSettings.profilePicURL,
    accountSettings.fullName,
    accountSettings.instagram,
    accountSettings.linkedin,
    accountSettings.twitter,
    accountSettings.tiktok,
    accountSettings.spotify,
    accountSettings.bio,
    accountData.inCreatorMode,
    accountData.uid,
    accountData.username,
    dispatch,
    mixpanel,
    onFinishSetUp,
  ]);

  return (
    <>
      <LoginWelcomeBody
        title="Set up your profile"
        subtext={`Customize your profile so ${
          accountSettings.inCreatorMode ? "your subscribers" : "people"
        } know it's you`}
      >
        <div className="ColumnNormal" style={{ gap: 24 }}>
          <EditProfileDetails
            accountSettings={accountSettings}
            updateAccountSettings={updateAccountSettings}
            showError={showError}
            setShowError={setShowError}
            loading={loading}
            isOnboarding
          />
          <RectangleButton
            buttonLabel={<span>Continue</span>}
            onPress={finishSetupOnPress}
            theme={theme}
            altPaddingVert={12}
            disabled={loading || showError.type !== ""}
            loading={loading}
          />
        </div>
      </LoginWelcomeBody>
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        theme={theme}
        closeModal={() => {
          setAlertText({ heading: "", subHeading: "" });
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
    </>
  );
};

export default memo(LoginOnboardingProfile);
