import React, { useCallback, useMemo, useState } from "react";
import "../../../css/CreateEvent.css";
import "../../../css/GlobalStyles.css";
import "../../../css/FullEvent/EventDashboard.css";
import { Event, TicketV2, NotificationType } from "@markit/common.types";
import { Icon } from "@iconify/react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Colors } from "../../../utils/colors";
import { RWebShare } from "react-web-share";
import PopupModalContainer from "../../Containers/PopupModalContainer";
import EmptyStateButton from "../../Buttons/EmptyStateButton";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { isEventExternalLink } from "@markit/common.utils";
import { useDispatch } from "react-redux";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import AlertContainer from "../../Containers/AlertContainer";
import { useNavigate } from "react-router-dom";
import { TrackingLinkData } from "../../../hooks/useLoadTrackingLinksList";
import { showNotificationBanner } from "../../../utils/notificationUtils";

interface ShareEventModalProps {
  event: Event;
  userTicket?: TicketV2;
  revenuePercentSplit: number;
  hideTrackingLinks: boolean;
  closeShareModal: () => void;
  isTrackingLinksView: boolean;
  setIsTrackingLinksView: (trackingLinksView: boolean) => void;
  trackingLinkData: TrackingLinkData[];
  canLoadMoreTrackingLinks: boolean;
  fetchMoreTrackingLinks: () => void;
  setTrackingLinkModalVisible: (trackingLinkModalVisible: boolean) => void;
}

const ShareEventModal = (props: ShareEventModalProps) => {
  const {
    event,
    userTicket,
    revenuePercentSplit,
    hideTrackingLinks,
    closeShareModal,
    isTrackingLinksView,
    setIsTrackingLinksView,
    trackingLinkData,
    canLoadMoreTrackingLinks,
    fetchMoreTrackingLinks,
    setTrackingLinkModalVisible,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownSelect, setDropdownSelect] = useState(
    isTrackingLinksView ? 1 : 0
  );

  const [alertText, setAlertText] = useState("");

  const generateURL = useCallback(
    (linkMappingId?: string) => {
      return (
        "https://markitai.com/e/" +
        event.id +
        (revenuePercentSplit !== 0
          ? "?promoter=" +
            userTicket?.id +
            (linkMappingId ? "&l=" + linkMappingId : "")
          : linkMappingId
          ? "?l=" + linkMappingId
          : "")
      );
    },
    [event.id, revenuePercentSplit, userTicket?.id]
  );

  const renderLinkSharingBar = (
    title: string,
    linkMappingId?: string,
    last?: boolean
  ) => {
    return (
      <div
        style={{
          backgroundColor: Colors.GRAY6,
          borderRadius: 12,
          padding: 14,
          marginBottom: last ? 0 : 10,
        }}
      >
        <div style={{ fontSize: 14, marginBottom: 14 }}>{title}</div>
        <div className="ShareEventModalLinks">
          <RWebShare
            data={{
              url: generateURL(linkMappingId),
            }}
          >
            <div>
              <div className="ShareEventModalLinkIcon">
                <Icon icon="ion:share" height={26} />
              </div>
              <div style={{ fontSize: 11, paddingTop: 7 }}>Share to...</div>
            </div>
          </RWebShare>
          <div>
            <div
              className="ShareEventModalLinkIcon"
              onClick={() => onCopyClick(linkMappingId)}
            >
              <Icon icon="ep:link" height={26} />
            </div>
            <div style={{ fontSize: 11, paddingTop: 7 }}>Copy Link</div>
          </div>
          <div>
            <div
              className="ShareEventModalLinkIcon"
              onClick={() => onMessagesClick(linkMappingId)}
            >
              <Icon icon="ion:chatbubble-outline" height={22} />
            </div>
            <div style={{ fontSize: 11, paddingTop: 7 }}>Messages</div>
          </div>
          <div>
            <div className="ShareEventModalLinkIcon" onClick={onInstagramClick}>
              <Icon icon="mdi:instagram" height={24} />
            </div>
            <div style={{ fontSize: 11, paddingTop: 7 }}>Instagram</div>
          </div>
          <div>
            <div
              className="ShareEventModalLinkIcon"
              onClick={() => onQRCodeClick(linkMappingId)}
            >
              <Icon icon="ion:qr-code" height={22} />
            </div>
            <div style={{ fontSize: 11, paddingTop: 7 }}>QR Code</div>
          </div>
        </div>
      </div>
    );
  };

  const onCopyClick = useCallback(
    (linkMappingId?: string) => {
      const el = document.createElement("input");
      el.value = generateURL(linkMappingId);
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      showNotificationBanner(dispatch, "Copied!", NotificationType.AFFIRMATIVE);
    },
    [dispatch, generateURL]
  );

  const onMessagesClick = useCallback(
    (linkMappingId?: string) => {
      const message = generateURL(linkMappingId);
      const url = `sms:&body=${encodeURIComponent(message)}`;
      window.location.href = url;
    },
    [generateURL]
  );

  const onInstagramClick = useCallback(async () => {
    setAlertText("Coming soon...");
  }, []);

  const onQRCodeClick = useCallback(
    async (linkMappingId?: string) => {
      navigate(
        "/e/" +
          event.id +
          "/eventQRCode" +
          (revenuePercentSplit !== 0 ? "?promoter=" + userTicket?.id : ""),
        {
          state: { eventId: event.id, linkMappingId: linkMappingId },
        }
      );
    },
    [event.id, navigate, revenuePercentSplit, userTicket?.id]
  );

  const newTrackingLinkOnPress = useCallback(() => {
    closeShareModal();
    setIsTrackingLinksView(false);
    setTrackingLinkModalVisible(true);
  }, [closeShareModal, setIsTrackingLinksView, setTrackingLinkModalVisible]);

  const renderEmptyTrackingLink = useMemo(
    () => (
      <div style={{ paddingBlock: 100, width: 200, margin: "0 auto" }}>
        <EmptyStateButton
          title={"No Tracking Links"}
          btnText="New Tracking Link"
          description={
            "Gain insights into opens and conversions of your different promotion channels."
          }
          icon={
            <Icon
              icon={"ion:analytics"}
              height={49}
              style={{ color: Colors.GRAY1 }}
            />
          }
          iconBox={73}
          onPress={newTrackingLinkOnPress}
        />
      </div>
    ),
    [newTrackingLinkOnPress]
  );

  return (
    <PopupModalContainer
      headerComp={`Share ${
        isEventExternalLink(event.eventType) ? "Link" : "Event"
      }`}
      headerLeftIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingInline: "14px",
            marginBottom: "10px",
          }}
        >
          <Icon
            icon={"ion:share"}
            height={35}
            style={{
              color: Colors.BLACK,
            }}
          />
        </div>
      }
      noExit={true}
      closeOnOutsidePress
      valueComp={
        <>
          <div style={{ width: "100%" }}>
            <div
              className="AlignedRowSpaced"
              style={{
                cursor: "pointer",
                padding: "12px 14px",
                backgroundColor: Colors.GRAY6,
                borderRadius: 12,
                border: `0.5px solid ${Colors.GRAY11}`,
                gap: 5,
                fontSize: 14,
              }}
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {dropdownSelect === 0
                ? "General Link"
                : dropdownSelect === 1
                ? "Tracking Links"
                : "Promoter Tracking Links"}
              {dropdownOpen ? (
                <IoIosArrowUp size={16} color={Colors.BLACK} />
              ) : (
                <IoIosArrowDown size={16} color={Colors.BLACK} />
              )}
            </div>
            {dropdownOpen ? (
              <div className="ShareEventModalDropdown">
                <div
                  className="ShareEventModalDropdownItem"
                  onClick={() => {
                    setDropdownOpen(false);
                    setDropdownSelect(0);
                  }}
                >
                  <div style={{ fontSize: 14, cursor: "pointer" }}>
                    General Shareable Link
                  </div>
                  {dropdownSelect === 0 && (
                    <Icon icon="ion:checkmark" height={16} />
                  )}
                </div>
                {!hideTrackingLinks ? (
                  <>
                    <HorizontalDivider altMargin={14} />
                    <div
                      className="ShareEventModalDropdownItem"
                      onClick={() => {
                        setDropdownOpen(false);
                        setDropdownSelect(1);
                      }}
                    >
                      <div style={{ fontSize: 14, cursor: "pointer" }}>
                        Tracking Links
                      </div>
                      {dropdownSelect === 1 && (
                        <Icon icon="ion:checkmark" height={16} />
                      )}
                    </div>
                  </>
                ) : null}
                {/* @TODO: Once Promoter Tracking Links is implemented */}
                {/* <hr />
              <div
                className="ShareEventModalDropdownItem"
                onClick={() => {
                  setDropdownOpen(false);
                  setDropdownSelect(2);
                }}
              >
                <div style={{ fontSize: 14, cursor: "pointer" }}>
                  Promoter Tracking Links
                </div>
                {dropdownSelect === 2 && (
                  <Icon icon="ion:checkmark" height={16} />
                )}
                </div> */}
              </div>
            ) : null}
          </div>
          <div style={{ paddingTop: "14px" }}>
            {dropdownSelect === 0 ? (
              <>
                {renderLinkSharingBar("General Link", "", true)}
                {trackingLinkData.length === 0 ? renderEmptyTrackingLink : null}
              </>
            ) : trackingLinkData.length > 0 ? (
              <div className="ColumnNormal" style={{ gap: 10 }}>
                <div>
                  {trackingLinkData.map((item, index) => {
                    return renderLinkSharingBar(
                      item.eventLinkTracker.alias,
                      item.linkMapping.id,
                      index === trackingLinkData.length - 1
                    );
                  })}
                </div>
                {canLoadMoreTrackingLinks ? (
                  <div
                    onClick={fetchMoreTrackingLinks}
                    style={{ textAlign: "center", cursor: "pointer" }}
                  >
                    <span className="bodySubtext">Load More...</span>
                  </div>
                ) : null}
                <button
                  onClick={newTrackingLinkOnPress}
                  className="RectangleButton"
                  style={{
                    backgroundColor: Colors.WHITE,
                    border: "0.5px solid #929292",
                    cursor: "pointer",
                    paddingBlock: 10,
                  }}
                >
                  <p
                    style={{
                      fontWeight: "500",
                      fontSize: 14,
                      cursor: "pointer",
                    }}
                  >
                    New Tracking Link
                  </p>
                  <AiOutlinePlusCircle color={Colors.BLACK} />
                </button>
              </div>
            ) : (
              renderEmptyTrackingLink
            )}
          </div>
          <AlertContainer
            headerComp={alertText}
            closeModal={() => setAlertText("")}
            hideModal={alertText === ""}
          />
        </>
      }
      closeModal={() => {
        setIsTrackingLinksView(false);
        closeShareModal();
      }}
    />
  );
};

export default ShareEventModal;
