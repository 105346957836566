import React, { memo, useCallback, useRef, useEffect, useState } from "react";
import "../../css/DefaultLandingPage.css";
import "../../css/GlobalStyles.css";
import TopHeader from "../../components/TopHeader";
import Footer from "../../components/Footer";
import { useOnMount } from "../../hooks/useOnMount";
import { useSelector, useDispatch } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import MarkitHeaderLogo from "../../components/MarkitHeaderLogo";
import { MARKIT_WEBSITE, MARKITAI } from "@markit/common.utils";
import { useNavigate } from "../../hooks/useNavigate";
import { DataLoaders } from "../../redux/slices/dataSlice";
import {
  AcesCarousel,
  BobbyCarousel,
  BUCarousel,
  CitizensCarousel,
  CAMHCarousel,
  DopeCarousel,
  MeetIRLCarousel,
  OffsetCarousel,
  OldDominionCarousel,
  OSLCarousel,
  RecordsCarousel,
  RemaCarousel,
  SidetalkCarousel,
  StateChampsCarousel,
  TechstarsCarousel,
  TuftsCarousel,
} from "../../assets/LandingPage/Carousel/carouselIndex";
import {
  JessicaBlumMembership,
  JessicaBlumMembershipMobile,
  ZaneCarterMembership,
  ZaneCarterMembershipMobile,
  AlexJohnsonMembership,
  AlexJohnsonMembershipMobile,
} from "../../assets/LandingPage/MembershipCarousel/membershipCarouselIndex";
import YourBrandYourWay from "../../assets/LandingPage/YourBrandYourWay.png";
import PricingThatScalesWithYou from "../../assets/LandingPage/PricingThatScalesWithYou.png";
import ReachYourFansInstantly from "../../assets/LandingPage/ReachYourFansInstantly.png";
import OldDominion from "../../assets/LandingPage/OldDominion.png";
import Aces from "../../assets/LandingPage/Aces.png";
import iMessageOnSteroids from "../../assets/LandingPage/iMessageOnSteroids.png";
import iMessageOnSteroidsMobile from "../../assets/LandingPage/iMessageOnSteroidsMobile.png";
import JessicaSubscriberQuote from "../../assets/LandingPage/JessicaSubscriberQuote.png";
import JessicaSubscriberQuoteMobile from "../../assets/LandingPage/JessicaSubscriberQuoteMobile.png";
import TextingMadeHuman from "../../assets/LandingPage/TextingMadeHuman.png";
import BackgroundGradient from "../../assets/LandingPage/BackgroundGradient.png";
import { isDesktop } from "react-device-detect";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import MarkitFullLogoBlueWhite from "../../assets/MarkitFullLogoBlueWhite.png";
import { NavigationId } from "../../navigation/AppParamList";
import { Helmet } from "react-helmet";
import CarouselContainer, {
  CarouselItem,
} from "../../components/AnimationContainers/CarouselContainer";
import { Carousel } from "react-responsive-carousel";

const DefaultLandingPage = memo(function DefaultLandingPageFn() {
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoContainerRef = useRef<HTMLDivElement>(null);

  // Add state for mobile nav
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const fanWords = ["Network", "Biggest Fans", "Loyal Community"];

  const styles = {
    button: {
      padding: isDesktop ? "12px 16px" : "9px 13px",
      borderRadius: isDesktop ? 22 : 18,
      width: "fit-content",
    },

    gradientTopLeft: {
      width: "100%",
      height: isDesktop ? 100 : 53,
      background: "linear-gradient(to top left, black 50%, white 50%)",
    },

    gradientBottomRight: {
      width: "100%",
      height: isDesktop ? 100 : 53,
      background: "linear-gradient(to bottom right, black 50%, white 50%)",
    },

    headerLabel: {
      fontSize: isDesktop ? 20 : 14,
      fontWeight: 500,
      paddingBottom: isDesktop ? 10 : 7,
    },

    headerSubtext: {
      fontSize: isDesktop ? 22 : 15,
      fontWeight: 500,
      color: Colors.GRAY14,
    },

    headerText: {
      fontSize: isDesktop ? 64 : 48,
      fontWeight: 500,
      lineHeight: 1.1,
    },

    largeImage: {
      width: "100%",
      height: "auto",
    },

    mobileView: {
      paddingInline: 20,
    },

    slideUpAnimation: {
      opacity: 0,
      transform: "translateY(20px)",
      transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
    },

    slideUpVisible: {
      opacity: 1,
      transform: "translateY(0)",
    },

    testimonialBox: {
      backgroundColor: Colors.GRAY10,
      padding: 30,
      borderRadius: 20,
      height: isDesktop ? 253 : "auto",
      gap: isDesktop ? 0 : 30,
    },

    testimonialText: {
      fontSize: isDesktop ? 24 : 20,
      color: Colors.WHITE,
    },

    testimonialImage: {
      height: isDesktop ? 60 : 42,
    },

    testimonialName: {
      fontSize: isDesktop ? 24 : 16,
      fontWeight: 500,
      color: Colors.GRAY1,
    },
  };

  // Navigation effect
  useEffect(() => {
    if (loggedIn === LoginState.LOGGED_IN && accountData.inCreatorMode) {
      navigate("/home");
    }
  }, [loggedIn, accountData.inCreatorMode, navigate]);

  // Animation effect with check to prevent duplicate setup
  useOnMount(() => {
    const scrollContainer = logoContainerRef.current;
    if (!scrollContainer) return;

    const scroll = () => {
      const scrollWidth = scrollContainer.scrollWidth;
      const clientWidth = scrollContainer.clientWidth;

      if (scrollContainer.scrollLeft >= scrollWidth - clientWidth) {
        scrollContainer.scrollLeft = 0;
      } else {
        scrollContainer.scrollLeft += 1;
      }
    };

    const intervalId = setInterval(scroll, 30);

    // Add intersection observer for slide-up animations
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    // Observe all images with slide-up class
    document
      .querySelectorAll(".slide-up")
      .forEach((el) => observer.observe(el));

    return () => {
      clearInterval(intervalId);
      observer.disconnect();
    };
  });

  useOnMount(() => {
    const style = document.createElement("style");
    style.textContent = `
      .slide-up {
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 1s ease-out, transform 0.8s ease-out;
      }
      .slide-up.visible {
        opacity: 1;
        transform: translateY(0);
      }
    `;
    document.head.appendChild(style);
    return () => style.remove();
  });

  const getStartedOnPress = useCallback(() => {
    if (loggedIn === LoginState.LOGGED_IN) {
      dispatch(DataLoaders.user(accountData.uid));
      navigate(`/u/${accountData.username}`, {
        state: { username: accountData.username },
      });
    } else {
      navigate(NavigationId.WELCOME);
    }
  }, [accountData.uid, accountData.username, dispatch, loggedIn, navigate]);

  const pricingOnPress = useCallback(() => {
    window.open(MARKIT_WEBSITE + "pricing");
  }, []);

  const textingFeaturesOnPress = useCallback(() => {
    window.open(MARKIT_WEBSITE + "texting");
  }, []);

  const supportOnPress = useCallback(() => {
    window.open(MARKIT_WEBSITE + "contact");
  }, []);

  const CustomerLogoCarousel = () => (
    <div
      ref={logoContainerRef}
      style={{
        display: "flex",
        gap: 40,
        overflow: "hidden",
        width: "100%",
      }}
    >
      {[...Array(2)].map((_, groupIndex) => (
        <div
          key={groupIndex}
          className="AlignedRow"
          style={{ gap: isDesktop ? 50 : 28, flexShrink: 0 }}
        >
          <img
            src={RecordsCarousel}
            alt="17o1 Records"
            style={{ height: isDesktop ? 80 : 45 }}
          />
          <img
            src={BobbyCarousel}
            alt="Bobby Shmurda"
            style={{ height: isDesktop ? 102 : 58 }}
          />
          <img
            src={TechstarsCarousel}
            alt="Techstars"
            style={{ height: isDesktop ? 28 : 16 }}
          />
          <img
            src={BUCarousel}
            alt="BU"
            style={{ height: isDesktop ? 50 : 28 }}
          />
          <img
            src={StateChampsCarousel}
            alt="State Champs"
            style={{ height: isDesktop ? 102 : 58 }}
          />
          <img
            src={MeetIRLCarousel}
            alt="MeetIRL"
            style={{ height: isDesktop ? 35 : 20 }}
          />
          <img
            src={DopeCarousel}
            alt="Dope"
            style={{ height: isDesktop ? 53 : 29 }}
          />
          <img
            src={RemaCarousel}
            alt="Rema"
            style={{ height: isDesktop ? 102 : 58 }}
          />
          <img
            src={SidetalkCarousel}
            alt="Sidetalk"
            style={{ height: isDesktop ? 84 : 47 }}
          />
          <img
            src={OldDominionCarousel}
            alt="Old Dominion"
            style={{ height: isDesktop ? 102 : 58 }}
          />
          <img
            src={CAMHCarousel}
            alt="CAMH"
            style={{ height: isDesktop ? 45 : 25 }}
          />
          <img
            src={TuftsCarousel}
            alt="Tufts"
            style={{ height: isDesktop ? 36 : 20 }}
          />
          <img
            src={OffsetCarousel}
            alt="Offset"
            style={{ height: isDesktop ? 102 : 58 }}
          />
          <img
            src={AcesCarousel}
            alt="Aces"
            style={{ height: isDesktop ? 53 : 30 }}
          />

          <img
            src={OSLCarousel}
            alt="OSL Falcons"
            style={{ height: isDesktop ? 76 : 43 }}
          />
          <img
            src={CitizensCarousel}
            alt="Citizens Bank"
            style={{ height: isDesktop ? 23 : 13 }}
          />
        </div>
      ))}
    </div>
  );

  const carouselItems: CarouselItem[] = [
    {
      image: isDesktop ? JessicaBlumMembership : JessicaBlumMembershipMobile,
      title: "",
      width: 884, // padding helps set width for mobile view
    },
    {
      image: isDesktop ? ZaneCarterMembership : ZaneCarterMembershipMobile,
      title: "",
      width: 884,
    },
    {
      image: isDesktop ? AlexJohnsonMembership : AlexJohnsonMembershipMobile,
      title: "",
      width: 884,
    },
  ];

  return (
    <div
      className={isMobileNavOpen ? "PreventAllScrolling" : ""}
      style={{
        backgroundColor: Colors.BLACK,
        overflowX: "hidden",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>Home | Markit AI</title>
        <meta
          name="description"
          content="Markit is a texting platform designed for creators to directly engage their followers and generate sustainable monthly income."
        />
        <link rel="canonical" href={MARKITAI} />
      </Helmet>
      <TopHeader
        whiteLogo
        homePage
        preventLogoClick
        onMobileNavOpenChange={setIsMobileNavOpen}
      />
      <div
        className="AlignedColumn"
        style={{
          paddingBlock: isDesktop ? 100 : 60,

          color: Colors.WHITE,
        }}
      >
        <MarkitHeaderLogo
          fullLogo
          alternateLogo={MarkitFullLogoBlueWhite}
          disabled
        />
        <h1
          style={{
            fontSize: isDesktop ? 96 : 63,
            fontWeight: 500,
            paddingBlock: 20,
            textAlign: "center",
            letterSpacing: "-1px",
          }}
        >
          The Texting Platform {isDesktop ? <br /> : null}For Creators
        </h1>
        <p
          style={{
            fontSize: 20,
            fontWeight: 500,
            paddingBottom: isDesktop ? 40 : 60,
          }}
        >
          Directly engage your fans {isDesktop ? null : <br />}and make monthly
          income.
        </p>
        <div
          className={isDesktop ? "Centering" : "AlignedColumn"}
          style={{ gap: 14, width: 164 }}
        >
          <RectangleButton
            buttonLabel={
              <p
                className="AlignedRowSelect"
                style={{
                  fontSize: isDesktop ? 16 : 12,
                  whiteSpace: "nowrap",
                  fontWeight: 500,
                }}
              >
                Start Texting
              </p>
            }
            onPress={getStartedOnPress}
            altColor={Colors.BLUE5}
            altTextColor={Colors.WHITE}
            containerStyles={{
              ...styles.button,
              alignSelf: "center",
            }}
            iconRight={
              <Icon
                icon="ion:chevron-forward"
                height={16}
                color={Colors.WHITE}
              />
            }
          />
          <RectangleButton
            buttonLabel={
              <p
                className="AlignedRowSelect"
                style={{
                  fontSize: isDesktop ? 16 : 12,
                  whiteSpace: "nowrap",
                  fontWeight: 500,
                }}
              >
                Book a Demo
              </p>
            }
            onPress={supportOnPress}
            altColor={Colors.GRAY7}
            altTextColor={Colors.WHITE}
            containerStyles={{
              ...styles.button,
              alignSelf: "center",
            }}
          />
        </div>
        <div
          style={{
            marginTop: isDesktop ? 120 : 80,
            width: "100vw",
            marginLeft: "calc(-50vw + 50%)",
          }}
        >
          <CustomerLogoCarousel />
        </div>
      </div>
      {/* Fold 2 */}
      <div
        className={isDesktop ? "AlignedColumn" : "ColumnNormal"}
        style={{
          paddingTop: 60,
          paddingBottom: isDesktop ? 40 : 60,
          textAlign: isDesktop ? "center" : "left",
          backgroundColor: Colors.WHITE,
        }}
      >
        <div
          className={isDesktop ? "AlignedColumn" : "ColumnNormal"}
          style={{
            ...(isDesktop ? {} : styles.mobileView),
          }}
        >
          <span
            style={{
              ...styles.headerLabel,
              color: Colors.GREEN2,
            }}
          >
            Monetize with Texting
          </span>
          <h2
            style={{
              ...styles.headerText,
              paddingBottom: 30,
              color: Colors.BLACK,
            }}
          >
            Earn More {isDesktop ? null : <br />}From Your <br />
            <div
              className={isDesktop ? "ColumnCentering" : "AlignedRow"}
              style={{ maxWidth: 884 }}
            >
              <Carousel
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                showIndicators={false}
                stopOnHover={false}
                autoPlay={true}
                infiniteLoop
                interval={5000}
                transitionTime={500}
                emulateTouch={true}
              >
                {fanWords.map((word, index) => (
                  <span
                    className={isDesktop ? "ColumnCentering" : "AlignedRow"}
                    key={index}
                    style={{
                      color: Colors.GREEN2,
                      fontWeight: 600,
                    }}
                  >
                    {word}
                  </span>
                ))}
              </Carousel>
            </div>
          </h2>
          <span
            style={{
              fontSize: isDesktop ? 17 : 15,
              fontWeight: 500,
              marginBottom: 60,
              color: Colors.BLACK,
            }}
          >
            Sell merchandise/products, offer paid {isDesktop ? null : <br />}
            memberships with {isDesktop ? <br /> : null}
            subscription tiers, and create {isDesktop ? null : <br />}monthly
            recurring revenue on Markit
          </span>
        </div>
        {/* Carousel container has 20px padding on each side already, which matches the padding on the fold for mobile view */}
        <div className="slide-up">
          <CarouselContainer
            carouselItems={carouselItems}
            containerStyles={{ maxWidth: 884 }}
          />
        </div>
      </div>
      <div style={styles.gradientTopLeft} />
      {/* Fold 3 */}
      <div style={{ maxWidth: 1152, margin: "0 auto" }}>
        <div
          className="AlignedColumnSpaced"
          style={{
            ...(isDesktop ? {} : styles.mobileView),
            paddingTop: isDesktop ? 80 : 60,
            paddingBottom: isDesktop ? 100 : 60,
            gap: isDesktop ? 120 : 60,
          }}
        >
          {/* Reach your Followers instantly */}
          <div
            className={isDesktop ? "RowNormal" : "ColumnNormal"}
            style={{ gap: isDesktop ? 155 : 50 }}
          >
            <div className="ColumnNormal">
              <span
                style={{
                  ...styles.headerLabel,
                  color: Colors.BLUE5,
                }}
              >
                Texting with Markit
              </span>
              <div className="ColumnNormal" style={{ gap: 30 }}>
                <h2
                  style={{
                    ...styles.headerText,
                    color: Colors.WHITE,
                  }}
                >
                  Reach Your <br />
                  Followers <br />
                  <span
                    className="AlignedRow"
                    style={{ display: "inline-flex" }}
                  >
                    <span style={{ color: Colors.BLUE5, fontWeight: 600 }}>
                      Instantly
                    </span>
                    <Icon
                      icon="iconoir:send-solid"
                      style={{ color: Colors.BLUE5, marginLeft: 8 }}
                    />
                  </span>
                </h2>

                <p style={styles.headerSubtext}>
                  90% of subscribers read your text within 3 minutes, which is
                  way more effective than email or social media. Personalize
                  your texts with emojis, images, and polls.
                </p>

                <RectangleButton
                  buttonLabel="Explore Texting"
                  onPress={textingFeaturesOnPress}
                  altColor={Colors.WHITE}
                  altTextColor={Colors.BLACK}
                  iconRight={
                    <Icon
                      icon="ion:chevron-forward"
                      height={16}
                      color={Colors.BLACK}
                    />
                  }
                  containerStyles={styles.button}
                />
              </div>
            </div>
            <img
              src={ReachYourFansInstantly}
              alt="Markit messaging interface"
              className="slide-up"
              style={{
                ...styles.largeImage,
                objectFit: "contain",
                maxWidth: 519,
              }}
            />
          </div>
          {/* Testimonials */}
          <div
            className={isDesktop ? "AlignedRow" : "AlignedColumn"}
            style={{ flex: 1, gap: 40 }}
          >
            <div className="ColumnNormalSpaced" style={styles.testimonialBox}>
              <p style={styles.testimonialText}>
                &quot;Markit is a game-changer—we text our fans, and they
                actually text back! Markit clicked for me when I saw, &apos;I
                named my daughter after this song&apos; and &apos;this song
                helped me through my breakup.&apos; It was so cool.&quot;
              </p>
              <div className="AlignedRow" style={{ gap: 20 }}>
                <img
                  src={OldDominion}
                  alt="old dominion"
                  style={styles.testimonialImage}
                />
                <div>
                  <p style={styles.testimonialName}>Alexa</p>
                  <p style={styles.testimonialName}>Manager, Old Dominion</p>
                </div>
              </div>
            </div>
            <div className="ColumnNormalSpaced" style={styles.testimonialBox}>
              <p style={styles.testimonialText}>
                &quot;I dropped a text about a limited-edition hoodie, and
                within minutes, we saw orders rolling in and fans replying,
                &apos;Just ordered!&apos; and &apos;Literally was waiting for
                this-can&apos;t wait for it to come!&apos; No guessing, people
                were getting our message.&quot;
              </p>
              <div className="AlignedRow" style={{ gap: 20 }}>
                <img src={Aces} alt="aces" style={styles.testimonialImage} />
                <div>
                  <p style={styles.testimonialName}>Brian</p>
                  <p style={styles.testimonialName}>Founder, ACES</p>
                </div>
              </div>
            </div>
          </div>
          {/* iMessage on Steroids */}
          <div
            className={isDesktop ? "AlignedRow" : "ColumnNormal"}
            style={{
              gap: isDesktop ? 153 : 60,
            }}
          >
            {isDesktop ? (
              <img
                src={iMessageOnSteroids}
                alt="Markit two way conversation interface"
                className="slide-up"
                style={{
                  ...styles.largeImage,
                  objectFit: "contain",
                  maxWidth: 621,
                }}
              />
            ) : null}
            <div className="ColumnNormal">
              <span
                style={{
                  ...styles.headerLabel,
                  color: Colors.BLUE5,
                }}
              >
                Texting with Markit
              </span>
              <div className="ColumnNormal" style={{ gap: isDesktop ? 30 : 0 }}>
                <h2
                  style={{
                    ...styles.headerText,
                    color: Colors.WHITE,
                    paddingBottom: isDesktop ? 0 : 21,
                  }}
                >
                  iMessage <br />
                  on Steroids
                </h2>
                <p
                  style={{
                    ...styles.headerSubtext,
                    paddingBottom: isDesktop ? 0 : 30,
                  }}
                >
                  Create your personal authentic channel to{" "}
                  {isDesktop ? null : <br />}connect with your best fans.
                  <br />
                  <br />
                  And because it&apos;s texts, fans can reply like{" "}
                  {isDesktop ? null : <br />}they&apos;re chatting with a
                  friend.
                </p>
                <RectangleButton
                  buttonLabel="Explore Two Way"
                  onPress={textingFeaturesOnPress}
                  altColor={Colors.BLUE5}
                  altTextColor={Colors.WHITE}
                  iconRight={
                    <Icon
                      icon="ion:chevron-forward"
                      height={16}
                      color={Colors.WHITE}
                    />
                  }
                  containerStyles={styles.button}
                />
              </div>
            </div>
            {!isDesktop ? (
              <img
                src={iMessageOnSteroidsMobile}
                alt="Markit two way conversation interface"
                className="slide-up"
                style={{
                  ...styles.largeImage,
                  objectFit: "contain",
                }}
              />
            ) : null}
          </div>
          {/* Subscriber Testimonials */}
          <img
            src={
              isDesktop ? JessicaSubscriberQuote : JessicaSubscriberQuoteMobile
            }
            alt="Subscriber Quote"
            className="slide-up"
            style={{
              ...styles.largeImage,
              objectFit: "contain",
              maxWidth: 766,
            }}
          />
        </div>
      </div>
      <div style={styles.gradientBottomRight} />
      {/* Fold 4 */}
      <div
        style={{
          ...(isDesktop ? {} : styles.mobileView),
          backgroundColor: Colors.WHITE,
          paddingTop: isDesktop ? 80 : 60,
          paddingBottom: isDesktop ? 100 : 60,
        }}
      >
        <div style={{ maxWidth: 1152, margin: "0 auto" }}>
          <div
            className={isDesktop ? "RowNormal" : "ColumnNormal"}
            style={{ gap: isDesktop ? 167 : 40 }}
          >
            <div
              className="ColumnNormal"
              style={{ maxWidth: isDesktop ? 383 : undefined }}
            >
              <span
                style={{
                  ...styles.headerLabel,
                  color: Colors.ORANGE1,
                }}
              >
                Grow Your Following
              </span>
              <div className="ColumnNormal" style={{ gap: 30 }}>
                <h2
                  style={{
                    ...styles.headerText,
                    color: Colors.BLACK,
                  }}
                >
                  Your Brand
                  <br />
                  Your Way
                </h2>

                <p style={{ ...styles.headerSubtext, color: Colors.BLACK }}>
                  Every creator has a unique connection with their followers.
                  With a suite of growth tools Markit enables creators to grow
                  with the channel that suits them best.
                </p>

                <RectangleButton
                  buttonLabel="Grow with Markit"
                  onPress={getStartedOnPress}
                  altColor={Colors.BLACK}
                  altTextColor={Colors.WHITE}
                  iconRight={
                    <Icon
                      icon="ion:chevron-forward"
                      height={16}
                      color={Colors.WHITE}
                    />
                  }
                  containerStyles={styles.button}
                />
              </div>
            </div>
            <img
              src={YourBrandYourWay}
              alt="Concert image"
              className="slide-up"
              style={{
                ...styles.largeImage,
                objectFit: "contain",
                maxWidth: 602,
              }}
            />
          </div>
        </div>
      </div>
      {/* Fold 5 */}
      <div
        style={{
          ...(isDesktop ? {} : styles.mobileView),
          backgroundColor: Colors.BLACK,
          paddingTop: isDesktop ? 80 : 60,
          paddingBottom: isDesktop ? 80 : 60,
        }}
      >
        <div style={{ maxWidth: 1152, margin: "0 auto" }}>
          <div
            className={isDesktop ? "RowNormal" : "ColumnNormal"}
            style={{ gap: isDesktop ? 149 : 60 }}
          >
            <div className="ColumnNormal">
              <span
                style={{
                  ...styles.headerLabel,
                  color: Colors.BLUE5,
                }}
              >
                Pricing
              </span>
              <div className="ColumnNormal" style={{ gap: 30 }}>
                <h2
                  style={{
                    ...styles.headerText,
                    fontSize: isDesktop ? 48 : 45,
                    color: Colors.WHITE,
                  }}
                >
                  Pricing That
                  <br />
                  Scales With You
                </h2>

                <p style={{ ...styles.headerSubtext }}>
                  Markit is free to use. Upgrade to Markit+ to explore
                  additional features and unlock unlimited texting. Contact
                  support to learn more.
                </p>
                <div
                  className="AlignedRow"
                  style={{ gap: isDesktop ? 14 : 10 }}
                >
                  <RectangleButton
                    buttonLabel="Pricing"
                    onPress={pricingOnPress}
                    altColor={Colors.WHITE}
                    altTextColor={Colors.BLACK}
                    iconRight={
                      <Icon
                        icon="ion:chevron-forward"
                        height={16}
                        color={Colors.BLACK}
                      />
                    }
                    containerStyles={styles.button}
                  />
                  <RectangleButton
                    buttonLabel="Contact Support"
                    onPress={supportOnPress}
                    altColor={Colors.BLACK}
                    altTextColor={Colors.WHITE}
                    iconRight={
                      <Icon
                        icon="ion:chevron-forward"
                        height={16}
                        color={Colors.WHITE}
                      />
                    }
                    containerStyles={{
                      ...styles.button,
                      border: `1px solid ${Colors.WHITE}`,
                    }}
                  />
                </div>
              </div>
            </div>
            <img
              src={PricingThatScalesWithYou}
              alt="Follower network growth"
              className="slide-up"
              style={{
                ...styles.largeImage,
                objectFit: "contain",
                maxWidth: 545,
              }}
            />
          </div>
        </div>
      </div>
      {/* Fold 6 */}
      <div
        className={"AlignedColumn"}
        style={{
          paddingTop: isDesktop ? 80 : 60,
          paddingBottom: isDesktop ? 80 : 60,
          backgroundImage: `url(${BackgroundGradient})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h2
          style={{
            ...styles.headerText,
            paddingBottom: 30,
            color: Colors.WHITE,
            textAlign: "center",
          }}
        >
          Text Marketing {isDesktop ? null : <br />}That{" "}
          {isDesktop ? <br /> : null}Feels {isDesktop ? null : <br />}
          <span style={{ color: Colors.BLUE5, fontWeight: 600 }}>Human</span>
        </h2>
        <RectangleButton
          buttonLabel={
            <p
              className="AlignedRowSelect"
              style={{
                fontSize: isDesktop ? 16 : 12,
                whiteSpace: "nowrap",
                fontWeight: 500,
              }}
            >
              Start Texting
            </p>
          }
          onPress={getStartedOnPress}
          altColor={Colors.BLUE5}
          altTextColor={Colors.WHITE}
          containerStyles={{
            ...styles.button,
            alignSelf: "center",
          }}
          iconRight={
            <Icon icon="ion:chevron-forward" height={16} color={Colors.WHITE} />
          }
        />
        <img
          src={TextingMadeHuman}
          alt="Creator Subscriber Collage"
          className="slide-up"
          style={{
            ...styles.largeImage,
            paddingTop: 60,
            objectFit: "contain",
            maxWidth: isDesktop ? 480 : 336,
          }}
        />
      </div>
      <Footer />
    </div>
  );
});

export default DefaultLandingPage;
