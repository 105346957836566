/* eslint-disable no-unused-vars */
import {
  collection,
  db,
  doc,
  DocumentReference,
  DocumentSnapshot,
  FieldValue,
  getDoc,
  getDocs,
  writeBatch,
  WriteBatch,
  query,
  where,
} from "../firebase";
import { SetOptions, UpdateData } from "firebase/firestore";
import {
  accountConverter,
  activityConverter,
  eventConverter,
  ticketConverter,
  groupChatConverter,
  messageConverter,
  wishMarkConverter,
  spreadsheetUserConverter,
  phoneNumberConverter,
  conversationConverter,
  discussionConverter,
  audienceListConverter,
  audienceListMemberConverter,
  subscriptionReferralConverter,
  massTextConverter,
  textRecipientConverter,
  linkMappingConverter,
  eventLinkTrackerConverter,
  trackingDataConverter,
  massTextAttachmentConverter,
  formResponseConverter,
  followerConverter,
  followingConverter,
  savedFormQuestionConverter,
  savedFormResponseConverter,
  spreadsheetConverter,
  featuredUserConverter,
  featuredEventsConverter,
  campaignConverter,
  followerSubscriptionConverter,
} from "./converters";

export enum Collection {
  EVENTS = "events",
  GROUPS = "groups",
  CONVERSATIONS = "conversations",
  MARKS = "marks",
  MASS_TEXTS = "massTexts",
  MESSAGES = "messages",
  DISCUSSIONS = "discussion",
  PHONE_NUMBERS = "phoneNumbers",
  REFERRALS = "referrals",
  SUBSCRIPTIONREFERRALS = "subscriptionReferrals",
  SOFT_USERS = "softUsers",
  USERNAMES = "usernames",
  USERS = "users",
  WISHLIST = "wishlist",
  FEATURED_USERS = "featuredUsers",
  FEATURED_EVENTS = "featuredEvents",
}

export enum UserCollection {
  ACTIVITY = "activity",
  EVENTS = "events",
  DISCUSSIONS = "discussion",
  GROUPS = "groups",
  CONVERSATIONS = "conversations",
  WISHLIST = "wishlist",
  CAMPAIGNS = "campaigns",
  MASS_TEXTS = "massTexts",
  SPREADSHEETUSERS = "spreadsheetUsers",
  SPREADSHEETS = "spreadsheets",
  AUDIENCE_LISTS = "audienceLists",
  AUDIENCE_LIST_MEMBERS = "audienceListMembers",
  FOLLOWERS = "followers",
  FOLLOWING = "following",
  SAVED_FORM_QUESTIONS = "savedFormQuestions",
}

export enum EventCollection {
  TICKETS = "tickets",
  FORMRESPONSE = "formResponse",
  LINK_MAPPINGS = "linkMappings",
  EVENT_LINK_TRACKERS = "eventLinkTrackers",
}

export enum CampaignCollection {
  MASS_TEXTS = "massTexts",
}

export enum MassTextCollection {
  TEXT_RECIPIENTS = "textRecipients",
  MASS_TEXT_ATTACHMENTS = "massTextAttachments",
}

export enum LinkTrackerCollection {
  TRACKING_DATA = "trackers",
}

export enum FollowerCollection {
  SAVED_FORM_RESPONSES = "savedFormResponses",
  FOLLOWER_SUBSCRIPTIONS = "followerSubscriptions",
}

function getDataFromSnap<T>(
  docSnap: Promise<DocumentSnapshot<T>>,
  errorMessage?: string
): Promise<T | undefined> {
  return docSnap
    .then((snapshot) => {
      const data = snapshot.data();
      if (data === undefined) {
        console.error(`FirebaseUtils fetch data failed - ${errorMessage}`);
        return undefined;
      }
      return data;
    })
    .catch(() => {
      return Promise.reject(
        new Error(`FirebaseUtils fetch data failed - ${errorMessage}`)
      );
    });
}

type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

type ArrayToFieldValue<A> = {
  [K in KeysMatching<A, any[]>]: FieldValue;
};

export type FirebaseUpdateData<A> = {
  [key in keyof A]: key extends keyof ArrayToFieldValue<A>
    ? A[key] | ArrayToFieldValue<A>[key]
    : A[key];
};

export class Batch {
  batch: WriteBatch;
  count: number;
  errorMessage: string | undefined;

  constructor(errorMessage?: string) {
    this.batch = writeBatch(db);
    this.count = 0;
    this.errorMessage = errorMessage;
  }

  async incrementCount() {
    this.count += 1;
    if (this.count >= 500) {
      this.commit();
    }
  }

  async set<T>(
    documentRef: DocumentReference<T>,
    data: T,
    options?: SetOptions | undefined
  ) {
    this.batch.set(documentRef, data, options!);
    this.incrementCount();
    return this;
  }

  async delete<T>(documentRef: DocumentReference<T>) {
    this.batch.delete(documentRef);
    this.incrementCount();
    return this;
  }

  async update<T>(documentRef: DocumentReference<T>, data: UpdateData<T>) {
    this.batch.update(documentRef, data);
    this.incrementCount();
    return this;
  }

  async commit() {
    try {
      await this.batch.commit();
      this.count = 0;
    } catch (e: any) {
      alert(this.errorMessage || e.message);
      console.error(this.errorMessage + ": ", e.message);
    }
  }
}

/* User Util */
export const getUsersRef = () => {
  return collection(db, Collection.USERS).withConverter(accountConverter);
};
export const getUsersSnap = async () => await getDocs(getUsersRef());
export const getUserRef = (uid: string) => {
  return doc(db, Collection.USERS, uid).withConverter(accountConverter);
};
export const getUserSnap = async (uid: string) => await getDoc(getUserRef(uid));
export const getUserData = async (uid: string) =>
  await getDataFromSnap(getUserSnap(uid), "userData " + uid);
export const getUserDataByUsername = async (username: string) => {
  const query_ = await getDocs(
    query(getUsersRef(), where("username", "==", username))
  );
  if (query_.docs.length) {
    return query_.docs[0].data();
  } else {
    return undefined;
  }
};

/* User Activity Util */
export const getUserActivitiesRef = (uid: string) => {
  return collection(getUserRef(uid), UserCollection.ACTIVITY).withConverter(
    activityConverter
  );
};
export const getUserActivitiesSnap = async (uid: string) =>
  await getDocs(getUserActivitiesRef(uid));
export const getUserActivityRef = (uid: string, activityId: string) => {
  return doc(
    getUserRef(uid),
    UserCollection.ACTIVITY,
    activityId
  ).withConverter(activityConverter);
};
export const getUserActivitySnap = async (uid: string, activityId: string) =>
  await getDoc(getUserActivityRef(uid, activityId));
export const getUserActivityData = async (uid: string, activityId: string) =>
  await getDataFromSnap(
    getUserActivitySnap(uid, activityId),
    "uid: " + uid + ", activityId: " + activityId
  );
/* User Events Util */
export const getUserEventsRef = (uid: string) => {
  return collection(getUserRef(uid), UserCollection.EVENTS).withConverter(
    eventConverter
  );
};
export const getUserEventsSnap = (uid: string) =>
  getDocs(getUserEventsRef(uid));
export const getUserEventRef = (uid: string, eventId: string) => {
  return doc(getUserRef(uid), UserCollection.EVENTS, eventId).withConverter(
    eventConverter
  );
};
export const getUserEventSnap = (uid: string, eventId: string) =>
  getDoc(getUserEventRef(uid, eventId));
export const getUserEventData = async (uid: string, eventId: string) =>
  await getDataFromSnap(getUserEventSnap(uid, eventId), "eventId: " + eventId);

/* User Event Discussions Util */
export const getUserEventDiscussionsRef = (uid: string, eventId: string) => {
  return collection(
    getUserEventRef(uid, eventId),
    UserCollection.DISCUSSIONS
  ).withConverter(discussionConverter);
};
export const getUserEventDiscussionsSnap = (uid: string, eventId: string) =>
  getDocs(getUserEventDiscussionsRef(uid, eventId));
export const getUserEventDiscussionRef = (
  uid: string,
  eventId: string,
  discussionPostId: string
) => {
  return doc(
    getUserEventRef(uid, eventId),
    UserCollection.DISCUSSIONS,
    discussionPostId
  ).withConverter(discussionConverter);
};
export const getUserEventDiscussionSnap = (
  uid: string,
  eventId: string,
  discussionPostId: string
) => getDoc(getUserEventDiscussionRef(uid, eventId, discussionPostId));
export const getUserEventDiscussionData = async (
  uid: string,
  eventId: string,
  discussionPostId: string
) =>
  await getDataFromSnap(
    getUserEventDiscussionSnap(uid, eventId, discussionPostId),
    "discussionPostId: " + discussionPostId
  );

/* Event Ticket Util */
export const getTicketsRef = (id: string) => {
  return collection(getEventRef(id), EventCollection.TICKETS).withConverter(
    ticketConverter
  );
};

export const getTicketsSnap = (id: string) => getDocs(getTicketsRef(id));
export const getTicketRef = (id: string, tid: string) =>
  doc(getTicketsRef(id), tid);
export const getTicketSnap = (id: string, tid: string) =>
  getDoc(getTicketRef(id, tid));
export const getTicketData = async (id: string, tid: string) =>
  await getDataFromSnap(getTicketSnap(id, tid));

/* Event Form Util */
export const getFormResponsesRef = (id: string) => {
  return collection(
    getEventRef(id),
    EventCollection.FORMRESPONSE
  ).withConverter(formResponseConverter);
};
export const getFormResponsesSnap = (id: string) =>
  getDocs(getFormResponsesRef(id));
export const getUserFormResponseRef = (id: string, uid: string) =>
  doc(getFormResponsesRef(id), uid);
export const getUserFormResponseSnap = (id: string, uid: string) =>
  getDoc(getUserFormResponseRef(id, uid));
export const getUserFormResponseData = async (id: string, uid: string) =>
  await getDataFromSnap(getUserFormResponseSnap(id, uid));

/* User Event Ticket Util */
export const getUserTicketsRef = (uid: string, id: string) => {
  return collection(
    getUserEventRef(uid, id),
    EventCollection.TICKETS
  ).withConverter(ticketConverter);
};

export const getUserTicketsSnap = (uid: string, id: string) =>
  getDocs(getUserTicketsRef(uid, id));
export const getUserTicketRef = (uid: string, id: string, tid: string) =>
  doc(getUserTicketsRef(uid, id), tid);
export const getUserTicketSnap = (uid: string, id: string, tid: string) =>
  getDoc(getUserTicketRef(uid, id, tid));
export const getUserTicketData = async (uid: string, id: string, tid: string) =>
  await getDataFromSnap(getUserTicketSnap(uid, id, tid));

/* User Groups Util */
export const getUserGroupsRef = (uid: string) => {
  return collection(getUserRef(uid), UserCollection.GROUPS).withConverter(
    groupChatConverter
  );
};
export const getUserGroupsSnap = (uid: string) =>
  getDocs(getUserGroupsRef(uid));
export const getUserGroupRef = (uid: string, groupId: string) => {
  return doc(getUserRef(uid), UserCollection.GROUPS, groupId).withConverter(
    groupChatConverter
  );
};
export const getUserGroupSnap = (uid: string, groupId: string) =>
  getDoc(getUserGroupRef(uid, groupId));
export const getUserGroupData = async (uid: string, groupId: string) =>
  await getDataFromSnap(
    getUserGroupSnap(uid, groupId),
    "uid: " + uid + ", groupId: " + groupId
  );

/* User Conversations Util */
export const getUserConversationsRef = (uid: string) => {
  return collection(
    getUserRef(uid),
    UserCollection.CONVERSATIONS
  ).withConverter(conversationConverter);
};
export const getUserConversationsSnap = (uid: string) =>
  getDocs(getUserConversationsRef(uid));
export const getUserConversationRef = (uid: string, conversationId: string) => {
  return doc(
    getUserRef(uid),
    UserCollection.CONVERSATIONS,
    conversationId
  ).withConverter(conversationConverter);
};
export const getUserConversationSnap = (uid: string, conversationId: string) =>
  getDoc(getUserConversationRef(uid, conversationId));
export const getUserConversationData = async (
  uid: string,
  conversationId: string
) => await getDataFromSnap(getUserConversationSnap(uid, conversationId));

/* User Wishlist Util */
export const getUserWishlistRef = (uid: string) => {
  return collection(getUserRef(uid), UserCollection.WISHLIST).withConverter(
    wishMarkConverter
  );
};
export const getUserWishlistSnap = (uid: string) =>
  getDocs(getUserWishlistRef(uid));
export const getUserWishMarkRef = (uid: string, wishMarkId: string) => {
  return doc(
    getUserRef(uid),
    UserCollection.WISHLIST,
    wishMarkId
  ).withConverter(wishMarkConverter);
};
export const getUserWishMarkSnap = (uid: string, wishMarkId: string) =>
  getDoc(getUserWishMarkRef(uid, wishMarkId));
export const getUserWishMarkData = async (uid: string, wishMarkId: string) =>
  await getDataFromSnap(
    getUserWishMarkSnap(uid, wishMarkId),
    "wishMarkId: " + wishMarkId
  );

/* SpreadsheetUser Util */
export const getSpreadsheetUsersRef = (uid: string) => {
  return collection(
    getUserRef(uid),
    UserCollection.SPREADSHEETUSERS
  ).withConverter(spreadsheetUserConverter);
};
export const getSpreadsheetUsersSnap = (uid: string) =>
  getDocs(getSpreadsheetUsersRef(uid));
export const getSpreadsheetUserRef = (uid: string, phoneNumber: string) => {
  return doc(
    getUserRef(uid),
    UserCollection.SPREADSHEETUSERS,
    phoneNumber
  ).withConverter(spreadsheetUserConverter);
};
export const getSpreadsheetUserSnap = (uid: string, phoneNumber: string) =>
  getDoc(getSpreadsheetUserRef(uid, phoneNumber));
export const getSpreadsheetUserData = async (
  uid: string,
  phoneNumber: string
) =>
  await getDataFromSnap(
    getSpreadsheetUserSnap(uid, phoneNumber),
    "spreadsheetUserData " + phoneNumber
  );

/* Spreadsheet Util */
export const getSpreadsheetsRef = (uid: string) => {
  return collection(getUserRef(uid), UserCollection.SPREADSHEETS).withConverter(
    spreadsheetConverter
  );
};

export const getSpreadsheetsSnap = (uid: string) =>
  getDocs(getSpreadsheetsRef(uid));
export const getSpreadsheetRef = (uid: string, fid: string) =>
  doc(getSpreadsheetsRef(uid), fid).withConverter(spreadsheetConverter);
export const getSpreadsheetSnap = (uid: string, fid: string) =>
  getDoc(getSpreadsheetRef(uid, fid));
export const getSpreadsheetData = async (uid: string, fid: string) =>
  await getDataFromSnap(getSpreadsheetSnap(uid, fid));

/* Event Util */
export const getEventsRef = () => {
  return collection(db, Collection.EVENTS).withConverter(eventConverter);
};
export const getEventsSnap = async () => await getDocs(getEventsRef());
export const getEventRef = (eventId: string) => {
  return doc(db, Collection.EVENTS, eventId).withConverter(eventConverter);
};
export const getEventSnap = async (eventId: string) =>
  await getDoc(getEventRef(eventId));
export const getEventData = async (eventId: string) =>
  await getDataFromSnap(getEventSnap(eventId), "eventId: " + eventId);

/* Event LinkMapping Util */
export const getEventLinkMappingsRef = (eid: string) => {
  return collection(
    getEventRef(eid),
    EventCollection.LINK_MAPPINGS
  ).withConverter(linkMappingConverter);
};

export const getEventLinkMappingsSnap = async (eid: string) =>
  await getDocs(getEventLinkMappingsRef(eid));
export const getEventLinkMappingRef = (eid: string, mappingId: string) =>
  doc(getEventLinkMappingsRef(eid), mappingId).withConverter(
    linkMappingConverter
  );
export const getEventLinkMappingSnap = async (eid: string, mappingId: string) =>
  await getDoc(getEventLinkMappingRef(eid, mappingId));
export const getEventLinkMappingData = async (eid: string, mappingId: string) =>
  await getDataFromSnap(getEventLinkMappingSnap(eid, mappingId));

/* UserEvent LinkMapping Util */
export const getUserEventLinkMappingsRef = (uid: string, eid: string) => {
  return collection(
    getUserEventRef(uid, eid),
    EventCollection.LINK_MAPPINGS
  ).withConverter(linkMappingConverter);
};

export const getUserEventLinkMappingsSnap = async (uid: string, eid: string) =>
  await getDocs(getUserEventLinkMappingsRef(uid, eid));
export const getUserEventLinkMappingRef = (
  uid: string,
  eid: string,
  mappingId: string
) =>
  doc(getUserEventLinkMappingsRef(uid, eid), mappingId).withConverter(
    linkMappingConverter
  );
export const getUserEventLinkMappingSnap = async (
  uid: string,
  eid: string,
  mappingId: string
) => await getDoc(getUserEventLinkMappingRef(uid, eid, mappingId));
export const getUserEventLinkMappingData = async (
  uid: string,
  eid: string,
  mappingId: string
) => await getDataFromSnap(getUserEventLinkMappingSnap(uid, eid, mappingId));

/* Event EventLinkTracker Util */
export const getEventLinkTrackersRef = (eid: string) => {
  return collection(
    getEventRef(eid),
    EventCollection.EVENT_LINK_TRACKERS
  ).withConverter(eventLinkTrackerConverter);
};

export const getEventLinkTrackersSnap = async (eid: string) =>
  await getDocs(getEventLinkTrackersRef(eid));
export const getEventLinkTrackerRef = (eid: string, linkId: string) =>
  doc(getEventLinkTrackersRef(eid), linkId).withConverter(
    eventLinkTrackerConverter
  );
export const getEventLinkTrackerSnap = async (eid: string, linkId: string) =>
  await getDoc(getEventLinkTrackerRef(eid, linkId));
export const getEventLinkTrackerData = async (eid: string, linkId: string) =>
  await getDataFromSnap(getEventLinkTrackerSnap(eid, linkId));

/* UserEvent EventLinkTracker Util */
export const getUserEventLinkTrackersRef = (uid: string, eid: string) => {
  return collection(
    getUserEventRef(uid, eid),
    EventCollection.EVENT_LINK_TRACKERS
  ).withConverter(eventLinkTrackerConverter);
};

export const getUserEventLinkTrackersSnap = async (uid: string, eid: string) =>
  await getDocs(getUserEventLinkTrackersRef(uid, eid));
export const getUserEventLinkTrackerRef = (
  uid: string,
  eid: string,
  linkId: string
) =>
  doc(getUserEventLinkTrackersRef(uid, eid), linkId).withConverter(
    eventLinkTrackerConverter
  );
export const getUserEventLinkTrackerSnap = async (
  uid: string,
  eid: string,
  linkId: string
) => await getDoc(getUserEventLinkTrackerRef(uid, eid, linkId));
export const getUserEventLinkTrackerData = async (
  uid: string,
  eid: string,
  linkId: string
) => await getDataFromSnap(getUserEventLinkTrackerSnap(uid, eid, linkId));

/* EventLinkTracker TrackingData Util */
export const getEventTrackersRef = (eid: string, linkId: string) => {
  return collection(
    getEventLinkTrackerRef(eid, linkId),
    LinkTrackerCollection.TRACKING_DATA
  ).withConverter(trackingDataConverter);
};

export const getEventTrackersSnap = async (eid: string, linkId: string) =>
  await getDocs(getEventTrackersRef(eid, linkId));
export const getEventTrackerRef = (
  eid: string,
  linkId: string,
  trackerId: string
) =>
  doc(getEventTrackersRef(eid, linkId), trackerId).withConverter(
    trackingDataConverter
  );
export const getEventTrackerSnap = async (
  eid: string,
  linkId: string,
  trackerId: string
) => await getDoc(getEventTrackerRef(eid, linkId, trackerId));
export const getEventTrackerData = async (
  eid: string,
  linkId: string,
  trackerId: string
) => await getDataFromSnap(getEventTrackerSnap(eid, linkId, trackerId));

/* User EventLinkTracker TrackingData Util */
export const getUserEventTrackersRef = (
  uid: string,
  eid: string,
  linkId: string
) => {
  return collection(
    getUserEventLinkTrackerRef(uid, eid, linkId),
    LinkTrackerCollection.TRACKING_DATA
  ).withConverter(trackingDataConverter);
};

export const getUserEventTrackersSnap = async (
  uid: string,
  eid: string,
  linkId: string
) => await getDocs(getUserEventTrackersRef(uid, eid, linkId));
export const getUserEventTrackerRef = (
  uid: string,
  eid: string,
  linkId: string,
  trackerId: string
) =>
  doc(getUserEventTrackersRef(uid, eid, linkId), trackerId).withConverter(
    trackingDataConverter
  );
export const getUserEventTrackerSnap = async (
  uid: string,
  eid: string,
  linkId: string,
  trackerId: string
) => await getDoc(getUserEventTrackerRef(uid, eid, linkId, trackerId));
export const getUserEventTrackerData = async (
  uid: string,
  eid: string,
  linkId: string,
  trackerId: string
) =>
  await getDataFromSnap(getUserEventTrackerSnap(uid, eid, linkId, trackerId));

/* Event Discussion Util */
export const getEventDiscussionsRef = (eventId: string) => {
  return collection(getEventRef(eventId), Collection.DISCUSSIONS).withConverter(
    discussionConverter
  );
};
export const getEventDiscussionsSnap = async (eventId: string) =>
  await getDocs(getEventDiscussionsRef(eventId));
export const getEventDiscussionRef = (
  eventId: string,
  discussionPostId: string
) => {
  return doc(
    getEventRef(eventId),
    Collection.DISCUSSIONS,
    discussionPostId
  ).withConverter(discussionConverter);
};
export const getEventDiscussionSnap = async (
  eventId: string,
  discussionPostId: string
) => {
  await getDoc(getEventDiscussionRef(eventId, discussionPostId));
};

/* Groups Util */
export const getGroupsRef = () => {
  return collection(db, Collection.GROUPS).withConverter(groupChatConverter);
};
export const getGroupsSnap = async () => await getDocs(getGroupsRef());
export const getGroupRef = (groupId: string) => {
  return doc(db, Collection.GROUPS, groupId).withConverter(groupChatConverter);
};
export const getGroupSnap = async (groupId: string) =>
  await getDoc(getGroupRef(groupId));
export const getGroupData = async (groupId: string) =>
  await getDataFromSnap(getGroupSnap(groupId), "groupId: " + groupId);

/* Messages Util */
export const getMessagesRef = () => {
  return collection(db, Collection.MESSAGES).withConverter(messageConverter);
};
export const getMessagesSnap = () => getDocs(getMessagesRef());
export const getMessageRef = (messageId: string) => {
  return doc(db, Collection.MESSAGES, messageId).withConverter(
    messageConverter
  );
};
export const getMessageSnap = (messageId: string) =>
  getDoc(getMessageRef(messageId));
export const getMessageData = async (messageId: string) =>
  await getDataFromSnap(getMessageSnap(messageId), "messageId: " + messageId);

/* Conversations Util */
export const getConversationsRef = () => {
  return collection(db, Collection.CONVERSATIONS).withConverter(
    conversationConverter
  );
};
export const getConversationsSnap = () => getDocs(getConversationsRef());
export const getConversationRef = (conversationId: string) => {
  return doc(db, Collection.CONVERSATIONS, conversationId).withConverter(
    conversationConverter
  );
};
export const getConversationSnap = (conversationId: string) =>
  getDoc(getConversationRef(conversationId));
export const getConversationData = async (conversationId: string) =>
  await getDataFromSnap(getConversationSnap(conversationId));

/* Phone Numbers Util */
export const getPhoneNumbersRef = () => {
  return collection(db, Collection.PHONE_NUMBERS).withConverter(
    phoneNumberConverter
  );
};
export const getPhoneNumbersSnap = () => getDocs(getPhoneNumbersRef());
export const getPhoneNumberRef = (phoneNumber: string) => {
  return doc(db, Collection.PHONE_NUMBERS, phoneNumber).withConverter(
    phoneNumberConverter
  );
};
export const getPhoneNumberSnap = (phoneNumber: string) =>
  getDoc(getPhoneNumberRef(phoneNumber));
export const getPhoneNumberData = async (phoneNumber: string) =>
  await getDataFromSnap(getPhoneNumberSnap(phoneNumber));

/* Referrals Util */
export const getReferralsRef = () => {
  return collection(db, Collection.REFERRALS);
};
export const getReferralsSnap = () => getDocs(getReferralsRef());
export const getReferralRef = (referralId: string) => {
  return doc(db, Collection.REFERRALS, referralId);
};
export const getReferralSnap = (referralId: string) =>
  getDoc(getReferralRef(referralId));
export const getReferralData = async (referralId: string) =>
  await getDataFromSnap(
    getReferralSnap(referralId),
    "referralId -" + referralId
  );

/* Soft Users Util */
export const getSoftUsersRef = () => {
  return collection(db, Collection.SOFT_USERS);
};
export const getSoftUsersSnap = () => getDocs(getSoftUsersRef());
export const getSoftUserRef = (phoneNumber: string) => {
  return doc(db, Collection.SOFT_USERS, phoneNumber);
};
export const getSoftUserSnap = (phoneNumber: string) =>
  getDoc(getSoftUserRef(phoneNumber));
export const getSoftUserData = async (phoneNumber: string) =>
  await getDataFromSnap(
    getSoftUserSnap(phoneNumber),
    "softUser -" + phoneNumber
  );

/* Usernames Util */
export const getUsernamesRef = () => {
  return collection(db, Collection.USERNAMES);
};
export const getUsernamesSnap = () => getDocs(getUsernamesRef());
export const getUsernameRef = (username: string) => {
  return doc(db, Collection.USERNAMES, username);
};
export const getUsernameSnap = (username: string) =>
  getDoc(getUsernameRef(username));
export const getUsernameData = async (username: string) =>
  await getDataFromSnap(getUsernameSnap(username), "username -" + username);

/* AudienceLists Util */
export const getUserAudienceListsRef = (uid: string) => {
  return collection(
    getUserRef(uid),
    UserCollection.AUDIENCE_LISTS
  ).withConverter(audienceListConverter);
};

export const getUserAudienceListsSnap = (uid: string) =>
  getDocs(getUserAudienceListsRef(uid));

export const getUserAudienceListRef = (uid: string, audienceListId: string) => {
  return doc(
    getUserRef(uid),
    UserCollection.AUDIENCE_LISTS,
    audienceListId
  ).withConverter(audienceListConverter);
};

export const getUserAudienceListSnap = (uid: string, audienceListId: string) =>
  getDoc(getUserAudienceListRef(uid, audienceListId));

export const getUserAudienceListData = async (
  uid: string,
  audienceListId: string
) => await getDataFromSnap(getUserAudienceListSnap(uid, audienceListId));

/* AudienceListMembers Util */
export const getUserAudienceListMembersRef = (uid: string, id: string) => {
  return collection(
    getUserAudienceListRef(uid, id),
    UserCollection.AUDIENCE_LIST_MEMBERS
  ).withConverter(audienceListMemberConverter);
};
export const getUserAudienceListMembersSnap = (uid: string, id: string) =>
  getDocs(getUserAudienceListMembersRef(uid, id));

export const getUserAudienceListMemberRef = (
  uid: string,
  id: string,
  memberId: string
) => {
  return doc(
    getUserAudienceListRef(uid, id),
    UserCollection.AUDIENCE_LIST_MEMBERS,
    memberId
  ).withConverter(audienceListMemberConverter);
};

export const getUserAudienceListMemberSnap = (
  uid: string,
  audienceListId: string,
  memberId: string
) => getDoc(getUserAudienceListMemberRef(uid, audienceListId, memberId));

export const getUserAudienceListMemberData = async (
  uid: string,
  audienceListId: string,
  memberId: string
) =>
  await getDataFromSnap(
    getUserAudienceListMemberSnap(uid, audienceListId, memberId)
  );

/* SavedFormQuestions Util */
export const getUserSavedFormQuestionsRef = (uid: string) => {
  return collection(
    getUserRef(uid),
    UserCollection.SAVED_FORM_QUESTIONS
  ).withConverter(savedFormQuestionConverter);
};
export const getUserSavedFormQuestionsSnap = (uid: string) =>
  getDocs(getUserSavedFormQuestionsRef(uid));
export const getUserSavedFormQuestionRef = (uid: string, questionId: string) =>
  doc(getUserSavedFormQuestionsRef(uid), questionId);
export const getUserSavedFormQuestionSnap = (uid: string, questionId: string) =>
  getDoc(getUserSavedFormQuestionRef(uid, questionId));
export const getUserSavedFormQuestionData = async (
  uid: string,
  questionId: string
) => await getDataFromSnap(getUserSavedFormQuestionSnap(uid, questionId));

/* SavedFormResponses Util */
export const getUserSavedFormResponsesRef = (
  uid: string,
  followerId: string
) => {
  return collection(
    getUserFollowerRef(uid, followerId),
    FollowerCollection.SAVED_FORM_RESPONSES
  ).withConverter(savedFormResponseConverter);
};
export const getUserSavedFormResponsesSnap = (
  uid: string,
  followerId: string
) => getDocs(getUserSavedFormResponsesRef(uid, followerId));
export const getUserSavedFormResponseRef = (
  uid: string,
  followerId: string,
  responseId: string
) => doc(getUserSavedFormResponsesRef(uid, followerId), responseId);
export const getUserSavedFormResponseSnap = (
  uid: string,
  followerId: string,
  responseId: string
) => getDoc(getUserSavedFormResponseRef(uid, followerId, responseId));
export const getUserSavedFormResponseData = async (
  uid: string,
  followerId: string,
  responseId: string
) =>
  await getDataFromSnap(
    getUserSavedFormResponseSnap(uid, followerId, responseId)
  );

/* Follower Util */
export const getUserFollowersRef = (uid: string) => {
  return collection(getUserRef(uid), UserCollection.FOLLOWERS).withConverter(
    followerConverter
  );
};

export const getUserFollowersSnap = (uid: string) =>
  getDocs(getUserFollowersRef(uid));

export const getUserFollowerRef = (uid: string, followerId: string) => {
  return doc(
    getUserRef(uid),
    UserCollection.FOLLOWERS,
    followerId
  ).withConverter(followerConverter);
};

export const getUserFollowerSnap = (uid: string, followerId: string) =>
  getDoc(getUserFollowerRef(uid, followerId));

export const getUserFollowerData = async (uid: string, followerId: string) =>
  await getDataFromSnap(getUserFollowerSnap(uid, followerId));

/* Follower Subscriptions Util */
export const getUserFollowerSubscriptionsRef = (
  uid: string,
  followerId: string
) => {
  return collection(
    getUserFollowerRef(uid, followerId),
    FollowerCollection.FOLLOWER_SUBSCRIPTIONS
  ).withConverter(followerSubscriptionConverter);
};

export const getUserFollowerSubscriptionsSnap = (
  uid: string,
  followerId: string
) => getDocs(getUserFollowerSubscriptionsRef(uid, followerId));

export const getUserFollowerSubscriptionRef = (
  uid: string,
  followerId: string,
  subscriptionId: string
) => {
  return doc(
    getUserFollowerSubscriptionsRef(uid, followerId),
    subscriptionId
  ).withConverter(followerSubscriptionConverter);
};

export const getUserFollowerSubscriptionSnap = (
  uid: string,
  followerId: string,
  subscriptionId: string
) => getDoc(getUserFollowerSubscriptionRef(uid, followerId, subscriptionId));

export const getUserFollowerSubscriptionData = async (
  uid: string,
  followerId: string,
  subscriptionId: string
) =>
  await getDataFromSnap(
    getUserFollowerSubscriptionSnap(uid, followerId, subscriptionId)
  );

/* Following Util */
export const getUserFollowingsRef = (uid: string) => {
  return collection(getUserRef(uid), UserCollection.FOLLOWING).withConverter(
    followingConverter
  );
};

export const getUserFollowingsSnap = (uid: string) =>
  getDocs(getUserFollowingsRef(uid));

export const getUserFollowingRef = (uid: string, followingId: string) => {
  return doc(
    getUserRef(uid),
    UserCollection.FOLLOWING,
    followingId
  ).withConverter(followingConverter);
};

export const getUserFollowingSnap = (uid: string, followingId: string) =>
  getDoc(getUserFollowingRef(uid, followingId));

export const getUserFollowingData = async (uid: string, followingId: string) =>
  await getDataFromSnap(getUserFollowingSnap(uid, followingId));

/* User Campaign Util */
export const getUserCampaignsRef = (uid: string) => {
  return collection(getUserRef(uid), UserCollection.CAMPAIGNS).withConverter(
    campaignConverter
  );
};

export const getUserCampaignsSnap = (uid: string) =>
  getDocs(getUserCampaignsRef(uid));
export const getUserCampaignRef = (uid: string, id: string) =>
  doc(getUserCampaignsRef(uid), id).withConverter(campaignConverter);
export const getUserCampaignSnap = (uid: string, id: string) =>
  getDoc(getUserCampaignRef(uid, id));
export const getUserCampaignData = async (uid: string, id: string) =>
  await getDataFromSnap(getUserCampaignSnap(uid, id));

/* MassText Util (Top Level) */
export const getMassTextsRef = () => {
  return collection(db, Collection.MASS_TEXTS).withConverter(massTextConverter);
};

export const getMassTextsSnap = () => getDocs(getMassTextsRef());
export const getMassTextRef = (id: string) =>
  doc(getMassTextsRef(), id).withConverter(massTextConverter);
export const getMassTextSnap = (id: string) => getDoc(getMassTextRef(id));
export const getMassTextData = async (id: string) =>
  await getDataFromSnap(getMassTextSnap(id));

/* User Campaign MassText Util
 */
export const getCampaignMassTextsRef = (uid: string, campaignId: string) => {
  return collection(
    getUserCampaignRef(uid, campaignId),
    CampaignCollection.MASS_TEXTS
  ).withConverter(massTextConverter);
};

export const getCampaignMassTextsSnap = (uid: string, campaignId: string) =>
  getDocs(getCampaignMassTextsRef(uid, campaignId));
export const getCampaignMassTextRef = (
  uid: string,
  campaignId: string,
  id: string
) =>
  doc(getCampaignMassTextsRef(uid, campaignId), id).withConverter(
    massTextConverter
  );
export const getCampaignMassTextSnap = (
  uid: string,
  campaignId: string,
  id: string
) => getDoc(getCampaignMassTextRef(uid, campaignId, id));
export const getCampaignMassTextData = async (
  uid: string,
  campaignId: string,
  id: string
) => await getDataFromSnap(getCampaignMassTextSnap(uid, campaignId, id));

/* User Campaign MassTextAttachment Util */
export const getMassTextAttachmentsRef = (
  uid: string,
  campaignId: string,
  massTextId: string
) => {
  return collection(
    getCampaignMassTextRef(uid, campaignId, massTextId),
    MassTextCollection.MASS_TEXT_ATTACHMENTS
  ).withConverter(massTextAttachmentConverter);
};

export const getMassTextAttachmentsSnap = (
  uid: string,
  campaignId: string,
  massTextId: string
) => getDocs(getMassTextAttachmentsRef(uid, campaignId, massTextId));
export const getMassTextAttachmentRef = (
  uid: string,
  campaignId: string,
  massTextId: string,
  attachmentId: string
) =>
  doc(
    getMassTextAttachmentsRef(uid, campaignId, massTextId),
    attachmentId
  ).withConverter(massTextAttachmentConverter);
export const getMassTextAttachmentSnap = (
  uid: string,
  campaignId: string,
  massTextId: string,
  attachmentId: string
) =>
  getDoc(getMassTextAttachmentRef(uid, campaignId, massTextId, attachmentId));
export const getMassTextAttachmentData = async (
  uid: string,
  campaignId: string,
  massTextId: string,
  attachmentId: string
) =>
  await getDataFromSnap(
    getMassTextAttachmentSnap(uid, campaignId, massTextId, attachmentId)
  );

/* User Campaign TextRecipients Util */
export const getTextRecipientsRef = (
  uid: string,
  campaignId: string,
  massTextId: string
) => {
  return collection(
    getCampaignMassTextRef(uid, campaignId, massTextId),
    MassTextCollection.TEXT_RECIPIENTS
  ).withConverter(textRecipientConverter);
};

export const getTextRecipientsSnap = (
  uid: string,
  campaignId: string,
  massTextId: string
) => getDocs(getTextRecipientsRef(uid, campaignId, massTextId));
export const getTextRecipientRef = (
  uid: string,
  campaignId: string,
  massTextId: string,
  recipientId: string
) =>
  doc(
    getTextRecipientsRef(uid, campaignId, massTextId),
    recipientId
  ).withConverter(textRecipientConverter);
export const getTextRecipientSnap = (
  uid: string,
  campaignId: string,
  massTextId: string,
  recipientId: string
) => getDoc(getTextRecipientRef(uid, campaignId, massTextId, recipientId));
export const getTextRecipientData = async (
  uid: string,
  campaignId: string,
  massTextId: string,
  recipientId: string
) =>
  await getDataFromSnap(
    getTextRecipientSnap(uid, campaignId, massTextId, recipientId)
  );

/* User TextingRecipient TrackingData Util */
export const getTextRecipientTrackersRef = (
  uid: string,
  campaignId: string,
  massTextId: string,
  recipientId: string
) => {
  return collection(
    getTextRecipientRef(uid, campaignId, massTextId, recipientId),
    LinkTrackerCollection.TRACKING_DATA
  ).withConverter(trackingDataConverter);
};

export const getTextRecipientTrackersSnap = async (
  uid: string,
  campaignId: string,
  massTextId: string,
  recipientId: string
) =>
  await getDocs(
    getTextRecipientTrackersRef(uid, campaignId, massTextId, recipientId)
  );
export const getTextRecipientTrackerRef = (
  uid: string,
  campaignId: string,
  massTextId: string,
  recipientId: string,
  trackerId: string
) =>
  doc(
    getTextRecipientTrackersRef(uid, campaignId, massTextId, recipientId),
    trackerId
  ).withConverter(trackingDataConverter);
export const getTextRecipientTrackerSnap = async (
  uid: string,
  campaignId: string,
  massTextId: string,
  recipientId: string,
  trackerId: string
) =>
  await getDoc(
    getTextRecipientTrackerRef(
      uid,
      campaignId,
      massTextId,
      recipientId,
      trackerId
    )
  );
export const getTextRecipientTrackerData = async (
  uid: string,
  campaignId: string,
  massTextId: string,
  recipientId: string,
  trackerId: string
) =>
  await getDataFromSnap(
    getTextRecipientTrackerSnap(
      uid,
      campaignId,
      massTextId,
      recipientId,
      trackerId
    )
  );

/* SubscriptionReferral Util */
export const getSubscriptionReferralsRef = () => {
  return collection(db, Collection.SUBSCRIPTIONREFERRALS).withConverter(
    subscriptionReferralConverter
  );
};
export const getSubscriptionReferralsSnap = async () =>
  await getDocs(getSubscriptionReferralsRef());
export const getSubscriptionReferralRef = (referralId: string) => {
  return doc(db, Collection.SUBSCRIPTIONREFERRALS, referralId).withConverter(
    subscriptionReferralConverter
  );
};
export const getSubscriptionReferralSnap = async (referralId: string) =>
  await getDoc(getSubscriptionReferralRef(referralId));
export const getSubscriptionReferralData = async (referralId: string) =>
  await getDataFromSnap(
    getSubscriptionReferralSnap(referralId),
    "subscriptionReferral " + referralId
  );

export const getFeaturedUsersRef = () => {
  return collection(db, Collection.FEATURED_USERS).withConverter(
    featuredUserConverter
  );
};

export const getFeaturedUsersSnap = async () => await getDocs(getUsersRef());

export const getFeaturedUserRef = (uid: string) => {
  return doc(db, Collection.FEATURED_USERS, uid).withConverter(
    featuredUserConverter
  );
};

export const getFeaturedUserSnap = async (uid: string) =>
  await getDoc(getFeaturedUserRef(uid));

export const getFeaturedUserData = async (uid: string) =>
  await getDataFromSnap(getFeaturedUserSnap(uid));

export const getFeaturedEventsRef = () => {
  return collection(db, Collection.FEATURED_EVENTS).withConverter(
    featuredEventsConverter
  );
};
export const getFeaturedEventsSnap = async () => await getDocs(getEventsRef());

export const getFeaturedEventRef = (eventId: string) => {
  return doc(db, Collection.FEATURED_EVENTS, eventId).withConverter(
    featuredEventsConverter
  );
};

export const getFeaturedEventSnap = async (eventId: string) =>
  await getDoc(getFeaturedEventRef(eventId));

export const getFeaturedEventData = async (eventId: string) =>
  await getDataFromSnap(getFeaturedEventSnap(eventId));
