import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import React, { CSSProperties, useCallback, useMemo } from "react";
import { MARKIT_WEBSITE, textTimeWithinQuietHours } from "@markit/common.utils";
import { ScheduleTextInfo } from "@markit/common.types";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";

type QuietHoursNoticeProps = {
  scheduleTextInfo: ScheduleTextInfo;
  containerStyles?: CSSProperties;
};

const QuietHoursNotice = (props: QuietHoursNoticeProps) => {
  const { scheduleTextInfo, containerStyles } = props;
  const bypassQuietHours =
    useSelector(getAccountState).account.accountData.bypassQuietHours;

  const styles = {
    quietHoursContainer: {
      ...containerStyles,
      borderRadius: 12,
      padding: 14,
      backgroundColor: "rgba(255, 204, 0, 0.2)",
      border: `1px solid ${Colors.YELLOW1}`,
    },
  };

  const withinQuietHours = useMemo(
    () => textTimeWithinQuietHours(scheduleTextInfo),
    [scheduleTextInfo]
  );

  const learnMoreOnPress = useCallback(() => {
    window.open(`${MARKIT_WEBSITE}/quiethours`);
  }, []);

  return withinQuietHours && !bypassQuietHours ? (
    <StandardBorderedContainer containerStyles={styles.quietHoursContainer}>
      <div className="AlignedRow" style={{ gap: 14 }}>
        <div>
          <Icon icon="ion:moon" height={24} color={Colors.BLACK} />
        </div>
        <div className="ColumnNormal" style={{ gap: 7 }}>
          <span className="bodyMedium">Quiet Hours</span>
          <span style={{ fontSize: 12 }}>
            Some locations only permit SMS marketing between 8:00 AM and 7:30
            PM. Your current send time may not comply with these restrictions.
            Adjust your send time to prevent potential disruptions.
          </span>
          <div
            className="AlignedRowSelect"
            onClick={learnMoreOnPress}
            style={{ gap: 2 }}
          >
            <span style={{ fontSize: 12, fontWeight: 500 }}>Learn More</span>
            <Icon icon="ion:chevron-right" height={14} />
          </div>
        </div>
      </div>
    </StandardBorderedContainer>
  ) : null;
};

export default QuietHoursNotice;
