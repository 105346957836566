import { memo, useMemo } from "react";
import PopupModalContainer from "../../../Containers/PopupModalContainer";
import { Icon } from "@iconify/react";
import RectangleButton from "../../../Buttons/RectangleButton";
import { Colors } from "../../../../utils/colors";

type ConfirmSaveListMembersModalProps = {
  initialListMembers: string[];
  newListMembers: string[];
  saving: boolean;
  setModalVisible: (modalVisible: boolean) => void;
  saveOnPress: () => void;
};

export const ConfirmSaveListMembersModal = memo(
  function ConfirmSaveListMembersModalFn(
    props: ConfirmSaveListMembersModalProps
  ) {
    const {
      initialListMembers,
      newListMembers,
      saving,
      setModalVisible,
      saveOnPress,
    } = props;

    const newAddedMembers = useMemo(() => {
      const oldSet = new Set(initialListMembers);
      const added = newListMembers.filter((item) => !oldSet.has(item)).length;

      return added;
    }, [initialListMembers, newListMembers]);

    const removedMembers = useMemo(() => {
      const newSet = new Set(newListMembers);
      const removed = initialListMembers.filter(
        (item) => !newSet.has(item)
      ).length;

      return removed;
    }, [initialListMembers, newListMembers]);

    return (
      <PopupModalContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 14, fontWeight: 400 }}>
            <Icon icon={"ion:people"} height={35} />
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <span className="sectionTitle">Save List Members</span>
              <span className="smallBodySubtext">
                {newAddedMembers > 0
                  ? `You are adding ${newAddedMembers} ${
                      newAddedMembers !== 1 ? "people" : "person"
                    }${
                      removedMembers > 0
                        ? ` and removing ${removedMembers} ${
                            removedMembers !== 1 ? "people" : "person"
                          }`
                        : ""
                    }.`
                  : `You are removing ${removedMembers} ${
                      removedMembers !== 1 ? "people" : "person"
                    }.`}
              </span>
            </div>
          </div>
        }
        valueComp={
          <div className="AlignedRow" style={{ gap: 10 }}>
            <RectangleButton
              buttonLabel="Cancel"
              onPress={() => setModalVisible(false)}
              altTextColor={Colors.BLACK}
              altColor={Colors.GRAY6}
              altPaddingVert={14}
            />
            <RectangleButton
              buttonLabel={"Save"}
              onPress={saveOnPress}
              altTextColor={Colors.WHITE}
              altColor={Colors.BLACK}
              altPaddingVert={14}
              loading={saving}
            />
          </div>
        }
        noExit
        closeModal={() => setModalVisible(false)}
      />
    );
  }
);
