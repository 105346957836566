import React, { memo, useState, useMemo, useCallback, useContext } from "react";
import { useTheme } from "../../hooks/useTheme";
import { Icon } from "@iconify/react";
import {
  AccountData,
  Event,
  AudienceList,
  AudienceListVisibility,
  FollowType,
  Follower,
  SavedFormResponse,
  TicketV2,
  SavedFormQuestion,
  FollowerStatus,
  NotificationType,
  MembershipState,
  MANUAL_ADD_CONTACT,
  FollowerMembershipState,
} from "@markit/common.types";
import { Colors } from "../../utils/colors";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../redux/store";
import {
  FAVORITES_LIST_NAME,
  GetDateWithTime,
  abbreviateNumber,
  convertToMoney,
  friendlyRoleName,
  truncateString,
} from "@markit/common.utils";
import SingleCustomDataItem from "./SingleCustomDataItem";
import BlackHoverButton from "../Buttons/BlackHoverButton";
import { MixpanelContext } from "../../context/AnalyticsService";
import LabelButton from "../Buttons/LabelButton";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import ProfilePic from "../ProfilePic";
import useAsyncOnMount from "../../hooks/useAsyncEffectOnMount";
import ShowMoreButton from "../Buttons/ShowMoreButton";
import { showNotificationBanner } from "../../utils/notificationUtils";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import { checkAudienceListMembership } from "../../utils/audienceListUtils";
import {
  addMultipleAudienceListMembers,
  removeSingleAudienceListMember,
} from "../../redux/slices/accountSlice";
import { getShortMembershipFrequencyLabel } from "../../utils/subscriptionUtils";

export type RecentEventAnalytics = {
  event: Event;
  numTickets: number;
};

type FollowerProfileHeaderProps = {
  fullProfile: boolean;
  creatorUid: string;
  userData: AccountData;
  followerData: Follower | undefined;
  followerEventData: Event | undefined;
  allFormResponses?: (SavedFormResponse | undefined)[];
  essentialFormResponse: SavedFormResponse | undefined;
  recentEventsData: RecentEventAnalytics[];
  joinedAudienceLists: AudienceList[];
  numMassTexts?: number;
  essentialQuestion: SavedFormQuestion | undefined;
  savedQuestionsToShow?: SavedFormQuestion[];
  notHost: boolean;
  fullProfileOnPress?: () => void;
  closeProfilePreview?: () => void;
  addToListOnPress?: () => void;
  subscriptionHistoryOnPress: () => void;
  conversationOnPress: () => void;
  userTicket?: TicketV2 | undefined;
};

export const FollowerProfileHeader = memo(function FollowerProfileHeaderFn(
  props: FollowerProfileHeaderProps
) {
  const {
    fullProfile,
    creatorUid,
    userData,
    followerData,
    followerEventData,
    allFormResponses,
    essentialFormResponse,
    recentEventsData,
    joinedAudienceLists,
    numMassTexts,
    essentialQuestion,
    savedQuestionsToShow,
    notHost,
    fullProfileOnPress,
    addToListOnPress,
    subscriptionHistoryOnPress,
    closeProfilePreview, // todo (jonathan): use this when add to list empty state is added on desktop
    conversationOnPress,
    userTicket,
  } = props;
  const mixpanel = useContext(MixpanelContext);
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const { accountData, audienceListDetails } = useSelector(
    (state: AppState) => state.account
  );

  const isAdmin = useSelector(
    (state: AppState) => state.account.accountData.isAdmin
  );

  const [showMoreCustomData, setShowMoreCustomData] = useState(true);
  const [showMoreRecentEvents, setShowMoreRecentEvents] = useState(true);
  const [showMoreJoinedAudienceLists, setShowMoreJoinedAudienceLists] =
    useState(true);

  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [isFavorited, setIsFavorited] = useState<boolean>(false);

  const styles = {
    aligned: {
      alignItems: "center",
      display: "flex",
    },

    analyticsPanel: {
      border: `1.5px solid ${theme.DividerColor.borderColor}`,
      borderRadius: 8,
      padding: 10,
      marginBottom: 14,
    },

    emptyEventBox: {
      width: 50,
      height: 50,
      borderRadius: 8,
      backgroundColor: Colors.GRAY8,
    },

    emptyEventText: {
      justifyContent: "center",
      alignItems: "center",
      left: 0,
      right: 0,
    },

    headerItems: {
      justifyContent: "space-between",
    },

    headerTitle: {
      marginLeft: 14,
      fontSize: 12,
      fontWeight: 400,
    },

    largeNumber: {
      fontSize: 24,
      fontWeight: "500",
    },

    listAction: {
      width: 28,
      height: 28,
      borderRadius: 28,
      border: `1.5px solid ${theme.DividerColor.borderColor}`,
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    profileImage: {
      width: 48,
      height: 48,
      borderRadius: 50 / 2,
      overflow: "hidden",
      backgroundColor: theme.EmptyBG.backgroundColor,
    },

    profileBox: {
      justifyContent: "space-between",
      marginBottom: 14,
      paddingInline: 14,
      paddingBlock: 7,
    },

    sectionContainer: {
      marginTop: 4,
      marginBottom: 14,
      paddingBlock: 14,
    },

    smallBlueText: {
      color: Colors.BLUE5,
      fontSize: 12,
      fontWeight: "400",
    },

    subHeaderTitle: {
      fontSize: 14,
      fontWeight: "500",
    },

    subSectionContainer: {
      paddingInline: 14,
    },

    valuesRow: {
      justifyContent: "space-between",
      maxWidth: "80%",
    },
  };

  const audienceLists = useMemo(
    () => audienceListDetails.map((listDetail) => listDetail.list),
    [audienceListDetails]
  );

  // only for full profile vieew
  const totalTickets = useMemo(
    () =>
      recentEventsData
        ? recentEventsData.reduce((acc, curr) => acc + curr.numTickets, 0)
        : 0,
    [recentEventsData]
  );

  const privateAudienceLists = useMemo(
    () =>
      joinedAudienceLists
        ? joinedAudienceLists.filter(
            (list) => list.visibility === AudienceListVisibility.PRIVATE
          )
        : [],
    [joinedAudienceLists]
  );

  const publicAudienceLists = useMemo(
    () =>
      joinedAudienceLists
        ? joinedAudienceLists.filter(
            (list) => list.visibility === AudienceListVisibility.PUBLIC
          )
        : [],
    [joinedAudienceLists]
  );

  const favoriteList = useMemo(
    () => audienceLists.find((list) => list.name === FAVORITES_LIST_NAME),
    [audienceLists]
  );

  useAsyncOnMount(async () => {
    // Live set the favorites list state
    const isFavorited = await checkAudienceListMembership(
      userData.uid,
      favoriteList ? favoriteList.id : "",
      accountData.uid
    );
    setIsFavorited(isFavorited);
  });

  const followedFrom = useMemo(
    () =>
      followerData === undefined
        ? "Not Following"
        : followerData && followerData.followType !== FollowType.UNATTRIBUTED
        ? followerData.followType === FollowType.PROFILE
          ? "Followed Profile On"
          : followerData.followType === FollowType.EVENT
          ? `Went to Event${
              followerEventData ? ": " + followerEventData.title : ""
            }`
          : followerData.followType === FollowType.LINK
          ? `Submitted to Link${
              followerEventData ? ": " + followerEventData.title : ""
            }`
          : followerData.followType === FollowType.UPLOAD
          ? followerData.spreadsheetData.fileName === MANUAL_ADD_CONTACT
            ? "Manually Added Contact"
            : `Uploaded from ${
                followerData.spreadsheetData.fileName ?? "Import Contacts"
              }`
          : followerData.followType === FollowType.EVENTBRITE
          ? "Imported from Eventbrite"
          : "Followed On" // other
        : "Followed On",
    [followerData, followerEventData]
  );

  const followedOn = useMemo(
    () =>
      followerData === undefined
        ? "Not Following"
        : followerData && followerData.followType !== FollowType.UNATTRIBUTED
        ? GetDateWithTime(followerData.createdAt)
        : "Prior to Tracking",
    [followerData]
  );

  const invalidErrorMessage = useMemo(
    () =>
      followerData
        ? `${
            followerData.invalidErrorCode === 30005
              ? "Invalid or deactivated number "
              : followerData.invalidErrorCode === 30003
              ? "Temporary handset issue "
              : followerData.invalidErrorCode === 30006
              ? "Landline number or text unsupported "
              : " "
          }(Code: ${followerData.invalidErrorCode})`
        : "",
    [followerData]
  );

  const followerMembershipPlan = useMemo(
    () =>
      accountData.membership.membershipPlans.find(
        (plan) => plan.id === followerData?.membershipPlanId
      ),
    [accountData.membership.membershipPlans, followerData?.membershipPlanId]
  );

  const pressFavorite = useCallback(() => {
    setFavoriteLoading(true);
    if (favoriteList) {
      if (isFavorited) {
        dispatch(
          removeSingleAudienceListMember(creatorUid, favoriteList, userData.uid)
        );
        setIsFavorited(false);
        showNotificationBanner(
          dispatch,
          "Removed from Favorites",
          NotificationType.NEGATIVE
        );
      } else {
        mixpanel.track("Added Follower To Favorites List", {
          uid: creatorUid,
        });
        dispatch(
          addMultipleAudienceListMembers(
            creatorUid,
            favoriteList,
            [userData.uid],
            true
          )
        );
        setIsFavorited(true);
        showNotificationBanner(
          dispatch,
          "Added to Favorites",
          NotificationType.AFFIRMATIVE
        );
      }
    }
    setFavoriteLoading(false);
  }, [isFavorited, favoriteList, dispatch, creatorUid, userData.uid, mixpanel]);

  const emptyListsState = (label: string) => {
    return (
      <div style={{ ...styles.subSectionContainer }}>
        <div className="RowNormal" style={{ paddingBlock: 9 }}>
          <span className="bodySubtext">{label}</span>
        </div>
      </div>
    );
  };

  const renderSection = (
    title: string,
    onPress: () => void,
    body: JSX.Element
  ) => {
    return (
      <StandardBorderedContainer
        //   onClick={onPress} // todo (jonathan): disabled until we introduce new individual mass text analytics
        containerStyles={{
          ...styles.sectionContainer,
          backgroundColor: theme.NewPrimaryBG.backgroundColor,
          //   cursor: "pointer",
        }}
      >
        <div
          className="AlignedRow"
          style={{
            ...styles.headerItems,
            ...styles.subSectionContainer,
          }}
        >
          <span style={{ fontWeight: "600", fontSize: 14 }}>{title}</span>
          <div className="AlignedRow">
            <span style={{ fontWeight: "600", fontSize: 14 }}>All Data</span>
            <Icon
              icon="mdi:chevron-right"
              height={26}
              color={theme.SubText.color}
            />
          </div>
        </div>
        <HorizontalDivider altMargin={14} />
        <div style={{ ...styles.subSectionContainer }}>{body}</div>
      </StandardBorderedContainer>
    );
  };

  return (
    <>
      <div
        className="ColumnNormal"
        style={{
          maxHeight: fullProfile ? undefined : "87vh", // hard code max height on profile preview to get overflow scrolling working
          height: fullProfile ? "calc(100vh - 120px)" : undefined, // set fixed height to fix overflow on full profile
        }}
      >
        <StandardBorderedContainer
          containerStyles={{
            ...styles.profileBox,
            ...styles.aligned,
            flexDirection: "row",
            backgroundColor: theme.NewPrimaryBG.backgroundColor,
          }}
        >
          <div className="AlignedRow">
            <ProfilePic user={userData} size={48} />
            <div style={{ paddingLeft: 10 }}>
              <div style={{ marginBottom: fullProfile ? 0 : 4 }}>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: "500",
                    ...theme.PrimaryText,
                  }}
                >
                  {userData?.fullName || "Markit User"}
                </span>
              </div>
              <div className="AlignedRow">
                {!fullProfile && (!notHost || isAdmin) ? (
                  <div
                    className="AlignedRowBottomSelect"
                    onClick={fullProfileOnPress}
                  >
                    <span style={styles.smallBlueText}>Full Profile</span>
                    <Icon
                      icon="ion:chevron-forward-outline"
                      height={14}
                      color={Colors.BLUE5}
                    />
                  </div>
                ) : null}
                {notHost ? (
                  <span
                    style={{
                      color: theme.SubText.color,
                      fontSize: 12,
                      fontWeight: "500",
                    }}
                  >
                    {`Viewing as ${
                      isAdmin ? "Admin" : friendlyRoleName(userTicket)
                    } `}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="AlignedRow" style={{ gap: 10 }}>
            {!notHost &&
            followerData &&
            followerData.status === FollowerStatus.SUBSCRIBED ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {fullProfile ? (
                  <BlackHoverButton
                    leftIconName="ion:chatbubble-ellipses-outline"
                    leftIconSize={16}
                    onPress={conversationOnPress}
                    altBackgroundColor={Colors.WHITE}
                    containerStyles={styles.listAction}
                  />
                ) : null}
              </div>
            ) : null}
            {audienceLists.length > 0 && addToListOnPress ? (
              <BlackHoverButton
                leftIconName="mdi:playlist-plus"
                leftIconSize={18}
                onPress={addToListOnPress}
                altBackgroundColor={Colors.WHITE}
                containerStyles={styles.listAction}
              />
            ) : null}
            <BlackHoverButton
              leftIconName="ion:star-outline"
              leftIconSize={18}
              onPress={pressFavorite}
              altBackgroundColor={Colors.WHITE}
              permanentHover={isFavorited}
              containerStyles={{
                ...styles.listAction,
                pointerEvents: favoriteLoading ? "none" : "all",
              }}
            />
          </div>
        </StandardBorderedContainer>
        <span style={{ ...theme.SubText, ...styles.headerTitle }}>
          Essential
        </span>
        <StandardBorderedContainer
          containerStyles={{
            ...styles.sectionContainer,
            backgroundColor: theme.NewPrimaryBG.backgroundColor,
          }}
        >
          {essentialQuestion && userData ? (
            <SingleCustomDataItem
              savedQuestion={essentialQuestion}
              formResponse={essentialFormResponse}
              userData={userData}
              followerData={followerData}
              pressable={fullProfile}
              detailView={fullProfile}
            />
          ) : null}
          <div style={{ ...styles.subSectionContainer }}>
            <div style={{ marginBottom: 4 }}>
              <span className="bodySubtext">{followedFrom}</span>
            </div>
            <div>
              <span className="bodyMedium">{followedOn}</span>
            </div>
          </div>
          <HorizontalDivider altMargin={14} />
          <div style={{ ...styles.subSectionContainer }}>
            <div style={{ marginBottom: 4 }}>
              <span className="bodySubtext">Phone Number</span>
            </div>
            <div>
              <span className="bodyMedium">
                {followerData
                  ? formatPhoneNumber(
                      followerData.phoneNumber || userData.phoneNumber // back support if follower data made before phoneNumber field was added
                    )
                  : ""}
              </span>
            </div>
          </div>
          <HorizontalDivider altMargin={14} />
          <div style={{ ...styles.subSectionContainer }}>
            <div style={{ marginBottom: 7 }}>
              <span className="bodySubtext">Status</span>
            </div>
            <div>
              <LabelButton
                color={
                  followerData &&
                  followerData.status === FollowerStatus.SUBSCRIBED
                    ? Colors.GREEN2
                    : Colors.RED3
                }
                backgroundColor={Colors.WHITE}
                text={followerData ? followerData.status : ""}
              />
            </div>
            {followerData && followerData.invalidErrorCode !== 0 ? (
              <div style={{ marginTop: 7 }}>
                <span className="smallBodySubtext">{invalidErrorMessage}</span>
              </div>
            ) : null}
          </div>
          {followerMembershipPlan &&
          accountData.membership.state === MembershipState.ACTIVE ? (
            <>
              <HorizontalDivider altMargin={14} />
              <div
                className="ColumnNormal"
                style={{ ...styles.subSectionContainer, gap: 7 }}
              >
                <span className="bodySubtext">Plan</span>
                {followerData &&
                followerData.membershipState ===
                  FollowerMembershipState.PAYMENT_FAILED ? (
                  <div>
                    <LabelButton
                      color={Colors.RED3}
                      backgroundColor={Colors.WHITE}
                      text={"Payment Failed"}
                    />
                  </div>
                ) : null}
                <div className="ColumnNormal" style={{ gap: 4 }}>
                  <span className="bodyMedium">
                    {followerMembershipPlan.name} -{" "}
                    {convertToMoney(followerMembershipPlan.price / 100)}/
                    {getShortMembershipFrequencyLabel(
                      followerMembershipPlan.billingFrequency
                    )}
                  </span>
                  <div
                    onClick={subscriptionHistoryOnPress}
                    className="AlignedRowSelect"
                    style={{ gap: 4 }}
                  >
                    <span style={styles.smallBlueText}>
                      View subscription history
                    </span>
                    <Icon
                      icon="ion:chevron-right"
                      height={10}
                      color={Colors.BLUE5}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {fullProfile ? (
            <>
              <HorizontalDivider altMargin={14} />
              <div style={{ ...styles.subSectionContainer }}>
                <div style={{ marginBottom: 4 }}>
                  <span className="bodySubtext">Follow Source</span>
                </div>
                <div>
                  <span className="bodyMedium">
                    {followerData ? followerData.followSourceType : "N/A"}
                  </span>
                </div>
              </div>
            </>
          ) : null}
        </StandardBorderedContainer>
        {fullProfile &&
        savedQuestionsToShow &&
        savedQuestionsToShow.length > 0 ? (
          <>
            <span style={{ ...theme.SubText, ...styles.headerTitle }}>
              Custom Data
            </span>
            <StandardBorderedContainer
              containerStyles={{
                ...styles.sectionContainer,
                backgroundColor: theme.NewPrimaryBG.backgroundColor,
              }}
            >
              {fullProfile && userData && allFormResponses
                ? savedQuestionsToShow
                    .slice(
                      0,
                      savedQuestionsToShow.length > 8 && showMoreCustomData
                        ? 8
                        : savedQuestionsToShow.length
                    )
                    .map((question, index) => (
                      <div key={index}>
                        <SingleCustomDataItem
                          savedQuestion={question}
                          formResponse={allFormResponses[index]}
                          userData={userData}
                          followerData={followerData}
                          pressable
                          lastItem={savedQuestionsToShow.length === index + 1}
                          detailView={fullProfile}
                        />
                      </div>
                    ))
                : null}
              {savedQuestionsToShow.length > 8 && showMoreCustomData ? (
                <ShowMoreButton
                  onPress={() => setShowMoreCustomData(!showMoreCustomData)}
                  containerStyles={styles.subSectionContainer}
                />
              ) : null}
            </StandardBorderedContainer>
          </>
        ) : null}
        <span style={{ ...theme.SubText, ...styles.headerTitle }}>Events</span>
        <StandardBorderedContainer
          containerStyles={{
            ...styles.sectionContainer,
            backgroundColor: theme.NewPrimaryBG.backgroundColor,
          }}
        >
          {recentEventsData.length !== 0 ? (
            <>
              {fullProfile ? (
                <>
                  <div style={{ ...styles.subSectionContainer }}>
                    <div
                      className="AlignedRow"
                      style={{
                        ...styles.analyticsPanel,
                      }}
                    >
                      <div className="ColumnNormal">
                        <span style={styles.largeNumber}>
                          {recentEventsData.length}
                        </span>
                        <span style={{ ...theme.SubText, fontSize: 12 }}>
                          Total Events
                        </span>
                      </div>
                      <div className="ColumnNormal" style={{ marginLeft: 24 }}>
                        <span style={styles.largeNumber}>{totalTickets}</span>
                        <span style={{ ...theme.SubText, fontSize: 12 }}>
                          Total Tickets
                        </span>
                      </div>
                    </div>
                    <span
                      style={{
                        ...theme.PrimaryText,
                        ...styles.subHeaderTitle,
                      }}
                    >
                      Recent Events
                    </span>
                  </div>
                  <HorizontalDivider altMargin={14} />
                </>
              ) : null}
              {recentEventsData
                .slice(
                  0,
                  recentEventsData.length > 3 && showMoreRecentEvents
                    ? 3
                    : recentEventsData.length
                )
                .map((data, index) => (
                  <div key={data.event.id} className="ColumnNormal">
                    <div
                      className="AlignedRow"
                      style={{ ...styles.subSectionContainer }}
                    >
                      <img
                        src={data.event.photoURL}
                        alt="EventPhotoURL"
                        style={{ width: 48, height: 48, borderRadius: 4 }}
                      />
                      <div style={{ paddingLeft: 10 }}>
                        <div style={{ marginBottom: 4 }}>
                          <span className="OneLineText bodyMedium">
                            {data.event.title}
                          </span>
                        </div>
                        <div>
                          <span style={{ color: Colors.GRAY2, fontSize: 12 }}>
                            {data.numTickets} Ticket
                            {data.numTickets !== 1 ? "s" : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    {index !== recentEventsData.length - 1 ? (
                      <HorizontalDivider altMargin={14} />
                    ) : null}
                  </div>
                ))}
              {recentEventsData.length > 3 && showMoreRecentEvents ? (
                <ShowMoreButton
                  onPress={() => setShowMoreRecentEvents(!showMoreRecentEvents)}
                  containerStyles={styles.subSectionContainer}
                />
              ) : null}
            </>
          ) : (
            emptyListsState("No Events to Show")
          )}
        </StandardBorderedContainer>
        <span style={{ ...theme.SubText, ...styles.headerTitle }}>Lists</span>
        <StandardBorderedContainer
          containerStyles={{
            ...styles.sectionContainer,
            backgroundColor: theme.NewPrimaryBG.backgroundColor,
          }}
        >
          {joinedAudienceLists.length !== 0 ? (
            <>
              {fullProfile ? (
                <>
                  <div style={{ ...styles.subSectionContainer }}>
                    <div className="AlignedRow" style={styles.analyticsPanel}>
                      <div className="ColumnNormal">
                        <span style={styles.largeNumber}>
                          {joinedAudienceLists.length}
                        </span>
                        <span style={{ ...theme.SubText, fontSize: 12 }}>
                          Joined
                        </span>
                      </div>
                      <div className="ColumnNormal" style={{ marginLeft: 24 }}>
                        <span style={styles.largeNumber}>
                          {publicAudienceLists.length}
                        </span>
                        <span style={{ ...theme.SubText, fontSize: 12 }}>
                          Public
                        </span>
                      </div>
                      <div className="ColumnNormal" style={{ marginLeft: 24 }}>
                        <span style={styles.largeNumber}>
                          {privateAudienceLists.length}
                        </span>
                        <span style={{ ...theme.SubText, fontSize: 12 }}>
                          Private
                        </span>
                      </div>
                    </div>
                    <span
                      style={{
                        ...theme.PrimaryText,
                        ...styles.subHeaderTitle,
                      }}
                    >
                      Recent Lists
                    </span>
                  </div>
                  <HorizontalDivider altMargin={14} />
                </>
              ) : null}
              {joinedAudienceLists
                .slice(
                  0,
                  joinedAudienceLists.length > 3 && showMoreJoinedAudienceLists
                    ? 3
                    : joinedAudienceLists.length
                )
                .map((list, index) => (
                  <div key={list.id}>
                    <div
                      style={{
                        ...styles.subSectionContainer,
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <div style={{ marginBottom: 4 }}>
                        <span className="bodyMedium">
                          {truncateString(list.name, 50, "...")}
                        </span>
                      </div>
                      <div>
                        <span style={{ fontSize: 12, ...theme.SubText }}>
                          {list.visibility === AudienceListVisibility.PUBLIC
                            ? "Public"
                            : "Private"}
                        </span>
                      </div>
                    </div>
                    {index !== joinedAudienceLists.length - 1 ? (
                      <HorizontalDivider altMargin={14} />
                    ) : null}
                  </div>
                ))}
              {joinedAudienceLists.length > 3 && showMoreJoinedAudienceLists ? (
                <ShowMoreButton
                  onPress={() =>
                    setShowMoreJoinedAudienceLists(!showMoreJoinedAudienceLists)
                  }
                  containerStyles={styles.subSectionContainer}
                />
              ) : null}
            </>
          ) : (
            emptyListsState("No Lists to Show")
          )}
        </StandardBorderedContainer>
        {fullProfile && numMassTexts
          ? renderSection(
              "Texting",
              () => {},
              <div className="AlignedRow" style={{ ...styles.valuesRow }}>
                <div className="ColumnNormal">
                  <span style={styles.largeNumber}>
                    {abbreviateNumber(numMassTexts)}
                  </span>
                  <span style={{ ...theme.SubText, fontSize: 12 }}>
                    Texts Received
                  </span>
                </div>
              </div>
            )
          : null}
        {/* <div style={{ paddingVertical: 48 }}>
                <div style={{ ...styles.headerItems, flexDirection: "row" }}>
                  <div>
                    <div style={styles.headerText}>
                      <span style={{ fontWeight: '500', fontSize: 20 }}>
                        Attendee Revenue
                      </span>
                      <Icon
                        iconSet={IconSets.MaterialCommunityIcons}
                        name="currency-usd"
                        size={24}
                        style={{ color: Colors.GREEN1, marginLeft: 2 }}
                      />
                    </div>
                    <div style={{ marginTop: 2 }}>
                    <span style={{ color: Colors.GRAY1 }}>
                      Your revenue generated by this user
                    </span>
                    </div>
                  </div>
                </div>
                <HorizontalDivider altMargin={14} />
                <div style={{ marginTop: 14 }}>
                  <span style={{ color: Colors.GRAY1 }}>Gross Revenue</span>
                  <span style={{ fontSize: 32 }}>${revenue}</span>
                </div>
              </div> */}
        <div style={{ paddingBottom: 20 }} />
      </div>
    </>
  );
});
