import React, { useCallback, useMemo, useState } from "react";
import {
  Event,
  OrganizerType,
  TicketV2,
  AccountData,
  TicketType,
  Campaign,
  NotificationType,
} from "@markit/common.types";
import {
  eventHasRequestTickets,
  isEventExternalLink,
  isExternalLinkNoData,
} from "@markit/common.utils";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { CircularProgress } from "@mui/material";
import EmptyStateButton from "../../components/Buttons/EmptyStateButton";
import ProfileTicketItem, {
  TICKET_FLEX_SECTIONS,
  ORGANIZER_TICKET_FLEX_SECTIONS,
} from "../../components/DisplayItem/ProfileTicketItem";
import SearchBoxContainer from "../Containers/SearchBoxContainer";
import ListTabs from "../DropdownsAndTabs/ListTabs";
import BlackHoverButton from "../Buttons/BlackHoverButton";
import { exportEventData } from "../../utils/eventUtils/formUtils";
import ScanningInstructionsModal from "../../components/EventDashboard/DashboardModals/ScanningInstructionsModal";
import { NewItemButton } from "../Buttons/NewItemButton";
import filter from "lodash.filter";
import GridTableHeader from "../Headers/GridTableHeader";
import AlertContainer from "../Containers/AlertContainer";
import { EmptyStateFlatlist } from "../EmptyStates/EmptyStateFlatlist";
import EventDashboardLinkAnalyticsBox from "./EventDashboardLinkAnalyticsBox";
import StandardListContainerBox from "../Containers/StandardListContainerBox";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { AutomationItemPreview } from "../DisplayItem/CampaignItem/AutomationItemPreview";
import { CampaignDetails } from "../../redux/slices/campaignSlice";
import { showNotificationBanner } from "../../utils/notificationUtils";
import { useDispatch, useSelector } from "react-redux";
import EventDashboardAction from "./EventDashboardAction";
import { getAccountState } from "../../redux/slices/accountSlice";
import { Virtuoso } from "react-virtuoso";

type GuestListPanelProps = {
  event: Event;
  userTicket: TicketV2 | undefined;
  totalGuests: number;
  totalRequested: number;
  totalOrganizers: number;
  totalLinkViews: number;
  checkInGuests: boolean;
  canMassText: boolean;
  dashboardVisiblity: number;
  isHost: boolean;
  loading: boolean;
  campaign: Campaign | undefined;
  campaignDetail: CampaignDetails | undefined;
  openShareModal: (source: string) => void;
  setIsEventRoleModalOpen: (isEventRoleModalOpen: boolean) => void;
  setRoleOrganizerData: (roleOrganizerData: AccountData | undefined) => void;
  setRoleOrganizerTicketItem: (
    roleOrganizerTicketItem: TicketV2 | undefined
  ) => void;
  setProfilePreviewSelected: (
    profilePreviewSelected: AccountData | undefined
  ) => void;
  isAttendeeLoading: boolean;
  fullAttendeeTicketData: TicketV2[];
  fullAttendeeAccountData: AccountData[];
  loadMoreAttendeeUsers: () => Promise<void>;
  loadAttendeeSearchResultsThrottled: (searchTerm: string) => void;
  isPendingRequestsLoading: boolean;
  fullPendingRequestsTicketData: TicketV2[];
  fullPendingRequestsAccountData: AccountData[];
  loadMorePendingRequestsUsers: () => Promise<void>;
  loadPendingRequestsSearchResultsThrottled: (searchTerm: string) => void;
  isOrganizerLoading: boolean;
  fullOrganizerTicketData: TicketV2[];
  fullOrganizerAccountData: AccountData[];
  loadMoreOrganizerUsers: () => Promise<void>;
  loadOrganizerSearchResultsThrottled: (searchTerm: string) => void;
  isAttendeeFinished: boolean;
  isPendingRequestsFinished: boolean;
  isOrganizerFinished: boolean;
  inviteGuests: () => void;
};

export const GuestListPanel = (props: GuestListPanelProps) => {
  const {
    event,
    userTicket,
    totalGuests,
    totalRequested,
    totalOrganizers,
    totalLinkViews,
    checkInGuests,
    canMassText,
    dashboardVisiblity,
    isHost,
    loading,
    campaign,
    campaignDetail,
    openShareModal,
    setIsEventRoleModalOpen,
    setRoleOrganizerData,
    setRoleOrganizerTicketItem,
    setProfilePreviewSelected,
    isAttendeeLoading,
    fullAttendeeTicketData,
    fullAttendeeAccountData,
    loadMoreAttendeeUsers,
    loadAttendeeSearchResultsThrottled,
    isPendingRequestsLoading,
    fullPendingRequestsTicketData,
    fullPendingRequestsAccountData,
    loadMorePendingRequestsUsers,
    loadPendingRequestsSearchResultsThrottled,
    isOrganizerLoading,
    fullOrganizerTicketData,
    fullOrganizerAccountData,
    loadMoreOrganizerUsers,
    loadOrganizerSearchResultsThrottled,
    isAttendeeFinished,
    isPendingRequestsFinished,
    isOrganizerFinished,
    inviteGuests,
  } = props;
  const dispatch = useDispatch();
  const followersData = useSelector(getAccountState).account.followersData;
  const [listValue, setListValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDownloading, setDownloading] = useState(false);
  const [isScanningInstructionsModalOpen, setIsScanningInstructionsModalOpen] =
    useState(false);
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });

  const styles = {
    guestListComponent: { paddingInline: 14, paddingBottom: 12 },

    suggestedButtonView: {
      gap: 30,
      marginBottom: canMassText ? 30 : 0,
    },

    submissionsHeader: {
      fontSize: 14,
      fontWeight: "500",
      paddingInline: 10,
    },
  };

  const organizerTab = useMemo(() => listValue === 1, [listValue]);

  const hasRequestTicket = useMemo(
    () =>
      event &&
      event.customTickets.find(
        (customTicket) => customTicket.type === TicketType.REQUEST
      ),
    [event]
  );

  const hasEventPeopleHost = useMemo(
    () => (totalGuests > 0 || totalRequested > 0) && isHost,
    [isHost, totalGuests, totalRequested]
  );

  const isExternalLink = useMemo(
    () => isEventExternalLink(event.eventType),
    [event.eventType]
  );

  const containsUser = useCallback((item: AccountData, query: string) => {
    return item.fullName.toLowerCase().includes(query);
  }, []);

  const attendeeTicketsToShow = useMemo(() => {
    const ticketsToShow: TicketV2[] = fullAttendeeTicketData;
    const usersToShow: AccountData[] = fullAttendeeAccountData;
    if (searchTerm === "") {
      return ticketsToShow;
    }
    let userList: AccountData[] = usersToShow;
    userList = filter(userList, (user: AccountData) => {
      return containsUser(user, searchTerm);
    });
    const tickets: TicketV2[] = [];
    for (let i = 0; i < userList.length; i++) {
      tickets.push(...ticketsToShow.filter((t) => t.uid === userList[i].uid));
    }

    return tickets;
  }, [
    fullAttendeeTicketData,
    fullAttendeeAccountData,
    searchTerm,
    containsUser,
  ]);

  const onExportFinish = useCallback(
    (success: boolean) => {
      if (success) {
        if (alertText.heading !== "Export Failed") {
          showNotificationBanner(
            dispatch,
            "Data Exported",
            NotificationType.AFFIRMATIVE
          );
        }
      } else {
        setAlertText({
          heading: "Export Failed",
          subHeading: "Please try again or contact our hotline.",
        });
      }
    },
    [alertText.heading, dispatch]
  );
  const exportEventOnClick = useCallback(async () => {
    if (dashboardVisiblity >= 2) {
      await exportEventData(
        event,
        isDownloading,
        setDownloading,
        onExportFinish
      );
    } else {
      setAlertText({
        heading: "Not allowed to export data in this role",
        subHeading: "",
      });
    }
  }, [dashboardVisiblity, event, isDownloading, onExportFinish]);
  const organizerTicketsToShowGuestList = useMemo(() => {
    const ticketsToShow: TicketV2[] = fullOrganizerTicketData;
    const usersToShow: AccountData[] = fullOrganizerAccountData;
    if (searchTerm === "") {
      return ticketsToShow.sort(function (x, y) {
        return (
          new Date(x.createdAt).getTime() - new Date(y.createdAt).getTime()
        );
      });
    }
    let userList: AccountData[] = usersToShow;
    userList = filter(userList, (user: AccountData) => {
      return containsUser(user, searchTerm);
    });
    const ticketList: TicketV2[] = [];
    for (let i = 0; i < userList.length; i++) {
      ticketList.push(
        ...ticketsToShow.filter((t) => t.uid === userList[i].uid)
      );
    }
    return ticketList;
  }, [
    containsUser,
    fullOrganizerAccountData,
    fullOrganizerTicketData,
    searchTerm,
  ]);

  const pendingRequestsTicketsToShow = useMemo(() => {
    const ticketsToShow: TicketV2[] = fullPendingRequestsTicketData;
    const usersToShow: AccountData[] = fullPendingRequestsAccountData;
    if (searchTerm === "") {
      return ticketsToShow;
    }
    let userList: AccountData[] = usersToShow;
    userList = filter(userList, (user: AccountData) => {
      return containsUser(user, searchTerm);
    });
    const tickets: TicketV2[] = [];
    for (let i = 0; i < userList.length; i++) {
      tickets.push(...ticketsToShow.filter((t) => t.uid === userList[i].uid));
    }
    return tickets;
  }, [
    containsUser,
    fullPendingRequestsAccountData,
    fullPendingRequestsTicketData,
    searchTerm,
  ]);

  const currentListToShow = useMemo(
    () =>
      listValue === 0
        ? attendeeTicketsToShow
        : listValue === 1
        ? organizerTicketsToShowGuestList
        : pendingRequestsTicketsToShow,
    [
      attendeeTicketsToShow,
      listValue,
      organizerTicketsToShowGuestList,
      pendingRequestsTicketsToShow,
    ]
  );

  const handleSearch = (text: string) => {
    const lowerCaseText = text.toLowerCase();
    if (text !== "") {
      if (listValue === 0) {
        loadAttendeeSearchResultsThrottled(lowerCaseText);
      } else if (listValue === 1) {
        loadOrganizerSearchResultsThrottled(lowerCaseText);
      } else if (listValue === 2 && hasRequestTicket) {
        loadPendingRequestsSearchResultsThrottled(lowerCaseText);
      }
    }
    setSearchTerm(lowerCaseText);
  };

  const allGuestAccountData = useMemo(
    () =>
      fullAttendeeAccountData
        .concat(fullOrganizerAccountData)
        .concat(fullPendingRequestsAccountData),
    [
      fullAttendeeAccountData,
      fullOrganizerAccountData,
      fullPendingRequestsAccountData,
    ]
  );

  const renderTicketItem = useCallback(
    (item: TicketV2) => {
      return (
        <ProfileTicketItem
          ticket={item}
          event={event}
          allGuestAccountData={allGuestAccountData}
          listView={listValue}
          userTicket={userTicket}
          checkInGuests={checkInGuests}
          setProfilePreviewSelected={setProfilePreviewSelected}
          setIsEventRoleModalOpen={setIsEventRoleModalOpen}
          setRoleOrganizerData={setRoleOrganizerData}
          setRoleOrganizerTicketItem={setRoleOrganizerTicketItem}
        />
      );
    },
    [
      allGuestAccountData,
      checkInGuests,
      event,
      listValue,
      setIsEventRoleModalOpen,
      setProfilePreviewSelected,
      setRoleOrganizerData,
      setRoleOrganizerTicketItem,
      userTicket,
    ]
  );

  const renderEmptyGuestList = useMemo(() => {
    const isLoading =
      listValue === 0
        ? isAttendeeLoading
        : listValue === 1
        ? isOrganizerLoading
        : isPendingRequestsLoading;
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoading}
        containerStyles={{ paddingTop: 120 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title={
              isExternalLink
                ? "No Submissions"
                : listValue === 2
                ? "No Requests"
                : userTicket && userTicket.role.massTexting
                ? "Invite Guests"
                : "No Guests"
            }
            description={
              isExternalLink
                ? "Share link to start collecting data"
                : userTicket && userTicket.role.massTexting
                ? "Text your following to grow your guest list"
                : ""
            }
            icon={
              <Icon
                icon={isExternalLink ? "ion:share" : "ion:people"}
                height={70}
                style={{ color: Colors.GRAY1 }}
              />
            }
            iconBox={109.5}
            btnText={
              isExternalLink
                ? "Share Link"
                : userTicket && userTicket.role.massTexting
                ? "Invite Following"
                : ""
            }
            onPress={() =>
              isExternalLink
                ? openShareModal("empty state external")
                : inviteGuests()
            }
            containerStyles={{ paddingTop: 120 }}
          />
        }
      />
    );
  }, [
    inviteGuests,
    isAttendeeLoading,
    isExternalLink,
    isOrganizerLoading,
    isPendingRequestsLoading,
    listValue,
    openShareModal,
    searchTerm,
    userTicket,
  ]);

  const EmptyPlaceholder = useCallback(() => {
    return renderEmptyGuestList;
  }, [renderEmptyGuestList]);

  const Footer = useCallback(() => {
    return (isAttendeeLoading && listValue === 0 && !isAttendeeFinished) ||
      (isOrganizerLoading && listValue === 1 && !isOrganizerFinished) ||
      (isPendingRequestsLoading &&
        listValue === 2 &&
        !isPendingRequestsFinished) ? (
      <div className="Centering" style={{ paddingBlock: 14 }}>
        <CircularProgress style={{ color: Colors.GRAY1 }} size={24} />
      </div>
    ) : listValue === 1 && currentListToShow.length > 0 ? (
      <NewItemButton
        onPress={() => setIsEventRoleModalOpen(true)}
        label="Add Organizer..."
        subText={isHost ? "" : "Only available for event owner"}
        disabled={!isHost}
      />
    ) : (
      <div style={{ height: 40 }}></div>
    );
  }, [
    currentListToShow.length,
    isAttendeeFinished,
    isAttendeeLoading,
    isHost,
    isOrganizerFinished,
    isOrganizerLoading,
    isPendingRequestsFinished,
    isPendingRequestsLoading,
    listValue,
    setIsEventRoleModalOpen,
  ]);

  const renderGuestList = useMemo(
    () => (
      <Virtuoso
        style={{ height: "100%" }}
        data={currentListToShow}
        endReached={() => {
          if (searchTerm.trim() === "") {
            if (listValue === 0) {
              loadMoreAttendeeUsers();
            } else if (listValue === 1) {
              loadMoreOrganizerUsers();
            } else if (listValue === 2) {
              loadMorePendingRequestsUsers();
            }
          }
        }}
        increaseViewportBy={200}
        itemContent={(index, item) => {
          return renderTicketItem(item);
        }}
        components={{ Footer, EmptyPlaceholder }}
      />
    ),
    [
      EmptyPlaceholder,
      Footer,
      currentListToShow,
      listValue,
      loadMoreAttendeeUsers,
      loadMoreOrganizerUsers,
      loadMorePendingRequestsUsers,
      renderTicketItem,
      searchTerm,
    ]
  );

  return (
    <>
      {isScanningInstructionsModalOpen ? (
        <ScanningInstructionsModal
          setIsScanningInstructionsModalOpen={
            setIsScanningInstructionsModalOpen
          }
        />
      ) : null}
      <div className="AlignedRow" style={styles.suggestedButtonView}>
        <EventDashboardAction
          title="Share"
          iconName={"ion:share"}
          iconSize={31}
          onPress={() => openShareModal("suggested action")}
          visible={true}
          containerWidth={"100%"}
        />
        {followersData.length > 0 ? (
          <EventDashboardAction
            title={
              hasEventPeopleHost
                ? "Text Reminder"
                : isExternalLink
                ? "Text Following"
                : "Invite Following"
            }
            iconName={hasEventPeopleHost ? "ion:add-circle" : "ion:person-add"}
            iconSize={31}
            onPress={inviteGuests}
            visible={true}
            containerWidth={"100%"}
          />
        ) : null}
      </div>
      <div className="ColumnNormal" style={{ gap: 30 }}>
        {campaign && campaignDetail ? (
          <StandardBorderedContainer
            containerStyles={{
              backgroundColor: Colors.WHITE,
              padding: 14,
              borderRadius: 14,
              flex: 1,
            }}
          >
            <AutomationItemPreview
              campaignItem={campaign}
              campaignDetail={campaignDetail}
              onEventDashboard
            />
          </StandardBorderedContainer>
        ) : null}
        {userTicket && userTicket.role.type === OrganizerType.PROMOTER ? (
          <div
            className="EventDashboardRightContainer"
            style={{ marginBottom: 500 }}
          >
            <div className="EventDashboardPromoterContainer">
              <div>
                <div className="EventDashboardContentHeader">
                  Promoter Analytics
                </div>
                <div className="EventDashboardContentDescription">
                  Manage advanced analytics and get your promoter link in app
                </div>
              </div>
              <div className="EventDashboardDarkGrayContainer">
                Manage in app
              </div>
            </div>
          </div>
        ) : null}
        {userTicket &&
        userTicket.role.type === OrganizerType.ALTERNATEPAYMENTCOLLECTOR ? (
          <div
            className="EventDashboardRightContainer"
            style={{ marginBottom: "14px" }}
          >
            <div className="EventDashboardPromoterContainer">
              <div>
                <div className="EventDashboardContentHeader">
                  You&apos;re All Set
                </div>
                <div className="EventDashboardContentDescription">
                  Contact the event host with questions or to change your role
                </div>
              </div>
              <div className="EventDashboardDarkGrayContainer">
                Contact Host
              </div>
            </div>
          </div>
        ) : null}
        {isExternalLinkNoData(event.eventType) && totalGuests === 0 ? (
          <EventDashboardLinkAnalyticsBox totalLinkViews={totalLinkViews} />
        ) : checkInGuests ? (
          <StandardListContainerBox
            searchComp={
              <SearchBoxContainer
                placeholder={
                  isExternalLink
                    ? "Search People"
                    : listValue === 1
                    ? "Search Organizers..."
                    : listValue === 2
                    ? "Search Requested..."
                    : "Search Guest List..."
                }
                onChange={(e) => handleSearch(e.target.value)}
                containerStyles={{ marginTop: 0 }}
              />
            }
            headerRightComp={
              <>
                <BlackHoverButton
                  title={"Filter"}
                  iconName="ion:filter"
                  iconSize={14}
                  onPress={() =>
                    setAlertText({ heading: "Coming soon", subHeading: "" })
                  }
                  containerStyles={{ paddingInline: 14, paddingBlock: 12 }}
                />
                {!isExternalLink ? (
                  <BlackHoverButton
                    title={"Scan"}
                    iconName="ion:scan"
                    iconSize={14}
                    onPress={() => setIsScanningInstructionsModalOpen(true)}
                    containerStyles={{ paddingInline: 14, paddingBlock: 12 }}
                  />
                ) : null}
                <BlackHoverButton
                  title="Export&nbsp;Data"
                  iconName="ion:arrow-down"
                  iconSize={14}
                  onPress={exportEventOnClick}
                  containerStyles={{ paddingInline: 14, paddingBlock: 12 }}
                  iconLoading={isDownloading}
                />
              </>
            }
            subHeaderComp={
              isExternalLink ? (
                <div
                  style={{
                    paddingTop: 6,
                    paddingBottom: 18,
                    paddingInline: 10,
                  }}
                >
                  <span style={styles.submissionsHeader}>
                    Submissions ({totalGuests})
                  </span>
                </div>
              ) : (
                <ListTabs
                  tabLabels={
                    eventHasRequestTickets(event)
                      ? ["Attendees", "Organizers", "Requested"]
                      : ["Attendees", "Organizers"]
                  }
                  tabNumbers={
                    eventHasRequestTickets(event)
                      ? [
                          loading ? -1 : totalGuests,
                          loading ? -1 : totalOrganizers,
                          loading ? -1 : totalRequested,
                        ]
                      : [
                          loading ? -1 : totalGuests,
                          loading ? -1 : totalOrganizers,
                        ]
                  }
                  selectedValue={listValue}
                  onChange={setListValue}
                  containerStyles={styles.guestListComponent}
                  altColor={Colors.BLACK}
                />
              )
            }
            gridHeaderComp={
              <GridTableHeader
                flexSections={
                  organizerTab || isExternalLink
                    ? ORGANIZER_TICKET_FLEX_SECTIONS
                    : TICKET_FLEX_SECTIONS
                }
                sectionTitles={
                  isExternalLink
                    ? ["Name", "Submitted"]
                    : organizerTab
                    ? ["Name", "Role"]
                    : ["Name", "Ticket Type", "Ticket Status"]
                }
              />
            }
            listComp={renderGuestList}
            isLoading={loading}
          />
        ) : null}
      </div>
      <AlertContainer
        headerComp={
          <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
            <Icon icon={"ion:close-circle"} color={Colors.RED3} height={30} />
            {alertText.heading}
          </div>
        }
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
        altButtonLabel={
          alertText.heading === "Export Failed" ? "Got it" : undefined
        }
      />
    </>
  );
};
