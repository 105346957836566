import React, { useCallback, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import {
  AccountData,
  Follower,
  FollowerStatus,
  NotificationType,
} from "@markit/common.types";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import AlertContainer from "../Containers/AlertContainer";
import { exportIndividualFollowerData } from "../../utils/eventUtils/formUtils";
import { AppState } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { Colors } from "../../utils/colors";
import { readdFollower, unfollowUser } from "../../utils/FollowUser";
import { showNotificationBanner } from "../../utils/notificationUtils";
import ConfirmActionModal from "../Containers/ConfirmPopups/ConfirmActionModal";

type FollowerProfileHeaderBarProps = {
  userData: AccountData;
  followerData?: Follower;
  fullProfile: boolean;
  openDropdown: boolean;
  setOpenDropdown: (openDropdown: boolean) => void;
  goBackToProfilePreview?: () => void;
};

const FollowerProfileHeaderBar = (props: FollowerProfileHeaderBarProps) => {
  const {
    userData,
    followerData,
    openDropdown,
    setOpenDropdown,
    fullProfile,
    goBackToProfilePreview,
  } = props;
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });

  const [exportIsLoading, setExportIsLoading] = useState<boolean>(false);
  const [confirmReadd, setConfirmReadd] = useState(false);
  const accountData = useSelector(
    (state: AppState) => state.account.accountData
  );
  const dispatch = useDispatch();

  const styles = {
    previewHeader: {
      fontSize: 16,
      fontWeight: 600,
    },
  };

  const onExportFinish = useCallback(
    (success: boolean) => {
      if (success) {
        if (alertText.heading !== "Export Failed") {
          showNotificationBanner(
            dispatch,
            "Data Exported",
            NotificationType.AFFIRMATIVE
          );
        }
      } else {
        setAlertText({
          heading: "Export Failed",
          subHeading: "Please try again or contact our hotline.",
        });
      }
    },
    [alertText.heading, dispatch]
  );

  const exportSpreadsheetOnPress = useCallback(async () => {
    if (followerData === undefined) {
      setOpenDropdown(false);
      setAlertText({
        heading: "This person is not currently following you.",
        subHeading: "",
      });
      return;
    } else if (exportIsLoading) {
      return;
    }
    setExportIsLoading(true);

    // can also add a return operation to check status?
    if (followerData === undefined) {
      setAlertText({
        heading: "This person is not following you",
        subHeading: "",
      });
    } else {
      await exportIndividualFollowerData(
        accountData.uid,
        userData,
        onExportFinish
      );
    }
    setExportIsLoading(false);
    setOpenDropdown(false);
  }, [
    accountData.uid,
    exportIsLoading,
    onExportFinish,
    setOpenDropdown,
    userData,
    followerData,
  ]);

  const removeUserOnPress = useCallback(async () => {
    await unfollowUser(userData.uid, accountData.uid, true);
    setOpenDropdown(false);
    showNotificationBanner(dispatch, "Removed!", NotificationType.NEGATIVE);
  }, [accountData.uid, dispatch, setOpenDropdown, userData.uid]);

  const readdUserOnPress = useCallback(async () => {
    await readdFollower(userData.uid, accountData.uid);
    setOpenDropdown(false);
    showNotificationBanner(dispatch, "Added!", NotificationType.AFFIRMATIVE);
  }, [accountData.uid, dispatch, setOpenDropdown, userData.uid]);

  const blockUserOnPress = useCallback(() => {
    setAlertText({
      heading: "Coming soon...",
      subHeading: "Contact Markit hotline to block someone",
    });
  }, []);

  const handleOpenChange = useCallback(
    (nextOpen: boolean, info?: { source: "trigger" | "menu" }) => {
      if (info?.source === "trigger" || nextOpen) {
        setOpenDropdown(nextOpen);
      }
    },
    [setOpenDropdown]
  );

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    let dropdownItems: DropdownMenuItem[] = [
      {
        title: "Export Spreadsheet",
        icon: "ion:download-outline",
        key: "export",
        onPress: exportSpreadsheetOnPress,
        loading: exportIsLoading,
      },
    ];

    if (followerData && followerData.status === FollowerStatus.SUBSCRIBED) {
      dropdownItems = dropdownItems.concat([
        {
          title: "Remove",
          icon: "ion:archive",
          key: "remove",
          onPress: removeUserOnPress,
          isDestructive: true,
        },
        {
          title: "Block",
          icon: "mdi:cancel",
          key: "block",
          onPress: blockUserOnPress,
          isDestructive: true,
        },
      ]);
    } else if (
      followerData &&
      (followerData.status === FollowerStatus.REMOVED ||
        followerData.status === FollowerStatus.INVALID_NUMBER)
    ) {
      dropdownItems = dropdownItems.concat([
        {
          title: "Re-Add",
          icon: "ion:person-add",
          key: "re-add",
          onPress: () =>
            followerData.status === FollowerStatus.INVALID_NUMBER
              ? setConfirmReadd(true)
              : readdUserOnPress(),
        },
        {
          title: "Block",
          icon: "mdi:cancel",
          key: "block",
          onPress: blockUserOnPress,
          isDestructive: true,
        },
      ]);
    }
    return dropdownItems;
  }, [
    followerData,
    exportSpreadsheetOnPress,
    exportIsLoading,
    removeUserOnPress,
    blockUserOnPress,
    readdUserOnPress,
  ]);

  return (
    <>
      <div
        className="AlignedRowSpaced"
        style={{ position: "relative", marginTop: fullProfile ? 0 : 6 }}
      >
        {fullProfile ? (
          <div
            className="LargePopupPanelClose"
            onClick={goBackToProfilePreview}
          >
            <Icon icon={"mdi:close"} height={24} />
          </div>
        ) : (
          <span style={styles.previewHeader}>Preview</span>
        )}
        <DropdownMenu
          dropdownItems={dropdownItems}
          trigger={["click"]}
          placement={fullProfile ? "bottomLeft" : "bottom"}
          altWidth={200}
          isOpen={openDropdown}
          onOpenChange={handleOpenChange}
        >
          <Icon
            icon="ion:ellipsis-horizontal"
            height={20}
            color={Colors.BLACK}
          />
        </DropdownMenu>
      </div>
      <AlertContainer
        headerComp={
          <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
            {alertText.heading === "Export Failed" ? (
              <Icon icon={"ion:close-circle"} color={Colors.RED3} height={30} />
            ) : null}
            {alertText.heading}
          </div>
        }
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
        altButtonLabel={
          alertText.heading === "Export Failed" ? "Got it" : undefined
        }
      />
      <ConfirmActionModal
        heading="Are you sure? The last message to this contact went undelivered."
        confirmButtonText="Re-add"
        icon={<Icon icon="ion:person-add" height={30} />}
        hideModal={!confirmReadd}
        setIsVisible={setConfirmReadd}
        confirmOnPress={readdUserOnPress}
      />
    </>
  );
};

export default FollowerProfileHeaderBar;
