import { useState } from "react";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "../../Containers/PopupModalContainer";
import {
  AccountData,
  Follower,
  FollowerMembershipState,
  FollowerSubscription,
} from "@markit/common.types";
import { BackButton } from "../../Buttons/BackButton";
import useAsyncOnMount from "../../../hooks/useAsyncEffectOnMount";
import { getFollowerSubscriptions } from "../../../utils/followerSubscriptionUtils";
import MembershipBillingItem from "./MembershipBillingItem";
import { generate } from "shortid";
import { LessThanDate } from "@markit/common.utils";

interface MembershipSubscriptionHistoryModalProps {
  isVisible: boolean;
  closeOnPress: () => void;
  creatorData: AccountData;
  userData: AccountData;
  followerData: Follower;
  fromProfilePreview?: boolean;
}

const MembershipSubscriptionHistoryModal = (
  props: MembershipSubscriptionHistoryModalProps
) => {
  const {
    isVisible,
    closeOnPress,
    creatorData,
    userData,
    followerData,
    fromProfilePreview,
  } = props;
  const [followerSubscriptions, setFollowerSubscriptions] = useState<
    FollowerSubscription[]
  >([]);

  useAsyncOnMount(async () => {
    const followerSubs = await getFollowerSubscriptions(
      creatorData.uid,
      userData.uid
    );

    // Create a mock free subscription for the user's initial
    const freeSubscription: FollowerSubscription = {
      id: generate(),
      start: followerData.createdAt,
      end: followerData.createdAt,
      amountPaid: 0,
      membershipPlanId: "free",
      subscriptionId: "",
    };

    // If no subscriptions exist, just return the free subscription
    if (followerSubs.length === 0) {
      setFollowerSubscriptions([freeSubscription]);
      return;
    }

    const mostRecentSubscription = followerSubs[0];
    // Check if the user is downgraded to free (had paid subscriptions and now is on free membershipPlanId)
    const isDowngradedToFree =
      followerSubs.length > 0 && followerData.membershipPlanId === "free";

    // Check if free subscription should be included (more than one day between free and paid subscribe)
    const firstPaidSub = followerSubs[followerSubs.length - 1];
    const daysBetweenFreeAndPaid = Math.abs(
      (new Date(firstPaidSub.start).getTime() -
        new Date(freeSubscription.start).getTime()) /
        (1000 * 60 * 60 * 24)
    );
    const shouldIncludeFree = daysBetweenFreeAndPaid > 1;

    if (isDowngradedToFree) {
      const downgradeSubscription: FollowerSubscription = {
        id: "downgrade",
        start: mostRecentSubscription.end,
        end: mostRecentSubscription.end,
        amountPaid: 0,
        membershipPlanId: "free",
        subscriptionId: "",
      };

      setFollowerSubscriptions([
        downgradeSubscription,
        ...followerSubs,
        ...(shouldIncludeFree ? [freeSubscription] : []),
      ]);
    } else {
      const endDate = new Date(mostRecentSubscription.end);
      endDate.setMonth(endDate.getMonth() + 1);

      // Create a mock upcoming subscription object for the upcoming month since it's not created yet.
      // Don't create for payment failed subscriptions
      const mockUpcomingSub =
        followerData.membershipState === FollowerMembershipState.ACTIVE
          ? {
              ...mostRecentSubscription,
              start: mostRecentSubscription.end,
              end: endDate.toISOString(),
              id: generate(),
            }
          : undefined;

      setFollowerSubscriptions([
        ...(mockUpcomingSub ? [mockUpcomingSub] : []),
        ...followerSubs,
        ...(shouldIncludeFree ? [freeSubscription] : []),
      ]);
    }
  });

  return (
    <PopupModalContainer
      headerComp={
        <div className="ColumnNormal" style={{ gap: 20 }}>
          {fromProfilePreview ? <BackButton onPress={closeOnPress} /> : null}
          <span className="sectionTitle">Subscription History</span>
        </div>
      }
      valueComp={
        <div className="ColumnNormal" style={{ gap: 14 }}>
          {followerSubscriptions.map((subscription, index) => (
            <MembershipBillingItem
              key={subscription.id}
              membershipPlans={creatorData.membership.membershipPlans}
              follower={followerData}
              subscription={subscription}
              lastMonthSubscription={
                index < followerSubscriptions.length - 1
                  ? followerSubscriptions[index + 1]
                  : undefined
              }
              isUpcoming={
                index === 0 &&
                LessThanDate(new Date().toISOString(), subscription.start)
              }
            />
          ))}
        </div>
      }
      noExit
      closeOnOutsidePress={!fromProfilePreview}
      closeModal={closeOnPress}
      hideModal={!isVisible}
      modalContentWidth={PopupModalContainerWidth.SMALL}
    />
  );
};

export default MembershipSubscriptionHistoryModal;
