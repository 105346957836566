import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { CSSProperties } from "react";

type PaymentFailedButtonProps = {
  type: "Creator" | "Subscriber";
  onPress: () => void;
  containerStyles?: CSSProperties;
};

const PaymentFailedButton = (props: PaymentFailedButtonProps) => {
  const { type, onPress, containerStyles } = props;

  return (
    <div
      onClick={onPress}
      className="AlignedRowSpacedSelect"
      style={{
        padding: 14,
        borderRadius: 12,
        backgroundColor: Colors.RED3,
        color: Colors.WHITE,
        gap: 24,
        ...containerStyles,
      }}
    >
      <div className="ColumnNormalSelect" style={{ gap: 4 }}>
        <span className="bodyMedium">Payment Failed</span>
        <span className="bodySmallMedium">
          You must update your billing info to{" "}
          {type === "Subscriber"
            ? "reactivate subscription benefits"
            : "access Markit+"}
        </span>
      </div>
      <Icon icon="ion:chevron-right" height={16} />
    </div>
  );
};

export default PaymentFailedButton;
