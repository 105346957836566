import React, { useMemo } from "react";
import "../css/Footer.css";
import logo from "../assets/MarkitLogoBlack.png";
import WhiteLogo from "../assets/MarkitLogoWhite.png";
import { WhiteLabelColors, WhiteLabelSocial } from "../macros/WhiteLabelUsers";
import { useSelector } from "react-redux";
import { getAccountState } from "../redux/slices/accountSlice";
import { AccountData, ThemeType } from "@markit/common.types";
import { isDesktop } from "react-device-detect";
import { Colors } from "../utils/colors";
import {
  APPSTORE,
  GOOGLEPLAY,
  MARKIT_ATTENDEE_FAQ,
  MARKIT_PRICING,
  MARKIT_PRIVACY_POLICY,
  MARKIT_TERMS_OF_USE,
  MARKIT_TEXTING,
  MARKIT_WEBSITE,
} from "@markit/common.utils";
import { LightTheme, useTheme } from "../hooks/useTheme";
import OSPills from "./OSPills";
import { Icon } from "@iconify/react";

export const IG = "https://instagram.com/markit.social/";
export const FB = "https://www.facebook.com/markitapppage/";
export const X = "https://x.com/markit_social";
export const TIKTOK = "https://www.tiktok.com/@markitsocial";
export const LINKEDIN = "https://www.linkedin.com/company/markitapppage";

const iconStyle = {
  fontSize: 25,
  marginTop: 5,
  color: "#929292",
};

type FooterProp = {
  showRCA?: boolean;
  host?: AccountData | undefined;
  eventTheme?: ThemeType;
  forceLight?: boolean;
  alternateBackgroundColor?: string;
};

const Footer = (props: FooterProp) => {
  const { showRCA, host, eventTheme, forceLight, alternateBackgroundColor } =
    props;
  const { account } = useSelector(getAccountState);
  const { isRCA } = account;

  const { theme } = useTheme(eventTheme, forceLight);

  const hostContactHref: string | undefined = host
    ? host?.customer.phone !== ""
      ? `sms:${host?.customer.phone}`
      : undefined
    : undefined;

  const bgColor = useMemo(
    () => alternateBackgroundColor || theme.SecondaryBG.backgroundColor,
    [alternateBackgroundColor, theme.SecondaryBG.backgroundColor]
  );

  return (
    <div
      style={{
        backgroundColor: bgColor,
        display: "grid",
        placeItems: "center",
        padding: isDesktop ? "0px 18vw 60px 18vw" : "0px 0px 40px 0px",
      }}
    >
      <div
        className={isDesktop ? "FooterDesktop" : "Footer"}
        style={{
          ...(isRCA && showRCA
            ? { backgroundColor: WhiteLabelColors.RCA_TRANSPARENT }
            : { backgroundColor: bgColor }),
          width: "100%",
        }}
      >
        <div className={isDesktop ? "FooterTopDesktop" : "FooterTop"}>
          <div className="LogoFooterContainer">
            <div style={{ paddingLeft: 7 }}>
              {!isRCA ? (
                <img
                  className="LogoFooter"
                  src={theme === LightTheme ? logo : WhiteLogo}
                  alt={"banner"}
                />
              ) : null}
            </div>
            <div className="OSContainer">
              <OSPills eventTheme={eventTheme} forceLight={forceLight} />
            </div>
          </div>
          <div
            className={
              isDesktop ? "FooterInfoContainerDesktop" : "FooterInfoContainer"
            }
          >
            <div className="FooterInfoColumn">
              <>
                {isRCA && showRCA ? (
                  <div
                    className="FooterInfoTitle"
                    style={theme.PrimaryText}
                    onClick={() => {
                      window.open(WhiteLabelSocial.RCA_WEBSITE);
                    }}
                  >
                    Red Curtain Addict
                  </div>
                ) : (
                  <div
                    className="FooterInfoTitle"
                    style={theme.PrimaryText}
                    onClick={() => {
                      window.open(MARKIT_WEBSITE);
                    }}
                  >
                    Markit
                  </div>
                )}
              </>
              <a href={MARKIT_WEBSITE} target="_blank" rel="noreferrer">
                <p className="FooterInfoText">About</p>
              </a>
              <a href={MARKIT_TEXTING} target="_blank" rel="noreferrer">
                <p className="FooterInfoText">Texting</p>
              </a>
              <a href={MARKIT_PRICING} target="_blank" rel="noreferrer">
                <p className="FooterInfoText">Pricing</p>
              </a>
            </div>
            <div className="FooterInfoColumn">
              <p className="FooterInfoTitle" style={theme.PrimaryText}>
                Support
              </p>
              {hostContactHref && (
                <a href={hostContactHref} target="_blank" rel="noreferrer">
                  <p className="FooterInfoText">Contact Host</p>
                </a>
              )}
              <a href={MARKIT_ATTENDEE_FAQ} target="_blank" rel="noreferrer">
                <p className="FooterInfoText">Help Center</p>
              </a>
              <a href={MARKIT_ATTENDEE_FAQ} target="_blank" rel="noreferrer">
                <p className="FooterInfoText">Get In Touch</p>
              </a>
            </div>
            <div className="FooterInfoColumn">
              <p className="FooterInfoTitle" style={theme.PrimaryText}>
                Download
              </p>
              <a href={APPSTORE} target="_blank" rel="noreferrer">
                <p className="FooterInfoText">iOS</p>
              </a>
              <a href={GOOGLEPLAY} target="_blank" rel="noreferrer">
                <p className="FooterInfoText">Android</p>
              </a>
            </div>
            <div className="FooterInfoColumn">
              <p className="FooterInfoTitle" style={theme.PrimaryText}>
                Follow
              </p>
              <div className="FooterInfoSocials">
                <div className="FooterAppIcon">
                  <Icon
                    icon="mdi:instagram"
                    height={20}
                    onClick={() => window.open(IG)}
                    style={iconStyle}
                  />
                </div>
                <div className="FooterAppIcon">
                  <Icon
                    icon="ri:twitter-x-fill"
                    height={20}
                    onClick={() => window.open(X)}
                    style={iconStyle}
                  />
                </div>
                <div className="FooterAppIcon">
                  <Icon
                    icon="ri:linkedin-fill"
                    height={20}
                    onClick={() => window.open(LINKEDIN)}
                    style={iconStyle}
                  />
                </div>
                <div className="FooterAppIcon">
                  <Icon
                    icon="ic:baseline-tiktok"
                    height={20}
                    onClick={() => window.open(TIKTOK)}
                    style={iconStyle}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <hr
            style={{
              ...theme.DividerColor,
              marginBlock: "40px",
              width: "93%",
            }}
          />
        </div>

        <div className={isDesktop ? "FooterBottomDesktop" : "FooterBottom"}>
          <div className="FooterInfoColumn">
            <p className="FooterInfoTitle" style={theme.PrimaryText}>
              © Markit Social Inc.
            </p>
          </div>
          <div className="FooterInfoColumn">
            <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
              <p
                className="FooterInfoTitle"
                onClick={() => {
                  window.open(MARKIT_PRIVACY_POLICY);
                }}
                style={
                  isDesktop
                    ? {
                        ...theme.PrimaryText,
                        cursor: "pointer",
                      }
                    : {
                        cursor: "pointer",
                        color: Colors.GRAY2,
                        textDecoration: "underline",
                      }
                }
              >
                Privacy Policy
              </p>
              <p
                className="FooterInfoTitle"
                onClick={() => {
                  window.open(MARKIT_TERMS_OF_USE);
                }}
                style={
                  isDesktop
                    ? {
                        ...theme.PrimaryText,
                        cursor: "pointer",
                      }
                    : {
                        cursor: "pointer",
                        color: Colors.GRAY2,
                        textDecoration: "underline",
                      }
                }
              >
                Terms of Use
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
