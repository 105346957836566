import { FollowerSubscription, MembershipPlan } from "@markit/common.types";
import {
  getUserFollowersRef,
  getUserFollowerSubscriptionsRef,
} from "./FirebaseUtils";
import {
  orderBy,
  limit,
  query,
  getDocs,
  where,
  getCountFromServer,
} from "../firebase";

// Gets the most recent follower subscription for a given creator and follower
export const getMostRecentFollowerSubscription = async (
  creatorUid: string,
  followerUid: string
): Promise<FollowerSubscription | undefined> => {
  const followerSubscriptionsRef = getUserFollowerSubscriptionsRef(
    creatorUid,
    followerUid
  );
  const query_ = query(
    followerSubscriptionsRef,
    orderBy("end", "desc"),
    limit(1)
  );
  const snapshot = await getDocs(query_);
  if (!snapshot.empty) {
    const latestFollowerSubscription = snapshot.docs[0].data();
    return latestFollowerSubscription;
  }
  return undefined;
};

export const getFollowerSubscriptions = async (
  creatorUid: string,
  followerId: string
) => {
  const followerSubscriptionsRef = getUserFollowerSubscriptionsRef(
    creatorUid,
    followerId
  );
  const followerSubscriptionsSnap = await getDocs(
    query(followerSubscriptionsRef, orderBy("end", "desc"))
  );
  return followerSubscriptionsSnap.docs.map((doc) => doc.data());
};

// Gets the monthly revenue from all follower subscriptions up to the current date
// TODO: When we add multiple membership paid plans, we'll need to calculate revenue cumulatively for each follower
export const getFollowerSubscriptionsMonthlyRevenue = async (
  userId: string,
  membershipPlans: MembershipPlan[]
) => {
  const followersRef = getUserFollowersRef(userId);
  const query_ = query(followersRef, where("membershipPlanId", "!=", "free"));
  const snapshot = await getCountFromServer(query_);
  const numPaidFollowers = snapshot.data().count;
  const paidMembershipPlanPrice =
    membershipPlans.find((plan) => plan.id !== "free")?.price ?? 0;
  const totalRevenue = (numPaidFollowers * paidMembershipPlanPrice) / 100;
  return totalRevenue;
  // const totalRevenue = await Promise.all(
  //   snapshot.docs.map(async (doc) => {
  //     const followerData = doc.data();
  //     const mostRecentSubscription = await getMostRecentFollowerSubscription(
  //       userId,
  //       followerData.uid
  //     );
  //     if (
  //       mostRecentSubscription &&
  //       LessThanDate(mostRecentSubscription.start, new Date().toISOString())
  //     ) {
  //       return mostRecentSubscription.amountPaid;
  //     }
  //     return 0;
  //   })
  // );
  // return totalRevenue.reduce((acc, curr) => acc + curr, 0);
};
