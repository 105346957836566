import { Colors } from "../../utils/colors";

type FlatTabsProps = {
  items: string[];
  tabValue: number;
  setTabValue: (tabValue: number) => void;
};

// Tabs with line under spaced around (ie. ProfilePageFull)
const FlatTabs = (props: FlatTabsProps) => {
  const { items, tabValue, setTabValue } = props;

  const styles = {
    tabsContainer: {
      borderBottom: `1px solid ${Colors.GRAY4}`,
      width: "100%",
    },
    tabs: {
      display: "flex",
      justifyContent: "space-around",
    },
    tabItem: {
      flex: 1,
      paddingBottom: 12,
      fontSize: 16,
      color: Colors.GRAY1,
      background: "none",
      border: "none",
      outline: "none",
      cursor: "pointer",
      transition: "color 0.3s ease",
    },
    tabItemActive: {
      color: Colors.BLACK,
      fontWeight: 500,
    },
    tabIndicator: {
      bottom: 0,
      height: 2,
      backgroundColor: Colors.BLACK,
      width: `${100 / items.length}%`,
      transform: `translateX(${tabValue * 100}%)`,
      transition: "transform 0.3s ease, width 0.3s ease",
    },
  };

  const handlePress = (index: number) => {
    setTabValue(index);
  };

  return (
    <div style={{ ...styles.tabsContainer, position: "relative" }}>
      <div style={styles.tabs}>
        {items.map((item, index) => (
          <button
            key={index}
            style={{
              ...styles.tabItem,
              ...(index === tabValue ? styles.tabItemActive : {}),
            }}
            onClick={() => handlePress(index)}
          >
            {item}
          </button>
        ))}
      </div>
      <div style={{ ...styles.tabIndicator, position: "absolute" }} />
    </div>
  );
};

export default FlatTabs;
