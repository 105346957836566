import { useCallback, useMemo, useState } from "react";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import {
  AccountData,
  Follower,
  MembershipState,
  NotificationType,
} from "@markit/common.types";
import { showNotificationBanner } from "../../utils/notificationUtils";
import { useDispatch, useSelector } from "react-redux";
import RectangleButton from "./RectangleButton";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import ConfirmDeleteModal from "../Containers/ConfirmPopups/ConfirmDeleteModal";
import { isDesktop } from "react-device-detect";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useNavigate } from "react-router-dom";
import { onProfilePageNavigatePath } from "../../utils/navigationUtils";
import { unfollowUser } from "../../utils/FollowUser";

type ProfileFollowingActionsButtonProps = {
  followerData: Follower;
  creatorData: AccountData;
};

export const ProfileFollowingActionsButton = (
  props: ProfileFollowingActionsButtonProps
) => {
  const { followerData, creatorData } = props;
  const accountData = useSelector(getAccountState).account.accountData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const styles = {
    headerButton: {
      paddingInline: 40,
      paddingBlock: 14,
      flex: 1,
      whiteSpace: "nowrap",
    },
  };

  const hasPaidMembership = useMemo(
    () =>
      followerData.membershipPlanId && followerData.membershipPlanId !== "free",
    [followerData.membershipPlanId]
  );

  const onUnfollowClick = useCallback(async () => {
    setProcessing(true);
    await unfollowUser(accountData.uid, creatorData.uid, false);
    setProcessing(false);
    showNotificationBanner(dispatch, "Unsubscribed", NotificationType.NEGATIVE);
  }, [creatorData.uid, dispatch, setProcessing, accountData.uid]);

  const handleOpenChange = useCallback(
    (nextOpen: boolean, info?: { source: "trigger" | "menu" }) => {
      if (info?.source === "trigger" || nextOpen) {
        setIsOpenDropdown(nextOpen);
      }
    },
    [setIsOpenDropdown]
  );

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const menuItems = [];
    if (creatorData.membership.state === MembershipState.ACTIVE) {
      menuItems.push({
        title: "Manage Subscription",
        icon: "ion:cog",
        key: "manage_subscription",
        isDestructive: false,
        onPress: () =>
          onProfilePageNavigatePath(navigate, creatorData.username, "manage"),
      });
      if (!hasPaidMembership) {
        menuItems.push({
          title: "Upgrade",
          icon: "ion:ribbon",
          key: "upgrade",
          isDestructive: false,
          onPress: () =>
            onProfilePageNavigatePath(
              navigate,
              creatorData.username,
              "upgrade"
            ),
        });
      }
    }
    if (!hasPaidMembership) {
      menuItems.push({
        title: "Unsubscribe",
        icon: "ion:close-circle",
        key: "unsubscribe",
        isDestructive: true,
        onPress: () => {
          setConfirmModal(true);
          setIsOpenDropdown(false);
        },
      });
    }
    return menuItems;
  }, [
    creatorData.membership.state,
    creatorData.username,
    hasPaidMembership,
    navigate,
  ]);

  return (
    <>
      <DropdownMenu
        dropdownItems={dropdownItems}
        trigger={["click"]}
        placement="bottom"
        altWidth={isDesktop ? undefined : 170}
        overlayStyle={{ paddingInline: isDesktop ? 24 : 0 }}
        isOpen={isOpenDropdown}
        onOpenChange={handleOpenChange}
      >
        <RectangleButton
          buttonLabel="Subscribed"
          iconLeft={
            hasPaidMembership ? (
              <Icon icon={"ion:ribbon"} height={16} color={Colors.GREEN2} />
            ) : undefined
          }
          iconRight={
            <Icon
              icon={isOpenDropdown ? "ion:chevron-up" : "ion:chevron-down"}
              height={16}
              color={hasPaidMembership ? Colors.GREEN2 : Colors.BLACK}
            />
          }
          onPress={() => {}}
          loading={processing}
          altColor={Colors.WHITE1}
          altTextColor={hasPaidMembership ? Colors.GREEN2 : Colors.BLACK}
          containerStyles={{
            ...styles.headerButton,
            border: `0.5px solid ${Colors.GRAY2}`,
          }}
        />
      </DropdownMenu>
      <ConfirmDeleteModal
        heading={`Unsubscribe from ${creatorData.fullName}?`}
        subtext="Re-subscribe at any time."
        deleteButtonText="Unsubscribe"
        hideModal={!confirmModal}
        setIsVisible={setConfirmModal}
        deleteOnPress={onUnfollowClick}
      />
    </>
  );
};
