import { useMemo } from "react";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useSelector } from "react-redux";
import ProfilePic from "../ProfilePic";
import { NavigationId } from "../../navigation/AppParamList";
import { useLocation } from "react-router-dom";
import { DarkTheme, useTheme } from "../../hooks/useTheme";
import { m } from "framer-motion";
import { Colors } from "../../utils/colors";
import ProfileHeaderDropdown from "./CreatorProfile/ProfileHeaderDropdown";
import { ThemeType } from "@markit/common.types";

type CreatorModeSidebarProfileProps = {
  eventTheme?: ThemeType;
};

const CreatorModeSidebarProfile = (props: CreatorModeSidebarProfileProps) => {
  const { eventTheme } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const pathname = useLocation().pathname;
  const { theme } = useTheme(eventTheme);
  const isDarkTheme = useMemo(() => theme && theme === DarkTheme, [theme]);

  const isSelected = useMemo(
    () => pathname === NavigationId.HOME_PROFILE,
    [pathname]
  );

  return (
    <ProfileHeaderDropdown placement="left">
      <m.div
        className="AlignedRowSelect"
        style={{
          gap: 7,
          paddingBlock: 24,
          paddingInline: 14,
          backgroundColor: isSelected
            ? isDarkTheme
              ? Colors.GRAY7
              : Colors.GRAY6
            : isDarkTheme
            ? Colors.GRAY10
            : Colors.WHITE,
        }}
        whileHover={{
          backgroundColor: isDarkTheme ? Colors.GRAY7 : Colors.GRAY6,
        }}
        transition={{ duration: 0.1 }}
      >
        <div>
          <ProfilePic user={accountData} size={40} forcePointer />
        </div>
        <div className="ColumnNormalSelect" style={{ gap: 5, maxWidth: 120 }}>
          <span className="bodyMedium TwoLineText" style={theme.PrimaryText}>
            {accountData.fullName}
          </span>
          <span className="smallBodySubtext TextOverflowEllipsis">
            @{accountData.username}
          </span>
        </div>
      </m.div>
    </ProfileHeaderDropdown>
  );
};

export default CreatorModeSidebarProfile;
