import { Colors } from "../../utils/colors";

type SubscriptionPlanLabelItemProps = {
  planName: string;
  onPress?: () => void;
};

const SubscriptionPlanLabelItem = (props: SubscriptionPlanLabelItemProps) => {
  const { planName, onPress } = props;
  const styles = {
    smallNoticeButton: {
      display: "inline-block",
      backgroundColor: Colors.GRAY6,
      border: `0.5px solid ${Colors.GRAY11}`,
      padding: 10,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 20,
      fontSize: 12,
      cursor: onPress ? "pointer" : "",
    },
  };

  return (
    <div>
      <div style={styles.smallNoticeButton} onClick={onPress}>
        {planName}
      </div>
    </div>
  );
};

export default SubscriptionPlanLabelItem;
