import "../../../../css/GlobalStyles.css";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Event } from "@markit/common.types";
import { foundUserGenericLinks, hasSubscription } from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { getEventState } from "../../../../redux/slices/eventSlice";
import EmptyStateButton from "../../../../components/Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import RectangleButtonCreatorPanel from "../../../../components/Buttons/RectangleButtonCreatorPanel";
import { useNavigate } from "../../../../hooks/useNavigate";
import MarkitPlusActionButton from "../../../../components/Subscription/MarkitPlusActionButton";
import { MixpanelContext } from "../../../../context/AnalyticsService";
import { FeaturedEventCardMobile } from "../../../../components/LandingPage/RenderedEvents";
import CreatorModePanelWrapper from "../../../../components/CreatorDashboard/CreatorModePanelWrapper";

const LinksPanel = () => {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const mixpanel = useContext(MixpanelContext);
  const { events: eventList } = useSelector(getEventState);
  const { events } = eventList;
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const activeGenericLinks = useMemo(
    () => foundUserGenericLinks(events, accountData.uid, "active"),
    [accountData.uid, events]
  );

  const inactiveGenericLinks = useMemo(
    () => foundUserGenericLinks(events, accountData.uid, "inactive").reverse(),
    [accountData.uid, events]
  );

  const navigateCreateLinkOnPress = useCallback(() => {
    mixpanel.track("Pressed Create External Event Link", {
      distinct_id: accountData.uid,
      platform: "webapp",
      type: "generic_link",
      button_location: "links_panel",
    });
    navigate("/createLink");
  }, [accountData.uid, mixpanel, navigate]);

  const renderSubPanel = useMemo(() => {
    const renderLinks = (links: Event[]) => {
      return links.length > 0 ? (
        links.map((link) => (
          <FeaturedEventCardMobile
            event={link}
            altNavigationPath={
              link.isDraft ? "/create" : `/e/${link.id}/dashboard`
            }
            myEvent
            key={link.id}
          />
        ))
      ) : (
        <EmptyStateButton
          title={"Grow with Links"}
          description={
            "Collect audience data and grow your audience when you create and share a link through Markit."
          }
          icon={
            <Icon icon={"mdi:link-variant"} height={63} color={Colors.GRAY1} />
          }
          iconBackgroundColor={Colors.WHITE1}
          iconBox={80}
          btnText="Create a Link"
          onPress={navigateCreateLinkOnPress}
          containerStyles={{ paddingTop: 200 }}
        />
      );
    };

    return (
      <div className="ColumnNormal" style={{ gap: "14px", paddingBottom: 100 }}>
        {value === 0 && renderLinks(activeGenericLinks)}
        {value === 1 && renderLinks(inactiveGenericLinks)}
      </div>
    );
  }, [
    value,
    activeGenericLinks,
    inactiveGenericLinks,
    navigateCreateLinkOnPress,
  ]);

  return (
    <CreatorModePanelWrapper
      title="Links"
      headerRight={
        <div className="AlignedRow" style={{ gap: 14 }}>
          {!hasSubscription(accountData) ? <MarkitPlusActionButton /> : null}
          <RectangleButtonCreatorPanel
            title="Create Link"
            iconName="ion:add-circle"
            onPress={navigateCreateLinkOnPress}
          />
        </div>
      }
      headerTabs={{
        tabNames: [
          `Active (${activeGenericLinks.length})`,
          `Inactive (${inactiveGenericLinks.length})`,
        ],
        tabValue: value,
        setTabValue: setValue,
      }}
    >
      <div
        style={{ padding: 30, height: "100%", backgroundColor: Colors.WHITE }}
      >
        {renderSubPanel}
      </div>
    </CreatorModePanelWrapper>
  );
};

export default LinksPanel;
