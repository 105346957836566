import {
  AccountData,
  AudienceList,
  FollowerStatus,
} from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../../redux/slices/accountSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import filter from "lodash.filter";
import { Colors } from "../../../../../utils/colors";
import FlatList from "flatlist-react/lib";
import { CircularProgress } from "@mui/material";
import { MassTextsPersonItem } from "../../Items/MassTextsPersonItem";
import { EmptySearchState } from "../../../../EmptyStates/EmptySearchState";
import SearchBoxContainer from "../../../../Containers/SearchBoxContainer";
import { MassTextsActionItem } from "../../Items/MassTextsActionItem";
import { Icon } from "@iconify/react";
import { SelectRecipientsSharedProps } from "../SelectRecipientsScreens";
import {
  addOrFilterRecipients,
  filterFollowersByStatus,
  textableContactString,
} from "@markit/common.utils";
import { useLiveUpdatingListAnalytics } from "../../../../../hooks/useLiveHooks/useLiveUpdatingListAnalytics";
import { useLoadAudienceListMembers } from "../../../../../hooks/useLoadAudienceListMembers";
import { EmptyStateFlatlist } from "../../../../EmptyStates/EmptyStateFlatlist";

type SelectRecipientsAudienceListProps = SelectRecipientsSharedProps & {
  selectedList: AudienceList;
};

const SelectRecipientsAudienceList = (
  props: SelectRecipientsAudienceListProps
) => {
  const {
    selectedList,
    unsavedRecipients,
    setUnsavedRecipients,
    excludingMode,
    setProfileSelected,
    displayAllFollowers,
  } = props;
  const { account } = useSelector(getAccountState);
  const { followersData } = account;
  const [searchTerm, setSearchTerm] = useState("");

  const { liveListContactUids, loaded } = useLiveUpdatingListAnalytics({
    listId: selectedList.id,
  });

  const visibleFollowers = useMemo(
    () =>
      displayAllFollowers
        ? liveListContactUids
        : filterFollowersByStatus(
            liveListContactUids,
            followersData,
            FollowerStatus.SUBSCRIBED
          ),
    [displayAllFollowers, followersData, liveListContactUids]
  );

  const {
    isFinished,
    isLoading,
    fetchedUserData,
    loadMoreUsers,
    loadSearchResultsThrottled,
  } = useLoadAudienceListMembers({
    userIdList: loaded ? visibleFollowers : [],
    windowSize: 20,
  });

  const iconColor = useMemo(
    () => (excludingMode ? Colors.RED3 : Colors.BLUE5),
    [excludingMode]
  );

  useEffect(() => {
    if (loaded) {
      loadMoreUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  const containsUser = useCallback((user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    if (text !== "") {
      loadSearchResultsThrottled(text.toLowerCase());
    }
    setSearchTerm(text);
  };

  const membersToShow: AccountData[] = useMemo(() => {
    let searchResults: AccountData[] = fetchedUserData;
    if (searchTerm !== "") {
      searchResults = filter(fetchedUserData, (user: AccountData) => {
        return containsUser(user, searchTerm);
      });
    }
    return searchResults;
  }, [fetchedUserData, searchTerm, containsUser]);

  const isAllSelected = useCallback(
    (totalList: string[], subList: string[]) =>
      subList.length > 0
        ? totalList.every((sel) => subList.includes(sel))
        : false,
    []
  );

  const renderMemberItem = useCallback(
    (item: AccountData) => {
      return (
        <div key={item.uid}>
          <MassTextsPersonItem
            user={item}
            unsavedPeople={unsavedRecipients}
            setUnsavedPeople={setUnsavedRecipients}
            excludingMode={excludingMode}
            setProfileSelected={setProfileSelected}
          />
        </div>
      );
    },
    [excludingMode, setProfileSelected, setUnsavedRecipients, unsavedRecipients]
  );

  return (
    <>
      <div
        className="ColumnCenter"
        style={{
          gap: 4,
          backgroundColor: Colors.GRAY6,
          padding: 10,
          borderRadius: 12,
        }}
      >
        <span style={{ fontWeight: 500, fontSize: 14 }}>
          {selectedList.name}
        </span>
        <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
          {`${visibleFollowers.length} Contact${
            visibleFollowers.length !== 1 ? "s" : ""
          }`}
        </span>
        <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
          {selectedList.visibility === 0 ? "Public List" : "Private List"}
        </span>
      </div>
      <SearchBoxContainer
        value={searchTerm}
        placeholder="Search People"
        onChange={(e) => handleSearch(e.target.value)}
        containerStyles={{ marginBottom: 10 }}
      />
      <div className="HideScrollbar AllowScroll" style={{ paddingBottom: 200 }}>
        <div>
          <MassTextsActionItem
            title={"Select All"}
            icon={<Icon icon="ion:people" height={32} color={iconColor} />}
            onPress={() =>
              addOrFilterRecipients(
                unsavedRecipients,
                setUnsavedRecipients,
                visibleFollowers
              )
            }
            subtext={textableContactString(visibleFollowers.length)}
            isCheckSelected={isAllSelected(visibleFollowers, unsavedRecipients)}
            disabled={membersToShow.length === 0}
            loading={!loaded}
            checkColor={iconColor}
          />
          <hr style={{ marginBlock: 10 }} />
        </div>
        <FlatList
          list={membersToShow}
          renderItem={(item: AccountData) => renderMemberItem(item)}
          renderWhenEmpty={() => (
            <EmptyStateFlatlist
              searchTerm={searchTerm}
              isLoading={isLoading || !loaded}
              containerStyles={{ paddingTop: 120 }}
              nonSearchEmptyView={
                <EmptySearchState mainText="No people to show" />
              }
            />
          )}
          hasMoreItems={!isFinished}
          loadMoreItems={() => {
            loadMoreUsers();
          }}
          paginationLoadingIndicator={() =>
            isLoading && !isFinished ? (
              <CircularProgress
                style={{ color: "#929292", alignSelf: "center" }}
                size={20}
              />
            ) : null
          }
        />
      </div>
    </>
  );
};

export default SelectRecipientsAudienceList;
