import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  handlePluralString,
  MARKIT_TEXTING_CREDITS,
} from "@markit/common.utils";
import PopupModalContainer from "../../../Containers/PopupModalContainer";
import { Icon } from "@iconify/react";
import RectangleButton from "../../../Buttons/RectangleButton";
import { Colors } from "../../../../utils/colors";
import TextHoverButton from "../../../Buttons/TextHoverButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationState,
  notificationActions,
} from "../../../../redux/slices/notificationSlice";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { FREE_TIER_MESSAGE_LIMIT } from "@markit/common.types";

type MessageLimitsModalProps = {
  setModalVisible: (modalVisible: boolean) => void;
  numCredits: number;
  numRecipients: number;
  isScheduled: boolean;
};

export const MessageLimitsModal = memo(function MessageLimitsModalFn(
  props: MessageLimitsModalProps
) {
  const { setModalVisible, numCredits, numRecipients, isScheduled } = props;
  const { markitPlusModal } = useSelector(getNotificationState);
  const dispatch = useDispatch();
  const { accountData } = useSelector(getAccountState).account;
  const [hideModal, setHideModal] = useState(false);

  useEffect(() => {
    // Reopen the modal if it is hidden by the markit+ modal being visible
    if (!markitPlusModal && hideModal) {
      setHideModal(false);
    }
  }, [markitPlusModal, hideModal]);

  // Just in case free tier credits are over the limit
  const maxFreeTierCredits = useMemo(
    () =>
      Math.min(accountData.customer.freeTierMessages, FREE_TIER_MESSAGE_LIMIT),
    [accountData.customer.freeTierMessages]
  );

  const totalRecipientCredits = useMemo(
    () => numCredits * numRecipients,
    [numCredits, numRecipients]
  );

  const reachedTextLimit = useMemo(
    () => maxFreeTierCredits === FREE_TIER_MESSAGE_LIMIT,
    [maxFreeTierCredits]
  );

  const reduceTextAmount = useMemo(() => {
    const totalCredits = maxFreeTierCredits + totalRecipientCredits;
    return Math.max(totalCredits - FREE_TIER_MESSAGE_LIMIT, 0);
  }, [totalRecipientCredits, maxFreeTierCredits]);

  const headerText = useMemo(() => {
    return reachedTextLimit
      ? "You have reached the maximum free credits for this month"
      : "This text will exceed your free credits for this month";
  }, [reachedTextLimit]);

  const upgradeOnPress = useCallback(() => {
    setHideModal(true);
    dispatch(notificationActions.setMarkitPlusModal(true));
  }, [dispatch]);

  const textingCreditsOnPress = useCallback(() => {
    window.open(MARKIT_TEXTING_CREDITS);
  }, []);

  return hideModal ? null : (
    <PopupModalContainer
      headerComp={
        <div className="ColumnNormal" style={{ gap: 14, fontWeight: 400 }}>
          <Icon
            icon={isScheduled ? "ion:timer" : "ion:paper-plane"}
            height={35}
          />
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="sectionTitle">{headerText}</span>
            <span className="bodySubtext">
              Your free credit limit will be reset on the first of next month.
            </span>
            <TextHoverButton
              text={"Upgrade your plan for more text credits"}
              icon={
                <Icon
                  icon="ion:chevron-right"
                  height={12}
                  color={Colors.BLACK}
                />
              }
              iconPosition="right"
              onPress={upgradeOnPress}
              textStyles={{ color: Colors.BLACK }}
            />
          </div>

          <div className="ColumnNormal" style={{ gap: 4 }}>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <Icon icon={"ion:prism"} height={15} />
              <span className="bodyMedium">
                Sending this text will use {totalRecipientCredits}{" "}
                {handlePluralString("credit", totalRecipientCredits)}
              </span>
            </div>
            {isScheduled ? (
              <span className="smallBodySubtext">
                The final credit usage of this text will depend on the total
                number of recipients.
              </span>
            ) : (
              <span className="smallBodySubtext">
                {numCredits} {handlePluralString("credit", numCredits)} per text
                for {numRecipients}{" "}
                {handlePluralString("recipient", numRecipients)}
              </span>
            )}
            <TextHoverButton
              text="Learn more about credit usage"
              onPress={textingCreditsOnPress}
              icon={
                <Icon
                  icon="ion:chevron-right"
                  height={12}
                  style={{ color: Colors.BLUE5, marginBottom: -2 }}
                />
              }
              iconPosition="right"
              textStyles={{ color: Colors.BLUE5 }}
            />
          </div>
          <div className="ColumnNormal" style={{ gap: 4 }}>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <Icon icon={"ion:warning"} height={15} color={Colors.ORANGE1} />
              {reachedTextLimit ? (
                <span className="bodyMedium">You have 0 free credits left</span>
              ) : (
                <span className="bodyMedium">
                  Reduce your text by {reduceTextAmount}{" "}
                  {handlePluralString("credit", reduceTextAmount)} to{" "}
                  {isScheduled ? "schedule" : "send now"}
                </span>
              )}
            </div>
            <span className="smallBodySubtext">
              Your current text credit usage is {maxFreeTierCredits}/
              {FREE_TIER_MESSAGE_LIMIT}
            </span>
          </div>
        </div>
      }
      valueComp={
        <div className="AlignedRow" style={{ gap: 10 }}>
          <RectangleButton
            buttonLabel={"Ok"}
            onPress={() => setModalVisible(false)}
            altTextColor={Colors.BLACK}
            altColor={Colors.GRAY6}
            altPaddingVert={14}
          />
        </div>
      }
      noExit
      closeOnOutsidePress
      closeModal={() => setModalVisible(false)}
    />
  );
});
