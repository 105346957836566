import "../../css/GlobalStyles.css";
import { Helmet } from "react-helmet";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  LoginState,
  fetchAllUserFollowersData,
  fetchAudienceLists,
  getAccountState,
} from "../../redux/slices/accountSlice";
import { MixpanelContext } from "../../context/AnalyticsService";
import { Colors } from "../../utils/colors";
import { useNavigate } from "../../hooks/useNavigate";
import RectangleButtonCreatorPanel from "../../components/Buttons/RectangleButtonCreatorPanel";
import HelpModal from "../../components/Containers/HelpModal";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import UploadContacts from "../../components/CreatorDashboard/CreatorProfile/ImportContacts/UploadContacts";
import ConfirmDeleteModal from "../../components/Containers/ConfirmPopups/ConfirmDeleteModal";
import AssignImportedContacts from "../../components/CreatorDashboard/CreatorProfile/ImportContacts/AssignImportedContacts";
import {
  foundExistingSpreadsheetName,
  getTotalSpreadsheetFollowersUploaded,
} from "../../utils/spreadsheetUtils";
import { AudienceList, SpreadsheetInfo } from "@markit/common.types";
import ConfirmActionModal from "../../components/Containers/ConfirmPopups/ConfirmActionModal";
import SegmentContacts from "../../components/CreatorDashboard/CreatorProfile/ImportContacts/SegmentContacts";
import ConfirmImportContacts from "../../components/CreatorDashboard/CreatorProfile/ImportContacts/ConfirmImportContacts";
import { API } from "../../API";
import ProgressActionModal, {
  ProgressActionStatus,
} from "../../components/Containers/ProgressActionModal";
import { hasSubscriptionUnlockedAdvancedData } from "@markit/common.utils";
import CreatorModeWrapper from "../../components/CreatorDashboard/CreatorModeWrapper";
import { NavigationId } from "../../navigation/AppParamList";
import MultiStageContainer from "../../components/Containers/MultiStageContainer";
import AlertContainer from "../../components/Containers/AlertContainer";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import { getUserRef } from "../../utils/FirebaseUtils";
import { updateDoc } from "../../firebase";

export const FullNameColumnLabel = "Full Name";

export enum ImportContactsStage {
  UPLOAD = "Upload",
  ASSIGN = "Assign",
  SEGMENT = "Segment",
  CONFIRM = "Confirm",
}

const ImportContacts = () => {
  const { accountData, loggedIn, appInitialized, savedQuestions } =
    useSelector(getAccountState).account;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [importStage, setImportStage] = useState(ImportContactsStage.UPLOAD);
  const [currNumImported, setCurrNumImported] = useState(0);
  // Upload Stage
  const [spreadsheet, setSpreadsheet] = useState<SpreadsheetInfo>();
  const [displayFile, setDisplayFile] = useState<{
    name: string;
    isError: boolean;
    message: string;
  }>({ name: "", isError: false, message: "" });
  const [permissionsChecked, setPermissionsChecked] = useState(false);
  // Assign Stage
  const [assignedColumns, setAssignedColumns] = useState<string[]>([]);
  const [fullNameAlert, setFullNameAlert] = useState<{
    header: string;
    subHeader: string;
  }>({ header: "", subHeader: "" });
  // Segment Stage
  const [selectedLists, setSelectedLists] = useState<AudienceList[]>([]);
  const [includeExistingContacts, setIncludeExistingContacts] = useState(false);

  const [importStatusVisible, setImportStatusVisible] =
    useState<ProgressActionStatus>({
      modalVisible: false,
      state: "completed",
    });
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmImportVisible, setConfirmImportVisible] = useState(false);

  useEffect(() => {
    if (loggedIn === LoginState.LOGGED_IN && !appInitialized) {
      return;
    }
    // Handle Redirecting
    if (
      !hasSubscriptionUnlockedAdvancedData(accountData) &&
      !accountData.isAdmin
    ) {
      navigate(NavigationId.HOME_IMPORT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData, appInitialized]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (!appInitialized) {
      return;
    }
    // Get the number of imported contacts the user has uploaded already
    const numImportedContacts = await getTotalSpreadsheetFollowersUploaded(
      accountData.uid
    );
    setCurrNumImported(numImportedContacts);
  }, [accountData, appInitialized]);

  // Reset fields if spreadsheet is changed
  useEffect(() => {
    if (importStage !== ImportContactsStage.ASSIGN) {
      if (assignedColumns) {
        setAssignedColumns([]);
      }
      if (selectedLists) {
        setSelectedLists([]);
      }
      if (includeExistingContacts) {
        setIncludeExistingContacts(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spreadsheet]);

  const numAvailableImports = useMemo(
    () => 500 - currNumImported,
    [currNumImported]
  );

  const numUnassignedColumns = useMemo(
    () => assignedColumns.filter((column) => column === "").length,
    [assignedColumns]
  );

  // The spreadsheet rows to show (whether to include existing contacts or not)
  const spreadsheetRows = useMemo(
    () =>
      spreadsheet
        ? includeExistingContacts
          ? spreadsheet.validRows.concat(spreadsheet.existingContactRows)
          : spreadsheet.validRows
        : [],
    [includeExistingContacts, spreadsheet]
  );

  const stageNames = useMemo(
    () => [
      ImportContactsStage.UPLOAD,
      ImportContactsStage.ASSIGN,
      ImportContactsStage.SEGMENT,
      ImportContactsStage.CONFIRM,
    ],
    []
  );

  const handleImportOnPress = useCallback(async () => {
    if (spreadsheet) {
      const foundFullNameColumnIndex = assignedColumns.findIndex(
        (column) => column === FullNameColumnLabel
      );
      // If no full name column is found (should never enter because we require it on earlier step)
      if (foundFullNameColumnIndex === -1) {
        setFullNameAlert({
          header: "Full Name is required to continue",
          subHeader:
            "We had trouble identifying the assigned Full Name column. Please go back and make sure the Full Name is assigned to a column.",
        });
        return;
      }
      const clearanceApproval =
        spreadsheet.validRows.length <= numAvailableImports ||
        accountData.importContactsApproval;
      setImportStatusVisible({
        modalVisible: true,
        state: "in progress",
      });
      const finalSpreadsheetInfo: SpreadsheetInfo = {
        ...spreadsheet,
        fileName: displayFile.name,
      };
      const assignedQuestions = savedQuestions.filter((question) =>
        assignedColumns.some((id) => question.id === id)
      );
      await API.spreadsheet
        .uploadSpreadsheet({
          uid: accountData.uid,
          spreadsheet: finalSpreadsheetInfo,
          clearanceApproval: clearanceApproval,
          assignedQuestions: assignedQuestions,
          fullNameColumnIndex: foundFullNameColumnIndex,
          selectedListIds: selectedLists.map((list) => list.id),
          includeExistingContacts: includeExistingContacts,
        })
        .then(async (response) => {
          if (!response.success) {
            setImportStatusVisible({
              modalVisible: true,
              state: "failed",
            });
          }
          if (permissionsChecked) {
            const userRef = getUserRef(accountData.uid);
            updateDoc(userRef, { importContactsCheckedAgreement: true });
            mixpanel.track("Checked Agreement For Import Contacts", {
              distinct_id: accountData.uid,
              clearance_approval: clearanceApproval,
              valid_contacts: spreadsheet.validRows.length,
            });
          }
          if (selectedLists.length > 0) {
            dispatch(fetchAudienceLists(accountData.uid));
          }
          // TODO (jonathan): return uids from backend in future rather than calling fetchAll again
          dispatch(fetchAllUserFollowersData(accountData.uid, true));

          setImportStatusVisible({
            modalVisible: true,
            state: "completed",
          });
        })
        .catch(() => {
          setImportStatusVisible({
            modalVisible: true,
            state: "failed",
          });
        });
    }
  }, [
    accountData.importContactsApproval,
    accountData.uid,
    assignedColumns,
    dispatch,
    displayFile.name,
    includeExistingContacts,
    mixpanel,
    numAvailableImports,
    permissionsChecked,
    savedQuestions,
    selectedLists,
    spreadsheet,
  ]);

  // Add the checks before showing the confirmation import popup
  const continueOnPress = useCallback(async () => {
    const spreadsheetNameExists = await foundExistingSpreadsheetName(
      displayFile.name,
      accountData.uid
    );

    if (importStage === ImportContactsStage.UPLOAD) {
      if (spreadsheetNameExists || displayFile.name === "") {
        setDisplayFile({
          ...displayFile,
          isError: true,
          message:
            displayFile.name === ""
              ? "You must enter a display name."
              : "This display name has been used before. Choose another name.",
        });
        return;
      }
      setImportStage(ImportContactsStage.ASSIGN);
      mixpanel.track("Finished Import Contacts Stage", {
        distinct_id: accountData.uid,
        stage: ImportContactsStage.UPLOAD,
        spreadsheet_name: displayFile.name,
        valid_contacts: spreadsheet?.validRows.length,
      });
    } else if (importStage === ImportContactsStage.ASSIGN) {
      if (spreadsheetRows[0].fullName === "") {
        setFullNameAlert({
          header: "Full Name is required to continue",
          subHeader:
            "You must assign a column as Full Name in order to continue your upload.",
        });
        return;
      }
      if (numUnassignedColumns > 0) {
        setConfirmVisible(true);
        return;
      }
      setImportStage(ImportContactsStage.SEGMENT);
      mixpanel.track("Finished Import Contacts Stage", {
        distinct_id: accountData.uid,
        stage: ImportContactsStage.ASSIGN,
        assignedQuestionIds: assignedColumns,
      });
    } else if (importStage === ImportContactsStage.SEGMENT) {
      setImportStage(ImportContactsStage.CONFIRM);
      mixpanel.track("Finished Import Contacts Stage", {
        distinct_id: accountData.uid,
        stage: ImportContactsStage.SEGMENT,
        selectedListIds: selectedLists.map((list) => list.id),
      });
    } else if (importStage === ImportContactsStage.CONFIRM) {
      setConfirmImportVisible(true);
    }
  }, [
    accountData.uid,
    assignedColumns,
    displayFile,
    importStage,
    mixpanel,
    numUnassignedColumns,
    selectedLists,
    spreadsheet?.validRows.length,
    spreadsheetRows,
  ]);

  const navigateImportTab = useCallback(() => {
    navigate(NavigationId.HOME_IMPORT);
  }, [navigate]);

  const backOnPress = useCallback(() => {
    if (importStage === ImportContactsStage.UPLOAD) {
      if (spreadsheet) {
        setShowConfirmDelete(true);
      } else {
        navigateImportTab();
      }
    } else {
      setImportStage(
        importStage === ImportContactsStage.ASSIGN
          ? ImportContactsStage.UPLOAD
          : importStage === ImportContactsStage.SEGMENT
          ? ImportContactsStage.ASSIGN
          : ImportContactsStage.SEGMENT
      );
    }
  }, [importStage, navigateImportTab, spreadsheet]);

  return (
    <div>
      <Helmet>
        <title>{"Import Contacts"}</title>
        <meta name="og:title" content={"Import Contacts"} />
        <meta name="og:description" content={"Upload Spreadsheet"} />
      </Helmet>
      <CreatorModeWrapper>
        <div
          className="ColumnNormal"
          style={{ backgroundColor: Colors.WHITE1, paddingBottom: 100 }}
        >
          <div className="CreatorPanelContainer">
            <div className="AlignedRowSpaced">
              <h2>Import Contacts</h2>
              <RectangleButtonCreatorPanel
                title="Need Help?"
                onPress={() => setShowHelpModal(true)}
                iconName=""
                addHover
              />
            </div>
          </div>
          <div className="CreatorPanelContainer">
            <MultiStageContainer
              currentStageIndex={stageNames.findIndex(
                (stage) => stage === importStage
              )}
              stageNames={stageNames}
              backOnPress={backOnPress}
              rightButton={
                <RectangleButton
                  buttonLabel={
                    <span style={{ fontSize: 14, fontWeight: 500 }}>
                      {importStage === ImportContactsStage.CONFIRM
                        ? "Confirm and Import"
                        : importStage === ImportContactsStage.SEGMENT
                        ? selectedLists.length > 0
                          ? "Add and Continue"
                          : "Skip to Confirm"
                        : "Continue"}
                    </span>
                  }
                  altColor={Colors.BLACK}
                  altTextColor={Colors.WHITE}
                  disabled={!spreadsheet || spreadsheetRows.length === 0}
                  altPaddingHorz={
                    importStage === ImportContactsStage.SEGMENT ? 20 : 40
                  }
                  altPaddingVert={14}
                  onPress={continueOnPress}
                  iconRight={
                    <Icon
                      icon="mdi:chevron-right"
                      height={16}
                      color={
                        !spreadsheet || spreadsheetRows.length === 0
                          ? Colors.GRAY1
                          : Colors.WHITE
                      }
                    />
                  }
                />
              }
            >
              {importStage === ImportContactsStage.UPLOAD ? (
                <UploadContacts
                  spreadsheet={spreadsheet}
                  setSpreadsheet={setSpreadsheet}
                  currNumImported={currNumImported}
                  displayFile={displayFile}
                  setDisplayFile={setDisplayFile}
                  permissionsChecked={permissionsChecked}
                  setPermissionsChecked={setPermissionsChecked}
                  includeExistingContacts={includeExistingContacts}
                  setIncludeExistingContacts={setIncludeExistingContacts}
                />
              ) : spreadsheet && importStage === ImportContactsStage.ASSIGN ? (
                <AssignImportedContacts
                  spreadsheet={spreadsheet}
                  setSpreadsheet={setSpreadsheet}
                  includeExistingContacts={includeExistingContacts}
                  assignedColumns={assignedColumns}
                  setAssignedColumns={setAssignedColumns}
                  setFullNameAlert={setFullNameAlert}
                />
              ) : importStage === ImportContactsStage.SEGMENT ? (
                <SegmentContacts
                  selectedLists={selectedLists}
                  setSelectedLists={setSelectedLists}
                />
              ) : importStage === ImportContactsStage.CONFIRM ? (
                <ConfirmImportContacts
                  spreadsheet={spreadsheet}
                  setSpreadsheet={setSpreadsheet}
                  displayFile={displayFile}
                  selectedLists={selectedLists}
                  includeExistingContacts={includeExistingContacts}
                />
              ) : null}
            </MultiStageContainer>
          </div>
        </div>
      </CreatorModeWrapper>
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
      <ConfirmDeleteModal
        heading="Are you sure want to exit?"
        subtext="Your changes will be discarded."
        deleteButtonText="Exit"
        hideModal={!showConfirmDelete}
        setIsVisible={setShowConfirmDelete}
        deleteOnPress={navigateImportTab}
        icon={<Icon icon="ion:exit" height={40} />}
      />
      <ConfirmActionModal
        heading={"Ready to Import?"}
        subtext={`You are about to import ${spreadsheetRows.length} contacts from ${displayFile.name}. This cannot be undone.`}
        confirmButtonText={"Import"}
        icon={<Icon icon="ion:cloud-upload" height={40} />}
        hideModal={!confirmImportVisible}
        setIsVisible={setConfirmImportVisible}
        confirmOnPress={handleImportOnPress}
      />
      <ConfirmActionModal
        heading={`You have ${numUnassignedColumns} unassigned columns`}
        subtext={
          "Are you sure you want to continue? Unassigned columns will not be included in this import."
        }
        confirmButtonText={"Continue"}
        icon={<Icon icon="ion:warning" height={40} />}
        hideModal={!confirmVisible}
        setIsVisible={setConfirmVisible}
        confirmOnPress={() => setImportStage(ImportContactsStage.SEGMENT)}
      />
      <AlertContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 14 }}>
            <Icon icon="ion:warning" height={40} />
            <span>{fullNameAlert.header}</span>
          </div>
        }
        subHeaderComp={fullNameAlert.subHeader}
        altButtonLabel="Got It"
        closeModal={() => setFullNameAlert({ header: "", subHeader: "" })}
        hideModal={!fullNameAlert.header}
      />
      <ProgressActionModal
        actionTitle="Import"
        actionStatus={importStatusVisible}
        setActionStatus={setImportStatusVisible}
        failureMessage={
          "An error occurred while importing. Try again or contact our hotline for help."
        }
        inProgressMessage={"Your import may take a few minutes."}
        successMessage={`Successfully imported ${
          spreadsheetRows.length
        } contact${spreadsheetRows.length === 1 ? "" : "s"}.`}
        addOnCompletion={() => navigate(NavigationId.HOME_IMPORT)}
      />
    </div>
  );
};

export default memo(ImportContacts);
