import "../../css/Containers/ContainerStyles.css";
import React, { CSSProperties, ReactNode } from "react";
import { ModifiedTextMessageDisplay } from "../DisplayItem/ModifiedTextMessageDisplay";
import { Colors } from "../../utils/colors";
import { CircularProgress } from "@mui/material";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { useTheme } from "../../hooks/useTheme";

type PreviewBoxContainerProps = {
  headerComp: ReactNode;
  subHeaderComp: ReactNode;
  headerRightComp: ReactNode;
  message: string;
  containerStyles?: CSSProperties;
  loading?: boolean;
  disablePlugins?: boolean;
};

// This is used to display a preview of a message, ie. The Ticket Text, the event text timeline, etc
const PreviewBoxContainer = (props: PreviewBoxContainerProps) => {
  const {
    headerComp,
    subHeaderComp,
    headerRightComp,
    message,
    containerStyles,
    loading,
    disablePlugins,
  } = props;
  const { theme } = useTheme();

  return (
    <div
      style={{
        borderRadius: 8,
        paddingBlock: 14,
        ...containerStyles,
      }}
    >
      <div className="AlignedRowSpaced" style={{ paddingInline: 14 }}>
        <div className="ColumnNormal" style={{ gap: 4 }}>
          {headerComp}
          <div style={{ display: "flex" }}>{subHeaderComp}</div>
        </div>
        <div>{headerRightComp}</div>
      </div>
      <HorizontalDivider altMargin={14} />
      <div
        style={{
          border: `1.5px solid ${theme.DividerColor.borderColor}`,
          backgroundColor: Colors.WHITE1,
          padding: 10,
          marginInline: 14,
          marginTop: 10,
          borderRadius: 8,
          height: 40,
          overflow: "hidden",
        }}
      >
        <h4
          className="TwoLineText"
          style={{
            fontWeight: "400",
            fontSize: 13,
            whiteSpace: "pre-line",
          }}
        >
          {loading ? (
            <div className="Centering" style={{ height: 40 }}>
              <CircularProgress style={{ color: Colors.GRAY1 }} size={18} />
            </div>
          ) : (
            <ModifiedTextMessageDisplay
              message={message}
              disablePlugins={disablePlugins}
            />
          )}
        </h4>
      </div>
    </div>
  );
};

export default PreviewBoxContainer;
