// export type AppParamListProps =
//   | AdminDashboardProps
//   | AdminDashboardEventsProps
//   | CommunityScreenProps
//   | EmailLoginScreenProps
//   | EventQRCodeScreenProps
//   | FullEventScreenProps
//   | FullEventFinalScreenProps
//   | GuestListScreenProps
//   | LoginScreenProps
//   | OrganizersPageScreenProps
//   | ProfileEventsScreenProps
//   | ProfileFinalScreenProps
//   | ProfileFinalPathScreenProps
//   | QrCodeScreenProps
//   | SubscriptionPlanScreenProps
//   | SubscriptionCheckoutScreenProps
//   | SubscriptionMarkitNumberScreenProps
//   | SubscriptionCancelScreenProps;

export enum NavigationId {
  ADMIN_DASHBOARD = "/adminDashboard/*",
  ADMIN_DASHBOARD_EVENTS = "/adminDashboard/events",
  COMMUNITY = "/community",
  EMAIL_LOGIN = "/login/email",
  EVENT_QR_CODE = "/",
  EXPLORE = "/explore",
  FULLEVENT_FINAL = "/e/:eventId/i/:userId",
  GUEST_LIST = "/e/:eventId/guestlist",
  HOME = "/home/*",
  HOME_PROFILE = "/home/profile",
  HOME_CONTACTS = "/home/contacts",
  HOME_MEMBERSHIPS = "/home/memberships",
  HOME_LISTS = "/home/lists",
  HOME_IMPORT = "/home/import",
  HOME_INTEGRATIONS = "/home/integrations",
  HOME_EVENTS = "/home/events",
  HOME_LINKS = "/home/links",
  HOME_CAMPAIGNS = "/home/campaigns",
  HOME_AUTOMATIONS = "/home/automations",
  HOME_CONVERSATIONS = "/home/conversations",
  HOME_TRANSACTIONS = "/home/transactions",
  HOME_BILLING = "/home/billing",
  HOME_GET_STARTED = "/home/get-started",
  IMPORT = "/import",
  INTEGRATIONS = "/integrations",
  CREATE = "/create",
  CREATE_LINK = "/createLink",
  CREATE_LINK_EVENT = "/createLink?event",
  ONBOARDING = "/onboarding",
  ORGANIZERSPAGE = "/e/:eventId/i/:userId/organizers",
  PROFILE_EVENTS = "/u/:username/eventlist",
  PROFILE_FINAL = "/u/:username",
  QR_CODE = "/",
  SIGN_IN = "/signIn",
  SUBSCRIPTION_PLAN = "/subscription/*",
  SUBSCRIPTION_CHECKOUT = "/subscription/checkout",
  SUBSCRIPTION_DETAILS = "/subscription/details",
  SUBSCRIPTION_MARKIT_NUMBER = "/subscription/details/onboarding",
  WELCOME = "/welcome",
}

export enum LandingPageNavigationId {
  PRICING = "/pricing",
}

export interface AdminDashboardProps {
  id: NavigationId.ADMIN_DASHBOARD;
}

export interface FullEventFinalProps {
  id: NavigationId.FULLEVENT_FINAL;
}

export type AppParamList = {
  Login: undefined;
  FullEventFinal: {
    eventId: string;
    userId: string;
  };
};
