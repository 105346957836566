import { CSSProperties } from "react";
import { Colors } from "../../utils/colors";
import RectangleButton from "./RectangleButton";

type FatHeaderActionButtonProps = {
  title: string;
  onPress: () => void;
  icon?: JSX.Element;
  loading?: boolean;
  containerStyles?: CSSProperties;
};

// This button is used on headers of pages and flows (has border around button)
const FatHeaderActionButton = (props: FatHeaderActionButtonProps) => {
  const { title, onPress, icon, loading, containerStyles } = props;

  const styles = {
    headerRightButton: {
      whiteSpace: "nowrap",
      border: `1.5px solid ${Colors.GRAY2}`,
      ...containerStyles,
    },
  };

  return (
    <RectangleButton
      buttonLabel={title}
      onPress={onPress}
      containerStyles={styles.headerRightButton}
      altPaddingHorz={20}
      altPaddingVert={14}
      altBorderRadius={100}
      width={loading ? 100 : undefined}
      altTextColor={containerStyles?.color || Colors.BLACK}
      altColor={Colors.WHITE1}
      iconRight={icon}
      loading={loading}
      disabled={loading}
    />
  );
};

export default FatHeaderActionButton;
