import "../../css/FullEvent/FullEvent.css";
import "../../css/GlobalStyles.css";
import React, {
  memo,
  useMemo,
  useCallback,
  useContext,
  useState,
  useRef,
} from "react";
import {
  Event,
  AccountData,
  RoleCase,
  FollowSourceType,
  TicketV2,
} from "@markit/common.types";
import { isDesktop } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import {
  LoginState,
  accountActions,
  getAccountState,
  logoutUser,
} from "../../redux/slices/accountSlice";
import { useTheme } from "../../hooks/useTheme";
import RectangleButton from "../Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import StandardContainer from "../Containers/StandardContainer";
import { friendlyRoleName } from "@markit/common.utils";
import { API } from "../../API";
import { MixpanelContext } from "../../context/AnalyticsService";
import { useLocation, useNavigate } from "react-router-dom";
import { getTicketsRef } from "../../utils/FirebaseUtils";
import { getDocs, onSnapshot, query, where } from "../../firebase";
import {
  refreshEventData,
  refreshEventTicketData,
} from "../../redux/slices/eventSlice";
import useOnUnmount from "../../hooks/useOnUnmount";
import { NavigationId } from "../../navigation/AppParamList";
import { LoginStage } from "../../screens/Login/LoginWelcome";

type FullEventOrganizerConfirmationProps = {
  event: Event;
  roleTicket: TicketV2;
  roleUserData: AccountData | undefined;
  alreadyHaveRole: boolean;
  userData: AccountData;
  numOfTickets: number;
  viewQRCode: () => void;
  calendar: React.ReactNode;
};

const FullEventOrganizerConfirmation = memo(
  function FullEventOrganizerConfirmationFn(
    props: FullEventOrganizerConfirmationProps
  ) {
    const {
      event,
      roleTicket,
      roleUserData,
      alreadyHaveRole,
      userData,
      numOfTickets,
      viewQRCode,
      calendar,
    } = props;
    const { accountData, loggedIn } = useSelector(getAccountState).account;
    const { theme } = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation() || {};
    const mixpanel = useContext(MixpanelContext);
    const [acceptRoleLoading, setAcceptRoleLoading] = useState(false);
    const unsubscribeRef = useRef<(() => void) | void>();

    const styles = {
      subtext: {
        fontSize: 14,
      },
    };

    const roleName = useMemo(() => friendlyRoleName(roleTicket), [roleTicket]);

    useOnUnmount((_latestDeps) => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
        unsubscribeRef.current = undefined;
      }
    }, []);

    const userRoleCase = useMemo(() => {
      if (alreadyHaveRole) {
        return RoleCase.LOGGED_IN_ALREADY_HAVE_A_ROLE;
      }
      if (roleTicket) {
        return loggedIn === LoginState.LOGGED_IN
          ? roleTicket.uid === ""
            ? RoleCase.LOGGED_IN_UNASSIGNED
            : roleTicket.uid === accountData.uid
            ? RoleCase.LOGGED_IN_ASSIGNED_CORRECTLY
            : RoleCase.LOGGED_IN_ASSIGNED_INCORRECTLY
          : roleTicket.uid !== "" && roleUserData
          ? RoleCase.LOGGED_OUT_ASSIGNED
          : RoleCase.LOGGED_OUT_UNASSIGNED;
      } else {
        return RoleCase.NO_ROLE;
      }
    }, [accountData.uid, alreadyHaveRole, loggedIn, roleTicket, roleUserData]);

    const disableAcceptRole = useMemo(
      () => userRoleCase === RoleCase.LOGGED_IN_ALREADY_HAVE_A_ROLE,
      [userRoleCase]
    );

    const roleHeaderText = useMemo(() => {
      switch (userRoleCase) {
        case RoleCase.LOGGED_IN_UNASSIGNED:
          return `Signed in as ${accountData.fullName}`;
        case RoleCase.LOGGED_IN_ASSIGNED_CORRECTLY:
          return `You are a ${roleName}`;
        case RoleCase.LOGGED_IN_ASSIGNED_INCORRECTLY:
        case RoleCase.LOGGED_OUT_ASSIGNED:
          return `${
            roleUserData ? roleUserData.fullName : "---"
          } was assigned as ${roleName}`;
        case RoleCase.LOGGED_IN_ALREADY_HAVE_A_ROLE:
          return `You are already a ${roleName}`;
        case RoleCase.LOGGED_OUT_UNASSIGNED:
        case RoleCase.NO_ROLE:
          return "";
      }
    }, [accountData.fullName, userRoleCase, roleName, roleUserData]);

    const roleSubText = useMemo(() => {
      switch (userRoleCase) {
        case RoleCase.LOGGED_IN_UNASSIGNED:
          return `Accept this ${roleName.toLowerCase()} role as ${
            accountData.fullName
          } by pressing 'accept role' below.`;
        case RoleCase.LOGGED_IN_ASSIGNED_CORRECTLY:
          return "Manage the event below.";
        case RoleCase.LOGGED_IN_ASSIGNED_INCORRECTLY:
          return `You are logged into the wrong account. Login as ${
            roleUserData ? roleUserData.fullName : "---"
          } to view/manage this role.`;
        case RoleCase.LOGGED_OUT_ASSIGNED:
          return `Login as ${
            roleUserData ? roleUserData.fullName : "---"
          } to view this role.`;
        case RoleCase.LOGGED_IN_ALREADY_HAVE_A_ROLE:
          return "Ask the host to remove your existing role or login under a different account to accept another role.";
        case RoleCase.LOGGED_OUT_UNASSIGNED:
        case RoleCase.NO_ROLE:
          return "";
      }
    }, [userRoleCase, roleName, accountData.fullName, roleUserData]);

    const roleButtonText = useMemo(() => {
      switch (userRoleCase) {
        case RoleCase.LOGGED_IN_UNASSIGNED:
        case RoleCase.LOGGED_IN_ALREADY_HAVE_A_ROLE:
          return "Accept Role";
        case RoleCase.LOGGED_IN_ASSIGNED_CORRECTLY:
          return "Event Dashboard";
        case RoleCase.LOGGED_IN_ASSIGNED_INCORRECTLY:
          return "Logout";
        case RoleCase.LOGGED_OUT_ASSIGNED:
          return "Login";
        case RoleCase.LOGGED_OUT_UNASSIGNED:
        case RoleCase.NO_ROLE:
          return "";
      }
    }, [userRoleCase]);

    const errorAcceptingEventRole = useCallback(() => {
      mixpanel.track("Webapp: Error Redeeming Unassigned Role", {
        distinct_id: userData.uid,
        creation_type: "login",
        event_id: event.id,
        timeout: false,
      });
      alert("Error accepting role. Please try again.");
      setAcceptRoleLoading(false);
    }, [event.id, mixpanel, userData.uid]);

    const redirectToDashboard = useCallback(async () => {
      dispatch(refreshEventData(event.id));
      dispatch(refreshEventTicketData(event.id, userData.uid));
      mixpanel.track("Webapp: Redeemed Unassigned Role", {
        distinct_id: userData.uid,
        creation_type: "login",
        event_id: event.id,
      });
      dispatch(accountActions.setCurrentRoleTicket(undefined));

      // wait a second for new ticket data to be updated
      setTimeout(async () => {
        navigate(`/e/${event.id}/dashboard`, { state: { eventId: event.id } });
        setAcceptRoleLoading(false);
      }, 2000);
    }, [dispatch, event.id, mixpanel, navigate, userData.uid]);

    const roleButtonOnPress = useCallback(async () => {
      if (event && userData && roleTicket) {
        switch (userRoleCase) {
          case RoleCase.LOGGED_IN_UNASSIGNED:
            setAcceptRoleLoading(true);
            // assign the roles
            await API.rsvp
              .enqueueRSVPToEvent({
                toPhoneNumber: userData.phoneNumber,
                fullName: userData.fullName,
                eventId: event.id,
                followSourceType: isDesktop
                  ? FollowSourceType.DESKTOP_WEB
                  : FollowSourceType.MOBILE_WEB,
                customTicketId: "",
                numberOfTickets: 1,
                redeemTicketId: roleTicket.id,
                promoCodeId: "",
                userReferrer: "",
                referrer: "",
                amount: 0,
                chargeId: "",
                customerId: "",
                uid: userData.uid,
              })
              .then(async (response) => {
                const { success, firstTicketIdTracker } = response;
                if (!success) {
                  errorAcceptingEventRole();
                } else {
                  // listen for ticket creation before redirecting to dashboard
                  // most of this logic is copied from FullEventInputForm when redirecting to confirmation page
                  const ticketsRef = getTicketsRef(event.id);
                  const ticketIdTrackerQuery = query(
                    ticketsRef,
                    where("id", "==", firstTicketIdTracker)
                  );

                  // alert with an error if not redirected within 20 seconds
                  const timeout = setTimeout(async () => {
                    // check one more time if the ticket exists
                    const ticketsRef = getTicketsRef(event.id);
                    const ticketIdTrackerQuery = query(
                      ticketsRef,
                      where("id", "==", firstTicketIdTracker)
                    );
                    const ticketIdTrackerSnapshot = await getDocs(
                      ticketIdTrackerQuery
                    );
                    if (!ticketIdTrackerSnapshot.empty) {
                      const newTicket = ticketIdTrackerSnapshot.docs[0].data();
                      // only success if you ticket was updated with the organizer's uid
                      if (newTicket.uid === userData.uid) {
                        redirectToDashboard();
                      } else {
                        errorAcceptingEventRole();
                      }
                    } else {
                      // this case should be impossible since the ticket should always exist since you're redeeming an unassigned role
                      errorAcceptingEventRole();
                    }
                  }, 20000);

                  const unsubscribe = onSnapshot(
                    ticketIdTrackerQuery,
                    (snapshot) => {
                      snapshot.docChanges().forEach(async (change) => {
                        const newTicket = change.doc.data();
                        // only success if you ticket was updated with the organizer's uid
                        if (newTicket.uid === userData.uid) {
                          // make sure we don't redirect again since it found the ticket
                          clearTimeout(timeout);
                          redirectToDashboard();
                        }
                      });
                    }
                  );
                  unsubscribeRef.current = unsubscribe;
                }
              })
              .catch((e: any) => {
                alert(
                  "An error occurred. " +
                    "Please try again or contact the hotline number below."
                );
              });
            break;
          case RoleCase.LOGGED_IN_ASSIGNED_CORRECTLY:
            navigate(`/e/${event.id}/dashboard`, {
              state: { eventId: event.id },
            });
            break;
          case RoleCase.LOGGED_IN_ASSIGNED_INCORRECTLY:
            try {
              dispatch(logoutUser());
              alert("You have successfully logged out!");
            } catch (e: any) {
              alert("There was an error logging out. Please try again.");
              console.log(e.message);
            }
            break;
          case RoleCase.LOGGED_OUT_ASSIGNED:
            dispatch(accountActions.setRedirectPath(location.pathname));
            navigate(NavigationId.SIGN_IN, {
              state: { preLoginStage: LoginStage.PHONE },
            });
            break;
          case RoleCase.LOGGED_OUT_UNASSIGNED:
          case RoleCase.LOGGED_IN_ALREADY_HAVE_A_ROLE:
          case RoleCase.NO_ROLE:
        }
      }
    }, [
      event,
      userData,
      roleTicket,
      userRoleCase,
      navigate,
      dispatch,
      location.pathname,
      errorAcceptingEventRole,
      redirectToDashboard,
    ]);

    const renderOrganizerHeader = useMemo(
      () => (
        <div className="ColumnNormal" style={{ gap: 14, paddingBottom: 24 }}>
          <Icon
            icon={"ion:person-circle"}
            height={35}
            style={{ color: theme.PrimaryText.color }}
          />
          <span style={{ fontSize: 18, fontWeight: "500" }}>
            {roleHeaderText}
          </span>
          <span style={{ ...styles.subtext, ...theme.SubText }}>
            {roleSubText}
          </span>
        </div>
      ),
      [
        roleHeaderText,
        roleSubText,
        styles.subtext,
        theme.PrimaryText.color,
        theme.SubText,
      ]
    );

    return (
      <StandardContainer
        headerComp={renderOrganizerHeader}
        theme={theme}
        valueComp={
          <div className="ColumnNormal" style={{ gap: 10 }}>
            <RectangleButton
              buttonLabel={
                <div className="AlignedRowSelect" style={{ gap: 8 }}>
                  <span
                    style={
                      disableAcceptRole
                        ? theme.DisabledButtonText
                        : theme.ButtonText
                    }
                  >
                    {roleButtonText}
                  </span>
                  <Icon
                    icon="iconoir:arrow-up-right"
                    height={16}
                    style={
                      disableAcceptRole
                        ? theme.DisabledButtonText
                        : theme.ButtonText
                    }
                  />
                </div>
              }
              altPaddingVert={14}
              altBorderRadius={12}
              onPress={roleButtonOnPress}
              theme={theme}
              disabled={disableAcceptRole || acceptRoleLoading}
              loading={acceptRoleLoading}
            />
            <div className="AlignedRow" style={{ gap: 10 }}>
              {numOfTickets > 1 ? (
                <RectangleButton
                  buttonLabel={<span>View Extra Tickets</span>}
                  altPaddingVert={14}
                  altBorderRadius={12}
                  onPress={viewQRCode}
                  altColor={theme.TertiaryBG.backgroundColor}
                  altTextColor={theme.PrimaryText.color}
                  containerStyles={{ flex: 1 }}
                />
              ) : null}
              {calendar}
            </div>
          </div>
        }
      />
    );
  }
);

export default FullEventOrganizerConfirmation;
