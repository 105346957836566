import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useCallback, useMemo } from "react";
import { hasSubscription, isBusinessSubscription } from "@markit/common.utils";
import { Colors } from "../../utils/colors";
import { useNavigate } from "../../hooks/useNavigate";
import { NavigationId } from "../../navigation/AppParamList";
import FatHeaderActionButton from "../Buttons/FatHeaderActionButton";
import { notificationActions } from "../../redux/slices/notificationSlice";

type MarkitPlusActionButtonProps = { mainAction?: boolean; small?: boolean };

const MarkitPlusActionButton = (props: MarkitPlusActionButtonProps) => {
  const { mainAction, small } = props;
  const { accountData } = useSelector(getAccountState).account;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const styles = {
    title: {
      fontSize: small ? 12 : 14,
      fontWeight: 500,
      color: hasSubscription(accountData)
        ? Colors.WHITE
        : mainAction
        ? Colors.WHITE
        : Colors.BLACK,
    },

    headerRightButton: {
      paddingInline: 20,
      paddingBlock: 14,
      borderRadius: 100,
      whiteSpace: "nowrap",
    },

    smallHeaderRightButton: {
      paddingInline: 10,
      paddingBlock: 5,
      borderRadius: 100,
      whiteSpace: "nowrap",
    },
  };

  const buttonTitle = useMemo(
    () => (
      <span style={styles.title}>
        {hasSubscription(accountData) ? "Markit" : "Get Markit"}
        <span
          style={{
            color:
              hasSubscription(accountData) || !mainAction
                ? Colors.BLUE5
                : undefined,
          }}
        >
          +
        </span>
      </span>
    ),
    [accountData, mainAction, styles.title]
  );

  const showUpgradeButton = useMemo(
    () => hasSubscription(accountData) && !isBusinessSubscription(accountData),
    [accountData]
  );

  const showMainButton = useMemo(
    () => !hasSubscription(accountData) || mainAction,
    [accountData, mainAction]
  );

  const onPress = useCallback(() => {
    if (hasSubscription(accountData)) {
      navigate(NavigationId.HOME_BILLING);
    } else {
      dispatch(notificationActions.setMarkitPlusModal(true));
    }
  }, [accountData, dispatch, navigate]);

  const onUpgradePress = useCallback(() => {
    navigate("/subscription", { state: { changePlan: true } });
  }, [navigate]);

  return showMainButton || showUpgradeButton ? (
    <>
      <div className="AlignedRow" style={{ gap: 14 }}>
        {small ? (
          <div
            className="AlignedRowSelect"
            onClick={onPress}
            style={{
              ...styles.smallHeaderRightButton,
              backgroundColor: hasSubscription(accountData)
                ? Colors.BLACK
                : mainAction
                ? Colors.BLUE5
                : Colors.GRAY6,
            }}
          >
            {buttonTitle}
          </div>
        ) : null}
        {!small && showUpgradeButton ? (
          <FatHeaderActionButton title="Upgrade" onPress={onUpgradePress} />
        ) : null}
        {!small && showMainButton ? (
          <div
            className="AlignedRowSelect"
            onClick={onPress}
            style={{
              ...styles.headerRightButton,
              backgroundColor: hasSubscription(accountData)
                ? Colors.BLACK
                : mainAction
                ? Colors.BLUE5
                : Colors.GRAY6,
            }}
          >
            {buttonTitle}
          </div>
        ) : null}
      </div>
    </>
  ) : null;
};

export default MarkitPlusActionButton;
