import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import CustomTextField from "../../components/CustomTextField";
import React, { memo, useCallback, useRef, useState } from "react";
import {
  MEDIA_UPLOAD_CODES,
  errorAlertText,
  errorSubAlertText,
  uploadMedia,
} from "../../utils/photoUtils";
import { AccountData } from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import {
  INSTAGRAM_DEFAULT,
  LINKEDIN_DEFAULT,
  SPOTIFY_DEFAULT,
  TIKTOK_DEFAULT,
  X_DEFAULT,
  detectedCensored,
} from "@markit/common.utils";
import AlertContainer from "../../components/Containers/AlertContainer";
import ProfilePic from "../ProfilePic";
import CustomTextFieldMultiline from "../TextFields/CustomTextFieldMultiline";

export type EditProfileError = {
  type: "fullName" | "username" | "profilePic" | "";
  message: string;
};

type EditProfileDetailsProps = {
  accountSettings: AccountData;
  updateAccountSettings: (accountSettings: Partial<AccountData>) => void;
  showError: EditProfileError;
  setShowError: (showError: EditProfileError) => void;
  loading: boolean;
  isOnboarding?: boolean;
};

const EditProfileDetails = (props: EditProfileDetailsProps) => {
  const {
    accountSettings,
    updateAccountSettings,
    showError,
    setShowError,
    loading,
    isOnboarding,
  } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { theme } = useTheme();
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const styles = {
    labelText: { fontSize: 12, fontWeight: 500, color: Colors.BLACK },
    labelTagText: { fontSize: 12, fontWeight: 500, color: Colors.GRAY2 },
    labelTagTextError: {
      fontSize: 12,
      fontWeight: 500,
      color: theme.ErrorBorderColor.borderColor,
    },
  };

  const updateAccountState = useCallback(
    (update: Partial<AccountData>) => {
      if (update.fullName !== undefined) {
        updateAccountSettings({ fullName: update.fullName });
        if (detectedCensored(update.fullName)) {
          setShowError({
            type: "fullName",
            message: "Content Violation: Banned word(s) detected",
          });
          return;
        } else {
          setShowError({ type: "", message: "" });
        }
      }
      if (update.username !== undefined) {
        setShowError({ type: "", message: "" });
        updateAccountSettings({ username: update.username });
      }
    },
    [setShowError, updateAccountSettings]
  );

  const uploadImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return new Promise<void>((resolve, reject) => {
        (async () => {
          const uploadData = await uploadMedia(event, true, true);
          if (uploadData.uploadCode !== MEDIA_UPLOAD_CODES.SUCCESS) {
            setAlertText({
              heading: errorAlertText(uploadData.uploadCode, true),
              subHeading: errorSubAlertText(uploadData.uploadCode),
            });

            reject();
          } else {
            updateAccountSettings({ profilePicURL: uploadData.blobUrl });
            setShowError({ type: "", message: "" });
            resolve();
          }
        })().catch(reject); // Handle any async errors
      });
    },
    [setShowError, updateAccountSettings]
  );

  return (
    <>
      <div className="ColumnNormal" style={{ gap: 14 }}>
        <div className="ColumnNormal" style={{ gap: 7 }}>
          <div className="AlignedRow" style={{ gap: 7 }}>
            <span style={styles.labelText}>
              {isOnboarding ? "Add a " : ""}Profile Photo
            </span>
            {accountData.inCreatorMode ? (
              <span
                style={
                  showError.type === "profilePic"
                    ? styles.labelTagTextError
                    : styles.labelTagText
                }
              >
                {"(required)"}
              </span>
            ) : null}
          </div>
          <label
            className="Centering"
            style={{
              position: "relative",
              width: 80,
              height: 80,
              borderRadius: 80 / 2,
              borderStyle: "dashed",
              borderColor:
                showError.type === "profilePic"
                  ? theme.ErrorBorderColor.borderColor
                  : Colors.GRAY2,
              borderWidth: accountSettings.profilePicURL ? 0 : 1,
              cursor: "pointer",
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={uploadImage}
              style={{ color: "transparent" }}
            />
            {accountSettings.profilePicURL ? (
              <>
                <ProfilePic user={accountSettings} size={80} forcePointer />
                <div
                  className="Centering"
                  style={{
                    position: "absolute",
                    left: 60,
                    bottom: 0,
                    width: 24,
                    height: 24,
                    borderRadius: 6,
                    backgroundColor: Colors.WHITE1,
                    border: `0.5px solid ${Colors.GRAY2}`,
                    cursor: "pointer",
                  }}
                >
                  <Icon icon={"ion:create"} height={16} />
                </div>
              </>
            ) : (
              <Icon
                icon="ion:add"
                height={42}
                style={{ color: Colors.BLACK }}
              />
            )}
          </label>
        </div>
        <div className="ColumnNormal" style={{ gap: 7 }}>
          <div className="AlignedRow" style={{ gap: 7 }}>
            <span style={styles.labelText}>Full Name</span>
            <span
              style={
                showError.type === "fullName"
                  ? styles.labelTagTextError
                  : styles.labelTagText
              }
            >
              {"(required)"}
            </span>
          </div>
          <CustomTextField
            value={accountSettings.fullName}
            placeholder="full name"
            disabled={loading}
            borderRadius={12}
            inputMode="text"
            error={showError.type === "fullName"}
            onChange={(change: any) => {
              updateAccountState({ fullName: change.target.value });
            }}
            maxLength={42}
          />
          {showError.type === "fullName" ? (
            <span style={{ fontSize: 12, color: Colors.RED2 }}>
              {showError.message}
            </span>
          ) : null}
        </div>
        <div className="ColumnNormal" style={{ gap: 7 }}>
          <div className="AlignedRow" style={{ gap: 7 }}>
            <span style={styles.labelText}>Username</span>
            <span
              style={
                showError.type === "username"
                  ? styles.labelTagTextError
                  : styles.labelTagText
              }
            >
              {"(required)"}
            </span>
          </div>
          <CustomTextField
            value={accountSettings.username}
            placeholder="username"
            disabled={loading}
            borderRadius={12}
            inputMode="text"
            error={showError.type === "username"}
            onChange={(change: any) => {
              updateAccountState({ username: change.target.value });
            }}
            maxLength={30}
          />
          {showError.type === "username" ? (
            <span style={{ fontSize: 12, color: Colors.RED2 }}>
              {showError.message}
            </span>
          ) : null}
        </div>
        {accountData.inCreatorMode || !isOnboarding ? (
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <span style={styles.labelText}>Bio</span>
              <span style={styles.labelTagText}>{"(optional)"}</span>
            </div>
            <CustomTextFieldMultiline
              value={accountSettings.bio}
              placeholder="bio"
              disabled={loading}
              onChange={(change: any) => {
                updateAccountSettings({ bio: change.target.value });
              }}
              containerStyles={{ backgroundColor: Colors.WHITE }}
              maxLength={250}
            />
          </div>
        ) : null}
        {accountData.inCreatorMode && !isOnboarding ? (
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <span style={styles.labelText}>Link</span>
              <span style={styles.labelTagText}>{"(optional)"}</span>
            </div>
            <CustomTextField
              value={accountSettings.profileLink}
              placeholder="Enter website"
              disabled={loading}
              borderRadius={12}
              inputMode="text"
              onChange={(change: any) => {
                updateAccountSettings({ profileLink: change.target.value });
              }}
              maxLength={150}
            />
          </div>
        ) : null}
        <div className="ColumnNormal" style={{ gap: 7 }}>
          <div className="AlignedRow" style={{ gap: 7 }}>
            <span style={styles.labelText}>Link Socials</span>
            <span style={styles.labelTagText}>{"(optional)"}</span>
          </div>
          <div>
            <CustomTextField
              value={accountSettings.instagram}
              placeholder={INSTAGRAM_DEFAULT}
              borderRadius={12}
              rectangleBottom
              noBottomBorder
              inputMode="text"
              onChange={(change: any) => {
                updateAccountSettings({ instagram: change.target.value });
              }}
              startAdornment={
                <Icon
                  icon="mdi:instagram"
                  height={22}
                  color={
                    accountSettings.instagram === ""
                      ? Colors.GRAY2
                      : Colors.BLACK
                  }
                  style={{ marginLeft: 14 }}
                />
              }
            />
            <CustomTextField
              value={accountSettings.linkedin}
              placeholder={LINKEDIN_DEFAULT}
              borderRadius={12}
              rectangleTop
              rectangleBottom
              noBottomBorder
              inputMode="text"
              onChange={(change: any) => {
                updateAccountSettings({ linkedin: change.target.value });
              }}
              startAdornment={
                <Icon
                  icon="ri:linkedin-fill"
                  height={22}
                  color={
                    accountSettings.linkedin === ""
                      ? Colors.GRAY2
                      : Colors.BLACK
                  }
                  style={{ marginLeft: 14 }}
                />
              }
            />
            <CustomTextField
              value={accountSettings.twitter}
              placeholder={X_DEFAULT}
              borderRadius={12}
              rectangleTop
              rectangleBottom
              noBottomBorder
              inputMode="text"
              onChange={(change: any) => {
                updateAccountSettings({ twitter: change.target.value });
              }}
              startAdornment={
                <Icon
                  icon="ri:twitter-x-fill"
                  height={22}
                  color={
                    accountSettings.twitter === "" ? Colors.GRAY2 : Colors.BLACK
                  }
                  style={{ marginLeft: 14 }}
                />
              }
            />
            <CustomTextField
              value={accountSettings.tiktok}
              placeholder={TIKTOK_DEFAULT}
              inputMode="text"
              borderRadius={12}
              rectangleTop
              rectangleBottom
              noBottomBorder
              onChange={(change: any) => {
                updateAccountSettings({ tiktok: change.target.value });
              }}
              startAdornment={
                <Icon
                  icon="ic:baseline-tiktok"
                  height={22}
                  color={
                    accountSettings.tiktok === "" ? Colors.GRAY2 : Colors.BLACK
                  }
                  style={{ marginLeft: 14 }}
                />
              }
            />
            <CustomTextField
              value={accountSettings.spotify}
              placeholder={SPOTIFY_DEFAULT}
              inputMode="text"
              borderRadius={12}
              rectangleTop
              onChange={(change: any) => {
                updateAccountSettings({ spotify: change.target.value });
              }}
              startAdornment={
                <Icon
                  icon="mdi:spotify"
                  height={22}
                  color={
                    accountSettings.spotify === "" ? Colors.GRAY2 : Colors.BLACK
                  }
                  style={{ marginLeft: 14 }}
                />
              }
            />
          </div>
        </div>
      </div>
      <AlertContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 4 }}>
            <Icon icon="mdi-image-remove" height={40} />
            <span style={{ fontSize: 16, fontWeight: "500" }}>
              {alertText.heading}
            </span>
          </div>
        }
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        theme={theme}
        closeModal={() => {
          setAlertText({ heading: "", subHeading: "" });
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
    </>
  );
};

export default memo(EditProfileDetails);
