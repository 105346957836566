import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { NotificationBanner, NotificationType } from "@markit/common.types";

export type NotificationState = {
  notificationBanner: NotificationBanner;
  markitPlusModal: boolean;
};

export const initialNotificationState: NotificationState = {
  notificationBanner: {
    lastTriggered: "",
    confirmAction: false,
    title: "",
    type: NotificationType.NONE,
    altIcon: "",
  },
  markitPlusModal: false,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState: initialNotificationState,
  reducers: {
    setNotificationBanner: (
      state,
      action: PayloadAction<NotificationBanner>
    ) => {
      state.notificationBanner = action.payload;
    },
    clearNotificationBanner: (state) => {
      state.notificationBanner = {
        lastTriggered: state.notificationBanner.lastTriggered,
        confirmAction: false,
        title: "",
        type: NotificationType.NONE,
        altIcon: "",
      };
    },
    setMarkitPlusModal: (state, action: PayloadAction<boolean>) => {
      state.markitPlusModal = action.payload;
    },
  },
});

export const notificationActions = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;

export const getNotificationState = (state: AppState) => state.notifications;
