import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import { useCallback, useMemo, useState } from "react";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "../../Containers/PopupModalContainer";
import { useDispatch, useSelector } from "react-redux";
import { RWebShare } from "react-web-share";
import { useTheme } from "../../../hooks/useTheme";
import AlertButtonWrapper from "../../Containers/AlertButtonWrapper";
import { AccountData, NotificationType } from "@markit/common.types";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import RectangleButton from "../../Buttons/RectangleButton";
import QRCode from "react-qr-code";
import { onShareLink } from "../../../utils/shareLink";
import { isDesktop } from "react-device-detect";
import { deepCopy, MARKITAI } from "@markit/common.utils";
import {
  getAccountState,
  changeSharedProfileLink,
} from "../../../redux/slices/accountSlice";

export type ShareProfileLinkModalProps = {
  username: string;
  setShareLinkModal: (shareLinkModal: boolean) => void;
};

const ShareProfileLinkModal = (props: ShareProfileLinkModalProps) => {
  const { username, setShareLinkModal } = props;
  const { accountData } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const { theme } = useTheme();

  const [alertText, setAlertText] = useState("");
  const [qrCodeModal, setQrCodeModal] = useState(false);

  const styles = {
    smallText: { fontSize: 11, paddingTop: 7 },
  };
  const shareURL = useMemo(
    () => `${MARKITAI}/u/${username}/subscribe`,
    [username]
  );

  const updateSharedProfileLink = useCallback(() => {
    const newAccountData: AccountData = deepCopy(accountData);
    newAccountData.sharedProfileLink = true;
    dispatch(changeSharedProfileLink(true, accountData.uid));
  }, [dispatch, accountData]);

  const onCopyClick = useCallback(() => {
    const el = document.createElement("input");
    el.value = shareURL;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    updateSharedProfileLink();
    showNotificationBanner(dispatch, "Copied!", NotificationType.AFFIRMATIVE);
  }, [dispatch, shareURL, updateSharedProfileLink]);

  const shareProfileLink = useCallback(async () => {
    await onShareLink("Share your profile link!", shareURL);
    updateSharedProfileLink();
  }, [shareURL, updateSharedProfileLink]);

  const onMessagesClick = useCallback(() => {
    const url = `sms:&body=${encodeURIComponent(shareURL)}`;
    window.location.href = url;
    updateSharedProfileLink();
  }, [shareURL, updateSharedProfileLink]);

  const renderShareTo = useMemo(
    () => (
      <div
        className="ShareEventModalLinkIcon"
        style={theme ? theme.SecondaryBG : { backgroundColor: Colors.WHITE }}
      >
        <Icon icon="mdi:share-outline" height={26} />
      </div>
    ),
    [theme]
  );

  return (
    <>
      <PopupModalContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 10 }}>
            <Icon icon={"ion:share"} height={35} />
            <span className="sectionTitle">Share Profile</span>
          </div>
        }
        valueComp={
          <div className="ColumnNormal" style={{ gap: 14 }}>
            <AlertButtonWrapper
              buttonComp={
                <div
                  className="ColumnNormal"
                  style={{
                    backgroundColor: Colors.GRAY6,
                    padding: 14,
                    borderRadius: 12,
                    gap: 14,
                  }}
                >
                  <span className="bodyMedium">General Link</span>
                  <div
                    className="ShareEventModalLinks"
                    style={theme?.PrimaryText}
                  >
                    <div>
                      <div
                        className="ShareEventModalLinkIcon"
                        style={
                          theme
                            ? theme.SecondaryBG
                            : { backgroundColor: Colors.WHITE }
                        }
                        onClick={onCopyClick}
                      >
                        <Icon icon="ep:link" height={26} />
                      </div>
                      <div style={styles.smallText}>Copy Link</div>
                    </div>
                    {isDesktop ? (
                      <RWebShare data={{ url: shareURL }}>
                        <div>
                          {renderShareTo}
                          <span style={styles.smallText}>Share to...</span>
                        </div>
                      </RWebShare>
                    ) : (
                      <div>
                        <div onClick={shareProfileLink}>{renderShareTo}</div>
                        <span style={styles.smallText}>Share to...</span>
                      </div>
                    )}
                    <div>
                      <div
                        className="ShareEventModalLinkIcon"
                        style={
                          theme
                            ? theme.SecondaryBG
                            : { backgroundColor: Colors.WHITE }
                        }
                        onClick={onMessagesClick}
                      >
                        <Icon icon="ion:chatbubble-outline" height={22} />
                      </div>
                      <div style={styles.smallText}>Messages</div>
                    </div>
                    <div>
                      <div
                        className="ShareEventModalLinkIcon"
                        style={
                          theme
                            ? theme.SecondaryBG
                            : { backgroundColor: Colors.WHITE }
                        }
                        onClick={() => setAlertText("Coming soon...")}
                      >
                        <Icon icon="mdi:instagram" height={24} />
                      </div>
                      <div style={styles.smallText}>Instagram</div>
                    </div>
                    <div>
                      <div
                        className="ShareEventModalLinkIcon"
                        style={
                          theme
                            ? theme.SecondaryBG
                            : { backgroundColor: Colors.WHITE }
                        }
                        onClick={() => setQrCodeModal(true)}
                      >
                        <Icon icon="ion:qr-code" height={22} />
                      </div>
                      <div style={{ ...styles.smallText, textAlign: "center" }}>
                        QR Code
                      </div>
                    </div>
                  </div>
                </div>
              }
              alertTextHeader={alertText}
              clearAlert={() => setAlertText("")}
            />
            <RectangleButton
              buttonLabel="Copy Link"
              iconRight={<Icon icon="ep:link" height={16} />}
              onPress={onCopyClick}
              containerStyles={{
                backgroundColor: Colors.BLACK,
                color: Colors.WHITE,
                paddingBlock: 16,
                paddingInline: 12,
              }}
            />
          </div>
        }
        theme={theme}
        noExit
        closeOnOutsidePress={!qrCodeModal}
        closeModal={() => setShareLinkModal(false)}
        modalContentWidth={PopupModalContainerWidth.SMALL}
      />
      {qrCodeModal ? (
        <PopupModalContainer
          headerComp={
            <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <span className="AboutSubtitle">Share QR Code</span>
            </div>
          }
          valueComp={
            <div className="Centering" style={{ paddingBlock: "30px" }}>
              <QRCode value={shareURL} />
            </div>
          }
          noExit
          closeOnOutsidePress
          closeModal={() => setQrCodeModal(false)}
        />
      ) : null}
    </>
  );
};

export default ShareProfileLinkModal;
