import "../../css/Subscription.css";
import { memo } from "react";
import { isDesktop } from "react-device-detect";
import Footer from "../../components/Footer";
import CreatorModeWrapper from "../../components/CreatorDashboard/CreatorModeWrapper";
import TopHeader from "../../components/TopHeader";
import SubscriptionPlansFlow from "../../components/Subscription/SubscriptionPlansFlow";

const SubscriptionPlan = memo(function SubscriptionPlanFn() {
  return (
    <>
      {isDesktop ? (
        <CreatorModeWrapper hideSidebar>
          <SubscriptionPlansFlow />
        </CreatorModeWrapper>
      ) : (
        <div className="ColumnNormal">
          <TopHeader />
          <SubscriptionPlansFlow />
          <Footer />
        </div>
      )}
    </>
  );
});

export default SubscriptionPlan;
