import { useCallback, useState } from "react";
import { getUserAudienceListMembersRef } from "../../utils/FirebaseUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  accountActions,
  getAccountState,
} from "../../redux/slices/accountSlice";
import useAsyncEffect from "../useAsyncEffect";
import { getDocs, orderBy, query } from "../../firebase";

type useLiveUpdatingListAnalyticsProps = {
  listId: string;
};

export const useLiveUpdatingListAnalytics = (
  props: useLiveUpdatingListAnalyticsProps
) => {
  const { listId } = props;
  const dispatch = useDispatch();
  const { accountData } = useSelector(getAccountState).account;
  const [liveListContactUids, setLiveListContactUids] = useState<string[]>([]);
  const [initialLoaded, setInitialLoaded] = useState(false);

  const [loaded, setLoaded] = useState(false);

  const reloadCount = useCallback(() => {
    setLoaded(false);
  }, []);

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (_deps, isLatest) => {
      if (!loaded && listId) {
        const audienceListMembersRef = getUserAudienceListMembersRef(
          accountData.uid,
          listId
        );
        const audienceListMembersQuery = query(
          audienceListMembersRef,
          orderBy("createdAt", "desc")
        );
        const snapshot = await getDocs(audienceListMembersQuery);
        if (isLatest()) {
          const members = snapshot.docs.map((doc) => doc.data().uid);
          if (!initialLoaded) {
            // Update the numMembers on redux only on first load
            dispatch(
              accountActions.addAudienceListNumMembers({
                listId,
                numMembers: members.length,
              })
            );
            setInitialLoaded(true);
          }
          setLiveListContactUids(members);
        }
      }
      setLoaded(true);
    },
    [accountData.uid, dispatch, initialLoaded, listId, loaded]
  );

  return {
    liveListContactUids,
    loaded,
    reloadCount,
  };
};
