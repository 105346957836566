import { Icon } from "@iconify/react";
import PopupModalContainer from "../../../Containers/PopupModalContainer";
import { MARKIT_HOTLINE_NUMBER } from "@markit/common.utils";
import { Colors } from "../../../../utils/colors";
import { formatPhoneNumber } from "../../../../utils/FormatPhoneNumber";

type SendingTextModalProps = {
  modalVisible: boolean;
  setModalVisible: (modalVisible: boolean) => void;
  numRecipients: number;
};

const SendingTextModal = (props: SendingTextModalProps) => {
  const { modalVisible, setModalVisible, numRecipients } = props;

  return modalVisible ? (
    <PopupModalContainer
      headerComp={
        <div className="ColumnNormal" style={{ gap: 14 }}>
          <Icon icon="ion:paper-plane" height={35} />
          <span className="sectionTitle">Sending Text Messages</span>
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="bodyMedium">
              Estimated Send Time:{" "}
              <span style={{ color: Colors.GREEN2 }}>
                {numRecipients === 0
                  ? "Calculating..."
                  : numRecipients / 4 < 60
                  ? "under a minute"
                  : numRecipients / 4 < 90
                  ? "1 minute"
                  : `${Math.ceil(numRecipients / 4 / 60)} minutes`}
              </span>
            </span>
            <span className="bodySubtext">
              Sending a text to many recipients at once can take up to several
              minutes as the texts are queued to carrier networks. Contact our
              hotline if your text gets stuck on sending for significantly
              longer than the estimated send time.
            </span>
          </div>
        </div>
      }
      valueComp={
        <div
          className="AlignedRow"
          style={{
            padding: 14,
            backgroundColor: Colors.GRAY6,
            borderRadius: 12,
            gap: 7,
            justifyContent: "center",
          }}
        >
          <span className="bodyMedium">Contact Hotline</span>{" "}
          <span className="bodySubtext">
            {formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}
          </span>
        </div>
      }
      noExit
      closeOnOutsidePress
      closeModal={() => setModalVisible(false)}
    />
  ) : null;
};

export default SendingTextModal;
