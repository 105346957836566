import PopupModalContainer, {
  PopupModalContainerWidth,
} from "../../Containers/PopupModalContainer";
import { useCallback, useContext, useEffect, useState } from "react";
import { filterUndefinedValues } from "@markit/common.utils";
import MembershipBillingItem from "./MembershipBillingItem";
import {
  AccountData,
  Follower,
  FollowerMembershipState,
  FollowerSubscription,
} from "@markit/common.types";
import useAsyncOnMount from "../../../hooks/useAsyncEffectOnMount";
import { getMostRecentFollowerSubscription } from "../../../utils/followerSubscriptionUtils";
import { MixpanelContext } from "../../../context/AnalyticsService";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { useSelector } from "react-redux";

export type MembershipFailedItem = {
  follower: Follower;
  creatorData: AccountData;
};

type MembershipPaymentFailedButtonModalProps = {
  setIsVisible: (isVisible: boolean) => void;
  followerDataMap: Map<string, Follower>;
  followingAccountDataFollowing: AccountData[];
};

const MembershipPaymentFailedButtonModal = (
  props: MembershipPaymentFailedButtonModalProps
) => {
  const { setIsVisible, followerDataMap, followingAccountDataFollowing } =
    props;
  const { uid } = useSelector(getAccountState).account.accountData;
  const mixpanel = useContext(MixpanelContext);
  const [latestFollowerSubscriptions, setLatestFollowerSubscriptions] =
    useState<FollowerSubscription[]>([]);

  useAsyncOnMount(async () => {
    if (mixpanel) {
      mixpanel.track("Show Membership Update Payment Info Prompt", {
        distinct_id: uid,
      });
    }

    const paymentFailedSubscriptions = await Promise.all(
      Array.from(followerDataMap.entries()).map(async ([uid, follower]) =>
        follower.membershipState === FollowerMembershipState.PAYMENT_FAILED
          ? await getMostRecentFollowerSubscription(uid, follower.uid)
          : undefined
      )
    );
    const definedFollowerSubscriptions = filterUndefinedValues(
      paymentFailedSubscriptions
    );
    setLatestFollowerSubscriptions(definedFollowerSubscriptions);
  });

  useEffect(() => {
    // Close modal if there are no failed payment follower subscriptions anymore
    const hasFailedPayments = Array.from(followerDataMap.values()).some(
      (follower) =>
        follower.membershipState === FollowerMembershipState.PAYMENT_FAILED
    );
    if (!hasFailedPayments) {
      setIsVisible(false);
    }
  }, [followerDataMap, setIsVisible]);

  const renderMembershipFailedItem = useCallback(
    (subscription: FollowerSubscription) => {
      const creatorData = followingAccountDataFollowing.find(
        (followingAccount) =>
          followingAccount.membership.membershipPlans.some(
            (plan) => plan.id === subscription.membershipPlanId
          )
      );
      const follower = creatorData
        ? followerDataMap.get(creatorData.uid)
        : undefined;
      return (
        <MembershipBillingItem
          key={subscription.id}
          membershipPlans={
            creatorData ? creatorData.membership.membershipPlans : []
          }
          follower={follower}
          subscription={subscription}
          isUpcoming={true}
          creatorFullName={creatorData?.fullName}
        />
      );
    },
    [followerDataMap, followingAccountDataFollowing]
  );

  return (
    <PopupModalContainer
      headerComp={
        <div className="ColumnNormal" style={{ gap: 7 }}>
          <span className="sectionTitle">Payment Failed</span>
          <span className="smallBodySubtext" style={{ fontWeight: 400 }}>
            The following subscriptions and their benefits are on hold until you
            pay your latest invoice.
          </span>
        </div>
      }
      valueComp={
        <div className="ColumnNormal" style={{ gap: 14 }}>
          {latestFollowerSubscriptions.map((subscription) =>
            renderMembershipFailedItem(subscription)
          )}
        </div>
      }
      noExit
      closeModal={() => setIsVisible(false)}
      closeOnOutsidePress
      modalContentWidth={PopupModalContainerWidth.SMALL}
    />
  );
};

export default MembershipPaymentFailedButtonModal;
