import {
  closestPluginWordMatch,
  compareTwoStringsInsensitive,
  findCharNewKeyPressed,
  findNearestSlashIndex,
  isMarkitLink,
  splitPluginTextInputMessage,
} from "@markit/common.utils";
import { Colors } from "../../../../utils/colors";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import {
  ShortenedPluginKeywords,
  TextPluginOption,
  TextPluginOptions,
} from "@markit/common.types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TextareaAutosize } from "@mui/material";
import getCaretCoordinates from "textarea-caret";
import { useOnMount } from "../../../../hooks/useOnMount";
import { Icon } from "@iconify/react";
import { useTheme } from "../../../../hooks/useTheme";

export enum PluginTextVisibility {
  ALL = "all",
  NO_EVENTS = "no events",
  NONE = "none",
}

type InsertPluginTextInputProps = {
  message: string;
  setMessage: (message: string) => void;
  placeholder: string;
  cursorPosition: number;
  setCursorPosition: (cursorPosition: number) => void;
  pluginWords: Set<string>;
  image: string;
  removeImage: () => void;
  pluginVisibility: PluginTextVisibility;
  fixedBottomText?: JSX.Element | undefined;
  isMassText?: boolean;
};

export const InsertPluginTextInput = (props: InsertPluginTextInputProps) => {
  const {
    message,
    setMessage,
    placeholder,
    cursorPosition,
    setCursorPosition,
    pluginWords,
    image,
    removeImage,
    pluginVisibility,
    fixedBottomText,
    isMassText,
  } = props;
  const { theme } = useTheme();
  const [showPluginPopup, setShowPluginPopup] = useState(false);
  const [searchedPluginWord, setSearchedPluginWord] = useState("");
  const [hoverIndex, setHoverIndex] = useState(-1);
  const caretPosition = useRef({ top: 0, left: 0, height: 0 });

  const [aspectRatio, setAspectRatio] = useState(1);

  useEffect(() => {
    if (image) {
      const img = new Image();
      img.onload = () => {
        setAspectRatio(img.width / img.height);
      };
      img.src = image;
    }
  }, [image]);

  useOnMount(() => {
    const textarea = document.querySelector("textarea");
    if (textarea) {
      const handleInput = () => {
        const position = getCaretCoordinates(textarea, textarea.selectionEnd);
        caretPosition.current = position;
      };
      textarea.addEventListener("input", handleInput);
      return () => {
        textarea.removeEventListener("input", handleInput);
      };
    }
  });

  const styles = {
    coverImage: {
      marginTop: 24,
      marginBottom: 14,
      width: 260,
      borderRadius: 12,
      resizeMode: "cover",
    },

    coverImageIcon: {
      backgroundColor: theme.ButtonSecondaryBG.backgroundColor,
      width: 28,
      height: 28,
      borderRadius: 14,
      cursor: "pointer",
    },

    textInputText: { fontSize: 14, fontWeight: "400", letterSpacing: "normal" },
    textInputShared: {
      fontSize: "14px",
      lineHeight: "1.25",
      fontFamily: "Arial, sans-serif",
      padding: 0,
      margin: 0,
      whiteSpace: "pre-wrap",
      letterSpacing: "normal",
      width: "100%",
      paddingTop: isMassText ? 10 : 0,
    },
    pluginSubtext: {
      color: Colors.GRAY2,
      fontWeight: "500",
      fontSize: 12,
    },
  };

  const textPluginOptionsToShow = useMemo(() => {
    const visibleTextPluginOptions =
      pluginVisibility === PluginTextVisibility.ALL
        ? TextPluginOptions
        : pluginVisibility === PluginTextVisibility.NO_EVENTS
        ? TextPluginOptions.filter((plugin) => !plugin.name.includes("/Event"))
        : [];
    if (searchedPluginWord !== "") {
      const filteredTextPlugins = visibleTextPluginOptions.filter((plugin) =>
        plugin.name.toLowerCase().includes(searchedPluginWord.toLowerCase())
      );
      return filteredTextPlugins.slice(0, 3);
    }
    return visibleTextPluginOptions.slice(0, 3);
  }, [pluginVisibility, searchedPluginWord]);

  const showTextPluginPopup = useMemo(
    () => showPluginPopup && textPluginOptionsToShow.length > 0,
    [showPluginPopup, textPluginOptionsToShow.length]
  );

  // Checks to see if the cursor is currently positioned within the substring in focus and if that substring is a valid plugin starter
  // Needed for checking if the pluginPopup should be visible when typing plugins with multiple words
  const cursorWithinFocusedPlugin = useCallback(
    (substring: string, newMessage: string) => {
      const startsWithSlash = /\//g;
      const matches = newMessage.match(startsWithSlash);
      if (matches) {
        let index = 0;
        for (let i = 0; i < matches.length; i++) {
          const foundSlashIndex = newMessage.indexOf(substring, index);
          if (foundSlashIndex !== -1) {
            const endIndex =
              foundSlashIndex +
              substring.length +
              (ShortenedPluginKeywords.some((word) =>
                substring.includes(word.slice(1).toLowerCase().trim())
              )
                ? 1
                : 0);
            if (
              cursorPosition >= foundSlashIndex &&
              cursorPosition <= endIndex
            ) {
              return true;
            } else {
              index = foundSlashIndex + 1;
            }
          } else {
            index = foundSlashIndex + 1;
          }
        }
      }
      return false;
    },
    [cursorPosition]
  );

  // Checks to see if the substring in the message matches the format of any plugin word
  const doesSubstringMatchPlugin = useCallback(
    (substring: string, newMessage: string) => {
      if (!substring.startsWith("/")) {
        return false;
      }
      if (!cursorWithinFocusedPlugin(substring, newMessage)) {
        return false;
      }

      // Find plugin that matches the substring based on letter matching
      const foundPluginWord = closestPluginWordMatch(substring);
      if (foundPluginWord === "") {
        return false;
      }
      let substringIndex = 0;
      for (const char of substring) {
        if (
          compareTwoStringsInsensitive(char, foundPluginWord[substringIndex])
        ) {
          substringIndex++;
        } else {
          substringIndex = 0; // Reset the index if characters don't match
        }

        if (substringIndex === foundPluginWord.length) {
          break;
        }
      }
      if (substringIndex === substring.length) {
        setSearchedPluginWord(substring);
        return true;
      }
      return false;
    },
    [cursorWithinFocusedPlugin]
  );

  // Called within onKeyPress to determine whether to show or hide the plugin popup modal
  const handleChangedText = useCallback(
    (inputText: string) => {
      setMessage(inputText);
      const words = splitPluginTextInputMessage(inputText);

      if (words.length === 0 || !words.some((word) => word.includes("/"))) {
        setShowPluginPopup(false);
      }

      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        if (word.startsWith("/")) {
          const foundPluginWord = closestPluginWordMatch(word);
          if (
            compareTwoStringsInsensitive(word, foundPluginWord) &&
            foundPluginWord !== ""
          ) {
            // if the word becomes the same as the plugin name, dismiss popup
            const replaced = inputText.replace(word, foundPluginWord);
            setMessage(replaced);
            setShowPluginPopup(false);
          } else if (doesSubstringMatchPlugin(word.trim(), inputText)) {
            // if any word starts with a forward slash and matches part of plugin, show popup
            setShowPluginPopup(true);
            return;
          }
        } else {
          setSearchedPluginWord("");
          setShowPluginPopup(false);
        }
      }
    },
    [doesSubstringMatchPlugin, setMessage]
  );

  // Called within onKeyPress to determine whether to show or hide the plugin popup modal
  const addPluginWordOnPress = useCallback(
    (pluginOption: TextPluginOption) => {
      const startsWithSlash = /\//g;
      const matches = message.match(startsWithSlash);
      if (matches) {
        let index = 0;
        for (let i = 0; i < matches.length; i++) {
          const foundWord = matches[i];
          const foundSlashIndex = message.indexOf(foundWord, index);
          if (foundSlashIndex !== -1) {
            const nearestSlashIndex = findNearestSlashIndex(
              message,
              cursorPosition
            );
            if (foundSlashIndex === nearestSlashIndex) {
              const slashWord = message.substring(
                foundSlashIndex,
                cursorPosition
              );
              const newMessage =
                message.substring(0, foundSlashIndex) +
                pluginOption.name +
                " " +
                message.substring(foundSlashIndex + slashWord.length);
              setMessage(newMessage);
              setCursorPosition(cursorPosition + pluginOption.name.length);
            } else {
              index = foundSlashIndex + 1;
            }
          }
        }
      }
      setShowPluginPopup(false);
    },
    [cursorPosition, message, setCursorPosition, setMessage]
  );

  // Called on every single key press that the user inputs and determines what to do with the key
  const handleKeyPress = useCallback(
    (newMassTextMessage: string) => {
      const key = findCharNewKeyPressed(newMassTextMessage, message);
      if (key === "Backspace") {
        // check if backspace is at the end of a plugin word
        const pluginWordsArray = Array.from(pluginWords);
        for (const pluginWord of pluginWordsArray) {
          if (
            message.substring(
              cursorPosition - pluginWord.length,
              cursorPosition
            ) === pluginWord
          ) {
            const updatedMassTextMessage =
              newMassTextMessage.substring(
                0,
                cursorPosition - pluginWord.length
              ) + newMassTextMessage.substring(cursorPosition);
            setMessage(updatedMassTextMessage);
            return;
          }
        }

        handleChangedText(newMassTextMessage);
      } else if (key === "Enter" && showTextPluginPopup) {
        addPluginWordOnPress(textPluginOptionsToShow[0]);
      } else {
        handleChangedText(newMassTextMessage);
      }
    },
    [
      addPluginWordOnPress,
      cursorPosition,
      handleChangedText,
      message,
      pluginWords,
      setMessage,
      showTextPluginPopup,
      textPluginOptionsToShow,
    ]
  );

  return (
    <div className="ColumnNormal">
      <div
        className="ColumnNormal"
        style={{ position: "relative", width: "100%" }}
      >
        <TextareaAutosize
          id="masstextmessage"
          key="masstextstextarea"
          minRows={image || fixedBottomText ? 2 : 10}
          value={message}
          placeholder={placeholder}
          onChange={(e) => {
            const newCursorPosition = e.target.selectionEnd;
            if (cursorPosition !== newCursorPosition) {
              setCursorPosition(newCursorPosition);
            }
            handleKeyPress(e.target.value);
          }}
          className="MassTextsTextArea"
          style={{
            zIndex: 1,
            color: Colors.TRANSPARENT,
            border: "none",
            overflow: "hidden",
            outline: "none",
            resize: "none",
            height: "auto",

            ...styles.textInputShared,
          }}
          maxLength={9999}
        />
        <div
          style={{
            zIndex: 0,
            position: "absolute",
            top: 0,
            left: 0,
            ...styles.textInputShared,
          }}
        >
          {splitPluginTextInputMessage(message).map((word, index) => {
            const pluginWord = pluginWords.has(word.trim());
            const isValidLink =
              word.trim().startsWith("https://") || isMarkitLink(word.trim());
            return (
              <span
                key={index}
                style={{
                  color:
                    pluginWord || isValidLink ? Colors.GREEN2 : Colors.BLACK,
                }}
              >
                {word}
              </span>
            );
          })}
        </div>
        {showTextPluginPopup ? (
          <AnimatePresence>
            <LazyMotion features={domAnimation}>
              <m.div
                className="ColumnNormal HideScrollbar"
                style={{
                  zIndex: 99,
                  position: "absolute",
                  backgroundColor: Colors.WHITE,
                  boxShadow: "0px 4px 24px 5px #1E1E371A",
                  padding: 7,
                  borderRadius: 8,
                  width: 280,
                  left: 50,
                  top: 0,
                  marginTop: caretPosition.current.top + 20,
                }}
                initial={{ top: "0px", opacity: 0 }}
                animate={{ top: "10px", opacity: 1 }}
                exit={{ top: "0px", opacity: 0 }}
                transition={{ duration: 0.3, type: "tween" }}
              >
                <span
                  style={{
                    ...styles.pluginSubtext,
                    paddingInline: 7,
                    paddingBottom: 7,
                  }}
                >
                  Insert Plugin
                </span>
                {textPluginOptionsToShow.map((pluginOption, index) => (
                  <div
                    key={index}
                    className="ColumnNormalSelect"
                    style={{
                      padding: 7,
                      borderRadius: 8,
                      backgroundColor:
                        index === hoverIndex ? Colors.GRAY6 : Colors.WHITE,
                    }}
                    onMouseEnter={() => setHoverIndex(index)}
                    onClick={() => addPluginWordOnPress(pluginOption)}
                  >
                    <span style={styles.textInputText}>
                      {pluginOption.name}
                    </span>
                    <span
                      style={{
                        ...styles.pluginSubtext,
                        marginTop: 3,
                      }}
                    >
                      {pluginOption.description}
                    </span>
                  </div>
                ))}
              </m.div>
            </LazyMotion>
          </AnimatePresence>
        ) : null}
      </div>
      {fixedBottomText ? (
        <div
          style={{
            paddingTop: 24,
            paddingBottom: image ? 24 : 120,
          }}
        >
          {fixedBottomText}
        </div>
      ) : null}
      {image ? (
        <div style={{ position: "relative", paddingBottom: 120 }}>
          <img
            src={image}
            alt={"text media"}
            style={{
              ...styles.coverImage,
              ...{ aspectRatio: aspectRatio },
            }}
          />
          <div
            className="Centering"
            style={{
              ...styles.coverImageIcon,
              ...{ position: "absolute", top: 34, left: 220 },
            }}
            onClick={removeImage}
          >
            <Icon icon={"ion:close-outline"} height={24} color={Colors.WHITE} />
          </div>
        </div>
      ) : null}
    </div>
  );
};
