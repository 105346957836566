import "../../css/ProfilePage/OrganizersPage.css";
import "../../css/FullEvent/FullEvent.css";
import React, { memo, useCallback, useState } from "react";
import { AccountData, FollowType } from "@markit/common.types";
import Box from "@mui/material/Box";
import mixpanel from "mixpanel-browser";
import FullEventCardSummary from "../../components/FullEvent/FullEventCardSummary";
import OrganizerItem from "../../components/OrganizerItem";
import { followUser } from "../../utils/FollowUser";
import { isDesktop } from "react-device-detect";
import Banner, { BannerType } from "../../components/Banner";
import {
  useEventSelector,
  useParametricSelector,
} from "../../hooks/useParametricSelector";
import {
  getLoadedEvent,
  getLoadedUser,
  getLoadedUsers,
} from "../../redux/slices/dataSlice";
import LoadingScreen from "../LoadingScreen";
import { useSelector } from "react-redux";
import { getUrlParamState } from "../../redux/slices/urlParamSlice";
import Footer from "../../components/Footer";
import { getUserFollowingsSnap } from "../../utils/FirebaseUtils";
import { useNavigate } from "../../hooks/useNavigate";
import useAsyncOnMount from "../../hooks/useAsyncEffectOnMount";

type OrganizersPageProps = {};

const OrganizersPage = memo(function OrganizersPageFn(
  props: OrganizersPageProps
) {
  const { i: userId } = useSelector(getUrlParamState);
  const eventId = useEventSelector();
  const navigate = useNavigate();
  const [userFollowing, setUserFollowing] = useState<string[]>([]);
  const {
    isLoading: isLoadingEventData,
    data: event,
    isError: isErrorEventData,
  } = useParametricSelector(getLoadedEvent, eventId);
  const {
    isLoading: isLoadingUserData,
    data: userData,
    isError: isErrorUserData,
  } = useParametricSelector(getLoadedUser, userId);
  const {
    isLoading: isLoadingHostData,
    data: host,
    isError: isErrorHostData,
  } = useParametricSelector(getLoadedUser, event?.createdBy ?? "");

  const cohostsData: AccountData[] = useParametricSelector(
    getLoadedUsers,
    event?.cohosts ?? []
  )
    .map((user) => user?.data)
    .filter((user) => user !== undefined) as AccountData[];

  const performersData: AccountData[] = useParametricSelector(
    getLoadedUsers,
    event?.performers ?? []
  )
    .map((user) => user?.data)
    .filter((user) => user !== undefined) as AccountData[];

  useAsyncOnMount(async () => {
    if (mixpanel) {
      mixpanel.track("Webapp: Opened Follow Organizer Link", {
        distinct_id: userId,
        event_id: eventId,
        total_performers: performersData.length,
        total_cohosts: cohostsData.length,
      });
    }
    const followingsSnap = await getUserFollowingsSnap(userId);
    const following = await Promise.all(
      followingsSnap.docs.map((doc) => {
        return doc.data().uid;
      })
    );
    setUserFollowing(following);
  });

  const alreadyFollowing = useCallback(
    (organizerId: string) => {
      if (userData) {
        return (
          userFollowing.includes(organizerId) || userData.uid === organizerId
        );
      }
      return false;
    },
    [userData, userFollowing]
  );

  const followBtnPressed = useCallback(
    async (item: AccountData) => {
      if (userData) {
        await followUser({
          userPhone: userData.phoneNumber,
          userFullName: userData.fullName,
          userToFollow: item,
          uid: "",
          followType: FollowType.PROFILE,
          answers: undefined,
        });
        mixpanel.track("Webapp: Follow Selected Organizer Account", {
          distinct_id: userData.uid,
          eventId: eventId,
          organizer: item.uid,
        });
      }
    },
    [eventId, userData]
  );

  const onViewEventClick = useCallback(() => {
    navigate(`/e/${eventId}`);
  }, [eventId, navigate]);

  const onProfileClick = useCallback(
    (organizer: string) => {
      navigate(`/u/${organizer}`);
    },
    [navigate]
  );

  if (isLoadingEventData || isLoadingUserData || isLoadingHostData) {
    return <LoadingScreen />;
  }
  if (
    isErrorUserData ||
    isErrorEventData ||
    isErrorHostData ||
    event == null ||
    userData == null ||
    host == null
  ) {
    console.log(userData);
    return <LoadingScreen error />;
  }
  if (userData) {
    return (
      <div className="OrganizersContainer">
        <div style={isDesktop ? { maxWidth: "550px" } : {}}>
          <div className="OrganizersFinalHeader" style={{ paddingTop: 20 }}>
            <span className="FinalTitle">Follow Others</span>
            <div onClick={onViewEventClick}>
              <span
                style={{
                  color: "#FE296C",
                  fontSize: 14,
                  fontWeight: "600",
                }}
              >
                View Event
              </span>
            </div>
          </div>
          <hr style={{ width: "100%" }} />
          <FullEventCardSummary event={event} host={host} />
          <hr style={{ width: "100%" }} />
          <div>
            {cohostsData.length > 0 || event.createdBy === userData.uid ? (
              <Box
                component="form"
                sx={{
                  marginTop: 2,
                  paddingX: 2,
                  paddingY: 2,
                  backgroundColor: "#F4F5F6",
                  borderRadius: 3,
                  alignItems: "center",
                  alignSelf: "center",
                }}
                noValidate
              >
                <span className="OrganizersTitle">Follow Co-Hosts</span>
                <div style={{ paddingTop: 10 }}>
                  {cohostsData.length > 0 ? (
                    cohostsData.map((cohost, index) => (
                      <div key={cohost.uid}>
                        <OrganizerItem
                          user={cohost}
                          following={alreadyFollowing(cohost.uid)}
                          onProfileClick={(organizer: string) =>
                            onProfileClick(organizer)
                          }
                          selectOrganizerItem={() => followBtnPressed(cohost)}
                        />
                        {index !== cohostsData.length - 1 ? (
                          <hr style={{ width: "100%" }} />
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <span className="OrganizersEmptyStateText">
                      When you add co-hosts, they will show up here
                    </span>
                  )}
                </div>
              </Box>
            ) : null}
            {performersData.length > 0 || event.createdBy === userData.uid ? (
              <Box
                component="form"
                sx={{
                  marginTop: 2,
                  paddingX: 2,
                  paddingY: 2,
                  backgroundColor: "#F4F5F6",
                  borderRadius: 3,
                  alignItems: "center",
                  alignSelf: "center",
                }}
                noValidate
              >
                <span className="OrganizersTitle">Follow Performers</span>
                <div style={{ paddingTop: 10 }}>
                  {performersData.length > 0 ? (
                    performersData.map((performer, index) => (
                      <div key={performer.uid}>
                        <OrganizerItem
                          user={performer}
                          following={alreadyFollowing(performer.uid)}
                          onProfileClick={(organizer: string) =>
                            onProfileClick(organizer)
                          }
                          selectOrganizerItem={() =>
                            followBtnPressed(performer)
                          }
                        />
                        {index !== performersData.length - 1 ? (
                          <hr style={{ width: "100%" }} />
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <span className="OrganizersEmptyStateText">
                      When you add performers, they will show up here
                    </span>
                  )}
                </div>
              </Box>
            ) : null}
            <div style={{ marginTop: 15, marginBottom: 15 }}>
              {host.customer.phone !== "" ? (
                <Banner
                  type={BannerType.TEXTHOST}
                  hostPhone={host.customer.phone}
                />
              ) : (
                <Banner type={BannerType.TEXTHOST_FREE} />
              )}
              <Footer showRCA host={host} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
});

export default OrganizersPage;
