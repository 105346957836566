import {
  AccountData,
  FormInputType,
  SavedFormQuestion,
  SavedFormResponse,
} from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../../redux/slices/accountSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import filter from "lodash.filter";
import { Colors } from "../../../../../utils/colors";
import FlatList from "flatlist-react/lib";
import { CircularProgress } from "@mui/material";
import {
  addOrFilterRecipients,
  formQuestionDisplayName,
  textableContactString,
} from "@markit/common.utils";
import SearchBoxContainer from "../../../../Containers/SearchBoxContainer";
import { MassTextsActionItem } from "../../Items/MassTextsActionItem";
import { Icon } from "@iconify/react";
import CustomLinkify from "../../../../Links/CustomLinkify";
import CustomCheckbox from "../../../../CustomCheckbox";
import { API } from "../../../../../API";
import { SelectRecipientsSharedProps } from "../SelectRecipientsScreens";
import { SelectRecipientItem } from "../../../../DisplayItem/SelectRecipientItem";
import { EmptyStateFlatlist } from "../../../../EmptyStates/EmptyStateFlatlist";
import EmptyStateButton from "../../../../Buttons/EmptyStateButton";
import { SelectRecipientsDataItemResponses } from "./SelectRecipientsDataItemResponses";
import useAsyncEffect from "../../../../../hooks/useAsyncEffect";
import { useLoadUserList } from "../../../../../hooks/useLoadUserList";

type SelectRecipientsCollectedDataProps = SelectRecipientsSharedProps & {
  selectedQuestion: SavedFormQuestion;
};

const SelectRecipientsCollectedData = (
  props: SelectRecipientsCollectedDataProps
) => {
  const {
    unsavedRecipients,
    setUnsavedRecipients,
    excludingMode,
    setProfileSelected,
    selectedQuestion,
    showCategories,
  } = props;
  const { accountData } = useSelector(getAccountState).account;
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFormOption, setSelectedFormOption] = useState("");
  const [formQuestionResponses, setFormQuestionResponses] = useState<
    Map<string, SavedFormResponse>
  >(new Map<string, SavedFormResponse>());

  const formQuestionResponsesArr = useMemo(
    () =>
      formQuestionResponses ? Array.from(formQuestionResponses.keys()) : [],
    [formQuestionResponses]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (selectedQuestion) {
      setLoading(true);
      // this fetches the form responses of only attendees that are subscribed still
      const { formQuestionResponses } =
        await API.profile.fetchFollowersQuestionResponses({
          uid: accountData.uid,
          formQuestionId: selectedQuestion.id,
        });
      const formResponsesMap = new Map(formQuestionResponses);
      setFormQuestionResponses(formResponsesMap);
      setLoading(false);
    }
  }, [accountData.uid, selectedQuestion, setUnsavedRecipients, showCategories]);

  const {
    fetchedUserData,
    loadMoreUsers,
    isFinished,
    isLoading,
    loadSearchResultsThrottled,
  } = useLoadUserList({
    userIdList: formQuestionResponsesArr,
    windowSize: 30,
  });

  useEffect(() => {
    if (!loading) {
      loadMoreUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const containsUser = useCallback((userData: AccountData, query: string) => {
    return userData.fullName.toLowerCase().includes(query.toLowerCase());
  }, []);

  const containsOption = useCallback((option: string, query: string) => {
    return option.toLowerCase().includes(query.toLowerCase());
  }, []);

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
    loadSearchResultsThrottled(text.toLowerCase());
  };

  // The visible selected form question options to show (for checkbox and multiple choice case)
  const formQuestionOptionsToShow: string[] = useMemo(() => {
    let searchResults: any[] = [];
    if (
      selectedQuestion &&
      (selectedQuestion.formInput.inputType === FormInputType.CHECK_BOX ||
        selectedQuestion.formInput.inputType === FormInputType.RADIO)
    ) {
      if (searchTerm !== "") {
        searchResults = filter(
          selectedQuestion.formInput.options,
          (option: string) => {
            return containsOption(option, searchTerm);
          }
        );
      } else {
        searchResults = selectedQuestion.formInput.options;
      }
    }
    return searchResults;
  }, [containsOption, searchTerm, selectedQuestion]);

  // The visible selected form question people to show (for non checkbox and multiple choice case)
  const formQuestionItemsToShow: AccountData[] = useMemo(() => {
    let searchResults = fetchedUserData;
    if (selectedQuestion) {
      if (searchTerm !== "") {
        searchResults = filter(searchResults, (user: AccountData) =>
          containsUser(user, searchTerm.toLowerCase())
        );
      }
    }
    return searchResults;
  }, [containsUser, fetchedUserData, searchTerm, selectedQuestion]);

  const iconColor = useMemo(
    () => (excludingMode ? Colors.RED3 : Colors.BLUE5),
    [excludingMode]
  );

  const selectRecipientOnPress = useCallback(
    (item: string) => {
      if (unsavedRecipients.includes(item)) {
        const filteredSelected = unsavedRecipients.filter(
          (user) => user !== item
        );
        setUnsavedRecipients(filteredSelected);
      } else {
        const newSelected = unsavedRecipients.concat(item);
        setUnsavedRecipients(newSelected);
      }
    },
    [unsavedRecipients, setUnsavedRecipients]
  );

  const isAllSelected = useCallback(
    (totalList: string[], subList: string[]) =>
      subList.length > 0
        ? totalList.every((sel) => subList.includes(sel))
        : false,
    []
  );

  const renderCheckbox = useCallback(
    (isChecked: boolean, onPress: () => void) => (
      <CustomCheckbox
        checked={isChecked}
        onChange={onPress}
        sx={{ padding: 0 }}
        altColor={iconColor}
      />
    ),
    [iconColor]
  );

  const renderEmptyView = useMemo(
    () => (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoading}
        containerStyles={{ paddingTop: 120 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title={"No Responses Collected"}
            icon={<Icon icon={"ion:person"} height={60} />}
            iconBox={70}
            containerStyles={{ paddingTop: 120 }}
          />
        }
      />
    ),
    [isLoading, searchTerm]
  );

  return (
    <>
      <div
        style={{
          backgroundColor: Colors.GRAY6,
          padding: 10,
          borderRadius: 12,
          gap: 5,
        }}
        className="ColumnNormal"
      >
        <span style={{ fontSize: 14, fontWeight: "500" }}>
          {selectedQuestion.formInput.label}
        </span>
        <span style={{ color: Colors.GRAY1, fontSize: 12 }}>
          {formQuestionDisplayName(selectedQuestion.formInput)}
        </span>
      </div>
      <SearchBoxContainer
        value={searchTerm}
        placeholder={"Search"}
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
        containerStyles={{ marginBottom: 10 }}
      />
      <div className="HideScrollbar AllowScroll" style={{ paddingBottom: 200 }}>
        {loading ? (
          <div className="Centering" style={{ paddingTop: 250 }}>
            <CircularProgress style={{ color: "#929292" }} size={20} />
          </div>
        ) : (
          <>
            {formQuestionResponsesArr.length > 0 && searchTerm === "" ? (
              <>
                <MassTextsActionItem
                  title={"Select All"}
                  icon={
                    <Icon icon="ion:person-add" height={29} color={iconColor} />
                  }
                  onPress={() =>
                    addOrFilterRecipients(
                      unsavedRecipients,
                      setUnsavedRecipients,
                      formQuestionResponsesArr
                    )
                  }
                  subtext={textableContactString(
                    formQuestionResponsesArr.length
                  )}
                  isCheckSelected={isAllSelected(
                    formQuestionResponsesArr,
                    unsavedRecipients
                  )}
                  checkColor={iconColor}
                />
                <hr />
              </>
            ) : null}
            {selectedQuestion.formInput.inputType === FormInputType.CHECK_BOX ||
            selectedQuestion.formInput.inputType === FormInputType.RADIO ? (
              <FlatList
                list={formQuestionOptionsToShow}
                renderWhenEmpty={() => renderEmptyView}
                renderItem={(item) => {
                  const subItemsToShow: Map<string, string> = new Map<
                    string,
                    string
                  >();
                  formQuestionResponses.forEach((values, key) => {
                    const foundValue = values.responses.find(
                      (value) => value === item
                    );
                    if (foundValue) {
                      subItemsToShow.set(key, foundValue);
                    }
                  });
                  return (
                    <div>
                      <div className="AlignedRowSpaced" style={{ gap: 5 }}>
                        <div
                          onClick={() =>
                            setSelectedFormOption(
                              selectedFormOption !== item ? item : ""
                            )
                          }
                          className="AlignedRowSpacedSelect"
                          style={{
                            flexGrow: 1,
                            pointerEvents:
                              subItemsToShow.size > 0 ? "all" : "none",
                          }}
                        >
                          <div
                            className="ColumnNormalSelect"
                            style={{ gap: 3 }}
                          >
                            <span style={{ fontSize: 14 }}>{item}</span>
                            <span style={{ fontSize: 12 }}>
                              {textableContactString(subItemsToShow.size)}
                            </span>
                          </div>
                          {subItemsToShow.size > 0 ? (
                            <Icon
                              icon={
                                selectedFormOption === item
                                  ? "mdi-chevron-up"
                                  : "mdi:chevron-down"
                              }
                              height={22}
                              color={Colors.GRAY1}
                            />
                          ) : null}
                        </div>
                        {subItemsToShow.size > 0
                          ? renderCheckbox(
                              isAllSelected(
                                Array.from(subItemsToShow.keys()),
                                unsavedRecipients
                              ),
                              () =>
                                addOrFilterRecipients(
                                  unsavedRecipients,
                                  setUnsavedRecipients,
                                  Array.from(subItemsToShow.keys())
                                )
                            )
                          : null}
                      </div>
                      <hr />
                      {selectedFormOption === item ? (
                        <SelectRecipientsDataItemResponses
                          responseUserIds={Array.from(subItemsToShow.keys())}
                          unsavedRecipients={unsavedRecipients}
                          setUnsavedRecipients={setUnsavedRecipients}
                          setProfileSelected={setProfileSelected}
                          excludingMode={excludingMode}
                        />
                      ) : null}
                    </div>
                  );
                }}
              />
            ) : (
              <FlatList
                list={formQuestionItemsToShow}
                renderItem={(item: AccountData) => {
                  const responseFound = formQuestionResponses.get(item.uid);
                  const response = responseFound
                    ? responseFound.responses.every(
                        (response) => response === ""
                      )
                      ? ["No Response Collected"]
                      : responseFound.responses
                    : ["No Response Collected"];

                  return (
                    <div className="ColumnNormal">
                      <SelectRecipientItem
                        item={item}
                        selectPreviewOnPress={() => setProfileSelected(item)}
                        subtext={
                          <CustomLinkify>
                            <span
                              className="TextOverflowEllipsis"
                              style={{ fontSize: 12, width: "360px" }}
                            >
                              {response.join(", ")}
                            </span>
                          </CustomLinkify>
                        }
                        isSelected={unsavedRecipients.includes(item.uid)}
                        selectOnPress={() => selectRecipientOnPress(item.uid)}
                        iconColor={iconColor}
                      />
                    </div>
                  );
                }}
                renderWhenEmpty={() => renderEmptyView}
                hasMoreItems={!isFinished}
                loadMoreItems={() => {
                  loadMoreUsers();
                }}
                paginationLoadingIndicator={() =>
                  isLoading && !isFinished ? (
                    <CircularProgress
                      style={{ color: "#929292", alignSelf: "center" }}
                      size={20}
                    />
                  ) : null
                }
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SelectRecipientsCollectedData;
