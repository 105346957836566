import { useCallback, useEffect, useMemo, useState } from "react";
import { Colors } from "../../../utils/colors";
import {
  AccountData,
  Conversation,
  FollowerStatus,
} from "@markit/common.types";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import ConversationsSidebarPreview from "../../../components/CreatorDashboard/Conversations/ConversationsSidebarPreview";
import ConversationsMainView from "../../../components/CreatorDashboard/Conversations/ConversationsMainView";
import filter from "lodash.filter";
import { Icon } from "@iconify/react";
import {
  hasSubscription,
  isSubscriptionPaymentFailed,
} from "@markit/common.utils";
import { ConversationSidebarEmptyState } from "../../../components/CreatorDashboard/Conversations/ConversationSidebarEmptyState";
import BlurredConversationPreview from "../../../assets/FreeTierConversationBlurred.png";
import EmptyStateButton from "../../../components/Buttons/EmptyStateButton";
import SearchBoxContainer from "../../../components/Containers/SearchBoxContainer";
import { EmptyStateFlatlist } from "../../../components/EmptyStates/EmptyStateFlatlist";
import CreatorModePanelWrapper from "../../../components/CreatorDashboard/CreatorModePanelWrapper";
import MarkitPlusActionButton from "../../../components/Subscription/MarkitPlusActionButton";
import RectangleButtonCreatorPanel from "../../../components/Buttons/RectangleButtonCreatorPanel";
import { HorizontalDivider } from "../../../components/Dividers/HorizontalDivider";
import { useLoadUserFollowList } from "../../../hooks/useLoadUserFollowList";
import { useLoadUserConversationsList } from "../../../hooks/useLoadUserConversationsList";
import useAsyncOnMount from "../../../hooks/useAsyncEffectOnMount";
import { notificationActions } from "../../../redux/slices/notificationSlice";
import { Virtuoso } from "react-virtuoso";

export enum ConversationOption {
  ALL = "all",
  UNREAD = "unread",
}

const ConversationsPanel = () => {
  const { accountData } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedConversationUser, setSelectedConversationUser] =
    useState<AccountData>();
  const [newUserSearchTerm, setNewUserSearchTerm] = useState("");
  const [isLoadingConversations, setIsLoadingConversations] = useState(true);
  const [loadingInitialConversation, setLoadingInitialConversation] =
    useState(true);
  const [newConversation, setNewConversation] = useState(false);

  const {
    fetchedConversations,
    fetchedUserData,
    isLoading,
    loadSearchResultsThrottled: loadConversationResultsThrottled,
    fetchConversations,
    fetchMoreConversations,
  } = useLoadUserConversationsList({
    userId: accountData.uid,
    windowSize: 20,
  });

  const {
    fetchedUserData: fetchedFollowersUserData,
    loadSearchResultsThrottled,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: FollowerStatus.SUBSCRIBED,
    windowSize: 10,
  });

  const selectedConversation = useMemo(
    () =>
      fetchedConversations.find(
        (convo) =>
          selectedConversationUser &&
          convo.participantUids.includes(selectedConversationUser.uid)
      ),
    [selectedConversationUser, fetchedConversations]
  );

  useAsyncOnMount(async () => {
    await fetchConversations();
    setIsLoadingConversations(false);
  });

  // initialize to start with the most recent conversation selected
  useEffect(() => {
    if (fetchedConversations.length > 0 && loadingInitialConversation) {
      const conversationUserData = fetchedUserData.find(
        (follower) =>
          follower.uid === fetchedConversations[0].participantUids[0]
      );
      if (conversationUserData) {
        setSelectedConversationUser(conversationUserData);
        setLoadingInitialConversation(false);
      }
    }
  }, [fetchedUserData, loadingInitialConversation, fetchedConversations]);

  const handleSearch = (text: string) => {
    if (text !== "") {
      loadConversationResultsThrottled(text.toLowerCase());
    }
    setSearchTerm(text.toLowerCase());
  };

  const containsUser = useCallback(
    (item: Conversation, query: string) => {
      const foundUser = fetchedUserData.find(
        (user) => user.uid === item.participantUids[0]
      );
      if (foundUser) {
        return foundUser.fullName.toLowerCase().includes(query);
      }
      return false;
    },
    [fetchedUserData]
  );

  const conversationsToShow = useMemo(() => {
    const sortedConversations = fetchedConversations.sort(
      (x: Conversation, y: Conversation) => {
        return (
          new Date(y.recentTimestamp).getTime() -
          new Date(x.recentTimestamp).getTime()
        );
      }
    );
    if (searchTerm === "") {
      return sortedConversations;
    }
    let conversationList: Conversation[] = sortedConversations;
    conversationList = filter(conversationList, (convo: Conversation) => {
      return containsUser(convo, searchTerm);
    });

    return conversationList;
  }, [fetchedConversations, searchTerm, containsUser]);

  const newConversationOnPress = useCallback(() => {
    if (hasSubscription(accountData)) {
      setNewUserSearchTerm("");
      setSelectedConversationUser(undefined);
      setNewConversation(true);
    } else {
      dispatch(notificationActions.setMarkitPlusModal(true));
    }
  }, [accountData, dispatch]);

  const closeNewConversationOnPress = useCallback(() => {
    setNewUserSearchTerm("");
    setNewConversation(false);
    const conversationUserData = fetchedUserData.find(
      (follower) => follower.uid === fetchedConversations[0].participantUids[0]
    );
    setSelectedConversationUser(conversationUserData);
  }, [fetchedUserData, fetchedConversations]);

  const displayConversationsMainView = useCallback(
    async (item: Conversation) => {
      if (isSubscriptionPaymentFailed(accountData.customer.state)) {
        // setPaymentInfoVisible(true);
        // @TODO: Show modal about payment info
      } else {
        const mainUser = fetchedUserData.find(
          (user) => user.uid === item.participantUids[0]
        );

        setSelectedConversationUser(mainUser);
        setNewConversation(false);
      }
    },
    [accountData.customer.state, fetchedUserData]
  );

  const renderFreeTierMessageTitle = useMemo(() => {
    if (accountData.numFreeTierReplies > 1) {
      // 2 or more people have tried to message free tier user
      return `${accountData.numFreeTierReplies} people texted you`;
    } else if (accountData.numFreeTierReplies === 1) {
      return "Someone texted you"; // 1 person tried to message
    }
    // 0 people have tried to message free tier user
    return "When someone texts you";
  }, [accountData.numFreeTierReplies]);

  const renderEmptyFree = useMemo(() => {
    return (
      <div className="ColumnNormal">
        <div style={{ marginBottom: 4 }}>
          <ConversationSidebarEmptyState
            title={renderFreeTierMessageTitle}
            subtext={
              "You can chat with and answer people's questions by upgrading to Markit+"
            }
            onPress={() => {
              dispatch(notificationActions.setMarkitPlusModal(true));
            }}
          />
        </div>
        {accountData.numFreeTierReplies > 0 ? (
          <img
            src={BlurredConversationPreview}
            alt="Blurred conversation list preview for free tier"
            style={{ width: "fit-content", alignSelf: "center" }}
          />
        ) : null}
      </div>
    );
  }, [accountData.numFreeTierReplies, renderFreeTierMessageTitle, dispatch]);

  const renderEmptySubView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoading}
        containerStyles={{ paddingTop: 120 }}
        nonSearchEmptyView={
          !newConversation ? (
            <ConversationSidebarEmptyState
              title="No Conversations"
              subtext={"Your conversations will show up here."}
            />
          ) : (
            <></>
          )
        }
      />
    );
  }, [isLoading, newConversation, searchTerm]);

  const renderConversationItem = useCallback(
    (item: Conversation) => (
      <div className="ColumnNormal" key={item.conversationSid}>
        <ConversationsSidebarPreview
          fetchedConversationUserData={fetchedUserData}
          conversationItem={item}
          isSelectedItem={
            item.conversationSid === selectedConversation?.conversationSid
          }
          selectConversationMainView={displayConversationsMainView}
        />
        <div style={{ width: 316, alignSelf: "flex-end" }}>
          <HorizontalDivider />
        </div>
      </div>
    ),
    [
      displayConversationsMainView,
      fetchedUserData,
      selectedConversation?.conversationSid,
    ]
  );

  const EmptyPlaceholder = () => {
    return !hasSubscription(accountData) ? renderEmptyFree : renderEmptySubView;
  };

  const Footer = () => {
    return isLoading ? (
      <div className="Centering" style={{ paddingBlock: 14 }}>
        <CircularProgress style={{ color: Colors.GRAY1 }} size={24} />
      </div>
    ) : null;
  };

  return (
    <CreatorModePanelWrapper
      title="Conversations"
      headerRight={
        <div className="AlignedRow" style={{ gap: 14 }}>
          <MarkitPlusActionButton />
          <RectangleButtonCreatorPanel
            title="New Chat"
            iconName="ion:create"
            onPress={newConversationOnPress}
          />
        </div>
      }
      preventScroll
    >
      {isLoadingConversations ||
      (fetchedConversations.length > 0 && loadingInitialConversation) ? (
        <div className="Centering" style={{ height: "80vh" }}>
          <CircularProgress style={{ color: Colors.BLACK }} size={30} />
        </div>
      ) : (
        <div
          className="RowNormal"
          style={{ backgroundColor: Colors.WHITE, height: "100%" }}
        >
          {/* Conversations Sidebar */}
          <div
            className="ConversationsPanelLeftContainer ColumnNormal"
            style={{ height: "100%" }}
          >
            <div
              style={{
                padding: 14,
                paddingTop: 20,
                borderBottom: `${
                  hasSubscription(accountData) &&
                  conversationsToShow.length === 0
                    ? "0.5px"
                    : "0px"
                } solid ${Colors.GRAY11}`,
              }}
            >
              <SearchBoxContainer
                placeholder={"Search"}
                onChange={(e) => handleSearch(e.target.value)}
                containerStyles={{ marginBlock: 0 }}
              />
            </div>
            <div
              className="HideScrollbar AllowScroll"
              style={{ paddingInline: 14 }}
            >
              {!selectedConversation && newConversation ? (
                <div
                  className="AlignedRowSpaced ConversationSidebarItem"
                  style={{ backgroundColor: Colors.BLUE5, marginBottom: 4 }}
                >
                  <div className="AlignedRow" style={{ gap: 10 }}>
                    <Icon
                      icon={"mdi:person-circle-outline"}
                      height={50}
                      style={{ color: Colors.WHITE }}
                    />
                    <div style={{ height: 61 }}>
                      <span
                        className="sectionTitle"
                        style={{ color: Colors.WHITE }}
                      >
                        New Conversation
                      </span>
                    </div>
                  </div>
                  <Icon
                    onClick={closeNewConversationOnPress}
                    icon={"mdi:close"}
                    height={20}
                    style={{
                      color: Colors.WHITE,
                      pointerEvents:
                        fetchedConversations.length > 0 ? "all" : "none",
                    }}
                  />
                </div>
              ) : null}
              <Virtuoso
                style={{ height: "100%" }}
                data={conversationsToShow}
                endReached={() => {
                  if (searchTerm.trim() === "" && !isLoading) {
                    fetchMoreConversations();
                  }
                }}
                increaseViewportBy={200}
                itemContent={(index, item) => {
                  return renderConversationItem(item);
                }}
                components={{ Footer, EmptyPlaceholder }}
              />
            </div>
          </div>
          {/* Conversations Main View */}
          <div className="ConversationsPanelRightContainer">
            {conversationsToShow.length === 0 && !newConversation ? (
              <div className="Centering" style={{ height: "80vh" }}>
                {isLoading ? null : (
                  <EmptyStateButton
                    title={
                      hasSubscription(accountData)
                        ? fetchedConversations.length === 0
                          ? "You have no conversations yet"
                          : "No Conversations Found"
                        : "Conversations available with Markit+"
                    }
                    description="Conversations are created when your subscribers message you."
                    btnText={
                      hasSubscription(accountData) ? "New Chat" : "Get Markit+"
                    }
                    onPress={newConversationOnPress}
                    icon={
                      <Icon
                        icon="ion:chatbubbles"
                        height={50}
                        style={{ color: Colors.PURPLE6 }}
                      />
                    }
                    iconBox={70}
                    iconBackgroundColor={Colors.WHITE}
                  />
                )}
              </div>
            ) : (
              <div key={selectedConversationUser?.uid}>
                <ConversationsMainView
                  conversationUser={selectedConversationUser}
                  setConversationUser={setSelectedConversationUser}
                  fetchedConversationUserData={fetchedUserData}
                  fetchedConversations={fetchedConversations}
                  newUserSearchTerm={newUserSearchTerm}
                  setNewUserSearchTerm={setNewUserSearchTerm}
                  fetchedFollowers={fetchedFollowersUserData}
                  loadSearchResultsThrottled={loadSearchResultsThrottled}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </CreatorModePanelWrapper>
  );
};

export default ConversationsPanel;
