import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../../../../redux/slices/accountSlice";
import React, { useCallback, useMemo, useState } from "react";
import filter from "lodash.filter";
import FlatList from "flatlist-react/lib";
import SearchBoxContainer from "../../../../Containers/SearchBoxContainer";
import { GenericActionItem } from "../../../../DisplayItem/GenericActionItem";
import { GetLongDate } from "../../../../../utils/GetLongDate";
import { HorizontalDivider } from "../../../../Dividers/HorizontalDivider";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../../utils/colors";
import { getUserSpreadsheetsAndTextableContacts } from "../../../../../utils/spreadsheetUtils";
import { CircularProgress } from "@mui/material";
import { EmptyStateFlatlist } from "../../../../EmptyStates/EmptyStateFlatlist";
import EmptyStateButton from "../../../../Buttons/EmptyStateButton";
import { NavigationId } from "../../../../../navigation/AppParamList";
import { useNavigate } from "../../../../../hooks/useNavigate";
import { ImportData, SelectRecipientGroupType } from "@markit/common.types";
import {
  hasSubscriptionUnlockedAdvancedData,
  textableContactString,
} from "@markit/common.utils";
import useAsyncOnMount from "../../../../../hooks/useAsyncEffectOnMount";
import { notificationActions } from "../../../../../redux/slices/notificationSlice";

type SelectRecipientsImportLibraryProps = {
  updateSelectType: (selectType: Partial<SelectRecipientGroupType>) => void;
  displayAllFollowers?: boolean;
};

const SelectRecipientsImportLibrary = (
  props: SelectRecipientsImportLibraryProps
) => {
  const { updateSelectType, displayAllFollowers } = props;
  const { accountData } = useSelector(getAccountState).account;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingSpreadsheets, setLoadingSpreadsheets] = useState(true);
  const [importData, setImportData] = useState<ImportData[]>([]);

  useAsyncOnMount(async () => {
    const userSpreadsheets = await getUserSpreadsheetsAndTextableContacts(
      accountData.uid,
      displayAllFollowers
    );
    setImportData(userSpreadsheets);
    setLoadingSpreadsheets(false);
  });

  const newImportOnPress = useCallback(() => {
    if (!hasSubscriptionUnlockedAdvancedData(accountData)) {
      dispatch(notificationActions.setMarkitPlusModal(true));
      return;
    }
    navigate(NavigationId.IMPORT);
  }, [accountData, navigate, dispatch]);

  const containsSpreadsheet = useCallback(
    (importItem: ImportData, query: string) => {
      return importItem.spreadsheet.fileName
        .toLowerCase()
        .includes(query.toLowerCase());
    },
    []
  );

  const handleSearch = (text: string) => {
    setSearchTerm(text);
  };

  const itemsToShow: ImportData[] = useMemo(() => {
    let searchResults: ImportData[] = importData;
    if (searchTerm !== "") {
      searchResults = filter(importData, (spreadsheet: ImportData) => {
        return containsSpreadsheet(spreadsheet, searchTerm);
      });
    }
    return searchResults;
  }, [importData, searchTerm, containsSpreadsheet]);

  return (
    <>
      <span style={{ fontWeight: "500" }}>Browse Imports</span>
      <SearchBoxContainer
        value={searchTerm}
        placeholder={"Search Imports..."}
        autoCapitalize="none"
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
        containerStyles={{ marginBottom: 14 }}
      />
      <div className="AllowScroll" style={{ paddingBottom: 200 }}>
        {loadingSpreadsheets ? (
          <div className="Centering" style={{ paddingTop: 250 }}>
            <CircularProgress style={{ color: "#929292" }} size={20} />
          </div>
        ) : (
          <FlatList
            list={itemsToShow}
            renderItem={(item: ImportData) => (
              <>
                <GenericActionItem
                  leftComp={undefined}
                  textComp={
                    <div className="ColumnNormalSelect" style={{ gap: 4 }}>
                      <span className="TextOverflowEllipsis bodyMedium">
                        {item.spreadsheet.fileName}
                      </span>
                      <span className="smallBodySubtext">
                        {GetLongDate(
                          item.spreadsheet.createdAt,
                          true,
                          true,
                          true,
                          true
                        )}
                      </span>
                      <span className="smallBodySubtext">
                        {textableContactString(item.textableContacts)}
                      </span>
                    </div>
                  }
                  rightComp={
                    <Icon
                      icon="ion:chevron-right"
                      height={16}
                      color={Colors.GRAY1}
                    />
                  }
                  onPress={() => updateSelectType({ selectedItem: item })}
                />
                <HorizontalDivider altMargin={10} />
              </>
            )}
            renderWhenEmpty={() => (
              <EmptyStateFlatlist
                containerStyles={{ paddingTop: 200 }}
                searchTerm={searchTerm}
                isLoading={loadingSpreadsheets}
                nonSearchEmptyView={
                  <EmptyStateButton
                    title="Import Contacts"
                    description="Import a CSV to build your audience"
                    icon={
                      <Icon
                        icon="ion:cloud-upload"
                        height={64}
                        color={Colors.GRAY1}
                      />
                    }
                    iconBox={84}
                    btnText="New Import"
                    onPress={newImportOnPress}
                    containerStyles={{ paddingTop: 150 }}
                  />
                }
              />
            )}
          />
        )}
      </div>
    </>
  );
};

export default SelectRecipientsImportLibrary;
