import "../../css/ProfilePage/ProfilePage.css";
import { memo, useEffect, useMemo, useState } from "react";
import { AccountData, Event, Follower, Visibility } from "@markit/common.types";
import { isDesktop } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginState,
  accountActions,
  getAccountState,
} from "../../redux/slices/accountSlice";
import ProfilePageHeader from "./ProfilePageHeader";
import {
  ProfileDisplayView,
  ProfilePageTimelineHeader,
  ProfileTimelineState,
} from "./ProfilePageTimelineHeader";
import ProfilePageTimeline from "./ProfilePageTimeline";
import OrganizersInfoDisplay from "../FullEventSubComponents/OrganizersInfoDisplay";
import CreatorTextManual from "../CreatorTextManual";
import {
  datesAreOnSameDay,
  foundUserGenericLinks,
  hostedEvents,
  hostingLiveEvents,
  isEventExternalLink,
  isExternalGenericLink,
  LessThanDate,
} from "@markit/common.utils";
import { useOnMount } from "../../hooks/useOnMount";
import { getEventState } from "../../redux/slices/eventSlice";
import { useSearchParams } from "react-router-dom";
import { isProfileRCA } from "../../utils/globalUtils";
import { API } from "../../API";
import { fetchEventOrganizers } from "../../utils/eventUtils/eventUtils";
import useAsyncOnMount from "../../hooks/useAsyncEffectOnMount";
import { Colors } from "../../utils/colors";
import FlatTabs from "../Tabs/FlatTabs";
import Skeleton from "react-loading-skeleton";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import { getUserFollowerRef } from "../../utils/FirebaseUtils";
import { onSnapshot } from "../../firebase";
import ProfilePageUserSubscriptions from "./ProfilePageUserSubscriptions";

type ProfilePageFullProps = { userData: AccountData };

const ProfilePageFull = memo(function ProfilePageFullFn(
  props: ProfilePageFullProps
) {
  const { userData } = props;
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn, appInitialized } = account;
  const { events: eventList } = useSelector(getEventState);
  const { events, tickets } = eventList;
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [followerData, setFollowerData] = useState<Follower>();
  const [followingAccountDataFollowing, setFollowingAccountDataFollowing] =
    useState<AccountData[]>([]);
  const [followerDataMap, setFollowerDataMap] = useState<Map<string, Follower>>(
    new Map()
  );
  // Profile Timeline States
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingDates, setLoadingDates] = useState(true);
  const [loadingTimelineState, setLoadingTimelineState] = useState(true);
  const [loadingFollower, setLoadingFollower] = useState(true);
  const [userAttendingEvents, setUserAttendingEvents] = useState<Event[]>([]);
  const [userEvents, setUserEvents] = useState<Event[]>([]);
  const [eventOrganizers, setEventOrganizers] = useState<AccountData[]>([]);
  const [eventDates, setEventDates] = useState<string[]>([]);
  const [eventTimelineState, setEventTimelineState] =
    useState<ProfileTimelineState>(ProfileTimelineState.UPCOMING);
  const [eventDisplayView, setEventDisplayView] = useState<ProfileDisplayView>(
    ProfileDisplayView.CARD_VIEW
  );
  const [tabValue, setTabValue] = useState(0);
  const [showMoreEvents, setShowMoreEvents] = useState(false);

  useOnMount(() => {
    // If condensed is part of the url, show the grid view defaulted
    const condensedView = searchParams.get("condensed");
    if (condensedView === "true") {
      setEventDisplayView(ProfileDisplayView.GRID_VIEW);
    }
  });

  // Reset the tab value if you navigate to another profile page
  useEffect(() => {
    setTabValue(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]); // Will reset when the path changes

  const isMyAccount = useMemo(
    () =>
      loggedIn === LoginState.LOGGED_IN && accountData.uid === userData?.uid,
    [accountData.uid, loggedIn, userData?.uid]
  );

  const isUserRCA = useMemo(
    () => (userData && isProfileRCA(userData.uid)) ?? false,
    [userData]
  );

  const fullLoading = useMemo(
    () => loadingEvents || loadingDates || loadingTimelineState,
    [loadingDates, loadingEvents, loadingTimelineState]
  );

  const showAllEvents = useMemo(
    () => showMoreEvents || eventDisplayView === ProfileDisplayView.GRID_VIEW,
    [eventDisplayView, showMoreEvents]
  );

  const userLinks = useMemo(() => {
    const links = isMyAccount
      ? foundUserGenericLinks(events, accountData.uid, "active")
      : userEvents.filter((event) => isExternalGenericLink(event.eventType));
    return links;
  }, [accountData.uid, events, isMyAccount, userEvents]);

  const userEventsNoLinks = useMemo(
    () => userEvents.filter((event) => !isExternalGenericLink(event.eventType)),
    [userEvents]
  );

  const upcomingEvents = useMemo(() => {
    const hosting = isMyAccount
      ? hostingLiveEvents(events, accountData.uid, true)
      : userEventsNoLinks.filter((event) =>
          LessThanDate(new Date().toISOString(), event.end)
        );
    return hosting;
  }, [accountData.uid, events, isMyAccount, userEventsNoLinks]);

  const pastEvents = useMemo(() => {
    const hosted = isMyAccount
      ? hostedEvents(events, accountData.uid)
      : userEventsNoLinks.filter((event) =>
          LessThanDate(event.end, new Date().toISOString())
        );
    return hosted.reverse();
  }, [accountData.uid, events, isMyAccount, userEventsNoLinks]);

  const attendingEvents = useMemo(() => {
    const attending = userAttendingEvents.filter((event) =>
      LessThanDate(new Date().toISOString(), event.end)
    );
    return attending;
  }, [userAttendingEvents]);

  const attendedEvents = useMemo(() => {
    const attended = userAttendingEvents.filter((event) =>
      LessThanDate(event.end, new Date().toISOString())
    );
    return attended.reverse();
  }, [userAttendingEvents]);

  const eventsToShow = useMemo(() => {
    switch (eventTimelineState) {
      case ProfileTimelineState.UPCOMING:
        return upcomingEvents;
      case ProfileTimelineState.PAST:
        return pastEvents;
      case ProfileTimelineState.ATTENDING:
        return attendingEvents;
      case ProfileTimelineState.ATTENDED:
        return attendedEvents;
      default:
        return [];
    }
  }, [
    attendedEvents,
    attendingEvents,
    eventTimelineState,
    pastEvents,
    upcomingEvents,
  ]);

  const hasAnyEvents = useMemo(
    () =>
      upcomingEvents.length > 0 ||
      pastEvents.length > 0 ||
      attendingEvents.length > 0 ||
      attendedEvents.length > 0,
    [
      attendedEvents.length,
      attendingEvents.length,
      pastEvents.length,
      upcomingEvents.length,
    ]
  );

  const ProfileTabNames: string[] = useMemo(() => {
    const tabs = ["Links", "Contact"];
    if (hasAnyEvents) {
      tabs.unshift("Events");
    }
    if (isMyAccount && followingAccountDataFollowing.length > 0) {
      tabs.push("Subscriptions");
    }
    return tabs;
  }, [followingAccountDataFollowing.length, hasAnyEvents, isMyAccount]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (!userData || loggedIn !== LoginState.LOGGED_IN) {
      return;
    }

    const { followingAccounts } = await API.user.followingUsers({
      uid: userData.uid,
    });
    setFollowingAccountDataFollowing(followingAccounts);

    const followerRef = getUserFollowerRef(userData.uid, accountData.uid);
    const unsubscribe = onSnapshot(followerRef, async (snapshot) => {
      const followerData = snapshot.data();
      if (followerData) {
        setFollowerData(followerData);
      }
      setLoadingFollower(false);
    });
    return () => {
      unsubscribe();
    };
  }, [accountData.uid, loggedIn, userData]);

  useAsyncOnMount(async () => {
    let finalEvents: Event[] = [];
    if (loadingEvents) {
      if (isMyAccount) {
        // Return early if redux state is not loaded yet
        if (!appInitialized) {
          return;
        }
        // For my account, show all events, including attending/attended events
        // nonCreatedTickets refers to any event you aren't the explicit creator
        const nonCreatedTickets = tickets.filter(
          (ticket) => ticket.customTicketId !== ""
        );
        const nonCreatedEvents = events
          .filter((event) => !isEventExternalLink(event.eventType))
          .filter((myEvent: Event) =>
            nonCreatedTickets.find((tickets) => tickets.eventId === myEvent.id)
          );
        setUserAttendingEvents(
          nonCreatedEvents.sort((a, b) => {
            return new Date(a.start).getTime() - new Date(b.start).getTime();
          })
        );
        setUserEvents(events);
        finalEvents = events;
      } else {
        // For other profiles, only show the upcoming and past events, not attending/attended events
        const otherWishlistResponse = await API.user.otherUserWishList({
          uid: userData.uid,
        });
        const { events } = otherWishlistResponse;
        setUserEvents(
          events.filter(
            (event) => !event.isDraft && event.visibility === Visibility.Public
          )
        );
        finalEvents = events;
      }

      const eventOrganizers = await fetchEventOrganizers(finalEvents);
      setEventOrganizers(eventOrganizers);
    }

    setLoadingEvents(false);
    dispatch(accountActions.setIsRCA(isUserRCA));
  });

  // Load the default timeline state, which is determined after loading all the events
  useEffect(() => {
    if (!loadingEvents && loadingTimelineState) {
      setEventTimelineState(
        upcomingEvents.length === 0
          ? pastEvents.length === 0
            ? attendingEvents.length === 0
              ? attendedEvents.length === 0
                ? ProfileTimelineState.UPCOMING
                : ProfileTimelineState.ATTENDED
              : ProfileTimelineState.ATTENDING
            : ProfileTimelineState.PAST
          : ProfileTimelineState.UPCOMING
      );
      setLoadingTimelineState(false);
    }
  }, [
    attendedEvents.length,
    attendingEvents.length,
    eventsToShow.length,
    loadingEvents,
    loadingTimelineState,
    pastEvents.length,
    upcomingEvents.length,
    userLinks.length,
  ]);

  // Load the unique event dates for the events loaded in for the timeline
  useEffect(() => {
    const finalEvents = showAllEvents ? eventsToShow : eventsToShow.slice(0, 3);
    if (!loadingEvents) {
      if (finalEvents.length > 0) {
        const foundEventDates: string[] = [];
        for (let i = 0; i < finalEvents.length; i++) {
          if (
            !foundEventDates.some((eventDate) =>
              datesAreOnSameDay(
                new Date(eventDate),
                new Date(finalEvents[i].start)
              )
            )
          ) {
            foundEventDates.push(finalEvents[i].start);
          }
        }
        setEventDates(foundEventDates);
      }
      setLoadingDates(false);
    }
  }, [eventsToShow, loadingEvents, showAllEvents, showMoreEvents]);

  const SkeletonLoading = useMemo(
    () => (
      <div
        className="ColumnNormal"
        style={{ gap: 10, paddingInline: isDesktop ? 0 : 14 }}
      >
        <Skeleton
          key={0}
          height={40}
          baseColor={Colors.WHITE1}
          borderRadius={12}
        />
        <div className="AlignedRowSpaced">
          <Skeleton
            key={1}
            height={40}
            width={100}
            baseColor={Colors.WHITE1}
            borderRadius={12}
          />
          <Skeleton
            key={2}
            height={40}
            width={200}
            baseColor={Colors.WHITE1}
            borderRadius={12}
          />
        </div>
        {[3, 4, 5].map((item, index) => (
          <Skeleton
            key={index}
            height={109}
            baseColor={Colors.WHITE1}
            borderRadius={12}
          />
        ))}
      </div>
    ),
    []
  );

  return (
    <div
      className="ColumnCentering"
      style={{ width: "100%", paddingTop: isDesktop ? 30 : 20 }}
    >
      <ProfilePageHeader
        followerData={followerData}
        otherUserData={userData}
        isLoading={loggedIn === LoginState.LOGGED_IN && loadingFollower}
      />
      <div
        className="ColumnNormal"
        style={{
          gap: isDesktop ? 30 : 20,
          width: isDesktop ? 544 : "100%",
          paddingTop: isDesktop ? 30 : 20,
          paddingBottom: isDesktop ? 60 : 24,
        }}
      >
        {fullLoading ? (
          SkeletonLoading
        ) : (
          <>
            <FlatTabs
              items={ProfileTabNames}
              tabValue={tabValue}
              setTabValue={setTabValue}
            />
            {tabValue === ProfileTabNames.indexOf("Subscriptions") ? (
              <ProfilePageUserSubscriptions
                followingAccountDataFollowing={followingAccountDataFollowing}
                followerDataMap={followerDataMap}
                setFollowerDataMap={setFollowerDataMap}
              />
            ) : tabValue === ProfileTabNames.indexOf("Contact") ? (
              <div
                className="ColumnNormal"
                style={{
                  gap: isDesktop ? 20 : 14,
                  paddingInline: isDesktop ? 0 : 14,
                }}
              >
                <OrganizersInfoDisplay
                  host={userData}
                  organizers={[userData]}
                  isProfilePage
                  containerStyles={{
                    backgroundColor: Colors.WHITE1,
                    border: `1px solid ${Colors.GRAY11}`,
                  }}
                />
                <CreatorTextManual
                  host={userData}
                  containerStyles={{
                    backgroundColor: Colors.WHITE1,
                    border: `1px solid ${Colors.GRAY11}`,
                  }}
                />
              </div>
            ) : (
              <div
                className="ColumnNormal"
                style={{ gap: 14, paddingInline: isDesktop ? 0 : 14 }}
              >
                <ProfilePageTimelineHeader
                  timelineState={eventTimelineState}
                  setTimelineState={setEventTimelineState}
                  displayView={eventDisplayView}
                  setDisplayView={setEventDisplayView}
                  tabValue={tabValue}
                  tabNames={ProfileTabNames}
                  hasEvents={hasAnyEvents}
                  isMyAccount={isMyAccount}
                />
                <ProfilePageTimeline
                  displayView={eventDisplayView}
                  timelineState={eventTimelineState}
                  userItemsToShow={
                    tabValue === ProfileTabNames.indexOf("Events")
                      ? eventsToShow
                      : userLinks
                  }
                  userData={userData}
                  eventDates={eventDates}
                  eventOrganizers={eventOrganizers}
                  showMoreEvents={showAllEvents}
                  setShowMoreEvents={setShowMoreEvents}
                  isLinksTab={tabValue === ProfileTabNames.indexOf("Links")}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default ProfilePageFull;
