import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Campaign,
  CampaignType,
  MassText,
  SubSelectionStatusId,
  SubSelectionType,
} from "@markit/common.types";
import { Colors } from "../../../utils/colors";
import MassTextPanel from "../MassTexts/MassTextPanel";
import RectangleButton from "../../../components/Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import { HorizontalDivider } from "../../../components/Dividers/HorizontalDivider";
import { VerticalDivider } from "../../../components/Dividers/VerticalDivider";
import {
  displayAutomationTriggerActionSubtext,
  displayAutomationTriggerLabel,
  generateSuggestedActionItem,
  handlePluralString,
  isEventExternalLink,
  isLockedPresetAutomations,
} from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getEventState } from "../../../redux/slices/eventSlice";
import CampaignTypeItem from "./Items/CampaignTypeItem";
import { useTheme } from "../../../hooks/useTheme";
import {
  MassTextSelectedType,
  MassTextsWrapperType,
} from "../MassTexts/MassTextWrapperManager";
import { MassTextDetails } from "../../../redux/slices/campaignSlice";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import TriggerSummaryPanel from "./TriggerSummaryPanel";
import CampaignFlowTimeline from "./CampaignFlowTimeline";
import { useOnMount } from "../../../hooks/useOnMount";
import { useLocation } from "react-router-dom";
import PresetAutomationTriggerIcon from "./Items/PresetAutomationTriggerIcon";

type CampaignFlowPanelProps = {
  campaign: Campaign;
  massTextDetails: MassTextDetails[];
  totalRecipients: number;
  editingCampaign: boolean;
  setEditingCampaign: (editingCampaign: boolean) => void;
};

const CampaignFlowPanel = (props: CampaignFlowPanelProps) => {
  const {
    campaign,
    massTextDetails,
    totalRecipients,
    editingCampaign,
    setEditingCampaign,
  } = props;
  const { events } = useSelector(getEventState).events;
  const { theme } = useTheme();
  const { isFollowUp } = useLocation().state ?? {};
  const [selectedTextType, setSelectedTextType] =
    useState<MassTextSelectedType>({
      massText: undefined,
      isVisible: true,
      isDuplicateText: false,
    });
  const [duplicateTextType, setDuplicateTextType] =
    useState<MassTextSelectedType>({
      massText: undefined,
      isVisible: false,
      isDuplicateText: true,
    });
  const [triggerItemSelected, setTriggerItemSelected] = useState(false);

  const styles = {
    itemContainer: {
      paddingBlock: 14,
      backgroundColor: Colors.WHITE,
      borderRadius: 14,
      width: 307,
    },
    newTextLabel: {
      fontSize: 13,
      fontWeight: 500,
      color: Colors.BLUE5,
    },
  };

  const renderVerticalLine = (
    <div style={{ paddingBlock: 4 }}>
      <VerticalDivider height={30} color={Colors.GRAY2} />
    </div>
  );

  const sortedMassTextDetails = useMemo(
    () =>
      [...massTextDetails].sort(
        (a, b) =>
          new Date(a.massText.sentAt).getTime() -
          new Date(b.massText.sentAt).getTime()
      ),
    [massTextDetails]
  );

  const automationCampaign = useMemo(
    () => campaign.type === CampaignType.AUTOMATION,
    [campaign.type]
  );

  const foundEvent = useMemo(
    () => events.find((event) => event.id === campaign.eventId),
    [campaign.eventId, events]
  );

  // Handles if there is no mass text selected, default back to the initial mass text
  useEffect(() => {
    if (
      !editingCampaign &&
      !selectedTextType.massText &&
      !triggerItemSelected
    ) {
      setSelectedTextType({
        massText: sortedMassTextDetails[0].massText,
        isVisible: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingCampaign]);

  // Handles if the selected text no longer exists and we need to select new text, ie. deleted
  useEffect(() => {
    if (
      selectedTextType.massText &&
      !sortedMassTextDetails.some(
        (text) => text.massText.id === selectedTextType.massText?.id
      )
    ) {
      setSelectedTextType({
        massText:
          sortedMassTextDetails[sortedMassTextDetails.length - 1].massText,
        isVisible: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedMassTextDetails.length]);

  const duplicateTextOnPress = useCallback((massText: MassText) => {
    setDuplicateTextType({ massText, isVisible: true, isDuplicateText: true });
  }, []);

  const newTextOnPress = useCallback(() => {
    setEditingCampaign(true);
    setSelectedTextType({
      massText: undefined,
      isVisible: true,
    });
  }, [setEditingCampaign]);

  useOnMount(() => {
    // Handle immediately opening new text if the user viewed dashboard from the Follow Up action
    if (isFollowUp) {
      newTextOnPress();
    }
  });

  const triggerItemOnPress = useCallback(() => {
    setSelectedTextType({
      ...selectedTextType,
      isDuplicateText: false,
      massText: undefined,
    });
    setTriggerItemSelected(true);
  }, [selectedTextType]);

  return (
    <>
      <div className="RowNormal PreventScroll">
        <div
          className="ColumnNormal PreventScroll"
          style={{
            flex: 1,
            borderRight: `1.5px solid ${theme.DividerColor.borderColor}`,
          }}
        >
          {!editingCampaign ? (
            <>
              <div
                className="AlignedRowSpaced"
                style={{
                  flexShrink: 0,
                  paddingInline: 30,
                  height: 58,
                  backgroundColor: Colors.WHITE,
                }}
              >
                <span className="sectionTitle">
                  {automationCampaign ? "Automation" : "Campaign"} Flow
                </span>
                <CampaignTypeItem
                  numMassTexts={massTextDetails.length}
                  automation={automationCampaign}
                  preventPointer
                />
              </div>
              <HorizontalDivider altMargin={0} />
            </>
          ) : null}
          <div
            className="ColumnNormal AllowScroll"
            style={{
              paddingTop: 30,
              alignItems: "center",
            }}
          >
            <div
              className="ColumnNormal"
              style={{ gap: 7, textAlign: "center", paddingBlock: 7 }}
            >
              <span className="bodySubtext" style={{ color: Colors.GRAY2 }}>
                {automationCampaign ? "Automation" : "Campaign"} Begins
              </span>
              {!automationCampaign ? (
                <div
                  className="AlignedRow"
                  style={{ gap: 2, justifyContent: "center" }}
                >
                  <span style={{ fontSize: 12, color: Colors.BLACK }}>
                    {totalRecipients !== 0 ? totalRecipients : "--"}{" "}
                    {handlePluralString("recipient", totalRecipients)}
                  </span>
                </div>
              ) : null}
            </div>
            {automationCampaign ? (
              <div
                style={{
                  border: triggerItemSelected ? "2px solid black" : "",
                  borderRadius: 18,
                  padding: triggerItemSelected ? 4 : 0,
                }}
              >
                <StandardBorderedContainer
                  containerStyles={{
                    ...styles.itemContainer,
                    cursor: "pointer",
                  }}
                  onPress={triggerItemOnPress}
                >
                  <div
                    className="AlignedRowSelect"
                    style={{ gap: 7, paddingInline: 14 }}
                  >
                    <Icon icon={"ion:flash"} height={15} />
                    <span className="bodyMedium">Trigger</span>
                  </div>
                  <HorizontalDivider altMargin={14} />
                  <div
                    className="AlignedRowSelect"
                    style={{ gap: 10, paddingInline: 14 }}
                  >
                    {foundEvent ? (
                      <img
                        src={foundEvent.photoURL}
                        alt={"AutomationEventCampaignItem"}
                        style={{ width: 37, height: 37, borderRadius: 8 }}
                      />
                    ) : (
                      <PresetAutomationTriggerIcon
                        campaignTitle={campaign.title}
                        containerStyles={{
                          border: `1.5px solid ${theme.DividerColor.borderColor}`,
                          cursor: "pointer",
                        }}
                      />
                    )}
                    <div className="ColumnNormalSelect" style={{ gap: 4 }}>
                      <span className="bodyMedium">
                        {displayAutomationTriggerLabel(campaign, foundEvent)}
                      </span>
                      <span className="smallBodySubtext">
                        {displayAutomationTriggerActionSubtext(
                          campaign,
                          foundEvent
                        )}
                      </span>
                    </div>
                  </div>
                </StandardBorderedContainer>
              </div>
            ) : null}
            {renderVerticalLine}
            <CampaignFlowTimeline
              campaign={campaign}
              sortedMassTextDetails={sortedMassTextDetails}
              foundEvent={foundEvent}
              selectedTextType={selectedTextType}
              setSelectedTextType={setSelectedTextType}
              duplicateTextOnPress={duplicateTextOnPress}
              setTriggerItemSelected={setTriggerItemSelected}
            />
            {renderVerticalLine}
            {editingCampaign &&
            !triggerItemSelected &&
            !selectedTextType.massText &&
            selectedTextType.isVisible ? (
              <>
                <div
                  style={{
                    border: "2px solid black",
                    borderRadius: 18,
                    padding: 4,
                  }}
                >
                  <StandardBorderedContainer
                    containerStyles={styles.itemContainer}
                  >
                    <div
                      className="ColumnNormal"
                      style={{ gap: 7, paddingInline: 14 }}
                    >
                      <span className="bodyMedium">New Text...</span>
                      <span className="smallBodySubtext">
                        Send or schedule your text to add to campaign
                      </span>
                    </div>
                  </StandardBorderedContainer>
                </div>
                {renderVerticalLine}
              </>
            ) : null}
            {!isLockedPresetAutomations(campaign) ? (
              <>
                <div>
                  <RectangleButton
                    buttonLabel={
                      <span style={styles.newTextLabel}>New Text</span>
                    }
                    onPress={newTextOnPress}
                    altPaddingHorz={10}
                    altPaddingVert={10}
                    altBorderRadius={100}
                    containerStyles={{
                      gap: 4,
                      border: `1px solid ${Colors.BLUE5}`,
                    }}
                    altColor={"rgba(0,122,255,0.1)"}
                    altTextColor={Colors.BLUE5}
                    iconLeft={
                      <Icon
                        icon={"ic:round-plus"}
                        height={16}
                        style={{ color: Colors.BLUE5 }}
                      />
                    }
                  />
                </div>
                {renderVerticalLine}
              </>
            ) : null}
            <div
              className="AlignedRow"
              style={{ gap: 7, paddingTop: 7, paddingBottom: 100 }}
            >
              <span className="bodySubtext" style={{ color: Colors.GRAY2 }}>
                {automationCampaign ? "Automation" : "Campaign"} Ends
              </span>
              <Icon
                icon={"ion:exit-outline"}
                height={17}
                style={{ color: Colors.GRAY2, marginBottom: -1 }}
              />
            </div>
          </div>
        </div>
        {triggerItemSelected ? (
          <div style={editingCampaign ? { width: 557 } : { flex: 1 }}>
            <TriggerSummaryPanel
              campaign={campaign}
              totalRecipients={totalRecipients}
            />
          </div>
        ) : selectedTextType.isVisible ? (
          <div
            key={selectedTextType.massText?.id}
            style={editingCampaign ? { width: 557 } : { flex: 1 }}
          >
            <MassTextPanel
              wrapperType={
                editingCampaign
                  ? MassTextsWrapperType.SIDE_PANEL
                  : MassTextsWrapperType.SIDE_PANEL_PREVIEW
              }
              selectedTextType={selectedTextType}
              setSelectedTextType={setSelectedTextType}
              duplicateOnPress={duplicateTextOnPress}
              setEditingCampaign={setEditingCampaign}
              campaign={campaign}
              suggestedAction={
                !selectedTextType.massText &&
                campaign.type === CampaignType.AUTOMATION
                  ? foundEvent && isEventExternalLink(foundEvent.eventType)
                    ? generateSuggestedActionItem(
                        SubSelectionType.ALL_PEOPLE,
                        ""
                      )
                    : generateSuggestedActionItem(
                        SubSelectionType.STATUS,
                        SubSelectionStatusId.ATTENDEES
                      )
                  : undefined
              }
              event={foundEvent}
            />
          </div>
        ) : null}
      </div>
      {duplicateTextType.isVisible ? (
        <MassTextPanel
          wrapperType={MassTextsWrapperType.MODAL}
          selectedTextType={duplicateTextType}
          setSelectedTextType={setDuplicateTextType}
          setEditingCampaign={setEditingCampaign}
          campaign={campaign}
        />
      ) : null}
    </>
  );
};

export default CampaignFlowPanel;
