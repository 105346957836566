import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../../../../utils/colors";
import RectangleButton from "../../../Buttons/RectangleButton";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "../../../Containers/PopupModalContainer";
import CustomTextField from "../../../CustomTextField";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import {
  accountActions,
  getAccountState,
} from "../../../../redux/slices/accountSlice";
import { useContext, useState } from "react";
import { Icon } from "@iconify/react";
import { showPhoneInputError } from "../../../../utils/phoneUtils";
import { showNotificationBanner } from "../../../../utils/notificationUtils";
import { FollowType, NotificationType } from "@markit/common.types";
import { MixpanelContext } from "../../../../context/AnalyticsService";
import { followUser } from "../../../../utils/FollowUser";
import CustomPhoneTextField from "../../../TextFields/CustomPhoneTextField";
import {
  getUserData,
  getUserFollowersRef,
} from "../../../../utils/FirebaseUtils";
import { limit, onSnapshot, query, where } from "../../../../firebase";
import { MARKIT_TERMS_OF_USE } from "@markit/common.utils";

export type AddSingleContactModalProps = {
  setIsVisible: (isVisible: boolean) => void;
};

const AddSingleContactModal = (props: AddSingleContactModalProps) => {
  const { setIsVisible } = props;
  const { accountData } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullNameError, setFullNameError] = useState({
    isError: false,
    message: "",
  });
  const [phoneNumberError, setPhoneNumberError] = useState({
    isError: false,
    message: "",
  });
  const [processing, setProcessing] = useState(false);

  const addSingleContact = async () => {
    if (fullName === "") {
      setFullNameError({
        isError: true,
        message: "Full name cannot be empty.",
      });
    }
    if (showPhoneInputError(phoneNumber, setPhoneNumberError)) {
      return;
    }
    setProcessing(true);

    const success = await followUser({
      userPhone: phoneNumber,
      userFullName: fullName,
      userToFollow: accountData,
      uid: "",
      answers: undefined,
      followType: FollowType.UPLOAD,
      disableTriggerMessage: true,
    });
    if (!success) {
      setProcessing(false);
      return;
    }
    // Add live snapshot to populate redux once follower data is created
    const followersRef = getUserFollowersRef(accountData.uid);
    const followersQuery = query(
      followersRef,
      where("phoneNumber", "==", phoneNumber),
      limit(1)
    );
    const unsubscribe = onSnapshot(followersQuery, async (snapshot) => {
      const followerData = snapshot.docs[0].data();
      if (followerData) {
        const userData = await getUserData(followerData.uid);
        if (userData) {
          dispatch(accountActions.addMultipleFollowingAccountData([userData]));
          dispatch(accountActions.addMultipleFollowerData([followerData]));
        } else {
          alert("Please refresh the page to view the new contact. Thank you.");
        }
        if (mixpanel) {
          mixpanel.track("Added Single Follower Contact", {
            distinct_id: accountData.uid,
            phone_number: phoneNumber,
            full_name: fullName,
          });
        }
        setIsVisible(false);
        setProcessing(false);
        showNotificationBanner(
          dispatch,
          "Added Contact",
          NotificationType.AFFIRMATIVE
        );
        unsubscribe();
      }
    });
  };

  return (
    <>
      <PopupModalContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 14 }}>
            <Icon icon="ion:person-add" height={35} color={Colors.BLACK} />
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <span className="sectionTitle">Add Single Contact</span>
              <span className="smallBodySubtext" style={{ fontWeight: 400 }}>
                Manually add a single contact to your audience
              </span>
            </div>
          </div>
        }
        valueComp={
          <div className="ColumnNormal" style={{ gap: 20 }}>
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <span className="bodyMedium">Full Name</span>
              <div className="ColumnNormal" style={{ gap: 4 }}>
                <CustomTextField
                  value={fullName}
                  placeholder="Full name"
                  inputMode="text"
                  borderRadius={12}
                  maxLength={60}
                  backgroundColor={Colors.WHITE}
                  error={fullNameError.isError}
                  onChange={(change: any) => {
                    setFullName(change.target.value);
                    setFullNameError({
                      isError: false,
                      message: "",
                    });
                  }}
                  altMarginBottom={0}
                />
                {fullNameError.isError ? (
                  <span
                    style={{
                      color: Colors.RED3,
                      fontWeight: 400,
                      fontSize: 12,
                    }}
                  >
                    {fullNameError.message}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <span className="bodyMedium">Phone Number</span>
              <CustomPhoneTextField
                placeholder="Phone number"
                phoneNumber={phoneNumber}
                onPhoneChange={(value) => {
                  setPhoneNumber(value);
                  setPhoneNumberError({ isError: false, message: "" });
                }}
                showPhoneError={phoneNumberError}
              />
            </div>
            <span className="smallBodySubtext">
              By pressing “Add Contact”, you acknowledge and agree you have
              obtained explicit consent from the individual whose phone number
              you are uploading, or will upload, and also agree to the following{" "}
              <span
                onClick={() => window.open(MARKIT_TERMS_OF_USE)}
                style={{
                  color: Colors.BLACK,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                terms.
              </span>
            </span>
          </div>
        }
        fixedBottomContent={
          <div className="ColumnNormal">
            <HorizontalDivider altMargin={14} />
            <div className="AlignedRow" style={{ gap: 10, paddingInline: 14 }}>
              <RectangleButton
                buttonLabel={"Cancel"}
                onPress={() => setIsVisible(false)}
                altTextColor={Colors.BLACK}
                altColor={Colors.GRAY11}
                containerStyles={{ paddingBlock: 14, paddingInline: 12 }}
              />
              <RectangleButton
                buttonLabel={"Add Contact"}
                onPress={addSingleContact}
                altTextColor={Colors.WHITE}
                altColor={Colors.BLACK}
                containerStyles={{ paddingBlock: 14, paddingInline: 12 }}
                loading={processing}
              />
            </div>
          </div>
        }
        noExit
        closeModal={() => setIsVisible(false)}
        modalContentWidth={PopupModalContainerWidth.SMALL}
      />
    </>
  );
};

export default AddSingleContactModal;
