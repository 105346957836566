import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import PopupModalContainer from "../Containers/PopupModalContainer";
import RectangleButton from "../Buttons/RectangleButton";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  LoginState,
  accountActions,
  getAccountState,
} from "../../redux/slices/accountSlice";
import { MixpanelContext } from "../../context/AnalyticsService";
import { connectToStripeAccount } from "../../utils/stripeAccountUtils";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { testStripe } from "../FullEvent/FullEventInputForm";
import { StripeAccountStatus } from "@markit/common.types";
import { ThemeStyle } from "../../hooks/useTheme";
import { useLocation } from "react-router-dom";
import { useNavigate } from "../../hooks/useNavigate";
import { NavigationId } from "../../navigation/AppParamList";

export enum AccountStatus {
  INCOMPLETE = 0,
  PENDING = 1,
  FINISHING = 2,
}

type EnablePaidTicketsModalProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  isPromoter?: boolean;
  theme?: ThemeStyle;
};

const EnablePaidTicketsModal = (props: EnablePaidTicketsModalProps) => {
  const { isVisible, setIsVisible, isPromoter, theme } = props;
  const { accountData, loggedIn } = useSelector(getAccountState).account;
  const mixpanel = useContext(MixpanelContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);

  const styles = {
    subtext: {
      fontSize: 14,
      fontWeight: "400",
      color: Colors.GRAY1,
    },
  };

  const userStripeAccountStatus = useMemo(
    () =>
      testStripe
        ? accountData.testStripeAccountStatus
        : accountData.stripeAccountStatus,
    [accountData.stripeAccountStatus, accountData.testStripeAccountStatus]
  );

  const userStripeAccountId = useMemo(
    () =>
      testStripe
        ? accountData.testStripeAccountId
        : accountData.stripeAccountId,
    [accountData.stripeAccountId, accountData.testStripeAccountId]
  );

  const accountStatus: AccountStatus = useMemo(
    () =>
      userStripeAccountId === ""
        ? AccountStatus.INCOMPLETE
        : userStripeAccountStatus === StripeAccountStatus.PENDING
        ? AccountStatus.PENDING
        : AccountStatus.FINISHING,
    [userStripeAccountId, userStripeAccountStatus]
  );

  const connectToStripe = useCallback(async () => {
    setLoading(true);
    if (loggedIn !== LoginState.LOGGED_IN) {
      dispatch(accountActions.setRedirectPath(pathname));
      navigate(NavigationId.WELCOME);
    } else if (accountStatus !== AccountStatus.PENDING) {
      mixpanel.track("Navigated To Set Up Paid Tickets", {
        origin: "profile",
      });
      await connectToStripeAccount(accountData.uid);
    } else {
      setIsVisible(false);
    }
    setLoading(false);
  }, [
    accountData.uid,
    accountStatus,
    dispatch,
    loggedIn,
    mixpanel,
    navigate,
    pathname,
    setIsVisible,
  ]);

  const promoterSubtext =
    "The owner of this event has assigned you as a promoter. In order to receive revenue payouts and your promoter link, you must first connect or create a Stripe account.";

  const toggleModal = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible, setIsVisible]);

  return (
    <div>
      {isVisible ? (
        <PopupModalContainer
          headerComp={
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <span
                style={{ fontSize: 16, fontWeight: "500", marginRight: 24 }}
              >
                {accountStatus === AccountStatus.INCOMPLETE
                  ? isPromoter
                    ? "Connect to Stripe to receive payouts and share promoter link"
                    : "Enable Paid Ticketing"
                  : accountStatus === AccountStatus.PENDING
                  ? "Pending Stripe Verification"
                  : isPromoter
                  ? "Finish connecting to Stripe to receive payouts and share promoter link"
                  : "Finish Connecting Stripe"}
              </span>
              {accountStatus === AccountStatus.PENDING ? (
                <span style={styles.subtext}>
                  Your Stripe account is being verified and will take a few
                  minutes. If it does not change, there may be missing
                  information or a failure.
                </span>
              ) : accountStatus === AccountStatus.FINISHING ? (
                <span style={styles.subtext}>
                  {isPromoter
                    ? promoterSubtext
                    : "In order to enable paid ticketing on Markit you must finish connecting to your Stripe account."}
                </span>
              ) : (
                <span style={styles.subtext}>
                  {isPromoter
                    ? promoterSubtext
                    : "To enable paid ticketing on Markit you must connect or create a Stripe account."}
                </span>
              )}
            </div>
          }
          valueComp={
            <div
              className="AlignedColumn"
              style={{
                paddingTop: 14,
                paddingBottom: 24,
                paddingInline: 34,
                gap: 14,
              }}
            >
              <Icon
                icon={"fa:cc-stripe"}
                height={49}
                color={theme?.ButtonBG.backgroundColor}
              />
              <span style={{ ...styles.subtext, textAlign: "center" }}>
                We use stripe to allow you to process purchases and manage your
                payouts and funds.
              </span>
              <span style={{ ...styles.subtext, textAlign: "center" }}>
                Once you have set up a Stripe account, all transactions will
                flow through Stripe with payouts coming within 48 hours after
                initiating.
              </span>
              <span style={{ ...styles.subtext, textAlign: "center" }}>
                Using Stripe, you can directly connect with your debit card or
                directly with your bank account.
              </span>
            </div>
          }
          fixedBottomContent={
            <div
              className="ColumnNormal"
              style={{ gap: 14, paddingInline: 14 }}
            >
              <HorizontalDivider />
              <RectangleButton
                buttonLabel={
                  <span>
                    {loggedIn !== LoginState.LOGGED_IN
                      ? "Login or Sign Up to Connect Stripe"
                      : accountStatus === AccountStatus.INCOMPLETE
                      ? "Connect Stripe"
                      : accountStatus === AccountStatus.PENDING
                      ? "Done"
                      : "Finish Connecting Stripe"}
                  </span>
                }
                loading={loading}
                disabled={loading}
                onPress={connectToStripe}
                altTextColor={theme ? theme.ButtonText.color : Colors.WHITE}
                altColor={theme ? theme.ButtonBG.backgroundColor : Colors.BLACK}
              />
            </div>
          }
          noExit
          closeModal={toggleModal}
          closeOnOutsidePress
          theme={theme}
        />
      ) : null}
    </div>
  );
};

export default EnablePaidTicketsModal;
