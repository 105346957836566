import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import {
  mostRecentSubscription,
  formatDate,
  hasSubscription,
  mostRecentSubscriptionPlan,
  subscriptionOverflowRate,
  handlePluralString,
  isBasicSubscription,
} from "@markit/common.utils";
import { Icon } from "@iconify/react";
import ProgressBar from "@ramonak/react-progress-bar";
import { useCallback, useMemo, useState } from "react";
import { GetLongDate } from "../../utils/GetLongDate";
import { Colors } from "../../utils/colors";
import { IoWarningOutline } from "react-icons/io5";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { FREE_TIER_MESSAGE_LIMIT } from "@markit/common.types";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import PopupModalContainer from "../Containers/PopupModalContainer";
import MarkitPlusActionButton from "../Subscription/MarkitPlusActionButton";
import { useNavigate } from "react-router-dom";
import SubscriptionPlanLabelItem from "../Subscription/SubscriptionPlanLabelItem";
import { notificationActions } from "../../redux/slices/notificationSlice";

type TextingPanelUsageProps = {
  onBilling?: boolean;
};

const TextingPanelUsage = (props: TextingPanelUsageProps) => {
  const { onBilling } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [overflowModalVisible, setOverflowModalVisible] =
    useState<boolean>(false);

  const trialSubscription = useMemo(
    () => accountData.customer.state === "TRIAL",
    [accountData.customer.state]
  );

  const frozenSubscription = useMemo(
    () => accountData.customer.state === "FROZEN",
    [accountData.customer.state]
  );

  const subscription = useMemo(
    () =>
      hasSubscription(accountData)
        ? mostRecentSubscription(accountData)
        : undefined,
    [accountData]
  );

  const subscriptionPlan = useMemo(
    () => mostRecentSubscriptionPlan(accountData),
    [accountData]
  );

  const overflowRate = useMemo(
    () => subscriptionOverflowRate(accountData),
    [accountData]
  );

  const freeTierFirstDayBilling = useMemo(() => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return formatDate(firstDay, false, true, false);
  }, []);

  const freeTierLastDayBilling = useMemo(() => {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return formatDate(lastDay, false, true, false);
  }, []);

  const freeTierMessages = useMemo(
    () =>
      accountData.customer.freeTierMessages
        ? accountData.customer.freeTierMessages
        : 0,
    [accountData.customer.freeTierMessages]
  );

  const progressTickerLabels: number[] = useMemo(() => {
    const labels: number[] = [0];
    if (subscription) {
      for (let i = 1; i <= 4; i++) {
        labels.push(subscription.price[0].limit * (i / 4));
      }
    } else {
      for (let i = 1; i <= 4; i++) {
        labels.push(FREE_TIER_MESSAGE_LIMIT * (i / 4));
      }
    }
    return labels;
  }, [subscription]);

  const overflow = useMemo(
    () =>
      subscription ? subscription.message - subscription.price[0].limit : 0,
    [subscription]
  );

  const styles = {
    smallNoticeButton: {
      backgroundColor: Colors.GRAY6,
      alignItems: "center",
      padding: 10,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 20,
      fontSize: 12,
      cursor: "pointer",
    },

    usageLabelView: {
      marginBottom: 7,
    },
  };

  const navigateBilling = useCallback(
    () =>
      subscription
        ? navigate("/home/billing")
        : dispatch(notificationActions.setMarkitPlusModal(true)),
    [navigate, subscription, dispatch]
  );

  const textUsagePercent = useMemo(() => {
    return (
      <div style={{ marginLeft: 10 }}>
        {!isBasicSubscription(accountData) ? (
          <span
            style={{
              fontSize: 14,
              color: Colors.BLACK,
            }}
          >
            {subscription
              ? `${subscription.message.toLocaleString()}/${subscription.price[0].limit.toLocaleString()}  • `
              : `${freeTierMessages}/${FREE_TIER_MESSAGE_LIMIT} • `}
          </span>
        ) : null}
        <span style={{ fontSize: 12, color: Colors.BLACK }}>
          {subscription
            ? overflow > 0
              ? `${overflow.toLocaleString()} ${
                  isBasicSubscription(accountData) ? "" : "Overflow "
                }${handlePluralString("credit", overflow)} used. Expect $${
                  Math.round(overflowRate * overflow) / 100
                } added to next invoice.`
              : isBasicSubscription(accountData)
              ? `${subscription.message} credits used`
              : `${
                  Math.round(
                    (subscription.message / subscription.price[0].limit) * 10000
                  ) / 100
                }% Free credits used`
            : `${
                Math.round(
                  (freeTierMessages / FREE_TIER_MESSAGE_LIMIT) * 10000
                ) / 100
              }% Free credits used`}
        </span>
      </div>
    );
  }, [accountData, freeTierMessages, overflow, overflowRate, subscription]);

  const noticeButton = useMemo(() => {
    if (subscriptionPlan) {
      if (frozenSubscription) {
        return (
          <div
            className="AlignedRow"
            style={{ ...styles.smallNoticeButton, ...styles.usageLabelView }}
          >
            <span>Account Frozen</span>
            <IoWarningOutline
              size={14}
              style={{ color: Colors.ORANGE1, marginLeft: 4 }}
            />
          </div>
        );
      } else if (trialSubscription) {
        return (
          <div className="AlignedRow" style={styles.usageLabelView}>
            <SubscriptionPlanLabelItem planName="Free Trial" />
            {textUsagePercent}
          </div>
        );
      } else {
        return (
          <div className="AlignedRow" style={styles.usageLabelView}>
            <SubscriptionPlanLabelItem
              planName={`${subscriptionPlan.name} Plan`}
            />
            {textUsagePercent}
          </div>
        );
      }
    } else {
      return (
        <div className="AlignedRow" style={styles.usageLabelView}>
          <SubscriptionPlanLabelItem
            planName=" Free Plan"
            onPress={() =>
              dispatch(notificationActions.setMarkitPlusModal(true))
            }
          />
          {textUsagePercent}
        </div>
      );
    }
  }, [
    subscriptionPlan,
    frozenSubscription,
    trialSubscription,
    styles.smallNoticeButton,
    styles.usageLabelView,
    textUsagePercent,
    dispatch,
  ]);

  return (
    <>
      <StandardBorderedContainer
        containerStyles={{
          backgroundColor: Colors.WHITE,
          width: "100%",
          paddingBlock: 14,
        }}
      >
        <div className="AlignedRowSpaced" style={{ paddingInline: 14 }}>
          <span className="sectionTitle">Texting Usage</span>
          {hasSubscription(accountData) && !frozenSubscription ? (
            <div className="AlignedRow" style={{ gap: 7 }}>
              <span style={{ color: Colors.BLUE5 }}>
                {formatPhoneNumber(accountData.customer.phone)}
              </span>
              <Icon
                icon="ion:checkmark-circle"
                height={16}
                color={Colors.BLUE5}
              />
            </div>
          ) : (
            <MarkitPlusActionButton small />
          )}
        </div>
        <HorizontalDivider altMargin={14} />
        <div style={{ paddingInline: 14 }}>
          <div className="AlignedRowSpaced">{noticeButton}</div>
          <div className="ColumnNormal" style={{ gap: 5 }}>
            <span style={{ fontSize: 12, color: Colors.GRAY2 }}>
              This{" "}
              {trialSubscription
                ? "trial"
                : subscriptionPlan
                ? "billing"
                : "monthly"}{" "}
              cycle (
              {subscription
                ? GetLongDate(subscription.start, false, true, false)
                : freeTierFirstDayBilling}{" "}
              -{" "}
              {subscription
                ? GetLongDate(subscription.end, false, true, false)
                : freeTierLastDayBilling}
              )
            </span>
            <div className="AlignedRow">
              <span style={{ fontSize: 12, color: Colors.GRAY2 }}>
                {subscription && trialSubscription
                  ? `Card on file will be charged at end of trial (${GetLongDate(
                      subscription.end,
                      false,
                      true,
                      false
                    )}) or when credit cap is hit`
                  : subscriptionPlan
                  ? `Overflow Rate (${overflowRate} ${handlePluralString(
                      "cent",
                      overflowRate
                    )} per credit)`
                  : `Free tier is capped at ${FREE_TIER_MESSAGE_LIMIT} credits`}
              </span>
              {hasSubscription(accountData) && !trialSubscription ? (
                <Icon
                  icon="mdi:information-outline"
                  height={14}
                  color={Colors.BLACK}
                  style={{ marginLeft: 4, cursor: "pointer" }}
                  onClick={() => {
                    setOverflowModalVisible(true);
                  }}
                />
              ) : null}
            </div>
            {!onBilling && !trialSubscription ? (
              <div className="AlignedRowSelect" onClick={navigateBilling}>
                {subscription ? (
                  <span style={{ fontSize: 12, color: Colors.BLUE5 }}>
                    View Billing
                  </span>
                ) : (
                  <span style={{ fontSize: 12, color: Colors.BLUE5 }}>
                    Upgrade
                  </span>
                )}
                <Icon
                  icon="iconoir:arrow-up-right"
                  height={14}
                  color={Colors.BLUE5}
                />
              </div>
            ) : null}
          </div>
          {!isBasicSubscription(accountData) ? (
            <div style={{ paddingTop: 14 }}>
              <ProgressBar
                completed={
                  subscription ? subscription.message : freeTierMessages
                }
                maxCompleted={
                  subscription
                    ? subscription.price[0].limit
                    : FREE_TIER_MESSAGE_LIMIT
                }
                isLabelVisible={false}
                bgColor={Colors.BLUE5}
                baseBgColor={Colors.GRAY6}
                height="16px"
                borderRadius="4px"
              />
              <div className="AlignedRowSpaced">
                {progressTickerLabels.map((_, index) => (
                  <span
                    key={index}
                    style={{
                      fontSize: 8,
                      color: Colors.GRAY2,
                      userSelect: "none",
                    }}
                  >
                    |
                  </span>
                ))}
              </div>
              <div className="AlignedRowSpaced">
                {progressTickerLabels.map((label, index) => (
                  <span
                    key={index}
                    style={{
                      fontSize: 12,
                      color: Colors.GRAY2,
                      userSelect: "none",
                    }}
                  >
                    {label}
                  </span>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </StandardBorderedContainer>
      {overflowModalVisible ? (
        <PopupModalContainer
          headerComp={"Overflow Texting"}
          valueComp={
            <div>
              <h4 className="AboutSubtitle">What are overflow credits?</h4>
              <h4 className="OverflowTextSubtitle">
                Every month we give you complimentary credits based on your
                Markit plan. With Markit+, once you use all of your
                complimentary monthly credits you are still able to send texts;
                these are called overflow credits. You can send as many overflow
                credits as you want and will be charged based on your usage.
              </h4>
              <h4 className="AboutSubtitle" style={{ paddingTop: 20 }}>
                Rates
              </h4>
              <h4 className="OverflowTextSubtitle">
                After your complimentary monthly credits we charge you{" "}
                {overflowRate} {handlePluralString("cent", overflowRate)} per
                credit. Overflow charges will be added to your monthly billing
                alongside your existing subscription billing.
              </h4>
              <h4 className="AboutSubtitle" style={{ paddingTop: 20 }}>
                Refund Policy
              </h4>
              <h4 className="OverflowTextSubtitle">
                Markit does not offer refunds on overflow credits sent.
              </h4>
            </div>
          }
          closeModal={() => setOverflowModalVisible(false)}
        />
      ) : null}
    </>
  );
};

export default TextingPanelUsage;
