import "../../../css/GlobalStyles.css";
import {
  AccountData,
  Conversation,
  IMessage,
  TextTypes,
  TwilioMessageStatus,
} from "@markit/common.types";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { limit, onSnapshot, query, where } from "../../../firebase";
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../API";
import { getUserConversationsRef } from "../../../utils/FirebaseUtils";
import { Colors } from "../../../utils/colors";
import TextareaAutosize from "react-textarea-autosize";
import ProfilePic from "../../ProfilePic";
import { uniqueVals } from "@markit/common.utils";
import { useOnMount } from "../../../hooks/useOnMount";
import filter from "lodash.filter";
import ConversationsMessagesContainer from "./ConversationsMessagesContainer";
import { CircularProgress } from "@mui/material";
import ConversationMessageInput from "./ConversationMessageInput";
import FullProfilePreviewModal from "../../FollowerProfile/FullProfilePreviewModal";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import { updateChatConversationIndicator } from "../../../redux/slices/conversationSlice";

type ConversationsMainViewProps = {
  conversationUser: AccountData | undefined;
  setConversationUser: (conversationUser: AccountData | undefined) => void;
  fetchedConversations: Conversation[];
  fetchedConversationUserData: AccountData[];
  newUserSearchTerm: string;
  setNewUserSearchTerm: (newUserSearchTerm: string) => void;
  fetchedFollowers: AccountData[];
  loadSearchResultsThrottled: (text: string) => void;
};

const ConversationsMainView = (props: ConversationsMainViewProps) => {
  const {
    conversationUser,
    setConversationUser,
    fetchedConversations,
    fetchedConversationUserData,
    newUserSearchTerm,
    setNewUserSearchTerm,
    fetchedFollowers,
    loadSearchResultsThrottled,
  } = props;
  const dispatch = useDispatch();
  const { accountData } = useSelector(getAccountState).account;
  const [messageCount, setMessageCount] = useState(15);
  const [fetchedAllMessages, setFetchedAllMessages] = useState(false);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [loadingConversation, setLoadingConversation] = useState<boolean>(true);
  const [loadingMoreMessages, setLoadingMoreMessages] =
    useState<boolean>(false);
  const [showLoadingState, setShowLoadingState] = useState(false);
  const [profilePreviewSelected, setProfilePreviewSelected] =
    useState<AccountData>();

  const scrollableFeedRef = useRef<any>(null);

  const scrollToBottom = () => {
    scrollableFeedRef.current?.scrollToBottom();
  };

  const selectedConversation = useMemo(
    () =>
      fetchedConversations.find(
        (convo) =>
          conversationUser &&
          convo.participantUids.includes(conversationUser.uid)
      ),
    [conversationUser, fetchedConversations]
  );

  const styles = {
    profileViewButton: {
      cursor: "pointer",
      paddingBlock: 7,
      paddingInline: 14,
      borderRadius: 100,
      border: `1px solid ${Colors.GRAY2}`,
      backgroundColor: Colors.WHITE1,
    },
  };

  const ref = useRef<any>(null);
  useEffect(() => {
    const handler = (event: any) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !profilePreviewSelected
      ) {
        setNewUserSearchTerm("");
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [profilePreviewSelected, setNewUserSearchTerm]);

  const handleSearch = (text: string) => {
    setNewUserSearchTerm(text.toLowerCase());
    loadSearchResultsThrottled(text.toLowerCase());
  };

  const containsUser = useCallback((item: AccountData, query: string) => {
    return (
      item.fullName.toLowerCase().includes(query) && item.phoneNumber !== ""
    );
  }, []);

  const followersToShow: AccountData[] = useMemo(() => {
    if (newUserSearchTerm === "") {
      return [];
    }
    let userList: AccountData[] = fetchedFollowers;
    userList = filter(userList, (user: AccountData) => {
      return containsUser(user, newUserSearchTerm);
    });
    return userList.slice(0, 5);
  }, [newUserSearchTerm, fetchedFollowers, containsUser]);

  const fetchConversationMessages = useCallback(
    async (increment: boolean) => {
      if (selectedConversation && conversationUser) {
        setLoadingMoreMessages(true);
        const response = await API.creatorText.fetchUserConversationMessages({
          conversationId: selectedConversation.conversationSid,
          twilioUser: accountData,
          attendeeUser: conversationUser,
          messageLimit: increment ? messageCount + 15 : messageCount,
        });
        const { messages } = response;
        if (messages.length === 0) {
          setFetchedAllMessages(true);
        }
        setMessageCount(increment ? messageCount + 15 : messageCount);
        const sortedMessages = messages.sort((x: IMessage, y: IMessage) => {
          return (
            new Date(x.createdAt).getTime() - new Date(y.createdAt).getTime()
          );
        });
        setMessages(uniqueVals(sortedMessages));
        setLoadingMoreMessages(false);
      }
    },
    [accountData, conversationUser, messageCount, selectedConversation]
  );

  const newIMessage = useCallback(
    (convoData: Conversation) => {
      const iMessage: IMessage = {
        _id: convoData.recentMessage.twilioMessageId,
        text: convoData.recentMessage.text ?? "",
        photoURL: convoData.recentMessage.photoURL ?? "",
        createdAt: new Date(convoData.recentTimestamp) ?? "",
        user: {
          _id: convoData.recentMessage.sentBy,
          name: fetchedConversationUserData.find(
            (m) => m.uid === convoData.recentMessage.sentBy
          )?.fullName,
          avatar: fetchedConversationUserData.find(
            (m) => m.uid === convoData.recentMessage.sentBy
          )?.profilePicURL,
        },
        type: TextTypes.CREATORMESSAGE,
        status: TwilioMessageStatus.INITIALIZED,
      };
      return iMessage;
    },
    [fetchedConversationUserData]
  );

  // Snapshot listener for any new messages added to the conversation thread
  useOnMount(() => {
    if (conversationUser) {
      const conversationsRef = getUserConversationsRef(accountData.uid);
      const query_ = query(
        conversationsRef,
        where("participantUids", "array-contains", conversationUser.uid),
        limit(1)
      );
      const unsubscribe = onSnapshot(query_, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          if (message.type === "added" || message.type === "modified") {
            const conversationData = message.doc.data();
            const iMessage: IMessage = newIMessage(conversationData);
            if (iMessage.text !== "" || iMessage.photoURL !== "") {
              setMessages((prev) => {
                if (conversationData.recentMessage.sentBy !== accountData.uid) {
                  const index = prev.findIndex((c) => c._id === iMessage._id);
                  if (index === -1) {
                    prev = prev.concat(iMessage);
                  } else {
                    prev.splice(index, 1, iMessage);
                  }
                } else {
                  prev = prev.concat(iMessage);
                }
                return uniqueVals(prev, (message) => message._id.toString());
              });
            }
          }
        });
      });
      return () => unsubscribe();
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (conversationUser) {
      setLoadingConversation(true);
      const timeout = setTimeout(() => {
        // Show loading dots if converation messages take longer than three seconds to load
        if (loadingConversation) {
          setShowLoadingState(true);
        }
      }, 3000);
      await fetchConversationMessages(false);
      clearTimeout(timeout);
      setLoadingConversation(false);
      setShowLoadingState(false);
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationUser]);

  useEffect(() => {
    if (
      selectedConversation &&
      selectedConversation.chatIndicator.some(
        (member) => member.indicator && member.uid === accountData.uid
      )
    ) {
      console.log("Marking read...");
      dispatch(
        updateChatConversationIndicator(
          selectedConversation.conversationSid,
          accountData.uid,
          false
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversation]);

  const selectNewConversation = useCallback(
    async (item: AccountData) => {
      setMessages([]);
      setConversationUser(item);
      setNewUserSearchTerm("");
    },
    [setConversationUser, setNewUserSearchTerm]
  );

  const renderProfileViewButton = (item: AccountData) => {
    return (
      <div
        style={styles.profileViewButton}
        onClick={(e) => {
          setProfilePreviewSelected(item);
          e.stopPropagation();
        }}
      >
        <span className="bodyMedium">View</span>
      </div>
    );
  };

  const renderLoadingConversation = useMemo(
    () =>
      showLoadingState ? (
        <div className="ColumnCentering" style={{ gap: 24, height: "100%" }}>
          <CircularProgress style={{ color: Colors.BLACK }} size={38} />
          <div
            className="ColumnNormal"
            style={{ gap: 14, textAlign: "center" }}
          >
            <span className="sectionTitle">Loading Chat</span>
            <span className="bodySubtext">This will only take a second</span>
          </div>
        </div>
      ) : (
        <div />
      ),
    [showLoadingState]
  );

  return (
    <div>
      {/* Conversation Header */}
      <div className="AlignedRow ConversationsMainViewHeader">
        {!conversationUser ? (
          <div
            ref={ref}
            className="AlignedRow"
            style={{ position: "relative", flexGrow: 1 }}
          >
            <div className="AlignedRow" style={{ flexGrow: 1 }}>
              <span className="sectionTitle">To:</span>
              <TextareaAutosize
                value={newUserSearchTerm}
                placeholder={""}
                onChange={(e) => handleSearch(e.target.value)}
                className="ConversationsInput bodyMedium"
                maxRows={1}
              />
            </div>
            {followersToShow.length > 0 ? (
              <div
                className="ColumnNormal"
                style={{
                  position: "absolute",
                  top: 50,
                  left: 20,
                  padding: 10,
                  borderRadius: 8,
                  minWidth: 340,
                  boxShadow: "0px 1px 6px 0px #b9b9b9",
                  backgroundColor: Colors.WHITE,
                }}
              >
                {followersToShow.map((follower) => {
                  return (
                    <div
                      onClick={() => selectNewConversation(follower)}
                      className="AlignedRowSpacedSelect ConversationSidebarItem"
                      style={{ borderRadius: 8, paddingInline: 7 }}
                      key={follower.uid}
                    >
                      <div className="AlignedRow" style={{ gap: 10 }}>
                        <ProfilePic user={follower} size={40} />
                        <span
                          className="AboutSubtitle"
                          style={{ fontSize: 14 }}
                        >
                          {follower.fullName}
                        </span>
                      </div>
                      {renderProfileViewButton(follower)}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        ) : conversationUser ? (
          <div className="AlignedRowSpaced" style={{ flexGrow: 1 }}>
            <span className="sectionTitle">{conversationUser.fullName}</span>
            {renderProfileViewButton(conversationUser)}
          </div>
        ) : null}
      </div>
      {/* Conversation Messages */}
      <div
        className="ColumnNormal"
        style={{
          height: "calc(100vh - 230px)",
          justifyContent: "space-between",
        }}
      >
        {!loadingConversation ? (
          <ConversationsMessagesContainer
            userId={accountData.uid}
            messages={messages}
            conversationsMainUser={conversationUser}
            fetchedAllMessages={fetchedAllMessages}
            fetchConversationMessages={(increment: boolean) =>
              fetchConversationMessages(increment)
            }
            loadingMoreMessages={loadingMoreMessages}
            scrollableFeedRef={scrollableFeedRef}
          />
        ) : (
          renderLoadingConversation
        )}
        <div
          className="AlignedRowSpaced"
          style={{ marginTop: 10, marginBottom: 14 }}
        >
          <ConversationMessageInput
            conversationsMainUser={conversationUser}
            scrollToBottom={scrollToBottom}
          />
        </div>
      </div>
      {profilePreviewSelected ? (
        <FullProfilePreviewModal
          profileSelected={profilePreviewSelected}
          setProfileSelected={setProfilePreviewSelected}
        />
      ) : null}
    </div>
  );
};

export default ConversationsMainView;
