import "../../../css/ProfilePage/ProfilePage.css";
import "../../../css/GlobalStyles.css";
import { memo, useMemo, useRef } from "react";
import TopHeader from "../../../components/TopHeader";
import Footer from "../../../components/Footer";
import { Helmet } from "react-helmet";
import {
  useParametricSelector,
  useProfileSelector,
} from "../../../hooks/useParametricSelector";
import { getLoadedUserByUsername } from "../../../redux/slices/dataSlice";
import { useSelector } from "react-redux";
import LoadingScreen from "../../LoadingScreen";
import {
  LoginState,
  getAccountState,
} from "../../../redux/slices/accountSlice";
import ProfilePageFull from "../../../components/ProfilePage/ProfilePageFull";
import { Colors } from "../../../utils/colors";

const ProfilePage = memo(function ProfilePageFn() {
  const { accountData, loggedIn, appInitialized } =
    useSelector(getAccountState).account;

  const username = useProfileSelector();
  const {
    isLoading: isLoadingUserData,
    data: userData,
    isError: isErrorUserData,
  } = useParametricSelector(getLoadedUserByUsername, username);

  const refContainer = useRef<HTMLDivElement>(null);

  const isMyAccount = useMemo(
    () =>
      loggedIn === LoginState.LOGGED_IN && accountData.uid === userData?.uid,
    [accountData.uid, loggedIn, userData?.uid]
  );

  const userDataToShow = useMemo(
    () => (isMyAccount ? accountData : userData),
    [accountData, isMyAccount, userData]
  );

  if (
    isLoadingUserData ||
    (loggedIn === LoginState.LOGGED_IN && !appInitialized) ||
    !userDataToShow
  ) {
    return (
      <LoadingScreen
        isLoadingPage={
          isLoadingUserData ||
          (loggedIn === LoginState.LOGGED_IN && !appInitialized) ||
          !userDataToShow
        }
      />
    );
  }

  if (isErrorUserData) {
    return <LoadingScreen error />;
  }

  const title = `${userDataToShow.fullName} (@${userDataToShow.username}) on Markit`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="og:title" content={title} />
        <meta name="og:description" content={userDataToShow.username} />
      </Helmet>
      <div ref={refContainer}>
        <div className="ProfileWrapper">
          <div className="ProfileHeaderDesktop">
            <TopHeader showRCA />
          </div>
          <ProfilePageFull userData={userDataToShow} />
        </div>
        <Footer alternateBackgroundColor={Colors.WHITE1} />
      </div>
    </>
  );
});

export default ProfilePage;
