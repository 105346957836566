import React, { useState } from "react";
import { Colors } from "../../utils/colors";
import { Carousel } from "react-responsive-carousel";

export interface CarouselItem {
  image: string;
  title: string;
  width?: number; // must pass in width if height is not passed in
  height?: number; // must pass in height if width is not passed in
}

type CarouselContainerProps = {
  carouselItems: CarouselItem[];
  dark?: boolean;
  enableSwipe?: boolean;
  containerStyles?: React.CSSProperties;
};

const CarouselContainer = (props: CarouselContainerProps) => {
  const { carouselItems, dark, enableSwipe, containerStyles } = props;

  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div
      className="ColumnCentering"
      style={{
        gap: 30,
        margin: "auto",
        overflow: "hidden",
        ...containerStyles,
      }}
    >
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        stopOnHover={false}
        autoPlay={true}
        infiniteLoop
        interval={5000}
        transitionTime={500}
        swipeable={!!enableSwipe}
        emulateTouch={!!enableSwipe}
        onChange={(index) => setCurrentIndex(index)}
        selectedItem={currentIndex}
      >
        {carouselItems.map((item, index) => (
          <div
            key={index}
            className="ColumnCentering"
            style={{
              gap: 40,
              paddingInline: 20,
            }}
          >
            <img
              src={item.image}
              alt={item.title}
              style={{
                maxWidth: item.width ? item.width : "100%",
                height: item.height ? item.height : "100%",
                objectFit: "contain",
              }}
            />
            {item.title ? (
              <span
                className="sectionTitle"
                style={{
                  color: dark ? Colors.WHITE : Colors.BLACK,
                  maxWidth: 327,
                  wordWrap: "break-word",
                }}
              >
                {item.title}
              </span>
            ) : null}
          </div>
        ))}
      </Carousel>
      <div className="AlignedRow" style={{ gap: 7, justifyContent: "center" }}>
        {carouselItems.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            disabled={!enableSwipe}
            style={{
              width: 10,
              height: 10,
              borderRadius: 100,
              backgroundColor:
                currentIndex === index
                  ? dark
                    ? Colors.GRAY15
                    : Colors.BLACK
                  : dark
                  ? Colors.BLACK1
                  : Colors.GRAY11,
              cursor: enableSwipe ? "pointer" : "default",
              border: "none",
              padding: 0,
              transition: "background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CarouselContainer;
