import TopHeader from "../../components/TopHeader";
import Footer from "../../components/Footer";
import { Colors } from "../../utils/colors";
import ProfilePageHeader from "../../components/ProfilePage/ProfilePageHeader";
import ProfileAccountDetails from "../../components/CreatorDashboard/CreatorProfile/ProfileAccountDetails";
import StandardBorderedContainer from "../../components/Containers/StandardBorderedContainer";
import OSPills from "../../components/OSPills";
import { ImportantAnnouncementModal } from "../../components/DisplayModal/ImportantAnnouncementModal";
import { useLocation } from "react-router-dom";
import { NavigationId } from "../../navigation/AppParamList";
import BillingHistoryMobile from "../../components/MobileCreatorMode/BillingHistoryMobile";

// TODO (jonathan): can eventually expand this as we show more home navigation on mobile
const HomePageMobile = () => {
  const { pathname } = useLocation();

  if (pathname === NavigationId.HOME_BILLING) {
    return <BillingHistoryMobile />;
  }
  return (
    <div style={{ transition: "0.5s" }}>
      <TopHeader />
      <div className="ColumnNormal" style={{ gap: 14, paddingTop: 20 }}>
        <ProfilePageHeader followerData={undefined} isLoading={false} />
        <div
          className="ColumnNormal"
          style={{
            backgroundColor: Colors.WHITE1,
            paddingInline: 14,
            paddingBlock: 24,
            gap: 14,
          }}
        >
          <StandardBorderedContainer
            containerStyles={{
              padding: 14,
              borderRadius: 14,
              backgroundColor: Colors.WHITE,
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <span style={{ fontSize: 14, fontWeight: 500 }}>Get the app</span>
              <span style={{ fontSize: 12, color: Colors.GRAY2, width: "70%" }}>
                Download the app to use Markit on the go or experience Markit on
                desktop.
              </span>
            </div>
            <OSPills alternateTextSize={12} />
          </StandardBorderedContainer>
          <ProfileAccountDetails />
        </div>
      </div>
      <Footer />
      <ImportantAnnouncementModal />
    </div>
  );
};

export default HomePageMobile;
