import "../../css/ProfilePage/ProfilePage.css";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  AccountData,
  Follower,
  FollowerMembershipState,
  FollowerStatus,
} from "@markit/common.types";
import { isDesktop } from "react-device-detect";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import {
  handleLinkPressed,
  hasSubscriptionUnlockedAdvancedData,
} from "@markit/common.utils";
import ProfilePic from "../ProfilePic";
import ProfilePageEditProfile from "./ProfilePageEditProfile";
import { CopyCustomLinkify } from "../Links/CopyCustomLinkify";
import AlertContainer from "../Containers/AlertContainer";
import RectangleButton from "../Buttons/RectangleButton";
import ShareProfileLinkModal from "../CreatorDashboard/CreatorProfile/ShareProfileLinkModal";
import ProfileSettingsDropdown from "../CreatorDashboard/CreatorProfile/ProfileSettingsDropdown";
import { onProfilePageNavigatePath } from "../../utils/navigationUtils";
import { useNavigate } from "react-router-dom";
import { ProfileFollowingActionsButton } from "../Buttons/ProfileFollowingActionsButton";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import Skeleton from "react-loading-skeleton";
import PaymentFailedButton from "../Subscription/PaymentFailedButton";
import MembershipPaymentFailedButtonModal from "../Subscription/Memberships/MembershipPaymentFailedButtonModal";

export enum SocialMediaType {
  INSTAGRAM = "instagram",
  LINKEDIN = "linkedin",
  TWITTER = "twitter",
  TIKTOK = "tiktok",
  SPOTIFY = "spotify",
}

type ProfilePageHeaderProps = {
  followerData: Follower | undefined;
  otherUserData?: AccountData;
  isLoading: boolean;
};

const ProfilePageHeader = memo(function ProfilePageHeaderFn({
  followerData,
  otherUserData,
  isLoading,
}: ProfilePageHeaderProps) {
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [shareModal, setShareModal] = useState(false);
  const [paymentFailedModal, setPaymentFailedModal] = useState(false);
  const styles = {
    container: {
      paddingInline: 14,
    },
    headerButton: {
      paddingInline: 40,
      paddingBlock: 14,
      flex: 1,
      whiteSpace: "nowrap",
    },
    settingsContainer: {
      cursor: "pointer",
      width: 45,
      height: 45,
      borderRadius: 12,
      border: `0.5px solid ${Colors.GRAY2}`,
      backgroundColor: Colors.WHITE1,
    },
  };

  const userData = useMemo(
    () => otherUserData || accountData,
    [accountData, otherUserData]
  );

  const isMyAccount = useMemo(
    () =>
      loggedIn === LoginState.LOGGED_IN && accountData.uid === userData?.uid,
    [accountData.uid, loggedIn, userData?.uid]
  );

  const onShareProfile = useCallback(() => {
    setShareModal(true);
  }, []);

  const navigateSubscribe = useCallback(
    () => onProfilePageNavigatePath(navigate, userData.username, "subscribe"),
    [navigate, userData.username]
  );

  const renderSocialMedia = useCallback(
    (type: SocialMediaType, link: string, iconName: string) => {
      return (
        <div
          className="ProfileBrowserSocialMediaIcon"
          style={{ marginBottom: -3 }}
          onClick={() => window.open(handleLinkPressed(type, link), "_blank")}
        >
          <Icon icon={iconName} height={16} style={{ color: Colors.GRAY3 }} />
        </div>
      );
    },
    []
  );

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const menuItems: DropdownMenuItem[] = [
      {
        title: "Share Profile",
        icon: "ion:share",
        key: "share_profile",
        onPress: () => setShareModal(true),
      },
    ];

    return menuItems;
  }, []);

  return (
    <>
      <div style={{ width: isDesktop ? 544 : "100%" }}>
        <div
          className="ColumnNormal"
          style={{ paddingInline: isDesktop ? 0 : 14, gap: 20 }}
        >
          <div className="AlignedRow" style={{ gap: isDesktop ? 30 : 14 }}>
            <div>
              <ProfilePic
                user={userData}
                size={isDesktop ? 100 : 80}
                borderStyle="1px solid #F4F5F6"
              />
            </div>
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <span style={{ fontSize: 20, fontWeight: 500 }}>
                {userData.fullName}
              </span>
              <div className="AlignedRow" style={{ gap: 4 }}>
                <span className="bodySubtext">@{userData.username}</span>
                {hasSubscriptionUnlockedAdvancedData(userData) ? (
                  <VerifiedIcon
                    style={{ color: Colors.BLUE5 }}
                    sx={{ fontSize: 16 }}
                  />
                ) : null}
              </div>
              <div className="AlignedRow" style={{ gap: 8 }}>
                {userData.instagram !== ""
                  ? renderSocialMedia(
                      SocialMediaType.INSTAGRAM,
                      userData.instagram,
                      "mdi:instagram"
                    )
                  : null}
                {userData.twitter !== ""
                  ? renderSocialMedia(
                      SocialMediaType.TWITTER,
                      userData.twitter,
                      "ri:twitter-x-fill"
                    )
                  : null}
                {userData.linkedin !== ""
                  ? renderSocialMedia(
                      SocialMediaType.LINKEDIN,
                      userData.linkedin,
                      "ri:linkedin-fill"
                    )
                  : null}
                {userData.tiktok !== ""
                  ? renderSocialMedia(
                      SocialMediaType.TIKTOK,
                      userData.tiktok,
                      "ic:baseline-tiktok"
                    )
                  : null}
                {userData.spotify !== ""
                  ? renderSocialMedia(
                      SocialMediaType.SPOTIFY,
                      userData.spotify,
                      "mdi:spotify"
                    )
                  : null}
              </div>
            </div>
          </div>
          {userData.bio || userData.profileLink ? (
            <>
              <div className="ColumnNormal" style={{ gap: 7 }}>
                {userData.bio !== "" ? (
                  <span style={{ fontSize: 14 }}>{userData.bio}</span>
                ) : null}
                {userData.profileLink !== "" ? (
                  <CopyCustomLinkify text={userData.profileLink} />
                ) : null}
              </div>
            </>
          ) : null}
          {isLoading ? (
            <Skeleton height={44} baseColor={Colors.WHITE1} borderRadius={12} />
          ) : !isMyAccount ? (
            !followerData ||
            followerData.status !== FollowerStatus.SUBSCRIBED ? (
              <div className="AlignedRow" style={{ gap: 10 }}>
                <RectangleButton
                  buttonLabel="Subscribe"
                  onPress={navigateSubscribe}
                  altColor={Colors.RED2}
                  containerStyles={styles.headerButton}
                />
                <DropdownMenu
                  trigger={["click"]}
                  placement="bottomRight"
                  altWidth={200}
                  dropdownItems={dropdownItems}
                >
                  <div className="Centering" style={styles.settingsContainer}>
                    <Icon icon="ion:ellipsis-horizontal" height={21} />
                  </div>
                </DropdownMenu>
              </div>
            ) : (
              <div className="ColumnNormal" style={{ gap: 20 }}>
                <div className="AlignedRow" style={{ gap: 10 }}>
                  {otherUserData ? (
                    <div style={{ flex: 1 }}>
                      <ProfileFollowingActionsButton
                        followerData={followerData}
                        creatorData={otherUserData}
                      />
                    </div>
                  ) : null}
                  <div style={{ flex: 1 }}>
                    <RectangleButton
                      buttonLabel="Share Profile"
                      onPress={onShareProfile}
                      altColor={Colors.WHITE1}
                      altTextColor={Colors.BLACK}
                      containerStyles={{
                        ...styles.headerButton,
                        border: `0.5px solid ${Colors.GRAY2}`,
                      }}
                    />
                  </div>
                </div>
                {followerData &&
                followerData.membershipState ===
                  FollowerMembershipState.PAYMENT_FAILED ? (
                  <PaymentFailedButton
                    type="Subscriber"
                    onPress={() => setPaymentFailedModal(true)}
                  />
                ) : null}
              </div>
            )
          ) : (
            <div className="AlignedRow" style={{ gap: 8 }}>
              <RectangleButton
                buttonLabel="Share Profile"
                onPress={onShareProfile}
                altColor={Colors.RED2}
                containerStyles={styles.headerButton}
              />
              <RectangleButton
                buttonLabel="Edit Profile"
                onPress={() => setIsEditing(true)}
                altColor={Colors.WHITE1}
                altTextColor={Colors.BLACK}
                containerStyles={{
                  ...styles.headerButton,
                  border: `0.5px solid ${Colors.GRAY2}`,
                }}
              />
              <ProfileSettingsDropdown />
            </div>
          )}
        </div>
      </div>
      <AlertContainer
        headerComp={alertText}
        closeModal={() => setAlertText("")}
        hideModal={alertText === ""}
      />
      {isEditing ? (
        <ProfilePageEditProfile
          isVisible={isEditing}
          setIsVisible={setIsEditing}
        />
      ) : null}
      {shareModal ? (
        <ShareProfileLinkModal
          username={userData.username}
          setShareLinkModal={setShareModal}
        />
      ) : null}
      {paymentFailedModal && followerData ? (
        <MembershipPaymentFailedButtonModal
          setIsVisible={setPaymentFailedModal}
          followerDataMap={new Map([[userData.uid, followerData]])}
          followingAccountDataFollowing={[userData]}
        />
      ) : null}
    </>
  );
});

export default ProfilePageHeader;
