import { useState } from "react";
import CreatorModePanelWrapper from "../../../../components/CreatorDashboard/CreatorModePanelWrapper";
import { Colors } from "../../../../utils/colors";
import MembershipSettings from "../../../../components/CreatorDashboard/Memberships/MembershipSettings";
import MembershipMembers from "../../../../components/CreatorDashboard/Memberships/MembershipMembers";
import FatHeaderActionButton from "../../../../components/Buttons/FatHeaderActionButton";
import { Icon } from "@iconify/react";
import {
  convertToMoney,
  hasSubscriptionUnlockedAdvancedData,
} from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { MembershipState } from "@markit/common.types";
import useAsyncOnMount from "../../../../hooks/useAsyncEffectOnMount";
import { getFollowerSubscriptionsMonthlyRevenue } from "../../../../utils/followerSubscriptionUtils";
import ShareMembershipUpgradeLinkModal from "../../../../components/CreatorDashboard/CreatorProfile/ShareMembershipUpgradeLinkModal";

enum MembershipTabs {
  MEMBERS = 0,
  SETTINGS = 1,
}

const MembershipsPanel = () => {
  const { accountData } = useSelector(getAccountState).account;
  const [tabValue, setTabValue] = useState<MembershipTabs>(
    MembershipTabs.MEMBERS
  );
  const [listValue, setListValue] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [shareUpgradeLink, setShareUpgradeLink] = useState(false);

  const styles = {
    bodyContainer: {
      backgroundColor:
        tabValue === MembershipTabs.MEMBERS ? Colors.WHITE : Colors.WHITE1,
      paddingTop: 30,
      height: "100%",
    },
  };

  useAsyncOnMount(async () => {
    const totalMonthRevenue = await getFollowerSubscriptionsMonthlyRevenue(
      accountData.uid,
      accountData.membership.membershipPlans
    );
    setTotalRevenue(totalMonthRevenue);
  });

  return (
    <CreatorModePanelWrapper
      title={"Memberships"}
      headerTabs={{
        tabNames: ["Members", "Settings"],
        tabValue: tabValue,
        setTabValue: setTabValue,
      }}
      headerRight={
        accountData.membership.state === MembershipState.ACTIVE &&
        hasSubscriptionUnlockedAdvancedData(accountData) ? (
          <div className="AlignedRow" style={{ gap: 20 }}>
            {totalRevenue > 0 ? (
              <div className="ColumnNormal" style={{ alignItems: "center" }}>
                <span style={{ fontSize: 28 }}>
                  {convertToMoney(totalRevenue)}
                </span>
                <span className="smallBodySubtext">
                  Monthly Subscription Revenue
                </span>
              </div>
            ) : null}
            <div>
              <FatHeaderActionButton
                title="Share Upgrade Link"
                icon={
                  <Icon
                    icon="ion:share"
                    height={19}
                    color={Colors.BLACK}
                    style={{ paddingBottom: 2 }}
                  />
                }
                onPress={() => setShareUpgradeLink(true)}
                containerStyles={{ paddingBlock: 0, height: 46 }}
              />
            </div>
          </div>
        ) : null
      }
    >
      <div style={styles.bodyContainer}>
        <MembershipMembers
          tabValue={tabValue}
          setTabValue={setTabValue}
          listValue={listValue}
          setListValue={setListValue}
        />
        <MembershipSettings
          tabValue={tabValue}
          setTabValue={setTabValue}
          setListValue={setListValue}
        />
      </div>
      {shareUpgradeLink ? (
        <ShareMembershipUpgradeLinkModal
          username={accountData.username}
          setShareLinkModal={setShareUpgradeLink}
        />
      ) : null}
    </CreatorModePanelWrapper>
  );
};

export default MembershipsPanel;
