import { useCallback, useMemo, useState } from "react";
import { filterUndefinedValues, uniqueVals } from "@markit/common.utils";
import { AccountData, Follower, FollowerStatus } from "@markit/common.types";
import { fetchSingleFollower, fetchSingleUser } from "../utils/FetchSingleData";
import { algoliaClient } from "../utils/algoliaUtils";
import { accountActions, getAccountState } from "../redux/slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import useOnUnmount from "./useOnUnmount";

type useLoadUserListProps = {
  userIdList: string[];
  creatorUid?: string; // not currently used, but can use this parameter to filter a userIdList by follower status of a creator
  followerStatus?: FollowerStatus; // not currently used, but can use this parameter to filter a userIdList by follower status of a creator
  windowSize: number;
  callbackFn?: (accountData: AccountData[]) => void;
};
export const useLoadUserList = (props: useLoadUserListProps) => {
  const { userIdList, creatorUid, followerStatus, windowSize, callbackFn } =
    props;

  const { followingAccountData, followersData } =
    useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [allFetchedUserData, setAllFetchedUserData] = useState<AccountData[]>(
    []
  );
  const [fetchedFollowerData, setFetchedFollowerData] = useState<Follower[]>(
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  useOnUnmount(
    (latestDeps) => {
      dispatch(
        accountActions.addMultipleFollowingAccountData(
          latestDeps[0] as AccountData[]
        )
      );
      dispatch(
        accountActions.addMultipleFollowerData(latestDeps[1] as Follower[])
      );
    },
    [allFetchedUserData, fetchedFollowerData]
  );

  const fetchedUserData = useMemo(
    () =>
      allFetchedUserData.filter((userData) =>
        userIdList.includes(userData.uid)
      ),
    [allFetchedUserData, userIdList]
  );

  const addUserData = useCallback(
    (newUserData: AccountData[]) => {
      setAllFetchedUserData((allFetchedUserData) =>
        uniqueVals(
          allFetchedUserData.concat(newUserData),
          (userData) => userData.uid
        )
      );
      callbackFn && callbackFn(newUserData);
    },
    [callbackFn]
  );

  const addFollowerData = useCallback((newFollowerData: Follower[]) => {
    setFetchedFollowerData((allFetchedUserData) =>
      uniqueVals(
        newFollowerData.concat(allFetchedUserData),
        (followerData) => followerData.uid
      )
    );
  }, []);

  const checkIfAddUserData = useCallback(
    async (uids: string[]) => {
      const userResults = await Promise.all(
        uids.map(async (uid) => {
          const userData = await fetchSingleUser(uid, followingAccountData);
          if (!userData) {
            return { userData: undefined, followerData: undefined };
          }
          if (followerStatus && creatorUid) {
            const followerData = await fetchSingleFollower(
              creatorUid,
              userData.uid,
              followersData
            );
            if (followerData && followerData.status === followerStatus) {
              return { userData, followerData };
            }
            return { userData: undefined, followerData: undefined };
          } else {
            return { userData, followerData: undefined };
          }
        })
      );
      const users: AccountData[] = filterUndefinedValues(
        userResults.map((result) => result.userData)
      );
      addUserData(users);
      const followers: Follower[] = filterUndefinedValues(
        userResults.map((result) => result.followerData)
      );
      addFollowerData(followers);
    },
    [
      addFollowerData,
      addUserData,
      creatorUid,
      followerStatus,
      followersData,
      followingAccountData,
    ]
  );

  const loadAndStoreUsers = useCallback(async () => {
    setIsLoading(true);
    const usersToFetch = userIdList
      .slice(0, allFetchedUserData.length + windowSize)
      .filter(
        (userId) =>
          !allFetchedUserData.some((userData) => userData.uid === userId)
      );
    await checkIfAddUserData(usersToFetch);
    if (usersToFetch.length < windowSize) {
      setIsFinished(true);
    }

    setIsLoading(false);
  }, [checkIfAddUserData, allFetchedUserData, userIdList, windowSize]);

  const loadMoreUsers = useCallback(async () => {
    if (userIdList.length === 0) return;
    if (!isFinished && !isLoading) {
      loadAndStoreUsers();
    }
  }, [userIdList.length, isFinished, isLoading, loadAndStoreUsers]);

  // When the users displayed need to be manually refetched due to a state change of userIdList
  const reloadMoreUsers = useCallback(async () => {
    setIsFinished(false);
    setAllFetchedUserData([]);
  }, []);

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      setIsLoading(true);
      if (!isFinished) {
        const results = (
          await algoliaClient.searchSingleIndex({
            indexName: "users",
            searchParams: { query: searchTerm },
          })
        ).hits;

        const userIds = results
          .map((obj: any) => obj.objectID)
          .filter(
            (uid: string) =>
              userIdList.includes(uid) &&
              !allFetchedUserData.some((userData) => userData.uid === uid)
          );

        await checkIfAddUserData(userIds);
      }
      setIsLoading(false);
    },
    [checkIfAddUserData, allFetchedUserData, isFinished, userIdList]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          loadSearchResults(searchTerm);
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );

  return {
    isFinished,
    isLoading,
    fetchedUserData,
    loadMoreUsers,
    reloadMoreUsers,
    loadSearchResultsThrottled,
  };
};
