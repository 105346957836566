import { ReactNode } from "react";
import MarkitLogoBlue from "../../assets/MarkitLogoBlue.png";
import { isDesktop } from "react-device-detect";

type LoginWelcomeBodyProps = {
  title: string;
  subtext: string | ReactNode;
  alternateIcon?: ReactNode;
  children: ReactNode;
};

const LoginWelcomeBody = (props: LoginWelcomeBodyProps) => {
  const { title, subtext, alternateIcon, children } = props;

  return (
    <>
      <div
        className={isDesktop ? "ColumnCentering" : "ColumnNormal"}
        style={{ gap: 20, paddingInline: isDesktop ? 0 : 14 }}
      >
        {alternateIcon ?? (
          <div>
            <img
              style={{ height: 20, width: "auto", objectFit: "cover" }}
              src={MarkitLogoBlue}
              alt={"markitLogoBlue"}
            />
          </div>
        )}
        <div
          className={isDesktop ? "ColumnCentering" : "ColumnNormal"}
          style={{ gap: 12, width: isDesktop ? 500 : undefined }}
        >
          <span style={{ fontSize: 20, fontWeight: 500 }}>{title}</span>
          <span
            className="smallBodySubtext"
            style={{ textAlign: isDesktop ? "center" : undefined }}
          >
            {subtext}
          </span>
        </div>
      </div>
      <div
        className="ColumnNormal"
        style={{
          width: isDesktop ? 330 : undefined,
          margin: isDesktop ? "auto" : undefined,
          paddingInline: isDesktop ? 0 : 14,
          gap: 20,
          alignItems: "center",
        }}
      >
        {children}
      </div>
    </>
  );
};

export default LoginWelcomeBody;
