import "../../css/GlobalStyles.css";
import { memo, useCallback, useMemo, useState } from "react";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import { AudienceList } from "@markit/common.types";
import BlackHoverButton from "../Buttons/BlackHoverButton";
import AudienceListDetailsModal from "../CreatorDashboard/CreatorProfile/AudienceLists/AudienceListDetailsModal";
import { FAVORITES_LIST_NAME } from "@markit/common.utils";

type AudienceListDropdownButtonProps = {
  list: AudienceList;
  setSelectRecipientsVisible: (selectRecipientsVisible: boolean) => void;
};

// Dropdown button on the AudienceListDashboard
const AudienceListDropdownButton = memo(function AudienceListDropdownButtonFn(
  props: AudienceListDropdownButtonProps
) {
  const { list, setSelectRecipientsVisible } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [listPopupVisible, setListPopupVisible] = useState(false);

  const styles = {
    headerRightButton: {
      paddingInline: 20,
      paddingBlock: 14,
      borderRadius: 100,
      whiteSpace: "nowrap",
    },
  };

  const editPeopleOnPress = useCallback(() => {
    if (setSelectRecipientsVisible) {
      setSelectRecipientsVisible(true);
    }
  }, [setSelectRecipientsVisible]);

  const editListDetailsOnPress = useCallback(() => {
    setListPopupVisible(true);
  }, []);

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const menuItems: DropdownMenuItem[] = [
      {
        title: "Add & Exclude People",
        icon: "ion:person-add",
        key: "add_exclude",
        onPress: editPeopleOnPress,
      },
    ];
    if (list.name !== FAVORITES_LIST_NAME) {
      menuItems.push({
        title: "Edit List Details",
        icon: "ion:list",
        key: "edit_list_details",
        onPress: editListDetailsOnPress,
      });
    }

    return menuItems;
  }, [editListDetailsOnPress, editPeopleOnPress, list.name]);

  return (
    <>
      <DropdownMenu
        trigger={["click"]}
        placement="bottomRight"
        altWidth={200}
        dropdownItems={dropdownItems}
        onOpenChange={(isOpen: boolean) => setShowDropdown(isOpen)}
      >
        <BlackHoverButton
          title={"Manage List"}
          iconName={showDropdown ? "ion:chevron-up" : "ion:chevron-down"}
          iconSize={16}
          permanentHover={true}
          onPress={() => {}}
          containerStyles={styles.headerRightButton}
        />
      </DropdownMenu>
      {listPopupVisible ? (
        <AudienceListDetailsModal
          isVisible={listPopupVisible}
          setIsVisible={setListPopupVisible}
          existingList={list}
        />
      ) : null}
    </>
  );
});

export default AudienceListDropdownButton;
