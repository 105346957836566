import React, { ReactNode, useCallback, useMemo, useState } from "react";
import "../../css/CreateEvent.css";
import {
  FormInputType,
  NotificationType,
  SavedFormQuestion,
} from "@markit/common.types";
import { LightTheme, ThemeStyle } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import { IoAddCircleOutline, IoMenuOutline } from "react-icons/io5";
import { Icon } from "@iconify/react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CreateEventSectionHeader from "../CreateEvent/CreateEventSectionHeader";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import { questionInputName } from "../../utils/eventUtils/formUtils";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../redux/store";
import {
  LoginState,
  accountActions,
  removeSingleProfileQuestion,
  reorderProfileQuestions,
} from "../../redux/slices/accountSlice";
import { filterUndefinedValues } from "@markit/common.utils";
import FormSavedQuestions, {
  FormSavedQuestionsType,
} from "./FormSavedQuestions";
import FormBuilder from "./FormBuilder";
import AlertContainer from "../Containers/AlertContainer";
import ConfirmDeleteModal from "../Containers/ConfirmPopups/ConfirmDeleteModal";
import { showNotificationBanner } from "../../utils/notificationUtils";
import EmptyStateButton from "../Buttons/EmptyStateButton";

interface FormBuilderWrapperProps {
  isProfileQuestions: boolean;
  hostId: string;
  theme?: ThemeStyle;
  altHeader?: ReactNode;
  altFooter?: ReactNode;
  altItemsColor?: string;
}

const FormBuilderWrapper = (props: FormBuilderWrapperProps) => {
  const {
    isProfileQuestions,
    hostId,
    theme,
    altHeader,
    altFooter,
    altItemsColor,
  } = props;
  const { currentEventFormQuestions, savedQuestions, accountData, loggedIn } =
    useSelector((state: AppState) => state.account);
  const dispatch = useDispatch();
  const [isMinimized, setIsMinimized] = useState(false);
  const [savedQuestionsVisible, setSavedQuestionsVisible] = useState(false);
  const [formQuestionItem, setFormQuestionItem] = useState<SavedFormQuestion>();
  const [deleteFormQuestionItem, setDeleteFormQuestionItem] =
    useState<SavedFormQuestion>();
  const [alertText, setAlertText] = useState("");

  const profileFormQuestions = useMemo(() => {
    const formQuestions = accountData.formQuestions.map((questionId) =>
      savedQuestions.find((question) => question.id === questionId)
    );
    const formQuestionsDefined: SavedFormQuestion[] =
      filterUndefinedValues(formQuestions);
    return formQuestionsDefined;
  }, [accountData.formQuestions, savedQuestions]);

  const currentFormQuestions = useMemo(
    () =>
      isProfileQuestions ? profileFormQuestions : currentEventFormQuestions,
    [currentEventFormQuestions, isProfileQuestions, profileFormQuestions]
  );

  const showCohostAlert = useCallback(() => {
    if (!isProfileQuestions && hostId !== accountData.uid) {
      setAlertText("Cohost cannot modify form questions");
      return true;
    }
    return false;
  }, [accountData.uid, hostId, isProfileQuestions]);

  const handleOnDrag = useCallback(
    (result: any) => {
      if (!result.destination) return;

      const reorderedItem = currentFormQuestions[parseInt(result.source.index)];
      const newFormQuestions = [...currentFormQuestions];
      newFormQuestions.splice(result.source.index, 1);
      newFormQuestions.splice(result.destination.index, 0, reorderedItem);

      if (isProfileQuestions) {
        dispatch(
          reorderProfileQuestions(
            hostId,
            newFormQuestions.map((question) => question.id)
          )
        );
      } else {
        dispatch(
          accountActions.reorderCurrentEventFormQuestions(newFormQuestions)
        );
      }
    },
    [currentFormQuestions, dispatch, hostId, isProfileQuestions]
  );

  const newQuestionOnPress = useCallback(
    (currQuestion?: SavedFormQuestion) => {
      if (!showCohostAlert()) {
        if (currQuestion) {
          setFormQuestionItem(currQuestion);
        } else {
          setSavedQuestionsVisible(true);
        }
      }
    },
    [showCohostAlert]
  );

  const deleteQuestionOnPress = useCallback(
    (currQuestion: SavedFormQuestion) => {
      if (currQuestion && !showCohostAlert()) {
        setDeleteFormQuestionItem(currQuestion);
      }
    },
    [showCohostAlert]
  );

  const deleteFormQuestion = useCallback(() => {
    if (!deleteFormQuestionItem) {
      setAlertText("There was an issue deleting this question.");
      return;
    }

    if (isProfileQuestions) {
      dispatch(removeSingleProfileQuestion(hostId, deleteFormQuestionItem.id));
    } else {
      const updatedFormQuestions = currentFormQuestions.filter(
        (question) => question.id !== deleteFormQuestionItem.id
      );
      dispatch(
        accountActions.reorderCurrentEventFormQuestions(updatedFormQuestions)
      );
    }
    setDeleteFormQuestionItem(undefined);
    showNotificationBanner(
      dispatch,
      "Question Removed",
      NotificationType.NEGATIVE
    );
  }, [
    deleteFormQuestionItem,
    isProfileQuestions,
    dispatch,
    hostId,
    currentFormQuestions,
  ]);

  return (
    <>
      {altHeader ?? (
        <div>
          <CreateEventSectionHeader
            title="Collect Info"
            description={
              isProfileQuestions
                ? "Collect additional subscriber data on initial subscribe"
                : "Add questions to collect attendee data like phone numbers and emails"
            }
            isMinimized={isMinimized}
            setIsMinimized={setIsMinimized}
            theme={theme}
          />
        </div>
      )}
      <AnimatePresence>
        {!isMinimized ? (
          <LazyMotion features={domAnimation}>
            <m.div
              style={{ marginTop: altHeader ? 0 : "14px" }}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2, ease: "easeIn" }}
              className="HideScrollbar"
            >
              <DragDropContext onDragEnd={(result) => handleOnDrag(result)}>
                <Droppable droppableId="characters">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="characters"
                    >
                      {currentFormQuestions.map((question, index) => {
                        return (
                          <Draggable
                            key={question.id}
                            draggableId={question.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  style={{
                                    padding: 14,
                                    borderRadius: 8,
                                    marginBottom: 14,
                                    backgroundColor: theme
                                      ? theme.TertiaryBG.backgroundColor
                                      : altItemsColor || Colors.WHITE,
                                  }}
                                >
                                  <div className="AlignedRowSpaced">
                                    <div
                                      className="AlignedRow"
                                      style={{ gap: 5 }}
                                    >
                                      <div style={{ cursor: "pointer" }}>
                                        <IoMenuOutline
                                          color={Colors.GRAY1}
                                          size={24}
                                          style={{ paddingTop: 4 }}
                                        />
                                      </div>
                                      <span
                                        style={{
                                          ...theme?.SubText,
                                          fontSize: 12,
                                        }}
                                      >
                                        {questionInputName(
                                          question.formInput.inputType
                                        )}
                                      </span>
                                      {question.formInput.isRequired ? (
                                        <span
                                          style={{
                                            color: Colors.RED1,
                                            fontSize: 12,
                                          }}
                                        >
                                          *Required
                                        </span>
                                      ) : null}
                                    </div>
                                    <div
                                      className="AlignedRow"
                                      style={{ gap: 10 }}
                                    >
                                      <Icon
                                        className="TrashButton"
                                        icon={"ion:trash-outline"}
                                        height={20}
                                        onClick={() =>
                                          deleteQuestionOnPress(question)
                                        }
                                      />
                                      <Icon
                                        className={
                                          "EditButton " +
                                          (theme === LightTheme || !theme
                                            ? "EditButtonLight"
                                            : "EditButtonDark")
                                        }
                                        icon={"feather:edit"}
                                        height={20}
                                        onClick={() =>
                                          newQuestionOnPress(question)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <hr
                                    style={{
                                      ...theme?.DividerColor,
                                      marginTop: 10,
                                    }}
                                  />
                                  <div
                                    className="AlignedRow"
                                    style={{ gap: 7 }}
                                  >
                                    {question.isEssential ? (
                                      <div>
                                        <Icon
                                          icon="fa:map-pin"
                                          height={14}
                                          color={theme?.PrimaryText.color}
                                        />
                                      </div>
                                    ) : null}
                                    <h2
                                      className="AboutSubtitle"
                                      style={{
                                        fontSize: 14,
                                        ...theme?.PrimaryText,
                                      }}
                                    >
                                      {question.formInput.label}
                                    </h2>
                                  </div>
                                  {question.formInput.inputType ===
                                    FormInputType.RADIO ||
                                  question.formInput.inputType ===
                                    FormInputType.CHECK_BOX ? (
                                    question.formInput.options.map(
                                      (option, index) => (
                                        <div
                                          key={index}
                                          className="AlignedRow"
                                          style={{
                                            paddingBlock: 8,
                                            gap: 6,
                                          }}
                                        >
                                          {question.formInput.inputType ===
                                          FormInputType.RADIO ? (
                                            <span
                                              style={{
                                                color: Colors.GRAY2,
                                                fontSize: 12,
                                              }}
                                            >
                                              #{index + 1}
                                            </span>
                                          ) : (
                                            <div
                                              style={{
                                                padding: 4,
                                                border: "1px solid #B9B9B9",
                                              }}
                                            />
                                          )}
                                          <span
                                            style={{
                                              fontSize: 12,
                                              color: Colors.GRAY2,
                                            }}
                                          >
                                            {option}
                                          </span>
                                        </div>
                                      )
                                    )
                                  ) : (
                                    <div
                                      className="AlignedRow"
                                      style={{ paddingBlock: 8, gap: 4 }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 12,
                                          color: Colors.GRAY2,
                                        }}
                                      >
                                        {question.formInput.placeholder ||
                                          "Answer here..."}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {altFooter ? (
                <div onClick={() => newQuestionOnPress()}>{altFooter}</div>
              ) : isProfileQuestions && currentFormQuestions.length === 0 ? (
                <EmptyStateButton
                  title={"Add Questions to Collect Info"}
                  description={
                    "Collect custom info on subscribe. Your questions will show up here."
                  }
                  icon={
                    <Icon
                      icon="ion:document"
                      height={50}
                      color={Colors.BLUE5}
                    />
                  }
                  iconBox={84}
                  containerStyles={{ paddingBlock: 80 }}
                  btnText={"Add Question"}
                  onPress={() => newQuestionOnPress()}
                />
              ) : (
                <div
                  onClick={() => newQuestionOnPress()}
                  className={
                    "formQuestionAddButton " +
                    (theme === LightTheme || !theme
                      ? "formQuestionAddButtonLight"
                      : "formQuestionAddButtonDark")
                  }
                >
                  <span style={{ fontSize: 14, ...theme?.PrimaryText }}>
                    Add Question
                  </span>
                  <IoAddCircleOutline
                    size={18}
                    color={
                      theme === LightTheme || !theme
                        ? Colors.BLACK
                        : Colors.WHITE
                    }
                    style={{ marginBottom: -1 }}
                  />
                </div>
              )}
            </m.div>
          </LazyMotion>
        ) : null}
      </AnimatePresence>
      {(loggedIn !== LoginState.LOGGED_IN && savedQuestionsVisible) ||
      formQuestionItem ? (
        <FormBuilder
          type={
            isProfileQuestions
              ? FormSavedQuestionsType.PROFILE
              : FormSavedQuestionsType.EVENT
          }
          setIsFormsModalOpen={setSavedQuestionsVisible}
          selectedQuestions={currentEventFormQuestions}
          setSelectedQuestions={(formQuestions: SavedFormQuestion[]) => {
            dispatch(
              accountActions.reorderCurrentEventFormQuestions(formQuestions)
            );
          }}
          formQuestionItem={formQuestionItem}
          setFormQuestionItem={setFormQuestionItem}
          theme={theme}
        />
      ) : savedQuestionsVisible ? (
        <FormSavedQuestions
          theme={theme}
          type={
            isProfileQuestions
              ? FormSavedQuestionsType.PROFILE
              : FormSavedQuestionsType.EVENT
          }
          isVisible={savedQuestionsVisible}
          setIsVisible={setSavedQuestionsVisible}
        />
      ) : null}
      <ConfirmDeleteModal
        heading="Are you sure you want to delete this question?"
        subtext="You cannot undo this."
        deleteButtonText="Delete"
        hideModal={!deleteFormQuestionItem}
        setIsVisible={(visible: boolean) => {
          if (!visible) {
            setDeleteFormQuestionItem(undefined);
          }
        }}
        deleteOnPress={deleteFormQuestion}
        theme={theme}
      />
      <AlertContainer
        headerComp={alertText}
        theme={theme}
        closeModal={() => setAlertText("")}
        hideModal={alertText === ""}
      />
    </>
  );
};

export default FormBuilderWrapper;
