import { useMemo, useState } from "react";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import MainActionsDropdownMenu from "../DropdownsAndTabs/MainActionsDropdownMenu";
import MarkitHeaderLogo from "../MarkitHeaderLogo";
import TourwaveLogo from "../../../src/assets/TourwaveLogo.png";
import RectangleButton from "../Buttons/RectangleButton";
import HelpModal from "../Containers/HelpModal";
import { DarkTheme, useTheme } from "../../hooks/useTheme";
import { ThemeType } from "@markit/common.types";
import MarkitFullLogoWhite from "../../../src/assets/MarkitFullLogoWhite.png";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useNavigate } from "react-router-dom";

type CreatorModeTopHeaderProps = { eventTheme?: ThemeType };

const CreatorModeTopHeader = (props: CreatorModeTopHeaderProps) => {
  const { eventTheme } = props;
  const isTourwaveUser =
    useSelector(getAccountState).account.accountData.isTourwaveUser;
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [helpModal, setHelpModal] = useState(false);
  const { theme } = useTheme(eventTheme);
  const navigate = useNavigate();

  const isDarkTheme = useMemo(() => theme && theme === DarkTheme, [theme]);

  const styles = {
    headerButton: {
      height: 38,
      width: 38,
      borderRadius: 38 / 2,
      backgroundColor: Colors.GRAY10,
      border: `1px solid ${Colors.BLACK1}`,
      cursor: "pointer",
    },
  };

  return (
    <>
      <div
        className="AlignedRowSpaced CreatorPanelContainer"
        style={{
          padding: 14,
          backgroundColor: Colors.BLACK,
          borderBottom: isDarkTheme
            ? `0.5px solid ${Colors.BLACK1}`
            : undefined,
        }}
      >
        <div style={{ paddingInline: 8 }}>
          {isTourwaveUser ? (
            <div
              className="AlignedRowSelect"
              style={{ gap: 14 }}
              onClick={() => navigate("/home")}
            >
              <img
                style={{ height: 18, width: "auto" }}
                alt="TourwaveLogo"
                src={TourwaveLogo}
              />
              <span style={{ fontSize: 10, color: Colors.GRAY1 }}>
                powered by
              </span>
              <img
                style={{ height: 18, width: "auto" }}
                alt="MarkitFullIconTourwave"
                src={MarkitFullLogoWhite}
              />
            </div>
          ) : (
            <MarkitHeaderLogo alternateLogo={MarkitFullLogoWhite} />
          )}
        </div>
        <div className="AlignedRow" style={{ gap: 10 }}>
          <MainActionsDropdownMenu
            sourceLocation="creator_top_header"
            overlayStyles={{ width: 200 }}
          >
            <div
              className="Centering"
              style={styles.headerButton}
              onClick={() => setDropdownVisible(!dropdownVisible)}
            >
              <Icon
                icon="ion:add"
                height={26}
                style={{ color: Colors.WHITE }}
              />
            </div>
          </MainActionsDropdownMenu>
          <RectangleButton
            buttonLabel="Support"
            onPress={() => setHelpModal(true)}
            altPaddingHorz={12}
            altPaddingVert={10}
            altBorderRadius={100}
            altColor={Colors.GRAY10}
            altTextColor={Colors.WHITE}
            containerStyles={{
              border: `1px solid ${Colors.BLACK1}`,
              height: 40,
            }}
          />
        </div>
      </div>
      {helpModal ? (
        <HelpModal showModal={helpModal} setShowModal={setHelpModal} />
      ) : null}
    </>
  );
};

export default CreatorModeTopHeader;
