import { useTheme } from "../../../hooks/useTheme";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import { isDesktop } from "react-device-detect";
import ProfilePic from "../../ProfilePic";
import {
  AccountData,
  MembershipPlan,
  NotificationType,
} from "@markit/common.types";
import RectangleButton from "../../Buttons/RectangleButton";
import { Colors } from "../../../utils/colors";
import { Icon } from "@iconify/react";
import AlertContainer from "../../Containers/AlertContainer";
import { API } from "../../../API";
import { stripePromise, testStripe } from "../../FullEvent/FullEventInputForm";
import { MixpanelContext } from "../../../context/AnalyticsService";
import { Elements } from "@stripe/react-stripe-js";
import SubscriptionCheckoutForm, {
  SubscriptionCheckoutType,
} from "../../Subscription/SubscriptionCheckoutForm";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { CircularProgress } from "@mui/material";
import CustomTextField from "../../CustomTextField";
import { getUserFollowerRef } from "../../../utils/FirebaseUtils";
import { onSnapshot } from "../../../firebase";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import { useDispatch } from "react-redux";
import { onProfilePageNavigatePath } from "../../../utils/navigationUtils";
import { useLocation, useNavigate } from "react-router-dom";

type SubscribeFlowMembershipCheckoutProps = {
  creatorData: AccountData;
  selectedMembershipPlan: MembershipPlan;
  uid: string;
  backOnPress: () => void;
};

const SubscribeFlowMembershipCheckout = (
  props: SubscribeFlowMembershipCheckoutProps
) => {
  const { creatorData, selectedMembershipPlan, uid, backOnPress } = props;
  const { theme } = useTheme();
  const pathname = useLocation().pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mixpanel = useContext(MixpanelContext);
  const [showingPaymentSheet, setShowingPaymentSheet] =
    useState<boolean>(false);
  const [options, setOptions] = useState<{
    clientSecret: string;
    appearance: {};
  }>();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });
  const [purchasing, setPurchasing] = useState(false);

  const styles = {
    errorText: { fontSize: 12, color: Colors.RED3 },
  };

  const isManageSubscription = useMemo(
    () => pathname.includes("manageSubscription"),
    [pathname]
  );

  const fetchPaymentSheetParams = useCallback(async () => {
    setOptions(undefined);
    await API.user
      .initializeFollowerSubscription({
        uid: uid,
        email: email,
        priceId: selectedMembershipPlan.id,
        creatorStripeAccountId:
          testStripe && creatorData.testStripeAccountId
            ? creatorData.testStripeAccountId
            : creatorData.stripeAccountId,
        creatorUserId: creatorData.uid,
      })
      .then(async (res: any) => {
        const { clientSecret } = res;
        const stripeOptions = {
          clientSecret: clientSecret,
          appearance: {
            theme: "stripe",
            variables: { colorBackground: Colors.WHITE },
          },
        };
        setOptions(stripeOptions);
      })
      .catch((err: any) => {
        console.error("Error on initialize: " + err.message);
      });
  }, [
    creatorData.stripeAccountId,
    creatorData.testStripeAccountId,
    creatorData.uid,
    email,
    selectedMembershipPlan.id,
    uid,
  ]);

  const showPaymentSheet = useCallback(async () => {
    let showError = false;
    if (email === "") {
      setEmailError(true);
      showError = true;
    }
    if (showError) {
      return;
    }
    setShowingPaymentSheet(true);
    fetchPaymentSheetParams();
  }, [email, fetchPaymentSheetParams]);

  const onPurchaseSuccess = useCallback(async () => {
    showNotificationBanner(dispatch, "Purchased", NotificationType.AFFIRMATIVE);
    onProfilePageNavigatePath(navigate, creatorData.username);
  }, [creatorData.username, dispatch, navigate]);

  const onConfirmApplePay = useCallback(() => {
    if (mixpanel) {
      mixpanel.track("Webapp: Getting Follower Subscription With Apple Pay", {
        distinct_id: uid,
        creator_uid: creatorData.uid,
        creator_stripe_account_id: creatorData.stripeAccountId,
        membership_plan_id: selectedMembershipPlan.id,
        is_manage_subscription: isManageSubscription,
      });
    }
    onPurchaseSuccess();
  }, [
    creatorData.stripeAccountId,
    creatorData.uid,
    isManageSubscription,
    mixpanel,
    onPurchaseSuccess,
    selectedMembershipPlan.id,
    uid,
  ]);

  const paymentOrRsvpElement = useMemo(() => {
    if (options !== undefined) {
      return (
        <div className="SubscriptionCheckoutPaymentSheet">
          <Elements
            key={options.clientSecret}
            stripe={stripePromise}
            options={options}
          >
            <SubscriptionCheckoutForm
              label={selectedMembershipPlan.name}
              amount={selectedMembershipPlan.price}
              processing={purchasing}
              setProcessing={setPurchasing}
              isReadyForPayment={true}
              clientSecret={options.clientSecret}
              onConfirmApplePay={onConfirmApplePay}
              checkoutType={SubscriptionCheckoutType.FOLLOWER_MEMBERSHIP}
              onPress={async (stripe: Stripe, elements: StripeElements) => {
                if (mixpanel) {
                  mixpanel.track(
                    "Webapp: Attempting Getting Follower Subscription",
                    {
                      distinct_id: uid,
                      creator_uid: creatorData.uid,
                      creator_stripe_account_id: creatorData.stripeAccountId,
                      membership_plan_id: selectedMembershipPlan.id,
                      is_manage_subscription: isManageSubscription,
                    }
                  );
                }
                setPurchasing(true);
                const { error } = await stripe.confirmPayment({
                  elements,
                  redirect: "if_required",
                });

                if (error) {
                  // This point will only be reached if there is an immediate error when
                  // confirming the payment. Show error to your customer (for example, payment
                  // details incomplete)
                  console.error(error.message);
                  setAlertText({
                    heading:
                      error.message ??
                      "An error occurred when entering your info.",
                    subHeading: "Please try again.",
                  });
                  mixpanel.track(
                    "Webapp: Error Getting Follower Subscription",
                    {
                      distinct_id: uid,
                      creator_uid: creatorData.uid,
                      creator_stripe_account_id: creatorData.stripeAccountId,
                      membership_plan_id: selectedMembershipPlan.id,
                      error_message: error.message,
                    }
                  );
                  setPurchasing(false);
                } else {
                  // Add a snapshot to listen for when the follower membership plan matches the selected plan
                  // Once it's created, we know that they have gotten their paid subscription
                  const followerRef = getUserFollowerRef(creatorData.uid, uid);
                  const unsubscribe = onSnapshot(
                    followerRef,
                    async (snapshot) => {
                      // TODO: Add a timeout if it takes too long, throw an error message to the user
                      const followerData = snapshot.data();
                      if (
                        followerData &&
                        followerData.membershipPlanId ===
                          selectedMembershipPlan.id
                      ) {
                        await onPurchaseSuccess();
                        setPurchasing(false);
                        unsubscribe();
                      }
                    }
                  );
                }
              }}
            />
          </Elements>
        </div>
      );
    } else if (showingPaymentSheet) {
      return (
        <div className="ColumnCentering">
          <CircularProgress style={{ color: Colors.GRAY1 }} size={24} />
        </div>
      );
    } else {
      return undefined;
    }
  }, [
    creatorData.stripeAccountId,
    creatorData.uid,
    isManageSubscription,
    mixpanel,
    onConfirmApplePay,
    onPurchaseSuccess,
    options,
    purchasing,
    selectedMembershipPlan.id,
    selectedMembershipPlan.name,
    selectedMembershipPlan.price,
    showingPaymentSheet,
    uid,
  ]);

  return (
    <div
      className="ColumnNormal"
      style={{
        marginInline: "auto",
        paddingTop: 40,
        width: isDesktop ? 544 : "100%",
        gap: 30,
      }}
    >
      <div
        className="ColumnCentering"
        style={{ gap: isDesktop ? 30 : 14, paddingInline: isDesktop ? 0 : 14 }}
      >
        <ProfilePic size={isDesktop ? 100 : 75} user={creatorData} />
        <div className="ColumnCentering" style={{ gap: 7 }}>
          <span
            className="sectionTitle"
            style={{ fontWeight: 600, ...theme.PrimaryText }}
          >
            Choose payment method
          </span>
          <div
            onClick={backOnPress}
            className="AlignedRowSelect"
            style={{ gap: 2 }}
          >
            <Icon icon={"ion:chevron-left"} height={11} style={theme.SubText} />
            <span className="smallBodySubtext">Back to plans</span>
          </div>
        </div>
      </div>
      <div
        className="ColumnNormal"
        style={{
          width: isDesktop ? 330 : "100%",
          alignSelf: "center",
          gap: 14,
        }}
      >
        <div
          className="ColumnNormal"
          style={{ gap: 7, paddingInline: isDesktop ? 0 : 14 }}
        >
          <span className="bodyMedium">
            Enter email{" "}
            <span className="smallBodySubtext">
              (required to receive receipt)
            </span>
          </span>
          <div className="ColumnNormal" style={{ gap: 4 }}>
            <CustomTextField
              value={email}
              inputMode="email"
              placeholder="Enter Email"
              borderRadius={12}
              backgroundColor={Colors.WHITE}
              onChange={(change: any) => {
                setEmailError(false);
                setEmail(change.target.value);
              }}
              error={emailError}
            />
            {emailError ? (
              <span style={styles.errorText}>Email required</span>
            ) : null}
          </div>
        </div>
        <div
          className="AlignedRowSpaced"
          style={{ paddingInline: isDesktop ? 0 : 14 }}
        >
          <span style={{ fontWeight: 500 }}>Total</span>
          <span style={{ fontSize: 24, fontWeight: 500 }}>
            ${selectedMembershipPlan.price / 100}
          </span>
        </div>
        <div style={{ paddingInline: isDesktop ? 0 : 14 }}>
          {paymentOrRsvpElement}
          {!showingPaymentSheet ? (
            <RectangleButton
              buttonLabel="Continue to Payment"
              onPress={showPaymentSheet}
              altPaddingVert={14}
              altPaddingHorz={14}
              altColor={Colors.RED2}
              altTextColor={Colors.WHITE}
            />
          ) : null}
        </div>
      </div>
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => {
          setAlertText({ heading: "", subHeading: "" });
        }}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
    </div>
  );
};

export default memo(SubscribeFlowMembershipCheckout);
