import { useCallback, useMemo, useState } from "react";
import { Colors } from "../../../utils/colors";
import { Icon } from "@iconify/react";
import {
  LessThanDate,
  checkTwilioMessagingSurgeHit,
  deepCopy,
  detectedCensored,
  getEventAttachmentLinks,
  hostingLiveEvents,
  massTextMessageAttachmentsValidation,
  uniqueVals,
  detectedLinkShortener,
  calculateTextMessageCreditsEstimate,
  MARKITAI,
  textTimeWithinQuietHours,
  displayAutomationTriggerAction,
  hasSubscription,
} from "@markit/common.utils";
import {
  MassText,
  MassTextType,
  SubSelectionItem,
  NotificationType,
  ScheduleTextInfo,
  Campaign,
  FREE_TIER_MESSAGE_LIMIT,
} from "@markit/common.types";
import { API } from "../../../API";
import { useSelector, useDispatch } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { MassTextMessagingScreenType } from "./MassTextPanel";
import mixpanel from "mixpanel-browser";
import ConfirmDeleteModal from "../../Containers/ConfirmPopups/ConfirmDeleteModal";
import HelpModal from "../../Containers/HelpModal";
import TestTextButton from "./TestTextButton";
import { getEventState } from "../../../redux/slices/eventSlice";
import AlertContainer from "../../Containers/AlertContainer";
import { saveMediaToFirestore } from "../../../utils/photoUtils";
import ContentViolationAlert from "../../DeliveryScore/ContentViolationAlert";
import { useLocation, useNavigate } from "react-router-dom";
import { onCampaignNavigatePath } from "../../../utils/navigationUtils";
import { NavigationId } from "../../../navigation/AppParamList";
import { MassTextConfirmDeleteModal } from "./Items/MassTextConfirmDeleteModal";
import { getCampaignState } from "../../../redux/slices/campaignSlice";
import { MassTextsWrapperType } from "./MassTextWrapperManager";
import MassTextsHeaderPreview from "./Header/MassTextsHeaderPreview";
import RectangleButton from "../../Buttons/RectangleButton";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import QuietHoursSendingModal from "./Message/QuietHoursSendingModal";
import { ConfirmSendTextModal } from "./Message/ConfirmSendTextModal";
import { BackButton } from "../../Buttons/BackButton";
import { MessageLimitsModal } from "./Message/MessageLimitsModal";

type MassTextsMessagingHeaderProps = {
  wrapperType: MassTextsWrapperType;
  campaign: Campaign | undefined;
  setEditingCampaign?: (editingCampaign: boolean) => void;
  duplicateOnPress?: (massText: MassText) => void;
  screen: MassTextMessagingScreenType;
  setScreen: (screen: MassTextMessagingScreenType) => void;
  massText: MassText;
  updateMassTextSettings: (massTextSettings: Partial<MassText>) => void;
  resetState: (newMassText?: MassText) => void;
  madeChanges: boolean;
  finalRecipients: string[];
  suggestedAction: SubSelectionItem | undefined;
  scheduleTextInfo: ScheduleTextInfo;
  setScheduleTextInfo: (ScheduleTextInfo: ScheduleTextInfo) => void;
  isEditing: boolean;
  totalDailyMessages: number;
  loadingDailyMessages: boolean;
  loadingCategoryRecipients: boolean;
};

const MassTextsMessagingHeader = (props: MassTextsMessagingHeaderProps) => {
  const {
    wrapperType,
    campaign,
    setEditingCampaign,
    duplicateOnPress,
    screen,
    setScreen,
    massText,
    updateMassTextSettings,
    resetState,
    madeChanges,
    finalRecipients,
    suggestedAction,
    scheduleTextInfo,
    setScheduleTextInfo,
    isEditing,
    totalDailyMessages,
    loadingDailyMessages,
    loadingCategoryRecipients,
  } = props;
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { accountData } = useSelector(getAccountState).account;
  const { campaignDetails } = useSelector(getCampaignState);
  const { events } = useSelector(getEventState).events;
  const dispatch = useDispatch();
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const [quietHoursModal, setQuietHoursModal] = useState(false);
  const [sendTextConfirm, setSendTextConfirm] = useState(false);
  const [messageLimitsModal, setMessageLimitsModal] = useState(false);
  const [showConfirmBackModal, setShowConfirmBackModal] =
    useState<boolean>(false);
  const [showDeleteMassTextModal, setShowDeleteMassTextModal] =
    useState<boolean>(false);

  const [initialMassText, setInitialMassText] = useState(deepCopy(massText));
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });
  const [messageContentAlert, setMessageContentAlert] = useState(false);
  const [sending, setSending] = useState(false);

  // Use this instead of scheduleTextInfo to handle cases when not on send schedule screen
  const textInfo: ScheduleTextInfo = useMemo(() => {
    if (screen === MassTextMessagingScreenType.SEND_SCHEDULE) {
      return scheduleTextInfo;
    } else {
      return {
        sendTime: massText.sentAt ? new Date(massText.sentAt) : new Date(),
        scheduleToggle: massText.scheduled,
        catchUpChecked: massText.catchUpEnabled,
      };
    }
  }, [
    massText.catchUpEnabled,
    massText.scheduled,
    massText.sentAt,
    scheduleTextInfo,
    screen,
  ]);

  // The event that the event text is for
  const foundEventText = useMemo(
    () => events.find((event) => event.id === massText.eventRefId),
    [events, massText.eventRefId]
  );

  // The initial text in the campaign, if this text is part of an existing campaign
  const initialCampaignText = useMemo(() => {
    const foundCampaignDetail = campaignDetails.find(
      (detail) => detail.campaignId === massText.campaignId
    );
    if (foundCampaignDetail && campaign) {
      const foundInitialText = foundCampaignDetail.massTextDetails.find(
        (text) => text.massText.id === campaign.initialTextId
      );
      return foundInitialText?.massText;
    }
  }, [campaignDetails, campaign, massText.campaignId]);

  const numTextCredits = useMemo(
    () =>
      calculateTextMessageCreditsEstimate(
        massText.message,
        massText.mediaUrl,
        foundEventText
      ),
    [foundEventText, massText.mediaUrl, massText.message]
  );

  const isTriggerText = useMemo(
    () => massText.type === MassTextType.TRIGGERTEXT,
    [massText.type]
  );

  const finalSubSelectionIds = useMemo(
    () => (suggestedAction ? [suggestedAction] : massText.subSelectionIds),
    [massText.subSelectionIds, suggestedAction]
  );

  const isSendTimePassed = useMemo(
    () =>
      LessThanDate(massText.sentAt, new Date().toISOString()) && !isTriggerText,
    [isTriggerText, massText.sentAt]
  );

  const handleBackNavigation = useCallback(() => {
    switch (screen) {
      case MassTextMessagingScreenType.LINK_ANALYTICS:
        setScreen(MassTextMessagingScreenType.SEND_SUMMARY);
        break;
      case MassTextMessagingScreenType.SUMMARY:
      case MassTextMessagingScreenType.SEND_SUMMARY:
        resetState();
        break;
      case MassTextMessagingScreenType.RECIPIENTS_SEND_SUMMARY:
        setScreen(MassTextMessagingScreenType.SEND_SUMMARY);
        break;
      case MassTextMessagingScreenType.SEND_SCHEDULE:
        setScheduleTextInfo({
          scheduleToggle: massText.scheduled,
          sendTime: new Date(massText.sentAt),
          catchUpChecked: massText.catchUpEnabled,
        });
        setScreen(
          isEditing
            ? MassTextMessagingScreenType.SEND_SUMMARY
            : MassTextMessagingScreenType.SUMMARY
        );
        break;
    }
  }, [
    isEditing,
    massText.catchUpEnabled,
    massText.scheduled,
    massText.sentAt,
    resetState,
    screen,
    setScheduleTextInfo,
    setScreen,
  ]);

  const backOnPress = useCallback(() => {
    if (
      !madeChanges ||
      screen === MassTextMessagingScreenType.RECIPIENTS_SEND_SUMMARY
    ) {
      handleBackNavigation();
    } else {
      setShowConfirmBackModal(true);
    }
  }, [madeChanges, screen, handleBackNavigation]);

  const headerLabel = useMemo(() => {
    if (screen === MassTextMessagingScreenType.SEND_SUMMARY) {
      return isTriggerText && campaign
        ? displayAutomationTriggerAction(campaign, foundEventText)
        : isSendTimePassed
        ? "Text Performance"
        : "Scheduled Text";
    } else if (
      screen === MassTextMessagingScreenType.SUMMARY &&
      wrapperType !== MassTextsWrapperType.MODAL
    ) {
      return "New Text";
    } else if (screen === MassTextMessagingScreenType.RECIPIENTS_SEND_SUMMARY) {
      return "Text Recipients";
    } else if (screen === MassTextMessagingScreenType.LINK_ANALYTICS) {
      return "Link Analytics";
    } else if (screen === MassTextMessagingScreenType.SEND_SCHEDULE) {
      return "Sending Schedule";
    }
    return "";
  }, [
    campaign,
    foundEventText,
    isSendTimePassed,
    isTriggerText,
    screen,
    wrapperType,
  ]);

  const hideTopRightButton = useMemo(() => {
    return (
      screen === MassTextMessagingScreenType.RECIPIENTS_SEND_SUMMARY ||
      screen === MassTextMessagingScreenType.LINK_ANALYTICS ||
      (screen === MassTextMessagingScreenType.SEND_SUMMARY && isSendTimePassed)
    );
  }, [isSendTimePassed, screen]);

  const eventsHostingCanMassText = useMemo(
    () => hostingLiveEvents(events, accountData.uid, true).reverse(),
    [accountData.uid, events]
  );

  const eventAttachmentLinks = useMemo(() => {
    const allEventIds = uniqueVals(
      massText.eventRefId
        ? eventsHostingCanMassText
            .map((event) => event.id)
            .concat(massText.eventRefId)
        : eventsHostingCanMassText.map((event) => event.id)
    );
    return getEventAttachmentLinks(massText.message, allEventIds);
  }, [eventsHostingCanMassText, massText.eventRefId, massText.message]);

  const saveChangesToText = useCallback(async () => {
    if (massText) {
      setSending(true);
      let finalUpdatedMassText = massText;
      if (
        massText.mediaUrl !== "" &&
        massText.mediaUrl !== initialMassText.mediaUrl
      ) {
        const url = await saveMediaToFirestore(
          massText.mediaUrl,
          accountData.uid + "/textMedia/massTexts/" + massText.id
        );
        finalUpdatedMassText = { ...massText, mediaUrl: url };
      }
      await API.text
        .updateMassTextMessage({
          userId: accountData.uid,
          massText: finalUpdatedMassText,
        })
        .then((response) => {
          if (!response.success) {
            setAlertText({
              heading: "Failed to update!",
              subHeading: "Please try again.",
            });
          } else {
            showNotificationBanner(
              dispatch,
              "Text Updated!",
              NotificationType.AFFIRMATIVE
            );
            resetState();
          }
        });

      setSending(false);
    }
  }, [
    massText,
    initialMassText.mediaUrl,
    accountData.uid,
    dispatch,
    resetState,
  ]);

  // Checks if the send time is valid
  const confirmSendTimeChecks = useCallback(() => {
    if (massText.scheduled && isSendTimePassed) {
      setAlertText({
        heading: "Please edit the send time.",
        subHeading: "The scheduled send time is set before the current time.",
      });
      return false;
    }
    // If the send time is less than the initial mass text in the campaign
    // or if the initial mass text is not queued and trying to send a text now, prevent from sending
    if (
      !isTriggerText &&
      initialCampaignText &&
      (LessThanDate(massText.sentAt, initialCampaignText.sentAt) ||
        (!initialCampaignText.queued && !massText.scheduled))
    ) {
      setAlertText({
        heading: "Please edit the send time.",
        subHeading:
          "The send time cannot be prior to the first text in the campaign.",
      });
      return false;
    }
    return true;
  }, [
    initialCampaignText,
    isSendTimePassed,
    isTriggerText,
    massText.scheduled,
    massText.sentAt,
  ]);

  const sendMassText = useCallback(async () => {
    const finalMessage = massTextMessageAttachmentsValidation(
      massText.message,
      eventAttachmentLinks,
      finalRecipients.length
    );
    setSending(true);

    // upload media if it exists
    let mediaUrl = "";
    if (massText.mediaUrl !== "") {
      const url = await saveMediaToFirestore(
        massText.mediaUrl,
        accountData.uid + "/textMedia/massTexts/" + massText.id
      );
      mediaUrl = url;
    }

    await API.text
      .sendCustomMessage({
        userData: accountData,
        message: finalMessage,
        sendTime: massText.scheduled ? massText.sentAt : "",
        catchUpEnabled: massText.catchUpEnabled,
        eventRefId: massText.eventRefId,
        subSelectionIds: finalSubSelectionIds,
        excludedSubSelectionIds: massText.excludedSubSelectionIds,
        recipients: finalRecipients,
        numExcludedRecipients: 0,
        mediaUrl: mediaUrl,
        campaignId: massText.campaignId ?? "",
        massTextId: massText.id,
      })
      .then((response) => {
        if (!response.success) {
          setAlertText({
            heading: "An error occurred.",
            subHeading:
              "Please contact our hotline 617-213-0897 to diagnose the issue before attempting to send again.",
          });

          mixpanel.track("Mass Text Message Failed", {
            message: finalMessage,
            recipientsLength: finalRecipients.length,
            numExcludedRecipients: 0,
            errorMessage: "response.success returned false",
          });
          return;
        }
        if (mixpanel) {
          mixpanel.track("Mass Text Message Initialized", {
            source: "webapp",
            type: response.massText.type,
            massTextId: response.massText.id,
          });
        }
        updateMassTextSettings({
          id: response.massText.id,
          campaignId: response.massText.campaignId,
          sentAt: response.massText.sentAt,
          type: response.massText.type,
        });

        showNotificationBanner(
          dispatch,
          massText.scheduled ? "Text Scheduled!" : "Text Sent!",
          NotificationType.AFFIRMATIVE
        );
        if (wrapperType !== MassTextsWrapperType.MODAL) {
          resetState(response.massText);
          setScreen(MassTextMessagingScreenType.SEND_SUMMARY);
        } else {
          // Do a window navigate if modal panel and already on a campaign path (ie. duplicate text)
          if (pathname.startsWith("/c")) {
            window.location.href = `${MARKITAI}${onCampaignNavigatePath(
              response.massText.campaignId
            )}`;
          } else {
            navigate(onCampaignNavigatePath(response.massText.campaignId), {
              state: { campaignId: response.massText.campaignId },
            });
          }
        }
        setSending(false);
      })
      .catch((e: any) => {
        setAlertText({
          heading: "An error occurred.",
          subHeading:
            "Please contact our hotline 617-213-0897 to diagnose the issue before attempting to send again.",
        });
        mixpanel.track("Mass Text Message Failed", {
          message: massText.message,
          recipientsLength: finalRecipients.length,
          numExcludedRecipients: 0,
          errorMessage: e.message,
        });
      });
  }, [
    massText.message,
    massText.mediaUrl,
    massText.scheduled,
    massText.sentAt,
    massText.catchUpEnabled,
    massText.eventRefId,
    massText.excludedSubSelectionIds,
    massText.campaignId,
    massText.id,
    eventAttachmentLinks,
    finalRecipients,
    accountData,
    finalSubSelectionIds,
    updateMassTextSettings,
    dispatch,
    wrapperType,
    resetState,
    setScreen,
    pathname,
    navigate,
  ]);

  const confirmMassText = useCallback(async () => {
    // if message is not set
    if (massText.message === "") {
      setAlertText({
        heading: "Write your message first!",
        subHeading: "Select recipients and write your message before sending.",
      });
      return;
    }
    // if message length exceeds max count
    if (massText.message.length > 1500) {
      setAlertText({
        heading: "Exceeded max character count of 1500.",
        subHeading: "Shorten your message before trying to send again.",
      });
      return;
    }
    // if banned terms exist in message
    if (detectedCensored(massText.message)) {
      setMessageContentAlert(true);
      return;
    }
    if (detectedLinkShortener(massText.message)) {
      setAlertText({
        heading: "Your message includes a known url link shortener.",
        subHeading: "Remove them before trying to send again.",
      });
      return;
    }
    // if an event text and not scheduled and there are no attendees for the event
    if (
      finalRecipients.length === 0 &&
      massText.eventRefId &&
      !massText.scheduled
    ) {
      setAlertText({
        heading: "This message cannot be sent immediately.",
        subHeading:
          "You have no attendees within the custom selection for this event. You can schedule this text instead.",
      });
      return;
    }
    // if there are no recipients selected for a non-category text
    if (
      !isTriggerText &&
      finalRecipients.length === 0 &&
      finalSubSelectionIds.length === 0
    ) {
      setAlertText({
        heading: "Select recipients first!",
        subHeading: "Select recipients and write your message before sending.",
      });
      return;
    }
    if (!isTriggerText) {
      // Check if sending total num credits for free tier user will reach their free credit limit
      if (
        !hasSubscription(accountData) &&
        accountData.customer.freeTierMessages +
          finalRecipients.length * numTextCredits >
          FREE_TIER_MESSAGE_LIMIT
      ) {
        setMessageLimitsModal(true);
        return false;
      }
      // If trying to send/schedule during quiet hours
      if (textTimeWithinQuietHours(textInfo) && !accountData.bypassQuietHours) {
        setQuietHoursModal(true);
        return;
      }
    }
    if (!confirmSendTimeChecks()) {
      return;
    }
    // loading state for fetching the recipients from the categories
    if (loadingCategoryRecipients) {
      setAlertText({
        heading: "Not ready to send.",
        subHeading:
          "Currently fetching the recipients this text will be sent to. This will only take a few seconds.",
      });
      return;
    }
    // if the daily message count is loading, there are more than 1000 recipients selected, and the user is not a subaccount
    if (
      !isTriggerText &&
      loadingDailyMessages &&
      finalRecipients.length > 100 &&
      !accountData.customer.subAccountSid
    ) {
      setAlertText({
        heading: "Not ready to send.",
        subHeading:
          "We are making sure everything is all set. This will only take up to a minute.",
      });
      return;
    }
    // If there are more than 100 recipients selected, the messaging surge is hit, and the user is not a subaccount
    if (
      !isTriggerText &&
      finalRecipients.length > 100 &&
      checkTwilioMessagingSurgeHit(
        totalDailyMessages + finalRecipients.length,
        accountData.customer.subAccountSid
      )
    ) {
      mixpanel.track("Mass Texting Surge Modal Visible", {
        totalTwilioMessages: totalDailyMessages,
        numRecipients: finalRecipients.length,
        text_type: "mass text",
        from: "web-app",
      });
      setAlertText({
        heading: "We are currently experiencing a surge in Mass Texting.",
        subHeading:
          "To ensure critical texts are being sent [tickets, confirmations, etc], we have locked this feature until 12AM PST. Scheduled texts and Event texts will still be sent. Thank you for your patience.",
      });
      return;
    }
    if (screen === MassTextMessagingScreenType.SEND_SUMMARY) {
      if (isTriggerText) {
        setSendTextConfirm(true);
      } else {
        saveChangesToText();
      }
    } else {
      setSendTextConfirm(true);
    }
  }, [
    massText.message,
    massText.eventRefId,
    massText.scheduled,
    finalRecipients.length,
    isTriggerText,
    finalSubSelectionIds.length,
    confirmSendTimeChecks,
    loadingCategoryRecipients,
    loadingDailyMessages,
    accountData,
    totalDailyMessages,
    screen,
    numTextCredits,
    textInfo,
    saveChangesToText,
  ]);

  const saveSendTimeOnPress = useCallback(() => {
    if (textTimeWithinQuietHours(textInfo) && !accountData.bypassQuietHours) {
      setQuietHoursModal(true);
      return;
    }
    updateMassTextSettings({
      scheduled: textInfo.scheduleToggle,
      sentAt: textInfo.sendTime.toISOString(),
      catchUpEnabled: textInfo.catchUpChecked,
    });
    setScreen(
      isEditing
        ? MassTextMessagingScreenType.SEND_SUMMARY
        : MassTextMessagingScreenType.SUMMARY
    );
  }, [
    textInfo,
    accountData.bypassQuietHours,
    updateMassTextSettings,
    setScreen,
    isEditing,
  ]);

  const handleTopRightButton = useCallback(async () => {
    if (
      screen === MassTextMessagingScreenType.SUMMARY ||
      screen === MassTextMessagingScreenType.SEND_SUMMARY
    ) {
      confirmMassText();
    } else if (screen === MassTextMessagingScreenType.SEND_SCHEDULE) {
      saveSendTimeOnPress();
    }
  }, [confirmMassText, saveSendTimeOnPress, screen]);

  if (wrapperType === MassTextsWrapperType.SIDE_PANEL_PREVIEW) {
    return (
      <MassTextsHeaderPreview
        screen={screen}
        setEditingCampaign={setEditingCampaign}
        duplicateOnPress={duplicateOnPress}
        massText={massText}
        headerLabel={headerLabel}
        handleBackNavigation={handleBackNavigation}
      />
    );
  }

  return (
    <>
      <div
        className="AlignedRowSpaced"
        style={{ height: 56, paddingInline: 14 }}
      >
        <div className="AlignedRow" style={{ gap: 20 }}>
          <BackButton
            onPress={backOnPress}
            iconName={
              screen === MassTextMessagingScreenType.SUMMARY ||
              screen === MassTextMessagingScreenType.SEND_SUMMARY
                ? "mdi:close"
                : "ion:chevron-back"
            }
          />
          <span className="sectionTitle">{headerLabel}</span>
        </div>
        <div className="AlignedRow" style={{ gap: 7 }}>
          {screen === MassTextMessagingScreenType.SEND_SUMMARY &&
          !isSendTimePassed &&
          !isTriggerText ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setShowDeleteMassTextModal(true)}
            >
              <Icon icon="ion:trash-outline" height={24} color={Colors.RED1} />
            </div>
          ) : screen === MassTextMessagingScreenType.SUMMARY ? (
            <div
              onClick={() => setShowHelpModal(true)}
              className="Centering"
              style={{
                width: 36,
                height: 36,
                border: `0.5px solid ${Colors.GRAY2}`,
                borderRadius: 100,
                backgroundColor: Colors.WHITE1,
                cursor: "pointer",
              }}
            >
              <Icon icon="ion:help" height={25} />
            </div>
          ) : null}
          {!isSendTimePassed &&
          (screen === MassTextMessagingScreenType.SUMMARY ||
            screen === MassTextMessagingScreenType.SEND_SUMMARY) ? (
            <TestTextButton
              message={massText.message}
              image={massText.mediaUrl}
              save={massText.mediaUrl !== initialMassText.mediaUrl}
              event={foundEventText}
            />
          ) : null}
          {!hideTopRightButton ? (
            <div>
              <RectangleButton
                buttonLabel={
                  screen === MassTextMessagingScreenType.SEND_SUMMARY
                    ? "Save"
                    : screen === MassTextMessagingScreenType.SUMMARY
                    ? massText.scheduled
                      ? "Schedule"
                      : "Send"
                    : "Done"
                }
                onPress={handleTopRightButton}
                altBorderRadius={100}
                altPaddingHorz={14}
                altPaddingVert={10}
                width={sending ? 60 : undefined}
                altColor={Colors.BLACK}
                altTextColor={Colors.WHITE}
                loading={sending}
                disabled={
                  (screen === MassTextMessagingScreenType.SEND_SCHEDULE ||
                    screen === MassTextMessagingScreenType.SEND_SUMMARY) &&
                  !madeChanges
                }
                iconRight={
                  screen === MassTextMessagingScreenType.SUMMARY ? (
                    <Icon
                      icon={
                        massText.scheduled ? "ion:timer" : "ion:paper-plane"
                      }
                      height={16}
                      color={Colors.WHITE}
                    />
                  ) : undefined
                }
              />
            </div>
          ) : null}
        </div>
      </div>
      {showDeleteMassTextModal ? (
        <MassTextConfirmDeleteModal
          massText={massText}
          campaignInitialText={initialCampaignText}
          isOnlyMassText={false}
          showDeleteModal={showDeleteMassTextModal}
          setShowDeleteModal={setShowDeleteMassTextModal}
          handleAfterDelete={() => {
            if (!initialCampaignText) {
              alert("Please refresh the page to delete.");
            } else {
              if (initialCampaignText.id === massText.id) {
                navigate(NavigationId.HOME_CAMPAIGNS);
              } else {
                resetState();
              }
            }
          }}
        />
      ) : null}
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
      {quietHoursModal ? (
        <QuietHoursSendingModal
          scheduleTextInfo={textInfo}
          setScheduleTextInfo={(scheduleTextInfo: ScheduleTextInfo) => {
            if (screen === MassTextMessagingScreenType.SEND_SCHEDULE) {
              setScheduleTextInfo(scheduleTextInfo);
            } else {
              updateMassTextSettings({
                sentAt: new Date(scheduleTextInfo.sendTime).toISOString(),
                scheduled: scheduleTextInfo.scheduleToggle,
              });
            }
          }}
          setIsVisible={setQuietHoursModal}
        />
      ) : null}
      <ConfirmDeleteModal
        heading="Are you sure you want to exit?"
        subtext="Your edits won't be saved. This cannot be undone."
        deleteButtonText="Exit"
        hideModal={!showConfirmBackModal}
        setIsVisible={setShowConfirmBackModal}
        deleteOnPress={() => {
          setShowConfirmBackModal(false);
          if (screen === MassTextMessagingScreenType.SUMMARY) {
            resetState();
          } else {
            handleBackNavigation();
          }
        }}
        icon={<Icon icon="ion:exit-outline" height={40} />}
      />
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
      <ContentViolationAlert
        message={massText.message}
        isVisible={messageContentAlert}
        setIsVisible={setMessageContentAlert}
      />
      {sendTextConfirm ? (
        <ConfirmSendTextModal
          setModalVisible={setSendTextConfirm}
          confirmOnPress={
            screen === MassTextMessagingScreenType.SEND_SUMMARY
              ? saveChangesToText
              : sendMassText
          }
          numCredits={numTextCredits}
          isTriggerText={isTriggerText}
          isScheduled={textInfo.scheduleToggle}
          massText={massText}
          foundEventText={foundEventText}
          recipients={isTriggerText ? [] : finalRecipients}
          isSending={sending}
          showCategories={
            massText.subSelectionIds.length > 0 || suggestedAction !== undefined
          }
        />
      ) : null}
      {messageLimitsModal ? (
        <MessageLimitsModal
          setModalVisible={setMessageLimitsModal}
          numCredits={numTextCredits}
          numRecipients={finalRecipients.length}
          isScheduled={textInfo.scheduleToggle}
        />
      ) : null}
    </>
  );
};

export default MassTextsMessagingHeader;
