import {
  AccountData,
  AudienceList,
  Event,
  SelectRecipientGroupType,
  SelectRecipientType,
  SubSelectionStatusId,
  SubSelectionType,
  FollowerStatus,
  MembershipState,
} from "@markit/common.types";
import { CircularProgress } from "@mui/material";
import FlatList from "flatlist-react/lib";
import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { m } from "framer-motion";
import SearchBoxContainer from "../../../Containers/SearchBoxContainer";
import React, { useCallback, useMemo, useState } from "react";
import filter from "lodash.filter";
import { RenderedEventMassTextsModal } from "../../../LandingPage/RenderedEvents";
import { MassTextsActionItem } from "../Items/MassTextsActionItem";
import { MassTextsPersonItem } from "../Items/MassTextsPersonItem";
import { useLoadUserFollowList } from "../../../../hooks/useLoadUserFollowList";
import { getEventState } from "../../../../redux/slices/eventSlice";
import { SelectRecipientsMainSharedProps } from "./SelectRecipientsScreens";
import {
  filterUndefinedValues,
  hasSubscriptionUnlockedAdvancedData,
} from "@markit/common.utils";
import SelectRecipientsSuggestedCategories from "./SelectRecipientsSuggestedCategories";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import AlertContainer from "../../../Containers/AlertContainer";
import { EmptyStateFlatlist } from "../../../EmptyStates/EmptyStateFlatlist";
import { useLiveUpdatingEventAnalytics } from "../../../../hooks/useLiveUpdatingEventAnalytics";
import { NavigationId } from "../../../../navigation/AppParamList";
import { useNavigate } from "../../../../hooks/useNavigate";
import { fetchSingleUser } from "../../../../utils/FetchSingleData";
import useAsyncEffect from "../../../../hooks/useAsyncEffect";
import { useOnMount } from "../../../../hooks/useOnMount";
import AudienceListActionItem from "../../../DisplayItem/AudienceListItem/AudienceListActionItem";
import { notificationActions } from "../../../../redux/slices/notificationSlice";

type SelectRecipientsOverviewProps = SelectRecipientsMainSharedProps & {
  allSelectedPeople: string[];
  setAllSelectedPeople: (allSelectedPeople: string[]) => void;
  allExcludedPeople: string[];
  setAllExcludedPeople: (allExcludedPeople: string[]) => void;
  showDoneButton: boolean;
  setShowDoneButton: (showDoneButton: boolean) => void;
  updateSelectType: (selectType: Partial<SelectRecipientGroupType>) => void;
  setProfileSelected: (profileSelected: AccountData) => void;
  backToOverview: (numRecipients?: number) => void;
};

const SelectRecipientsOverview = (props: SelectRecipientsOverviewProps) => {
  const {
    massText,
    updateMassTextSettings,
    excludingMode,
    setExcludingMode,
    allSelectedPeople,
    setAllSelectedPeople,
    allExcludedPeople,
    setAllExcludedPeople,
    showCategories,
    showDoneButton,
    setShowDoneButton,
    updateSelectType,
    setProfileSelected,
    backToOverview,
    displayAllFollowers,
  } = props;
  const { events } = useSelector(getEventState).events;
  const {
    accountData,
    audienceListDetails,
    followingAccountData,
    followersData,
    loadedAllFollowers,
  } = useSelector(getAccountState).account;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [alertText, setAlertText] = useState("");
  const [suggestedFollowers, setSuggestedFollowers] = useState<AccountData[]>(
    []
  );

  const styles = {
    mainText: { fontWeight: "500", fontSize: 14 },
    sectionContainer: {
      padding: 14,
      backgroundColor: Colors.GRAY6,
      borderRadius: 8,
    },
  };

  const audienceLists = useMemo(
    () => audienceListDetails.map((listDetail) => listDetail.list),
    [audienceListDetails]
  );

  const { liveTotalGuests, liveTotalRequested } = useLiveUpdatingEventAnalytics(
    {
      eventId: massText ? massText.eventRefId : "",
    }
  );

  const {
    fetchedUserData,
    fetchedFollowerData,
    loadUsers,
    loadSearchResultsThrottled,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: displayAllFollowers ? undefined : FollowerStatus.SUBSCRIBED,
    windowSize: 20,
  });

  const foundEvent = useMemo(
    () => events.find((event) => massText && event.id === massText.eventRefId),
    [events, massText]
  );

  const eventsHosting = useMemo(
    () =>
      events
        .filter(
          (event) => event.createdBy === accountData.uid && !event.isDraft
        )
        .sort(
          (x, y) => new Date(y.start).getTime() - new Date(x.start).getTime()
        ),
    [accountData.uid, events]
  );

  const categoriesToShow = useMemo(
    () =>
      massText
        ? excludingMode
          ? massText.excludedSubSelectionIds
          : massText.subSelectionIds
        : [],
    [excludingMode, massText]
  );

  const netCategoriesToShow = useMemo(
    () =>
      massText
        ? excludingMode
          ? massText.excludedSubSelectionIds
          : massText.subSelectionIds.filter(
              (category) =>
                !massText.excludedSubSelectionIds.some(
                  (excludedCategory) => excludedCategory.id === category.id
                )
            )
        : [],
    [excludingMode, massText]
  );

  // Shows the selected or excluded people depending on the select type
  const newRecipientsToShow = useMemo(
    () => (excludingMode ? allExcludedPeople : allSelectedPeople),
    [excludingMode, allExcludedPeople, allSelectedPeople]
  );

  const netRecipients = useMemo(
    () =>
      excludingMode
        ? allExcludedPeople
        : allSelectedPeople.filter(
            (userId) => !allExcludedPeople.includes(userId)
          ),
    [allExcludedPeople, allSelectedPeople, excludingMode]
  );

  const iconColor = useMemo(
    () => (excludingMode ? Colors.RED3 : Colors.BLUE5),
    [excludingMode]
  );

  const excludedToShowLength = useMemo(
    () =>
      showCategories && massText
        ? massText.excludedSubSelectionIds.length
        : allExcludedPeople.length,
    [allExcludedPeople.length, massText, showCategories]
  );

  const visibleFollowers = useMemo(
    () =>
      displayAllFollowers
        ? followersData
        : followersData.filter(
            (follower) => follower.status === FollowerStatus.SUBSCRIBED
          ),
    [displayAllFollowers, followersData]
  );

  // added to refresh for any new followers during webapp session
  useOnMount(() => {
    loadUsers();
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (visibleFollowers.length) {
      const followers = await Promise.all(
        visibleFollowers
          .slice(0, Math.min(visibleFollowers.length, 2))
          .map(async (follower) => {
            const user = await fetchSingleUser(
              follower.uid,
              followingAccountData
            );
            return user;
          })
      );
      const definedFollowers = filterUndefinedValues(followers);

      setSuggestedFollowers(definedFollowers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleFollowers]);

  const containsUser = (user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query.toLowerCase());
  };

  const containsEvent = (event: Event, query: string) => {
    return event.title.toLowerCase().includes(query.toLowerCase());
  };

  const containsList = (list: AudienceList, query: string) => {
    return list.name.toLowerCase().includes(query.toLowerCase());
  };

  const itemsToShow = useMemo(() => {
    if (searchTerm === "") {
      return [];
    }

    const finalList: (AccountData | Event | AudienceList)[] = [];
    const userList = filter(fetchedUserData, (user: AccountData) => {
      return containsUser(user, searchTerm);
    });
    const eventList = filter(eventsHosting, (event: Event) => {
      return containsEvent(event, searchTerm);
    }).sort(
      (x: Event, y: Event) =>
        new Date(y.start).getTime() - new Date(x.start).getTime()
    );
    const userAudienceList = filter(audienceLists, (list: AudienceList) => {
      return containsList(list, searchTerm);
    });
    finalList.push(...eventList, ...userAudienceList, ...userList);

    return finalList;
  }, [audienceLists, eventsHosting, fetchedUserData, searchTerm]);

  const updateSearch = useCallback(
    (text: string) => {
      if (text !== "") {
        loadSearchResultsThrottled(text.toLowerCase());
      }
      setSearchTerm(text);
    },
    [loadSearchResultsThrottled]
  );

  const excludeOnPress = useCallback(() => {
    setExcludingMode(true);
  }, [setExcludingMode]);

  const navigateBrowseOption = useCallback(
    (type: SelectRecipientType) => {
      updateSelectType({ type: type });
    },
    [updateSelectType]
  );

  const selectAllFollowers = useCallback(() => {
    const followerIds = visibleFollowers.map((f) => f.uid);
    setAllSelectedPeople(followerIds);
    setShowDoneButton(false);
    backToOverview(followerIds.length);
  }, [
    backToOverview,
    setAllSelectedPeople,
    setShowDoneButton,
    visibleFollowers,
  ]);

  const newImportOnPress = useCallback(() => {
    if (!hasSubscriptionUnlockedAdvancedData(accountData)) {
      dispatch(notificationActions.setMarkitPlusModal(true));
      return;
    }
    navigate(NavigationId.IMPORT);
  }, [accountData, navigate, dispatch]);

  const selectCategoryPreviewOnPress = useCallback(
    (tabValue: number, isAllAttendees?: boolean, isAllPeople?: boolean) => {
      if (foundEvent && massText && updateMassTextSettings) {
        if (isAllAttendees) {
          if (
            massText.subSelectionIds.some(
              (category) =>
                category.type === SubSelectionType.STATUS &&
                category.id === SubSelectionStatusId.ATTENDEES
            )
          ) {
            updateMassTextSettings({ subSelectionIds: [] });
          } else {
            updateMassTextSettings({
              subSelectionIds: massText.subSelectionIds.concat({
                type: SubSelectionType.STATUS,
                id: SubSelectionStatusId.ATTENDEES,
                formOption: "",
              }),
            });
          }
        } else if (isAllPeople) {
          if (
            massText.subSelectionIds.some(
              (category) => category.type === SubSelectionType.ALL_PEOPLE
            )
          ) {
            updateMassTextSettings({ subSelectionIds: [] });
          } else {
            updateMassTextSettings({
              subSelectionIds: massText.subSelectionIds.concat({
                type: SubSelectionType.ALL_PEOPLE,
                id: "",
                formOption: "",
              }),
            });
          }
        } else {
          updateSelectType({
            type: SelectRecipientType.EVENTS,
            selectedItem: foundEvent,
            eventTabValue: tabValue,
            isPreview: true,
          });
        }
      }
    },
    [foundEvent, massText, updateMassTextSettings, updateSelectType]
  );

  const ActionRightIcon = useMemo(
    () => <Icon icon="ion:chevron-right" height={16} color={Colors.GRAY1} />,
    []
  );

  const renderEventItem = useCallback(
    (item: Event) => {
      return (
        <div
          onClick={() => {
            updateSelectType({
              type: SelectRecipientType.EVENTS,
              selectedItem: item,
              isPreview: true,
            });
          }}
        >
          <RenderedEventMassTextsModal event={item} hideType />
        </div>
      );
    },
    [updateSelectType]
  );

  const renderListItem = useCallback(
    (item: AudienceList) => {
      return (
        <AudienceListActionItem
          item={item}
          onPress={() =>
            updateSelectType({
              type: SelectRecipientType.LISTS,
              selectedItem: item,
              isPreview: true,
            })
          }
          alternateColor={iconColor}
        />
      );
    },
    [iconColor, updateSelectType]
  );

  const renderRecipientItem = useCallback(
    (item: AccountData) => {
      return (
        <MassTextsPersonItem
          user={item}
          unsavedPeople={newRecipientsToShow}
          setUnsavedPeople={(allPeople: string[]) => {
            if (excludingMode) {
              setAllExcludedPeople(allPeople);
            } else {
              setAllSelectedPeople(allPeople);
            }
          }}
          excludingMode={excludingMode}
          setProfileSelected={setProfileSelected}
        />
      );
    },
    [
      excludingMode,
      newRecipientsToShow,
      setAllExcludedPeople,
      setAllSelectedPeople,
      setProfileSelected,
    ]
  );

  const renderEmptyAudienceView = useMemo(
    () => (
      <div>
        <MassTextsActionItem
          title={"Build Your Audience"}
          icon={<Icon icon="ion:people" height={32} color={Colors.BLUE5} />}
          onPress={() =>
            setAlertText(
              "Press your profile pic in the top right header to share your profile!"
            )
          }
          subtext={"You currently have no followers"}
        />
        <HorizontalDivider altMargin={10} />
        <MassTextsActionItem
          title={"Import Contacts"}
          icon={<Icon icon="ion:person-add" height={29} color={iconColor} />}
          onPress={newImportOnPress}
        />
      </div>
    ),
    [iconColor, newImportOnPress]
  );

  const renderEmptySuggestedView = useMemo(
    () => (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={false}
        btnText={visibleFollowers.length === 0 ? "Build Audience" : undefined}
        containerStyles={{ paddingTop: 120 }}
        nonSearchEmptyView={
          <div className="ColumnNormal" style={{ gap: 10 }}>
            <span className="bodySubtext">Suggested</span>
            {visibleFollowers.length > 0 ? (
              <div>
                {eventsHosting
                  .slice(0, Math.min(eventsHosting.length, 2))
                  .map((event) => (
                    <div key={event.id}>
                      {renderEventItem(event)}
                      <HorizontalDivider altMargin={10} />
                    </div>
                  ))}
                {audienceLists
                  .slice(0, Math.min(audienceLists.length, 2))
                  .map((list) => (
                    <div key={list.id}>
                      {renderListItem(list)}
                      <HorizontalDivider altMargin={10} />
                    </div>
                  ))}
                {suggestedFollowers.map((user) =>
                  user ? renderRecipientItem(user) : null
                )}
              </div>
            ) : loadedAllFollowers && visibleFollowers.length === 0 ? (
              renderEmptyAudienceView
            ) : (
              <div className="Centering" style={{ paddingTop: 14 }}>
                <CircularProgress style={{ color: "#929292" }} size={20} />
              </div>
            )}
          </div>
        }
      />
    ),
    [
      audienceLists,
      eventsHosting,
      loadedAllFollowers,
      renderEmptyAudienceView,
      renderEventItem,
      renderListItem,
      renderRecipientItem,
      searchTerm,
      visibleFollowers.length,
      suggestedFollowers,
    ]
  );

  const renderIndividualListItem = useCallback(
    (item: Event | AudienceList | AccountData) => (
      <>
        {(item as AccountData).uid
          ? renderRecipientItem(item as AccountData)
          : (item as Event).title
          ? renderEventItem(item as Event)
          : renderListItem(item as AudienceList)}
        {!(item as AccountData).uid ? (
          <HorizontalDivider altMargin={10} />
        ) : null}
      </>
    ),
    [renderEventItem, renderListItem, renderRecipientItem]
  );

  const renderListItems = useMemo(
    () => (
      <div className="AllowScroll" style={{ paddingBottom: 120 }}>
        <FlatList
          list={itemsToShow}
          renderItem={(item: Event | AudienceList | AccountData) =>
            renderIndividualListItem(item)
          }
          renderWhenEmpty={() => renderEmptySuggestedView}
        />
      </div>
    ),
    [itemsToShow, renderEmptySuggestedView, renderIndividualListItem]
  );

  const ActionItemDivider = (
    <div style={{ paddingLeft: 62 }}>
      <HorizontalDivider />
    </div>
  );

  return (
    <>
      {!showCategories ? (
        <div className="AlignedRow" style={{ gap: 10, marginBottom: 14 }}>
          <SearchBoxContainer
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => updateSearch(e.target.value)}
            onFocus={() => setShowDoneButton(true)}
            containerStyles={{ marginTop: 0 }}
          />
          {showDoneButton ? (
            <m.div
              key="selectrecipientsdone"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeIn", duration: 0.2, type: "tween" }}
              style={{ cursor: "pointer" }}
            >
              <span
                onClick={() => {
                  setSearchTerm("");
                  setShowDoneButton(false);
                }}
                style={{ color: iconColor, fontWeight: "500" }}
              >
                Done
              </span>
            </m.div>
          ) : null}
        </div>
      ) : null}
      {showDoneButton ? (
        renderListItems
      ) : (
        <div className="ColumnNormal PreventScroll" style={{ gap: 16 }}>
          {newRecipientsToShow.length > 0 || categoriesToShow.length > 0 ? (
            <div style={styles.sectionContainer}>
              <div style={{ width: "100%" }}>
                <div
                  className="AlignedRowSpacedSelect"
                  style={{
                    pointerEvents:
                      netRecipients.length > 0 || netCategoriesToShow.length > 0
                        ? "all"
                        : "none",
                  }}
                  onClick={() =>
                    updateSelectType({
                      type: SelectRecipientType.RECIPIENTS,
                    })
                  }
                >
                  <div className="ColumnNormalSelect">
                    <span
                      style={{
                        color:
                          showCategories && !excludingMode
                            ? Colors.PURPLE7
                            : iconColor,
                        ...styles.mainText,
                      }}
                    >
                      {showCategories ? (
                        <>
                          {netCategoriesToShow.length}{" "}
                          {!excludingMode &&
                          categoriesToShow.length !== netCategoriesToShow.length
                            ? "Net "
                            : ""}
                          {netCategoriesToShow.length !== 1
                            ? "Categories "
                            : "Category "}
                          {excludingMode ? "Excluded" : ""}
                        </>
                      ) : (
                        <>
                          {excludingMode
                            ? allExcludedPeople.length
                            : netRecipients.length}{" "}
                          {excludingMode
                            ? "Excluded"
                            : `${
                                allExcludedPeople.length > 0 ? "Net " : ""
                              }Recipient${
                                netRecipients.length !== 1 ? "s" : ""
                              }`}
                        </>
                      )}
                    </span>
                  </div>
                  {netRecipients.length > 0 || netCategoriesToShow.length > 0
                    ? ActionRightIcon
                    : null}
                </div>
                {!excludingMode ? (
                  <>
                    <HorizontalDivider altMargin={14} />
                    <div
                      onClick={excludeOnPress}
                      className="AlignedRowSpacedSelect"
                    >
                      {excludedToShowLength > 0 ? (
                        <span
                          style={{
                            ...styles.mainText,
                            color: Colors.RED3,
                          }}
                        >
                          {excludedToShowLength}{" "}
                          {showCategories
                            ? excludedToShowLength !== 1
                              ? "Categories"
                              : "Category"
                            : ""}{" "}
                          Excluded
                        </span>
                      ) : (
                        <span style={styles.mainText}>Exclude</span>
                      )}
                      {ActionRightIcon}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
          {foundEvent && showCategories ? (
            <SelectRecipientsSuggestedCategories
              event={foundEvent}
              excludingMode={excludingMode}
              selectedCategories={massText ? massText.subSelectionIds : []}
              selectOnPress={(
                tabValue: number,
                isAllAttendees?: boolean,
                isAllPeople?: boolean
              ) =>
                selectCategoryPreviewOnPress(
                  tabValue,
                  isAllAttendees,
                  isAllPeople
                )
              }
              hideAllAttendees={liveTotalGuests === 0 && liveTotalRequested > 0}
            />
          ) : (
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <span className="bodySubtext">Browse...</span>
              {visibleFollowers.length === 0 ? (
                renderEmptyAudienceView
              ) : (
                <>
                  {!excludingMode &&
                  visibleFollowers.length !== allSelectedPeople.length ? (
                    <>
                      <MassTextsActionItem
                        title={"Select All Followers"}
                        icon={
                          <Icon
                            icon="ion:people"
                            height={32}
                            color={iconColor}
                          />
                        }
                        onPress={selectAllFollowers}
                        subtext={`${visibleFollowers.length} People`}
                        alternateRightIcon={
                          !loadedAllFollowers ? (
                            <CircularProgress
                              style={{ color: Colors.GRAY1 }}
                              size={18}
                            />
                          ) : undefined
                        }
                        disabled={!loadedAllFollowers}
                        loading={!loadedAllFollowers}
                      />
                      {ActionItemDivider}
                    </>
                  ) : null}
                  <MassTextsActionItem
                    title={"Followers"}
                    icon={
                      <Icon icon="ion:person" height={30} color={iconColor} />
                    }
                    onPress={() =>
                      navigateBrowseOption(SelectRecipientType.PEOPLE)
                    }
                    alternateRightIcon={ActionRightIcon}
                  />
                  {ActionItemDivider}
                  {accountData.membership.state === MembershipState.ACTIVE ? (
                    <>
                      <MassTextsActionItem
                        title={"Memberships"}
                        icon={
                          <Icon
                            icon="ion:ribbon"
                            height={32}
                            color={iconColor}
                          />
                        }
                        onPress={() =>
                          navigateBrowseOption(SelectRecipientType.MEMBERSHIPS)
                        }
                        alternateRightIcon={ActionRightIcon}
                      />
                      {ActionItemDivider}
                    </>
                  ) : null}
                  <MassTextsActionItem
                    title={"Events"}
                    icon={
                      <Icon icon="ion:calendar" height={30} color={iconColor} />
                    }
                    onPress={() =>
                      navigateBrowseOption(SelectRecipientType.EVENTS)
                    }
                    alternateRightIcon={ActionRightIcon}
                  />
                  {ActionItemDivider}
                  <MassTextsActionItem
                    title={"Lists"}
                    icon={
                      <Icon icon="ion:list" height={32} color={iconColor} />
                    }
                    onPress={() =>
                      navigateBrowseOption(SelectRecipientType.LISTS)
                    }
                    alternateRightIcon={ActionRightIcon}
                  />
                  {ActionItemDivider}
                  <MassTextsActionItem
                    title={"Collected Data"}
                    icon={
                      <Icon icon="ion:document" height={32} color={iconColor} />
                    }
                    onPress={() =>
                      navigateBrowseOption(SelectRecipientType.COLLECTED_DATA)
                    }
                    alternateRightIcon={ActionRightIcon}
                  />
                  {ActionItemDivider}
                  <MassTextsActionItem
                    title={"Imports"}
                    icon={
                      <Icon
                        icon="ion:cloud-download"
                        height={28}
                        color={iconColor}
                      />
                    }
                    onPress={() =>
                      navigateBrowseOption(SelectRecipientType.IMPORTS)
                    }
                    alternateRightIcon={ActionRightIcon}
                  />
                  {ActionItemDivider}
                </>
              )}
            </div>
          )}
        </div>
      )}
      <AlertContainer
        headerComp={alertText}
        closeModal={() => setAlertText("")}
        hideModal={alertText === ""}
      />
    </>
  );
};

export default SelectRecipientsOverview;
