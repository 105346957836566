import { AudienceList, SelectRecipientGroupType } from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../../redux/slices/accountSlice";
import { useCallback, useMemo, useState } from "react";
import filter from "lodash.filter";
import FlatList from "flatlist-react/lib";
import SearchBoxContainer from "../../../../Containers/SearchBoxContainer";
import { HorizontalDivider } from "../../../../Dividers/HorizontalDivider";
import { Colors } from "../../../../../utils/colors";
import { EmptyStateFlatlist } from "../../../../EmptyStates/EmptyStateFlatlist";
import { sortAudienceListsDisplay } from "@markit/common.utils";
import AudienceListActionItem from "../../../../DisplayItem/AudienceListItem/AudienceListActionItem";

type SelectRecipientsAudienceListLibraryProps = {
  excludingMode: boolean;
  updateSelectType: (selectType: Partial<SelectRecipientGroupType>) => void;
};

const SelectRecipientsAudienceListLibrary = (
  props: SelectRecipientsAudienceListLibraryProps
) => {
  const { excludingMode, updateSelectType } = props;
  const { audienceListDetails } = useSelector(getAccountState).account;
  const [searchTerm, setSearchTerm] = useState("");

  const audienceLists = useMemo(
    () => audienceListDetails.map((listDetail) => listDetail.list),
    [audienceListDetails]
  );

  const containsList = useCallback((list: AudienceList, query: string) => {
    return list.name.toLowerCase().includes(query.toLowerCase());
  }, []);

  const handleSearch = (text: string) => {
    setSearchTerm(text);
  };

  const itemsToShow: AudienceList[] = useMemo(() => {
    const userAudienceLists: AudienceList[] =
      sortAudienceListsDisplay(audienceLists);
    let searchResults: AudienceList[] = userAudienceLists;
    if (searchTerm !== "") {
      searchResults = filter(userAudienceLists, (list: AudienceList) => {
        return containsList(list, searchTerm);
      });
    }
    return searchResults;
  }, [audienceLists, searchTerm, containsList]);

  return (
    <>
      <SearchBoxContainer
        value={searchTerm}
        placeholder={"Search Lists..."}
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
        containerStyles={{ marginTop: 0, marginBottom: 14 }}
      />
      <div className="AllowScroll" style={{ paddingBottom: 200 }}>
        <FlatList
          list={itemsToShow}
          renderItem={(item: AudienceList) => (
            <>
              <AudienceListActionItem
                item={item}
                onPress={() => updateSelectType({ selectedItem: item })}
                alternateColor={excludingMode ? Colors.RED3 : Colors.BLUE5}
              />
              <HorizontalDivider altMargin={10} />
            </>
          )}
          renderWhenEmpty={() => (
            <EmptyStateFlatlist
              containerStyles={{ paddingTop: 200 }}
              isLoading={false}
              searchTerm={searchTerm}
              nonSearchEmptyView={<></>}
            />
          )}
        />
      </div>
    </>
  );
};

export default SelectRecipientsAudienceListLibrary;
