import "../../css/Subscription.css";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { API } from "../../API";
import { testStripe } from "../../components/FullEvent/FullEventInputForm";
import { MixpanelContext } from "../../context/AnalyticsService";
import { useSelector } from "react-redux";
import { getAccountState, LoginState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { isDesktop } from "react-device-detect";
import TopHeader from "../../components/TopHeader";
import ConfirmDeleteModal from "../../components/Containers/ConfirmPopups/ConfirmDeleteModal";
import { useTheme } from "../../hooks/useTheme";
import StandardBorderedContainer from "../../components/Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../../components/Dividers/HorizontalDivider";
import RectangleButton from "../../components/Buttons/RectangleButton";
import {
  MARKIT_HOTLINE_NUMBER,
  mostRecentSubscription,
  subscriptionPlanType,
} from "@markit/common.utils";
import { CircularProgress } from "@mui/material";
import SubscriptionBillingItem from "../../components/Subscription/Items/SubscriptionBillingItem";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";

type SubscriptionFreezeProps = {};

const SubscriptionFreeze = memo(function SubscriptionFreezeFn(
  props: SubscriptionFreezeProps
) {
  const { theme } = useTheme();
  const { accountData, loggedIn, appInitialized } =
    useSelector(getAccountState).account;
  const mixpanel = useContext(MixpanelContext);
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [confirmationView, setConfirmationView] = useState(false);
  const [subscriptionConfirm, setSubscriptionConfirm] = useState(false);
  const [loading, setLoading] = useState(true);

  const styles = {
    middleContainer: {
      backgroundColor: Colors.WHITE1,
      flex: 1,
      flexGrow: 1,
      paddingTop: 30,
      paddingBottom: 100,
      gap: 24,
    },
  };

  useEffect(() => {
    if (appInitialized && loggedIn !== LoginState.LOGGED_OUT && loading) {
      setLoading(false);
    }
  }, [accountData, appInitialized, loading, loggedIn]);

  const mostRecentSub = useMemo(
    () => mostRecentSubscription(accountData),
    [accountData]
  );

  const subscriptionPlan = useMemo(
    () =>
      mostRecentSub
        ? subscriptionPlanType(mostRecentSub.price[0].id, accountData.uid)
        : undefined,
    [accountData.uid, mostRecentSub]
  );

  const frozenSubscription = useMemo(
    () => accountData.customer.state === "FROZEN",
    [accountData.customer.state]
  );

  const freezeSubscription = useCallback(async () => {
    setCancelling(true);
    if (!frozenSubscription) {
      if (mixpanel) {
        mixpanel.track("User Freezing Markit+ Subscription", {
          distinct_id: accountData.uid,
          date: new Date(),
        });
      }
      await (testStripe
        ? API.user.freezeTestSubscription
        : API.user.freezeSubscription)({
        uid: accountData.uid,
      })
        .then((res: any) => {
          setConfirmationView(true);
        })
        .catch((err: any) => {
          console.error(err.message);
          return undefined;
        })
        .finally(() => setCancelling(false));
    } else {
      if (mixpanel) {
        mixpanel.track("User Un-Freezing Markit+ Subscription", {
          date: new Date(),
          distinct_id: accountData.uid,
        });
      }
      await (testStripe
        ? API.user.unfreezeTestSubscription
        : API.user.unfreezeSubscription)({
        uid: accountData.uid,
      })
        .then((res: any) => {
          setConfirmationView(true);
        })
        .catch((err: any) => {
          console.error(err.message);
          return undefined;
        })
        .finally(() => setCancelling(false));
    }
  }, [frozenSubscription, accountData.uid, mixpanel]);

  return (
    <div className="ColumnNormal" style={{ height: "100vh" }}>
      <TopHeader />
      <div className="ColumnNormal" style={{ paddingTop: 30, flex: 1 }}>
        <div className="AlignedColumn">
          <span style={{ fontSize: 32, fontWeight: "500" }}>
            {frozenSubscription
              ? confirmationView
                ? "Subscription Froze"
                : "Un-Freeze Subscription"
              : "Freeze Subscription"}
          </span>
          <div
            style={{
              width: isDesktop ? 610 : "90%",
              textAlign: "center",
              paddingBlock: 30,
            }}
          >
            <span className="bodyMedium" style={theme.LabelText}>
              You can freeze your Markit+ subscription for up to 6 months
              without being billed. After 6 months, your creator phone number
              will be permanently released and all of your conversations
              associated with that number deleted.
              <br />
              <br />
              While frozen, you are unable to use any form of texting services,
              2-way communication, and all other Markit+ services.
            </span>
          </div>
        </div>
        {!loading && subscriptionPlan ? (
          <div className="AlignedColumn" style={styles.middleContainer}>
            <StandardBorderedContainer
              containerStyles={{
                paddingBottom: 14,
                paddingTop: 20,
                borderRadius: 14,
                backgroundColor: Colors.WHITE,
                width: isDesktop ? 362 : "90%",
              }}
            >
              <div className="ColumnNormal" style={{ gap: 14 }}>
                <SubscriptionBillingItem
                  subscription={mostRecentSub}
                  showBaseline={true}
                  showOverflow={false}
                  amount={subscriptionPlan.price / 100}
                />
                <HorizontalDivider />
              </div>
              <div
                className="ColumnNormal"
                style={{ gap: 4, paddingTop: 24, paddingInline: 14 }}
              >
                <RectangleButton
                  buttonLabel={
                    frozenSubscription
                      ? confirmationView
                        ? "Subscription Froze"
                        : "Un-Freeze Subscription"
                      : "Freeze Subscription"
                  }
                  onPress={() => setSubscriptionConfirm(true)}
                  altColor={Colors.RED3}
                  altTextColor={Colors.WHITE}
                  altPaddingHorz={14}
                  altPaddingVert={14}
                  loading={cancelling}
                  disabled={confirmationView}
                />
                {cancelling ? (
                  <span
                    className="smallBodySubtext"
                    style={{ textAlign: "center" }}
                  >
                    Loading... Please do not leave this page!
                  </span>
                ) : null}
              </div>
            </StandardBorderedContainer>
            <span className="bodySubtext">
              For questions, contact our hotline at{" "}
              <a
                href={`sms:${MARKIT_HOTLINE_NUMBER}`}
                style={{ ...theme.PrimaryText, fontWeight: 500 }}
              >
                {formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}
              </a>
            </span>
          </div>
        ) : (
          <div
            className="Centering"
            style={{ flex: 1, backgroundColor: Colors.WHITE1 }}
          >
            <CircularProgress size={24} style={{ color: Colors.BLACK }} />
          </div>
        )}
      </div>
      <ConfirmDeleteModal
        heading={"Are you sure you want to freeze your subscription?"}
        subtext={"You can unfreeze at any time through your profile."}
        deleteButtonText={"Freeze"}
        hideModal={!subscriptionConfirm}
        setIsVisible={setSubscriptionConfirm}
        deleteOnPress={freezeSubscription}
        theme={theme}
      />
    </div>
  );
});

export default SubscriptionFreeze;
