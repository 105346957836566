import { useCallback, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { AnimatePresence, domAnimation, LazyMotion, m } from "framer-motion";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { Colors } from "../../../utils/colors";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import PresetAutomationTriggerIcon from "./Items/PresetAutomationTriggerIcon";
import { useTheme } from "../../../hooks/useTheme";
import { Campaign } from "@markit/common.types";
import { useSelector } from "react-redux";
import { getEventState } from "../../../redux/slices/eventSlice";
import { useNavigate } from "react-router-dom";
import TriggerTextRecipients from "../MassTexts/TriggerTextRecipients";
import { onCreatorProfileNavigatePath } from "../../../utils/navigationUtils";
import {
  displayAutomationTriggerActionSubtext,
  displayAutomationTriggerLabel,
  NON_SUBSCRIBER_REPLY_CAMPAIGN_ID,
  SUBSCRIBED_CAMPAIGN_NAME,
} from "@markit/common.utils";

type TriggerSummaryPanelProps = {
  campaign: Campaign;
  totalRecipients: number;
};

const TriggerSummaryPanel = (props: TriggerSummaryPanelProps) => {
  const { campaign, totalRecipients } = props;
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { events } = useSelector(getEventState).events;
  const [recipientsScreen, setRecipientsScreen] = useState(false);

  const styles = {
    sectionContainer: {
      paddingBlock: 14,
      backgroundColor: Colors.WHITE1,
      borderRadius: 12,
      margin: 20,
    },
    showcaseBody: {
      paddingInline: 14,
      gap: 14,
    },
  };

  const foundEvent = useMemo(
    () => events.find((event) => event.id === campaign.eventId),
    [campaign.eventId, events]
  );

  const navigateTriggerOnPress = useCallback(
    (profileTabValue?: number) => {
      if (campaign.eventId) {
        navigate(`/e/${campaign.eventId}/dashboard`, {
          state: { eventId: campaign.eventId },
        });
      } else {
        onCreatorProfileNavigatePath(navigate, profileTabValue);
      }
    },
    [campaign.eventId, navigate]
  );

  return (
    <div
      className="ColumnNormal PreventScroll"
      style={{ backgroundColor: Colors.WHITE }}
    >
      <AnimatePresence>
        <LazyMotion features={domAnimation}>
          <m.div
            key="triggersummaryheader"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: "easeIn" }}
          >
            <div
              className="AlignedRowSpaced"
              style={{ height: 58, paddingInline: 20 }}
            >
              <div className="AlignedRow" style={{ gap: 14 }}>
                {recipientsScreen ? (
                  <div
                    className="LargePopupPanelClose"
                    onClick={() => setRecipientsScreen(false)}
                  >
                    <Icon
                      icon={"ion:chevron-back"}
                      height={24}
                      color={Colors.GRAY3}
                    />
                  </div>
                ) : null}
                <span className="sectionTitle">Trigger Details</span>
              </div>
            </div>
          </m.div>
        </LazyMotion>
      </AnimatePresence>
      <HorizontalDivider />
      <AnimatePresence>
        <LazyMotion features={domAnimation}>
          <m.div
            key="triggersummarybody"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: "easeIn" }}
            className="PreventScroll"
          >
            {!recipientsScreen ? (
              <StandardBorderedContainer
                containerStyles={{
                  ...styles.sectionContainer,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="AlignedRow" style={styles.showcaseBody}>
                  <StandardBorderedContainer
                    containerStyles={{
                      width: 38,
                      height: 38,
                      borderRadius: 20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: Colors.WHITE,
                    }}
                  >
                    <Icon icon={"ion:flash"} height={20} />
                  </StandardBorderedContainer>
                  <div className="ColumnNormal" style={{ gap: 4 }}>
                    <span className="sectionTitle">Trigger</span>
                    <span className="smallBodySubtext">
                      People enter this automation when this happens
                    </span>
                  </div>
                </div>
                <HorizontalDivider altMargin={14} />
                <div className="AlignedRowSpaced" style={styles.showcaseBody}>
                  <div className="AlignedRow" style={{ gap: 14 }}>
                    {foundEvent ? (
                      <img
                        src={foundEvent.photoURL}
                        alt={"AutomationEventCampaignItem"}
                        style={{ width: 37, height: 37, borderRadius: 8 }}
                      />
                    ) : (
                      <PresetAutomationTriggerIcon
                        campaignTitle={campaign.title}
                        containerStyles={{
                          border: `1.5px solid ${theme.DividerColor.borderColor}`,
                          backgroundColor: Colors.WHITE,
                        }}
                        iconSize={22}
                      />
                    )}
                    <div className="ColumnNormal" style={{ gap: 4 }}>
                      <span className="sectionTitle">
                        {displayAutomationTriggerLabel(campaign, foundEvent)}
                      </span>
                      <span className="smallBodySubtext">
                        {displayAutomationTriggerActionSubtext(
                          campaign,
                          foundEvent
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    className="AlignedRowSelect"
                    style={{ gap: 7 }}
                    onClick={() =>
                      navigateTriggerOnPress(
                        campaign.id === NON_SUBSCRIBER_REPLY_CAMPAIGN_ID
                          ? 2
                          : campaign.title === SUBSCRIBED_CAMPAIGN_NAME
                          ? 1
                          : undefined
                      )
                    }
                  >
                    <span style={{ fontSize: 14 }}>
                      {campaign.eventId
                        ? "Event Dashboard"
                        : "Profile Settings"}
                    </span>
                    <Icon icon="ion:chevron-right" height={14} />
                  </div>
                </div>
                {campaign.id !== NON_SUBSCRIBER_REPLY_CAMPAIGN_ID ? (
                  <>
                    <HorizontalDivider altMargin={14} />
                    <div
                      className="AlignedRowSpaced"
                      style={{ ...styles.showcaseBody, paddingBlock: 7 }}
                    >
                      <div className="AlignedRow" style={{ gap: 7 }}>
                        <span className="sectionTitle">{totalRecipients}</span>
                        <span className="bodySubtext">Started Flow</span>
                      </div>
                      {totalRecipients > 0 ? (
                        <div
                          className="AlignedRowSelect"
                          onClick={() => setRecipientsScreen(true)}
                          style={{ gap: 7 }}
                        >
                          <span style={{ fontSize: 14 }}>See All</span>
                          <Icon icon="ion:chevron-right" height={14} />
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </StandardBorderedContainer>
            ) : (
              <TriggerTextRecipients campaign={campaign} />
            )}
          </m.div>
        </LazyMotion>
      </AnimatePresence>
    </div>
  );
};

export default TriggerSummaryPanel;
