import { useCallback, useState } from "react";
import {
  MassText,
  MassTextAttachment,
  MassTextAttachmentType,
} from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../redux/slices/accountSlice";
import {
  deepCopy,
  makeEmptyMassText,
  makeEmptyTextAttachment,
  massTextMessageConverter,
  massTextPlaceholder,
} from "@markit/common.utils";
import { getMassTextAttachmentsSnap } from "../utils/FirebaseUtils";
import useAsyncOnMount from "./useAsyncEffectOnMount";
import { generate } from "shortid";

export type AttachmentMode = {
  icon: string;
  label: string;
};

export const attachmentModes: AttachmentMode[] = [
  {
    icon: "ion:calendar",
    label: MassTextAttachmentType.LINK,
  },
  {
    icon: "ion:gift",
    label: MassTextAttachmentType.FREE_TICKET_LINK,
  },
  {
    icon: "ion:pricetag",
    label: MassTextAttachmentType.DISCOUNTED_TICKET_LINK,
  },
  {
    icon: "ion:ticket-outline",
    label: MassTextAttachmentType.TICKET_LINK,
  },
  {
    icon: "mdi:calendar-account",
    label: MassTextAttachmentType.CALENDAR_INVITE,
  },
  {
    icon: "mdi:ticket-confirmation-outline",
    label: MassTextAttachmentType.GENERATE_TICKET_BUNDLE,
  },
];

type useMassTextManagementProps = {
  massText?: MassText;
  campaignId?: string;
  eventId?: string;
  isDuplicateText?: boolean;
};

export const useMassTextManagement = (props: useMassTextManagementProps) => {
  const { massText, campaignId, eventId, isDuplicateText } = props;
  const { accountData } = useSelector(getAccountState).account;
  const [massTextSettings, setMassTextSettings] = useState<MassText>(
    makeEmptyMassText(generate(), accountData.uid, accountData.fullName, {
      message: massTextPlaceholder(accountData.fullName),
      eventRefId: eventId,
      campaignId: campaignId ?? "",
    })
  );
  const [attachmentSettings, setAttachmentSettings] =
    useState<MassTextAttachment>(makeEmptyTextAttachment(generate()));
  const [loaded, setLoaded] = useState(false);

  const updateMassTextSettings = useCallback(
    (newMassTextSettings: Partial<MassText>) => {
      setMassTextSettings((prevMassTextSettings) => {
        return { ...prevMassTextSettings, ...newMassTextSettings };
      });
    },
    []
  );

  const updateAttachmentSettings = useCallback(
    (newAttachmentSettings: Partial<MassTextAttachment>) => {
      setAttachmentSettings((prevAttachmentSettings) => {
        return { ...prevAttachmentSettings, ...newAttachmentSettings };
      });
    },
    []
  );

  const resetMassText = useCallback(() => {
    setMassTextSettings(
      makeEmptyMassText(generate(), accountData.uid, accountData.fullName, {
        eventRefId: eventId,
      })
    );
  }, [accountData.fullName, accountData.uid, eventId]);

  const resetMassTextAttachment = useCallback(() => {
    setAttachmentSettings(makeEmptyTextAttachment(generate()));
  }, []);

  useAsyncOnMount(async () => {
    const emptyMassText = makeEmptyMassText(
      generate(),
      accountData.uid,
      accountData.fullName,
      { eventRefId: eventId, campaignId: isDuplicateText ? "" : campaignId }
    );
    if (isDuplicateText && massText) {
      const oldAttachmentsSnap = await getMassTextAttachmentsSnap(
        accountData.uid,
        massText.campaignId,
        massText.id
      );
      const attachments = oldAttachmentsSnap.docs.map((doc) => doc.data());
      const duplicateText: MassText = {
        ...emptyMassText,
        message: massTextMessageConverter(massText.message, attachments),
        catchUpEnabled: massText.catchUpEnabled,
        mediaUrl: massText.mediaUrl,
      };
      setMassTextSettings(duplicateText);
    } else {
      setMassTextSettings(massText ? deepCopy(massText) : emptyMassText);
    }
    setLoaded(true);
  });

  return {
    updateMassTextSettings,
    massTextSettings,
    makeEmptyMassText,
    attachmentSettings,
    updateAttachmentSettings,
    resetMassText,
    resetMassTextAttachment,
    loaded,
  };
};
