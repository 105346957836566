import "../../css/App.css";
import "../../css/Subscription.css";
import {
  useStripe,
  useElements,
  PaymentElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import {
  PaymentRequest,
  Stripe,
  StripeElements,
  StripePaymentRequestButtonElementClickEvent,
} from "@stripe/stripe-js";
import { MixpanelContext } from "../../context/AnalyticsService";
import { useContext, useEffect, useState } from "react";
import { Colors } from "../../utils/colors";
import RectangleButton from "../Buttons/RectangleButton";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";

export const enum SubscriptionCheckoutType {
  TRIAL = "trial subscription",
  CANCEL = "cancel subscription",
  FOLLOWER_MEMBERSHIP = "follower subscription",
  NORMAL = "normal subscription",
}

type SubscriptionCheckoutFormProps = {
  label: string;
  amount: number;
  clientSecret: string | undefined;
  isReadyForPayment: boolean;
  processing: boolean;
  setProcessing: (processing: boolean) => void;
  onPress: (stripe: Stripe, elements: StripeElements) => Promise<void>;
  onConfirmApplePay: () => void;
  checkoutType: SubscriptionCheckoutType;
};

const SubscriptionCheckoutForm = ({
  label,
  amount,
  clientSecret,
  isReadyForPayment,
  processing,
  setProcessing,
  onPress,
  onConfirmApplePay,
  checkoutType,
}: SubscriptionCheckoutFormProps) => {
  const uid = useSelector(getAccountState).account.accountData.uid;
  const stripe = useStripe();
  const elements = useElements();
  const mixpanel = useContext(MixpanelContext);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: { label, amount },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    console.log("payment request", pr !== undefined);

    // Check the availability of the Payment Request API.
    pr.canMakePayment()
      .then((result) => {
        console.log("can make payment");
        if (result) {
          setPaymentRequest(pr);
        }
      })
      .catch(() => {
        alert("Error showing payment sheet.");
      });
  }, [amount, elements, label, stripe]);

  // Moving this out of the useEffect fixed a bug where "options.clientSecret is not a mutable property" was printed infinitely.
  // We have this in CheckoutForm too, but it doesn't do infinite printing like it did here.
  if (paymentRequest && stripe) {
    paymentRequest.on("paymentmethod", async (e) => {
      if (!clientSecret) {
        e.complete("fail");
        return;
      }

      setProcessing(true);
      const { error: stripeError } =
        checkoutType === SubscriptionCheckoutType.TRIAL
          ? await stripe.confirmCardSetup(
              clientSecret,
              {
                payment_method: e.paymentMethod.id,
              },
              { handleActions: false }
            )
          : await stripe.confirmCardPayment(
              clientSecret,
              {
                payment_method: e.paymentMethod.id,
              },
              { handleActions: false }
            );

      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        e.complete("fail");
        setProcessing(false);
        mixpanel.track("Webapp: Stripe Error", {
          error_message: stripeError,
          distinct_id: uid,
        });
      } else {
        e.complete("success");
        onConfirmApplePay();
      }
    });
  }

  if (!stripe || !elements) {
    return null;
  }
  return (
    <>
      {paymentRequest ? (
        <div
          style={{ paddingTop: 10 }}
          aria-disabled={processing}
          className="applePayElement"
        >
          <PaymentRequestButtonElement
            options={{ paymentRequest }}
            onClick={(
              clickEvent: StripePaymentRequestButtonElementClickEvent
            ) => {
              if (!isReadyForPayment) {
                clickEvent.preventDefault();
                mixpanel.track("Webapp: Ready For Payment Error", {});
              }
            }}
          />
        </div>
      ) : null}
      <PaymentElement options={{ wallets: { applePay: "never" } }} />
      <RectangleButton
        buttonLabel={
          checkoutType === SubscriptionCheckoutType.FOLLOWER_MEMBERSHIP
            ? "Confirm and Pay"
            : checkoutType === SubscriptionCheckoutType.CANCEL
            ? "Pay and Cancel Subscription"
            : checkoutType === SubscriptionCheckoutType.TRIAL
            ? "Set up card"
            : "Pay with card"
        }
        onPress={() => {
          if (!stripe) {
            return;
          }
          onPress(stripe, elements);
        }}
        altPaddingVert={14}
        altColor={
          checkoutType === SubscriptionCheckoutType.FOLLOWER_MEMBERSHIP
            ? Colors.RED2
            : checkoutType === SubscriptionCheckoutType.CANCEL
            ? Colors.RED3
            : Colors.BLACK
        }
        altTextColor={Colors.WHITE}
        disabled={processing}
        loading={processing}
        containerStyles={{ marginTop: 14 }}
      />
    </>
  );
};

export default SubscriptionCheckoutForm;
