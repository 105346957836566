import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import CreatorModePanelWrapper from "../../../components/CreatorDashboard/CreatorModePanelWrapper";
import { Colors } from "../../../utils/colors";
import { TabPanel } from "../../../components/FullEventSubComponents/TabPanel";
import EmptyStateButton from "../../../components/Buttons/EmptyStateButton";
import { connectToStripeAccount } from "../../../utils/stripeAccountUtils";
import { Icon } from "@iconify/react";

const TransactionsPanel = () => {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const [tabValue, setTabValue] = useState(0);

  const styles = {
    textingPanelMainContentContainer: {
      backgroundColor: Colors.WHITE,
      paddingInline: 30,
      paddingTop: 30,
      height: "100%",
    },
  };

  const manageStripe = useCallback(async () => {
    await connectToStripeAccount(accountData.uid);
  }, [accountData.uid]);

  return (
    <CreatorModePanelWrapper
      title={"Transactions"}
      headerRight={<></>}
      headerTabs={{
        tabNames: ["Stripe Payouts", "Payment Disputes"],
        tabValue: tabValue,
        setTabValue: setTabValue,
      }}
    >
      <div style={styles.textingPanelMainContentContainer}>
        <TabPanel value={tabValue} index={0}>
          <div className="Centering" style={{ minHeight: "50vh" }}>
            <EmptyStateButton
              title="Manage Transactions in Stripe"
              description="You can view and manage all of your transactions in Stripe or the Markit app."
              btnText="Visit Stripe"
              onPress={manageStripe}
              icon={
                <Icon icon={"fa:cc-stripe"} height={38} color={Colors.PURPLE11} />
              }
              iconBox={80}
            />
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="EventDashboardContainerSpaceBetween">
            <div>
              <div className="EventDashboardContentHeader">Manage in app</div>
              <span className="EventDashboardContentDescription">
                Manage all your payout disputes in the Markit app.
              </span>
            </div>
          </div>
        </TabPanel>
      </div>
    </CreatorModePanelWrapper>
  );
};

export default TransactionsPanel;
