import "../../../css/GlobalStyles.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import CreatorModePanelWrapper from "../../../components/CreatorDashboard/CreatorModePanelWrapper";
import { TabPanel } from "../../../components/FullEventSubComponents/TabPanel";
import { Colors } from "../../../utils/colors";
import ProfilePageFull from "../../../components/ProfilePage/ProfilePageFull";
import ProfileWelcomeFlow from "../../../components/CreatorDashboard/CreatorProfile/ProfileWelcomeFlow";
import ProfileAccountDetails from "../../../components/CreatorDashboard/CreatorProfile/ProfileAccountDetails";
import { useLocation } from "react-router-dom";
import RectangleButtonCreatorPanel from "../../../components/Buttons/RectangleButtonCreatorPanel";
import ShareProfileLinkModal from "../../../components/CreatorDashboard/CreatorProfile/ShareProfileLinkModal";
import ProfileShareNumber from "../../../components/CreatorDashboard/CreatorProfile/ProfileShareNumber";

enum ProfileTabs {
  PUBLIC_PROFILE = 0,
  WELCOME_FLOW = 1,
  SHARE_NUMBER = 2,
  SETTINGS = 2,
}

const ProfilePanel = () => {
  const { accountData } = useSelector(getAccountState).account;
  const { initialTabValue } = useLocation().state || {};
  const [tabValue, setTabValue] = useState<ProfileTabs>(
    initialTabValue || ProfileTabs.PUBLIC_PROFILE
  );
  const [shareModal, setShareModal] = useState(false);

  const styles = {
    bodyContainer: {
      backgroundColor: tabValue === 0 ? Colors.WHITE : Colors.WHITE1,
      paddingTop: tabValue === 0 ? 0 : 30,
      paddingInline: 30,
      height: "100%",
    },
  };

  return (
    <CreatorModePanelWrapper
      title={"Profile"}
      headerRight={
        <RectangleButtonCreatorPanel
          title="Share Profile"
          iconName="ion:share"
          onPress={() => setShareModal(true)}
        />
      }
      headerTabs={{
        tabNames: [
          "Public Profile",
          "Welcome Flow",
          "Share Number",
          "Settings",
        ],
        tabValue: tabValue,
        setTabValue: setTabValue,
      }}
    >
      <div style={styles.bodyContainer}>
        <TabPanel value={tabValue} index={0}>
          <ProfilePageFull userData={accountData} />
        </TabPanel>
        <ProfileWelcomeFlow tabValue={tabValue} />
        <ProfileShareNumber tabValue={tabValue} />
        <TabPanel value={tabValue} index={3}>
          <ProfileAccountDetails />
        </TabPanel>
        {shareModal ? (
          <ShareProfileLinkModal
            username={accountData.username}
            setShareLinkModal={setShareModal}
          />
        ) : null}
      </div>
    </CreatorModePanelWrapper>
  );
};

export default ProfilePanel;
