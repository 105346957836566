import {
  SavedFormQuestion,
  SelectRecipientGroupType,
} from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../../redux/slices/accountSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import filter from "lodash.filter";
import { Colors } from "../../../../../utils/colors";
import FlatList from "flatlist-react/lib";
import { sortSavedQuestions } from "@markit/common.utils";
import SearchBoxContainer from "../../../../Containers/SearchBoxContainer";
import { Icon } from "@iconify/react";
import SavedQuestionItem from "../../../../DisplayItem/SavedQuestionItem";
import { EmptyStateFlatlist } from "../../../../EmptyStates/EmptyStateFlatlist";
import EmptyStateButton from "../../../../Buttons/EmptyStateButton";

type SelectRecipientsCollectedDataLibraryProps = {
  updateSelectType: (selectType: Partial<SelectRecipientGroupType>) => void;
};

const SelectRecipientsCollectedDataLibrary = (
  props: SelectRecipientsCollectedDataLibraryProps
) => {
  const { updateSelectType } = props;
  const { savedQuestions } = useSelector(getAccountState).account;
  const [searchTerm, setSearchTerm] = useState("");
  const [formQuestions, setFormQuestions] = useState<SavedFormQuestion[]>([]);

  // we don't use selectedFormQuestions as a dependency so that it doesn't re-order while you're pressing options
  useEffect(() => {
    const finalToShow = sortSavedQuestions(savedQuestions, [], []);
    setFormQuestions(finalToShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedQuestions]);

  const handleSearch = (text: string) => {
    setSearchTerm(text);
  };

  const containsSavedQuestion = useCallback(
    (question: SavedFormQuestion, query: string) => {
      return question.formInput.label
        ?.toLowerCase()
        .includes(query.toLowerCase());
    },
    []
  );

  // The savedFormQuestions to show
  const itemsToShow: SavedFormQuestion[] = useMemo(() => {
    let searchResults: any[] = [];
    if (searchTerm !== "") {
      searchResults = filter(formQuestions, (question: SavedFormQuestion) => {
        return containsSavedQuestion(question, searchTerm);
      });
    } else {
      searchResults = formQuestions;
    }
    return searchResults;
  }, [searchTerm, formQuestions, containsSavedQuestion]);

  return (
    <>
      <span style={{ fontWeight: "500" }}>Select by Collected Data</span>
      <SearchBoxContainer
        value={searchTerm}
        placeholder={"Search saved questions..."}
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
        containerStyles={{ marginBottom: 14 }}
      />
      <div className="AllowScroll" style={{ paddingBottom: 200 }}>
        <FlatList
          list={itemsToShow}
          renderItem={(item: SavedFormQuestion) => (
            <div>
              <SavedQuestionItem
                item={item}
                onItemPress={() => updateSelectType({ selectedItem: item })}
                rightIcon={
                  item.eventIds.length > 0 || item.onProfileFollow ? (
                    <Icon
                      icon="mdi:chevron-right"
                      height={22}
                      color={Colors.GRAY1}
                      style={{ cursor: "pointer" }}
                    />
                  ) : undefined
                }
                disabled={item.eventIds.length === 0 && !item.onProfileFollow}
              />
              <hr />
            </div>
          )}
          renderWhenEmpty={() => (
            <EmptyStateFlatlist
              containerStyles={{ paddingTop: 200 }}
              isLoading={false}
              searchTerm={searchTerm}
              nonSearchEmptyView={
                <EmptyStateButton
                  title="No Collected Data"
                  description="Your saved questions will show up here"
                  icon={
                    <Icon
                      icon={"ion:documents"}
                      height={64}
                      color={Colors.GRAY1}
                    />
                  }
                  iconBox={84}
                  containerStyles={{ paddingTop: 100 }}
                />
              }
            />
          )}
        />
      </div>
    </>
  );
};

export default SelectRecipientsCollectedDataLibrary;
