import { useMemo, useState } from "react";
import { Colors } from "../../utils/colors";
import CreatorModePanelWrapper from "../../components/CreatorDashboard/CreatorModePanelWrapper";
import { useAudienceListSelector } from "../../hooks/useParametricSelector";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useNavigate } from "react-router-dom";
import CreatorModeWrapper from "../../components/CreatorDashboard/CreatorModeWrapper";
import LoadingScreen from "../LoadingScreen";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { NavigationId } from "../../navigation/AppParamList";
import { Icon } from "@iconify/react";
import { useTheme } from "../../hooks/useTheme";
import {
  dateIsCurrentYear,
  FAVORITES_LIST_NAME,
  GetDateWithTime,
} from "@markit/common.utils";
import FatHeaderActionButton from "../../components/Buttons/FatHeaderActionButton";
import AudienceListPeoplePanel from "../../components/CreatorDashboard/CreatorProfile/AudienceLists/AudienceListPeoplePanel";
import AudienceListSettingsPanel from "../../components/CreatorDashboard/CreatorProfile/AudienceLists/AudienceListSettingsPanel";
import AudienceListRenameModal from "../../components/CreatorDashboard/CreatorProfile/AudienceLists/AudienceListRenameModal";
import StandardBorderedContainer from "../../components/Containers/StandardBorderedContainer";
import { VerticalDivider } from "../../components/Dividers/VerticalDivider";
import { AudienceListVisibility } from "@markit/common.types";
import AudienceListDropdownButton from "../../components/DropdownsAndTabs/AudienceListDropdownButton";
import ListSelectRecipientsPanel from "../../components/CreatorDashboard/MassTexts/SelectRecipientsFlow/ListSelectRecipientsPanel";

enum AudienceListDashboardTabs {
  PEOPLE = 0,
  SETTINGS = 1,
}

const AudienceListDashboard = () => {
  const { appInitialized, audienceListDetails } =
    useSelector(getAccountState).account;
  const navigate = useNavigate();
  const { theme } = useTheme();
  const audienceListId = useAudienceListSelector();
  const [tabValue, setTabValue] = useState(AudienceListDashboardTabs.PEOPLE);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [selectRecipientsVisible, setSelectRecipientsVisible] = useState(false);

  const styles = {
    headerContainerView: {
      padding: "12px 14px",
      gap: 7,
    },
    headerRightText: {
      fontSize: 32,
    },
  };

  const audienceList = useMemo(
    () =>
      audienceListDetails.find(
        (listDetail) => listDetail.list.id === audienceListId
      )?.list,
    [audienceListDetails, audienceListId]
  );

  if (!audienceList || !appInitialized) {
    return (
      <LoadingScreen
        isLoadingPage={!appInitialized}
        error={!audienceList && appInitialized}
      />
    );
  }

  return (
    <CreatorModeWrapper>
      <CreatorModePanelWrapper
        title={
          <div className="ColumnNormal" style={{ gap: 20 }}>
            <div className="AlignedRow" style={{ gap: 20 }}>
              <RectangleButton
                buttonLabel={<span className="bodyMedium">Back</span>}
                onPress={() => navigate(NavigationId.HOME_LISTS)}
                altBorderRadius={100}
                altPaddingHorz={12}
                altPaddingVert={10}
                width={65}
                containerStyles={{
                  border: `1px solid ${Colors.GRAY1}`,
                }}
                altColor={Colors.WHITE1}
                altTextColor={Colors.BLACK}
                iconLeft={
                  <div>
                    <Icon icon={"ion:chevron-left"} height={10} />
                  </div>
                }
              />
              <div className="AlignedRow" style={{ gap: 7 }}>
                <span
                  className="TextOverflowEllipsis"
                  style={{ ...styles.headerRightText, maxWidth: 600 }}
                >
                  {audienceList.name}
                </span>
                {audienceList.name !== FAVORITES_LIST_NAME ? (
                  <Icon
                    icon={"ion:create"}
                    height={22}
                    style={{ ...theme.LabelText, cursor: "pointer" }}
                    onClick={() => setShowRenameModal(true)}
                  />
                ) : null}
              </div>
            </div>
            <StandardBorderedContainer
              containerStyles={{ alignSelf: "flex-start" }}
            >
              <div className="AlignedRow">
                <div className="AlignedRow" style={styles.headerContainerView}>
                  <Icon icon={"ion:lock-closed"} height={15} />
                  <span className="bodyMedium">
                    {audienceList.visibility === AudienceListVisibility.PRIVATE
                      ? "Private"
                      : "Public"}{" "}
                    List
                  </span>
                </div>
                <VerticalDivider height={38} />
                <div className="AlignedRow" style={styles.headerContainerView}>
                  <Icon
                    icon={"ion:calendar"}
                    height={13}
                    style={{ color: theme.LabelText.color }}
                  />
                  <span style={{ fontSize: 12, ...theme.LabelText }}>
                    {audienceList.createdAt === audienceList.updatedAt
                      ? "Created:"
                      : "Updated:"}{" "}
                    {GetDateWithTime(
                      audienceList.updatedAt,
                      true,
                      !dateIsCurrentYear(audienceList.updatedAt)
                    )}
                  </span>
                </div>
                {audienceList.name !== FAVORITES_LIST_NAME ? (
                  <>
                    <VerticalDivider height={38} />
                    <div
                      className="AlignedRow"
                      style={{ ...styles.headerContainerView, maxWidth: 300 }}
                    >
                      <span
                        className="TextOverflowEllipsis"
                        style={{
                          ...theme.LabelText,
                          fontSize: 14,
                          fontWeight: "400",
                        }}
                      >
                        {audienceList.description || "No Description"}
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            </StandardBorderedContainer>
          </div>
        }
        headerContainerStyles={{ alignItems: "center" }}
        headerRight={
          <div className="AlignedRow" style={{ gap: 14 }}>
            <FatHeaderActionButton
              title="Export Data"
              onPress={() => alert("Coming soon...")}
            />
            <AudienceListDropdownButton
              list={audienceList}
              setSelectRecipientsVisible={setSelectRecipientsVisible}
            />
          </div>
        }
        headerTabs={{
          tabNames: ["People", "Settings"],
          tabValue: tabValue,
          setTabValue: setTabValue,
        }}
      >
        {tabValue === AudienceListDashboardTabs.PEOPLE ? (
          <AudienceListPeoplePanel
            audienceList={audienceList}
            selectRecipientsVisible={selectRecipientsVisible}
            setSelectRecipientsVisible={setSelectRecipientsVisible}
          />
        ) : (
          <AudienceListSettingsPanel audienceList={audienceList} />
        )}
      </CreatorModePanelWrapper>
      {selectRecipientsVisible ? (
        <ListSelectRecipientsPanel
          setIsVisible={(isVisible: boolean) => {
            setSelectRecipientsVisible(isVisible);
          }}
          audienceList={audienceList}
        />
      ) : null}
      {showRenameModal ? (
        <AudienceListRenameModal
          audienceList={audienceList}
          showModal={showRenameModal}
          setShowModal={setShowRenameModal}
        />
      ) : null}
    </CreatorModeWrapper>
  );
};

export default AudienceListDashboard;
