import { NotificationType } from "@markit/common.types";
import { Icon } from "@iconify/react";
import { useCallback } from "react";
import ConfirmDeleteModal from "../../../Containers/ConfirmPopups/ConfirmDeleteModal";
import { Colors } from "../../../../utils/colors";
import { NavigationId } from "../../../../navigation/AppParamList";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showNotificationBanner } from "../../../../utils/notificationUtils";
import {
  deleteAudienceList,
  getAccountState,
} from "../../../../redux/slices/accountSlice";

type AudienceListDeleteModalProps = {
  listId: string;
  showDeleteModal: boolean;
  setShowDeleteModal: (showDeleteModal: boolean) => void;
};

export const AudienceListDeleteModal = (
  props: AudienceListDeleteModalProps
) => {
  const { listId, showDeleteModal, setShowDeleteModal } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uid } = useSelector(getAccountState).account.accountData;
  const pathname = useLocation().pathname;

  const deleteListOnPress = useCallback(async () => {
    dispatch(deleteAudienceList(uid, listId));
    showNotificationBanner(dispatch, "List Deleted", NotificationType.NEGATIVE);
    if (pathname !== NavigationId.HOME_LISTS) {
      navigate(NavigationId.HOME_LISTS);
    }
  }, [listId, dispatch, navigate, pathname, uid]);

  return (
    <ConfirmDeleteModal
      heading="Delete List"
      subtext="Are you sure you want to delete this list? This cannot be undone."
      deleteButtonText="Delete List"
      icon={
        <Icon icon={"ion:trash"} height={35} style={{ color: Colors.RED3 }} />
      }
      hideModal={!showDeleteModal}
      setIsVisible={setShowDeleteModal}
      deleteOnPress={deleteListOnPress}
    />
  );
};
