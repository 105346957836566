import { memo, useCallback, useEffect, useMemo, useState } from "react";
import "../css/GlobalStyles.css";
import { useOnMount } from "../hooks/useOnMount";
import { useSelector } from "react-redux";
import { LoginState, getAccountState } from "../redux/slices/accountSlice";
import { Colors } from "../utils/colors";
import MarkitHeaderLogo from "../components/MarkitHeaderLogo";
import { useNavigate } from "../hooks/useNavigate";
import { isDesktop } from "react-device-detect";
import RectangleButton from "../components/Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import MarkitFullLogoBlueBlack from "../../src/assets/MarkitLogos/MarkitFullLogoBlueBlack.png";
import CustomTextField from "../components/CustomTextField";
import FanTexting from "../../src/assets/LandingPage/WelcomePage/FanTexting.png";
import HumanMap from "../../src/assets/LandingPage/WelcomePage/HumanMap.png";
import MembershipPlans from "../../src/assets/LandingPage/WelcomePage/MembershipPlans.png";
import { NavigationId } from "../navigation/AppParamList";
import { LoginStage } from "./Login/LoginWelcome";
import { fetchSignInMethodsForEmail, firebaseAuth } from "../firebase";
import TextHoverButton from "../components/Buttons/TextHoverButton";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Helmet } from "react-helmet";
import { MARKITAI } from "@markit/common.utils";
import CarouselContainer, {
  CarouselItem,
} from "../components/AnimationContainers/CarouselContainer";

const WelcomeLandingPage = memo(function WelcomeLandingPageFn() {
  const { loggedIn } = useSelector(getAccountState).account;
  const navigate = useNavigate();
  const [showRightSide, setShowRightSide] = useState(
    isDesktop && window.innerWidth >= 1024
  );
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState({
    status: false,
    message: "",
  });
  const [loading, setLoading] = useState(false);

  useOnMount(() => {
    if (loggedIn === LoginState.LOGGED_IN) {
      navigate("/home");
    }
  });

  const carouselItems: CarouselItem[] = [
    {
      image: FanTexting,
      title: "Reach your followers instantly with texting",
      height: 300,
    },
    {
      image: MembershipPlans,
      title: "Earn more from your biggest supporters with paid subscriptions",
      height: 300,
    },
    {
      image: HumanMap,
      title: "Text marketing that feels human",
      height: 300,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setShowRightSide(isDesktop && window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const continueWithEmail = useCallback(async () => {
    if (email === "") {
      setEmailError({ status: true, message: "Email cannot be empty" });
      return;
    }
    // Check if email user exists
    setLoading(true);
    try {
      const methods = await fetchSignInMethodsForEmail(firebaseAuth, email);
      const userExists = methods.length > 0;

      navigate(NavigationId.SIGN_IN, {
        state: {
          preEmail: email,
          preLoginStage: userExists
            ? LoginStage.EMAIL_LOGIN
            : LoginStage.ACCOUNT_TYPE,
        },
      });
    } catch (err: any) {
      setEmailError({ status: true, message: err.message });
      console.log(err);
    }
    setLoading(false);
  }, [email, navigate]);

  const continueWithPhone = useCallback(
    (defaultSubscriber?: boolean) => {
      navigate(
        NavigationId.SIGN_IN + (defaultSubscriber ? "?subscriber" : ""),
        {
          state: { preLoginStage: LoginStage.PHONE },
        }
      );
    },
    [navigate]
  );

  const renderNoCreatorButton = useMemo(
    () => (
      <RectangleButton
        buttonLabel={
          <span style={{ fontSize: 12, fontWeight: 500 }}>
            Not a creator? Join as subscriber
          </span>
        }
        iconRight={
          <Icon icon="ion:chevron-right" height={12} color={Colors.BLACK} />
        }
        onPress={() => continueWithPhone(true)}
        altTextColor={Colors.BLACK}
        containerStyles={{
          paddingBlock: 12,
          paddingInline: 16,
          borderRadius: 22,
          backgroundColor: Colors.WHITE1,
          border: `0.5px solid ${Colors.GRAY2}`,
          whiteSpace: "nowrap",
        }}
      />
    ),
    [continueWithPhone]
  );

  return (
    <div
      className="RowNormal"
      style={{
        backgroundColor: Colors.WHITE,
        minHeight: "100vh",
        overflowX: "hidden",
      }}
    >
      <Helmet>
        <title>Welcome | Markit AI</title>
        <meta
          name="description"
          content="Start texting today. Join as a creator or subscriber."
        />
        <link rel="canonical" href={MARKITAI + NavigationId.WELCOME} />
      </Helmet>
      <div
        className="ColumnNormal"
        style={{ flex: 1, position: "relative", minHeight: 800 }}
      >
        <div
          className="ColumnNormal"
          style={{
            gap: 40,
            margin: isDesktop ? "auto" : undefined,
            width: isDesktop ? 362 : undefined,
            paddingInline: isDesktop ? 20 : 14,
            paddingBlock: isDesktop ? 20 : 100,
          }}
        >
          <div className="ColumnNormal" style={{ gap: 20 }}>
            <MarkitHeaderLogo
              fullLogo
              alternateLogo={MarkitFullLogoBlueBlack}
              disabled
            />
            <div className="ColumnNormal" style={{ gap: isDesktop ? 20 : 14 }}>
              <span style={{ fontSize: isDesktop ? 60 : 45, fontWeight: 500 }}>
                Get started
                <br />
                for free.
              </span>
              <span className="bodyMedium" style={{ color: "#8D8D8D" }}>
                Sign in or sign up below
              </span>
            </div>
          </div>
          <div className="ColumnNormal" style={{ gap: 20 }}>
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <div className="ColumnNormal" style={{ gap: 7 }}>
                <CustomTextField
                  value={email}
                  placeholder="Your Email"
                  inputMode="text"
                  borderRadius={12}
                  altPadding={"12px 14px"}
                  onChange={(change: any) => {
                    setEmailError({
                      status: false,
                      message: "",
                    });
                    setEmail(change.target.value);
                  }}
                  error={emailError.status}
                />
                {emailError.status ? (
                  <span style={{ fontSize: 12, color: Colors.RED2 }}>
                    {emailError.message}
                  </span>
                ) : null}
              </div>
              <RectangleButton
                buttonLabel={"Continue"}
                onPress={continueWithEmail}
                loading={loading}
                containerStyles={{
                  paddingBlock: 14,
                  paddingInline: 10,
                  backgroundColor: Colors.BLACK,
                }}
              />
            </div>
            <div className="RowNormal" style={{ justifyContent: "center" }}>
              <TextHoverButton
                text="Continue with phone"
                onPress={() => continueWithPhone(false)}
                icon={
                  <Icon
                    icon="ion:phone-portrait-outline"
                    height={13}
                    color={Colors.GRAY1}
                  />
                }
                textStyles={{ fontSize: 14 }}
              />
            </div>
          </div>
          {isDesktop ? (
            <div
              style={{
                position: "absolute",
                bottom: 40,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              {renderNoCreatorButton}
            </div>
          ) : (
            <div style={{ margin: "auto" }}>{renderNoCreatorButton}</div>
          )}
        </div>
      </div>
      {showRightSide ? (
        <div
          className="ColumnNormal"
          style={{ flex: 1, backgroundColor: Colors.BLACK }}
        >
          <CarouselContainer
            carouselItems={carouselItems}
            dark
            enableSwipe
            containerStyles={{ width: "60%" }}
          />
        </div>
      ) : null}
    </div>
  );
});

export default WelcomeLandingPage;
