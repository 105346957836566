import { memo, useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import {
  useParametricSelector,
  useProfileSelector,
} from "../../../hooks/useParametricSelector";
import { getLoadedUserByUsername } from "../../../redux/slices/dataSlice";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../LoadingScreen";
import {
  LoginState,
  getAccountState,
} from "../../../redux/slices/accountSlice";
import MarkitSecuredContainer from "../../../components/UserInfoContainers/MarkitSecuredContainer";
import { useTheme } from "../../../hooks/useTheme";
import {
  FollowerStatus,
  FollowType,
  FormAnswersV2,
  MembershipPlan,
  MembershipState,
  NotificationType,
  SavedFormQuestion,
  VerificationState,
} from "@markit/common.types";
import {
  checkIfUserIsSubscribed,
  checkIfUserIsUnsubscribed,
  checkIfUserIsUnsubscribedMarkit,
  fetchFollowerDataFromPhoneNumber,
} from "../../../utils/userUtils";
import {
  getProfileFormQuestions,
  getUsersFormResponses,
  validateEventForm,
} from "../../../utils/eventUtils/formUtils";
import { createEmptyFormAnswersV2 } from "@markit/common.utils";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  onProfilePageNavigatePath,
  scrollToTop,
} from "../../../utils/navigationUtils";
import SubscribeFlowInitial from "../../../components/ProfilePage/SubscribeFlow/SubscribeFlowInitial";
import SubscribeFlowQuestions from "../../../components/ProfilePage/SubscribeFlow/SubscribeFlowQuestions";
import SubscribeFlowMembership from "../../../components/ProfilePage/SubscribeFlow/SubscribeFlowMembership";
import SubscribeFlowMembershipCheckout from "../../../components/ProfilePage/SubscribeFlow/SubscribeFlowMembershipCheckout";
import { followUser } from "../../../utils/FollowUser";

export enum SubscribingStage {
  INITIAL = "Initial",
  PROFILE_QUESTIONS = "Profile Questions",
  MEMBERSHIP = "Membership",
  MEMBERSHIP_CHECKOUT = "Membership Checkout",
}

const ProfilePageSubscribe = memo(function ProfilePageSubscribeFn() {
  const { accountData, loggedIn } = useSelector(getAccountState).account;
  const { theme } = useTheme();
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tempFullName, setTempFullName] = useState("");
  const [tempPhoneNumber, setTempPhoneNumber] = useState("");
  const [verificationState, setVerificationState] = useState(
    VerificationState.UNVERIFIED
  );
  const [uid, setUid] = useState("");
  const [answers, setAnswers] = useState<FormAnswersV2>(
    createEmptyFormAnswersV2([], [])
  );
  const [formErrorIds, setFormErrorIds] = useState<string[]>([]);
  const [profileFormQuestions, setProfileFormQuestions] = useState<
    SavedFormQuestion[]
  >([]);
  const [selectedMembershipPlan, setSelectedMembershipPlan] =
    useState<MembershipPlan>();
  const [subscribingStage, setSubscribingStage] = useState<SubscribingStage>(
    SubscribingStage.INITIAL
  );
  const [processing, setProcessing] = useState(false);
  const [optedOut, setOptedOut] = useState(false);
  const [optedOutCreator, setOptedOutCreator] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const username = useProfileSelector();
  const {
    isLoading: isLoadingUserData,
    data: creatorData,
    isError: isErrorUserData,
  } = useParametricSelector(getLoadedUserByUsername, username);

  const isUpgradePage = useMemo(() => pathname.includes("upgrade"), [pathname]);

  const isLoggedIn = useMemo(
    () => loggedIn === LoginState.LOGGED_IN,
    [loggedIn]
  );

  const membershipsEnabled = useMemo(
    () =>
      creatorData && creatorData?.membership.state === MembershipState.ACTIVE,
    [creatorData]
  );

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (_deps, isLatest) => {
      if (creatorData && initialLoading && isLatest()) {
        if (isLoggedIn) {
          // Check if user is logged in and already subscribed to creator
          const isSubscribed = await checkIfUserIsSubscribed(
            accountData.uid,
            creatorData.uid
          );
          if (isSubscribed) {
            onProfilePageNavigatePath(
              navigate,
              creatorData.username,
              isUpgradePage ? "upgrade" : "manage"
            );
            if (!isUpgradePage) {
              showNotificationBanner(
                dispatch,
                "Already Subscribed!",
                NotificationType.AFFIRMATIVE
              );
            }
            setInitialLoading(false);
            return;
          }
        }

        // fetch the event form questions
        const formQuestions = await getProfileFormQuestions(creatorData.uid);
        setProfileFormQuestions(formQuestions);

        // Default the membership plan to the first paid option, if applicable
        if (membershipsEnabled) {
          const paidMembershipPlans =
            creatorData.membership.membershipPlans.filter(
              (membership) => membership.price !== 0
            );
          if (paidMembershipPlans.length > 0) {
            setSelectedMembershipPlan(paidMembershipPlans[0]);
          }
        }

        if (isLoggedIn) {
          setTempFullName(accountData.fullName);
          setTempPhoneNumber(accountData.phoneNumber);
          setUid(accountData.uid);
          setVerificationState(VerificationState.VERIFIED);

          const [isUnsubscribedMarkit, isUnsubscribed, userFormResponses] =
            await Promise.all([
              checkIfUserIsUnsubscribedMarkit(accountData.phoneNumber),
              checkIfUserIsUnsubscribed(accountData.uid, creatorData.uid),
              getUsersFormResponses(
                creatorData.uid,
                formQuestions.map((formQuestions) => formQuestions.id),
                accountData.uid
              ),
            ]);
          // Check if logged in phone number is unsubscribed from markit number or creator
          // Even if using firebase OTP, we need them opted in so they can get confirmation texts
          setOptedOut(isUnsubscribedMarkit);
          setOptedOutCreator(isUnsubscribed);
          // set already answered form questions
          setAnswers(
            createEmptyFormAnswersV2(formQuestions, userFormResponses)
          );
        }

        setInitialLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [creatorData?.uid, isLoggedIn]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    // Autofill any form inputs if user is not logged in and has verified their number
    // The logged in case is handled by the useAsyncOnMount above
    if (
      loggedIn !== LoginState.LOGGED_IN &&
      verificationState === VerificationState.VERIFIED &&
      creatorData
    ) {
      // set already answered form questions
      const userFormResponses = await getUsersFormResponses(
        creatorData.uid,
        profileFormQuestions.map((formQuestions) => formQuestions.id),
        uid
      );
      setAnswers(
        createEmptyFormAnswersV2(profileFormQuestions, userFormResponses)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationState]);

  const followUserAccount = useCallback(
    async (showMemberships?: boolean) => {
      if (!creatorData) {
        return;
      }

      await followUser({
        userPhone: tempPhoneNumber,
        userFullName: tempFullName,
        userToFollow: creatorData,
        uid: "",
        answers: answers,
        followType: FollowType.PROFILE,
      });

      if (showMemberships) {
        scrollToTop();
        setSubscribingStage(SubscribingStage.MEMBERSHIP);
      } else {
        setProcessing(false);
        onProfilePageNavigatePath(navigate, creatorData.username);
        showNotificationBanner(
          dispatch,
          "Subscribed",
          NotificationType.AFFIRMATIVE
        );
      }
    },
    [answers, creatorData, dispatch, navigate, tempFullName, tempPhoneNumber]
  );

  /**
   * When the user verifies their number and presses the Subscribe button, three cases:
   * 1. Logged in user is already following creator
   *    a. Creator has membership plans redirect to profile manage subscription
   *    b. Creator has no membership plans, redirect to profile page
   * 2. Not following, has profile questions, show the questions to answer before subscribing
   * 3. a. Not following, has no profile questions AND no membership plans, subscribe the user with message
   *    b. Not following, has no profile questions but has membership plans, subscribe user and then go to plans
   */
  const verifyNumberOnPress = useCallback(async () => {
    if (!creatorData) {
      return;
    }
    setProcessing(true);
    const follower = await fetchFollowerDataFromPhoneNumber(
      creatorData.uid,
      tempPhoneNumber
    );
    if (follower && follower.status === FollowerStatus.SUBSCRIBED) {
      onProfilePageNavigatePath(
        navigate,
        creatorData.username,
        membershipsEnabled ? "upgrade" : undefined,
        true
      );
      if (!isUpgradePage) {
        showNotificationBanner(
          dispatch,
          "Already Subscribed!",
          NotificationType.AFFIRMATIVE
        );
      }
    } else if (profileFormQuestions.length > 0) {
      setSubscribingStage(SubscribingStage.PROFILE_QUESTIONS);
      scrollToTop();
      setProcessing(false);
    } else {
      await followUserAccount(membershipsEnabled);
    }
  }, [
    creatorData,
    dispatch,
    followUserAccount,
    isUpgradePage,
    membershipsEnabled,
    navigate,
    profileFormQuestions.length,
    tempPhoneNumber,
  ]);

  const submitQuestionsOnPress = useCallback(async () => {
    if (!creatorData) {
      return;
    }
    setProcessing(true);

    const errorIds = validateEventForm(answers, profileFormQuestions);
    if (errorIds.length > 0) {
      setFormErrorIds(errorIds);
      setProcessing(false);
      alert("Please answer the required questions.");
      return;
    }

    await followUserAccount(membershipsEnabled);
  }, [
    answers,
    creatorData,
    followUserAccount,
    membershipsEnabled,
    profileFormQuestions,
  ]);

  const chooseMembershipOnPress = useCallback(
    async (selectedMembership: MembershipPlan) => {
      if (!creatorData) {
        return;
      }
      setSelectedMembershipPlan(selectedMembership);
      if (selectedMembership.id === "free") {
        onProfilePageNavigatePath(navigate, creatorData.username);
        showNotificationBanner(
          dispatch,
          "Subscribed",
          NotificationType.AFFIRMATIVE
        );
      } else {
        setSubscribingStage(SubscribingStage.MEMBERSHIP_CHECKOUT);
        scrollToTop();
      }
    },
    [creatorData, dispatch, navigate]
  );

  if (isLoadingUserData || initialLoading || !creatorData) {
    return (
      <LoadingScreen
        isLoadingPage={isLoadingUserData || initialLoading || !creatorData}
      />
    );
  }

  if (isErrorUserData) {
    return <LoadingScreen error />;
  }

  const title = `${creatorData.fullName} (@${creatorData.username}) on Markit`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="og:title" content={title} />
        <meta name="og:description" content={creatorData.username} />
      </Helmet>
      <div
        className="ColumnNormalSpaced"
        style={{ ...theme.SecondaryBG, minHeight: "100vh" }}
      >
        {subscribingStage === SubscribingStage.INITIAL ? (
          <SubscribeFlowInitial
            creatorData={creatorData}
            processing={processing}
            tempFullName={tempFullName}
            setTempFullName={setTempFullName}
            tempPhoneNumber={tempPhoneNumber}
            setTempPhoneNumber={setTempPhoneNumber}
            verificationState={verificationState}
            setVerificationState={setVerificationState}
            optedOut={optedOut}
            setOptedOut={setOptedOut}
            optedOutCreator={optedOutCreator}
            setOptedOutCreator={setOptedOutCreator}
            verifyOnPress={verifyNumberOnPress}
            setUid={setUid}
          />
        ) : subscribingStage === SubscribingStage.PROFILE_QUESTIONS ? (
          <SubscribeFlowQuestions
            creatorData={creatorData}
            profileQuestions={profileFormQuestions}
            processing={processing}
            answers={answers}
            setAnswers={setAnswers}
            formErrorIds={formErrorIds}
            setFormErrorIds={setFormErrorIds}
            verifyOnPress={submitQuestionsOnPress}
          />
        ) : subscribingStage === SubscribingStage.MEMBERSHIP ? (
          <SubscribeFlowMembership
            creatorData={creatorData}
            chooseOnPress={chooseMembershipOnPress}
            selectedMembershipPlan={selectedMembershipPlan}
            setSelectedMembershipPlan={setSelectedMembershipPlan}
          />
        ) : subscribingStage === SubscribingStage.MEMBERSHIP_CHECKOUT &&
          selectedMembershipPlan ? (
          <SubscribeFlowMembershipCheckout
            creatorData={creatorData}
            selectedMembershipPlan={selectedMembershipPlan}
            uid={uid}
            backOnPress={() => setSubscribingStage(SubscribingStage.MEMBERSHIP)}
          />
        ) : null}
        <div
          className="Centering"
          style={{ paddingTop: 100, paddingBottom: 30 }}
        >
          <MarkitSecuredContainer />
        </div>
      </div>
    </>
  );
});

export default ProfilePageSubscribe;
