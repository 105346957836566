import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { MARKIT_HOTLINE_NUMBER } from "@markit/common.utils";
import { useCallback, useState } from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import { API } from "../../../API";
import AlertContainer from "../../Containers/AlertContainer";
import { formatPhoneNumber } from "react-phone-number-input";

type BillingItemInvoiceProps = {
  subscriptionId: string;
  subscriptionStart: string;
  upcomingPaymentFailed: boolean;
};

// Used for the Link to Invoice part of the BillingItem components
const BillingItemInvoice = (props: BillingItemInvoiceProps) => {
  const { subscriptionId, subscriptionStart, upcomingPaymentFailed } = props;
  const accountData = useSelector(getAccountState).account.accountData;

  const [alertText, setAlertText] = useState({
    heading: "",
    subHeading: "",
  });

  const openInvoice = useCallback(async () => {
    try {
      // Call your backend API endpoint
      const response = await API.user.fetchSubscriptionInvoice({
        uid: accountData.uid,
        subscriptionId: subscriptionId,
        date: subscriptionStart,
      });
      if (response.invoiceUrl) {
        window.open(response.invoiceUrl, "_blank");
      }
    } catch (error) {
      setAlertText({
        heading: "Cannot view invoice at this time.",
        subHeading: `Please text our hotline (${formatPhoneNumber(
          MARKIT_HOTLINE_NUMBER
        )}) with the following message: \nCannot view invoice for ${
          accountData.username
        }`,
      });
      console.error("Error fetching invoice:", error);
    }
  }, [
    accountData.uid,
    accountData.username,
    subscriptionId,
    subscriptionStart,
  ]);

  return (
    <>
      <div className="AlignedRowSelect" onClick={openInvoice}>
        <span
          style={{
            fontSize: 12,
            color: upcomingPaymentFailed ? Colors.RED3 : Colors.BLUE5,
          }}
        >
          {upcomingPaymentFailed ? "Pay Failed Invoice" : "Link to Invoice"}
        </span>
        <Icon
          icon="iconoir:arrow-up-right"
          height={14}
          color={upcomingPaymentFailed ? Colors.RED3 : Colors.BLUE5}
        />
      </div>
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
    </>
  );
};

export default BillingItemInvoice;
