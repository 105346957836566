import React, { useCallback, useMemo, useState } from "react";
import { Colors } from "../../utils/colors";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import { Icon } from "@iconify/react";

type ListDropdownProp = {
  dropdownLabels: string[];
  dropdownNumbers?: number[];
  selectedValue: number;
  onChange: (index: number) => void;
  tabStyles?: React.CSSProperties;
  loading?: boolean;
};

const ListDropdown = (props: ListDropdownProp) => {
  const {
    dropdownLabels,
    dropdownNumbers,
    selectedValue,
    onChange,
    tabStyles,
    loading,
  } = props;

  const [openDropdown, setOpenDropdown] = useState(false);

  const handleOpenChange = useCallback(
    (nextOpen: boolean, info?: { source: "trigger" | "menu" }) => {
      if (info?.source === "trigger" || nextOpen) {
        setOpenDropdown(nextOpen);
      }
    },
    []
  );

  const styles = {
    tabContainer: {
      transition: "all 200ms ease",
      gap: 10,
      borderRadius: 8,
      paddingInline: 14,
      paddingBlock: 12,
      backgroundColor: Colors.WHITE1,
      border: `0.8px solid ${Colors.GRAY2}`,
    },
  };

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    return dropdownLabels.map((label, index) => {
      return {
        title:
          label +
          (dropdownNumbers && dropdownNumbers[index] !== -1
            ? ` (${dropdownNumbers[index]})`
            : ""),
        icon: selectedValue === index ? "ion:checkmark-circle" : "",
        key: label,
        onPress: () => {
          onChange(index);
          setOpenDropdown(false);
        },
      };
    });
  }, [dropdownLabels, dropdownNumbers, onChange, selectedValue]);

  return (
    <DropdownMenu
      trigger={["click"]}
      placement="bottom"
      altWidth={180}
      topPadding={0}
      dropdownItems={dropdownItems}
      isOpen={openDropdown}
      onOpenChange={handleOpenChange}
    >
      <div
        className="AlignedRowSelect"
        style={{ ...styles.tabContainer, ...tabStyles }}
      >
        <span className="bodyMedium">
          {dropdownLabels[selectedValue]}
          {dropdownNumbers && dropdownNumbers[selectedValue] !== -1
            ? loading
              ? ""
              : ` (${dropdownNumbers[selectedValue]})`
            : null}
        </span>
        <Icon
          icon={!openDropdown ? "ion:chevron-down" : "ion:chevron-up"}
          height={16}
          style={{ marginBottom: -2 }}
        />
      </div>
    </DropdownMenu>
  );
};

export default ListDropdown;
