import {
  AccountData,
  Follower,
  FollowerMembershipState,
  MembershipPlanFrequency,
} from "@markit/common.types";
import { useCallback, useMemo, useState } from "react";
import ProfileItem from "../../ProfileItem";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import ThreeDotsButton from "../../Buttons/ThreeDotsButton";
import { DropdownMenu, DropdownMenuItem } from "../../DropdownMenu";
import { GetDateWithTime } from "@markit/common.utils";
import MembershipSubscriptionHistoryModal from "../../Subscription/Memberships/MembershipSubscriptionHistoryModal";
import { getShortMembershipFrequencyLabel } from "../../../utils/subscriptionUtils";
import LabelButton from "../../Buttons/LabelButton";
import { Colors } from "../../../utils/colors";

export const MEMBERSHIP_USER_FLEX_SECTIONS = [3, 2, 6];

type ProfileFollowerMembershipItemProps = {
  item: AccountData;
  setProfilePreviewSelected: (
    profilePreviewSelected: AccountData | undefined
  ) => void;
  fetchedFollowerData: Follower[];
};

const ProfileFollowerMembershipItem = ({
  item,
  setProfilePreviewSelected,
  fetchedFollowerData,
}: ProfileFollowerMembershipItemProps) => {
  const { accountData } = useSelector(getAccountState).account;
  const [subscriptionHistoryVisible, setSubscriptionHistoryVisible] =
    useState(false);

  const viewProfilePreview = useCallback(() => {
    setProfilePreviewSelected(item);
  }, [setProfilePreviewSelected, item]);

  const followerData = useMemo(
    () => fetchedFollowerData.find((follower) => follower.uid === item.uid),
    [fetchedFollowerData, item.uid]
  );

  const membershipPlan = useMemo(
    () =>
      accountData.membership.membershipPlans.find(
        (plan) => plan.id === followerData?.membershipPlanId
      ),
    [accountData.membership.membershipPlans, followerData?.membershipPlanId]
  );

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const dropdownItems: DropdownMenuItem[] = [
      {
        title: "View Profile",
        icon: "ion:person",
        key: "view",
        onPress: viewProfilePreview,
      },
      {
        title: "Subscription History",
        icon: "ion:hourglass",
        key: "subscription_history",
        onPress: () => setSubscriptionHistoryVisible(true),
      },
    ];
    return dropdownItems;
  }, [viewProfilePreview]);

  const threeDots = useCallback(
    () => (
      <div style={{ marginRight: 20 }}>
        <DropdownMenu
          trigger={["click"]}
          placement="bottomRight"
          altWidth={200}
          dropdownItems={dropdownItems}
        >
          <ThreeDotsButton onPress={() => {}} />
        </DropdownMenu>
      </div>
    ),
    [dropdownItems]
  );

  return (
    <>
      <div>
        <div className="AlignedRow">
          <div
            style={{ marginLeft: 14, flex: MEMBERSHIP_USER_FLEX_SECTIONS[0] }}
          >
            <ProfileItem profileItem={item} onItemPress={viewProfilePreview} />
          </div>
          <div
            className="AlignedRow"
            style={{ flex: MEMBERSHIP_USER_FLEX_SECTIONS[1] }}
          >
            {followerData &&
            followerData.membershipState ===
              FollowerMembershipState.PAYMENT_FAILED ? (
              <LabelButton
                color={Colors.RED3}
                backgroundColor={Colors.WHITE1}
                text={"Payment Failed"}
              />
            ) : (
              <span className="bodySubtext" style={{ paddingLeft: 7 }}>
                {membershipPlan
                  ? membershipPlan.billingFrequency ===
                    MembershipPlanFrequency.FOREVER
                    ? "Free"
                    : `$${
                        membershipPlan.price / 100
                      }/${getShortMembershipFrequencyLabel(
                        membershipPlan.billingFrequency
                      )}`
                  : "--"}
              </span>
            )}
          </div>
          <div
            className="AlignedRowSpaced"
            style={{ flex: MEMBERSHIP_USER_FLEX_SECTIONS[2] }}
          >
            <span className="bodySubtext" style={{ paddingLeft: 7 }}>
              {followerData ? GetDateWithTime(followerData.createdAt) : "--"}
            </span>
            {threeDots()}
          </div>
        </div>
        <HorizontalDivider altMargin={14} />
      </div>
      {subscriptionHistoryVisible && followerData ? (
        <MembershipSubscriptionHistoryModal
          isVisible={subscriptionHistoryVisible}
          closeOnPress={() => setSubscriptionHistoryVisible(false)}
          creatorData={accountData}
          userData={item}
          followerData={followerData}
        />
      ) : null}
    </>
  );
};

export default ProfileFollowerMembershipItem;
