import "../css/FullEvent/FullEvent.css";
import "../css/GlobalStyles.css";
import {
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import powered from "../assets/WhiteLabel/Powered.png";
import RCA from "../assets/WhiteLabel/RCA.png";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginState,
  accountActions,
  getAccountState,
} from "../redux/slices/accountSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../utils/colors";
import { MixpanelContext } from "../context/AnalyticsService";
import { useTheme } from "../hooks/useTheme";
import { ThemeType } from "@markit/common.types";
import MarkitHeaderLogo from "./MarkitHeaderLogo";
import { Icon } from "@iconify/react";
import { MARKIT_WEBSITE } from "@markit/common.utils";
import ProfileHeaderDropdown from "./CreatorDashboard/CreatorProfile/ProfileHeaderDropdown";
import ProfilePic from "./ProfilePic";
import FatHeaderActionButton from "./Buttons/FatHeaderActionButton";
import { DropdownMenu, DropdownMenuItem } from "./DropdownMenu";
import { checkIsDesktop } from "../utils/screenResponsivenessUtils";
import { isDesktop } from "react-device-detect";
import MarkitFullLogoWhite from "../../src/assets/MarkitFullLogoWhite.png";
import MarkitLogoWhite from "../../src/assets/MarkitLogoWhite.png";
import {
  LandingPageNavigationId,
  NavigationId,
} from "../navigation/AppParamList";
import TextHoverButton from "./Buttons/TextHoverButton";
import MobileNavDrawer from "./Navigation/MobileNavDrawer";

type TopHeaderProps = {
  hideLogin?: boolean;
  showRCA?: boolean;
  eventTheme?: ThemeType;
  whiteLogo?: boolean;
  homePage?: boolean;
  preventLogoClick?: boolean;
  onMobileNavOpenChange?: (isOpen: boolean) => void;
};

const TopHeader = memo(function TopHeaderFn(props: TopHeaderProps) {
  const {
    hideLogin,
    showRCA,
    eventTheme,
    whiteLogo = false,
    homePage = false,
    preventLogoClick = false,
    onMobileNavOpenChange,
  } = props;
  const mixpanel = useContext(MixpanelContext);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { account } = useSelector(getAccountState);
  const { isRCA, loggedIn, accountData } = account;

  const { theme } = useTheme(eventTheme, hideLogin);
  const [isDiscoverHovered, setIsDiscoverHovered] = useState(false);
  const [isSignInHovered, setIsSignInHovered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const styles = {
    buttonStyle: {
      paddingInline: 12,
      paddingBlock: 10,
      height: 40,
    },
  };

  // Add useEffect to handle window resizing
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isDesktopSize = useMemo(
    () => checkIsDesktop(windowWidth),
    [windowWidth]
  );

  const onMarkitClick = useCallback(() => {
    if (mixpanel) {
      mixpanel.track("Webapp: Pressed Header Markit Button", {
        pathname: location.pathname,
      });
    }
    if (loggedIn === LoginState.LOGGED_IN && accountData.inCreatorMode) {
      navigate("/home");
    } else {
      navigate("/");
    }
  }, [
    accountData.inCreatorMode,
    location.pathname,
    loggedIn,
    mixpanel,
    navigate,
  ]);

  const signUpUser = useCallback(() => {
    dispatch(accountActions.setRedirectPath(location.pathname));
    navigate(NavigationId.WELCOME);
  }, [dispatch, location.pathname, navigate]);

  const signInUser = useCallback(() => {
    dispatch(accountActions.setRedirectPath(location.pathname));
    navigate(NavigationId.SIGN_IN);
  }, [dispatch, location.pathname, navigate]);

  const exploreOnPress = useCallback(() => {
    navigate("/explore");
    mixpanel.track("Webapp: Pressed Header Explore Button", {
      pathname: location.pathname,
    });
  }, [location.pathname, mixpanel, navigate]);

  const productDropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "Texting",
        key: "texting",
        onPress: () => window.open(MARKIT_WEBSITE + "texting"),
      },
      {
        title: "Integrations",
        key: "integrations",
        onPress: () => window.open(MARKIT_WEBSITE + "integrations"),
      },
    ],
    []
  );

  const resourcesDropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "Help Center",
        key: "help_center",
        onPress: () => window.open(MARKIT_WEBSITE + "helpcenter"),
      },
      {
        title: "Texting Policies",
        key: "texting_policies",
        onPress: () => window.open(MARKIT_WEBSITE + "textingpolicies"),
      },
    ],
    []
  );

  const aboutDropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "Contact",
        key: "contact",
        onPress: () => window.open(MARKIT_WEBSITE + "contact"),
      },
      {
        title: "Press",
        key: "press",
        onPress: () => window.open(MARKIT_WEBSITE + "press"),
      },
    ],
    []
  );

  const headerNavOnPress = useCallback(
    (item: string) => {
      if (item === "Pricing") {
        navigate(LandingPageNavigationId.PRICING);
      }
    },
    [navigate]
  );

  const toggleMobileNav = useCallback(() => {
    const newState = !isMobileNavOpen;
    setIsMobileNavOpen(newState);
    onMobileNavOpenChange?.(newState);
  }, [isMobileNavOpen, onMobileNavOpenChange]);

  return (
    <div
      className={isDesktop ? "FinalHeaderDesktop" : "AlignedTopRow"}
      style={
        isRCA && showRCA
          ? {
              minHeight: isDesktop ? "100px" : "80px",
              marginRight: "20px",
            }
          : {}
      }
    >
      {isRCA && showRCA ? (
        <div className="AlignedRow">
          <div
            style={{
              height: isDesktop ? "90px" : "60px",
            }}
          >
            <img
              src={RCA}
              className="referralProgramCircle"
              alt="ReferralProgramImage"
            />
          </div>
          <div
            onClick={onMarkitClick}
            style={{
              marginLeft: 10,
              height: isDesktop ? "40px" : "30px",
            }}
          >
            <img
              src={powered}
              className="referralProgramCircle"
              alt="ReferralProgramImage"
            />
          </div>
        </div>
      ) : (
        <MarkitHeaderLogo
          theme={theme}
          alternateLogo={
            whiteLogo
              ? isDesktopSize
                ? MarkitFullLogoWhite
                : MarkitLogoWhite
              : undefined
          }
          disabled={preventLogoClick}
        />
      )}
      {homePage && isDesktopSize ? (
        <div
          className="AlignedRow"
          style={{
            gap: 40,
            position: "absolute",
            paddingInline: 160,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {["Product", "Resources", "About", "Pricing"].map((item) => (
            <DropdownMenu
              key={item}
              dropdownItems={
                item === "Product"
                  ? productDropdownItems
                  : item === "Resources"
                  ? resourcesDropdownItems
                  : item === "About"
                  ? aboutDropdownItems
                  : []
              }
              placement="bottom"
              disabled={item === "Pricing"}
              dark
            >
              <TextHoverButton
                text={item}
                onPress={() => headerNavOnPress(item)}
                textStyles={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: Colors.WHITE,
                }}
              />
            </DropdownMenu>
          ))}
        </div>
      ) : null}
      <div className="AlignedRow" style={{ gap: 10 }}>
        {accountData.isAdmin && !hideLogin ? (
          <span
            style={{
              color: Colors.RED2,
              fontSize: 14,
              fontWeight: 500,
              cursor: "pointer",
            }}
            onClick={() => navigate("/adminDashboard")}
          >
            Admin Dashboard
          </span>
        ) : null}
        {homePage ? (
          <div
            onMouseEnter={() => setIsDiscoverHovered(true)}
            onMouseLeave={() => setIsDiscoverHovered(false)}
          >
            <FatHeaderActionButton
              title="Discover"
              icon={
                <Icon
                  icon={"ion:compass"}
                  height={15}
                  color={
                    isDiscoverHovered ? theme.PrimaryText.color : Colors.WHITE
                  }
                />
              }
              onPress={exploreOnPress}
              containerStyles={{
                ...styles.buttonStyle,
                color: isDiscoverHovered
                  ? theme.PrimaryText.color
                  : Colors.WHITE,
                backgroundColor: isDiscoverHovered
                  ? theme.SecondaryBG.backgroundColor
                  : theme.PrimaryText.color,
              }}
            />
          </div>
        ) : null}
        {!hideLogin && isDesktopSize && loggedIn === LoginState.LOGGED_OUT ? (
          homePage ? (
            <div
              onMouseEnter={() => setIsSignInHovered(true)}
              onMouseLeave={() => setIsSignInHovered(false)}
            >
              <FatHeaderActionButton
                title="Sign In"
                onPress={signInUser}
                containerStyles={{
                  ...styles.buttonStyle,
                  color: isSignInHovered
                    ? theme.PrimaryText.color
                    : Colors.WHITE,
                  backgroundColor: isSignInHovered
                    ? theme.SecondaryBG.backgroundColor
                    : theme.PrimaryText.color,
                }}
              />
            </div>
          ) : null
        ) : null}
        {!hideLogin ? (
          loggedIn === LoginState.LOGGED_IN ? (
            <ProfileHeaderDropdown placement="bottom">
              <ProfilePic user={accountData} forcePointer size={40} />
            </ProfileHeaderDropdown>
          ) : (
            <div>
              <FatHeaderActionButton
                title="Sign Up"
                onPress={signUpUser}
                containerStyles={{
                  ...styles.buttonStyle,
                  color: theme.PrimaryText.color,
                  backgroundColor: theme.NewPrimaryBG.backgroundColor,
                }}
              />
            </div>
          )
        ) : null}
        {homePage && !isDesktopSize ? (
          <div
            onClick={toggleMobileNav}
            style={{
              cursor: "pointer",
              padding: "5px",
              marginLeft: "5px",
            }}
          >
            <Icon
              icon={isMobileNavOpen ? "mdi:close" : "mdi:menu"}
              height={24}
              color={whiteLogo ? Colors.WHITE : theme.PrimaryText.color}
            />
          </div>
        ) : null}
      </div>
      {!isDesktopSize ? (
        <MobileNavDrawer
          isOpen={isMobileNavOpen}
          onClose={() => toggleMobileNav()}
          productDropdownItems={productDropdownItems}
          resourcesDropdownItems={resourcesDropdownItems}
          aboutDropdownItems={aboutDropdownItems}
          headerNavOnPress={headerNavOnPress}
          exploreOnPress={exploreOnPress}
          signUpUser={signUpUser}
        />
      ) : null}
    </div>
  );
});

export default TopHeader;
