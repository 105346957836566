import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";

type PopupModalMessageTagProps = {
  message: string;
  popupTitle: string;
  popupSubtext: string;
};

// A message and information icon tag that shows an overlay modal when you press on the icon
const PopupModalMessageTag = (props: PopupModalMessageTagProps) => {
  const { message, popupSubtext, popupTitle } = props;
  const [showDialog, setShowDialog] = useState(false);

  const containerRef = useRef<any>(null);
  useEffect(() => {
    const handler = (event: any) => {
      if (
        showDialog &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowDialog(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showDialog]);

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      <div className="AlignedRow" style={{ color: Colors.GRAY1, gap: 4 }}>
        <span style={{ fontSize: 14 }}>{message}</span>
        <Icon
          icon="mdi:information-outline"
          onClick={() => setShowDialog(!showDialog)}
          height={14}
          style={{ cursor: "pointer", color: Colors.BLACK, paddingTop: 2 }}
        />
      </div>
      {showDialog ? (
        <div
          className="ColumnNormal"
          style={{
            boxShadow: "-1px 1px 4px 0px #1E1E371A",
            backgroundColor: Colors.WHITE,
            zIndex: 99,
            gap: 5,
            width: 400,
            padding: 14,
            borderRadius: 8,
            position: "absolute",
            ...{ left: "5%", top: 30 },
          }}
        >
          <span style={{ fontSize: 14 }}>{popupTitle}</span>
          <span style={{ fontSize: 12, color: Colors.GRAY2 }}>
            {popupSubtext}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default PopupModalMessageTag;
