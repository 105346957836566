import { Colors } from "../../../utils/colors";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { TabPanel } from "../../FullEventSubComponents/TabPanel";
import StandardListContainer from "../../Containers/StandardListContainer";
import SearchBoxContainer from "../../Containers/SearchBoxContainer";
import ListTabs from "../../DropdownsAndTabs/ListTabs";
import FlatlistLoadMore from "../../FlatlistLoadMore";
import {
  AccountData,
  FollowerStatus,
  MembershipState,
} from "@markit/common.types";
import { useLoadUserFollowList } from "../../../hooks/useLoadUserFollowList";
import filter from "lodash.filter";
import EmptyStateButton from "../../Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";
import { EmptyStateFlatlist } from "../../EmptyStates/EmptyStateFlatlist";
import ProfileFollowerMembershipItem, {
  MEMBERSHIP_USER_FLEX_SECTIONS,
} from "../../DisplayItem/ProfileItem/ProfileFollowerMembershipItem";
import FullProfilePreviewModal from "../../FollowerProfile/FullProfilePreviewModal";
import ShareProfileLinkModal from "../CreatorProfile/ShareProfileLinkModal";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import {
  hasSubscription,
  hasSubscriptionUnlockedAdvancedData,
} from "@markit/common.utils";
import { notificationActions } from "../../../redux/slices/notificationSlice";

export type MembershipMembersProps = {
  tabValue: number;
  setTabValue: (tabValue: number) => void;
  listValue: number;
  setListValue: (listValue: number) => void;
};

const MembershipMembers = (props: MembershipMembersProps) => {
  const { tabValue, setTabValue, listValue, setListValue } = props;
  const { accountData, followersData } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [profilePreviewSelected, setProfilePreviewSelected] =
    useState<AccountData>();
  const [shareModal, setShareModal] = useState(false);

  const membershipsEnabled = useMemo(
    () =>
      accountData.membership &&
      accountData.membership.state === MembershipState.ACTIVE &&
      accountData.membership.membershipPlans,
    [accountData.membership]
  );

  const paidMembershipId = useMemo(() => {
    if (membershipsEnabled) {
      return accountData.membership.membershipPlans.filter(
        (plan) => plan.id !== "free"
      )[0].id;
    }
    return "";
  }, [accountData.membership.membershipPlans, membershipsEnabled]);

  const subscribedFollowers = useMemo(
    () =>
      followersData.filter(
        (follower) => follower.status === FollowerStatus.SUBSCRIBED
      ),
    [followersData]
  );

  const numFreeMembers = useMemo(
    () =>
      subscribedFollowers.filter(
        (follower) => follower.membershipPlanId === "free"
      ).length,
    [subscribedFollowers]
  );

  const numPaidMembers = useMemo(
    () =>
      subscribedFollowers.filter(
        (follower) => follower.membershipPlanId === paidMembershipId
      ).length,
    [subscribedFollowers, paidMembershipId]
  );

  const tabLabels = useMemo(
    () =>
      membershipsEnabled
        ? [...accountData.membership.membershipPlans]
            .sort((a, b) => b.price - a.price)
            .map((plan) => plan.name)
        : [],
    [accountData.membership.membershipPlans, membershipsEnabled]
  );

  const {
    fetchedUserData: freeUserData,
    fetchedFollowerData: freeFollowerData,
    loadMoreUsers: loadMoreFreeUsers,
    loadUsers: loadFreeUsers,
    loadSearchResultsThrottled: loadFreeSearchResultsThrottled,
    isLoading: isFreeLoading,
    isFinished: isFreeFinished,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: FollowerStatus.SUBSCRIBED,
    membershipPlanId: "free",
    windowSize: 30,
  });

  const {
    fetchedUserData: paidUserData,
    fetchedFollowerData: paidFollowerData,
    loadMoreUsers: loadMorePaidUsers,
    loadUsers: loadPaidUsers,
    loadSearchResultsThrottled: loadPaidSearchResultsThrottled,
    isLoading: isPaidLoading,
    isFinished: isPaidFinished,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: FollowerStatus.SUBSCRIBED,
    membershipPlanId: paidMembershipId,
    windowSize: 30,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (membershipsEnabled) {
      await Promise.all([loadFreeUsers(), loadPaidUsers()]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipsEnabled]);

  const hasSub = useMemo(() => hasSubscription(accountData), [accountData]);

  const hasAdvancedSub = useMemo(
    () => hasSubscriptionUnlockedAdvancedData(accountData),
    [accountData]
  );

  const emptyStateOnPress = useCallback(() => {
    if (hasAdvancedSub) {
      setTabValue(1);
    } else {
      dispatch(notificationActions.setMarkitPlusModal(true));
    }
  }, [hasAdvancedSub, setTabValue, dispatch]);

  const contains = useCallback((user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    const lowerCaseText = text.toLowerCase();
    if (text !== "") {
      if (listValue === 0) {
        loadPaidSearchResultsThrottled(lowerCaseText);
      } else {
        loadFreeSearchResultsThrottled(lowerCaseText);
      }
    }
    setSearchTerm(lowerCaseText);
  };

  const fetchedUserData = useMemo(() => {
    if (listValue === 0) {
      return paidUserData;
    } else {
      return freeUserData;
    }
  }, [listValue, paidUserData, freeUserData]);

  const isLoading = useMemo(() => {
    if (listValue === 0) {
      return isPaidLoading;
    } else {
      return isFreeLoading;
    }
  }, [listValue, isFreeLoading, isPaidLoading]);

  const isFinished = useMemo(() => {
    if (listValue === 0) {
      return isPaidFinished;
    } else {
      return isFreeFinished;
    }
  }, [listValue, isFreeFinished, isPaidFinished]);

  const loadMoreUsers = useMemo(() => {
    if (listValue === 0) {
      return loadMorePaidUsers;
    } else {
      return loadMoreFreeUsers;
    }
  }, [listValue, loadMoreFreeUsers, loadMorePaidUsers]);

  const currentItemsToShow = useMemo(() => {
    let userList: AccountData[] = fetchedUserData;
    if (searchTerm !== "") {
      userList = filter(userList, (user: AccountData) => {
        return contains(user, searchTerm);
      });
    }
    return userList;
  }, [fetchedUserData, searchTerm, contains]);

  const currentFollowerDataToShow = useMemo(
    () => (listValue === 0 ? paidFollowerData : freeFollowerData),
    [listValue, paidFollowerData, freeFollowerData]
  );

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoading}
        containerStyles={{ paddingTop: 100 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title={"No Members"}
            description={
              "Share your profile to grow your subscriber lists and generate monthly revenue."
            }
            icon={<Icon icon="ion:people" height={64} color={Colors.GRAY1} />}
            iconBox={84}
            containerStyles={{ paddingTop: 100 }}
            btnText="Share Profile"
            onPress={() => setShareModal(true)}
          />
        }
      />
    );
  }, [isLoading, searchTerm]);

  const renderItem = useCallback(
    (item: AccountData) => {
      return (
        <div key={item.uid}>
          <ProfileFollowerMembershipItem
            item={item}
            setProfilePreviewSelected={setProfilePreviewSelected}
            fetchedFollowerData={currentFollowerDataToShow}
          />
        </div>
      );
    },
    [currentFollowerDataToShow]
  );

  return (
    <TabPanel value={tabValue} index={0}>
      {membershipsEnabled && hasAdvancedSub ? (
        <div style={{ padding: "0px 30px 30px 30px" }}>
          <StandardListContainer
            searchComp={
              <div className="ColumnNormal" style={{ gap: 14 }}>
                <SearchBoxContainer
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                  containerStyles={{ marginTop: 0, width: 394 }}
                />
                <ListTabs
                  tabLabels={tabLabels}
                  tabNumbers={[numPaidMembers, numFreeMembers]}
                  selectedValue={listValue}
                  onChange={setListValue}
                  altColor={Colors.BLACK}
                />
              </div>
            }
            gridTable={{
              flexSections: MEMBERSHIP_USER_FLEX_SECTIONS,
              sectionTitles: ["Name", "Membership Plan", "Subscribe Date"],
            }}
            listComp={
              <FlatlistLoadMore
                fullList={fetchedUserData}
                currentList={currentItemsToShow}
                renderItem={(item: AccountData) => renderItem(item)}
                renderWhenEmpty={renderEmptyView}
                isLoadingMore={isLoading}
                showLoadMore={searchTerm.trim() === "" && !isFinished}
                loadMoreOnPress={loadMoreUsers}
              />
            }
          />
        </div>
      ) : (
        <div className="Centering" style={{ paddingBlock: 120 }}>
          <EmptyStateButton
            title={
              hasAdvancedSub
                ? "Enable Paid Plans to Start Earning"
                : `${hasSub ? "Upgrade" : "Get"} Markit+ to Enable Paid Plans`
            }
            description={
              hasAdvancedSub
                ? "Your free and paid plans will show up here once you connect to Stripe and enable paid plans."
                : `${
                    hasSub ? "Upgrade" : "Get"
                  } Markit+ to start earning with paid subscription plans`
            }
            icon={
              <Icon
                icon={"ion:ribbon"}
                height={50}
                style={{ color: Colors.GREEN2 }}
              />
            }
            iconBox={80}
            iconBackgroundColor={Colors.WHITE1}
            btnText={
              hasAdvancedSub
                ? "Enable Paid Plans"
                : `${hasSub ? "Upgrade" : "Get Markit+"}`
            }
            onPress={emptyStateOnPress}
            buttonContainerStyles={{
              backgroundColor: hasAdvancedSub ? Colors.GREEN2 : Colors.BLACK,
            }}
          />
        </div>
      )}
      {profilePreviewSelected ? (
        <FullProfilePreviewModal
          profileSelected={profilePreviewSelected}
          setProfileSelected={setProfilePreviewSelected}
        />
      ) : null}
      {shareModal ? (
        <ShareProfileLinkModal
          username={accountData.username}
          setShareLinkModal={setShareModal}
        />
      ) : null}
    </TabPanel>
  );
};

export default MembershipMembers;
