import {
  AccountData,
  Conversation,
  IMessage,
  TextTypes,
} from "@markit/common.types";
import "../../../css/GlobalStyles.css";
import { Colors } from "../../../utils/colors";
import LargePopupModalContainer from "../../Containers/LargePopupModalContainer";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { Icon } from "@iconify/react";
import ProfileItem from "../../ProfileItem";
import ConversationsMessagesContainer from "./ConversationsMessagesContainer";
import { CircularProgress } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { API } from "../../../API";
import { getUserConversationsRef } from "../../../utils/FirebaseUtils";
import { limit, onSnapshot, query, where } from "../../../firebase";
import { uniqueVals } from "@markit/common.utils";
import { fetchSingleConversation } from "../../../utils/FetchSingleData";
import { getConversationState } from "../../../redux/slices/conversationSlice";
import ConversationMessageInput from "./ConversationMessageInput";
import { useOnMount } from "../../../hooks/useOnMount";

type ConversationsSidebarModalProps = {
  conversationUser: AccountData;
  setConversationUser: (conversationUser: AccountData | undefined) => void;
  reopenProfilePreview?: () => void;
  fullProfileOpen: boolean;
};

const ConversationsSidebarModal = (props: ConversationsSidebarModalProps) => {
  const {
    conversationUser,
    setConversationUser,
    reopenProfilePreview,
    fullProfileOpen,
  } = props;
  const { conversations } = useSelector(getConversationState);
  const { userConversations } = conversations;
  const { account } = useSelector(getAccountState);
  const { accountData, followingAccountData } = account;
  const [fetchedAllMessages, setFetchedAllMessages] = useState(false);
  const [loadingConversation, setLoadingConversation] = useState(true);
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
  const [messageCount, setMessageCount] = useState(15);
  const [messages, setMessages] = useState<IMessage[]>([]);

  const scrollableFeedRef = useRef<any>(null);

  const scrollToBottom = () => {
    scrollableFeedRef.current?.scrollToBottom();
  };

  const fetchConversationMessages = useCallback(
    async (increment: boolean) => {
      if (conversationUser) {
        setLoadingMoreMessages(true);
        const foundConversation = await fetchSingleConversation(
          accountData.uid,
          conversationUser.uid,
          userConversations
        );

        if (foundConversation) {
          const response = await API.creatorText.fetchUserConversationMessages({
            conversationId: foundConversation.conversationSid,
            twilioUser: accountData,
            attendeeUser: conversationUser,
            messageLimit: increment ? messageCount + 15 : messageCount,
          });
          const { messages } = response;
          if (messages.length === 0) {
            setFetchedAllMessages(true);
          }
          setMessageCount(increment ? messageCount + 15 : messageCount);
          const sortedMessages = messages.sort((x: IMessage, y: IMessage) => {
            return (
              new Date(x.createdAt).getTime() - new Date(y.createdAt).getTime()
            );
          });
          setMessages(uniqueVals(sortedMessages));
          setLoadingConversation(false);
          setLoadingMoreMessages(false);
        }
      }
    },
    [accountData, conversationUser, messageCount, userConversations]
  );

  const newIMessage = useCallback(
    (convoData: Conversation) => {
      const iMessage: IMessage = {
        _id: convoData.recentMessage.twilioMessageId,
        text: convoData.recentMessage.text ?? "",
        photoURL: convoData.recentMessage.photoURL ?? "",
        createdAt: new Date(convoData.recentTimestamp),
        user: {
          _id: convoData.recentMessage.sentBy,
          name: followingAccountData.find(
            (m) => m.uid === convoData.recentMessage.sentBy
          )?.fullName,
          avatar: followingAccountData.find(
            (m) => m.uid === convoData.recentMessage.sentBy
          )?.profilePicURL,
        },
        type: TextTypes.CREATORMESSAGE,
      };
      return iMessage;
    },
    [followingAccountData]
  );

  useOnMount(() => {
    const conversationsRef = getUserConversationsRef(accountData.uid);
    const query_ = query(
      conversationsRef,
      where("participantUids", "array-contains", conversationUser.uid),
      limit(1)
    );

    const unsubscribe = onSnapshot(query_, (snapshot) => {
      if (snapshot.empty) {
        setLoadingConversation(false);
        return;
      }
      const conversationData = snapshot.docs.map((doc) => doc.data())[0];
      const iMessage: IMessage = newIMessage(conversationData);
      if (iMessage.text !== "" || iMessage.photoURL !== "") {
        setMessages((prev) => {
          if (conversationData.recentMessage.sentBy !== accountData.uid) {
            const index = prev.findIndex((c) => c._id === iMessage._id);
            if (index === -1) {
              prev = prev.concat(iMessage);
            } else {
              prev.splice(index, 1, iMessage);
            }
          } else {
            prev = prev.concat(iMessage);
          }
          return uniqueVals(prev, (message) => message._id.toString());
        });
      }
    });

    return () => unsubscribe();
  });

  useEffect(() => {
    fetchConversationMessages(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationUser]);

  return (
    <>
      <LargePopupModalContainer
        showModal={conversationUser !== undefined}
        headerComp={
          <div>
            <div
              className="LargePopupPanelClose"
              onClick={() => {
                setConversationUser(undefined);
                if (reopenProfilePreview) {
                  reopenProfilePreview();
                }
              }}
            >
              <Icon
                icon={fullProfileOpen ? "ion:chevron-back" : "mdi:close"}
                height={24}
              />
            </div>
            <div style={{ margin: "14px -14px 14px" }}>
              <hr />
            </div>
          </div>
        }
        subHeaderComp={
          <ProfileItem
            profileItem={conversationUser}
            onItemPress={() => {}}
            disabled
            hideViewIcon
          />
        }
        valueComp={
          loadingConversation ? (
            <div
              className="ColumnCentering"
              style={{ gap: 24, height: "60vh" }}
            >
              <CircularProgress style={{ color: Colors.BLACK }} size={38} />
              <div
                className="ColumnNormal"
                style={{ gap: 14, textAlign: "center" }}
              >
                <span className="sectionTitle">Loading Chat</span>
                <span className="bodySubtext">
                  This will only take a second
                </span>
              </div>
            </div>
          ) : (
            <div
              className="ColumnNormal"
              style={{
                margin: "0px -20px 10px",
                height: "calc(100vh - 190px)",
              }}
            >
              <ConversationsMessagesContainer
                messages={messages}
                userId={accountData.uid}
                conversationsMainUser={conversationUser}
                fetchedAllMessages={fetchedAllMessages}
                fetchConversationMessages={() =>
                  fetchConversationMessages(true)
                }
                loadingMoreMessages={loadingMoreMessages}
                messageBubbleAltWidth={"70%"}
                scrollableFeedRef={scrollableFeedRef}
              />
              <div className="AlignedRow" style={{ paddingTop: 10 }}>
                <ConversationMessageInput
                  conversationsMainUser={conversationUser}
                  scrollToBottom={scrollToBottom}
                />
              </div>
            </div>
          )
        }
      />
    </>
  );
};

export default ConversationsSidebarModal;
