import React, { memo } from "react";
import { useTheme } from "../../hooks/useTheme";
import { ThemeType } from "@markit/common.types";

type HorizontalDividerProps = {
  altMargin?: number;
  eventTheme?: ThemeType;
};

export const HorizontalDivider = memo(function HorizontalDividerFn(
  props: HorizontalDividerProps
) {
  const { altMargin, eventTheme } = props;
  const { theme } = useTheme(eventTheme);

  return (
    <hr
      style={{
        marginBlock: altMargin ?? 0,
        borderColor: theme.DividerColor.borderColor,
        borderWidth: 1.5,
      }}
    />
  );
});
