import {
  getSubscriptionReferralRef,
  getSubscriptionReferralsRef,
  getUserFollowersRef,
  getUserRef,
  getUsersRef,
} from "./FirebaseUtils";
import { generate } from "shortid";
import {
  Membership,
  MembershipPlanFrequency,
  MembershipState,
  SubscriptionReferral,
} from "@markit/common.types";
import {
  getCountFromServer,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "../firebase";

/**
 * Gets called when a user purchases a subscription with a referral link
 * Adds a referral document to the subscriptionReferrals collection
 * @param referrerUsername username of the referrer
 * @param refereeUserId uid of the referee
 */
export const createSubscriptionReferral = async (
  referrerUsername: string,
  refereeUserId: string
) => {
  const usersRef = getUsersRef();
  const referrerQuery = query(
    usersRef,
    where("username", "==", referrerUsername)
  );
  const querySnapshot = await getDocs(referrerQuery);
  const referrerUserId: string = querySnapshot.docs[0].data().uid;
  const referralId = generate();
  const subscriptionReferral: SubscriptionReferral = {
    id: referralId,
    referrer: referrerUserId,
    referee: refereeUserId,
    createdAt: new Date().toISOString(),
  };
  // check if a referral already exists for the referee
  const subscriptionReferralQuery = query(
    getSubscriptionReferralsRef(),
    where("referee", "==", refereeUserId)
  );
  const subscriptionReferralSnapshot = await getDocs(subscriptionReferralQuery);
  if (!subscriptionReferralSnapshot.empty) {
    alert(
      "You have already received a referral in the past! You will not receive a referral bonus"
    );
    return;
  }
  const subscriptionReferralRef = getSubscriptionReferralRef(referralId);
  setDoc(subscriptionReferralRef, subscriptionReferral);
};

// Gets the number of non-free membership subscriptions for a creator
export const getNumPaidMembershipSubscriptions = async (userId: string) => {
  const followersRef = getUserFollowersRef(userId);
  const query_ = query(followersRef, where("membershipPlanId", "!=", "free"));
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

// Gets the number of paid members for a specific membership plan
export const getNumMembershipSubscriptionMembers = async (
  userId: string,
  membershipId: string
) => {
  const followersRef = getUserFollowersRef(userId);
  const query_ = query(
    followersRef,
    where("membershipPlanId", "==", membershipId)
  );
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

// Just change the membership state to inactive when a user disables memberships
export const disableMemberships = async (
  userId: string,
  membership: Membership
) => {
  const userRef = getUserRef(userId);
  const updatedMembership: Membership = {
    ...membership,
    state: MembershipState.INACTIVE,
  };
  updateDoc(userRef, { membership: updatedMembership });
};

// Returns the shortened label for display of the membership frequency
export const getShortMembershipFrequencyLabel = (
  frequency: MembershipPlanFrequency
) => {
  if (
    frequency === MembershipPlanFrequency.MONTHLY ||
    frequency === MembershipPlanFrequency.FOREVER
  ) {
    return "mo";
  } else if (frequency === MembershipPlanFrequency.ANNUALLY) {
    return "yr";
  }
};
