import { Colors } from "../../../utils/colors";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import RectangleButton from "../../Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import LabelButton from "../../Buttons/LabelButton";
import { convertToMoney } from "@markit/common.utils";
import { MembershipPlan } from "@markit/common.types";
import { getShortMembershipFrequencyLabel } from "../../../utils/subscriptionUtils";
import { DropdownMenu, DropdownMenuItem } from "../../DropdownMenu";
import MembershipEditPlanModal from "./MembershipEditPlanModal";
import ShareMembershipUpgradeLinkModal from "../CreatorProfile/ShareMembershipUpgradeLinkModal";

export type MembershipSettingsPlanItemProps = {
  membershipPlan: MembershipPlan;
  viewPlanSubscribers: (membershipId: string) => void;
};

const MembershipSettingsPlanItem = (props: MembershipSettingsPlanItemProps) => {
  const { membershipPlan, viewPlanSubscribers } = props;
  const { accountData } = useSelector(getAccountState).account;
  const [editMembershipPlan, setEditMembershipPlan] =
    useState<MembershipPlan>();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [shareUpgradeLink, setShareUpgradeLink] = useState(false);

  const styles = {
    sectionContainer: {
      paddingBlock: 14,
      backgroundColor: Colors.WHITE,
    },
  };

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const items: DropdownMenuItem[] = [
      {
        title: "View Subscribers",
        icon: "ion:people",
        key: "view_subscribers",
        onPress: () => viewPlanSubscribers(membershipPlan.id),
      },
    ];
    if (membershipPlan.id !== "free") {
      items.push({
        title: "Share Upgrade Link",
        icon: "ion:ribbon",
        key: "share_upgrade_link",
        onPress: () => setShareUpgradeLink(true),
      });
    }

    return items;
  }, [membershipPlan.id, viewPlanSubscribers]);

  return (
    <>
      <StandardBorderedContainer
        key={membershipPlan.id}
        containerStyles={styles.sectionContainer}
      >
        <div
          className="AlignedRowSpaced"
          style={{ alignItems: "flex-start", paddingInline: 14 }}
        >
          <div className="ColumnNormal" style={{ gap: 14 }}>
            <div>
              <LabelButton
                text={membershipPlan.name}
                color={Colors.BLACK}
                backgroundColor={Colors.GRAY6}
              />
            </div>
            <div
              className="AlignedRow"
              style={{ gap: 4, alignItems: "baseline" }}
            >
              <span style={{ fontSize: 24 }}>
                {convertToMoney(membershipPlan.price / 100)}
              </span>
              {membershipPlan.id !== "free" ? (
                <span className="smallBodySubtext">
                  /
                  {getShortMembershipFrequencyLabel(
                    membershipPlan.billingFrequency
                  )}
                </span>
              ) : null}
            </div>
          </div>
          <div className="AlignedRow" style={{ gap: 10 }}>
            <DropdownMenu
              dropdownItems={dropdownItems}
              placement="bottomRight"
              trigger={["click"]}
              altWidth={200}
              isOpen={isOpenDropdown}
              onOpenChange={(isOpen: boolean) => setIsOpenDropdown(isOpen)}
            >
              <RectangleButton
                buttonLabel="Manage"
                onPress={() => {}}
                altTextColor={Colors.BLACK}
                containerStyles={{
                  whiteSpace: "nowrap",
                  padding: 14,
                  backgroundColor: Colors.WHITE1,
                  border: `0.5px solid ${Colors.GRAY2}`,
                }}
                iconRight={
                  <Icon
                    icon={
                      isOpenDropdown ? "ion:chevron-up" : "ion:chevron-down"
                    }
                    height={14}
                    color={Colors.BLACK}
                  />
                }
              />
            </DropdownMenu>
            <RectangleButton
              buttonLabel="Edit"
              onPress={() => setEditMembershipPlan(membershipPlan)}
              iconRight={
                <Icon icon={"ion:create"} height={14} color={Colors.WHITE} />
              }
              altColor={Colors.BLACK}
              altTextColor={Colors.WHITE}
              containerStyles={{ padding: 14 }}
            />
          </div>
        </div>
        <HorizontalDivider altMargin={14} />
        <div className="ColumnNormal" style={{ gap: 7, paddingInline: 14 }}>
          <span className="bodyMedium" style={{ fontSize: 12 }}>
            Benefits
          </span>
          <span className="smallBodySubtext" style={{ whiteSpace: "pre-wrap" }}>
            {membershipPlan.description}
          </span>
        </div>
      </StandardBorderedContainer>
      {editMembershipPlan ? (
        <MembershipEditPlanModal
          editMembershipPlan={editMembershipPlan}
          setEditMembershipPlan={setEditMembershipPlan}
        />
      ) : null}
      {shareUpgradeLink ? (
        <ShareMembershipUpgradeLinkModal
          username={accountData.username}
          setShareLinkModal={setShareUpgradeLink}
        />
      ) : null}
    </>
  );
};

export default MembershipSettingsPlanItem;
