import { Colors } from "../../utils/colors";
import {
  MARKIT_FREE_CREATOR_NUMBER,
  MARKIT_NUMBER,
} from "@markit/common.utils";
import { formatPhoneNumber } from "react-phone-number-input";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { Icon } from "@iconify/react";
import { AccountData } from "@markit/common.types";
import React, { useMemo } from "react";
import { useTheme } from "../../hooks/useTheme";

type OptedOutBannerProps = {
  host?: AccountData;
  optedOut: boolean;
  optedOutCreator?: boolean;
  containerStyles?: React.CSSProperties;
};

const OptedOutBanner = (props: OptedOutBannerProps) => {
  const { host, optedOut, optedOutCreator, containerStyles } = props;
  const { theme } = useTheme();

  const optInNumber = useMemo(
    () =>
      host && host.customer.phone !== ""
        ? host.customer.phone
        : MARKIT_FREE_CREATOR_NUMBER,
    [host]
  );

  return (
    <StandardBorderedContainer
      containerStyles={{
        ...containerStyles,
        width: "100%",
        backgroundColor: theme.SecondaryBG.backgroundColor,
      }}
    >
      <div>
        <div className="AlignedRow" style={{ padding: 14 }}>
          <Icon
            icon="ion:alert-circle"
            height={18}
            style={{ marginRight: 7, color: Colors.RED3 }}
          />
          <div style={{ fontWeight: 500, color: Colors.RED3 }}>
            Opt Into Texts
          </div>
        </div>
        <HorizontalDivider />
        <div
          className="ColumnNormal"
          style={{
            gap: 14,
            fontSize: 14,
            padding: 14,
          }}
        >
          <span style={theme.SubText}>
            You previously opted out of texts from{" "}
            {optedOut
              ? "Markit"
              : optedOutCreator && host
              ? host.fullName
              : "Markit"}
            . Opt into texts to continue.
          </span>
          <span style={theme.SubText}>
            Text START to{" "}
            <a
              href={"sms:" + optInNumber}
              className="SupportLink"
              style={{
                ...theme.PrimaryText,
                fontWeight: 500,
                textDecoration: "none",
              }}
            >
              {optedOut
                ? formatPhoneNumber(MARKIT_NUMBER)
                : optedOutCreator && host
                ? formatPhoneNumber(optInNumber)
                : formatPhoneNumber(MARKIT_NUMBER)}
            </a>
          </span>
        </div>
      </div>
    </StandardBorderedContainer>
  );
};

export default OptedOutBanner;
