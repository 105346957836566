import "../../css/Login.css";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import { IconButton } from "@mui/material";
import { firebaseAuth } from "../../firebase";
import TopHeader from "../../components/TopHeader";
import { Colors } from "../../utils/colors";
import CustomTextField from "../../components/CustomTextField";
import { isDesktop } from "react-device-detect";
import { MixpanelContext } from "../../context/AnalyticsService";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import {
  friendlyRoleName,
  MARKITAI,
  PhoneErrorType,
} from "@markit/common.utils";
import VerificationCodeButton, {
  VerificationCodeOrigin,
} from "../../components/Verification/VerificationCodeButton";
import { VerificationState, Event } from "@markit/common.types";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { useLogin } from "../../hooks/useLogin";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useTheme } from "../../hooks/useTheme";
import MessageDataRates from "../../components/MessageDataRates";
import LoginForgotPassword from "../../components/Login/LoginForgotPassword";
import AlertContainer from "../../components/Containers/AlertContainer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomPhoneTextField from "../../components/TextFields/CustomPhoneTextField";
import { showPhoneInputError } from "../../utils/phoneUtils";
import RecaptchaVerifierWrapper from "../../components/Wrappers/RecaptchaVerifierWrapper";
import { Icon } from "@iconify/react";
import TextHoverButton from "../../components/Buttons/TextHoverButton";
import LoginWelcomeBody from "../../components/Login/LoginWelcomeBody";
import { getEventData } from "../../utils/FirebaseUtils";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import { Helmet } from "react-helmet";
import { NavigationId } from "../../navigation/AppParamList";
import LoginOnboardingAccountType from "../../components/Login/LoginOnboardingAccountType";

export enum LoginStage {
  PHONE = "Phone",
  EMAIL_LOGIN = "Email Login",
  EMAIL_SIGNUP = "Email Signup",
  ACCOUNT_TYPE = "Account Type",
  FORGOT_PASSWORD = "Forgot Password",
}

const LoginWelcome = memo(function LoginWelcomeFn() {
  const { account } = useSelector(getAccountState);
  const { currentRoleTicket } = account;
  const { preEmail, preLoginStage } = useLocation().state || {}; // preEmail = email input from welcome landing page, preLoginStage = different login stage from welcome landing page
  const mixpanel = useContext(MixpanelContext);
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });

  const { loginWithCredential } = useLogin();

  const [loading, setLoading] = useState(false);
  const [verificationState, setVerificationState] = useState(
    VerificationState.UNVERIFIED
  );
  const [optedOut, setOptedOut] = useState(false);
  const [tempPhoneNumber, setTempPhoneNumber] = useState<string>("");

  const [showCodeInputError, setShowCodeInputError] = useState(false);
  const [showPhoneNumberError, setShowPhoneNumberError] =
    useState<PhoneErrorType>({ isError: false, message: "" });

  const [loginStage, setLoginStage] = useState<LoginStage>(
    preLoginStage ?? LoginStage.EMAIL_LOGIN
  );
  // email login/signup
  const [email, setEmail] = useState<string>(preEmail ?? "");
  const [emailError, setEmailError] = useState({
    status: false,
    message: "",
  });
  const [passwordError, setPasswordError] = useState({
    status: false,
    message: "",
  });
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);

  // email signup
  const [confirmEmail, setConfirmEmail] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [currentEventForRole, setCurrentEventForRole] = useState<Event>();

  const styles = {
    errorMessage: { fontSize: 12, color: Colors.RED2 },
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (currentRoleTicket) {
      const eventDataForRole = await getEventData(currentRoleTicket.eventId);
      setCurrentEventForRole(eventDataForRole);
    }
  }, [currentRoleTicket]);

  const defaultSubscriber = useMemo(
    () => searchParams.has("subscriber"),
    [searchParams]
  );

  const handleClickShowPassword = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword]
  );

  const handleClickShowConfirmPassword = useCallback(
    () => setShowConfirmPassword(!showConfirmPassword),
    [showConfirmPassword]
  );

  const navigateEmailLoginOnPress = useCallback(() => {
    mixpanel.track("Webapp: User Start Login");
    setLoginStage(LoginStage.EMAIL_LOGIN);
  }, [mixpanel]);

  const navigateForgotPassword = useCallback(() => {
    setLoginStage(LoginStage.FORGOT_PASSWORD);
  }, []);

  const backToEmailSignIn = useCallback(async () => {
    setLoginStage(LoginStage.EMAIL_LOGIN);
    setEmail("");
    setConfirmEmail("");
    setPassword("");
    setConfirmPassword("");
    setEmailError({ status: false, message: "" });
    setPasswordError({ status: false, message: "" });
  }, []);

  const logInWithEmailAndPassword = useCallback(async () => {
    try {
      if (!email || !password) {
        setAlertText({
          heading: "One or more of the fields are empty.",
          subHeading: "You must fill them all to continue.",
        });
        return;
      }
      await signInWithEmailAndPassword(firebaseAuth, email, password).then(
        async (credential) => {
          if (credential.user !== null) {
            setLoading(true);
            setEmailError({ status: false, message: "" });
            setPasswordError({ status: false, message: "" });
            loginWithCredential(credential, true);
            setLoading(false);
          }
        }
      );
    } catch (error: any) {
      console.error(error);
      switch (error.message) {
        case "The email address is badly formatted.":
          setEmailError({
            status: true,
            message: "The email address is badly formatted",
          });
          break;
        case "The password is invalid or the user does not have a password.":
          setPasswordError({
            status: true,
            message:
              '"The password is invalid or the user does not have a password."',
          });
          break;
        default:
          setEmailError({
            status: true,
            message: "The email or password is invalid. Please try again.",
          });
          setPasswordError({
            status: true,
            message: "The email or password is invalid. Please try again.",
          });
      }
    }
  }, [email, password, loginWithCredential]);

  const registerEmailAndPassword = useCallback(() => {
    const emailLower = email.toLowerCase();
    const confirmEmailLower = confirmEmail.toLowerCase();
    if (!email || !password) {
      setAlertText({
        heading: "One or more of the fields are empty.",
        subHeading: "You must fill them all to continue.",
      });
      return;
    }
    if (emailLower !== confirmEmailLower) {
      setAlertText({
        heading: "Your emails do not match!",
        subHeading: "",
      });
      return;
    }
    if (password !== confirmPassword) {
      setAlertText({
        heading: "Your passwords do not match!",
        subHeading: "",
      });
      return;
    }
    setLoading(true);
    createUserWithEmailAndPassword(firebaseAuth, emailLower, password)
      .then((userCredential) => {
        if (userCredential.user !== null) {
          loginWithCredential(userCredential, true);
          mixpanel.track("Webapp: Agreed to Terms", {
            distinct_id: userCredential.user.uid,
            type: "Onboarding",
            customer_email: emailLower,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.code === "auth/email-already-exists") {
          setEmailError({ status: true, message: "Email already exists" });
        } else if (error.code === "auth/invalid-email") {
          setEmailError({ status: true, message: "Poorly formatted email" });
        } else if (error.code === "auth/weak-password") {
          setPasswordError({
            status: true,
            message: "Weak password. Please try another.",
          });
        } else {
          setEmailError({ status: true, message: error.message });
        }
        setLoading(false);
      });
  }, [
    confirmEmail,
    confirmPassword,
    email,
    loginWithCredential,
    mixpanel,
    password,
  ]);

  const continueWithPhone = useCallback(
    (defaultSubscriber?: boolean) => {
      if (defaultSubscriber) {
        navigate(`${NavigationId.SIGN_IN}?subscriber`, { replace: true });
      }
      setLoginStage(LoginStage.PHONE);
    },
    [navigate]
  );

  const renderBodyTitle = useMemo(
    () => (currentRoleTicket ? "Accept Organizer Role" : "Welcome to Markit"),
    [currentRoleTicket]
  );

  const renderBodySubtext = useCallback(
    (subtext: string) =>
      currentRoleTicket && currentEventForRole ? (
        <span>
          You&apos;ve been invited as a {friendlyRoleName(currentRoleTicket)}{" "}
          for{" "}
          <a
            style={{ color: Colors.BLACK, textDecoration: "underline" }}
            href={`${MARKITAI}/e/${currentEventForRole.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {currentEventForRole.title}
          </a>
          <br />
          Sign in or sign up below
          {currentRoleTicket ? " to accept this role." : ""}
        </span>
      ) : (
        subtext
      ),
    [currentEventForRole, currentRoleTicket]
  );

  const renderEmailLogin = useMemo(
    () => (
      <LoginWelcomeBody
        title={renderBodyTitle}
        subtext={renderBodySubtext(
          "Sign in with your email and password below"
        )}
      >
        <div className="ColumnNormal" style={{ gap: 14, width: "100%" }}>
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <CustomTextField
              value={email}
              placeholder="Email"
              inputMode="text"
              borderRadius={12}
              onChange={(change: any) => {
                setEmailError({
                  status: false,
                  message: "",
                });
                setEmail(change.target.value);
              }}
              error={emailError.status}
            />
            {emailError.status ? (
              <span style={styles.errorMessage}>{emailError.message}</span>
            ) : null}
          </div>
          <CustomTextField
            inputMode={showPassword ? "text" : "password"}
            value={password}
            placeholder="Password"
            error={passwordError.status}
            onChange={(password: any) => {
              setPasswordError({ status: false, message: "" });
              setPassword(password.target.value);
            }}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleClickShowPassword}
              >
                {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
              </IconButton>
            }
          />
        </div>
        <RectangleButton
          buttonLabel={<span>Continue</span>}
          onPress={logInWithEmailAndPassword}
          theme={theme}
          altPaddingVert={12}
          disabled={loading}
          loading={loading}
        />
        <div className="AlignedRow" style={{ gap: 20 }}>
          <TextHoverButton
            text="Forgot Password?"
            onPress={navigateForgotPassword}
            textStyles={{ color: Colors.BLACK }}
          />
          <TextHoverButton
            text="Continue with Phone"
            icon={
              <Icon
                icon={"ion:phone-portrait-outline"}
                height={13}
                color={Colors.GRAY1}
              />
            }
            onPress={() => continueWithPhone(false)}
          />
        </div>
        <span style={{ fontSize: 12, color: Colors.GRAY2 }}>
          If you are a subscriber, you must continue with phone
        </span>
      </LoginWelcomeBody>
    ),
    [
      continueWithPhone,
      email,
      emailError.message,
      emailError.status,
      handleClickShowPassword,
      loading,
      logInWithEmailAndPassword,
      navigateForgotPassword,
      password,
      passwordError.status,
      renderBodySubtext,
      renderBodyTitle,
      showPassword,
      styles.errorMessage,
      theme,
    ]
  );

  const renderEmailSignUp = useMemo(
    () => (
      <LoginWelcomeBody
        title={renderBodyTitle}
        subtext={renderBodySubtext(
          "Sign up with your email to continue as a creator"
        )}
      >
        <div className="ColumnNormal" style={{ gap: 14, width: "100%" }}>
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="bodySmallMedium">Email</span>
            <CustomTextField
              value={email}
              placeholder="Email"
              inputMode="text"
              borderRadius={12}
              onChange={(change: any) => {
                setEmailError({
                  status: false,
                  message: "",
                });
                setEmail(change.target.value);
              }}
              error={emailError.status}
            />
            {emailError.status ? (
              <span style={styles.errorMessage}>{emailError.message}</span>
            ) : null}
          </div>
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="bodySmallMedium">Confirm Email</span>
            <CustomTextField
              value={confirmEmail}
              placeholder="Confirm Email"
              inputMode="text"
              borderRadius={12}
              onChange={(change: any) => {
                setConfirmEmail(change.target.value);
              }}
              error={emailError.status}
            />
          </div>
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="bodySmallMedium">Password</span>
            <CustomTextField
              inputMode={showPassword ? "text" : "password"}
              value={password}
              placeholder="Password"
              error={passwordError.status}
              onChange={(password: any) => {
                setPasswordError({ status: false, message: "" });
                setPassword(password.target.value);
              }}
              autocompletePassword
              endAdornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleClickShowPassword}
                >
                  {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                </IconButton>
              }
            />
            {passwordError.status ? (
              <span style={styles.errorMessage}>{passwordError.message}</span>
            ) : null}
          </div>
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="bodySmallMedium">Confirm Password</span>
            <CustomTextField
              inputMode={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              placeholder="Confirm Password"
              error={passwordError.status}
              onChange={(password: any) => {
                setPasswordError({ status: false, message: "" });
                setConfirmPassword(password.target.value);
              }}
              autocompletePassword
              endAdornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleClickShowConfirmPassword}
                >
                  {showConfirmPassword ? <MdVisibility /> : <MdVisibilityOff />}
                </IconButton>
              }
            />
          </div>
        </div>
        <RectangleButton
          buttonLabel={<span>Create Account</span>}
          onPress={registerEmailAndPassword}
          theme={theme}
          altPaddingVert={12}
          disabled={loading}
          loading={loading}
        />
        <MessageDataRates />
      </LoginWelcomeBody>
    ),
    [
      confirmEmail,
      confirmPassword,
      email,
      emailError.message,
      emailError.status,
      handleClickShowConfirmPassword,
      handleClickShowPassword,
      loading,
      password,
      passwordError.message,
      passwordError.status,
      registerEmailAndPassword,
      renderBodySubtext,
      renderBodyTitle,
      showConfirmPassword,
      showPassword,
      styles.errorMessage,
      theme,
    ]
  );

  const renderAccountType = useMemo(
    () => (
      <LoginOnboardingAccountType
        creatorOnPress={() => setLoginStage(LoginStage.EMAIL_SIGNUP)}
        subscriberOnPress={() => continueWithPhone(true)}
      />
    ),
    [continueWithPhone]
  );

  const renderPhoneLogin = useMemo(
    () => (
      <LoginWelcomeBody
        title={renderBodyTitle}
        subtext={renderBodySubtext(
          defaultSubscriber
            ? "If you are a subscriber, you must continue with phone to receive texts"
            : "Sign in or sign up with your phone number below"
        )}
      >
        <div className="ColumnNormal" style={{ gap: 20, width: "100%" }}>
          <CustomPhoneTextField
            placeholder="Enter Phone Number"
            phoneNumber={tempPhoneNumber}
            onPhoneChange={(value) => {
              setShowPhoneNumberError({ isError: false, message: "" });
              setTempPhoneNumber(value as string);
            }}
            showPhoneError={showPhoneNumberError}
            disabled={
              optedOut || verificationState !== VerificationState.UNVERIFIED
            }
          />
          <VerificationCodeButton
            origin={VerificationCodeOrigin.SIGN_IN}
            buttonText="Continue"
            tempPhoneNumber={tempPhoneNumber}
            verificationState={verificationState}
            setVerificationState={setVerificationState}
            optedOut={optedOut}
            setOptedOut={setOptedOut}
            showCodeInputError={showCodeInputError}
            setShowCodeInputError={setShowCodeInputError}
            showError={() =>
              showPhoneInputError(tempPhoneNumber, setShowPhoneNumberError)
            }
          />
        </div>
        {!defaultSubscriber &&
        (verificationState === VerificationState.UNVERIFIED || optedOut) ? (
          <>
            <TextHoverButton
              text="Are you an organization? Continue with email"
              onPress={navigateEmailLoginOnPress}
              icon={<Icon icon="ion:mail" height={13} color={Colors.BLACK} />}
              textStyles={{ color: Colors.BLACK }}
            />
            <span
              style={{
                fontSize: 12,
                color: Colors.GRAY2,
                textAlign: "center",
                paddingInline: isDesktop ? 0 : 30,
              }}
            >
              Email is recommended for organizations where multiple people will
              be accessing the same account.
            </span>
          </>
        ) : null}
      </LoginWelcomeBody>
    ),
    [
      defaultSubscriber,
      navigateEmailLoginOnPress,
      optedOut,
      renderBodySubtext,
      renderBodyTitle,
      showCodeInputError,
      showPhoneNumberError,
      tempPhoneNumber,
      verificationState,
    ]
  );

  return (
    <RecaptchaVerifierWrapper>
      <Helmet>
        <title>Sign In Sign Up | Markit AI</title>
        <meta
          name="description"
          content="Sign in or sign up to Markit to start texting."
        />
        <link rel="canonical" href={MARKITAI + NavigationId.SIGN_IN} />
      </Helmet>
      <div className="LoginContainer" style={{ height: "100vh" }}>
        {isDesktop ? <TopHeader hideLogin /> : null}
        <div
          className="ColumnNormal"
          style={{
            margin: isDesktop ? "auto" : undefined,
            gap: 30,
            width: isDesktop ? 544 : undefined,
            marginTop: isDesktop ? 40 : 60,
          }}
        >
          {loginStage === LoginStage.EMAIL_LOGIN ? (
            renderEmailLogin
          ) : loginStage === LoginStage.EMAIL_SIGNUP ? (
            renderEmailSignUp
          ) : loginStage === LoginStage.ACCOUNT_TYPE ? (
            renderAccountType
          ) : loginStage === LoginStage.FORGOT_PASSWORD ? (
            <LoginForgotPassword
              email={email}
              setEmail={setEmail}
              emailError={emailError}
              setEmailError={setEmailError}
              backToEmailOnPress={backToEmailSignIn}
            />
          ) : (
            renderPhoneLogin
          )}
        </div>
      </div>
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        theme={theme}
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
    </RecaptchaVerifierWrapper>
  );
});

export default LoginWelcome;
