import "../../css/GlobalStyles.css";
import { useTheme } from "../../hooks/useTheme";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MixpanelContext } from "../../context/AnalyticsService";
import PopupModalContainer from "../Containers/PopupModalContainer";
import RectangleButton from "../Buttons/RectangleButton";
import { Colors } from "../../utils/colors";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useNavigate } from "../../hooks/useNavigate";
import { Icon } from "@iconify/react";
import HelpModal from "../Containers/HelpModal";
import MarkitLogoBlue from "../../assets/MarkitLogoBlue.png";
import TextHoverButton from "../Buttons/TextHoverButton";
import {
  getNotificationState,
  notificationActions,
} from "../../redux/slices/notificationSlice";
import {
  hasSubscription,
  isBasicSubscription,
  isCreatorSubscription,
} from "@markit/common.utils";

type MarkitPlusModalProps = {
  alternateIsVisible?: boolean; // This is for the case where the modal is shown within another modal
  alternateCloseOnPress?: () => void;
};

export const MarkitPlusModal = (props: MarkitPlusModalProps) => {
  const { alternateIsVisible, alternateCloseOnPress } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { markitPlusModal } = useSelector(getNotificationState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const mixpanel = useContext(MixpanelContext);
  const [helpModal, setHelpModal] = useState(false);

  useEffect(() => {
    if (markitPlusModal) {
      mixpanel.track("Pressed Markit+ Subscription Prompt", {
        distinct_id: accountData.uid,
        from: "webapp",
      });
    }
  }, [accountData.uid, mixpanel, markitPlusModal]);

  const hasSub = useMemo(() => hasSubscription(accountData), [accountData]);

  const onSubscriptionPress = useCallback(async () => {
    dispatch(notificationActions.setMarkitPlusModal(false));
    navigate("/subscription", { state: { changePlan: hasSub } });
  }, [dispatch, hasSub, navigate]);

  const closeOnPress = useCallback(() => {
    if (alternateCloseOnPress) {
      alternateCloseOnPress();
    } else {
      dispatch(notificationActions.setMarkitPlusModal(false));
    }
  }, [dispatch, alternateCloseOnPress]);

  const renderItemRow = useCallback(
    (title: string, subtext: string, iconName: string) => (
      <div className="AlignedRow">
        <div>
          <Icon icon={iconName} height={25} color={Colors.BLACK} />
        </div>
        <div className="ColumnNormal" style={{ gap: 4, paddingLeft: 20 }}>
          <span style={{ fontWeight: 500 }}>{title}</span>
          <span style={{ fontSize: 13, color: Colors.GRAY2, width: 320 }}>
            {subtext}
          </span>
        </div>
      </div>
    ),
    []
  );

  // Items to show if you are on free plan currently
  const renderFreePlanItems = useMemo(
    () => (
      <>
        {renderItemRow(
          "Dedicated Phone Number",
          "Get your own phone number and contact card that people can text directly.",
          "ion:call"
        )}
        {renderItemRow(
          "Two-Way Texting",
          "Answer questions, chat, and engage your subscribers with two-way texting conversations.",
          "ion:chatbubbles"
        )}
        {renderItemRow(
          "Paid Memberships",
          "Make money from texting. Charge monthly and annual fees from subscribers.",
          "ion:ribbon"
        )}
        {renderItemRow(
          "Import Contacts & Integrations",
          "Import contacts to start texting instantly.",
          "ion:cloud-upload"
        )}
      </>
    ),
    [renderItemRow]
  );

  // Items to show if you have a subscription already
  const renderSubscriptionPlanItems = useMemo(
    () => (
      <>
        {renderItemRow(
          "More Audience Lists",
          "More segmentation with lists.",
          "ion:list-circle"
        )}
        {isBasicSubscription(accountData) ? (
          <>
            {renderItemRow(
              "Send Images",
              "Send images directly through text.",
              "ion:image"
            )}
            {renderItemRow(
              "Paid Memberships",
              "Make money from texting. Charge monthly and annual fees from subscribers.",
              "ion:ribbon"
            )}
            {renderItemRow(
              "Import Contacts & Integrations",
              "Import contacts to start texting instantly.",
              "ion:cloud-upload"
            )}
          </>
        ) : (
          <>
            {renderItemRow(
              "API Access",
              "Work with our team to get direct API access.",
              "ion:code-slash"
            )}
            {renderItemRow(
              "White Glove Support",
              "Dedicated support agent.",
              "ion:diamond"
            )}
          </>
        )}
      </>
    ),
    [accountData, renderItemRow]
  );

  return (
    <>
      {(markitPlusModal || alternateIsVisible) && !helpModal ? (
        <PopupModalContainer
          headerComp={
            <div className="ColumnNormal" style={{ gap: 14, padding: 4 }}>
              <div>
                <img
                  style={{ height: 24, width: "auto", objectFit: "cover" }}
                  src={MarkitLogoBlue}
                  alt={"markitLogoBlue"}
                />
              </div>
              <div className="ColumnNormal" style={{ gap: 7 }}>
                <span style={{ fontSize: 20, fontWeight: 500 }}>
                  {hasSub ? "Upgrade your Markit" : "Markit"}
                  <span style={{ color: Colors.BLUE5 }}>+</span>
                  {hasSub ? " Plan" : ""}
                </span>
                <span className="bodySubtext">
                  {isBasicSubscription(accountData)
                    ? "Cheaper cost per credit, send images, paid memberships, import csv, and more."
                    : isCreatorSubscription(accountData)
                    ? "Cheaper cost per credit, more audience lists, API access, and dedicated support."
                    : "Share your unique Markit number. When people text your number they can join your audience."}
                </span>
              </div>
            </div>
          }
          subHeaderComp=""
          valueComp={
            <div className="ColumnNormal" style={{ gap: 24 }}>
              <div className="ColumnNormal" style={{ gap: 14 }}>
                {renderItemRow(
                  hasSub ? "Cheaper Texting" : "Unlimited Texts",
                  `Get more free texts and pay ${
                    hasSub ? "less for overflow" : "as you go"
                  }.`,
                  "ion:chatbubble-ellipses"
                )}
                {hasSub ? renderSubscriptionPlanItems : renderFreePlanItems}
              </div>
              <div
                className="ColumnNormal"
                style={{ gap: 10, alignItems: "center" }}
              >
                <RectangleButton
                  buttonLabel={
                    <span style={{ color: Colors.WHITE, fontSize: 14 }}>
                      {hasSub ? "Upgrade" : "Get Markit"}
                      {!hasSub ? (
                        <span style={{ color: Colors.BLUE5 }}>+</span>
                      ) : null}
                    </span>
                  }
                  onPress={onSubscriptionPress}
                  altColor={Colors.BLACK}
                  altPaddingVert={16}
                  altPaddingHorz={12}
                />
                <TextHoverButton
                  text="Contact Support"
                  onPress={() => setHelpModal(true)}
                  textStyles={{ fontSize: 14 }}
                />
              </div>
            </div>
          }
          theme={theme}
          closeModal={closeOnPress}
          closeOnOutsidePress
          noExit
        />
      ) : helpModal ? (
        <HelpModal showModal={helpModal} setShowModal={setHelpModal} />
      ) : null}
    </>
  );
};
