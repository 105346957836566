import {
  AccountData,
  AudienceList,
  NotificationType,
  SavedFormQuestion,
} from "@markit/common.types";
import { useCallback, useMemo } from "react";
import ProfileFollowerItem from "../ProfileItem/ProfileFollowerItem";
import { DropdownMenuItem } from "../../DropdownMenu";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountState,
  removeSingleAudienceListMember,
} from "../../../redux/slices/accountSlice";

export const AUDIENCE_LIST_FLEX_SECTIONS = [2, 1, 1, 6];

type AudienceListMemberItemProps = {
  list: AudienceList;
  user: AccountData;
  essentialQuestion: SavedFormQuestion | undefined;
  setProfileSelected: (profileSelected: AccountData | undefined) => void;
  removeMemberData: () => void;
};

const AudienceListMemberItem = (props: AudienceListMemberItemProps) => {
  const {
    list,
    user,
    essentialQuestion,
    setProfileSelected,
    removeMemberData,
  } = props;
  const followersData = useSelector(getAccountState).account.followersData;
  const uid = useSelector(getAccountState).account.accountData.uid;
  const dispatch = useDispatch();

  const removeListMember = useCallback(async () => {
    await dispatch(removeSingleAudienceListMember(uid, list, user.uid));
    removeMemberData();
    showNotificationBanner(
      dispatch,
      "Removed Member",
      NotificationType.NEGATIVE
    );
  }, [dispatch, list, removeMemberData, uid, user.uid]);

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const dropdownItems: DropdownMenuItem[] = [
      {
        title: "View Follower",
        icon: "ion:eye",
        key: "view",
        onPress: () => setProfileSelected(user),
      },
      {
        title: "Remove Member",
        icon: "ion:archive",
        key: "remove_member",
        onPress: removeListMember,
        isDestructive: true,
      },
    ];
    return dropdownItems;
  }, [removeListMember, setProfileSelected, user]);

  return (
    <ProfileFollowerItem
      item={user}
      setProfilePreviewSelected={setProfileSelected}
      essentialQuestion={essentialQuestion}
      fetchedFollowerData={followersData}
      alternateDropdownItems={dropdownItems}
    />
  );
};

export default AudienceListMemberItem;
