import { MembershipPlan, SelectRecipientGroupType } from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../../redux/slices/accountSlice";
import { useCallback, useMemo, useState } from "react";
import filter from "lodash.filter";
import FlatList from "flatlist-react/lib";
import SearchBoxContainer from "../../../../Containers/SearchBoxContainer";
import { HorizontalDivider } from "../../../../Dividers/HorizontalDivider";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../../utils/colors";
import { EmptyStateFlatlist } from "../../../../EmptyStates/EmptyStateFlatlist";
import EmptyStateButton from "../../../../Buttons/EmptyStateButton";
import { MassTextsActionItem } from "../../Items/MassTextsActionItem";
import { convertToMoney } from "@markit/common.utils";
import { getShortMembershipFrequencyLabel } from "../../../../../utils/subscriptionUtils";

type SelectRecipientsMembershipLibraryProps = {
  excludingMode: boolean;
  updateSelectType: (selectType: Partial<SelectRecipientGroupType>) => void;
};

const SelectRecipientsMembershipLibrary = (
  props: SelectRecipientsMembershipLibraryProps
) => {
  const { excludingMode, updateSelectType } = props;
  const { accountData } = useSelector(getAccountState).account;
  const [searchTerm, setSearchTerm] = useState("");

  const membershipPlans: MembershipPlan[] = useMemo(
    () => accountData.membership.membershipPlans,
    [accountData.membership.membershipPlans]
  );

  const containsMembership = useCallback(
    (membership: MembershipPlan, query: string) => {
      return membership.name.toLowerCase().includes(query.toLowerCase());
    },
    []
  );

  const handleSearch = (text: string) => {
    setSearchTerm(text);
  };

  const itemsToShow: MembershipPlan[] = useMemo(() => {
    let searchResults: any[] = [...membershipPlans].reverse();
    if (searchTerm !== "") {
      searchResults = filter(membershipPlans, (membership: MembershipPlan) => {
        return containsMembership(membership, searchTerm);
      });
    }
    return searchResults;
  }, [membershipPlans, searchTerm, containsMembership]);

  return (
    <>
      <SearchBoxContainer
        value={searchTerm}
        placeholder={"Search Memberships..."}
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
        containerStyles={{ marginTop: 0, marginBottom: 14 }}
      />
      <div className="AllowScroll" style={{ paddingBottom: 200 }}>
        <FlatList
          list={itemsToShow}
          renderItem={(item: MembershipPlan) => (
            <>
              <MassTextsActionItem
                title={item.name}
                altIconBackgroundColor={Colors.GRAY6}
                icon={
                  <Icon
                    icon={
                      item.id === "free" ? "ion:checkmark-circle" : "ion:ribbon"
                    }
                    height={30}
                    color={excludingMode ? Colors.RED3 : Colors.BLUE5}
                  />
                }
                onPress={() => updateSelectType({ selectedItem: item })}
                subtext={`${convertToMoney(
                  item.price / 100
                )}/${getShortMembershipFrequencyLabel(item.billingFrequency)}`}
                alternateRightIcon={
                  <Icon
                    icon="ion:chevron-right"
                    height={16}
                    color={Colors.GRAY1}
                  />
                }
              />
              <HorizontalDivider altMargin={10} />
            </>
          )}
          renderWhenEmpty={() => (
            <EmptyStateFlatlist
              containerStyles={{ paddingTop: 200 }}
              isLoading={false}
              searchTerm={searchTerm}
              nonSearchEmptyView={
                <EmptyStateButton
                  title="No Memberships"
                  description="Your memberships will show up here"
                  icon={
                    <Icon icon="ion:ribbon" height={64} color={Colors.BLUE5} />
                  }
                  iconBox={84}
                  containerStyles={{ paddingTop: 100 }}
                />
              }
            />
          )}
        />
      </div>
    </>
  );
};

export default SelectRecipientsMembershipLibrary;
