import { Colors } from "../../../utils/colors";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import FormBuilderWrapper from "../../Form/FormBuilderWrapper";
import PreviewBoxContainer from "../../Containers/PreviewBoxContainer";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import { useTheme } from "../../../hooks/useTheme";
import RectangleButton from "../../Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { onCampaignNavigatePath } from "../../../utils/navigationUtils";
import { TabPanel } from "../../FullEventSubComponents/TabPanel";
import { getCampaignState } from "../../../redux/slices/campaignSlice";
import { SUBSCRIBED_CAMPAIGN_NAME } from "@markit/common.utils";

export type ProfileWelcomeFlowProps = { tabValue: number };

const ProfileWelcomeFlow = (props: ProfileWelcomeFlowProps) => {
  const { tabValue } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { campaigns, campaignDetails } = useSelector(getCampaignState);
  const { theme } = useTheme();
  const navigate = useNavigate();

  const styles = {
    headerText: { fontSize: 14, fontWeight: "500" },
    subtext: { fontSize: 12, color: Colors.GRAY1 },
    sectionContainer: {
      padding: 14,
      borderRadius: 12,
      backgroundColor: Colors.WHITE,
    },
  };

  const followCampaign = useMemo(
    () =>
      campaigns.find((campaign) => campaign.title === SUBSCRIBED_CAMPAIGN_NAME),
    [campaigns]
  );

  const followMassText = useMemo(() => {
    const followCampaignDetails = campaignDetails.find(
      (detail) => detail.campaignId === followCampaign?.id
    );
    if (followCampaignDetails) {
      return followCampaignDetails.massTextDetails[0].massText;
    }
    return undefined;
  }, [campaignDetails, followCampaign]);

  const editFollowText = useCallback(() => {
    if (followCampaign) {
      navigate(onCampaignNavigatePath(followCampaign.id), {
        state: { campaignId: followCampaign.id, fromProfileTabIndex: 1 },
      });
    } else {
      alert("Unable to edit the follow text at this time.");
    }
  }, [followCampaign, navigate]);

  return (
    <TabPanel value={tabValue} index={1}>
      <div
        className="ColumnNormal"
        style={{ gap: 20, width: 544, paddingBottom: 100, margin: "auto" }}
      >
        <PreviewBoxContainer
          message={
            followMassText?.message ??
            "Unable to display the follow text at this time."
          }
          containerStyles={{
            borderRadius: 12,
            backgroundColor: Colors.WHITE,
            border: `1.5px solid ${theme.DividerColor.borderColor}`,
          }}
          headerComp={
            <div className="AlignedRow" style={{ gap: 10 }}>
              <span className="sectionTitle">Follow Text</span>
              <Icon icon="mdi:hand-wave" height={20} color={Colors.BLACK} />
            </div>
          }
          subHeaderComp={
            <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
              Sent when someone follows you
            </span>
          }
          headerRightComp={
            <RectangleButton
              buttonLabel="Edit"
              onPress={editFollowText}
              altBorderRadius={13}
              altPaddingHorz={12}
              altPaddingVert={12}
              altColor={Colors.BLACK}
              altTextColor={Colors.WHITE}
              iconRight={
                <Icon
                  icon={"ion:chevron-forward"}
                  height={16}
                  style={{ color: Colors.WHITE }}
                />
              }
            />
          }
        />
        <StandardBorderedContainer containerStyles={styles.sectionContainer}>
          <FormBuilderWrapper
            isProfileQuestions
            hostId={accountData.uid}
            altItemsColor={Colors.WHITE1}
          />
        </StandardBorderedContainer>
      </div>
    </TabPanel>
  );
};

export default ProfileWelcomeFlow;
