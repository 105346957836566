import "../../../../css/GlobalStyles.css";
import React, { useCallback, useMemo, useState } from "react";
import {
  AccountData,
  FollowerStatus,
  SpreadsheetStatus,
} from "@markit/common.types";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import {
  hasSubscription,
  hasSubscriptionUnlockedAdvancedData,
} from "@markit/common.utils";
import EmptyStateButton from "../../../../components/Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import RectangleButtonCreatorPanel from "../../../../components/Buttons/RectangleButtonCreatorPanel";
import { useNavigate } from "../../../../hooks/useNavigate";
import MarkitPlusActionButton from "../../../../components/Subscription/MarkitPlusActionButton";
import CreatorModePanelWrapper from "../../../../components/CreatorDashboard/CreatorModePanelWrapper";
import ImportHistory from "../../../../components/CreatorDashboard/CreatorProfile/ImportContacts/ImportHistory";
import SearchBoxContainer from "../../../../components/Containers/SearchBoxContainer";
import { useLiveUpdatingImportAnalytics } from "../../../../hooks/useLiveHooks/useLiveUpdatingImportAnalytics";
import { useLoadSpreadsheetUserList } from "../../../../hooks/useLoadSpreadsheetUserList";
import filter from "lodash.filter";
import ProfileSpreadsheetItem, {
  SPREADSHEET_USER_FLEX_SECTIONS,
} from "../../../../components/DisplayItem/ProfileSpreadsheetItem";
import { EmptyStateFlatlist } from "../../../../components/EmptyStates/EmptyStateFlatlist";
import FullProfilePreviewModal from "../../../../components/FollowerProfile/FullProfilePreviewModal";
import useAsyncOnMount from "../../../../hooks/useAsyncEffectOnMount";
import StandardListContainer from "../../../../components/Containers/StandardListContainer";
import FlatlistLoadMore from "../../../../components/FlatlistLoadMore";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "../../../../components/DropdownMenu";
import AddSingleContactModal from "../../../../components/CreatorDashboard/CreatorProfile/ImportContacts/AddSingleContactModal";
import { notificationActions } from "../../../../redux/slices/notificationSlice";

const ImportPanel = () => {
  const { accountData, appInitialized } = useSelector(getAccountState).account;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingFollowers, setLoadingFollowers] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [profilePreviewSelected, setProfilePreviewSelected] =
    useState<AccountData>();
  const [addContactVisible, setAddContactVisible] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const styles = {
    optInHeader: {
      paddingBlock: 5,
      fontSize: 14,
      fontWeight: "500",
    },
  };

  const { liveTotalSubscribedImportContacts, loaded } =
    useLiveUpdatingImportAnalytics();

  const {
    fetchedUserData,
    fetchedFollowerData,
    loadMoreUsers,
    loadUsers,
    loadSearchResultsThrottled,
    isLoading: isLoadingFollowers,
    isFinished: isFinishedLoadingFollowers,
  } = useLoadSpreadsheetUserList({
    userId: accountData.uid,
    followerStatus: FollowerStatus.SUBSCRIBED,
    windowSize: 30,
    status: SpreadsheetStatus.ACCEPTED,
  });

  useAsyncOnMount(async () => {
    await loadUsers();
    setLoadingFollowers(false);
  });

  const navigateNewUpload = useCallback(() => {
    if (!hasSubscriptionUnlockedAdvancedData(accountData)) {
      dispatch(notificationActions.setMarkitPlusModal(true));
      return;
    }
    navigate("/import");
  }, [accountData, navigate, dispatch]);

  const contains = useCallback((user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    if (text !== "") {
      loadSearchResultsThrottled(text.toLowerCase());
    }
    setSearchTerm(text.toLowerCase());
  };

  const itemsToShow: AccountData[] = useMemo(() => {
    if (!appInitialized) {
      return [];
    }
    let userList: AccountData[] = [];
    userList = fetchedUserData;
    if (searchTerm !== "") {
      userList = filter(userList, (user: AccountData) => {
        return contains(user, searchTerm);
      });
    }
    return userList;
  }, [appInitialized, fetchedUserData, searchTerm, contains]);

  const renderItem = useCallback(
    (item: AccountData) => (
      <ProfileSpreadsheetItem
        item={item}
        setProfilePreviewSelected={setProfilePreviewSelected}
        fetchedFollowerData={fetchedFollowerData}
      />
    ),
    [fetchedFollowerData, setProfilePreviewSelected]
  );

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoadingFollowers}
        containerStyles={{ paddingTop: 150 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title="Import Contacts"
            description="Import a CSV to build your audience"
            icon={
              <Icon icon="ion:cloud-upload" height={64} color={Colors.GRAY1} />
            }
            iconBox={84}
            btnText="New Import"
            onPress={navigateNewUpload}
            containerStyles={{ paddingTop: 150 }}
          />
        }
      />
    );
  }, [isLoadingFollowers, navigateNewUpload, searchTerm]);

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "Import CSV",
        subtext: !hasSubscriptionUnlockedAdvancedData(accountData) ? (
          <span className="smallBodySubtext">
            {hasSubscription(accountData)
              ? "Upgrade Markit"
              : "Available with Markit"}
            <span style={{ color: Colors.BLUE5 }}>+</span>
            {hasSubscription(accountData) ? " Plan" : ""}
          </span>
        ) : undefined,
        icon: "ion:cloud-upload",
        key: "import_csv",
        onPress: navigateNewUpload,
      },
      {
        title: "Add Single Contact",
        icon: "ion:person-add",
        key: "add_single_contact",
        onPress: () => setAddContactVisible(true),
      },
    ],
    [accountData, navigateNewUpload]
  );

  return (
    <CreatorModePanelWrapper
      title="Import Audience"
      headerRight={
        <div className="AlignedRow" style={{ gap: 14 }}>
          <MarkitPlusActionButton />
          <DropdownMenu
            dropdownItems={dropdownItems}
            placement="bottomRight"
            altWidth={200}
          >
            <RectangleButtonCreatorPanel
              title="New Import"
              iconName="ion:add-circle"
              onPress={() => {}}
            />
          </DropdownMenu>
        </div>
      }
      headerTabs={{
        tabNames: ["Overview", "Import History"],
        tabValue: tabValue,
        setTabValue: setTabValue,
      }}
    >
      <div
        className="ColumnNormal"
        style={{ padding: 30, backgroundColor: Colors.WHITE, height: "100%" }}
      >
        <StandardListContainer
          searchComp={
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <SearchBoxContainer
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                containerStyles={{ marginTop: 0, width: 394 }}
              />
              <span style={styles.optInHeader}>
                Opted-In ({loaded ? liveTotalSubscribedImportContacts : "--"})
              </span>
            </div>
          }
          gridTable={{
            flexSections: SPREADSHEET_USER_FLEX_SECTIONS,
            sectionTitles: ["Name", "Upload Name", "Phone Number", "Status"],
          }}
          listComp={
            <FlatlistLoadMore
              fullList={fetchedUserData}
              currentList={itemsToShow}
              renderItem={(item: AccountData) => renderItem(item)}
              renderWhenEmpty={renderEmptyView}
              isLoadingMore={isLoadingFollowers}
              showLoadMore={
                searchTerm.trim() === "" && !isFinishedLoadingFollowers
              }
              loadMoreOnPress={loadMoreUsers}
            />
          }
          isLoading={loadingFollowers || !loaded}
        />
        {tabValue === 1 ? (
          <ImportHistory
            isVisible={tabValue === 1}
            setIsVisible={() => setTabValue(0)}
          />
        ) : null}
        <FullProfilePreviewModal
          profileSelected={profilePreviewSelected}
          setProfileSelected={setProfilePreviewSelected}
        />
        {addContactVisible ? (
          <AddSingleContactModal setIsVisible={setAddContactVisible} />
        ) : null}
      </div>
    </CreatorModePanelWrapper>
  );
};

export default ImportPanel;
