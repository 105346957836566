import "../../../../css/GlobalStyles.css";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import EmptyStateButton from "../../../../components/Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import CreatorModePanelWrapper from "../../../../components/CreatorDashboard/CreatorModePanelWrapper";
import SearchBoxContainer from "../../../../components/Containers/SearchBoxContainer";
import filter from "lodash.filter";
import { EmptyStateFlatlist } from "../../../../components/EmptyStates/EmptyStateFlatlist";
import ProgressActionModal, {
  ProgressActionStatus,
} from "../../../../components/Containers/ProgressActionModal";
import { useLoadUserFollowList } from "../../../../hooks/useLoadUserFollowList";
import { AccountData, FollowerStatus } from "@markit/common.types";
import { exportAllFollowerData } from "../../../../utils/eventUtils/formUtils";
import ListTabs from "../../../../components/DropdownsAndTabs/ListTabs";
import ProfileFollowerItem, {
  FOLLOWER_SHORT_USER_FLEX_SECTIONS,
  FOLLOWER_USER_FLEX_SECTIONS,
} from "../../../../components/DisplayItem/ProfileItem/ProfileFollowerItem";
import { CircularProgress } from "@mui/material";
import FullProfilePreviewModal from "../../../../components/FollowerProfile/FullProfilePreviewModal";
import FatHeaderActionButton from "../../../../components/Buttons/FatHeaderActionButton";
import useAsyncOnMount from "../../../../hooks/useAsyncEffectOnMount";
import StandardListContainer from "../../../../components/Containers/StandardListContainer";
import FlatlistLoadMore from "../../../../components/FlatlistLoadMore";
import AddSingleContactModal from "../../../../components/CreatorDashboard/CreatorProfile/ImportContacts/AddSingleContactModal";
import RectangleButtonCreatorPanel from "../../../../components/Buttons/RectangleButtonCreatorPanel";

const ContactsPanel = () => {
  const { account } = useSelector(getAccountState);
  const { accountData, appInitialized, savedQuestions, followersData } =
    account;
  const [loadingFollowers, setLoadingFollowers] = useState(true);
  const [listValue, setListValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [profilePreviewSelected, setProfilePreviewSelected] =
    useState<AccountData>();
  const [addContactVisible, setAddContactVisible] = useState(false);
  const [confirmPopupExport, setConfirmPopupExport] =
    useState<ProgressActionStatus>({
      modalVisible: false,
      state: "completed",
    });

  const essentialQuestion = useMemo(
    () => savedQuestions.find((question) => question.isEssential),
    [savedQuestions]
  );

  const {
    fetchedUserData,
    fetchedFollowerData,
    loadMoreUsers,
    loadUsers,
    loadSearchResultsThrottled,
    isLoading: isLoadingFollowers,
    isFinished: isFinishedLoadingFollowers,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: FollowerStatus.SUBSCRIBED,
    windowSize: 30,
  });

  const {
    fetchedUserData: fetchedUnsubscribedUserData,
    fetchedFollowerData: fetchedUnsubscribedFollowerData,
    loadMoreUsers: loadMoreUnsubscribedUsers,
    loadUsers: loadUnsubscribedUsers,
    loadSearchResultsThrottled: loadUnsubscribedSearchResultsThrottled,
    isLoading: isLoadingUnsubscribedFollowers,
    isFinished: isFinishedLoadingUnsubscribedFollowers,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: FollowerStatus.UNSUBSCRIBED,
    windowSize: 30,
  });

  const {
    fetchedUserData: fetchedRemovedUserData,
    fetchedFollowerData: fetchedRemovedFollowerData,
    loadMoreUsers: loadMoreRemovedUsers,
    loadUsers: loadRemovedUsers,
    loadSearchResultsThrottled: loadRemovedSearchResultsThrottled,
    isLoading: isLoadingRemovedFollowers,
    isFinished: isFinishedLoadingRemovedFollowers,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: FollowerStatus.REMOVED,
    windowSize: 30,
  });

  const {
    fetchedUserData: fetchedInvalidUserData,
    fetchedFollowerData: fetchedInvalidFollowerData,
    loadMoreUsers: loadMoreInvalidUsers,
    loadUsers: loadInvalidUsers,
    loadSearchResultsThrottled: loadInvalidSearchResultsThrottled,
    isLoading: isLoadingInvalidFollowers,
    isFinished: isFinishedLoadingInvalidFollowers,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: FollowerStatus.INVALID_NUMBER,
    windowSize: 30,
  });

  useAsyncOnMount(async () => {
    await Promise.all([
      loadUsers(),
      loadUnsubscribedUsers(),
      loadRemovedUsers(),
      loadInvalidUsers(),
    ]);

    setLoadingFollowers(false);
  });

  const subscribedFollowers = useMemo(
    () =>
      followersData.filter(
        (follower) => follower.status === FollowerStatus.SUBSCRIBED
      ),
    [followersData]
  );

  const unsubscribedFollowers = useMemo(
    () =>
      followersData.filter(
        (follower) => follower.status === FollowerStatus.UNSUBSCRIBED
      ),
    [followersData]
  );

  const removedFollowers = useMemo(
    () =>
      followersData.filter(
        (follower) => follower.status === FollowerStatus.REMOVED
      ),
    [followersData]
  );

  const invalidFollowers = useMemo(
    () =>
      followersData.filter(
        (follower) => follower.status === FollowerStatus.INVALID_NUMBER
      ),
    [followersData]
  );

  const initialLoading = useMemo(
    () => loadingFollowers || !appInitialized,
    [appInitialized, loadingFollowers]
  );

  const onExportFinish = useCallback((success: boolean) => {
    if (success) {
      setConfirmPopupExport({ modalVisible: true, state: "completed" });
    } else {
      setConfirmPopupExport({ modalVisible: true, state: "failed" });
    }
  }, []);

  const exportSpreadsheetOnPress = useCallback(async () => {
    if (confirmPopupExport.state === "in progress") {
      return;
    }

    setConfirmPopupExport({ modalVisible: true, state: "in progress" });
    await exportAllFollowerData(accountData.uid, onExportFinish);
  }, [accountData.uid, confirmPopupExport.state, onExportFinish]);

  const contains = useCallback((user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    const lowerCaseText = text.toLowerCase();
    if (text !== "") {
      if (listValue === 0) {
        loadSearchResultsThrottled(lowerCaseText);
      } else if (listValue === 1) {
        loadUnsubscribedSearchResultsThrottled(lowerCaseText);
      } else if (listValue === 2) {
        loadRemovedSearchResultsThrottled(lowerCaseText);
      } else if (listValue === 3) {
        loadInvalidSearchResultsThrottled(lowerCaseText);
      }
    }
    setSearchTerm(lowerCaseText);
  };

  const allItems = useMemo(
    () =>
      listValue === 0
        ? fetchedUserData
        : listValue === 1
        ? fetchedUnsubscribedUserData
        : listValue === 2
        ? fetchedRemovedUserData
        : fetchedInvalidUserData,
    [
      fetchedInvalidUserData,
      fetchedRemovedUserData,
      fetchedUnsubscribedUserData,
      fetchedUserData,
      listValue,
    ]
  );

  const isFinished = useMemo(
    () =>
      listValue === 0
        ? isFinishedLoadingFollowers
        : listValue === 1
        ? isFinishedLoadingUnsubscribedFollowers
        : listValue === 2
        ? isFinishedLoadingRemovedFollowers
        : isFinishedLoadingInvalidFollowers,
    [
      isFinishedLoadingFollowers,
      isFinishedLoadingInvalidFollowers,
      isFinishedLoadingRemovedFollowers,
      isFinishedLoadingUnsubscribedFollowers,
      listValue,
    ]
  );

  const currentItemsToShow = useMemo(() => {
    if (initialLoading) {
      return [];
    }
    let userList: AccountData[] = [];
    userList = allItems;
    if (searchTerm !== "") {
      userList = filter(userList, (user: AccountData) => {
        return contains(user, searchTerm);
      });
    }
    return userList;
  }, [initialLoading, allItems, searchTerm, contains]);

  const currentFollowerDataToShow = useMemo(
    () =>
      listValue === 0
        ? fetchedFollowerData
        : listValue === 1
        ? fetchedUnsubscribedFollowerData
        : listValue === 2
        ? fetchedRemovedFollowerData
        : fetchedInvalidFollowerData,
    [
      fetchedFollowerData,
      listValue,
      fetchedUnsubscribedFollowerData,
      fetchedRemovedFollowerData,
      fetchedInvalidFollowerData,
    ]
  );

  const isLoading = useMemo(
    () =>
      listValue === 0
        ? isLoadingFollowers && !isFinishedLoadingFollowers
        : listValue === 1
        ? isLoadingUnsubscribedFollowers &&
          !isFinishedLoadingUnsubscribedFollowers
        : listValue === 2
        ? isLoadingRemovedFollowers && !isFinishedLoadingRemovedFollowers
        : isLoadingInvalidFollowers && !isFinishedLoadingInvalidFollowers,
    [
      listValue,
      isLoadingFollowers,
      isFinishedLoadingFollowers,
      isLoadingUnsubscribedFollowers,
      isFinishedLoadingUnsubscribedFollowers,
      isLoadingRemovedFollowers,
      isFinishedLoadingRemovedFollowers,
      isLoadingInvalidFollowers,
      isFinishedLoadingInvalidFollowers,
    ]
  );

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoading}
        containerStyles={{ paddingTop: 150 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title={
              subscribedFollowers.length > 0
                ? "No People to Show"
                : "Grow Your Audience"
            }
            description={
              subscribedFollowers.length > 0
                ? ""
                : "You currently have no followers. Build your audience to grow a textable following."
            }
            icon={<Icon icon="ion:people" height={64} color={Colors.GRAY1} />}
            iconBox={84}
            containerStyles={{ paddingTop: 150 }}
          />
        }
      />
    );
  }, [isLoading, subscribedFollowers.length, searchTerm]);

  const renderItem = useCallback(
    (item: AccountData) => {
      return (
        <div key={item.uid}>
          <ProfileFollowerItem
            item={item}
            setProfilePreviewSelected={setProfilePreviewSelected}
            essentialQuestion={essentialQuestion}
            fetchedFollowerData={currentFollowerDataToShow}
          />
        </div>
      );
    },
    [currentFollowerDataToShow, essentialQuestion]
  );

  return (
    <CreatorModePanelWrapper
      title={`Contacts (${followersData.length})`}
      headerRight={
        <div className="AlignedRow" style={{ gap: 14 }}>
          <FatHeaderActionButton
            title="Export Data"
            onPress={exportSpreadsheetOnPress}
          />
          <RectangleButtonCreatorPanel
            title="Add Contact"
            iconName="ion:add-circle"
            onPress={() => setAddContactVisible(true)}
          />
        </div>
      }
    >
      {initialLoading ? (
        <div
          className="Centering"
          style={{ height: "100%", backgroundColor: Colors.WHITE }}
        >
          <CircularProgress style={{ color: Colors.GRAY1 }} size={24} />
        </div>
      ) : (
        <div
          className="ColumnNormal"
          style={{ backgroundColor: Colors.WHITE, padding: 30, height: "100%" }}
        >
          <StandardListContainer
            searchComp={
              <div className="ColumnNormal" style={{ gap: 14 }}>
                <SearchBoxContainer
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                  containerStyles={{ marginTop: 0, width: 394 }}
                />
                <ListTabs
                  tabLabels={[
                    "Subscribed",
                    "Unsubscribed",
                    "Removed",
                    "Invalid Number",
                    //   "Blocked",
                  ]}
                  tabNumbers={[
                    subscribedFollowers.length,
                    unsubscribedFollowers.length,
                    removedFollowers.length,
                    invalidFollowers.length,
                  ]}
                  selectedValue={listValue}
                  onChange={setListValue}
                  altColor={Colors.BLACK}
                />
              </div>
            }
            gridTable={{
              flexSections: essentialQuestion
                ? FOLLOWER_USER_FLEX_SECTIONS
                : FOLLOWER_SHORT_USER_FLEX_SECTIONS,
              sectionTitles: essentialQuestion
                ? ["Name", "Status", "Follow Source", "Essential"]
                : ["Name", "Status", "Follow Source"],
            }}
            listComp={
              <FlatlistLoadMore
                fullList={allItems}
                currentList={currentItemsToShow}
                renderItem={(item: AccountData) => renderItem(item)}
                renderWhenEmpty={renderEmptyView}
                isLoadingMore={isLoading}
                showLoadMore={searchTerm.trim() === "" && !isFinished}
                loadMoreOnPress={() => {
                  if (listValue === 0) {
                    loadMoreUsers();
                  } else if (listValue === 1) {
                    loadMoreUnsubscribedUsers();
                  } else if (listValue === 2) {
                    loadMoreRemovedUsers();
                  } else if (listValue === 3) {
                    loadMoreInvalidUsers();
                  }
                }}
              />
            }
            isLoading={loadingFollowers}
          />
        </div>
      )}
      {profilePreviewSelected ? (
        <FullProfilePreviewModal
          profileSelected={profilePreviewSelected}
          setProfileSelected={setProfilePreviewSelected}
        />
      ) : null}
      {addContactVisible ? (
        <AddSingleContactModal setIsVisible={setAddContactVisible} />
      ) : null}
      <ProgressActionModal
        actionTitle="Export"
        actionStatus={confirmPopupExport}
        setActionStatus={setConfirmPopupExport}
        failureMessage={
          "An error occurred while exporting. Try again or contact our hotline for help."
        }
        inProgressMessage={"Your export may take a few minutes."}
        successMessage={`Successfully exported ${
          followersData.length ?? 0
        } contact${followersData.length === 1 ? "" : "s"}.`}
      />
    </CreatorModePanelWrapper>
  );
};

export default ContactsPanel;
