import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AccountData,
  AudienceList,
  ImportData,
  MembershipPlan,
  NotificationType,
  SavedFormQuestion,
  SelectRecipientGroupType,
  SelectRecipientType,
} from "@markit/common.types";
import SelectRecipientsEvent from "./SelectEvents/SelectRecipientsEvent";
import SelectRecipientsAudienceList from "./SelectAudienceLists/SelectRecipientsAudienceList";
import SelectRecipientsCollectedData from "./SelectCollectedData/SelectRecipientsCollectedData";
import SelectRecipientsOverview from "./SelectRecipientsOverview";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import FullProfilePreviewModal from "../../../FollowerProfile/FullProfilePreviewModal";
import SelectRecipientHeader from "./SelectRecipientsHeader";
import SelectedRecipientsList from "./SelectedRecipientsList";
import SelectRecipientsImportLibrary from "./SelectImports/SelectRecipientsImportLibrary";
import SelectRecipientsImport from "./SelectImports/SelectRecipientsImport";
import SelectRecipientsAudienceListLibrary from "./SelectAudienceLists/SelectRecipientsAudienceListLibrary";
import SelectRecipientsEventLibrary from "./SelectEvents/SelectRecipientsEventLibrary";
import SelectRecipientsFollowers from "./SelectRecipientsFollowers";
import isEqual from "lodash.isequal";
import { MassTextsWrapperType } from "../MassTextWrapperManager";
import LargePopupModalContainer from "../../../Containers/LargePopupModalContainer";
import { useSelector, useDispatch } from "react-redux";
import {
  addMultipleAudienceListMembers,
  getAccountState,
} from "../../../../redux/slices/accountSlice";
import { useLiveUpdatingListAnalytics } from "../../../../hooks/useLiveHooks/useLiveUpdatingListAnalytics";
import { getUserAudienceListMembersRef } from "../../../../utils/FirebaseUtils";
import { onSnapshot } from "../../../../firebase";
import { CircularProgress } from "@mui/material";
import { Colors } from "../../../../utils/colors";
import SelectRecipientsCollectedDataLibrary from "./SelectCollectedData/SelectRecipientsCollectedDataLibrary";
import { showNotificationBanner } from "../../../../utils/notificationUtils";
import SelectRecipientsMembershipLibrary from "./SelectMemberships/SelectRecipientsMembershipLibrary";

export type ListSelectRecipientsMainSharedProps = {
  wrapperType: MassTextsWrapperType;
  excludingMode: boolean;
  setExcludingMode: (excludingMode: boolean) => void;
  showDoneButton: boolean;
  setShowDoneButton: (showDoneButton: boolean) => void;
  displayAllFollowers: boolean; // if true, don't filter for just subscribed followers to display
};

type ListSelectRecipientsPanelProps = {
  setIsVisible: (isVisible: boolean) => void;
  audienceList: AudienceList;
};

// Used for audience list select recipients flow to add/remove members
const ListSelectRecipientsPanel = (props: ListSelectRecipientsPanelProps) => {
  const { setIsVisible, audienceList } = props;
  const dispatch = useDispatch();
  const { accountData } = useSelector(getAccountState).account;
  const [allSelectedPeople, setAllSelectedPeople] = useState<string[]>([]);
  const [allExcludedPeople, setAllExcludedPeople] = useState<string[]>([]);
  const [unsavedRecipients, setUnsavedRecipients] = useState<string[]>([]);
  const [profileSelected, setProfileSelected] = useState<AccountData>();

  const [selectedType, setSelectedType] = useState<SelectRecipientGroupType>({
    type: SelectRecipientType.NONE,
    selectedItem: undefined,
    eventTabValue: 0,
  });
  const [excludingMode, setExcludingMode] = useState<boolean>(false);
  const [showDoneButton, setShowDoneButton] = useState(false);

  const { liveListContactUids, loaded } = useLiveUpdatingListAnalytics({
    listId: audienceList.id,
  });

  useEffect(() => {
    if (loaded) {
      setAllSelectedPeople(liveListContactUids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useEffect(() => {
    // Update the unsavedRecipients whenever you go to a selection screen again
    if (selectedType.type !== SelectRecipientType.NONE) {
      setUnsavedRecipients(
        excludingMode ? allExcludedPeople : allSelectedPeople
      );
    }
  }, [allExcludedPeople, allSelectedPeople, excludingMode, selectedType.type]);

  const updateSelectType = useCallback(
    (newSelectedType: Partial<SelectRecipientGroupType>) => {
      setSelectedType((prevSelectedType) => {
        return { ...prevSelectedType, ...newSelectedType };
      });
    },
    []
  );

  const sharedPropsPack = {
    allRecipients: excludingMode ? allExcludedPeople : allSelectedPeople,
    unsavedRecipients: unsavedRecipients,
    setUnsavedRecipients: setUnsavedRecipients,
    unsavedCategories: [],
    setUnsavedCategories: () => {},
    excludingMode: excludingMode,
    showCategories: false,
    setProfileSelected: setProfileSelected,
    displayAllFollowers: true,
  };

  const mainSharedPropsPack: ListSelectRecipientsMainSharedProps = {
    wrapperType: MassTextsWrapperType.MODAL,
    excludingMode: excludingMode,
    setExcludingMode: setExcludingMode,
    showDoneButton: showDoneButton,
    setShowDoneButton: setShowDoneButton,
    displayAllFollowers: true,
  };

  const netPeople = useMemo(
    () => allSelectedPeople.filter((item) => !allExcludedPeople.includes(item)),
    [allExcludedPeople, allSelectedPeople]
  );

  const backOnPress = useCallback(() => {
    if (selectedType.type === SelectRecipientType.NONE) {
      setIsVisible(false);
    } else {
      setSelectedType({
        type: SelectRecipientType.NONE,
        selectedItem: undefined,
        eventTabValue: 0,
      });
    }
  }, [selectedType.type, setIsVisible]);

  const resetOnPress = useCallback(() => {
    setAllExcludedPeople([]);
    setAllSelectedPeople([]);
    updateSelectType({ type: SelectRecipientType.NONE });
  }, [setAllExcludedPeople, setAllSelectedPeople, updateSelectType]);

  const saveListPeople = useCallback(async () => {
    await dispatch(
      addMultipleAudienceListMembers(accountData.uid, audienceList, netPeople)
    );
    // Listen for the documents in firebase before closing the modal
    const audienceListMembersRef = getUserAudienceListMembersRef(
      accountData.uid,
      audienceList.id
    );
    const unsubscribe = onSnapshot(audienceListMembersRef, (snapshot) => {
      if (snapshot.size === netPeople.length) {
        setIsVisible(false);
        showNotificationBanner(
          dispatch,
          "List Members Updated",
          NotificationType.AFFIRMATIVE
        );
        unsubscribe();
      }
    });
  }, [dispatch, accountData.uid, audienceList, netPeople, setIsVisible]);

  const madeChanges = useMemo(() => {
    if (selectedType.type !== SelectRecipientType.NONE) {
      return !isEqual(
        excludingMode ? allExcludedPeople : allSelectedPeople,
        unsavedRecipients
      );
    } else if (
      saveListPeople !== undefined &&
      !isEqual(liveListContactUids, netPeople)
    ) {
      return true;
    }
    return false;
  }, [
    allExcludedPeople,
    allSelectedPeople,
    excludingMode,
    liveListContactUids,
    netPeople,
    saveListPeople,
    selectedType.type,
    unsavedRecipients,
  ]);

  return (
    <>
      <LargePopupModalContainer
        showModal={true}
        containerStyles={{ padding: 0, height: "100%" }}
        valueComp={
          <div className="ColumnNormal PreventScroll">
            <SelectRecipientHeader
              {...mainSharedPropsPack}
              backToOverview={backOnPress}
              selectType={selectedType}
              updateSelectType={updateSelectType}
              unsavedRecipients={unsavedRecipients}
              setUnsavedRecipients={setUnsavedRecipients}
              unsavedCategories={[]}
              setUnsavedCategories={() => {}}
              setAllExcludedPeople={setAllExcludedPeople}
              setAllSelectedPeople={setAllSelectedPeople}
              madeChanges={madeChanges}
              saveListPeople={saveListPeople}
              initialListMembers={liveListContactUids}
              netListMembers={netPeople}
            />
            <HorizontalDivider />
            {loaded ? (
              <div
                className="ColumnNormal PreventScroll"
                style={{ padding: 14 }}
              >
                {selectedType.type === SelectRecipientType.NONE ? (
                  <SelectRecipientsOverview
                    {...mainSharedPropsPack}
                    allSelectedPeople={allSelectedPeople}
                    setAllSelectedPeople={setAllSelectedPeople}
                    allExcludedPeople={allExcludedPeople}
                    setAllExcludedPeople={setAllExcludedPeople}
                    updateSelectType={updateSelectType}
                    setProfileSelected={setProfileSelected}
                    backToOverview={() => {}}
                  />
                ) : null}
                {/* Select by Followers Screen */}
                {selectedType.type === SelectRecipientType.PEOPLE ? (
                  <SelectRecipientsFollowers
                    selectedType={selectedType}
                    {...sharedPropsPack}
                  />
                ) : null}
                {/* Select by Memberships Screen */}
                {selectedType.type === SelectRecipientType.MEMBERSHIPS ? (
                  (selectedType.selectedItem as MembershipPlan) ? (
                    <SelectRecipientsFollowers
                      selectedType={selectedType}
                      {...sharedPropsPack}
                    />
                  ) : (
                    <SelectRecipientsMembershipLibrary
                      excludingMode={excludingMode}
                      updateSelectType={updateSelectType}
                    />
                  )
                ) : null}
                {/* Select by Event Screen */}
                {selectedType.type === SelectRecipientType.EVENTS ? (
                  selectedType.selectedItem ? (
                    <SelectRecipientsEvent
                      selectedType={selectedType}
                      {...sharedPropsPack}
                    />
                  ) : (
                    <SelectRecipientsEventLibrary
                      updateSelectType={updateSelectType}
                    />
                  )
                ) : null}
                {/* Select by Audience List Screen */}
                {selectedType.type === SelectRecipientType.LISTS ? (
                  selectedType.selectedItem ? (
                    <SelectRecipientsAudienceList
                      selectedList={selectedType.selectedItem as AudienceList}
                      {...sharedPropsPack}
                    />
                  ) : (
                    <SelectRecipientsAudienceListLibrary
                      excludingMode={excludingMode}
                      updateSelectType={updateSelectType}
                    />
                  )
                ) : null}
                {/* Select by Collected Data Screen */}
                {selectedType.type === SelectRecipientType.COLLECTED_DATA ? (
                  selectedType.selectedItem ? (
                    <SelectRecipientsCollectedData
                      selectedQuestion={
                        selectedType.selectedItem as SavedFormQuestion
                      }
                      {...sharedPropsPack}
                    />
                  ) : (
                    <SelectRecipientsCollectedDataLibrary
                      updateSelectType={updateSelectType}
                    />
                  )
                ) : null}
                {/* Select by Import Screen */}
                {selectedType.type === SelectRecipientType.IMPORTS ? (
                  selectedType.selectedItem ? (
                    <SelectRecipientsImport
                      selectedImport={selectedType.selectedItem as ImportData}
                      {...sharedPropsPack}
                    />
                  ) : (
                    <SelectRecipientsImportLibrary
                      updateSelectType={updateSelectType}
                      displayAllFollowers
                    />
                  )
                ) : null}
                {/* Selected Recipients Screen */}
                {selectedType.type === SelectRecipientType.RECIPIENTS ? (
                  <SelectedRecipientsList
                    {...mainSharedPropsPack}
                    unsavedRecipients={unsavedRecipients}
                    setUnsavedRecipients={setUnsavedRecipients}
                    unsavedCategories={[]}
                    setUnsavedCategories={() => {}}
                    allSelectedPeople={allSelectedPeople}
                    allExcludedPeople={allExcludedPeople}
                    setProfileSelected={setProfileSelected}
                    resetOnPress={resetOnPress}
                  />
                ) : null}
              </div>
            ) : (
              <div className="Centering PreventScroll">
                <CircularProgress style={{ color: Colors.GRAY1 }} size={24} />
              </div>
            )}
          </div>
        }
      />
      {profileSelected ? (
        <FullProfilePreviewModal
          profileSelected={profileSelected}
          setProfileSelected={setProfileSelected}
        />
      ) : null}
    </>
  );
};

export default ListSelectRecipientsPanel;
