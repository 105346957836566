import { AudienceList, AudienceListVisibility } from "@markit/common.types";
import { Colors } from "../../../utils/colors";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { Icon } from "@iconify/react";
import {
  dateIsCurrentYear,
  FAVORITES_LIST_NAME,
  GetDateWithTime,
} from "@markit/common.utils";
import LabelButton from "../../Buttons/LabelButton";
import ButtonWithDropdown from "../../Buttons/ButtonWithDropdown";
import { useCallback, useMemo, useState } from "react";
import { useTheme } from "../../../hooks/useTheme";
import { DropdownMenuItem } from "../../DropdownMenu";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { AudienceListDeleteModal } from "../../CreatorDashboard/CreatorProfile/AudienceLists/AudienceListDeleteModal";

export const AUDIENCE_LIST_FLEX_SECTIONS = [2, 1, 1, 6];

type AudienceListItemProps = {
  item: AudienceList;
  editListOnPress: () => void;
};

const AudienceListItem = ({ item, editListOnPress }: AudienceListItemProps) => {
  const { theme } = useTheme();
  const audienceListDetails =
    useSelector(getAccountState).account.audienceListDetails;
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const styles = {
    itemBorder: { border: `1px solid ${theme.SubText.color}` },
  };

  const audienceListDetail = useMemo(
    () =>
      audienceListDetails.find((listDetail) => listDetail.list.id === item.id),
    [audienceListDetails, item.id]
  );

  const viewOnPress = useCallback(() => {
    navigate(`/a/${item.id}`, {
      state: { audienceListId: item.id },
    });
  }, [item.id, navigate]);

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const menuItems: DropdownMenuItem[] = [
      {
        title: "View",
        icon: "ion:arrow-forward",
        key: "view",
        onPress: viewOnPress,
        isDestructive: false,
      },
    ];
    if (item.name !== FAVORITES_LIST_NAME) {
      menuItems.push(
        {
          title: "Edit List Details",
          icon: "ion:list",
          key: "edit_details",
          onPress: () => {
            editListOnPress();
            setShowDropdown(false);
          },
          isDestructive: false,
        },
        {
          title: "Delete",
          icon: "ion:trash",
          key: "delete",
          onPress: () => {
            setShowDeleteModal(true);
            setShowDropdown(false);
          },
          isDestructive: true,
        }
      );
    }
    return menuItems;
  }, [editListOnPress, item.name, viewOnPress]);

  return (
    <div>
      <div
        className="AlignedRow"
        style={{ paddingInline: 14, paddingBlock: 10 }}
      >
        <div
          className="ColumnNormalSelect"
          onClick={viewOnPress}
          style={{ gap: 7, flex: AUDIENCE_LIST_FLEX_SECTIONS[0] }}
        >
          <div className="AlignedRowSelect" style={{ gap: 7, maxWidth: 220 }}>
            {item.name === FAVORITES_LIST_NAME ? (
              <Icon icon="mdi:star" height={15} />
            ) : null}
            <span className="bodyMedium TextOverflowEllipsis">{item.name}</span>
          </div>
          <span className="smallBodySubtext">
            {item.createdAt === item.updatedAt ? "Created:" : "Last Updated:"}{" "}
            {GetDateWithTime(
              item.updatedAt,
              true,
              !dateIsCurrentYear(item.updatedAt)
            )}
          </span>
        </div>
        <div
          className="AlignedRow"
          style={{ flex: AUDIENCE_LIST_FLEX_SECTIONS[1] }}
        >
          <LabelButton
            color={Colors.BLACK}
            backgroundColor={Colors.WHITE1}
            text={
              item.visibility === AudienceListVisibility.PRIVATE
                ? "Private"
                : "Public"
            }
            containerStyles={{ border: `1px solid ${Colors.GRAY1}` }}
          />
        </div>
        <div
          className="AlignedRow"
          style={{ flex: AUDIENCE_LIST_FLEX_SECTIONS[2] }}
        >
          <span className="bodyMedium" style={{ marginLeft: 14 }}>
            {audienceListDetail ? audienceListDetail.numMembers : "--"}
          </span>
        </div>
        <div
          className="AlignedRowSpaced"
          style={{ flex: AUDIENCE_LIST_FLEX_SECTIONS[3], gap: 14 }}
        >
          <span
            className="TwoLineText smallBodySubtext"
            style={{ maxWidth: 299, marginLeft: 14, ...theme.LabelText }}
          >
            {item.name === FAVORITES_LIST_NAME
              ? "Favorites List"
              : item.description !== ""
              ? item.description
              : "No Description"}
          </span>
          <ButtonWithDropdown
            buttonLabel="View"
            textColor={Colors.BLACK}
            isOpenDropdown={showDropdown}
            setIsOpenDropdown={setShowDropdown}
            onPress={viewOnPress}
            dropdownItems={dropdownItems}
            containerStyles={styles.itemBorder}
          />
        </div>
      </div>
      <HorizontalDivider altMargin={14} />
      {showDeleteModal ? (
        <AudienceListDeleteModal
          listId={item.id}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
        />
      ) : null}
    </div>
  );
};

export default AudienceListItem;
