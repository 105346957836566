import React from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import CustomTextField from "../CustomTextField";
import RectangleButton from "../Buttons/RectangleButton";
import { OnForgotPasswordPress } from "../../utils/ReauthenticateLogin";
import TextHoverButton from "../Buttons/TextHoverButton";
import { isDesktop } from "react-device-detect";

type LoginForgotPasswordProps = {
  email: string;
  setEmail: (email: string) => void;
  emailError: {
    status: boolean;
    message: string;
  };
  setEmailError: (emailError: { status: boolean; message: string }) => void;
  backToEmailOnPress: () => void;
};

const LoginForgotPassword = (props: LoginForgotPasswordProps) => {
  const { email, setEmail, emailError, setEmailError, backToEmailOnPress } =
    props;
  const { theme } = useTheme();

  return (
    <div
      className="ColumnNormal"
      style={{
        width: isDesktop ? 330 : undefined,
        margin: isDesktop ? "auto" : undefined,
        gap: 20,
        alignItems: isDesktop ? "center" : "flex-start",
        paddingInline: isDesktop ? 0 : 14,
      }}
    >
      <div
        className={isDesktop ? "ColumnCentering" : "ColumnNormal"}
        style={{ gap: 12 }}
      >
        <span style={{ ...theme.PrimaryText, fontSize: 20, fontWeight: 500 }}>
          Forgot Password?
        </span>
        <span
          className="LoginHeaderTextDescription"
          style={{
            textAlign: isDesktop ? "center" : "left",
          }}
        >
          Enter the email address associated with your account,
          {isDesktop ? " " : <br />}and we&apos;ll email you a link to reset
          your password.
        </span>
      </div>
      <div className="ColumnNormal" style={{ gap: 20, width: "100%" }}>
        <CustomTextField
          value={email}
          placeholder="Email"
          inputMode="text"
          borderRadius={12}
          onChange={(change: any) => {
            setEmailError({
              status: false,
              message: "",
            });
            setEmail(change.target.value);
          }}
          error={emailError.status}
        />
        <RectangleButton
          buttonLabel={<span>Send Reset Link</span>}
          onPress={() => OnForgotPasswordPress(email, setEmailError)}
          theme={theme}
          altPaddingVert={12}
        />
      </div>
      <div style={{ margin: "auto" }}>
        <TextHoverButton
          text="Back"
          icon={
            <Icon icon="ion:chevron-left" height={11} color={Colors.GRAY1} />
          }
          onPress={backToEmailOnPress}
        />
      </div>
    </div>
  );
};

export default LoginForgotPassword;
