import { Colors } from "../../../utils/colors";
import { useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  changeMembershipPlan,
  getAccountState,
} from "../../../redux/slices/accountSlice";
import RectangleButton from "../../Buttons/RectangleButton";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "../../Containers/PopupModalContainer";
import CustomTextField from "../../CustomTextField";
import CustomTextFieldMultiline from "../../TextFields/CustomTextFieldMultiline";
import { MembershipPlan, NotificationType } from "@markit/common.types";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import AlertContainer from "../../Containers/AlertContainer";
import { InputAdornment, TextField } from "@mui/material";
import { useTheme } from "../../../hooks/useTheme";
import {
  getNumMembershipSubscriptionMembers,
  getNumPaidMembershipSubscriptions,
} from "../../../utils/subscriptionUtils";
import useAsyncOnMount from "../../../hooks/useAsyncEffectOnMount";

export type MembershipEditPlanModalProps = {
  editMembershipPlan: MembershipPlan;
  setEditMembershipPlan: (
    editMembershipPlan: MembershipPlan | undefined
  ) => void;
};

const MembershipEditPlanModal = (props: MembershipEditPlanModalProps) => {
  const { editMembershipPlan, setEditMembershipPlan } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const [savingChanges, setSavingChanges] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [alertText, setAlertText] = useState({
    header: "",
    subHeader: "",
  });
  const [numPaidSubscriptions, setNumPaidSubscriptions] = useState(0);

  const originalMembershipPlan = editMembershipPlan;

  useAsyncOnMount(async () => {
    const numPaidSubs = await getNumPaidMembershipSubscriptions(
      accountData.uid
    );
    setNumPaidSubscriptions(numPaidSubs);
  });

  const disabledPrice = useMemo(
    () =>
      editMembershipPlan.id === "free" ||
      (numPaidSubscriptions > 0 && editMembershipPlan.price > 0),
    [editMembershipPlan.id, editMembershipPlan.price, numPaidSubscriptions]
  );

  const updateSelectedMembershipPlan = useCallback(
    (newPlanSettings: Partial<MembershipPlan>) => {
      if (!editMembershipPlan) return;
      const finalPlan: MembershipPlan = {
        ...editMembershipPlan,
        ...newPlanSettings,
      };
      setEditMembershipPlan({ ...finalPlan });
    },
    [editMembershipPlan, setEditMembershipPlan]
  );

  const saveMembershipPlan = useCallback(async () => {
    if (editMembershipPlan) {
      setSavingChanges(true);
      const numPaidMembers = await getNumMembershipSubscriptionMembers(
        accountData.uid,
        editMembershipPlan.id
      );
      if (
        numPaidMembers > 0 &&
        originalMembershipPlan.price !== editMembershipPlan.price
      ) {
        setAlertText({
          header: "You currently have paid members on this plan.",
          subHeader:
            "You can only edit the price if there are no paid members for the specific plan.",
        });
        setSavingChanges(false);
        return;
      }
      await dispatch(changeMembershipPlan(editMembershipPlan, accountData.uid));
      setEditMembershipPlan(undefined);
      showNotificationBanner(
        dispatch,
        "Saved Changes",
        NotificationType.AFFIRMATIVE
      );
      setSavingChanges(false);
    }
  }, [
    accountData.uid,
    dispatch,
    editMembershipPlan,
    originalMembershipPlan.price,
    setEditMembershipPlan,
  ]);

  return (
    <>
      <PopupModalContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="sectionTitle">Plan Details</span>
            <span className="smallBodySubtext">
              Edit plan name, price, and benefits
            </span>
          </div>
        }
        valueComp={
          <div className="ColumnNormal" style={{ gap: 20 }}>
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <span className="bodyMedium">Plan Name</span>
              <div className="ColumnNormal" style={{ gap: 4 }}>
                <CustomTextField
                  value={editMembershipPlan.name}
                  placeholder="Name Your Plan..."
                  inputMode="text"
                  borderRadius={12}
                  maxLength={60}
                  backgroundColor={Colors.WHITE1}
                  onChange={(change: any) => {
                    updateSelectedMembershipPlan({
                      name: change.target.value,
                    });
                  }}
                  altMarginBottom={0}
                />
              </div>
            </div>
            <div className="ColumnNormal" style={{ gap: 10 }}>
              <span className="bodyMedium">Monthly Price</span>
              <TextField
                size="small"
                type="number"
                placeholder={"10.00"}
                disabled={disabledPrice}
                value={
                  editMembershipPlan.id !== "free" &&
                  editMembershipPlan.price === 0
                    ? ""
                    : editMembershipPlan.price / 100
                }
                onChange={(e) => {
                  const roundedPriceInCents = Math.round(
                    (e.target.value as unknown as number) * 100
                  );
                  updateSelectedMembershipPlan({ price: roundedPriceInCents });
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                inputProps={{ min: 1 }}
                className="normalTextField"
                InputProps={{
                  style: {
                    fontSize: 14,
                    borderRadius: 12,
                    backgroundColor: Colors.WHITE1,
                    width: "100%",
                    borderColor: isFocused
                      ? theme.FocusedTextFieldBorder.borderColor
                      : disabledPrice
                      ? theme.TextFieldBorder.borderColor
                      : theme.EditableTextFieldBorder.borderColor,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="bodySubtext">$</span>
                    </InputAdornment>
                  ),
                }}
              />
              {editMembershipPlan.id !== "free" ? (
                <span className="smallBodySubtext">
                  Price must be above $1. Markit takes 10% transaction fee not
                  including stripe processing fees.
                </span>
              ) : null}
            </div>
            <div className="ColumnNormal" style={{ gap: 10 }}>
              <span className="bodyMedium">Plan Benefits (Optional)</span>
              <CustomTextFieldMultiline
                value={editMembershipPlan.description}
                placeholder="Write a brief description..."
                onChange={(e) =>
                  updateSelectedMembershipPlan({
                    description: e.target.value,
                  })
                }
                maxLength={240}
              />
            </div>
          </div>
        }
        fixedBottomContent={
          <div className="ColumnNormal">
            <HorizontalDivider altMargin={14} />
            <div className="AlignedRow" style={{ gap: 10, paddingInline: 14 }}>
              <RectangleButton
                buttonLabel={"Cancel"}
                onPress={() => setEditMembershipPlan(undefined)}
                altTextColor={Colors.BLACK}
                altColor={Colors.GRAY11}
                containerStyles={{ paddingBlock: 14, paddingInline: 12 }}
              />
              <RectangleButton
                buttonLabel={"Save"}
                onPress={saveMembershipPlan}
                altTextColor={Colors.WHITE}
                altColor={Colors.BLACK}
                containerStyles={{ paddingBlock: 14, paddingInline: 12 }}
                loading={savingChanges}
              />
            </div>
          </div>
        }
        noExit
        closeModal={() => setEditMembershipPlan(undefined)}
        modalContentWidth={PopupModalContainerWidth.SMALL}
      />
      <AlertContainer
        headerComp={alertText.header}
        subHeaderComp={alertText.subHeader}
        closeModal={() => setAlertText({ header: "", subHeader: "" })}
        hideModal={alertText.header === ""}
      />
    </>
  );
};

export default MembershipEditPlanModal;
