import { useTheme } from "../../../../hooks/useTheme";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "../../../Containers/PopupModalContainer";
import CustomTextField from "../../../CustomTextField";
import { Colors } from "../../../../utils/colors";
import RectangleButton from "../../../Buttons/RectangleButton";
import { useCallback, useMemo, useState } from "react";
import { AudienceList } from "@markit/common.types";
import {
  compareTwoStringsInsensitive,
  FAVORITES_LIST_NAME,
} from "@markit/common.utils";
import { useSelector, useDispatch } from "react-redux";
import {
  getAccountState,
  updateAudienceList,
} from "../../../../redux/slices/accountSlice";

type AudienceListRenameModalProps = {
  audienceList: AudienceList;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
};

const AudienceListRenameModal = (props: AudienceListRenameModalProps) => {
  const { audienceList, showModal, setShowModal } = props;
  const { audienceListDetails, accountData } =
    useSelector(getAccountState).account;
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const [newListName, setNewListName] = useState(audienceList.name);
  const [listNameError, setListNameError] = useState("");

  const audienceLists = useMemo(
    () => audienceListDetails.map((listDetail) => listDetail.list),
    [audienceListDetails]
  );

  const renameListOnPress = useCallback(async () => {
    if (!newListName) {
      setListNameError("The list name cannot be empty.");
      return;
    }
    if (compareTwoStringsInsensitive(newListName, FAVORITES_LIST_NAME)) {
      setListNameError('Cannot rename the list to "Favorites".');
      return;
    }
    if (audienceLists.some((list) => list.name === newListName)) {
      setListNameError("A list already exists with this name.");
      return;
    }
    const newList: AudienceList = { ...audienceList, name: newListName };
    dispatch(updateAudienceList(accountData.uid, newList));
    setShowModal(false);
    setNewListName("");
  }, [
    accountData.uid,
    audienceList,
    audienceLists,
    dispatch,
    newListName,
    setShowModal,
  ]);

  return (
    <PopupModalContainer
      valueComp={
        <div className="ColumnNormal" style={{ gap: 20 }}>
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="bodyMedium">Edit List Name</span>
            <div className="ColumnNormal" style={{ gap: 4 }}>
              <CustomTextField
                value={newListName}
                placeholder="List Name"
                inputMode="text"
                borderRadius={12}
                onChange={(name: any) => {
                  setListNameError("");
                  setNewListName(name.target.value);
                }}
                maxLength={60}
                error={listNameError !== ""}
              />
              {listNameError ? (
                <span
                  className="smallBodySubtext"
                  style={{ color: Colors.RED3 }}
                >
                  {listNameError}
                </span>
              ) : null}
            </div>
            <span className="smallBodySubtext">{newListName.length}/60</span>
          </div>
          <RectangleButton
            buttonLabel="Save"
            onPress={renameListOnPress}
            altTextColor={Colors.WHITE}
            altColor={Colors.BLACK}
            altPaddingVert={14}
            disabled={audienceList.name === newListName}
          />
        </div>
      }
      noExit
      noDivider
      closeOnOutsidePress
      closeModal={() => setShowModal(false)}
      hideModal={!showModal}
      theme={theme}
      modalContentWidth={PopupModalContainerWidth.SMALL}
    />
  );
};

export default AudienceListRenameModal;
