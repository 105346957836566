import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AccountData,
  AudienceList,
  FollowerStatus,
} from "@markit/common.types";
import { Colors } from "../../../../utils/colors";
import filter from "lodash.filter";
import SearchBoxContainer from "../../../Containers/SearchBoxContainer";
import { Icon } from "@iconify/react";
import ListDropdown from "../../../DropdownsAndTabs/ListDropdown";
import { useLoadAudienceListMembers } from "../../../../hooks/useLoadAudienceListMembers";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import useAsyncEffect from "../../../../hooks/useAsyncEffect";
import { filterFollowersByStatus } from "@markit/common.utils";
import {
  FOLLOWER_SHORT_USER_FLEX_SECTIONS,
  FOLLOWER_USER_FLEX_SECTIONS,
} from "../../../DisplayItem/ProfileItem/ProfileFollowerItem";
import { EmptyStateFlatlist } from "../../../EmptyStates/EmptyStateFlatlist";
import EmptyStateButton from "../../../Buttons/EmptyStateButton";
import StandardListContainer from "../../../Containers/StandardListContainer";
import FlatlistLoadMore from "../../../FlatlistLoadMore";
import FullProfilePreviewModal from "../../../FollowerProfile/FullProfilePreviewModal";
import { useLiveUpdatingListAnalytics } from "../../../../hooks/useLiveHooks/useLiveUpdatingListAnalytics";
import AudienceListMemberItem from "../../../DisplayItem/AudienceListItem/AudienceListMemberItem";

export enum ListMemberFilterStatus {
  SUBSCRIBED = 0,
  UNSUBSCRIBED = 1,
  REMOVED = 2,
  INVALID_NUMBER = 3,
}

type AudienceListPeoplePanelProps = {
  audienceList: AudienceList;
  selectRecipientsVisible: boolean;
  setSelectRecipientsVisible: (selectRecipientsVisible: boolean) => void;
};

const AudienceListPeoplePanel = (props: AudienceListPeoplePanelProps) => {
  const { audienceList, selectRecipientsVisible, setSelectRecipientsVisible } =
    props;
  const { followersData, savedQuestions } =
    useSelector(getAccountState).account;
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownValue, setDropdownValue] = useState<ListMemberFilterStatus>(
    ListMemberFilterStatus.SUBSCRIBED
  );
  const [profileSelected, setProfileSelected] = useState<AccountData>();
  const [initialLoading, setInitialLoading] = useState(true);

  const { liveListContactUids, loaded, reloadCount } =
    useLiveUpdatingListAnalytics({ listId: audienceList.id });

  const essentialQuestion = useMemo(
    () => savedQuestions.find((question) => question.isEssential),
    [savedQuestions]
  );

  const subscribedMembers = useMemo(
    () =>
      filterFollowersByStatus(
        liveListContactUids,
        followersData,
        FollowerStatus.SUBSCRIBED
      ),
    [followersData, liveListContactUids]
  );

  const unsubscribedMembers = useMemo(
    () =>
      filterFollowersByStatus(
        liveListContactUids,
        followersData,
        FollowerStatus.UNSUBSCRIBED
      ),
    [followersData, liveListContactUids]
  );

  const removedMembers = useMemo(
    () =>
      filterFollowersByStatus(
        liveListContactUids,
        followersData,
        FollowerStatus.REMOVED
      ),
    [followersData, liveListContactUids]
  );

  const invalidMembers = useMemo(
    () =>
      filterFollowersByStatus(
        liveListContactUids,
        followersData,
        FollowerStatus.INVALID_NUMBER
      ),
    [followersData, liveListContactUids]
  );

  const {
    isFinished: isSubscribedFinished,
    isLoading: isSubscribedLoading,
    fetchedUserData: fetchedSubscribedUserData,
    loadMoreUsers: loadMoreSubscribedUsers,
    loadSearchResultsThrottled: loadSubscribedSearchResultsThrottled,
    reloadMoreUsers: reloadSubscribedUsers,
    removeUserData: removeSubscribedUserData,
  } = useLoadAudienceListMembers({
    userIdList: loaded ? subscribedMembers : [],
    windowSize: 20,
  });

  const {
    isFinished: isUnsubscribedFinished,
    isLoading: isUnsubscribedLoading,
    fetchedUserData: fetchedUnsubscribedUserData,
    loadMoreUsers: loadMoreUnsubscribedUsers,
    loadSearchResultsThrottled: loadUnsubscribedSearchResultsThrottled,
    removeUserData: removeUnsubscribedUserData,
  } = useLoadAudienceListMembers({
    userIdList: loaded ? unsubscribedMembers : [],
    windowSize: 20,
  });

  const {
    isFinished: isRemovedFinished,
    isLoading: isRemovedLoading,
    fetchedUserData: fetchedRemovedUserData,
    loadMoreUsers: loadMoreRemovedUsers,
    loadSearchResultsThrottled: loadRemovedSearchResultsThrottled,
    removeUserData: removeRemovedUserData,
  } = useLoadAudienceListMembers({
    userIdList: loaded ? removedMembers : [],
    windowSize: 20,
  });

  const {
    isFinished: isInvalidFinished,
    isLoading: isInvalidLoading,
    fetchedUserData: fetchedInvalidUserData,
    loadMoreUsers: loadMoreInvalidUsers,
    loadSearchResultsThrottled: loadInvalidSearchResultsThrottled,
    removeUserData: removeInvalidUserData,
  } = useLoadAudienceListMembers({
    userIdList: loaded ? invalidMembers : [],
    windowSize: 20,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (loaded) {
      await Promise.all([
        loadMoreSubscribedUsers(),
        loadMoreUnsubscribedUsers(),
        loadMoreRemovedUsers(),
        loadMoreInvalidUsers(),
      ]);
      setInitialLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  // Reload the audience list members displayed in the list when you close the select modal
  useEffect(() => {
    if (!initialLoading && !selectRecipientsVisible) {
      reloadCount();
      reloadSubscribedUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRecipientsVisible]);

  const fetchedUserData = useMemo(
    () =>
      dropdownValue === ListMemberFilterStatus.SUBSCRIBED
        ? fetchedSubscribedUserData
        : dropdownValue === ListMemberFilterStatus.UNSUBSCRIBED
        ? fetchedUnsubscribedUserData
        : dropdownValue === ListMemberFilterStatus.REMOVED
        ? fetchedRemovedUserData
        : fetchedInvalidUserData,
    [
      dropdownValue,
      fetchedInvalidUserData,
      fetchedRemovedUserData,
      fetchedSubscribedUserData,
      fetchedUnsubscribedUserData,
    ]
  );

  const isLoading = useMemo(
    () =>
      dropdownValue === ListMemberFilterStatus.SUBSCRIBED
        ? isSubscribedLoading
        : dropdownValue === ListMemberFilterStatus.UNSUBSCRIBED
        ? isUnsubscribedLoading
        : dropdownValue === ListMemberFilterStatus.REMOVED
        ? isRemovedLoading
        : isInvalidLoading,
    [
      dropdownValue,
      isInvalidLoading,
      isRemovedLoading,
      isSubscribedLoading,
      isUnsubscribedLoading,
    ]
  );

  const isFinished = useMemo(
    () =>
      dropdownValue === ListMemberFilterStatus.SUBSCRIBED
        ? isSubscribedFinished
        : dropdownValue === ListMemberFilterStatus.UNSUBSCRIBED
        ? isUnsubscribedFinished
        : dropdownValue === ListMemberFilterStatus.REMOVED
        ? isRemovedFinished
        : isInvalidFinished,
    [
      dropdownValue,
      isInvalidFinished,
      isRemovedFinished,
      isSubscribedFinished,
      isUnsubscribedFinished,
    ]
  );

  const loadSearchResultsThrottled = useCallback(
    (text: string) =>
      dropdownValue === ListMemberFilterStatus.SUBSCRIBED
        ? loadSubscribedSearchResultsThrottled(text)
        : dropdownValue === ListMemberFilterStatus.UNSUBSCRIBED
        ? loadUnsubscribedSearchResultsThrottled(text)
        : dropdownValue === ListMemberFilterStatus.REMOVED
        ? loadRemovedSearchResultsThrottled(text)
        : loadInvalidSearchResultsThrottled(text),
    [
      dropdownValue,
      loadInvalidSearchResultsThrottled,
      loadRemovedSearchResultsThrottled,
      loadSubscribedSearchResultsThrottled,
      loadUnsubscribedSearchResultsThrottled,
    ]
  );

  // Called when you remove an individual member to remove the user from the useLoad, and also reset the count
  const removeUserData = useCallback(
    (userId: string) => {
      dropdownValue === ListMemberFilterStatus.SUBSCRIBED
        ? removeSubscribedUserData(userId)
        : dropdownValue === ListMemberFilterStatus.UNSUBSCRIBED
        ? removeUnsubscribedUserData(userId)
        : dropdownValue === ListMemberFilterStatus.REMOVED
        ? removeRemovedUserData(userId)
        : removeInvalidUserData(userId);
      reloadCount();
    },
    [
      dropdownValue,
      reloadCount,
      removeInvalidUserData,
      removeRemovedUserData,
      removeSubscribedUserData,
      removeUnsubscribedUserData,
    ]
  );

  const loadMoreUsers = useCallback(
    () =>
      dropdownValue === ListMemberFilterStatus.SUBSCRIBED
        ? loadMoreSubscribedUsers()
        : dropdownValue === ListMemberFilterStatus.UNSUBSCRIBED
        ? loadMoreUnsubscribedUsers()
        : dropdownValue === ListMemberFilterStatus.REMOVED
        ? loadMoreRemovedUsers()
        : loadMoreInvalidUsers(),
    [
      dropdownValue,
      loadMoreInvalidUsers,
      loadMoreRemovedUsers,
      loadMoreSubscribedUsers,
      loadMoreUnsubscribedUsers,
    ]
  );

  const handleSearch = (text: string) => {
    setTimeout(() => {
      const lowercaseText = text.toLowerCase();
      setSearchTerm(lowercaseText);
      loadSearchResultsThrottled(lowercaseText);
    }, 100);
  };

  const contains = useCallback((user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query);
  }, []);

  const listMembersToShow = useMemo(() => {
    let listMembers: AccountData[] = fetchedUserData;
    if (searchTerm !== "") {
      listMembers = filter(listMembers, (member: AccountData) => {
        return contains(member, searchTerm);
      });
    }
    return listMembers;
  }, [contains, fetchedUserData, searchTerm]);

  const renderEmptyView = useMemo(() => {
    const noListMembers = liveListContactUids.length === 0;
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoading || !loaded}
        containerStyles={{ paddingTop: 100 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title={
              noListMembers ? "Add People to Your List" : "No People in Status"
            }
            description={
              noListMembers
                ? "People in this list will show up here. Grow your lists by sharing or importing people"
                : "There are no people within this specific status."
            }
            icon={
              <Icon icon="ion:person-add" height={50} color={Colors.BLUE5} />
            }
            iconBox={84}
            containerStyles={{ paddingTop: 100 }}
            btnText={noListMembers ? "Add People" : undefined}
            onPress={() => setSelectRecipientsVisible(true)}
          />
        }
      />
    );
  }, [
    isLoading,
    liveListContactUids.length,
    loaded,
    searchTerm,
    setSelectRecipientsVisible,
  ]);

  const renderMemberItem = useCallback(
    (item: AccountData) => {
      return (
        <div key={item.uid}>
          <AudienceListMemberItem
            list={audienceList}
            user={item}
            setProfileSelected={setProfileSelected}
            essentialQuestion={essentialQuestion}
            removeMemberData={() => removeUserData(item.uid)}
          />
        </div>
      );
    },
    [audienceList, essentialQuestion, removeUserData]
  );

  return (
    <div
      className="ColumnNormal"
      style={{ backgroundColor: Colors.WHITE, height: "100%", padding: 30 }}
    >
      <StandardListContainer
        searchComp={
          <div className="AlignedRowSpaced" style={{ width: "100%" }}>
            <div className="AlignedRow" style={{ gap: 14 }}>
              <SearchBoxContainer
                placeholder={"Search"}
                onChange={(e) => handleSearch(e.target.value)}
                containerStyles={{ marginTop: 0, width: 394 }}
              />
              <ListDropdown
                dropdownLabels={[
                  "Subscribed",
                  "Unsubscribed",
                  "Removed",
                  "Invalid Number",
                  //   "Blocked",
                ]}
                dropdownNumbers={[
                  !loaded ? -1 : subscribedMembers.length,
                  !loaded ? -1 : unsubscribedMembers.length,
                  !loaded ? -1 : removedMembers.length,
                  !loaded ? -1 : invalidMembers.length,
                ]}
                selectedValue={dropdownValue}
                onChange={setDropdownValue}
              />
            </div>
            {liveListContactUids.length > 0 ? (
              <span className="bodyMedium">
                {liveListContactUids.length} Total List Members
              </span>
            ) : null}
          </div>
        }
        gridTable={{
          flexSections: essentialQuestion
            ? FOLLOWER_USER_FLEX_SECTIONS
            : FOLLOWER_SHORT_USER_FLEX_SECTIONS,
          sectionTitles: essentialQuestion
            ? ["Name", "Status", "Follow Source", "Essential"]
            : ["Name", "Status", "Follow Source"],
        }}
        listComp={
          <FlatlistLoadMore
            fullList={fetchedUserData}
            currentList={listMembersToShow}
            renderItem={(item: AccountData) => renderMemberItem(item)}
            renderWhenEmpty={renderEmptyView}
            isLoadingMore={isLoading}
            showLoadMore={searchTerm.trim() === "" && !isFinished}
            loadMoreOnPress={loadMoreUsers}
          />
        }
      />
      {profileSelected ? (
        <FullProfilePreviewModal
          profileSelected={profileSelected}
          setProfileSelected={setProfileSelected}
        />
      ) : null}
    </div>
  );
};

export default AudienceListPeoplePanel;
