import React, { memo } from "react";
import { TextPluginOptions } from "@markit/common.types";
import { splitPluginTextInputMessage } from "@markit/common.utils";
import { Colors } from "../../utils/colors";

type ModifiedTextMessageDisplayProps = {
  message: string;
  altColor?: string;
  disablePlugins?: boolean;
};

export const ModifiedTextMessageDisplay = memo(
  function ModifiedTextMessageDisplayFn(
    props: ModifiedTextMessageDisplayProps
  ) {
    const { message, altColor, disablePlugins } = props;

    return (
      <>
        {disablePlugins ? (
          <span style={{ color: altColor ?? Colors.GRAY3 }}>{message}</span>
        ) : (
          splitPluginTextInputMessage(message).map((word, index) => {
            const pluginWord = TextPluginOptions.some(
              (option) => option.name === word
            );
            return (
              <span
                key={index}
                style={{
                  color: pluginWord ? Colors.GREEN2 : altColor ?? Colors.GRAY3,
                }}
              >
                {word}
              </span>
            );
          })
        )}
      </>
    );
  }
);
