import React, { useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import {
  GuestInfo,
  SpreadsheetFileDisplay,
  SpreadsheetInfo,
} from "@markit/common.types";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import {
  formatImportedPhoneNumber,
  formatPhoneNumber,
  isPhoneNumberValid,
} from "../../../../utils/FormatPhoneNumber";
import ValidContactsPopupPanel from "./ValidContactsPopupPanel";
import RectangleButton from "../../../Buttons/RectangleButton";
import { MARKIT_HOTLINE_NUMBER, detectedCensored } from "@markit/common.utils";
import {
  foundExistingSpreadsheetContacts,
  getCleanedDataValidColumns,
  parseSpreadsheetFile,
} from "../../../../utils/spreadsheetUtils";
import { CircularProgress } from "@mui/material";
import ConfirmActionModal from "../../../Containers/ConfirmPopups/ConfirmActionModal";
import AlertContainer from "../../../Containers/AlertContainer";
import ComplianceConsentTerms from "../../../Compliance/ComplianceConsentTerms";
import { FileUploader } from "react-drag-drop-files";

type SpreadsheetFileData = {
  phoneNumber: string;
  dataColumns: string[];
};

type UploadSpreadsheetFileProps = {
  spreadsheet: SpreadsheetInfo | undefined;
  setSpreadsheet: (spreadsheet: SpreadsheetInfo | undefined) => void;
  currNumImported: number;
  displayFile: SpreadsheetFileDisplay;
  setDisplayFile?: (displayFile: SpreadsheetFileDisplay) => void;
  permissionsChecked?: boolean;
  setPermissionsChecked?: (permissionsChecked: boolean) => void;
};

const UploadSpreadsheetFile = (props: UploadSpreadsheetFileProps) => {
  const {
    spreadsheet,
    setSpreadsheet,
    currNumImported,
    displayFile,
    setDisplayFile,
    permissionsChecked,
    setPermissionsChecked,
  } = props;
  const { accountData } = useSelector(getAccountState).account;
  const [tempSpreadsheet, setTempSpreadsheet] = useState<SpreadsheetInfo>();
  const [contactsVisible, setContactsVisible] = useState(0); // 1 = valid contacts, 2 = invalid contacts, 3 = duplicate, 4 = existing
  const [alertText, setAlertText] = useState<{
    heading: string;
    subHeading: string;
    btnType: "Help" | "Got It" | "";
  }>({ heading: "", subHeading: "", btnType: "" });
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [reuploadVisible, setReuploadVisible] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    subtext: { fontSize: 14, color: Colors.GRAY1 },
    bodyMedium: { fontSize: 14, fontWeight: 500 },
    reuploadBtn: {
      border: "0.5px solid #B9B9B9",
      paddingInline: 10,
      paddingBlock: 7,
      borderRadius: 8,
      cursor: "pointer",
      whiteSpace: "nowrap",
    },
  };

  const numAvailableImports = useMemo(
    () => 500 - currNumImported,
    [currNumImported]
  );

  const contactsToShow = useMemo(() => {
    if (!spreadsheet) {
      return [];
    }
    const contacts: { name: string; phoneNumber: string }[] = (
      contactsVisible === 1
        ? spreadsheet.validRows
        : contactsVisible === 2
        ? spreadsheet.invalidRows
        : contactsVisible === 3
        ? spreadsheet.duplicateRows
        : spreadsheet.existingContactRows
    ).map((row) => {
      return { name: row.fullName, phoneNumber: row.phoneNumber };
    });
    return contacts;
  }, [contactsVisible, spreadsheet]);

  const poorlyFormattedFile = useCallback((subHeading: string) => {
    setAlertText({
      heading: "Poorly Formatted File",
      subHeading: subHeading,
      btnType: "Got It",
    });
    setUploadLoading(false);
  }, []);

  const onUploadSpreadsheet = useCallback(
    (file) => {
      setReuploadVisible(false);
      if (setPermissionsChecked) {
        setPermissionsChecked(false);
      }
      if (!file) {
        return;
      }
      setUploadLoading(true);

      const reader = new FileReader();
      let csv: string = "";
      reader.onload = async (e) => {
        const fileData = e.target?.result;
        csv = parseSpreadsheetFile(fileData, file);
        if (!csv.length) {
          poorlyFormattedFile(
            "There were no entries found in the provided spreadsheet."
          );
          return;
        }

        // Check up to the first 50 rows and identify the phone number column
        const dataRows = csv.split("\n").filter((row) => row.length > 0);
        let phoneIndex = -1;
        for (let i = 0; i < Math.min(dataRows.length, 50); i++) {
          // Exit loop early once phoneIndex is identified
          if (phoneIndex !== -1) {
            break;
          }
          const dataRow = dataRows[i];
          // Split by commas excluding those found within double quotes
          const rowItems = dataRow.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          for (let i = 0; i < rowItems.length; i++) {
            const formattedNumber = formatImportedPhoneNumber(rowItems[i]);
            if (isPhoneNumberValid(formattedNumber)) {
              phoneIndex = i;
            }
          }
        }
        // No phone number column was found
        if (phoneIndex === -1) {
          poorlyFormattedFile(
            "There was no valid phone number column found in the spreadsheet."
          );
          return;
        }

        const data: SpreadsheetFileData[] = dataRows.map((row) => {
          // Split by commas excluding those found within double quotes
          // Then, when returning data, remove the double quotes found for cleaned data
          const splitData = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          const otherDataColumns = splitData.filter((_, i) => i !== phoneIndex);
          return {
            phoneNumber: splitData[phoneIndex],
            dataColumns: otherDataColumns.map((column) =>
              column.replace(/"/g, "")
            ),
          };
        });

        const longestDataRowsLength = data.reduce((max, row) => {
          return Math.max(max, row.dataColumns.length);
        }, 0);

        const validRows: GuestInfo[] = [];
        const invalidRows: GuestInfo[] = [];
        const duplicateRows: GuestInfo[] = [];

        await Promise.all(
          data.map((row: SpreadsheetFileData) => {
            // Keep the raw row data to pass into duplicate and failed rows
            const formattedNumber = formatImportedPhoneNumber(row.phoneNumber);
            const foundDuplicateNumber = validRows.some(
              (currRow) => currRow.phoneNumber === formattedNumber
            );

            // If there is only one dataColumn, assume it is the fullName
            const singleDataColumnName = longestDataRowsLength === 1;
            const guestRow: GuestInfo = {
              phoneNumber: formattedNumber,
              fullName: singleDataColumnName ? row.dataColumns[0] : "",
              dataColumns: row.dataColumns,
            };
            // If censored term detected, immediately push to invalidRow
            if (detectedCensored(guestRow.fullName)) {
              invalidRows.push(guestRow);
            } else if (
              isPhoneNumberValid(guestRow.phoneNumber) &&
              guestRow.phoneNumber !== accountData.phoneNumber
            ) {
              if (!foundDuplicateNumber) {
                validRows.push(guestRow);
              } else {
                duplicateRows.push(guestRow);
              }
            } else {
              invalidRows.push(guestRow);
            }
            return true;
          })
        );

        const existingContacts = await foundExistingSpreadsheetContacts(
          accountData.uid,
          validRows
        );

        const [cleanedValidRows, cleanedExistingRows] = await Promise.all([
          getCleanedDataValidColumns(validRows),
          getCleanedDataValidColumns(existingContacts),
        ]);

        const finalValidRows = cleanedValidRows.filter(
          (row) =>
            !cleanedExistingRows.some(
              (existingRow) => existingRow.phoneNumber === row.phoneNumber
            )
        );

        // If spreadsheet has over 500 contacts and user has not gotten approval
        if (
          finalValidRows.length >= numAvailableImports &&
          !accountData.importContactsApproval
        ) {
          setAlertText({
            heading: "Need permission to import 500+ contacts",
            subHeading:
              (currNumImported > 0
                ? `You have already imported ${currNumImported}/500 total contacts. `
                : "") +
              "Importing over 500 contacts requires manual approval from our team to ensure credibility and prevent unauthorized messaging. Please text our customer hotline to request permission and we will review your request promptly.",
            btnType: "Help",
          });
        } else if (currNumImported === 0) {
          // If this is the first spreadsheet the user is uploading
          setTempSpreadsheet({
            fileName: file.name,
            validRows: finalValidRows,
            invalidRows,
            duplicateRows,
            existingContactRows: cleanedExistingRows,
          });
          setConfirmVisible(true);
        } else {
          setSpreadsheet({
            fileName: file.name,
            validRows: finalValidRows,
            invalidRows,
            duplicateRows,
            existingContactRows: cleanedExistingRows,
          });
          if (setDisplayFile) {
            setDisplayFile({ ...displayFile, name: file.name });
          }
        }
        setUploadLoading(false);
      };
      if (file.name.endsWith(".xlsx")) {
        reader.readAsArrayBuffer(file);
      } else if (file.name.endsWith(".csv")) {
        reader.readAsText(file);
      } else {
        setAlertText({
          heading: "Invalid File",
          subHeading:
            "Sorry, the uploaded file type is not supported. Please upload a .csv or .xlsx file.",
          btnType: "Got It",
        });
      }
    },
    [
      accountData.importContactsApproval,
      accountData.phoneNumber,
      accountData.uid,
      currNumImported,
      displayFile,
      numAvailableImports,
      poorlyFormattedFile,
      setDisplayFile,
      setPermissionsChecked,
      setSpreadsheet,
    ]
  );

  const onUploadSpreadsheetDrop = useCallback(
    (file) => {
      onUploadSpreadsheet(file);
    },
    [onUploadSpreadsheet]
  );

  const onUploadSpreadsheetSelect = useCallback(
    async (event) => {
      await onUploadSpreadsheet(event.target.files[0]);
      event.target.value = null;
    },
    [onUploadSpreadsheet]
  );

  const handleFirstUpload = useCallback(() => {
    if (tempSpreadsheet) {
      setSpreadsheet(tempSpreadsheet);
      if (setDisplayFile) {
        setDisplayFile({ ...displayFile, name: tempSpreadsheet.fileName });
      }
    }
  }, [displayFile, setDisplayFile, setSpreadsheet, tempSpreadsheet]);

  const cancelFirstUpload = useCallback(() => {
    setTempSpreadsheet(undefined);
    if (setPermissionsChecked) {
      setPermissionsChecked(false);
    }
  }, [setPermissionsChecked]);

  const previewContactsOnPress = useCallback((contactRow: number) => {
    setContactsVisible(contactRow);
  }, []);

  const loadingState = useMemo(
    () => (
      <div>
        <CircularProgress size={16} style={{ color: Colors.GRAY1 }} />
      </div>
    ),
    []
  );

  const contactsInfoRow = useCallback(
    (contactRow: number, rowValue: number) => {
      const rowName =
        contactRow === 1
          ? "New Valid Contacts"
          : contactRow === 2
          ? "Failed Imports"
          : contactRow === 3
          ? "Duplicates Found"
          : "Already Existing Contacts";
      return (
        <div
          onClick={() => previewContactsOnPress(contactRow)}
          className="AlignedRowSpacedSelect"
          style={{
            paddingInline: 14,
            pointerEvents: rowValue === 0 ? "none" : "all",
          }}
        >
          <span
            style={{
              ...styles.subtext,
              fontWeight: 500,
              color: contactRow === 1 ? Colors.GREEN2 : Colors.BLACK,
            }}
          >
            {rowValue} {rowName}
          </span>
          <Icon icon="ion:chevron-right" height={14} color={Colors.GRAY1} />
        </div>
      );
    },
    [previewContactsOnPress, styles.subtext]
  );

  return (
    <>
      {!spreadsheet ? (
        <div className="ColumnNormal" style={{ paddingTop: 80, gap: 40 }}>
          <FileUploader
            handleChange={onUploadSpreadsheetDrop}
            name="file"
            types={["CSV", "XLSX"]}
            onTypeError={() =>
              setAlertText({
                heading: "Invalid File",
                subHeading:
                  "Sorry, the uploaded file type is not supported. Please upload a .csv or .xlsx file.",
                btnType: "Got It",
              })
            }
            dropMessageStyle={{ opacity: 0.5 }}
            disabled={uploadLoading}
          >
            <StandardBorderedContainer
              containerStyles={{
                border: "1.5px dashed #b9b9b9",
                paddingInline: 40,
                paddingBlock: 60,
                width: 547,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                pointerEvents: uploadLoading ? "none" : "all",
              }}
            >
              <div
                className="ColumnNormalSelect Centering"
                style={{
                  gap: 7,
                  paddingBottom: 40,
                  textAlign: "center",
                  paddingInline: 40,
                }}
              >
                <span style={{ fontSize: 20 }}>
                  Upload a csv or xlsx file to get started
                </span>
                <span style={styles.subtext}>
                  Make sure the spreadsheet has phone numbers in a column and a
                  contact name which you will assign in the next step.
                </span>
              </div>
              <div
                className="AlignedRowSelect Centering"
                style={{
                  backgroundColor: Colors.BLACK,
                  color: Colors.WHITE,
                  width: 140,
                  paddingBlock: 14,
                  borderRadius: 12,
                }}
              >
                {!uploadLoading ? (
                  <span style={styles.bodyMedium}>Browse</span>
                ) : (
                  <CircularProgress
                    style={{ color: Colors.WHITE, alignSelf: "center" }}
                    size={17}
                  />
                )}
              </div>
            </StandardBorderedContainer>
          </FileUploader>
        </div>
      ) : (
        <StandardBorderedContainer
          containerStyles={{
            backgroundColor: Colors.WHITE1,
            paddingBlock: 14,
            width: 547,
          }}
        >
          <div className="ColumnNormal" style={{ gap: 14 }}>
            <div
              className="AlignedRowSpaced"
              style={{ paddingInline: 14, gap: 14 }}
            >
              {setDisplayFile ? (
                <div className="AlignedRow" style={{ gap: 7 }}>
                  <div>
                    <Icon
                      icon="ion:checkmark-circle"
                      height={18}
                      color={Colors.GREEN2}
                      style={{ marginBottom: -2 }}
                    />
                  </div>
                  <span
                    className="OneLineText"
                    style={{
                      ...styles.bodyMedium,
                      wordBreak: "break-all",
                    }}
                  >
                    {spreadsheet.fileName}
                  </span>
                </div>
              ) : (
                <div className="ColumnNormal" style={{ gap: 4 }}>
                  <span
                    className="OneLineText"
                    style={{ ...styles.bodyMedium, wordBreak: "break-all" }}
                  >
                    {displayFile.name}
                  </span>
                  <span
                    className="OneLineText"
                    style={{ ...styles.subtext, wordBreak: "break-all" }}
                  >
                    {spreadsheet.fileName}
                  </span>
                </div>
              )}
              {permissionsChecked !== undefined ? (
                <div>
                  {uploadLoading ? (
                    loadingState
                  ) : (
                    <div
                      onClick={() => setReuploadVisible(true)}
                      className="Centering"
                      style={styles.reuploadBtn}
                    >
                      <span style={{ fontSize: 12 }}>Re-Upload</span>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            <HorizontalDivider />
            {contactsInfoRow(1, spreadsheet.validRows.length)}
            <HorizontalDivider />
            {contactsInfoRow(2, spreadsheet.invalidRows.length)}
            {spreadsheet.duplicateRows.length > 0 ? (
              <>
                <HorizontalDivider />
                {contactsInfoRow(3, spreadsheet.duplicateRows.length)}
              </>
            ) : null}
            {spreadsheet.existingContactRows.length > 0 ? (
              <>
                <HorizontalDivider />
                {contactsInfoRow(4, spreadsheet.existingContactRows.length)}
              </>
            ) : null}
          </div>
        </StandardBorderedContainer>
      )}
      {spreadsheet && contactsVisible > 0 ? (
        <ValidContactsPopupPanel
          contactsToShow={contactsToShow}
          contactsView={contactsVisible}
          setContactsView={setContactsVisible}
        />
      ) : null}
      <AlertContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 14 }}>
            <Icon icon="ion:cloud-upload" height={35} />
            <span>{alertText.heading}</span>
          </div>
        }
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        alternateValueComp={
          <RectangleButton
            buttonLabel={
              alertText.btnType === "Got It" ? (
                <span>Got It</span>
              ) : (
                <a
                  href={`sms:${MARKIT_HOTLINE_NUMBER}`}
                  className="AlignedRow"
                  style={{ gap: 10, fontWeight: 600 }}
                >
                  <span style={{ color: Colors.BLACK }}>Contact Hotline</span>
                  <span style={{ color: Colors.GRAY1 }}>
                    {formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}
                  </span>
                </a>
              )
            }
            onPress={() =>
              alertText.btnType === "Got It"
                ? setAlertText({ heading: "", subHeading: "", btnType: "" })
                : {}
            }
            altColor={
              alertText.btnType === "Got It" ? Colors.BLACK : Colors.GRAY6
            }
          />
        }
        closeOnOutsidePress={alertText.btnType === "Help"}
        closeModal={() =>
          setAlertText({ heading: "", subHeading: "", btnType: "" })
        }
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
      <ConfirmActionModal
        heading={"Before you continue"}
        subtext={
          permissionsChecked !== undefined && setPermissionsChecked ? (
            <ComplianceConsentTerms
              isChecked={permissionsChecked}
              setIsChecked={setPermissionsChecked}
              preText="You acknowledge and agree"
            />
          ) : undefined
        }
        confirmButtonText={"Continue"}
        icon={<Icon icon="ion:cloud-upload" height={40} />}
        hideModal={!confirmVisible}
        setIsVisible={setConfirmVisible}
        confirmOnPress={handleFirstUpload}
        cancelOnPress={cancelFirstUpload}
        disableConfirm={!permissionsChecked}
      />
      <ConfirmActionModal
        heading={"Upload a new file?"}
        subtext={"Your existing file will be discarded."}
        confirmButtonText={"New Upload"}
        icon={<Icon icon="ion:cloud-upload" height={40} />}
        hideModal={!reuploadVisible}
        setIsVisible={setReuploadVisible}
        confirmOnPress={() => {}}
        alternateConfirmBtn={
          <label
            className="RectangleButton"
            style={{
              backgroundColor: Colors.BLACK,
              cursor: "pointer",
              paddingBlock: 14,
            }}
          >
            <input
              type="file"
              accept=".csv, .xlsx"
              onChange={onUploadSpreadsheetSelect}
            />
            <span
              style={{ color: Colors.WHITE, fontSize: 14, fontWeight: 500 }}
            >
              New Upload
            </span>
          </label>
        }
      />
    </>
  );
};

export default UploadSpreadsheetFile;
