import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import { useCallback, useEffect, useMemo, useState } from "react";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "../../Containers/PopupModalContainer";
import { useSelector, useDispatch } from "react-redux";
import {
  changeProfilePasswordProtect,
  getAccountState,
} from "../../../redux/slices/accountSlice";
import { useTheme } from "../../../hooks/useTheme";
import { NotificationType } from "@markit/common.types";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import RectangleButton from "../../Buttons/RectangleButton";
import { DropdownMenu, DropdownMenuItem } from "../../DropdownMenu";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import SwitchToggleItem from "../../SwitchToggleItem";
import CustomTextField from "../../CustomTextField";

export type ProfileSettingsDropdownProps = {};

const ProfileSettingsDropdown = (props: ProfileSettingsDropdownProps) => {
  const { theme } = useTheme();
  const { accountData } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [profilePassword, setProfilePassword] = useState("");
  const [passwordProtectModal, setPasswordProtectModal] = useState(false);

  const styles = {
    sectionContainer: {
      padding: 14,
      borderRadius: 12,
      backgroundColor: Colors.GRAY6,
    },
  };

  useEffect(() => {
    if (passwordProtectModal) {
      setProfilePassword(accountData.profilePasswordProtect);
    }
  }, [accountData.profilePasswordProtect, passwordProtectModal]);

  const disableSetPassword = useMemo(
    () => profilePassword === accountData.profilePasswordProtect,
    [accountData.profilePasswordProtect, profilePassword]
  );

  const changeRequirePassword = useCallback(() => {
    dispatch(changeProfilePasswordProtect(accountData.uid, profilePassword));
    showNotificationBanner(
      dispatch,
      "Password Changed!",
      NotificationType.AFFIRMATIVE
    );
    setPasswordProtectModal(false);
  }, [accountData.uid, dispatch, profilePassword]);

  const togglePasswordProtectProfile = useCallback(() => {
    const password = !profilePassword ? "Password" : "";
    setProfilePassword(password);
  }, [profilePassword]);

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const menuItems: DropdownMenuItem[] = [
      {
        title: "Password Protect Profile",
        icon: "ion:key",
        key: "password_protect",
        onPress: () => setPasswordProtectModal(true),
      },
    ];

    return menuItems;
  }, []);

  return (
    <>
      <div
        className="Centering"
        style={{
          cursor: "pointer",
          width: 46,
          height: 46,
          borderRadius: 12,
          border: `0.5px solid ${Colors.GRAY2}`,
          backgroundColor: Colors.WHITE1,
        }}
      >
        <DropdownMenu
          trigger={["click"]}
          placement="bottomRight"
          altWidth={200}
          dropdownItems={dropdownItems}
        >
          <Icon icon="ion:ellipsis-horizontal" height={21} />
        </DropdownMenu>
      </div>
      {passwordProtectModal ? (
        <PopupModalContainer
          headerComp={
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <Icon icon={"ion:key"} height={35} />
              <div className="ColumnNormal" style={{ gap: 7 }}>
                <span className="sectionTitle">Password Protect Profile</span>
                <span className="smallBodySubtext" style={{ fontWeight: 400 }}>
                  Require a password to view events and links on your profile.
                </span>
              </div>
            </div>
          }
          valueComp={
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <StandardBorderedContainer
                containerStyles={{
                  ...styles.sectionContainer,
                  paddingInline: 0,
                  width: "100%",
                }}
              >
                <div
                  className="ColumnNormal"
                  style={{ gap: 14, paddingInline: 14 }}
                >
                  <SwitchToggleItem
                    title="Set Password"
                    toggleValue={profilePassword !== ""}
                    onChange={togglePasswordProtectProfile}
                  />
                  {profilePassword !== "" ? (
                    <CustomTextField
                      value={profilePassword}
                      placeholder="password"
                      inputMode="text"
                      borderRadius={12}
                      noAutocomplete
                      backgroundColor={Colors.WHITE}
                      onChange={(change: any) => {
                        setProfilePassword(change.target.value);
                      }}
                      altMarginBottom={0}
                      altPadding="10px 10px"
                    />
                  ) : null}
                </div>
              </StandardBorderedContainer>
              <RectangleButton
                buttonLabel="Save"
                onPress={changeRequirePassword}
                altColor={Colors.BLACK}
                altTextColor={Colors.WHITE}
                altPaddingVert={16}
                containerStyles={{
                  pointerEvents: disableSetPassword ? "none" : "all",
                  opacity: disableSetPassword ? 0.3 : 1,
                }}
              />
            </div>
          }
          theme={theme}
          noExit
          closeOnOutsidePress
          closeModal={() => {
            setPasswordProtectModal(false);
            setProfilePassword("");
          }}
          modalContentWidth={PopupModalContainerWidth.SMALL}
        />
      ) : null}
    </>
  );
};

export default ProfileSettingsDropdown;
