import "../css/GlobalStyles.css";
import { memo, useCallback, useContext } from "react";
import { isDesktop } from "react-device-detect";
import { useSelector } from "react-redux";
import { LoginState, getAccountState } from "../redux/slices/accountSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { MixpanelContext } from "../context/AnalyticsService";
import { DarkTheme, ThemeStyle } from "../hooks/useTheme";
import MarkitFullLogoBlack from "../../src/assets/MarkitFullLogoBlack.png";
import MarkitFullLogoWhite from "../../src/assets/MarkitFullLogoWhite.png";
import MarkitLogoBlack from "../../src/assets/MarkitLogoBlack.png";
import MarkitLogoWhite from "../../src/assets/MarkitLogoWhite.png";

type MarkitHeaderLogoProps = {
  fullLogo?: boolean;
  theme?: ThemeStyle;
  alternateLogo?: string;
  disabled?: boolean;
};

const MarkitHeaderLogo = memo(function MarkitHeaderLogoFn(
  props: MarkitHeaderLogoProps
) {
  const { fullLogo, theme, alternateLogo, disabled } = props;
  const mixpanel = useContext(MixpanelContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { account } = useSelector(getAccountState);
  const { loggedIn, accountData } = account;

  const onMarkitClick = useCallback(() => {
    if (mixpanel) {
      mixpanel.track("Webapp: Pressed Header Markit Button", {
        pathname: location.pathname,
      });
    }
    if (loggedIn === LoginState.LOGGED_IN && accountData.inCreatorMode) {
      navigate("/home");
    } else {
      navigate("/");
    }
  }, [
    accountData.inCreatorMode,
    location.pathname,
    loggedIn,
    mixpanel,
    navigate,
  ]);

  return (
    <div
      className="RowNormal"
      onClick={onMarkitClick}
      style={{
        gap: 10,
        alignItems: "flex-start",
        cursor: "pointer",
        pointerEvents: disabled ? "none" : "all",
      }}
    >
      {isDesktop || fullLogo ? (
        <img
          style={{ height: 20, width: "auto" }}
          alt="MarkitFullIcon"
          src={
            alternateLogo ||
            (theme && theme === DarkTheme
              ? MarkitFullLogoWhite
              : MarkitFullLogoBlack)
          }
          onClick={onMarkitClick}
        />
      ) : (
        <img
          style={{ height: 24, width: "auto" }}
          alt="MarkitAppIcon"
          src={
            alternateLogo ||
            (theme && theme === DarkTheme ? MarkitLogoWhite : MarkitLogoBlack)
          }
        />
      )}
    </div>
  );
});

export default MarkitHeaderLogo;
