import { ThemeType } from "@markit/common.types";
import { Colors } from "../utils/colors";
import {
  useEventSelector,
  useParametricSelector,
} from "./useParametricSelector";
import { getLoadedEvent } from "../redux/slices/dataSlice";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export interface ThemeStyle {
  [key: string]: any;
}

export const LightTheme: ThemeStyle = {
  PrimaryBG: { backgroundColor: Colors.GRAY6 },
  NewPrimaryBG: { backgroundColor: Colors.WHITE1 },
  SecondaryBG: { backgroundColor: Colors.WHITE },
  TertiaryBG: { backgroundColor: Colors.GRAY6 },
  GradientBG: { backgroundImage: "linear-gradient(white, 5%, #f4f5f6)" },
  SubGradientBG: {
    background: "linear-gradient(white, 10%, #b9b9b9, #f7f7f7, #b9b9b9)",
  },
  TransparentBG: { backgroundColor: "rgba(244, 245, 246, 0.8)" },
  SubComponentBG: { backgroundColor: Colors.WHITE },
  EmptyBG: { backgroundColor: Colors.GRAY11 },
  LinkDropdownBG: { backgroundColor: Colors.WHITE },
  HoverBG: { backgroundColor: "" },
  HoverSelectBG: { backgroundColor: "" },
  SignInBG: { backgroundColor: Colors.GRAY11 },
  TabRowBottom: { borderBottom: "2px solid #f4f5f6" },
  TabRowActiveBottom: { borderBottom: "2px solid #000" },
  TextFieldBG: { backgroundColor: "#EEEEF0" },
  ExpandBG: {
    backgroundImage: "linear-gradient(rgba(255, 255, 255, 0), 20%, white)",
  },
  DividerColor: { borderColor: Colors.GRAY11 },
  SubDividerColor: { borderColor: Colors.GRAY2 },
  LinkColor: { color: Colors.BLACK1 },
  ButtonBG: { backgroundColor: Colors.BLACK },
  ButtonSecondaryBG: { backgroundColor: Colors.GRAY7 },
  ButtonText: { color: Colors.WHITE },
  DisabledButtonBG: { backgroundColor: Colors.GRAY6 },
  DisabledButtonText: { color: Colors.GRAY2 },
  PrimaryText: { color: Colors.BLACK },
  SubText: { color: Colors.GRAY1 },
  SmallSubText: { color: Colors.BLACK },
  LabelText: { color: Colors.GRAY2 },
  CheckboxText: { color: "#383838" },
  ShareText: { color: Colors.GRAY1 },
  ShareIcon: { color: Colors.GRAY2 },
  BannerBorder: {
    borderTop: "1px solid #B9B9B9",
    borderLeft: "1px solid #B9B9B9",
    borderRight: "1px solid #B9B9B9",
  },
  TextFieldBorder: { borderColor: Colors.GRAY11 },
  EditableTextFieldBorder: { borderColor: Colors.GRAY2 },
  FocusedTextFieldBorder: { borderColor: Colors.BLACK },
  DisabledTextFieldText: { color: Colors.GRAY2 },
  ErrorBorderColor: { borderColor: Colors.RED2 },
  PicBorder: { borderColor: Colors.WHITE },
  StripeTheme: { theme: "stripe" },
  IdName: { id: "LightName", idVerified: "LightVerifiedName" },
  IdNumber: {
    id: "LightNumber",
    idVerifying: "LightVerifyingNumber",
    idVerified: "LightVerifiedNumber",
  },
};

export const DarkTheme: ThemeStyle = {
  PrimaryBG: { backgroundColor: Colors.BLACK },
  NewPrimaryBG: { backgroundColor: Colors.GRAY10 },
  SecondaryBG: { backgroundColor: Colors.GRAY10 },
  TertiaryBG: { backgroundColor: Colors.GRAY7 },
  GradientBG: { backgroundColor: Colors.BLACK },
  SubGradientBG: { background: Colors.BLACK },
  TransparentBG: { backgroundColor: "rgba(0, 0, 0, 0.8)" },
  SubComponentBG: { backgroundColor: Colors.BLACK },
  EmptyBG: { backgroundColor: Colors.GRAY3 },
  LinkDropdownBG: { backgroundColor: Colors.GRAY7 },
  HoverBG: { backgroundColor: Colors.GRAY10 },
  HoverSelectBG: { backgroundColor: Colors.BLACK },
  SignInBG: { backgroundColor: Colors.GRAY10 },
  TabRowBottom: { borderBottom: "2px solid #1C1C1D" },
  TabRowActiveBottom: { borderBottom: "2px solid #FFF" },
  TextFieldBG: { backgroundColor: Colors.GRAY7 },
  ExpandBG: {
    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0), 20%, #1C1C1D)",
  },
  DividerColor: { borderColor: Colors.BLACK1 },
  SubDividerColor: { borderColor: Colors.GRAY3 },
  LinkColor: { color: Colors.WHITE },
  ButtonBG: { backgroundColor: Colors.WHITE },
  ButtonText: { color: Colors.BLACK },
  DisabledButtonBG: { backgroundColor: Colors.GRAY7 },
  DisabledButtonText: { color: Colors.GRAY3 },
  PrimaryText: { color: Colors.WHITE },
  SubText: { color: Colors.GRAY3 },
  SmallSubText: { color: Colors.GRAY1 },
  LabelText: { color: Colors.GRAY2 },
  CheckboxText: { color: Colors.WHITE },
  ShareText: { color: Colors.GRAY2 },
  ShareIcon: { color: Colors.GRAY2 },
  BannerBorder: {
    borderTop: "1px solid #3D3D3D",
    borderLeft: "1px solid #3D3D3D",
    borderRight: "1px solid #3D3D3D",
  },
  TextFieldBorder: { borderColor: Colors.BLACK1 },
  EditableTextFieldBorder: { borderColor: Colors.GRAY3 },
  FocusedTextFieldBorder: { borderColor: Colors.WHITE },
  DisabledTextFieldText: { color: Colors.GRAY3 },
  ErrorBorderColor: { borderColor: Colors.RED2 },
  PicBorder: { borderColor: Colors.GRAY7 },
  StripeTheme: { theme: "night" },
  IdName: { id: "DarkName", idVerified: "DarkVerifiedName" },
  IdNumber: {
    id: "DarkNumber",
    idVerifying: "DarkVerifyingNumber",
    idVerified: "DarkVerifiedNumber",
  },
};

export const useTheme = (
  hardTheme?: number,
  forceLight?: boolean,
  forceDark?: boolean
) => {
  const location = useLocation();
  const [theme, setTheme] = useState<ThemeStyle>(LightTheme);

  // Find the event (theme is currently only for event's right now)
  const eventId = useEventSelector();
  const { data: event } = useParametricSelector(getLoadedEvent, eventId);

  // Set the theme style
  useEffect(() => {
    if (forceLight) {
      setTheme(LightTheme);
      return;
    } else if (forceDark) {
      setTheme(DarkTheme);
      return;
    }
    // if you need to implement theme in non-event pages, or edit event page
    if (hardTheme !== undefined) {
      setTheme(hardTheme === ThemeType.Dark ? DarkTheme : LightTheme);
      return;
    }

    if (
      location.pathname.includes("/dashboard") ||
      !event ||
      !location.pathname.includes("/e/")
    ) {
      setTheme(LightTheme);
    } else {
      setTheme(event.theme === ThemeType.Dark ? DarkTheme : LightTheme);
    }
  }, [event, forceDark, forceLight, hardTheme, location.pathname]);

  // changes the top header on ios on the searchbar
  if (
    event &&
    event.theme === ThemeType.Dark &&
    !location.pathname.includes("/dashboard")
  ) {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", "#000000");
    }
  }

  return { theme };
};
