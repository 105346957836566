import { memo, useCallback, useMemo } from "react";
import {
  calculateTextMessageCreditsEstimate,
  complianceMessage,
  handlePluralString,
  hasSubscription,
  MARKIT_TEXTING_CREDITS,
  mostRecentSubscription,
  SUBSCRIBED_CAMPAIGN_NAME,
} from "@markit/common.utils";
import PopupModalContainer from "../../../Containers/PopupModalContainer";
import { Icon } from "@iconify/react";
import RectangleButton from "../../../Buttons/RectangleButton";
import { Colors } from "../../../../utils/colors";
import TextCreditsNotice from "./TextCreditsNotice";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { fetchNumFollowersNoComplianceSent } from "../../../../utils/userUtils";
import { Event, MassText } from "@markit/common.types";
import { getCampaignState } from "../../../../redux/slices/campaignSlice";

type ConfirmSendTextModalProps = {
  setModalVisible: (modalVisible: boolean) => void;
  confirmOnPress: () => void;
  isTriggerText: boolean;
  isScheduled: boolean;
  numCredits: number;
  foundEventText: Event | undefined;
  massText: MassText;
  recipients: string[];
  isSending: boolean;
  showCategories: boolean;
};

export const ConfirmSendTextModal = memo(function ConfirmSendTextModalFn(
  props: ConfirmSendTextModalProps
) {
  const {
    setModalVisible,
    confirmOnPress,
    isTriggerText,
    isScheduled,
    numCredits,
    massText,
    foundEventText,
    recipients,
    isSending,
    showCategories,
  } = props;
  const { followersData, accountData } = useSelector(getAccountState).account;
  const { campaigns } = useSelector(getCampaignState);

  const showCategoryView = useMemo(
    () => showCategories && isScheduled,
    [isScheduled, showCategories]
  );

  const isClickedSubscribeText = useMemo(() => {
    const foundCampaign = campaigns.find(
      (campaign) => campaign.initialTextId === massText.id
    );
    return foundCampaign
      ? foundCampaign.title === SUBSCRIBED_CAMPAIGN_NAME
      : false;
  }, [campaigns, massText.id]);

  const numNonCompliantRecipients = useMemo(
    () =>
      fetchNumFollowersNoComplianceSent(accountData, followersData, recipients),
    [accountData, followersData, recipients]
  );

  const numCompliantRecipients = useMemo(
    () => recipients.length - numNonCompliantRecipients,
    [recipients.length, numNonCompliantRecipients]
  );

  const numComplianceCredits = useMemo(
    () =>
      calculateTextMessageCreditsEstimate(
        massText.message +
          complianceMessage(accountData.fullName, hasSubscription(accountData)),
        massText.mediaUrl,
        foundEventText
      ),
    [accountData, foundEventText, massText.mediaUrl, massText.message]
  );

  // The total number of credits factors in the compliance credits if the user is sending to non-compliance recipients or it's the clicked subscribe text
  const totalNumCredits = useMemo(
    () =>
      (numNonCompliantRecipients > 0 && numCredits !== numComplianceCredits) ||
      isClickedSubscribeText
        ? numCredits * numCompliantRecipients +
          numComplianceCredits *
            (isClickedSubscribeText ? 1 : numNonCompliantRecipients)
        : numCredits * recipients.length,
    [
      isClickedSubscribeText,
      numCredits,
      numCompliantRecipients,
      numComplianceCredits,
      numNonCompliantRecipients,
      recipients.length,
    ]
  );

  // Check if sending total num credits for trial subscription will exceed limit
  const exceedsTrialMessageLimit = useMemo(() => {
    const mostRecentSub = mostRecentSubscription(accountData);
    if (mostRecentSub) {
      const mostRecentLimit = mostRecentSub.price.find(
        (p) => p.type === "baseline"
      )?.limit;
      if (
        accountData.customer.state === "TRIAL" &&
        mostRecentLimit &&
        mostRecentSub.message + totalNumCredits > mostRecentLimit
      ) {
        return true;
      }
    }
    return false;
  }, [accountData, totalNumCredits]);

  const textingCreditsOnPress = useCallback(() => {
    window.open(MARKIT_TEXTING_CREDITS);
  }, []);

  return (
    <PopupModalContainer
      headerComp={
        <div className="ColumnNormal" style={{ gap: 14, fontWeight: 400 }}>
          <Icon
            icon={
              !isTriggerText && isScheduled ? "ion:timer" : "ion:paper-plane"
            }
            height={35}
          />
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="sectionTitle">
              {exceedsTrialMessageLimit
                ? "This text will exceed your free trial credits"
                : isTriggerText
                ? "Save Trigger?"
                : isScheduled
                ? "Ready to Schedule?"
                : "Ready to Send?"}
            </span>
            {exceedsTrialMessageLimit ? (
              <span className="smallBodySubtext">
                You will immediately be charged for the price of your
                subscription plan with the card on file.
              </span>
            ) : recipients.length > 0 && !showCategoryView ? (
              <span className="smallBodySubtext">
                This text will send to {recipients.length}{" "}
                {handlePluralString("recipient", recipients.length)}
                {massText.mediaUrl ? " with media pricing applied" : ""}. This
                cannot be undone.
              </span>
            ) : null}
          </div>
          <TextCreditsNotice numCredits={numCredits} />
          <div className="ColumnNormal" style={{ gap: 4 }}>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <Icon icon={"ion:prism"} height={15} />
              <span className="bodyMedium">
                {showCategoryView || isTriggerText
                  ? `This text will use ${
                      isClickedSubscribeText ? totalNumCredits : numCredits
                    } ${handlePluralString(
                      "credit",
                      isClickedSubscribeText ? totalNumCredits : numCredits
                    )} per recipient`
                  : `Sending this text will use ${totalNumCredits} ${handlePluralString(
                      "credit",
                      totalNumCredits
                    )}`}
              </span>
            </div>
            {isTriggerText ? (
              <span className="smallBodySubtext">
                The final credit usage of this text will depend on the total
                number of recipients.
              </span>
            ) : showCategoryView ? (
              <span className="smallBodySubtext">
                The total credit number may fluctuate based on the total number
                of recipients at time of send.
              </span>
            ) : (
              <>
                {numCompliantRecipients ? (
                  <span className="smallBodySubtext">
                    {numCredits} {handlePluralString("credit", numCredits)} per
                    text for {numCompliantRecipients}{" "}
                    {handlePluralString("recipient", numCompliantRecipients)}
                  </span>
                ) : null}
                {numNonCompliantRecipients > 0 ? (
                  <span className="smallBodySubtext">
                    {numComplianceCredits}{" "}
                    {handlePluralString("credit", numCredits)} per text for{" "}
                    {numNonCompliantRecipients}{" "}
                    {handlePluralString("recipient", numNonCompliantRecipients)}{" "}
                    that require compliance message
                  </span>
                ) : null}
              </>
            )}
            <div
              onClick={textingCreditsOnPress}
              className="AlignedRowSelect"
              style={{ gap: 2 }}
            >
              <span style={{ fontSize: 12, color: Colors.BLUE5 }}>
                Learn more about credit usage
              </span>
              <Icon
                icon="ion:chevron-right"
                height={12}
                style={{ color: Colors.BLUE5, marginBottom: -2 }}
              />
            </div>
          </div>
        </div>
      }
      valueComp={
        <div className="AlignedRow" style={{ gap: 10 }}>
          <RectangleButton
            buttonLabel="Cancel"
            onPress={() => setModalVisible(false)}
            altTextColor={Colors.BLACK}
            altColor={Colors.GRAY6}
            altPaddingVert={14}
          />
          <RectangleButton
            buttonLabel={
              isTriggerText ? "Save" : isScheduled ? "Schedule" : "Send Text"
            }
            onPress={confirmOnPress}
            altTextColor={Colors.WHITE}
            altColor={Colors.BLACK}
            altPaddingVert={14}
            loading={isSending}
          />
        </div>
      }
      noExit
      closeOnOutsidePress
      closeModal={() => setModalVisible(false)}
    />
  );
});
