import { Route, Routes } from "react-router-dom";
import { memo } from "react";
import ProfilePage from "../screens/Profile/SubscriberProfile/ProfilePage";
import ProfilePageSubscribe from "../screens/Profile/SubscriberProfile/ProfilePageSubscribe";
import ProfilePageSubscribeManage from "../screens/Profile/SubscriberProfile/ProfilePageSubscribeManage";

const ProfileRoute = memo(function ProfileRouteFn() {
  return (
    <Routes>
      <Route index element={<ProfilePage />} />
      <Route path="subscribe" element={<ProfilePageSubscribe />} />
      <Route path="upgrade" element={<ProfilePageSubscribe />} />
      <Route
        path="manageSubscription"
        element={<ProfilePageSubscribeManage />}
      />
    </Routes>
  );
});

export default ProfileRoute;
