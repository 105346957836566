import "../../css/EmptyStates/EmptyUserState.css";
import { Colors } from "../../utils/colors";
import TopHeader from "../TopHeader";
import Footer from "../Footer";
import { Icon } from "@iconify/react";

type BillingHistoryMobileProps = {};

const BillingHistoryMobile = (props: BillingHistoryMobileProps) => {
  return (
    <div className="WebApp" style={{ transition: "0.5s" }}>
      <div style={{ paddingBottom: 12 }}>
        <TopHeader />
        <div
          className="ColumnNormal"
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 14,
            height: 580,
            marginInline: 10,
            backgroundColor: Colors.WHITE,
          }}
        >
          <div
            className="ColumnNormal"
            style={{ justifyContent: "center", alignItems: "center", gap: 20 }}
          >
            <div
              className="Centering"
              style={{
                backgroundColor: Colors.WHITE1,
                width: 70,
                height: 70,
                borderRadius: 15,
              }}
            >
              <Icon
                icon="ion:receipt"
                height={34}
                style={{ color: Colors.GRAY1 }}
              />
            </div>
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <span
                style={{ textAlign: "center", fontWeight: "500", fontSize: 14 }}
              >
                Billing History Available on Desktop
              </span>
              <span
                style={{
                  textAlign: "center",
                  marginInline: 60,
                  fontSize: 12,
                  color: Colors.GRAY2,
                }}
              >
                Log into Markit on a desktop device to view billing history.
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BillingHistoryMobile;
