import { useCallback, useState } from "react";
import { filterUndefinedValues, uniqueVals } from "@markit/common.utils";
import { AccountData } from "@markit/common.types";
import { fetchSingleUser } from "../utils/FetchSingleData";
import { algoliaClient } from "../utils/algoliaUtils";
import { accountActions, getAccountState } from "../redux/slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import useOnUnmount from "./useOnUnmount";

type useLoadAudienceListMembersProps = {
  userIdList: string[];
  windowSize: number;
};

// Very similar to useLoadUserList but doesn't need to fetch the follower data
export const useLoadAudienceListMembers = (
  props: useLoadAudienceListMembersProps
) => {
  const { userIdList, windowSize } = props;

  const { followingAccountData } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [fetchedUserData, setFetchedUserData] = useState<AccountData[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  useOnUnmount(
    (latestDeps) => {
      dispatch(
        accountActions.addMultipleFollowingAccountData(
          latestDeps[0] as AccountData[]
        )
      );
    },
    [fetchedUserData]
  );

  const addUserData = useCallback((newUserData: AccountData[]) => {
    setFetchedUserData((fetchedUserData) =>
      uniqueVals(
        fetchedUserData.concat(newUserData),
        (userData) => userData.uid
      )
    );
  }, []);

  const removeUserData = useCallback((userDataUid: string) => {
    setFetchedUserData((fetchedUserData) => {
      const index = fetchedUserData.findIndex(
        (fetched) => fetched.uid === userDataUid
      );
      if (index !== -1) {
        fetchedUserData.splice(index, 1);
      }
      return [...fetchedUserData];
    });
  }, []);

  const checkIfAddUserData = useCallback(
    async (userIds: string[]) => {
      const users = await Promise.all(
        userIds.map(
          async (userId) => await fetchSingleUser(userId, followingAccountData)
        )
      );
      const definedUsers = filterUndefinedValues(users);
      addUserData(definedUsers);
    },
    [followingAccountData, addUserData]
  );

  const loadAndStoreUsers = useCallback(async () => {
    setIsLoading(true);
    const usersToFetch = userIdList.slice(
      0,
      fetchedUserData.length + windowSize
    );
    await checkIfAddUserData(usersToFetch);
    if (usersToFetch.length < windowSize) {
      setIsFinished(true);
    }

    setIsLoading(false);
  }, [checkIfAddUserData, fetchedUserData, userIdList, windowSize]);

  const loadMoreUsers = useCallback(async () => {
    if (userIdList.length === 0) return;
    if (!isFinished && !isLoading) {
      loadAndStoreUsers();
    }
  }, [userIdList.length, isFinished, isLoading, loadAndStoreUsers]);

  // When the users displayed need to be manually refetched due to a state change of userIdList
  const reloadMoreUsers = useCallback(() => {
    setIsFinished(false);
    setFetchedUserData([]);
  }, []);

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      setIsLoading(true);
      if (!isFinished) {
        const results = (
          await algoliaClient.searchSingleIndex({
            indexName: "users",
            searchParams: { query: searchTerm },
          })
        ).hits;

        const userIds = results
          .map((obj: any) => obj.objectID)
          .filter(
            (uid: string) =>
              userIdList.includes(uid) &&
              !fetchedUserData.some((userData) => userData.uid === uid)
          );
        await checkIfAddUserData(userIds);
      }
      setIsLoading(false);
    },
    [isFinished, userIdList, fetchedUserData, checkIfAddUserData]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          loadSearchResults(searchTerm);
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );

  return {
    isFinished,
    isLoading,
    fetchedUserData,
    loadMoreUsers,
    reloadMoreUsers,
    loadSearchResultsThrottled,
    removeUserData,
  };
};
