import "../../css/ProfilePage/ProfilePage.css";
import { memo, useCallback, useMemo, useState } from "react";
import { AccountData, Event, NotificationType } from "@markit/common.types";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import FlatList from "flatlist-react/lib";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import EmptyStateButton from "../Buttons/EmptyStateButton";
import { datesAreOnSameDay, formatDate } from "@markit/common.utils";
import ProfileEventTimelineCardItem from "../DisplayItem/ProfilePage/ProfileEventTimelineCardItem";
import ProfileEventTimelineGridItem from "../DisplayItem/ProfilePage/ProfileEventTimelineGridItem";
import {
  ProfileDisplayView,
  ProfileTimelineState,
} from "./ProfilePageTimelineHeader";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { getEventDisplayedOrganizers } from "../../utils/eventUtils/eventUtils";
import { useNavigate } from "../../hooks/useNavigate";
import CustomTextField from "../CustomTextField";
import { isDesktop } from "react-device-detect";
import { showNotificationBanner } from "../../utils/notificationUtils";

type ProfilePageTimelineProps = {
  displayView: ProfileDisplayView;
  timelineState: ProfileTimelineState;
  userItemsToShow: Event[];
  eventDates: string[];
  eventOrganizers: AccountData[];
  userData: AccountData | undefined;
  showMoreEvents: boolean;
  setShowMoreEvents: (showMoreEvents: boolean) => void;
  isLinksTab: boolean;
};

const ProfilePageTimeline = memo(function ProfilePageTimelineFn(
  props: ProfilePageTimelineProps
) {
  const {
    displayView,
    timelineState,
    userItemsToShow,
    eventDates,
    eventOrganizers,
    userData,
    showMoreEvents,
    setShowMoreEvents,
    isLinksTab,
  } = props;
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profilePassword, setProfilePassword] = useState("");

  const isCardDisplayView = useMemo(
    () => displayView === ProfileDisplayView.CARD_VIEW,
    [displayView]
  );

  // Truncates the amount of events to show when show more is false
  // Need this to identify if there are more than three events showing based by event dates
  const validUserEventsToShow = useMemo(() => {
    const foundOverflowEvent =
      userItemsToShow.length > 3 && !showMoreEvents
        ? userItemsToShow[3]
        : undefined;
    const index = userItemsToShow.findIndex(
      (event) => foundOverflowEvent && event.id === foundOverflowEvent.id
    );
    if (index !== -1) {
      return userItemsToShow.slice(0, index);
    }
    return userItemsToShow;
  }, [showMoreEvents, userItemsToShow]);

  const styles = {
    specialDate: {
      fontSize: isCardDisplayView ? 14 : 16,
      fontWeight: "500",
    },
    timelineDot: {
      zIndex: 2,
      backgroundColor: Colors.WHITE1,
    },
    floatingPasswordInput: {
      top: 40,
      right: 0,
      bottom: 0,
      left: 0,
      gap: 7,
      justifyContent: "center",
      backgroundColor: Colors.WHITE,
      padding: 14,
      borderRadius: 20,
      zIndex: 1,
      boxShadow: "0px 0.5px 1px 0px #b9b9b9",
      width: isDesktop ? "20vw" : 300,
      minWidth: isDesktop ? 240 : 300,
      height: "fit-content",
      marginInline: "auto",
    },
  };

  const isMyAccount = useMemo(
    () =>
      loggedIn === LoginState.LOGGED_IN && accountData.uid === userData?.uid,
    [accountData.uid, loggedIn, userData?.uid]
  );

  const hidePasswordProfile = useMemo(
    () =>
      !isMyAccount &&
      userData &&
      userData.profilePasswordProtect &&
      profilePassword !== userData.profilePasswordProtect,
    [isMyAccount, userData, profilePassword]
  );

  const tomorrowDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  }, []);

  const getEventItemUrl = useCallback(
    (item: Event) => {
      return `/e/${
        item.id +
        (isMyAccount &&
        userData &&
        (timelineState === ProfileTimelineState.ATTENDING ||
          timelineState === ProfileTimelineState.ATTENDED)
          ? `/i/${userData.uid}`
          : "")
      }`;
    },
    [isMyAccount, timelineState, userData]
  );

  const renderEmptyTimeline = useMemo(
    () => (
      <StandardBorderedContainer
        containerStyles={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 270,
          ...(hidePasswordProfile
            ? { filter: "blur(14px)", pointerEvents: "none" }
            : {}),
        }}
      >
        <EmptyStateButton
          title={
            isLinksTab
              ? "No Links"
              : isMyAccount ||
                timelineState === ProfileTimelineState.UPCOMING ||
                timelineState === ProfileTimelineState.PAST
              ? "No Events"
              : "No Tickets"
          }
          description={
            isMyAccount
              ? timelineState === ProfileTimelineState.UPCOMING
                ? "Events you create will show up here"
                : timelineState === ProfileTimelineState.PAST
                ? "Your past events will show up here"
                : timelineState === ProfileTimelineState.ATTENDING
                ? "Your upcoming tickets will show up here"
                : "Your past tickets will show up here"
              : undefined
          }
          icon={
            <Icon
              icon={isLinksTab ? "ion:link" : "ion:calendar"}
              height={42}
              color={Colors.GRAY1}
            />
          }
          iconBox={63}
          containerStyles={{ paddingTop: 20 }}
          btnText={!isLinksTab && isMyAccount ? "Create Event" : undefined}
          onPress={() => navigate("/create")}
        />
      </StandardBorderedContainer>
    ),
    [hidePasswordProfile, isLinksTab, isMyAccount, navigate, timelineState]
  );

  const renderDateDisplay = useCallback(
    (date: string) => {
      const dateObject = new Date(date);
      return (
        <div className="AlignedRow" style={{ gap: 7 }}>
          {datesAreOnSameDay(new Date(), dateObject) ? (
            <span style={styles.specialDate}>Today</span>
          ) : datesAreOnSameDay(tomorrowDate, dateObject) ? (
            <span style={styles.specialDate}>Tomorrow</span>
          ) : null}
          <span style={{ fontSize: isCardDisplayView ? 14 : 16 }}>
            {formatDate(
              dateObject,
              false,
              true,
              new Date().getFullYear() !== dateObject.getFullYear()
            )}
          </span>
        </div>
      );
    },
    [isCardDisplayView, styles.specialDate, tomorrowDate]
  );

  const ProfilePageTimelineLinks = useMemo(
    () => (
      <FlatList
        list={validUserEventsToShow}
        keyExtractor={(link: Event) => link.id}
        renderItem={(link: Event) => (
          <div key={link.id} style={{ paddingBottom: 10 }}>
            {isCardDisplayView ? (
              <ProfileEventTimelineCardItem
                event={link}
                organizers={[]}
                url={getEventItemUrl(link)}
                isMyAccount={isMyAccount}
              />
            ) : (
              <ProfileEventTimelineGridItem
                event={link}
                organizers={[]}
                url={getEventItemUrl(link)}
                isMyAccount={isMyAccount}
              />
            )}
          </div>
        )}
        renderWhenEmpty={() => renderEmptyTimeline}
      />
    ),
    [
      getEventItemUrl,
      isCardDisplayView,
      isMyAccount,
      renderEmptyTimeline,
      validUserEventsToShow,
    ]
  );

  return (
    <div
      className="HideScrollbar"
      style={{ position: "relative", minHeight: 300 }}
    >
      {hidePasswordProfile ? (
        <div
          className="ColumnNormal"
          style={{ position: "absolute", ...styles.floatingPasswordInput }}
        >
          <span className="bodyMedium">Enter password to view content</span>
          <CustomTextField
            value={profilePassword}
            placeholder="Password"
            inputMode="text"
            borderRadius={12}
            noAutocomplete
            backgroundColor={Colors.WHITE}
            onChange={(change: any) => {
              const password = change.target.value;
              if (userData && password === userData.profilePasswordProtect) {
                showNotificationBanner(
                  dispatch,
                  "Profile Unlocked!",
                  NotificationType.AFFIRMATIVE
                );
              }
              setProfilePassword(password);
            }}
            altMarginBottom={0}
            altPadding="14px 14px"
          />
        </div>
      ) : null}
      {userItemsToShow.length > 0 ? (
        <div
          style={
            hidePasswordProfile
              ? { filter: "blur(14px)", pointerEvents: "none" }
              : {}
          }
        >
          {isLinksTab ? (
            ProfilePageTimelineLinks
          ) : (
            <FlatList
              list={eventDates}
              keyExtractor={(date: string) => date}
              renderItem={(date) => {
                const foundEvents = validUserEventsToShow.filter((event) =>
                  datesAreOnSameDay(new Date(event.start), new Date(date))
                );
                const isLastDate = eventDates[eventDates.length - 1] === date;
                const isFilledIn =
                  eventDates[0] === date ||
                  timelineState === ProfileTimelineState.PAST ||
                  timelineState === ProfileTimelineState.ATTENDED;
                return foundEvents.length > 0 ? (
                  <div style={{ position: "relative", paddingBottom: 10 }}>
                    {isCardDisplayView ? (
                      <div
                        style={{
                          position: "absolute",
                          left: 6,
                          top: 15,
                          borderRadius: 8,
                          height:
                            validUserEventsToShow.length === 1 ? "90%" : "100%",
                          width: 1,
                          zIndex: 1,
                          background: isLastDate
                            ? "linear-gradient(to bottom, #929292, rgba(0, 0, 0, 0))"
                            : Colors.GRAY1,
                        }}
                      />
                    ) : null}
                    <div
                      className="ColumnNormal"
                      style={{
                        gap: 10,
                        paddingLeft: isCardDisplayView ? 24 : 0,
                      }}
                    >
                      {!isLinksTab ? (
                        <div className="AlignedRow">
                          {isCardDisplayView ? (
                            isFilledIn ? (
                              <Icon
                                icon={"ion:radio-button-on"}
                                height={16}
                                color={Colors.BLACK}
                                style={{
                                  ...styles.timelineDot,
                                  position: "absolute",
                                  left: -1,
                                  top: 10,
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  ...styles.timelineDot,
                                  position: "absolute",
                                  left: 2,
                                  top: 12.5,
                                  width: 7,
                                  height: 7,
                                  border: "1px solid #929292",
                                  borderRadius: 10,
                                }}
                              />
                            )
                          ) : null}
                          {isCardDisplayView ? (
                            <StandardBorderedContainer
                              containerStyles={{
                                borderRadius: 100,
                                paddingInline: 14,
                                paddingBlock: 8,
                                display: "flex",
                              }}
                            >
                              {renderDateDisplay(date)}
                            </StandardBorderedContainer>
                          ) : (
                            <div
                              className="ColumnNormal"
                              style={{ gap: 10, width: "100%" }}
                            >
                              {renderDateDisplay(date)}
                              <HorizontalDivider altMargin={0} />
                            </div>
                          )}
                        </div>
                      ) : null}
                      {foundEvents.map((event) => {
                        const foundOrganizers = event.cohosts.concat(
                          event.createdBy
                        );
                        const finalOrganizers = getEventDisplayedOrganizers(
                          event.createdBy,
                          eventOrganizers.filter((organizer) =>
                            foundOrganizers.includes(organizer.uid)
                          )
                        );
                        return (
                          <div key={event.id}>
                            {isCardDisplayView ? (
                              <ProfileEventTimelineCardItem
                                event={event}
                                organizers={finalOrganizers}
                                url={getEventItemUrl(event)}
                                isMyAccount={isMyAccount}
                              />
                            ) : (
                              <ProfileEventTimelineGridItem
                                event={event}
                                organizers={finalOrganizers}
                                url={getEventItemUrl(event)}
                                isMyAccount={isMyAccount}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null;
              }}
              renderWhenEmpty={() => renderEmptyTimeline}
            />
          )}
          {userItemsToShow.length > 3 && isCardDisplayView ? (
            <StandardBorderedContainer
              containerStyles={{
                marginLeft: !isLinksTab ? 24 : 0,
                borderRadius: 100,
                paddingInline: 14,
                paddingBlock: 8,
                display: "inline-flex",
                cursor: "pointer",
              }}
              onPress={() => setShowMoreEvents(!showMoreEvents)}
            >
              <span style={{ fontSize: 14 }}>
                Show {showMoreEvents ? "Less" : "More"}
              </span>
            </StandardBorderedContainer>
          ) : null}
        </div>
      ) : (
        renderEmptyTimeline
      )}
    </div>
  );
});

export default ProfilePageTimeline;
