import { useCallback, useMemo, useState } from "react";
import { Colors } from "../../../utils/colors";
import CreatorModePanelWrapper from "../../../components/CreatorDashboard/CreatorModePanelWrapper";
import StandardListContainer from "../../../components/Containers/StandardListContainer";
import SearchBoxContainer from "../../../components/Containers/SearchBoxContainer";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { useOnMount } from "../../../hooks/useOnMount";
import { Campaign } from "@markit/common.types";
import filter from "lodash.filter";
import { AutomationItemPreview } from "../../../components/DisplayItem/CampaignItem/AutomationItemPreview";
import {
  NON_SUBSCRIBER_REPLY_CAMPAIGN_NAME,
  SUBSCRIBED_CAMPAIGN_NAME,
} from "@markit/common.utils";
import { HorizontalDivider } from "../../../components/Dividers/HorizontalDivider";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "../../../components/DropdownMenu";
import { Icon } from "@iconify/react";
import { isAutomationActive } from "../../../utils/campaignUtils";
import { getEventState } from "../../../redux/slices/eventSlice";
import { CampaignConfirmDeleteModal } from "../../../components/CreatorDashboard/Campaigns/Items/CampaignConfirmDeleteModal";
import EmptyStateButton from "../../../components/Buttons/EmptyStateButton";
import { EmptyStateFlatlist } from "../../../components/EmptyStates/EmptyStateFlatlist";
import { useLoadAutomationItems } from "../../../hooks/useLoadAutomationItems";
import FlatlistLoadMore from "../../../components/FlatlistLoadMore";

const enum AutomationFilterState {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

const AutomationsPanel = () => {
  const { uid, customer } = useSelector(getAccountState).account.accountData;
  const events = useSelector(getEventState).events.events;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterState, setFilterState] = useState<AutomationFilterState>(
    AutomationFilterState.ACTIVE
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [windowSize, setWindowSize] = useState(15);
  const [showDeleteCampaign, setShowDeleteCampaign] = useState<Campaign>();

  const styles = {
    textingPanelMainContentContainer: {
      backgroundColor: Colors.WHITE,
      paddingInline: 30,
      paddingTop: 30,
      height: "100%",
    },
    filterContainer: {
      gap: 10,
      borderRadius: 8,
      paddingInline: 14,
      paddingBlock: 12,
      backgroundColor: Colors.WHITE1,
      border: `0.8px solid ${Colors.GRAY2}`,
    },
  };

  const {
    fetchedAutomations,
    fetchedAutomationDetails,
    isLoading: isLoadingAutomations,
    loadAutomations,
  } = useLoadAutomationItems({
    userId: uid,
  });

  useOnMount(() => {
    loadAutomations();
  });

  const contains = (campaign: Campaign, query: string) => {
    return campaign.title.toLowerCase().includes(query);
  };

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
  };

  const filteredAutomations = useMemo(() => {
    if (isLoadingAutomations) {
      return [];
    }
    return fetchedAutomations.filter((campaign) => {
      const massTexts =
        fetchedAutomationDetails
          .find((detail) => detail.campaignId === campaign.id)
          ?.massTextDetails.map((text) => text.massText) ?? [];
      const foundEvent = events.find((event) => event.id === campaign.eventId);
      if (massTexts.length > 0) {
        const isFiltered = isAutomationActive(
          campaign.title,
          massTexts,
          foundEvent,
          customer.phone !== ""
        );
        return filterState === AutomationFilterState.ACTIVE
          ? isFiltered
          : !isFiltered;
      }
      return [];
    });
  }, [
    customer.phone,
    events,
    fetchedAutomationDetails,
    fetchedAutomations,
    filterState,
    isLoadingAutomations,
  ]);

  const currentItemsToShow: Campaign[] = useMemo(() => {
    let campaignItems: Campaign[] = filteredAutomations.sort(
      (a: Campaign, b: Campaign) => {
        if (a.title === SUBSCRIBED_CAMPAIGN_NAME) return -1;
        if (b.title === SUBSCRIBED_CAMPAIGN_NAME) return 1;
        if (a.title === NON_SUBSCRIBER_REPLY_CAMPAIGN_NAME) return -1;
        if (b.title === NON_SUBSCRIBER_REPLY_CAMPAIGN_NAME) return 1;
        return 0;
      }
    );
    if (searchTerm !== "") {
      campaignItems = filter(campaignItems, (campaign: Campaign) => {
        return contains(campaign, searchTerm);
      });
    }
    return campaignItems.slice(0, windowSize);
  }, [filteredAutomations, searchTerm, windowSize]);

  const renderCampaignItem = useCallback(
    (item: Campaign) => {
      const foundCampaignDetail = fetchedAutomationDetails.find(
        (detail) => detail.campaignId === item.id
      );
      return (
        <div key={item.id}>
          <AutomationItemPreview
            campaignItem={item}
            campaignDetail={foundCampaignDetail}
            setShowDeleteCampaign={setShowDeleteCampaign}
          />
          {foundCampaignDetail ? <HorizontalDivider altMargin={14} /> : null}
        </div>
      );
    },
    [fetchedAutomationDetails]
  );

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: AutomationFilterState.ACTIVE,
        icon:
          filterState === AutomationFilterState.ACTIVE
            ? "ion:checkmark"
            : undefined,
        key: "active_automations",
        onPress: () => setFilterState(AutomationFilterState.ACTIVE),
      },
      {
        title: AutomationFilterState.INACTIVE,
        icon:
          filterState === AutomationFilterState.INACTIVE
            ? "ion:checkmark"
            : undefined,
        key: "inactive_automations",
        onPress: () => setFilterState(AutomationFilterState.INACTIVE),
      },
    ],
    [filterState]
  );

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoadingAutomations}
        containerStyles={{ paddingTop: 120 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title={"No Results Found"}
            description={"There were no automations within this filter"}
            icon={<Icon icon={"ion:search"} height={60} />}
            iconBox={70}
            containerStyles={{ paddingTop: 120 }}
          />
        }
      />
    );
  }, [isLoadingAutomations, searchTerm]);

  return (
    <CreatorModePanelWrapper title="Automations" headerRight={<></>}>
      <div style={styles.textingPanelMainContentContainer}>
        <StandardListContainer
          searchComp={
            <div
              className="AlignedRowSpaced"
              style={{ width: "100%", paddingBottom: 10 }}
            >
              <div className="AlignedRow" style={{ gap: 14 }}>
                <SearchBoxContainer
                  placeholder={"Search"}
                  onChange={(e) => handleSearch(e.target.value)}
                  containerStyles={{ marginTop: 0, width: 394 }}
                />
                <DropdownMenu
                  dropdownItems={dropdownItems}
                  trigger={["click"]}
                  placement="bottom"
                  onOpenChange={(isOpen: boolean) => setDropdownVisible(isOpen)}
                  altWidth={110}
                >
                  <div
                    className="AlignedRowSelect"
                    style={styles.filterContainer}
                  >
                    <span className="bodyMedium">{filterState}</span>
                    <Icon
                      icon={
                        dropdownVisible ? "mdi-chevron-up" : "mdi:chevron-down"
                      }
                      height={18}
                    />
                  </div>
                </DropdownMenu>
              </div>
            </div>
          }
          listComp={
            <FlatlistLoadMore
              fullList={filteredAutomations}
              currentList={currentItemsToShow}
              renderItem={(item) => renderCampaignItem(item)}
              renderWhenEmpty={renderEmptyView}
              isLoadingMore={false}
              showLoadMore={
                searchTerm.trim() === "" &&
                windowSize < filteredAutomations.length
              }
              loadMoreOnPress={() => setWindowSize(windowSize + 15)}
            />
          }
          isLoading={isLoadingAutomations}
        />
      </div>
      <CampaignConfirmDeleteModal
        campaign={showDeleteCampaign}
        preventDelete={true}
        showDeleteModal={showDeleteCampaign !== undefined}
        setShowDeleteModal={() => setShowDeleteCampaign(undefined)}
      />
    </CreatorModePanelWrapper>
  );
};

export default AutomationsPanel;
