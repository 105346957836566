import { Colors } from "../../../utils/colors";
import StandardBorderedContainer from "../../../components/Containers/StandardBorderedContainer";
import { Icon } from "@iconify/react";
import CreatorModePanelWrapper from "../../../components/CreatorDashboard/CreatorModePanelWrapper";
import CreatorModeOnboardingProgress from "../../../components/CreatorDashboard/CreatorModeOnboardingProgress";
import { useCallback, useMemo, useState } from "react";
import FatHeaderActionButton from "../../../components/Buttons/FatHeaderActionButton";
import WeeklyCommunityUpdatesModal from "../../../components/DisplayModal/WeeklyCommunityUpdatesModal";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { useLocation, useNavigate } from "react-router-dom";
import ShareProfileLinkModal from "../../../components/CreatorDashboard/CreatorProfile/ShareProfileLinkModal";
import { useOnMount } from "../../../hooks/useOnMount";
import MassTextPanel from "../../../components/CreatorDashboard/MassTexts/MassTextPanel";
import {
  MassTextSelectedType,
  MassTextsWrapperType,
} from "../../../components/CreatorDashboard/MassTexts/MassTextWrapperManager";
import { getCampaignState } from "../../../redux/slices/campaignSlice";
import {
  hasSubscription,
  isLockedPresetAutomations,
  NON_SUBSCRIBER_REPLY_CAMPAIGN_ID,
} from "@markit/common.utils";
import { CampaignType } from "@markit/common.types";
import AddSingleContactModal from "../../../components/CreatorDashboard/CreatorProfile/ImportContacts/AddSingleContactModal";
import { onCreatorProfileNavigatePath } from "../../../utils/navigationUtils";
import { checkUpdatedWelcomeFlow } from "../../../utils/userUtils";

const GetStartedOnboardingPanel = () => {
  const { accountData, followersData, savedQuestions } =
    useSelector(getAccountState).account;
  const { campaigns, campaignDetails } = useSelector(getCampaignState);
  const navigate = useNavigate();
  const { showSkipOnboarding } = useLocation().state ?? {};
  const [communityUpdatesVisible, setCommunityUpdatesVisible] = useState(false);
  const [addContactVisible, setAddContactVisible] = useState(false);
  const [shareProfileVisible, setShareProfileVisible] = useState(false);
  const [selectedTextType, setSelectedTextType] =
    useState<MassTextSelectedType>({ massText: undefined, isVisible: false });

  const styles = {
    bodyContainer: {
      backgroundColor: Colors.WHITE1,
      paddingTop: 40,
      height: "100%",
    },
    boxContainer: {
      gap: 10,
      width: 544,
      margin: "auto",
    },
    sectionContainer: {
      backgroundColor: Colors.WHITE,
      borderRadius: 12,
      padding: 14,
      cursor: "pointer",
    },
  };

  useOnMount(() => {
    if (finishedOnboarding) {
      navigate("/home");
    }
  });

  const sentFirstText = useMemo(
    () =>
      campaigns.filter(
        (campaign) =>
          !isLockedPresetAutomations(campaign) &&
          campaign.type === CampaignType.CAMPAIGN
      ).length > 0,
    [campaigns]
  );

  // Returns true if the user has any saved questions or if the subscribe trigger campaign has been updated
  const updatedWelcomeFlow = useMemo(
    () => checkUpdatedWelcomeFlow(savedQuestions, campaigns, campaignDetails),
    [campaigns, campaignDetails, savedQuestions]
  );

  const updatedAutoReplyText = useMemo(() => {
    const foundCampaignDetails = campaignDetails.find(
      (detail) => detail.campaignId === NON_SUBSCRIBER_REPLY_CAMPAIGN_ID
    );
    if (
      foundCampaignDetails &&
      foundCampaignDetails.massTextDetails.length > 0
    ) {
      const foundMassText = foundCampaignDetails.massTextDetails[0].massText;
      return !foundMassText.isDefault;
    }
    return false;
  }, [campaignDetails]);

  const finishedOnboarding = useMemo(() => {
    return (
      accountData.showedWeeklyCommunityUpdates &&
      accountData.sharedProfileLink &&
      followersData.length > 0 &&
      sentFirstText &&
      (!hasSubscription(accountData) ||
        (hasSubscription(accountData) &&
          updatedWelcomeFlow &&
          updatedAutoReplyText))
    );
  }, [
    accountData,
    followersData.length,
    sentFirstText,
    updatedAutoReplyText,
    updatedWelcomeFlow,
  ]);

  const skipOnboarding = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const renderOnboardingItem = useCallback(
    (
      iconName: string,
      title: string,
      onPress: () => void,
      isCompleted: boolean
    ) => (
      <StandardBorderedContainer
        containerStyles={{
          ...styles.sectionContainer,
          pointerEvents: isCompleted ? "none" : "all",
          opacity: isCompleted ? 0.5 : 1,
        }}
        onPress={onPress}
      >
        <div className="AlignedRowSpacedSelect" style={{ paddingBlock: 7 }}>
          <div className="AlignedRowSelect" style={{ gap: 14 }}>
            <Icon icon={iconName} height={24} color={Colors.BLACK} />
            <span className="bodyMedium">{title}</span>
          </div>
          <Icon
            icon={isCompleted ? "ion:checkmark-circle" : "ion:chevron-right"}
            height={isCompleted ? 24 : 18}
            color={isCompleted ? Colors.GREEN2 : Colors.GRAY1}
          />
        </div>
      </StandardBorderedContainer>
    ),
    [styles.sectionContainer]
  );

  return (
    <CreatorModePanelWrapper
      title={"Welcome to Markit"}
      headerRight={<CreatorModeOnboardingProgress />}
    >
      <div style={styles.bodyContainer}>
        <div className="ColumnNormal" style={{ gap: 30 }}>
          <div
            className="ColumnNormal"
            style={{ ...styles.boxContainer, alignItems: "center" }}
          >
            <span className="sectionTitle">
              {finishedOnboarding
                ? "Onboarding Complete!"
                : "Suggested Onboarding Steps"}
            </span>
            <span className="bodySubtext">
              Complete the following tasks to finish setting up your Markit
              profile!
            </span>
          </div>
          <div className="ColumnNormal" style={styles.boxContainer}>
            {hasSubscription(accountData) ? (
              <>
                {renderOnboardingItem(
                  "mdi:hand-wave",
                  "Customize your follow message and welcome flow",
                  () => onCreatorProfileNavigatePath(navigate, 1),
                  updatedWelcomeFlow
                )}
                {renderOnboardingItem(
                  "ion:chatbox-ellipses",
                  "Set up your auto reply text",
                  () => onCreatorProfileNavigatePath(navigate, 2),
                  updatedAutoReplyText
                )}
              </>
            ) : null}
            {renderOnboardingItem(
              "mdi:bell-badge",
              "Sign up for community texting updates",
              () => setCommunityUpdatesVisible(true),
              accountData.showedWeeklyCommunityUpdates
            )}
            {renderOnboardingItem(
              "ion:person-add",
              "Add your first contact",
              () => setAddContactVisible(true),
              followersData.length > 0
            )}
            {renderOnboardingItem(
              "ion:share",
              "Share your Markit profile",
              () => setShareProfileVisible(true),
              accountData.sharedProfileLink
            )}
            {renderOnboardingItem(
              "ion:paper-plane",
              "Send your first text",
              () =>
                setSelectedTextType({ massText: undefined, isVisible: true }),
              sentFirstText
            )}
          </div>
          {showSkipOnboarding || finishedOnboarding ? (
            <div style={{ margin: "auto" }}>
              <FatHeaderActionButton
                title={finishedOnboarding ? "Continue" : "Skip Onboarding"}
                icon={
                  <Icon
                    icon="ion:chevron-right"
                    height={12}
                    color={Colors.BLACK}
                  />
                }
                onPress={skipOnboarding}
                containerStyles={{ backgroundColor: Colors.WHITE }}
              />
            </div>
          ) : null}
        </div>
        {communityUpdatesVisible ? (
          <WeeklyCommunityUpdatesModal
            setModalVisible={setCommunityUpdatesVisible}
          />
        ) : null}
        {addContactVisible ? (
          <AddSingleContactModal setIsVisible={setAddContactVisible} />
        ) : null}
        {shareProfileVisible ? (
          <ShareProfileLinkModal
            username={accountData.username}
            setShareLinkModal={setShareProfileVisible}
          />
        ) : null}
        {selectedTextType.isVisible ? (
          <MassTextPanel
            wrapperType={MassTextsWrapperType.MODAL}
            selectedTextType={selectedTextType}
            setSelectedTextType={setSelectedTextType}
            campaign={undefined}
          />
        ) : null}
      </div>
    </CreatorModePanelWrapper>
  );
};

export default GetStartedOnboardingPanel;
