import { useState } from "react";
import { AudienceList } from "@markit/common.types";
import { FAVORITES_LIST_NAME } from "@markit/common.utils";
import { Colors } from "../../../../utils/colors";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import SettingsOption from "../../../EventDashboard/SettingsOption";
import HelpModal from "../../../Containers/HelpModal";
import { AudienceListDeleteModal } from "./AudienceListDeleteModal";

type AudienceListSettingsPanelProps = {
  audienceList: AudienceList;
};

const AudienceListSettingsPanel = (props: AudienceListSettingsPanelProps) => {
  const { audienceList } = props;
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const styles = {
    container: { width: 400 },

    contentHeader: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 14,
    },

    optionsContainer: {
      backgroundColor: Colors.WHITE,
      width: "100%",
    },
  };

  return (
    <div>
      <div style={{ paddingInline: 30, paddingBlock: 20 }}>
        <div className={"RowNormal"} style={{ gap: 30 }}>
          <div style={styles.container}>
            <div style={styles.contentHeader}>Support</div>
            <StandardBorderedContainer
              containerStyles={styles.optionsContainer}
            >
              <SettingsOption
                title={"Help"}
                subtext={""}
                iconName={"ion:help"}
                onPress={() => setShowHelpModal(true)}
                disabled={false}
              />
            </StandardBorderedContainer>
          </div>
          {audienceList.name !== FAVORITES_LIST_NAME ? (
            <div style={styles.container}>
              <div style={styles.contentHeader}>More</div>
              <StandardBorderedContainer
                containerStyles={styles.optionsContainer}
              >
                <div>
                  <SettingsOption
                    title="Delete List"
                    subtext={"This can't be undone"}
                    iconName={"ion:trash"}
                    onPress={() => setShowDeleteModal(true)}
                    disabled={false}
                    altColor={Colors.RED1}
                  />
                </div>
              </StandardBorderedContainer>
            </div>
          ) : null}
        </div>
      </div>
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
      <AudienceListDeleteModal
        listId={audienceList.id}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </div>
  );
};

export default AudienceListSettingsPanel;
