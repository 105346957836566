import { useMemo } from "react";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useSelector } from "react-redux";
import { DarkTheme, useTheme } from "../../hooks/useTheme";
import { m } from "framer-motion";
import { Colors } from "../../utils/colors";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { CampaignType, ThemeType } from "@markit/common.types";
import {
  hasSubscription,
  isLockedPresetAutomations,
  NON_SUBSCRIBER_REPLY_CAMPAIGN_ID,
} from "@markit/common.utils";
import { getCampaignState } from "../../redux/slices/campaignSlice";
import { checkUpdatedWelcomeFlow } from "../../utils/userUtils";

type CreatorModeOnboardingProgressProps = {
  onPress?: () => void;
  isSelected?: boolean;
  eventTheme?: ThemeType;
};

const CreatorModeOnboardingProgress = (
  props: CreatorModeOnboardingProgressProps
) => {
  const { onPress, isSelected, eventTheme } = props;
  const { accountData, followersData, savedQuestions } =
    useSelector(getAccountState).account;
  const { campaigns, campaignDetails } = useSelector(getCampaignState);
  const { theme } = useTheme(eventTheme);
  const isDarkTheme = useMemo(() => theme && theme === DarkTheme, [theme]);

  const sentFirstText = useMemo(
    () =>
      campaigns.filter(
        (campaign) =>
          !isLockedPresetAutomations(campaign) &&
          campaign.type === CampaignType.CAMPAIGN
      ).length > 0,
    [campaigns]
  );

  // Returns true if the user has any saved questions or if the subscribe trigger campaign has been updated
  const updatedWelcomeFlow = useMemo(
    () => checkUpdatedWelcomeFlow(savedQuestions, campaigns, campaignDetails),
    [campaigns, campaignDetails, savedQuestions]
  );

  const updatedAutoReplyText = useMemo(() => {
    const foundCampaignDetails = campaignDetails.find(
      (detail) => detail.campaignId === NON_SUBSCRIBER_REPLY_CAMPAIGN_ID
    );
    if (
      foundCampaignDetails &&
      foundCampaignDetails.massTextDetails.length > 0
    ) {
      const foundMassText = foundCampaignDetails.massTextDetails[0].massText;
      return !foundMassText.isDefault;
    }
    return false;
  }, [campaignDetails]);

  const onboardingProgressPercentage = useMemo(() => {
    const totalSteps = hasSubscription(accountData) ? 6 : 4;
    let completedSteps = 0;

    if (hasSubscription(accountData)) {
      if (updatedWelcomeFlow) completedSteps++; // customized follow message or welcome flow
      if (updatedAutoReplyText) completedSteps++; // set up auto reply text
    }
    if (accountData.showedWeeklyCommunityUpdates) completedSteps++; // community updates
    if (followersData.length > 0) completedSteps++; // add first contact
    if (accountData.sharedProfileLink) completedSteps++; // share markit profile
    if (sentFirstText) completedSteps++; // send first text

    return Math.round((completedSteps / totalSteps) * 100);
  }, [
    accountData,
    followersData.length,
    sentFirstText,
    updatedAutoReplyText,
    updatedWelcomeFlow,
  ]);

  return onPress === undefined || onboardingProgressPercentage < 100 ? (
    <div style={{ paddingBottom: onPress ? 10 : 0 }}>
      <m.div
        className={onPress ? "AlignedRowSelect" : "AlignedRow"}
        onClick={onPress}
        style={{
          paddingInline: 12,
          borderRadius: 8,
          height: onPress ? 49 : undefined,
          gap: 12,
          backgroundColor: isSelected
            ? isDarkTheme
              ? Colors.GRAY7
              : Colors.GRAY6
            : isDarkTheme
            ? Colors.GRAY10
            : Colors.WHITE,
        }}
        whileHover={
          onPress
            ? {
                backgroundColor: isDarkTheme ? Colors.GRAY7 : Colors.GRAY6,
              }
            : undefined
        }
        transition={{ duration: 0.1 }}
      >
        <div
          style={{
            width: 28,
            height: 28,
            position: "relative",
            cursor: onPress ? "pointer" : "default",
          }}
        >
          <CircularProgressbar
            value={Math.max(onboardingProgressPercentage, 1)}
            strokeWidth={16}
            styles={buildStyles({
              strokeLinecap: "round",
              pathColor: Colors.BLUE5,
              trailColor: Colors.GRAY2,
            })}
          />
        </div>
        <div
          className={onPress ? "ColumnNormalSelect" : "ColumnNormal"}
          style={{ gap: 2 }}
        >
          <span className="bodyMedium">Get Started</span>
          <span className="smallBodySubtext">
            {onboardingProgressPercentage}% set up
          </span>
        </div>
      </m.div>
    </div>
  ) : null;
};

export default CreatorModeOnboardingProgress;
