import { useTheme } from "../../../hooks/useTheme";
import { memo, useCallback, useMemo } from "react";
import { isDesktop } from "react-device-detect";
import ProfilePic from "../../ProfilePic";
import { AccountData, Follower, MembershipPlan } from "@markit/common.types";
import RectangleButton from "../../Buttons/RectangleButton";
import { Colors } from "../../../utils/colors";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { Icon } from "@iconify/react";
import { onProfilePageNavigatePath } from "../../../utils/navigationUtils";
import { useNavigate } from "react-router-dom";
import ExpandableBox from "../../Containers/ExpandableBox";

type SubscribeFlowMembershipProps = {
  creatorData: AccountData;
  chooseOnPress: (selectedMembership: MembershipPlan) => void;
  selectedMembershipPlan: MembershipPlan | undefined;
  setSelectedMembershipPlan: (selectedMembershipPlan: MembershipPlan) => void;
  followerData?: Follower;
};

const SubscribeFlowMembership = (props: SubscribeFlowMembershipProps) => {
  const {
    creatorData,
    chooseOnPress,
    selectedMembershipPlan,
    setSelectedMembershipPlan,
    followerData,
  } = props;
  const { theme } = useTheme();
  const navigate = useNavigate();

  const styles = {
    cardContainer: {
      paddingBlock: 14,
      borderRadius: 14,
      width: 250,
      minHeight: 327,
      cursor: "pointer",
    },
  };

  const currentMembershipPlan = useMemo(
    () =>
      creatorData.membership.membershipPlans.find(
        (plan) => plan.id === followerData?.membershipPlanId
      ),
    [creatorData.membership.membershipPlans, followerData?.membershipPlanId]
  );

  const navigateProfilePage = useCallback(
    () => onProfilePageNavigatePath(navigate, creatorData.username),
    [creatorData.username, navigate]
  );

  const isCurrentMembershipPlan = useCallback(
    (membershipId: string) =>
      followerData ? followerData.membershipPlanId === membershipId : false,
    [followerData]
  );

  const renderCardButton = useCallback(
    (membership: MembershipPlan) => {
      const cardLabel = isCurrentMembershipPlan(membership.id)
        ? "Current Plan"
        : currentMembershipPlan
        ? currentMembershipPlan.price < membership.price
          ? "Upgrade"
          : "Downgrade"
        : "Choose";
      return (
        <RectangleButton
          buttonLabel={cardLabel}
          onPress={() => chooseOnPress(membership)}
          altTextColor={
            isCurrentMembershipPlan(membership.id)
              ? Colors.GRAY1
              : selectedMembershipPlan?.id === membership.id
              ? Colors.WHITE
              : Colors.RED2
          }
          containerStyles={{
            padding: 14,
            borderRadius: 12,
            border: `1px solid ${
              isCurrentMembershipPlan(membership.id)
                ? theme.DividerColor.borderColor
                : Colors.RED2
            }`,
            backgroundColor: isCurrentMembershipPlan(membership.id)
              ? Colors.WHITE1
              : selectedMembershipPlan?.id === membership.id
              ? Colors.RED2
              : Colors.WHITE,
          }}
          disabled={isCurrentMembershipPlan(membership.id)}
        />
      );
    },
    [
      chooseOnPress,
      currentMembershipPlan,
      isCurrentMembershipPlan,
      selectedMembershipPlan?.id,
      theme.DividerColor.borderColor,
    ]
  );

  return (
    <div
      className="ColumnNormal"
      style={{
        marginInline: "auto",
        paddingTop: 40,
        width: isDesktop ? 544 : "100%",
        gap: 20,
      }}
    >
      <div
        className="ColumnCentering"
        style={{ gap: isDesktop ? 30 : 14, paddingInline: isDesktop ? 0 : 14 }}
      >
        <ProfilePic size={isDesktop ? 100 : 75} user={creatorData} />
        <span
          className="sectionTitle"
          style={{ fontWeight: 600, ...theme.PrimaryText }}
        >
          {followerData
            ? "Change subscription plan"
            : "Choose a subscription plan"}
        </span>
      </div>
      {isDesktop ? (
        <div
          className="AlignedRow"
          style={{ alignSelf: "center", alignItems: "stretch", gap: 24 }}
        >
          {creatorData.membership.membershipPlans.map((membership) => {
            const isSelected = selectedMembershipPlan?.id === membership.id;
            return (
              <StandardBorderedContainer
                key={membership.id}
                containerStyles={{
                  ...styles.cardContainer,
                  border: `1.5px solid ${
                    isSelected ? Colors.RED2 : theme.DividerColor.borderColor
                  }`,
                }}
                onPress={() => setSelectedMembershipPlan(membership)}
              >
                <div
                  className="ColumnNormalSpaced"
                  style={{ height: "100%", cursor: "pointer", gap: 14 }}
                >
                  <div className="ColumnNormalSelect">
                    <div
                      className="ColumnNormalSelect"
                      style={{ gap: 14, paddingInline: 14 }}
                    >
                      <span className="sectionTitle">{membership.name}</span>
                      <div className="AlignedRowSelect" style={{ gap: 4 }}>
                        <span className="bodyMedium">
                          ${membership.price / 100}
                        </span>
                        {membership.id !== "free" ? (
                          <span className="smallBodySubtext">
                            / {membership.billingFrequency}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <HorizontalDivider altMargin={14} />
                    <div
                      className="ColumnNormalSelect"
                      style={{ gap: 7, paddingInline: 14 }}
                    >
                      <span style={{ ...theme.LabelText, fontSize: 12 }}>
                        Benefits
                      </span>
                      <span
                        className="bodyMedium"
                        style={{ fontSize: 12, whiteSpace: "pre-wrap" }}
                      >
                        {membership.description}
                      </span>
                    </div>
                  </div>
                  <div style={{ paddingInline: 14 }}>
                    {renderCardButton(membership)}
                  </div>
                </div>
              </StandardBorderedContainer>
            );
          })}
        </div>
      ) : (
        <div className="ColumnNormal" style={{ gap: 20, paddingInline: 14 }}>
          {creatorData.membership.membershipPlans.map((membership) => (
            <StandardBorderedContainer
              key={membership.id}
              containerStyles={{
                width: "100%",
                paddingBlock: 14,
                borderRadius: 14,
                border: `${
                  membership.id === selectedMembershipPlan?.id
                    ? `2px solid ${Colors.BLACK}`
                    : `1.5px solid ${theme.DividerColor.borderColor}`
                }`,
              }}
              onPress={() => setSelectedMembershipPlan(membership)}
            >
              <div className="ColumnNormal">
                <div className="AlignedRowSpaced" style={{ paddingInline: 14 }}>
                  <div className="ColumnNormal" style={{ gap: 4 }}>
                    <span className="bodyMedium">{membership.name}</span>
                    <span style={theme.SubText}>
                      <span style={{ fontSize: 14, fontWeight: 500 }}>
                        ${membership.price / 100}
                      </span>{" "}
                      {membership.id !== "free" ? (
                        <span className="smallBodySubtext">
                          / {membership.billingFrequency}
                        </span>
                      ) : null}
                    </span>
                  </div>
                  {membership.id === selectedMembershipPlan?.id ? (
                    <Icon
                      icon={
                        membership.id !== "free"
                          ? "ion:ribbon"
                          : "ion:checkmark-circle"
                      }
                      height={25}
                      color={Colors.BLACK}
                    />
                  ) : null}
                </div>
                {membership.id === selectedMembershipPlan?.id ? (
                  <>
                    <HorizontalDivider altMargin={14} />
                    {selectedMembershipPlan?.description ? (
                      <div
                        className="ColumnNormal"
                        style={{ gap: 7, paddingInline: 14 }}
                      >
                        <span
                          style={{
                            ...theme.LabelText,
                            fontSize: 12,
                            fontWeight: 500,
                          }}
                        >
                          Benefits
                        </span>
                        <span
                          className="bodyMedium"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {selectedMembershipPlan.description}
                        </span>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            </StandardBorderedContainer>
          ))}
          {selectedMembershipPlan
            ? renderCardButton(selectedMembershipPlan)
            : null}
        </div>
      )}
      {followerData ? (
        <div
          className="AlignedRowSelect"
          style={{ justifyContent: "center", gap: 4 }}
          onClick={navigateProfilePage}
        >
          <span className="smallBodySubtext" style={{ fontWeight: 500 }}>
            Maybe later
          </span>
          <Icon icon="ion:chevron-right" height={11} color={Colors.GRAY1} />
        </div>
      ) : null}
    </div>
  );
};

export default memo(SubscribeFlowMembership);
