import "../../../../css/GlobalStyles.css";
import { useCallback, useMemo, useState } from "react";
import { AudienceList, AudienceListVisibility } from "@markit/common.types";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import {
  checkHitAudienceListsLimit,
  FAVORITES_LIST_NAME,
  hasSubscription,
  isBasicSubscription,
  isBusinessSubscription,
  sortAudienceListsDisplay,
} from "@markit/common.utils";
import EmptyStateButton from "../../../../components/Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import RectangleButtonCreatorPanel from "../../../../components/Buttons/RectangleButtonCreatorPanel";
import MarkitPlusActionButton from "../../../../components/Subscription/MarkitPlusActionButton";
import CreatorModePanelWrapper from "../../../../components/CreatorDashboard/CreatorModePanelWrapper";
import SearchBoxContainer from "../../../../components/Containers/SearchBoxContainer";
import FlatList from "flatlist-react/lib";
import AudienceListItem, {
  AUDIENCE_LIST_FLEX_SECTIONS,
} from "../../../../components/DisplayItem/AudienceListItem/AudienceListItem";
import filter from "lodash.filter";
import { EmptyStateFlatlist } from "../../../../components/EmptyStates/EmptyStateFlatlist";
import StandardListContainer from "../../../../components/Containers/StandardListContainer";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "../../../../components/DropdownMenu";
import AudienceListDetailsModal from "../../../../components/CreatorDashboard/CreatorProfile/AudienceLists/AudienceListDetailsModal";
import { notificationActions } from "../../../../redux/slices/notificationSlice";

export enum ListFilterState {
  ANY = "Any Visibility",
  PRIVATE = "Private",
  PUBLIC = "Public",
}

const ListsPanel = () => {
  const { audienceListDetails, appInitialized, accountData } =
    useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [listPopupVisible, setListPopupVisible] = useState(false);
  const [filterState, setFilterState] = useState<ListFilterState>(
    ListFilterState.ANY
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [existingList, setExistingList] = useState<AudienceList>();

  const styles = {
    container: {
      backgroundColor: Colors.WHITE,
      paddingInline: 30,
      paddingTop: 30,
      height: "100%",
    },
    filterContainer: {
      gap: 10,
      borderRadius: 8,
      paddingInline: 14,
      paddingBlock: 12,
      backgroundColor: Colors.WHITE1,
      border: `0.8px solid ${Colors.GRAY2}`,
    },
  };

  const audienceLists = useMemo(
    () => audienceListDetails.map((listDetail) => listDetail.list),
    [audienceListDetails]
  );

  const createdAudienceLists = useMemo(() => {
    return audienceLists.filter((list) => list.name !== FAVORITES_LIST_NAME);
  }, [audienceLists]);

  const contains = useCallback((list: AudienceList, query: string) => {
    return list.name.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
  };

  const audienceListsToShow = useMemo(() => {
    if (!appInitialized) {
      return [];
    }
    let userAudienceLists: AudienceList[] = sortAudienceListsDisplay(
      filterState === ListFilterState.ANY
        ? audienceLists
        : audienceLists.filter(
            (list) =>
              list.visibility ===
              (filterState === ListFilterState.PRIVATE
                ? AudienceListVisibility.PRIVATE
                : AudienceListVisibility.PUBLIC)
          )
    );
    if (searchTerm !== "") {
      userAudienceLists = filter(userAudienceLists, (list: AudienceList) => {
        return contains(list, searchTerm);
      });
    }
    return userAudienceLists;
  }, [appInitialized, audienceLists, contains, searchTerm, filterState]);

  const addNewList = useCallback(() => {
    if (checkHitAudienceListsLimit(createdAudienceLists, accountData)) {
      dispatch(notificationActions.setMarkitPlusModal(true));
      return;
    }
    setListPopupVisible(true);
  }, [accountData, createdAudienceLists, dispatch]);

  const editListOnPress = useCallback((item: AudienceList) => {
    setExistingList(item);
    setListPopupVisible(true);
  }, []);

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: ListFilterState.ANY,
        icon: filterState === ListFilterState.ANY ? "ion:checkmark" : undefined,
        key: "all_visibility",
        onPress: () => setFilterState(ListFilterState.ANY),
      },
      {
        title: ListFilterState.PRIVATE,
        icon:
          filterState === ListFilterState.PRIVATE ? "ion:checkmark" : undefined,
        key: "private_visibility",
        onPress: () => setFilterState(ListFilterState.PRIVATE),
      },
      {
        title: ListFilterState.PUBLIC,
        icon:
          filterState === ListFilterState.PUBLIC ? "ion:checkmark" : undefined,
        key: "public_visibility",
        onPress: () => setFilterState(ListFilterState.PUBLIC),
      },
    ],
    [filterState]
  );

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={false}
        containerStyles={{ paddingTop: 150 }}
        nonSearchEmptyView={<></>}
      />
    );
  }, [searchTerm]);

  return (
    <CreatorModePanelWrapper
      title="Lists"
      headerRight={
        <div className="AlignedRow" style={{ gap: 14 }}>
          <MarkitPlusActionButton />
          <RectangleButtonCreatorPanel
            title="New List"
            iconName="ion:create"
            onPress={addNewList}
          />
        </div>
      }
    >
      <div style={styles.container}>
        <StandardListContainer
          searchComp={
            <div className="AlignedRow" style={{ gap: 14 }}>
              <SearchBoxContainer
                placeholder={"Search"}
                onChange={(e) => handleSearch(e.target.value)}
                containerStyles={{ marginTop: 0, width: 394 }}
              />
              <DropdownMenu
                dropdownItems={dropdownItems}
                trigger={["click"]}
                placement="bottom"
                onOpenChange={(isOpen: boolean) => setDropdownVisible(isOpen)}
                altWidth={160}
              >
                <div
                  className="AlignedRowSelect"
                  style={styles.filterContainer}
                >
                  <span className="bodyMedium">{filterState}</span>
                  <Icon
                    icon={
                      dropdownVisible ? "mdi-chevron-up" : "mdi:chevron-down"
                    }
                    height={18}
                  />
                </div>
              </DropdownMenu>
            </div>
          }
          gridTable={{
            flexSections: AUDIENCE_LIST_FLEX_SECTIONS,
            sectionTitles: ["Name", "Visibility", "People", "Description"],
          }}
          isLoading={!appInitialized}
          listComp={
            <div className="ColumnNormal">
              <FlatList
                list={audienceListsToShow}
                renderItem={(item: AudienceList) => (
                  <div key={item.id}>
                    <AudienceListItem
                      item={item}
                      editListOnPress={() => editListOnPress(item)}
                    />
                  </div>
                )}
                renderWhenEmpty={() => renderEmptyView}
              />
              {!hasSubscription(accountData) &&
              createdAudienceLists.length === 0 ? (
                <EmptyStateButton
                  title="Get Markit+ to Create Lists"
                  description="Your lists will show up here"
                  icon={
                    <Icon icon="ion:list" height={64} color={Colors.GRAY1} />
                  }
                  iconBox={84}
                  btnText="Get Markit+"
                  onPress={addNewList}
                  containerStyles={{ paddingTop: 100 }}
                />
              ) : hasSubscription(accountData) &&
                !isBusinessSubscription(accountData) ? (
                <div
                  className="RowNormal"
                  style={{ justifyContent: "center", paddingTop: 14 }}
                >
                  <span className="bodySubtext">
                    You have reached {createdAudienceLists.length}/
                    {isBasicSubscription(accountData) ? "3" : "10"} available
                    lists.{" "}
                    <span
                      style={{ color: Colors.BLUE5, cursor: "pointer" }}
                      onClick={() =>
                        dispatch(notificationActions.setMarkitPlusModal(true))
                      }
                    >
                      Upgrade
                    </span>{" "}
                    to create more lists
                  </span>
                </div>
              ) : null}
            </div>
          }
        />
        {listPopupVisible ? (
          <AudienceListDetailsModal
            isVisible={listPopupVisible}
            setIsVisible={setListPopupVisible}
            existingList={existingList}
          />
        ) : null}
      </div>
    </CreatorModePanelWrapper>
  );
};

export default ListsPanel;
