import { useCallback, useMemo, useRef, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "../firebase";
import { filterUndefinedValues, uniqueVals } from "@markit/common.utils";
import {
  AccountData,
  Follower,
  Following,
  FollowerStatus,
  FollowerMembershipState,
} from "@markit/common.types";
import {
  getUserFollowersRef,
  getUserFollowingData,
  getUserFollowingsRef,
} from "../utils/FirebaseUtils";
import { fetchSingleFollower, fetchSingleUser } from "../utils/FetchSingleData";
import { loadAlgoliaUsersSearchResults } from "../utils/algoliaUtils";
import { batch, useDispatch, useSelector } from "react-redux";
import { accountActions, getAccountState } from "../redux/slices/accountSlice";
import useOnUnmount from "./useOnUnmount";

type useLoadUserFollowListProps = {
  userId: string;
  followListType: "Followers" | "Following";
  followerStatus?: FollowerStatus; // If not provided, all followers will be loaded
  membershipState?: FollowerMembershipState; // If not provided, all membership states will be loaded
  windowSize: number;
  membershipPlanId?: string;
};
export const useLoadUserFollowList = (props: useLoadUserFollowListProps) => {
  const {
    userId,
    followListType,
    followerStatus,
    membershipState,
    windowSize,
    membershipPlanId,
  } = props;

  const { followingAccountData, followersData } =
    useSelector(getAccountState).account;
  const dispatch = useDispatch();

  const [fetchedUnorderedUserData, setFetchedUnorderedUserData] = useState<
    AccountData[]
  >([]);
  const [fetchedFollowerData, setFetchedFollowerData] = useState<Follower[]>(
    []
  );
  const [fetchedFollowingData, setFetchedFollowingData] = useState<Following[]>(
    []
  );
  const unsubscribeArrayRef = useRef<(() => void)[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllMessages, setLoadedAllMessages] = useState(false);

  const fetchedUserData: AccountData[] = useMemo(() => {
    const followerUidOrder = fetchedFollowerData.map(
      (follower) => follower.uid
    );
    return fetchedUnorderedUserData.sort(
      (a, b) =>
        followerUidOrder.indexOf(a.uid) - followerUidOrder.indexOf(b.uid)
    );
  }, [fetchedFollowerData, fetchedUnorderedUserData]);

  useOnUnmount(
    (latestDeps) => {
      batch(() => {
        dispatch(
          accountActions.addMultipleFollowingAccountData(
            latestDeps[0] as AccountData[]
          )
        );
        dispatch(
          accountActions.addMultipleFollowerData(latestDeps[1] as Follower[])
        );
      });
      unsubscribeArrayRef.current.forEach(
        (unsubscribe) => unsubscribe && unsubscribe()
      );
      unsubscribeArrayRef.current = []; // Clear the array after cleanup
    },
    [fetchedUnorderedUserData, fetchedFollowerData]
  );

  const addUserData = useCallback((newUserData: AccountData[]) => {
    setFetchedUnorderedUserData((fetchedUserData) =>
      uniqueVals(
        newUserData.concat(fetchedUserData),
        (userData) => userData.uid
      )
    );
  }, []);

  const removeUserData = useCallback((userDataUid: string) => {
    setFetchedUnorderedUserData((fetchedUserData) => {
      const index = fetchedUserData.findIndex(
        (fetched) => fetched.uid === userDataUid
      );
      if (index !== -1) {
        fetchedUserData.splice(index, 1);
      }
      return [...fetchedUserData];
    });
  }, []);

  const addFollowerData = useCallback((newFollowers: Follower[]) => {
    setFetchedFollowerData((fetchedFollowerData) =>
      uniqueVals(
        newFollowers.concat(fetchedFollowerData),
        (followerData) => followerData.uid
      ).sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    );
  }, []);

  const removeFollowerData = useCallback((newFollowerUid: string) => {
    setFetchedFollowerData((fetchedFollowerData) => {
      const index = fetchedFollowerData.findIndex(
        (fetched) => fetched.uid === newFollowerUid
      );
      if (index !== -1) {
        fetchedFollowerData.splice(index, 1);
      }
      return [...fetchedFollowerData];
    });
  }, []);

  const addFollowingData = useCallback((newFollowings: Following[]) => {
    setFetchedFollowingData((fetchedFollowingData) =>
      uniqueVals(
        newFollowings.concat(fetchedFollowingData),
        (followingData) => followingData.uid
      )
    );
  }, []);

  const removeFollowingData = useCallback((newFollowingUid: string) => {
    setFetchedFollowingData((fetchedFollowingData) => {
      const index = fetchedFollowingData.findIndex(
        (fetched) => fetched.uid === newFollowingUid
      );
      if (index !== -1) {
        fetchedFollowingData.splice(index, 1);
      }
      return [...fetchedFollowingData];
    });
  }, []);

  const isFinished = useMemo(() => loadedAllMessages, [loadedAllMessages]);

  const updateData = useCallback(
    async (foundMemberData: Follower[] | Following[]) => {
      if (followListType === "Followers") {
        addFollowerData(foundMemberData as Follower[]);
        dispatch(
          accountActions.addMultipleFollowerData(foundMemberData as Follower[])
        );
      } else {
        addFollowingData(foundMemberData);
      }
      const membersUserData = await Promise.all(
        foundMemberData.map((member) =>
          fetchSingleUser(member.uid, followingAccountData)
        )
      );
      const definedMembersUserData: AccountData[] =
        filterUndefinedValues(membersUserData);
      addUserData(definedMembersUserData);
    },
    [
      addFollowerData,
      addFollowingData,
      addUserData,
      dispatch,
      followListType,
      followingAccountData,
    ]
  );

  const initialUpdateData = useCallback(
    async (foundMemberData: Follower[] | Following[]) => {
      if (foundMemberData.length) {
        setLastVisible(foundMemberData[foundMemberData.length - 1].createdAt);
      }
      if (foundMemberData.length < windowSize) {
        setLoadedAllMessages(true);
      }

      if (foundMemberData.length > 0) {
        await updateData(foundMemberData);
      }
    },
    [updateData, windowSize]
  );

  const liveUpdateData = useCallback(
    async (
      type: "added" | "modified" | "removed",
      member: Follower | Following,
      foundMemberData: Follower[] | Following[]
    ) => {
      if (
        (type === "added" &&
          !foundMemberData.some(
            (memberData) =>
              memberData.uid === member.uid &&
              memberData.createdAt === member.createdAt
          )) ||
        type === "modified"
      ) {
        updateData([member]);
      } else if (type === "removed") {
        if (followListType === "Followers") {
          removeFollowerData(member.uid);
        } else {
          removeFollowingData(member.uid);
        }
        removeUserData(member.uid);
      }
    },
    [
      followListType,
      removeFollowerData,
      removeFollowingData,
      removeUserData,
      updateData,
    ]
  );

  const loadUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const followMembersRef =
        followListType === "Followers"
          ? getUserFollowersRef(userId)
          : getUserFollowingsRef(userId);
      const baseQuery = [orderBy("createdAt", "desc"), limit(windowSize)];
      const conditions = [
        ...(followerStatus ? [where("status", "==", followerStatus)] : []),
        ...(membershipState
          ? [where("membershipState", "==", membershipState)]
          : []),
        ...(membershipPlanId
          ? [where("membershipPlanId", "==", membershipPlanId)]
          : []),
      ];

      const followMembersQuery = query(
        followMembersRef,
        ...conditions,
        ...baseQuery
      );

      const foundFollowMembers = (await getDocs(followMembersQuery)).docs.map(
        (doc) => doc.data()
      );
      await initialUpdateData(foundFollowMembers);

      const unsubscribe = onSnapshot(followMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message) => {
          const memberData = message.doc.data();
          liveUpdateData(message.type, memberData, foundFollowMembers);
        });
      });
      unsubscribeArrayRef.current.push(unsubscribe);

      setIsLoading(false);
    }
  }, [
    isFinished,
    isLoading,
    followListType,
    userId,
    membershipPlanId,
    followerStatus,
    membershipState,
    windowSize,
    initialUpdateData,
    liveUpdateData,
  ]);

  const loadMoreUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const followMembersRef =
        followListType === "Followers"
          ? getUserFollowersRef(userId)
          : getUserFollowingsRef(userId);
      const baseQuery = [
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(windowSize),
      ];
      const conditions = [
        ...(followerStatus ? [where("status", "==", followerStatus)] : []),
        ...(membershipState
          ? [where("membershipState", "==", membershipState)]
          : []),
        ...(membershipPlanId
          ? [where("membershipPlanId", "==", membershipPlanId)]
          : []),
      ];

      const followMembersQuery = query(
        followMembersRef,
        ...conditions,
        ...baseQuery
      );

      const foundFollowMembers = (await getDocs(followMembersQuery)).docs.map(
        (doc) => doc.data()
      );
      await initialUpdateData(foundFollowMembers);
      const unsubscribe = onSnapshot(followMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message) => {
          const memberData: Follower | Following = message.doc.data();
          liveUpdateData(message.type, memberData, foundFollowMembers);
        });
      });
      unsubscribeArrayRef.current.push(unsubscribe);

      setIsLoading(false);
    }
  }, [
    isFinished,
    isLoading,
    followListType,
    userId,
    membershipPlanId,
    followerStatus,
    membershipState,
    lastVisible,
    windowSize,
    initialUpdateData,
    liveUpdateData,
  ]);

  /*
   * Start searching utils
   */
  const checkIfAddUserData = useCallback(
    async (uid: string) => {
      const userData = await fetchSingleUser(uid, followingAccountData);
      if (userData) {
        if (followListType === "Followers") {
          const followerData = await fetchSingleFollower(
            uid,
            userData.uid,
            followersData
          );
          if (followerData) {
            if (
              followerData.status === followerStatus &&
              ((membershipPlanId &&
                followerData.membershipPlanId === membershipPlanId) ||
                !membershipPlanId)
            ) {
              addFollowerData([followerData]);
              addUserData([userData]);
            }
          }
        } else {
          const followingData = await getUserFollowingData(userId, uid);
          if (followingData) {
            addFollowingData([followingData]);
            addUserData([userData]);
          }
        }
      }
    },
    [
      addFollowerData,
      addFollowingData,
      addUserData,
      followListType,
      followerStatus,
      followersData,
      followingAccountData,
      membershipPlanId,
      userId,
    ]
  );

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      const fetchFollowers = async (userIds: string[]) => {
        setIsLoading(true);
        await Promise.all(
          userIds.map(async (uid) => {
            await checkIfAddUserData(uid);
          })
        );
        setIsLoading(false);
      };
      await loadAlgoliaUsersSearchResults(
        searchTerm,
        `following:${userId}`,
        (userIds: string[]) => fetchFollowers(userIds)
      );
    },
    [userId, checkIfAddUserData]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          if (searchTerm.length > 1) {
            loadSearchResults(searchTerm);
          }
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );
  /*
   * End searching utils
   */

  return {
    isFinished,
    isLoading,
    fetchedUserData,
    fetchedFollowerData,
    fetchedFollowingData,
    loadUsers,
    loadMoreUsers,
    loadSearchResultsThrottled,
  };
};
