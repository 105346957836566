import "../../css/ProfilePage/ProfilePage.css";
import { memo, useCallback, useEffect, useState } from "react";
import { AccountData, NotificationType } from "@markit/common.types";
import { Colors } from "../../utils/colors";
import { useSelector, useDispatch } from "react-redux";
import {
  getAccountState,
  updateAccountData,
} from "../../redux/slices/accountSlice";
import { showNotificationBanner } from "../../utils/notificationUtils";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "../Containers/PopupModalContainer";
import { usePartialAccountUpdates } from "../../hooks/usePartialAccountUpdates";
import EditProfileDetails, { EditProfileError } from "./EditProfileDetails";
import RectangleButton from "../Buttons/RectangleButton";
import { isValidUserName } from "../../utils/stringUtils";
import {
  checkValidSocialMediaLink,
  deepCopy,
  detectedCensored,
} from "@markit/common.utils";
import {
  getUserDataByUsername,
  getUsernameSnap,
} from "../../utils/FirebaseUtils";
import AlertContainer from "../Containers/AlertContainer";
import { isDesktop } from "react-device-detect";

type ProfilePageEditProfileProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

const ProfilePageEditProfile = memo(function ProfilePageEditProfileFn({
  isVisible,
  setIsVisible,
}: ProfilePageEditProfileProps) {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const dispatch = useDispatch();
  const [showError, setShowError] = useState<EditProfileError>({
    type: "",
    message: "",
  });
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });
  const { accountSettings, updateAccountSettings } = usePartialAccountUpdates();

  // prevent background scrolling when the modal is open
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  // checks all conditions for a valid profile
  const isValidProfileDetails = (accountSettings: AccountData) => {
    if (accountSettings.fullName === "") {
      return "Full name cannot be empty.";
    } else if (detectedCensored(accountSettings.fullName)) {
      return "Full name contains banned words and must be removed.";
    } else if (accountSettings.username === "") {
      return "Username cannot be empty";
    } else if (
      !checkValidSocialMediaLink("instagram", accountSettings.instagram)
    ) {
      return "Invalid instagram url";
    } else if (
      !checkValidSocialMediaLink("linkedin", accountSettings.linkedin)
    ) {
      return "Invalid linkedin url";
    } else if (!checkValidSocialMediaLink("twitter", accountSettings.twitter)) {
      return "Invalid X/twitter url";
    } else if (!checkValidSocialMediaLink("tiktok", accountSettings.tiktok)) {
      return "Invalid tiktok url";
    } else if (!checkValidSocialMediaLink("spotify", accountSettings.spotify)) {
      return "Invalid spotify url";
    }
    return "";
  };

  const saveEditProfile = useCallback(async () => {
    const usernameLowercase = accountSettings.username.toLowerCase();
    if (!isValidUserName(usernameLowercase)) {
      setShowError({
        type: "username",
        message:
          "Username can only consist of alphanumeric characters, 3-30 characters in length, no spaces",
      });
      return;
    }
    if (usernameLowercase !== accountData.username) {
      const usernameSnap = await getUsernameSnap(usernameLowercase);
      if (usernameSnap.exists()) {
        const usernameData = usernameSnap.data();
        const existingUserData = await getUserDataByUsername(
          usernameData.username
        );
        if (existingUserData && existingUserData.uid !== accountData.uid) {
          setShowError({
            type: "username",
            message: "Username already exists. Try another.",
          });
          return;
        }
      }
    }
    const isValidProfile = isValidProfileDetails(accountSettings);
    if (isValidProfile !== "") {
      setAlertText({ heading: isValidProfile, subHeading: "" });
      return;
    }
    const finalAccountSettings: AccountData = deepCopy(accountSettings);
    if (accountSettings.profileLink !== "") {
      // append web protocol if input url is not well formatted
      const url = finalAccountSettings.profileLink;
      finalAccountSettings.profileLink = /^(https|http):\/\/.*/.test(url)
        ? url
        : `https://${url}`;
    }
    finalAccountSettings.username = usernameLowercase;
    dispatch(updateAccountData(finalAccountSettings));
    showNotificationBanner(dispatch, "Saved", NotificationType.AFFIRMATIVE);
    setIsVisible(false);
  }, [
    accountData.uid,
    accountData.username,
    accountSettings,
    dispatch,
    setIsVisible,
  ]);

  return (
    <>
      <PopupModalContainer
        headerComp={<span>Edit Profile</span>}
        valueComp={
          <div className="ColumnNormal" style={{ paddingBottom: 14 }}>
            <EditProfileDetails
              accountSettings={accountSettings}
              updateAccountSettings={updateAccountSettings}
              showError={showError}
              setShowError={setShowError}
              loading={false}
            />
          </div>
        }
        fixedBottomContent={
          <div
            className="AlignedRow"
            style={{ gap: 10, paddingInline: 14, paddingTop: 10 }}
          >
            <RectangleButton
              buttonLabel={"Cancel"}
              onPress={() => setIsVisible(false)}
              altTextColor={Colors.BLACK}
              altColor={Colors.GRAY11}
              containerStyles={{ paddingBlock: 14, paddingInline: 12 }}
            />
            <RectangleButton
              buttonLabel={"Save"}
              onPress={saveEditProfile}
              altTextColor={Colors.WHITE}
              altColor={Colors.BLACK}
              containerStyles={{ paddingBlock: 14, paddingInline: 12 }}
            />
          </div>
        }
        noExit
        closeOnOutsidePress={alertText.heading === "" && isDesktop}
        closeModal={() => setIsVisible(false)}
        modalContentWidth={PopupModalContainerWidth.SMALL}
      />
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
    </>
  );
});

export default ProfilePageEditProfile;
