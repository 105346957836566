import { Campaign, CampaignType, MassText } from "@markit/common.types";
import { MassTextDetails } from "../../../../redux/slices/campaignSlice";
import { useMemo } from "react";
import LabelButton from "../../../Buttons/LabelButton";
import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { getEventState } from "../../../../redux/slices/eventSlice";
import { isAutomationActive } from "../../../../utils/campaignUtils";
import { getAccountState } from "../../../../redux/slices/accountSlice";

type CampaignStatusLabelProps = {
  massTexts: MassText[];
  initialMassTextDetail: MassTextDetails | undefined;
  campaign: Campaign;
};

// Used to display the status of the campaign (Active, Sent, Scheduled, Queued)
const CampaignStatusLabel = (props: CampaignStatusLabelProps) => {
  const { massTexts, initialMassTextDetail, campaign } = props;
  const events = useSelector(getEventState).events.events;
  const markitPhone =
    useSelector(getAccountState).account.accountData.customer.phone;

  const foundEvent = useMemo(
    () => events.find((event) => event.id === campaign.eventId),
    [campaign.eventId, events]
  );

  const automationCampaign = useMemo(
    () => campaign.type === CampaignType.AUTOMATION,
    [campaign.type]
  );

  // A text should be queued if the text is scheduled or if it's immediate and queued is true. Otherwise, show the queued status for sending
  const textQueued = useMemo(
    () =>
      initialMassTextDetail &&
      (initialMassTextDetail.massText.scheduled ||
        (!initialMassTextDetail.massText.scheduled &&
          initialMassTextDetail.massText.queued)),
    [initialMassTextDetail]
  );

  // Scheduled campaign status if there is a text in the campaign that is not yet queued and is scheduled
  const scheduledCampaign = useMemo(
    () =>
      massTexts &&
      massTexts.some((text) => !text.queued && text.scheduled) &&
      !automationCampaign,
    [automationCampaign, massTexts]
  );

  // Active campaign status if:
  // 1. Campaign: Has multiple texts and there is a text that is scheduled
  // 2. Automation: If it's the Clicked Subscribe trigger campaign, last automation text within a day of sending, event end still upcoming
  const activeCampaign = useMemo(
    () =>
      automationCampaign
        ? isAutomationActive(
            campaign.title,
            massTexts,
            foundEvent,
            markitPhone !== ""
          )
        : massTexts.length > 1 && scheduledCampaign,
    [
      automationCampaign,
      campaign.title,
      foundEvent,
      markitPhone,
      massTexts,
      scheduledCampaign,
    ]
  );

  const inactiveAutomation = useMemo(
    () => automationCampaign && !scheduledCampaign,
    [automationCampaign, scheduledCampaign]
  );

  return (
    <LabelButton
      color={Colors.BLACK}
      backgroundColor={
        activeCampaign
          ? "rgba(61,220,132,0.1)"
          : scheduledCampaign
          ? "rgba(0,122,255,0.1)"
          : inactiveAutomation
          ? "rgba(239,149,61,0.1)"
          : Colors.WHITE1
      }
      text={
        !textQueued
          ? "Queued"
          : activeCampaign
          ? "Active"
          : scheduledCampaign
          ? "Scheduled"
          : inactiveAutomation
          ? "Inactive"
          : "Sent"
      }
      icon={
        activeCampaign ? (
          <Icon icon={"ion:ellipse"} height={8} color={Colors.GREEN1} />
        ) : undefined
      }
      containerStyles={{
        border: `1px solid ${
          activeCampaign
            ? Colors.GREEN1
            : scheduledCampaign
            ? Colors.BLUE3
            : inactiveAutomation
            ? Colors.ORANGE1
            : Colors.GRAY1
        }`,
        marginLeft: 14,
      }}
    />
  );
};

export default CampaignStatusLabel;
