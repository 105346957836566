import { memo, useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import {
  useParametricSelector,
  useProfileSelector,
} from "../../../hooks/useParametricSelector";
import { getLoadedUserByUsername } from "../../../redux/slices/dataSlice";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../LoadingScreen";
import {
  LoginState,
  accountActions,
  getAccountState,
} from "../../../redux/slices/accountSlice";
import MarkitSecuredContainer from "../../../components/UserInfoContainers/MarkitSecuredContainer";
import { useTheme } from "../../../hooks/useTheme";
import {
  Follower,
  MembershipPlan,
  NotificationType,
} from "@markit/common.types";
import SubscribeFlowMembership from "../../../components/ProfilePage/SubscribeFlow/SubscribeFlowMembership";
import SubscribeFlowMembershipManage from "../../../components/ProfilePage/SubscribeFlow/SubscribeFlowMembershipManage";
import SubscribeFlowMembershipCheckout from "../../../components/ProfilePage/SubscribeFlow/SubscribeFlowMembershipCheckout";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import { CircularProgress } from "@mui/material";
import { Colors } from "../../../utils/colors";
import { API } from "../../../API";
import { onProfilePageNavigatePath } from "../../../utils/navigationUtils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import ConfirmDeleteModal from "../../../components/Containers/ConfirmPopups/ConfirmDeleteModal";
import { testStripe } from "../../../components/FullEvent/FullEventInputForm";
import { Icon } from "@iconify/react";
import { getUserFollowerRef } from "../../../utils/FirebaseUtils";
import { onSnapshot } from "../../../firebase";
import { NavigationId } from "../../../navigation/AppParamList";
import { LoginStage } from "../../Login/LoginWelcome";

export enum SubscribeManageStage {
  MEMBERSHIP = "Membership",
  MEMBERSHIP_PLANS = "Membership Plans",
  MEMBERSHIP_CHECKOUT = "Membership Checkout",
}

const ProfilePageSubscribeManage = memo(
  function ProfilePageSubscribeManageFn() {
    const { accountData, loggedIn, appInitialized } =
      useSelector(getAccountState).account;
    const { theme } = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const isUpgradeFlow = useMemo(
      () => searchParams.has("upgrade"),
      [searchParams]
    );

    const [selectedMembershipPlan, setSelectedMembershipPlan] =
      useState<MembershipPlan>();
    const [subscribingStage, setSubscribingStage] =
      useState<SubscribeManageStage>(
        isUpgradeFlow
          ? SubscribeManageStage.MEMBERSHIP_PLANS
          : SubscribeManageStage.MEMBERSHIP
      );
    const [followerData, setFollowerData] = useState<Follower>();
    const [loadingFollower, setLoadingFollower] = useState(true);
    const [confirmCancelModal, setConfirmCancelModal] = useState(false);
    const [processing, setProcessing] = useState(false);

    const username = useProfileSelector();
    const {
      isLoading: isLoadingUserData,
      data: creatorData,
      isError: isErrorUserData,
    } = useParametricSelector(getLoadedUserByUsername, username);

    const isLoggedIn = useMemo(
      () => loggedIn === LoginState.LOGGED_IN,
      [loggedIn]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useAsyncEffect(async () => {
      if (!isLoggedIn) {
        dispatch(accountActions.setRedirectPath(location.pathname));
        navigate(NavigationId.SIGN_IN, {
          state: { preLoginStage: LoginStage.PHONE },
        });
        return;
      }
      if (!appInitialized || !creatorData) {
        return;
      }

      // default the selected membership plan on the first paid plan
      const paidMembershipPlans = creatorData.membership.membershipPlans.filter(
        (plan) => plan.id !== "free"
      );
      if (paidMembershipPlans) {
        setSelectedMembershipPlan(paidMembershipPlans[0]);
      }

      const followerRef = getUserFollowerRef(creatorData.uid, accountData.uid);
      const unsubscribe = onSnapshot(followerRef, async (snapshot) => {
        const followerData = snapshot.data();
        if (followerData) {
          setFollowerData(followerData);
          setLoadingFollower(false);
        }
      });

      return () => {
        unsubscribe();
      };
    }, [
      accountData.uid,
      appInitialized,
      creatorData,
      dispatch,
      isLoggedIn,
      loggedIn,
      navigate,
    ]);

    const chooseMembershipOnPress = useCallback(
      async (selectedMembership: MembershipPlan) => {
        if (!creatorData) {
          return;
        }
        setSelectedMembershipPlan(selectedMembership);
        if (selectedMembership.id === "free") {
          setConfirmCancelModal(true);
        } else {
          setSubscribingStage(SubscribeManageStage.MEMBERSHIP_CHECKOUT);
        }
      },
      [creatorData]
    );

    const cancelFollowerSubscription = useCallback(async () => {
      if (!creatorData) {
        return;
      }
      setProcessing(true);
      await API.user.cancelFollowerSubscription(accountData.uid, {
        uid: accountData.uid,
        creatorUserId: creatorData.uid,
        creatorStripeAccountId:
          testStripe && creatorData.testStripeAccountId
            ? creatorData.testStripeAccountId
            : creatorData.stripeAccountId,
      });
      onProfilePageNavigatePath(navigate, creatorData.username);
      showNotificationBanner(dispatch, "Cancelled", NotificationType.NEGATIVE);
      setProcessing(false);
    }, [accountData.uid, creatorData, dispatch, navigate]);

    if (
      isLoadingUserData ||
      (loggedIn === LoginState.LOGGED_IN && !appInitialized) ||
      !creatorData
    ) {
      return (
        <LoadingScreen
          isLoadingPage={
            isLoadingUserData ||
            (loggedIn === LoginState.LOGGED_IN && !appInitialized) ||
            !creatorData
          }
        />
      );
    }

    if (isErrorUserData) {
      return <LoadingScreen error />;
    }

    const title = `${creatorData.fullName} (@${creatorData.username}) on Markit`;

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta name="og:title" content={title} />
          <meta name="og:description" content={creatorData.username} />
        </Helmet>
        <div
          className="ColumnNormalSpaced"
          style={{ ...theme.SecondaryBG, minHeight: "100vh" }}
        >
          {loadingFollower || !followerData ? (
            <div className="Centering" style={{ marginBlock: "auto" }}>
              <CircularProgress size={24} style={{ color: Colors.GRAY1 }} />
            </div>
          ) : (
            <>
              {subscribingStage === SubscribeManageStage.MEMBERSHIP ? (
                <SubscribeFlowMembershipManage
                  creatorData={creatorData}
                  setConfirmCancelModal={setConfirmCancelModal}
                  setSubscribeStage={setSubscribingStage}
                  followerData={followerData}
                />
              ) : subscribingStage === SubscribeManageStage.MEMBERSHIP_PLANS ? (
                <SubscribeFlowMembership
                  creatorData={creatorData}
                  chooseOnPress={chooseMembershipOnPress}
                  selectedMembershipPlan={selectedMembershipPlan}
                  setSelectedMembershipPlan={setSelectedMembershipPlan}
                  followerData={followerData}
                />
              ) : subscribingStage ===
                  SubscribeManageStage.MEMBERSHIP_CHECKOUT &&
                selectedMembershipPlan ? (
                <SubscribeFlowMembershipCheckout
                  creatorData={creatorData}
                  selectedMembershipPlan={selectedMembershipPlan}
                  uid={accountData.uid}
                  backOnPress={() =>
                    setSubscribingStage(SubscribeManageStage.MEMBERSHIP_PLANS)
                  }
                />
              ) : null}
            </>
          )}
          <div
            className="Centering"
            style={{ paddingTop: 100, paddingBottom: 30 }}
          >
            <MarkitSecuredContainer />
          </div>
        </div>
        <ConfirmDeleteModal
          heading={"Cancel Paid Subscription?"}
          subtext="Upon cancellation you will lose access to all plan benefits, and your account will be charged for the remainder of your plan's billing cycle. You can resubscribe at any time."
          deleteButtonText="Cancel Subscription"
          hideModal={!confirmCancelModal}
          setIsVisible={setConfirmCancelModal}
          deleteOnPress={cancelFollowerSubscription}
          buttonLoading={processing}
          icon={
            <Icon
              icon={"ion:ribbon"}
              height={35}
              style={{ color: Colors.BLACK }}
            />
          }
        />
      </>
    );
  }
);

export default ProfilePageSubscribeManage;
