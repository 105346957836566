import { useTheme } from "../../../hooks/useTheme";
import { memo, useCallback, useMemo, useState } from "react";
import { isDesktop } from "react-device-detect";
import ProfilePic from "../../ProfilePic";
import {
  AccountData,
  Follower,
  FollowerMembershipState,
  NotificationType,
} from "@markit/common.types";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import LoadingScreen from "../../../screens/LoadingScreen";
import RectangleButton from "../../Buttons/RectangleButton";
import { Colors } from "../../../utils/colors";
import { SubscribeManageStage } from "../../../screens/Profile/SubscriberProfile/ProfilePageSubscribeManage";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { Icon } from "@iconify/react";
import HelpModal from "../../Containers/HelpModal";
import MembershipSubscriptionHistoryModal from "../../Subscription/Memberships/MembershipSubscriptionHistoryModal";
import { useSelector, useDispatch } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import ConfirmDeleteModal from "../../Containers/ConfirmPopups/ConfirmDeleteModal";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import { useNavigate } from "react-router-dom";
import { onProfilePageNavigatePath } from "../../../utils/navigationUtils";
import { unfollowUser } from "../../../utils/FollowUser";
import PaymentFailedButton from "../../Subscription/PaymentFailedButton";
import MembershipPaymentFailedButtonModal from "../../Subscription/Memberships/MembershipPaymentFailedButtonModal";

type SubscribeFlowMembershipManageProps = {
  creatorData: AccountData;
  setConfirmCancelModal: (confirmCancelModal: boolean) => void;
  setSubscribeStage: (subscribeStage: SubscribeManageStage) => void;
  followerData: Follower;
};

const SubscribeFlowMembershipManage = (
  props: SubscribeFlowMembershipManageProps
) => {
  const {
    creatorData,
    setConfirmCancelModal,
    setSubscribeStage,
    followerData,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accountData } = useSelector(getAccountState).account;
  const { theme } = useTheme();
  const [helpModal, setHelpModal] = useState(false);
  const [subscriptionHistoryVisible, setSubscriptionHistoryVisible] =
    useState(false);
  const [processing, setProcessing] = useState(false);
  const [unsubscribeModal, setUnsubscribeModal] = useState(false);
  const [paymentFailedModal, setPaymentFailedModal] = useState(false);

  const styles = {
    cardContainer: {
      paddingBlock: 14,
      borderRadius: 14,
    },
    buttonsContainer: { gap: 10, paddingInline: 14, paddingTop: 20 },
  };

  const currentMembershipPlan = useMemo(
    () =>
      creatorData.membership.membershipPlans.find(
        (plan) => plan.id === followerData?.membershipPlanId
      ),
    [creatorData.membership.membershipPlans, followerData?.membershipPlanId]
  );

  const isFreePlan = useMemo(
    () => currentMembershipPlan?.id === "free",
    [currentMembershipPlan]
  );

  const backOnPress = useCallback(() => {
    onProfilePageNavigatePath(navigate, creatorData.username);
  }, [creatorData.username, navigate]);

  const onUnfollowClick = useCallback(async () => {
    setProcessing(true);
    await unfollowUser(accountData.uid, creatorData.uid, false);
    setProcessing(false);
    backOnPress();
    showNotificationBanner(dispatch, "Unsubscribed", NotificationType.NEGATIVE);
  }, [dispatch, accountData.uid, creatorData.uid, backOnPress]);

  const unsubscribeOnPress = useCallback(() => {
    isFreePlan ? setUnsubscribeModal(true) : setConfirmCancelModal(true);
  }, [isFreePlan, setConfirmCancelModal]);

  if (!currentMembershipPlan) {
    return <LoadingScreen error />;
  }

  return (
    <>
      <div
        className="ColumnNormal"
        style={{
          marginInline: "auto",
          paddingTop: 40,
          width: isDesktop ? 544 : "100%",
          gap: 20,
        }}
      >
        <div
          className="ColumnCentering"
          style={{
            gap: isDesktop ? 30 : 14,
            paddingInline: isDesktop ? 0 : 14,
          }}
        >
          <ProfilePic size={isDesktop ? 100 : 75} user={creatorData} />
          <div className="ColumnCentering" style={{ gap: 14 }}>
            <div className="ColumnCentering" style={{ gap: 7 }}>
              <span
                className="sectionTitle"
                style={{ fontWeight: 600, ...theme.PrimaryText }}
              >
                Manage Subscription
              </span>
              <div
                onClick={backOnPress}
                className="AlignedRowSelect"
                style={{ gap: 2 }}
              >
                <Icon
                  icon={"ion:chevron-left"}
                  height={11}
                  style={theme.SubText}
                />
                <span className="smallBodySubtext">Back to profile</span>
              </div>
            </div>
            <span
              className="bodySubtext"
              style={{ width: isDesktop ? 330 : "100%", textAlign: "center" }}
            >
              You are currently a{" "}
              {followerData && followerData.membershipPlanId !== "free"
                ? "paid"
                : "free"}{" "}
              subscriber to {creatorData.fullName}
            </span>
          </div>
          <div className="ColumnNormal" style={{ gap: 14, width: "100%" }}>
            <span className="sectionTitle">Current Subscription</span>
            <StandardBorderedContainer containerStyles={styles.cardContainer}>
              <div
                className="ColumnNormal"
                style={{ gap: 7, paddingInline: 14 }}
              >
                <span className="sectionTitle">
                  {currentMembershipPlan.name}
                </span>
                <span>
                  ${currentMembershipPlan.price / 100}{" "}
                  <span className="smallBodySubtext">/ month</span>
                </span>
              </div>
              <HorizontalDivider altMargin={14} />
              <div
                className="ColumnNormal"
                style={{ gap: 7, paddingInline: 14 }}
              >
                <span style={{ fontSize: 12, ...theme.LabelText }}>
                  Benefits
                </span>
                <span className="bodyMedium" style={{ whiteSpace: "pre-wrap" }}>
                  {currentMembershipPlan.description}
                </span>
              </div>
              {followerData.membershipState ===
              FollowerMembershipState.PAYMENT_FAILED ? (
                <div className="ColumnNormal" style={styles.buttonsContainer}>
                  <PaymentFailedButton
                    type="Subscriber"
                    onPress={() => setPaymentFailedModal(true)}
                  />
                  <RectangleButton
                    buttonLabel={
                      isFreePlan ? "Unsubscribe" : "Cancel Subscription"
                    }
                    onPress={unsubscribeOnPress}
                    altTextColor={Colors.RED1}
                    containerStyles={{
                      padding: 14,
                      borderRadius: 12,
                      border: `1px solid ${theme.DividerColor.borderColor}`,
                      backgroundColor: Colors.WHITE1,
                    }}
                  />
                </div>
              ) : (
                <div className="AlignedRow" style={styles.buttonsContainer}>
                  <RectangleButton
                    buttonLabel={isFreePlan ? "Upgrade" : "Change Plan"}
                    onPress={() =>
                      setSubscribeStage(SubscribeManageStage.MEMBERSHIP_PLANS)
                    }
                    containerStyles={{
                      padding: 14,
                      borderRadius: 12,
                      backgroundColor: Colors.BLACK,
                    }}
                  />
                  <RectangleButton
                    buttonLabel={
                      isFreePlan ? "Unsubscribe" : "Cancel Subscription"
                    }
                    onPress={unsubscribeOnPress}
                    altTextColor={Colors.RED1}
                    containerStyles={{
                      padding: 14,
                      borderRadius: 12,
                      border: `1px solid ${theme.DividerColor.borderColor}`,
                      backgroundColor: Colors.WHITE1,
                    }}
                  />
                </div>
              )}
            </StandardBorderedContainer>
          </div>
          <div className="ColumnNormal" style={{ gap: 14, width: "100%" }}>
            <span className="sectionTitle">Account</span>
            <StandardBorderedContainer
              containerStyles={{
                backgroundColor: Colors.WHITE1,
                ...styles.cardContainer,
              }}
            >
              <div
                className="ColumnNormal"
                style={{ gap: 14, paddingInline: 14 }}
              >
                <div
                  onClick={() => setSubscriptionHistoryVisible(true)}
                  className="AlignedRowSpacedSelect"
                  style={{ paddingBlock: 7 }}
                >
                  <span className="bodyMedium">Subscription History</span>
                  <Icon
                    icon="ion:chevron-forward"
                    height={18}
                    color={Colors.GRAY2}
                  />
                </div>
                {!isFreePlan ? (
                  <div
                    onClick={() => setHelpModal(true)}
                    className="AlignedRowSpacedSelect"
                    style={{ paddingBlock: 7 }}
                  >
                    <span className="bodyMedium">Manage Payment Method</span>
                    <Icon
                      icon="ion:chevron-forward"
                      height={18}
                      color={Colors.GRAY2}
                    />
                  </div>
                ) : null}
              </div>
            </StandardBorderedContainer>
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        heading={`Unsubscribe from ${creatorData.fullName}?`}
        subtext="Re-subscribe at any time."
        deleteButtonText="Unsubscribe"
        hideModal={!unsubscribeModal}
        setIsVisible={setUnsubscribeModal}
        deleteOnPress={onUnfollowClick}
        buttonLoading={processing}
      />
      {helpModal ? (
        <HelpModal
          showModal={helpModal}
          setShowModal={setHelpModal}
          alternateSubtext="Text our hotline to change your payment method."
        />
      ) : null}
      {subscriptionHistoryVisible ? (
        <MembershipSubscriptionHistoryModal
          isVisible={subscriptionHistoryVisible}
          closeOnPress={() => setSubscriptionHistoryVisible(false)}
          creatorData={creatorData}
          userData={accountData}
          followerData={followerData}
        />
      ) : null}
      {paymentFailedModal ? (
        <MembershipPaymentFailedButtonModal
          setIsVisible={setPaymentFailedModal}
          followerDataMap={new Map([[creatorData.uid, followerData]])}
          followingAccountDataFollowing={[creatorData]}
        />
      ) : null}
    </>
  );
};

export default memo(SubscribeFlowMembershipManage);
