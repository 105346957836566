import { AudienceList } from "@markit/common.types";
import { Colors } from "../../../utils/colors";
import { FAVORITES_LIST_NAME, handlePluralString } from "@markit/common.utils";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { MassTextsActionItem } from "../../CreatorDashboard/MassTexts/Items/MassTextsActionItem";
import { Icon } from "@iconify/react";

export const AUDIENCE_LIST_FLEX_SECTIONS = [2, 1, 1, 6];

type AudienceListActionItemProps = {
  item: AudienceList;
  onPress: () => void;
  alternateColor?: string;
  isCheckSelected?: boolean;
  disabled?: boolean;
};

// List item that is used as an action to view contents (ie. SelectRecipientsAudienceListLibrary)
const AudienceListActionItem = (props: AudienceListActionItemProps) => {
  const { item, onPress, alternateColor, isCheckSelected, disabled } = props;
  const audienceListDetails =
    useSelector(getAccountState).account.audienceListDetails;

  const audienceListDetail = useMemo(
    () =>
      audienceListDetails.find((listDetail) => listDetail.list.id === item.id),
    [audienceListDetails, item.id]
  );

  return (
    <MassTextsActionItem
      title={item.name}
      altIconBackgroundColor={Colors.GRAY6}
      icon={
        item.name === FAVORITES_LIST_NAME ? (
          <Icon icon="mdi:star-circle" height={34} />
        ) : (
          <Icon
            icon="ion:list"
            height={40}
            color={alternateColor ?? Colors.BLUE5}
          />
        )
      }
      onPress={onPress}
      subtext={`${
        audienceListDetail ? audienceListDetail.numMembers : "--"
      } ${handlePluralString("Contact", audienceListDetail?.numMembers || 0)}`}
      checkColor={isCheckSelected !== undefined ? Colors.BLUE5 : undefined}
      isCheckSelected={isCheckSelected}
      disabled={disabled}
      alternateRightIcon={
        isCheckSelected === undefined ? (
          <Icon icon="ion:chevron-right" height={16} color={Colors.GRAY1} />
        ) : undefined
      }
    />
  );
};

export default AudienceListActionItem;
