import { AudienceList, AudienceListVisibility } from "@markit/common.types";
import {
  getUserAudienceListMembersRef,
  getUserAudienceListsRef,
  getUserAudienceListsSnap,
} from "./FirebaseUtils";
import { getCountFromServer, getDocs, query, where } from "../firebase";
import { filterUndefinedValues } from "@markit/common.utils";

// Gets the number of audience list members on a specified list
export const fetchNumAudienceListMembers = async (
  userId: string,
  listId: string
) => {
  const audienceListMembersRef = getUserAudienceListMembersRef(userId, listId);
  const snapshot = await getCountFromServer(audienceListMembersRef);
  return snapshot.data().count;
};

// fetches the user's public audience lists
export const fetchUserPublicAudienceLists = async (
  userId: string
): Promise<AudienceList[]> => {
  const audienceListsRef = getUserAudienceListsRef(userId);
  const audienceListsQuery = query(
    audienceListsRef,
    where("visibility", "==", AudienceListVisibility.PUBLIC)
  );
  const audienceListsSnapshot = await getDocs(audienceListsQuery);
  const audienceLists = await Promise.all(
    audienceListsSnapshot.docs.map((snapshot) => {
      const audienceListData = snapshot.data();
      return audienceListData;
    })
  );
  return audienceLists;
};

// checks if the following users exist in an audience list
// TODO: Once we do a list refactor, should be able to check for this so that we can compare against all userIds instead of just one
export const checkAudienceListMembership = async (
  userId: string,
  audienceListId: string,
  creatorId: string
): Promise<boolean> => {
  if (audienceListId === "") {
    return false;
  }
  const audienceListRef = getUserAudienceListMembersRef(
    creatorId,
    audienceListId
  );
  const query_ = query(audienceListRef, where("uid", "==", userId));
  const querySnapshot = await getDocs(query_);

  return !querySnapshot.empty;
};

export const fetchUserJoinedAudienceLists = async (
  creatorUid: string,
  userId: string,
  numListsToDisplay?: number
) => {
  const creatorAudienceListsSnap = await getUserAudienceListsSnap(creatorUid);
  const audienceLists = await Promise.all(
    creatorAudienceListsSnap.docs.map(async (doc) => {
      const audienceList = doc.data();
      const membersRef = getUserAudienceListMembersRef(
        creatorUid,
        audienceList.id
      );
      const membersQuery = query(membersRef, where("uid", "==", userId));
      const snapshot = await getDocs(membersQuery);
      if (!snapshot.empty) {
        return audienceList;
      }
      return undefined;
    })
  );
  const definedAudienceLists: AudienceList[] =
    filterUndefinedValues(audienceLists);

  return numListsToDisplay
    ? definedAudienceLists.slice(0, numListsToDisplay)
    : definedAudienceLists;
};
