import { Icon } from "@iconify/react";
import { Colors } from "../utils/colors";
import { CircularProgress } from "@mui/material";
import { ReactNode } from "react";

type DropdownMenuLabelProps = {
  label: string;
  subtext?: ReactNode;
  icon?: string;
  width?: number;
  isDestructive?: boolean;
  loading?: boolean;
  dark?: boolean;
};

const DropdownMenuLabel = (props: DropdownMenuLabelProps) => {
  const {
    label,
    subtext,
    icon,
    width,
    isDestructive = false,
    loading = false,
    dark = false,
  } = props;

  return (
    <div
      className="AlignedRowSpacedSelect"
      style={{
        width: width,
        paddingBlock: 4,
        transition: "opacity 0.2s ease",
      }}
      onMouseEnter={(e) => (e.currentTarget.style.opacity = "0.7")}
      onMouseLeave={(e) => (e.currentTarget.style.opacity = "1")}
    >
      <div className="ColumnNormal">
        <span
          style={{
            fontWeight: 500,
            color: dark
              ? Colors.WHITE
              : isDestructive
              ? Colors.RED1
              : Colors.BLACK,
          }}
        >
          {label}
        </span>
        {subtext}
      </div>
      {icon ? (
        loading ? (
          <CircularProgress
            style={{
              color: dark ? Colors.WHITE : Colors.BLACK,
              alignSelf: "center",
            }}
            size={17}
          />
        ) : (
          <Icon
            icon={icon}
            height={16}
            style={{
              color: dark
                ? Colors.WHITE
                : isDestructive
                ? Colors.RED1
                : Colors.BLACK,
            }}
          />
        )
      ) : null}
    </div>
  );
};

export default DropdownMenuLabel;
