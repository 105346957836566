import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { Subscription } from "@markit/common.types";
import {
  convertToMoney,
  dateIsCurrentYear,
  formatDateTime,
  isSubscriptionPaymentFailed,
  subscriptionOverflowRate,
  subscriptionPlanType,
} from "@markit/common.utils";
import { useMemo } from "react";
import { Icon } from "@iconify/react";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import { Colors } from "../../../utils/colors";
import BillingItemInvoice from "./BillingItemInvoice";

type SubscriptionBillingItemProps = {
  subscription: Subscription;
  lastMonthSubscription?: Subscription;
  showBaseline: boolean;
  showOverflow: boolean;
  amount: number;
  showIcon?: boolean;
  isUpcoming?: boolean;
  displayInvoice?: boolean;
};

const SubscriptionBillingItem = (props: SubscriptionBillingItemProps) => {
  const {
    subscription,
    lastMonthSubscription,
    showBaseline,
    showOverflow,
    amount,
    showIcon = false,
    isUpcoming = false,
    displayInvoice = false,
  } = props;
  const accountData = useSelector(getAccountState).account.accountData;

  const subscriptionPlan = useMemo(
    () =>
      subscription.price
        ? subscriptionPlanType(subscription.price[0].id, accountData.uid)
        : undefined,
    [accountData.uid, subscription.price]
  );

  const overflowPrice = useMemo(
    () => subscriptionOverflowRate(accountData) / 100,
    [accountData]
  );

  const numOverflowCredits = useMemo(
    () =>
      subscriptionPlan
        ? Math.max(
            lastMonthSubscription
              ? lastMonthSubscription.message -
                  (lastMonthSubscription.price[0].limit ?? 0)
              : subscription.message - subscriptionPlan.baselineLimit,
            0
          )
        : 0,
    [lastMonthSubscription, subscription.message, subscriptionPlan]
  );

  const trialSubscription = useMemo(
    () => accountData.customer.state === "TRIAL",
    [accountData.customer.state]
  );

  const upcomingPaymentFailed = useMemo(
    () => isUpcoming && isSubscriptionPaymentFailed(accountData.customer.state),
    [accountData.customer.state, isUpcoming]
  );

  return subscriptionPlan ? (
    <div className="AlignedRow" style={{ paddingInline: 14, gap: 14, flex: 1 }}>
      {showIcon ? (
        <StandardBorderedContainer
          containerStyles={{
            padding: 7,
            borderRadius: 8,
            borderColor: upcomingPaymentFailed
              ? Colors.RED3
              : isUpcoming
              ? Colors.GRAY2
              : Colors.BLUE5,
            backgroundColor: upcomingPaymentFailed
              ? `${Colors.RED3}1A`
              : isUpcoming
              ? Colors.WHITE1
              : `${Colors.BLUE5}1A`,
            alignSelf: "flex-start",
            marginTop: displayInvoice ? 30 : 20,
          }}
        >
          <div className="Centering">
            <Icon
              icon={
                upcomingPaymentFailed
                  ? "ion:close"
                  : isUpcoming
                  ? "ion:time"
                  : "ion:checkmark"
              }
              height={23}
              color={
                upcomingPaymentFailed
                  ? Colors.RED3
                  : isUpcoming
                  ? Colors.GRAY1
                  : Colors.BLUE5
              }
            />
          </div>
        </StandardBorderedContainer>
      ) : null}
      <div className="ColumnNormal" style={{ gap: 14, flex: 1 }}>
        {displayInvoice ? (
          <div
            className="AlignedRow"
            style={{
              padding: "4px 7px",
              borderRadius: 4,
              border: `1.5px solid ${Colors.GRAY11}`,
              backgroundColor: Colors.WHITE1,
              width: "fit-content",
            }}
          >
            <span className="bodySmallMedium">
              {upcomingPaymentFailed
                ? "Failed Bill"
                : isUpcoming
                ? "Upcoming Bill"
                : "Billed"}{" "}
              on{" "}
              {formatDateTime(
                new Date(subscription.start),
                true,
                !dateIsCurrentYear(subscription.start)
              )}
            </span>
          </div>
        ) : null}
        <div
          className={
            showBaseline && showOverflow
              ? "RowNormalSpaced"
              : "AlignedRowSpaced"
          }
          style={{ gap: 14, flex: 1 }}
        >
          <div className="ColumnNormal" style={{ gap: 14, flex: 1 }}>
            {showBaseline ? (
              <div className="ColumnNormal" style={{ gap: 4 }}>
                <span className="bodyMedium">{subscriptionPlan.name} Plan</span>
                <span className="smallBodySubtext">
                  {trialSubscription ? "Trial" : "Billing"} cycle:{" "}
                  {formatDateTime(new Date(subscription.start), true, false)} -{" "}
                  {formatDateTime(new Date(subscription.end), true, true)}
                </span>
                <span className="smallBodySubtext">Quantity: 1</span>
                <span className="smallBodySubtext">
                  Unit Price:{" "}
                  {convertToMoney(
                    !trialSubscription ? subscriptionPlan.price / 100 : 0
                  )}
                </span>
              </div>
            ) : null}
            {showOverflow ? (
              <div className="ColumnNormal" style={{ gap: 4 }}>
                <span className="bodyMedium">Overflow Texting Credits</span>
                <span className="smallBodySubtext">
                  Billing cycle:{" "}
                  {formatDateTime(
                    new Date(
                      lastMonthSubscription
                        ? lastMonthSubscription.start
                        : subscription.start
                    ),
                    true,
                    false
                  )}{" "}
                  -{" "}
                  {formatDateTime(
                    new Date(
                      lastMonthSubscription
                        ? lastMonthSubscription.end
                        : subscription.end
                    ),
                    true,
                    true
                  )}
                </span>
                <span className="smallBodySubtext">
                  Overflow Credits Used: {numOverflowCredits.toLocaleString()}
                </span>
                <span className="smallBodySubtext">
                  Unit Price: ${overflowPrice}
                </span>
              </div>
            ) : null}
            {displayInvoice && (!isUpcoming || upcomingPaymentFailed) ? (
              <BillingItemInvoice
                subscriptionId={subscription.id}
                subscriptionStart={subscription.start}
                upcomingPaymentFailed={upcomingPaymentFailed}
              />
            ) : null}
          </div>
          <span className="sectionTitle">{convertToMoney(amount)}</span>
        </div>
      </div>
    </div>
  ) : null;
};

export default SubscriptionBillingItem;
