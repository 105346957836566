import { Icon } from "@iconify/react";
import MarkitLogoGray from "../../assets/MarkitLogoGray.png";
import MarkitLogoWhite from "../../assets/MarkitLogoWhite.png";
import { memo } from "react";
import { MARKIT_TERMS_OF_USE } from "@markit/common.utils";
import { LightTheme, useTheme } from "../../hooks/useTheme";

/**
 * To be used with the phone number verification, especially in the case of the external event link flow
 */
const MarkitSecuredContainer = () => {
  const { theme } = useTheme();

  return (
    <div className="Centering" style={{ gap: 7, ...theme.SubText }}>
      <Icon
        icon="ion:lock-closed-outline"
        height={14}
        color={theme.SubText.color}
      />
      <span style={{ fontSize: 12 }}>Secured by </span>
      <img
        style={{ height: 14, width: "auto" }}
        alt="MarkitAppIconGray"
        src={theme === LightTheme ? MarkitLogoGray : MarkitLogoWhite}
      />
      <span style={{ fontSize: 14, fontWeight: 600 }}>markit</span>
      <span
        style={{
          textDecorationLine: "underline",
          fontWeight: 500,
          fontSize: 12,
          cursor: "pointer",
        }}
        onClick={() => window.open(MARKIT_TERMS_OF_USE)}
      >
        Learn more
      </span>
    </div>
  );
};

export default memo(MarkitSecuredContainer);
