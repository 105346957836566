import { Route, Routes } from "react-router-dom";
import { memo } from "react";
import HomePage from "../screens/Home/HomePage";
import CampaignsPanel from "../screens/Profile/CreatorPanels/CampaignsPanel";
import TransactionsPanel from "../screens/Profile/CreatorPanels/TransactionsPanel";
import BillingPanel from "../screens/Profile/CreatorPanels/BillingPanel";
import EventsPanel from "../screens/Profile/CreatorPanels/GrowPanels/EventsPanel";
import HomePanel from "../screens/Profile/CreatorPanels/HomePanel";
import LinksPanel from "../screens/Profile/CreatorPanels/GrowPanels/LinksPanel";
import ConversationsPanel from "../screens/Profile/CreatorPanels/ConversationsPanel";
import ContactsPanel from "../screens/Profile/CreatorPanels/AudiencePanels/ContactsPanel";
import ListsPanel from "../screens/Profile/CreatorPanels/AudiencePanels/ListsPanel";
import ImportPanel from "../screens/Profile/CreatorPanels/AudiencePanels/ImportPanel";
import IntegrationsPanel from "../screens/Profile/CreatorPanels/AudiencePanels/IntegrationsPanel";
import AutomationsPanel from "../screens/Profile/CreatorPanels/AutomationsPanel";
import ProfilePanel from "../screens/Profile/CreatorPanels/ProfilePanel";
import MembershipsPanel from "../screens/Profile/CreatorPanels/AudiencePanels/MembershipsPanel";
import GetStartedOnboardingPanel from "../screens/Profile/CreatorPanels/GetStartedOnboardingPanel";

const HomeRoute = memo(function HomeRouteFn() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />}>
        <Route index element={<HomePanel />} />
        <Route path="profile" element={<ProfilePanel />} />
        <Route path="contacts" element={<ContactsPanel />} />
        <Route path="memberships" element={<MembershipsPanel />} />
        <Route path="lists" element={<ListsPanel />} />
        <Route path="import" element={<ImportPanel />} />
        <Route path="integrations" element={<IntegrationsPanel />} />
        <Route path="events" element={<EventsPanel />} />
        <Route path="links" element={<LinksPanel />} />
        <Route path="campaigns" element={<CampaignsPanel />} />
        <Route path="automations" element={<AutomationsPanel />} />
        <Route path="conversations" element={<ConversationsPanel />} />
        <Route path="transactions" element={<TransactionsPanel />} />
        <Route path="billing" element={<BillingPanel />} />
        <Route path="get-started" element={<GetStartedOnboardingPanel />} />
      </Route>
    </Routes>
  );
});

export default HomeRoute;
