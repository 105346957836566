import { useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createAudienceList,
  getAccountState,
  updateAudienceList,
} from "../../../../redux/slices/accountSlice";
import { Colors } from "../../../../utils/colors";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import { Icon } from "@iconify/react";
import CustomTextField from "../../../CustomTextField";
import {
  AudienceList,
  AudienceListVisibility,
  NotificationType,
} from "@markit/common.types";
import RectangleButton from "../../../Buttons/RectangleButton";
import {
  compareTwoStringsInsensitive,
  FAVORITES_LIST_NAME,
  makeEmptyAudienceList,
} from "@markit/common.utils";
import { generate } from "shortid";
import { showNotificationBanner } from "../../../../utils/notificationUtils";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "../../../Containers/PopupModalContainer";
import { useTheme } from "../../../../hooks/useTheme";
import { useNavigate } from "react-router-dom";
import { onAudienceListNavigatePath } from "../../../../utils/navigationUtils";
import CustomTextFieldMultiline from "../../../TextFields/CustomTextFieldMultiline";
import isEqual from "lodash.isequal";

type AudienceListDetailsModalProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  existingList: AudienceList | undefined;
  preventNavigation?: boolean; // If we don't want to navigate to list dashboard (ie. create list on import contacts)
};

const AudienceListDetailsModal = (props: AudienceListDetailsModalProps) => {
  const { isVisible, setIsVisible, existingList, preventNavigation } = props;
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accountData, audienceListDetails } =
    useSelector(getAccountState).account;
  const [audienceList, setAudienceList] = useState<AudienceList>(
    existingList ?? makeEmptyAudienceList(generate())
  );
  const [listNameError, setListNameError] = useState<{
    isError: boolean;
    message: string;
  }>({ isError: false, message: "" });
  const [savingList, setSavingList] = useState(false);

  const styles = {
    visibilityContainer: {
      backgroundColor: Colors.GRAY6,
      padding: 14,
      cursor: "pointer",
    },
    errorText: { fontSize: 12, color: Colors.RED2 },
  };

  const audienceLists = useMemo(
    () => audienceListDetails.map((listDetail) => listDetail.list),
    [audienceListDetails]
  );

  const updateAudienceListSettings = useCallback(
    (newListSettings: Partial<AudienceList>) => {
      setAudienceList((prevListSettings) => {
        return { ...prevListSettings, ...newListSettings };
      });
    },
    []
  );

  const confirmListOnPress = useCallback(async () => {
    if (audienceList.name.trim() === "") {
      setListNameError({
        isError: true,
        message: "You must enter a list name.",
      });
      return;
    }
    if (compareTwoStringsInsensitive(audienceList.name, FAVORITES_LIST_NAME)) {
      setListNameError({
        isError: true,
        message: 'Can not make list called "Favorites".',
      });
      return;
    }
    if (
      audienceLists
        .filter((list) => list.name !== existingList?.name)
        .some((list) => list.name === audienceList.name)
    ) {
      setListNameError({
        isError: true,
        message: "A list already exists with this name.",
      });
      return;
    }

    setSavingList(true);
    if (existingList) {
      await dispatch(updateAudienceList(accountData.uid, audienceList));
    } else {
      await dispatch(createAudienceList(accountData.uid, audienceList));
      if (!preventNavigation) {
        navigate(onAudienceListNavigatePath(audienceList.id), {
          state: { audienceListId: audienceList.id },
        });
      }
    }
    showNotificationBanner(
      dispatch,
      existingList ? "List Updated!" : "List Created!",
      NotificationType.AFFIRMATIVE
    );
    setIsVisible(false);
    setSavingList(false);
  }, [
    audienceList,
    audienceLists,
    dispatch,
    existingList,
    setIsVisible,
    accountData.uid,
    preventNavigation,
    navigate,
  ]);

  return (
    <>
      {isVisible ? (
        <PopupModalContainer
          headerComp={
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <Icon icon="ion:list-circle" height={40} />
              <div className="ColumnNormal" style={{ gap: 7 }}>
                <span className="sectionTitle">
                  {existingList ? "Edit List Details" : "New List"}
                </span>
                {!existingList ? (
                  <span className="bodySubtext">
                    Name your list and select visibility to continue
                  </span>
                ) : null}
              </div>
            </div>
          }
          valueComp={
            <div className="ColumnNormal" style={{ gap: 20 }}>
              <div className="ColumnNormal" style={{ gap: 10 }}>
                <span className="bodyMedium">Enter Name</span>
                <div className="ColumnNormal" style={{ gap: 4 }}>
                  <CustomTextField
                    value={audienceList.name}
                    placeholder="Name Your List..."
                    inputMode="text"
                    borderRadius={12}
                    backgroundColor={Colors.WHITE1}
                    onChange={(change: any) => {
                      setListNameError({ isError: false, message: "" });
                      updateAudienceListSettings({
                        name: change.target.value,
                      });
                    }}
                    error={listNameError.isError}
                    altMarginBottom={0}
                  />
                  {listNameError.isError ? (
                    <span style={styles.errorText}>
                      {listNameError.message}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="ColumnNormal" style={{ gap: 10 }}>
                <span className="bodyMedium">Description (Optional)</span>
                <CustomTextFieldMultiline
                  value={audienceList.description}
                  placeholder="Write a description..."
                  onChange={(e) =>
                    updateAudienceListSettings({
                      description: e.target.value,
                    })
                  }
                />
              </div>
              <div className="ColumnNormal" style={{ gap: 10 }}>
                <span className="bodyMedium">Visiblity</span>
                <StandardBorderedContainer
                  containerStyles={{
                    ...styles.visibilityContainer,
                    border:
                      audienceList.visibility === AudienceListVisibility.PRIVATE
                        ? `1.5px solid ${Colors.BLACK}`
                        : `1.5px solid ${theme.DividerColor.borderColor}`,
                  }}
                  onPress={() =>
                    updateAudienceListSettings({
                      visibility: AudienceListVisibility.PRIVATE,
                    })
                  }
                >
                  <div className="AlignedRowSelect" style={{ gap: 10 }}>
                    <Icon icon="ion:lock-closed" height={15} />
                    <div className="ColumnNormalSelect" style={{ gap: 4 }}>
                      <span className="bodyMedium">Private</span>
                      <span className="smallBodySubtext">
                        People are only added by you
                      </span>
                    </div>
                  </div>
                </StandardBorderedContainer>
                <StandardBorderedContainer
                  containerStyles={{
                    ...styles.visibilityContainer,
                    border:
                      audienceList.visibility === AudienceListVisibility.PUBLIC
                        ? `1.5px solid ${Colors.BLACK}`
                        : `1.5px solid ${theme.DividerColor.borderColor}`,
                  }}
                  onPress={() =>
                    updateAudienceListSettings({
                      visibility: AudienceListVisibility.PUBLIC,
                    })
                  }
                >
                  <div className="AlignedRowSelect" style={{ gap: 10 }}>
                    <Icon icon="ion:people" height={15} />
                    <div className="ColumnNormalSelect" style={{ gap: 4 }}>
                      <span className="bodyMedium">Public</span>
                      <span className="smallBodySubtext">
                        Anyone can join this list from your public profile
                      </span>
                    </div>
                  </div>
                </StandardBorderedContainer>
              </div>
              <RectangleButton
                buttonLabel={existingList ? "Save" : "Create List"}
                onPress={confirmListOnPress}
                loading={savingList}
                disabled={savingList || isEqual(existingList, audienceList)}
                altColor={Colors.BLACK}
                altPaddingHorz={12}
                altPaddingVert={16}
                altBorderRadius={12}
              />
            </div>
          }
          noExit
          closeOnOutsidePress
          closeModal={() => setIsVisible(false)}
          modalContentWidth={PopupModalContainerWidth.SMALL}
        />
      ) : null}
    </>
  );
};

export default AudienceListDetailsModal;
